import notificationBannerOptions from '../../../constants/notificationBannerOptions';
import CustomButton from '../../UI/Button';
import styles from './NotificationBanner.module.css';

function NotificationBanner({ meta, history, onCloseButtonClick }) {
  const { colourA, colourB } = notificationBannerOptions.colourSchemes.find((c) => meta.banner_color === c.id) || {
    colourA: '#ddd',
    colourB: '#eee',
  };
  const { label: actionText, link } = notificationBannerOptions.actions.find((a) => meta.action === a.id) || {
    label: '',
    link: '',
  };
  const Icon = notificationBannerOptions.icons.find((i) => meta.banner_pic === i.id)?.Component || (() => <div />);

  function handleActionButtonClick() {
    history?.push(link(meta.lar_id));
  }

  if (!meta.message) {
    return null;
  }

  return (
    <div className={styles.notification} style={{ backgroundColor: colourB }}>
      <Icon className={styles.notificationIcon} colour="white" size="3.4em" style={{ backgroundColor: colourA }} />
      <div>{meta.message}</div>
      {actionText ? <CustomButton onClick={handleActionButtonClick} text={actionText} primary /> : <div />}
      <CustomButton key="back" onClick={onCloseButtonClick} text="Close" />
    </div>
  );
}

export default NotificationBanner;
