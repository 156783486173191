import { GET_FEES_WORKSHEETS } from '../actions/types';

const initialState = {};

const feesWorksheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEES_WORKSHEETS:
      return action.payload;
    default:
      return state;
  }
};

export default feesWorksheetReducer;
