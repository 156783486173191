import { Button, message, Spin, Modal } from 'antd';
import { isEmpty } from 'lodash';
import { MessageIcon, MailIcon, PhoneIcon } from 'assets/icons';
import { setChatState } from 'store/actions/userActions';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import EndScenarioModal from 'components/LarAppNew/Modals/EndScenarioModal';
import InfoTriangleIcon from 'assets/icons/InfoTriangleIcon';
import useLarApi from 'components/LarAppNew/hooks/useLarApi';
import styles from './LarAppConfirm.module.css';

function LarAppConfirm() {
  const [larData, setLarData] = useState({});
  const [loading, setLoading] = useState(false);
  const [scenarioLoading, setScenarioLoading] = useState(false);
  const [activeModal, setActiveModal] = useState({ show: '', data: null });
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { getLar, endLarCreation, larScheduling } = useLarApi();

  const isAuction = larData.creation?.percent_completed_la === 100;
  const isFromBorrower = larData.team?.creator.role === 'Borrower';

  useEffect(() => {
    getLar(id)
      .then((res) => {
        setLarData(res.data);
      })
      .catch(() => message.error('Something was wrong. Please, try again.'));
    // eslint-disable-next-line
  }, []);

  function handleEditApp() {
    history.goBack();
  }

  function handleExit() {
    history.push('/');
  }

  function handleSubmitScenario() {
    setScenarioLoading(true);
    endLarCreation({
      id: +id,
      get_smartmatch_result: false,
    })
      .then(() => setActiveModal({ show: 'scenario_review', data: {} }))
      .finally(() => setScenarioLoading(false));
  }

  async function handleSchedulingAuction() {
    try {
      setLoading(true);
      const larSchedulingResponse = await larScheduling(+id);

      const endLarCreationResponse = await endLarCreation({ id: +id });

      if (endLarCreationResponse) {
        if (larSchedulingResponse.data?.can_schedule) {
          history.push(`/onboarding/auction/${id}/schedule`);
        } else {
          setActiveModal(() => ({
            show: 'lar_review',
            data: {},
          }));
        }
        message.success('LAR successfully created');
      }
    } finally {
      setLoading(false);
    }
  }

  function handleCloseActiveModal() {
    setActiveModal({ show: '', data: null });
  }

  function handleSendMessage() {
    dispatch(
      setChatState({
        lar_id: id,
        recipient_role: larData.team.borrower.role,
        type: 'sidebar',
        variant: 'messagesList',
      }),
    );
  }

  function goToDashboard() {
    history.push('/');
  }

  if (isEmpty(larData)) {
    return (
      <div className={styles.wrapper}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1 className={styles.title}>{isFromBorrower ? 'Loan request summary and next steps' : 'Next steps'}</h1>
        {isFromBorrower && (
          <p className={styles.subtitle}>
            This deal will appear in the Scenario pipeline with a status “Pending Review” until you decide what to do
            next.
          </p>
        )}
        <div className={styles.mainContent}>
          <ul className={styles.descriptionList}>
            <li className={styles.descriptionListItem}>
              Deal ID: <span>{larData.id}</span>
            </li>
            <li className={styles.descriptionListItem}>
              Opportunity: <span>{larData.loan_term_name}</span>
            </li>
            <li className={styles.descriptionListItem}>
              Created by:{' '}
              <span>
                {larData.team.creator.role} {larData.team.creator.full_name}
              </span>
            </li>
            <li className={styles.descriptionListItem}>
              Purpose: <span>{larData.loan_purpose_name}</span>
            </li>
            <li className={styles.descriptionListItem}>
              Address:{' '}
              <span>
                {larData.property_address}, {larData.property_city}, {larData.property_state}, {larData.property_zip}
              </span>
            </li>
          </ul>
          <div className={styles.contacts}>
            <div className={styles.contactsInfo}>
              <span className={styles.contactsPosition}>Borrower</span>
              <h2 className={styles.contactsName}>
                {larData.main_borrower_first_name} {larData.main_borrower_last_name}
              </h2>
              <div className={styles.contactsLinkWrapper}>
                <MailIcon color="grey-7" size="18" />
                <a className={styles.contactsLink} href="mailto:john@mortgagewrld.com">
                  {larData.main_borrower_email}
                </a>
              </div>
              <div className={styles.contactsLinkWrapper}>
                <PhoneIcon color="grey-7" size="18" />
                <a className={styles.contactsLink} href={`tel:${larData.main_borrower_phone}`}>
                  {larData.main_borrower_phone}
                </a>
              </div>
            </div>
            {larData.team.borrower.role !== 'Prospect borrower' && (
              <Button size="large" icon={<MessageIcon />} onClick={handleSendMessage}>
                Send message
              </Button>
            )}
          </div>
        </div>
        <p>What do you want to do?</p>
        <div className={styles.buttons}>
          <Button loading={scenarioLoading} size="large" type="dark" onClick={handleSubmitScenario}>
            Begin Scenario
          </Button>
          {isAuction && (
            <Button size="large" type="dark" onClick={handleSchedulingAuction} loading={loading}>
              Schedule Auction
            </Button>
          )}
          <Button size="large" onClick={handleEditApp}>
            Edit Application
          </Button>
          <Button size="large" onClick={handleExit}>
            Exit
          </Button>
        </div>
        {!isAuction && (
          <div className={styles.footer}>
            <InfoTriangleIcon />
            <p className={styles.footerText}>
              Additional information is required to schedule a Loan Auction.You can select Edit Application to return to
              the application.
            </p>
          </div>
        )}
      </div>
      <EndScenarioModal
        lar_id={id}
        visible={activeModal.show === 'scenario_submit'}
        onCloseActiveModal={handleCloseActiveModal}
        activeModal={activeModal}
      />
      <Modal
        title={activeModal.show === 'lar_review' ? 'Auction Scheduling' : 'Scenario Beginning'}
        open={['lar_review', 'scenario_review'].includes(activeModal.show)}
        onCancel={handleCloseActiveModal}
        footer={[
          <Button key="submit" type="dark" size="large" onClick={goToDashboard}>
            Go to dashboard
          </Button>,
        ]}
      >
        <p>
          We have some questions about this loan request. A Starport Account Manager will contact you shortly to discuss
          them.
        </p>
        <p>The Deal ID {id} remains on your dashboard.</p>
        <p>
          If you prefer to contact us, you can send an email to{' '}
          <a href="mailto:support@starportco.com.">support@starportco.com.</a>
        </p>
        <p>
          Thank you,
          <br />- The Starport Team
        </p>
      </Modal>
    </div>
  );
}

export default LarAppConfirm;
