import { memo } from 'react';

export default memo(({ size = '1.6em', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <rect width="32" height="32" rx="16" fill="#D9F7BE" />
    <g clipPath="url(#clip0_2105_113496)">
      <path d="M9 16L14 21L24 11" stroke="#52C41A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2105_113496">
        <rect width="24" height="24" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
));
