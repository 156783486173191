import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo(({ direction, ...params }) => (
  <GenericIcon {...params} transform={`scale(${direction === 'right' ? -1 : 1},1)`}>
    <path d="M4 12H14" />
    <path d="M4 12L8 16" />
    <path d="M4 12L8 8" />
    <path d="M20 4V20" />
  </GenericIcon>
));
