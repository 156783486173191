import { Input } from 'antd';
import AutoCompleteTextInput from 'components/UI/AutoCompleteTextInput';
import PhoneComponent from 'components/CustomComponents/PhoneInput';
import regExp from 'constants/regExp';

export default function QuestionFormItem({
  disabled,
  editFields,
  idx,
  question_answer,
  question_type,
  question,
  questionsArray,
  setAnswer,
}) {
  const inputValue = editFields.question_answers
    ? editFields.question_answers[`question_answer_${idx + 1}`]
    : question_answer;

  function setSelectedAddress(address, _, state, zip) {
    const stateIdx = questionsArray.find((i) => i.question_type === 'state');
    const zipIdx = questionsArray.findIndex((i) => i.question_type === 'zip');

    if (stateIdx >= 0) {
      setAnswer(state.preview);
    }

    if (zipIdx >= 0) {
      setAnswer(zip);
    }

    setAnswer(address);
  }

  return (
    <div className="question-wrapper">
      <span>
        Question {idx + 1}:{question}
      </span>
      {(() => {
        switch (question_type) {
          case 'long_text':
            return (
              <Input.TextArea
                disabled={disabled}
                maxLength={250}
                onChange={(e) => setAnswer(e.target.value)}
                rows={3}
                type="text"
                value={inputValue}
              />
            );
          case 'address':
            return (
              <AutoCompleteTextInput
                disabled={disabled}
                onChange={(e) => setAnswer(e.target.value)}
                setSelectedAddress={setSelectedAddress}
                value={inputValue}
              />
            );
          case 'phone':
            return (
              <PhoneComponent
                disabled={disabled}
                onChange={(phone) => {
                  setAnswer(phone);
                }}
                value={inputValue}
              />
            );
          case 'number':
          case 'short_text':
          case 'email':
          case 'zip':
          case 'state':
          case 'currency':
          default:
            return (
              <Input
                addonBefore={question_type === 'currency' ? '$' : undefined}
                disabled={disabled}
                maxLength={250}
                onChange={(e) => {
                  if (question_type === 'number' || question_type === 'currency') {
                    if (regExp.only_number.test(e.target.value)) {
                      setAnswer(e.target.value);
                    }
                  } else if (question_type === 'zip') {
                    if (regExp.only_number.test(e.target.value) && e.target.value.length <= 5) {
                      setAnswer(e.target.value);
                    }
                  } else {
                    setAnswer(e.target.value);
                  }
                }}
                type="text"
                value={inputValue}
              />
            );
        }
      })()}
    </div>
  );
}
