import { FileGrayDownloadIcon } from '../../../assets/icons/icons';
import Button from '../../UI/Button';
import './pdf-load-error.css';
import PDFAddNew from '../PDFAddNew';

function PDFLoadError({ file, height, submitNewDocument }) {
  return (
    <div style={{ height }} className={`pdf-load-error ${file === 'new' ? 'add-new' : ''}`}>
      <FileGrayDownloadIcon />
      {file !== 'new' ? (
        <>
          {file && <b>{file.split('/').pop().split('?')[0]}</b>}
          <span>Preview is not available for this type of items</span>
          <Button onClick={() => window.open(file)} className="btn_darkblue" text="Download" />
        </>
      ) : (
        <PDFAddNew submitNewDocument={submitNewDocument} />
      )}
    </div>
  );
}

export default PDFLoadError;
