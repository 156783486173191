import { useMemo } from 'react';
import classNames from 'classnames';
import { Steps, Tooltip } from 'antd';
import classes from './FinishedAuctionsSteps.module.css';

function CustomDot({ dot }) {
  return <Tooltip>{dot}</Tooltip>;
}

function FinishedAuctionsSteps({ steps }) {
  const parseStepToArray = Object.entries(steps).map((el) => ({
    ...el[1],
    step: el[0],
  }));
  const isWinner = steps.decision.text === 'You won';

  const currentStep = useMemo(() => {
    let curr = 0;
    parseStepToArray.forEach((el) => {
      if (el.step === 'reviews' && el.done) {
        curr = 0;
      }
      if (el.step === 'sentToBorrower' && el.done) {
        curr = 1;
      }
      if (el.step === 'decision' && el.done) {
        curr = 2;
      }
    });

    return curr;
  }, [parseStepToArray]);

  return (
    <div className={classNames([classes.stepsWrapper, isWinner && classes.stepsWrapperWinner])}>
      <Steps
        current={currentStep}
        progressDot={(dot, { status, index }) => <CustomDot dot={dot} status={status} index={index} />}
        items={parseStepToArray}
      />
    </div>
  );
}

export default FinishedAuctionsSteps;
