import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import { DashboardAPI } from 'api';
import { getDashboardMetrics } from 'store/actions/dashboard';
import parseFromET from 'constants/parseFromET';

import styles from './AuctionPreviewTimer.module.css';

const DbAPI = new DashboardAPI();

export default function AuctionPreviewTimer({ className, data }) {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState('00:00:00');
  const [isBrowserActive, setIsBrowserActive] = useState(true);
  const nextStart = useMemo(() => parseFromET(data?.next_event_time), [data]);
  const lastActivityTimeRef = useRef(Date.now());

  useEffect(() => {
    const handleFocus = () => {
      // Browser window is in focus
      setIsBrowserActive(true);
      lastActivityTimeRef.current = Date.now();
    };

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // Page is active
        setIsBrowserActive(false);
        lastActivityTimeRef.current = Date.now();
      }
    };

    const handleUserActivity = () => {
      lastActivityTimeRef.current = Date.now();
    };

    const checkActivity = () => {
      const currentTime = Date.now();
      const timeDifference = currentTime - lastActivityTimeRef.current;

      if (timeDifference > 60000) {
        // 1 minute
        console.log('Browser might be inactive');
        setIsBrowserActive(false);
      }

      setTimeout(checkActivity, 60000); // Check every minute
    };

    window.addEventListener('focus', handleFocus);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    checkActivity();

    return () => {
      window.removeEventListener('focus', handleFocus);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [timer]);

  function getDashboardData() {
    if (timer === '00:00:00' || timer.includes('-')) {
      DbAPI.getMetrics().then((res) => dispatch(getDashboardMetrics(res)));
    }
  }

  useEffect(() => {
    getDashboardData();
  }, [timer, isBrowserActive]);

  useEffect(() => {
    const interval = setInterval(() => {
      const delta = nextStart.diff(moment.now());

      const h = Math.floor(delta / 3.6e6);
      const m = Math.floor((delta - h * 3.6e6) / 6e4);
      const s = Math.floor((delta - h * 3.6e6 - m * 6e4) / 1e3);

      setTimer([h, m, s].map((n) => n.toString().padStart(2, 0)).join(':'));
    }, 1e3);

    return () => clearInterval(interval);
  }, [nextStart]);

  return (
    <div className={classNames(styles.root, className)}>
      <div className={classNames(styles.timerLeft, data.color && styles[data.color])}>{data.event}</div>
      <div className={styles.timerTop}>
        <time className={styles.timerTime}>{timer.includes('-') ? '00:00:00' : timer}</time>
        {data.next_event}
      </div>
    </div>
  );
}
