import Modal from 'components/Modals/Modal/Modal';

export default function DisableUserModal({ onCloseActiveModal, onSubmit, visible, activeModal }) {
  return (
    <Modal
      onOk={() => onSubmit(activeModal.data?.id)}
      okText="Disable"
      onCancel={onCloseActiveModal}
      open={visible}
      title="Disable user"
    >
      Do you want to disable {activeModal.data?.full_name}
      ’s account? This user will not be able to access his account before you enable the account.
    </Modal>
  );
}
