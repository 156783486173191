import SectionTitle from 'components/OnBoarding/SectionTitle';
import ApiUsageTable from 'components/OnBoarding/SuperAdmin/Api/ApiUsageTable';

function ApiPage() {
  return (
    <div>
      <div className="section_title_wrapper">
        <SectionTitle title="API Usage" />
      </div>
      <div style={{ marginTop: '24px' }}>
        <ApiUsageTable />
      </div>
    </div>
  );
}
export default ApiPage;
