import { Col, Form, InputNumber, Row, Select } from 'antd';
import { useContext } from 'react';
import numberToComma from 'constants/numberToComma';
import LarAppContext from '../../../context/LarAppContext';

export default function BorrowerFundsMain({ total }) {
  const { funds } = useContext(LarAppContext);

  return (
    <>
      {funds?.map((el) => (
        <Form.Item label={el.source} key={el.id}>
          <Form.Item
            initialValue={el.amount || null}
            name={[`${el.source}`, 'funds_amount']}
            style={{
              display: 'inline-block',
              marginBottom: 0,
            }}
          >
            <InputNumber
              addonBefore="$"
              formatter={(v) => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              min={0}
              max={99999999}
              parser={(v) => v.replace(/\$\s?|(,*)/g, '')}
              style={{ width: 215 }}
            />
          </Form.Item>

          <Form.Item
            initialValue={el.individual || 'individual'}
            name={[`${el.source}`, 'funds_individual']}
            style={{
              display: 'inline-block',
              marginBottom: 0,
              marginLeft: 30,
            }}
          >
            <Select
              options={[
                { value: 'individual', label: 'Individual' },
                { value: 'entity', label: 'Entity' },
              ]}
              style={{ width: 215 }}
            />
          </Form.Item>

          {/* This seems like a weird hack to pass entry id to the form */}
          <Form.Item
            initialValue={el.id}
            name={[`${el.source}`, 'id']}
            style={{
              display: 'none',
            }}
          >
            <InputNumber style={{ width: 215 }} addonBefore="$" />
          </Form.Item>
        </Form.Item>
      ))}
      <Row align="middle">
        <Col span={6}>
          <span>
            <b>Total:</b>
          </span>
        </Col>
        <Col span={16} offset={1}>
          <div style={{ background: '#F5F5F5', padding: 8, maxWidth: 215 }}>
            <b>${numberToComma(total)}</b>
          </div>
        </Col>
      </Row>
    </>
  );
}
