import './styles/lar_creation.css';
import { dropDownSearchValue } from 'constants/dropDownSearchValue';
import { message } from 'antd';
import { numberToCurrency } from 'constants/numberToComma';
import { useEffect, useState } from 'react';
import API from 'api';
import Button from 'components/UI/Button';
import DropdownMenu from 'components/CustomComponents/DropdownMenu';
import LarCreationApplicationForm from 'components/OnBoarding/SuperAdmin/LarCreationApplicationForm';

const minLoanAmountOptions = Array.from({ length: 5 }, (_, i) => ({
  value: (i + 2) * 50000,
  preview: numberToCurrency((i + 2) * 50000),
}));
const maxLoanAmountOptions = [
  ...Array.from({ length: 97 }, (_, i) => ({
    value: (i + 3) * 1000000,
    preview: numberToCurrency((i + 3) * 1000000),
  })),
  {
    value: 99999999,
    preview: numberToCurrency(99999999),
  },
];
const maxLiveLARS = Array.from({ length: 100 }, (_, i) => ({
  value: i + 1,
  preview: i + 1,
}));

const closingCostOptions = Array.from({ length: 8 }, (_, i) => ({ value: i, preview: `${i}%` }));
// const monthRevervesOptions = Array.from({length: 10}, (_, i) => ({value: (i + 1) + 2, preview: (i + 1) + 2}))
const interestOptions = Array.from({ length: 25 }, (_, i) => ({
  value: (i + 1) / 2 + 2.5,
  preview: `${(i + 1) / 2 + 2.5}%`,
}));
const amortizationOptions = [
  { preview: '10', value: '10' },
  { preview: '15', value: '15' },
  { preview: '20', value: '20' },
  { preview: '25', value: '25' },
  { preview: '30', value: '30' },
  { preview: 'Interest only', value: 'interest only' },
  { preview: 'Self-amortizing', value: 'self-amortizing' },
];
const monthOptions = Array.from({ length: 13 }, (_, i) => ({ value: i, preview: i }));
const ltcLtvOptions = Array.from({ length: 9 }, (_, i) => ({
  preview: `${(i + 10) * 5}%`,
  value: (i + 10) * 5,
}));
const ltvRefiOptions = Array.from({ length: 9 }, (_, i) => ({
  preview: `${(i + 1) * 5 + 45}%`,
  value: (i + 1) * 5 + 45,
}));

export default function LARCreation() {
  const [application, setApplication] = useState({ loading: false });

  useEffect(() => {
    API()
      .get('/superadmin/settings/LAR')
      .then((res) => {
        const {
          minimum_loan_amount,
          refinance_LTV,
          refinance_closing_cost,
          refinance_months_of_reserves,
          fix_to_flip_LTC,
          fix_to_flip_LTV,
          fix_to_flip_amortization,
          fix_to_flip_closing_cost,
          fix_to_flip_interest_rate,
          fix_to_flip_months_of_reserves,
          rental_LTV,
          rental_amortization,
          rental_closing_cost,
          rental_interest_rate,
          rental_months_of_reserves,
          maximum_active_lars,
          maximum_loan_amount,
        } = res.data.application_settings;

        const minimum_loan_amount_preview = dropDownSearchValue(minLoanAmountOptions, minimum_loan_amount);
        const refinance_LTV_preview = dropDownSearchValue(ltvRefiOptions, refinance_LTV) ?? {
          value: refinance_LTV,
          preview: `${refinance_LTV}%`,
        };
        const refinance_closing_cost_preview = dropDownSearchValue(closingCostOptions, refinance_closing_cost);
        const refinance_months_of_reserves_preview = dropDownSearchValue(monthOptions, refinance_months_of_reserves);
        const fix_to_flip_LTC_preview = dropDownSearchValue(ltcLtvOptions, fix_to_flip_LTC);
        const fix_to_flip_LTV_preview = dropDownSearchValue(ltcLtvOptions, fix_to_flip_LTV);
        const fix_to_flip_amortization_preview = dropDownSearchValue(amortizationOptions, fix_to_flip_amortization);
        const fix_to_flip_closing_cost_preview = dropDownSearchValue(closingCostOptions, fix_to_flip_closing_cost);
        const fix_to_flip_interest_rate_preview = dropDownSearchValue(interestOptions, fix_to_flip_interest_rate);
        const fix_to_flip_months_of_reserves_preview = dropDownSearchValue(
          monthOptions,
          fix_to_flip_months_of_reserves,
        );
        const rental_LTV_preview = dropDownSearchValue(ltcLtvOptions, rental_LTV);
        const rental_amortization_preview = dropDownSearchValue(amortizationOptions, rental_amortization);
        const rental_closing_cost_preview = dropDownSearchValue(closingCostOptions, rental_closing_cost);
        const rental_interest_rate_preview = dropDownSearchValue(interestOptions, rental_interest_rate);
        const rental_months_of_reserves_preview = dropDownSearchValue(monthOptions, rental_months_of_reserves);
        const maximum_active_lars_preview = dropDownSearchValue(maxLiveLARS, maximum_active_lars);
        const maximum_loan_amount_preview = dropDownSearchValue(maxLoanAmountOptions, maximum_loan_amount);

        setApplication({
          loading: true,
          minimum_loan_amount: {
            value: minimum_loan_amount_preview ?? null,
            error: false,
            errorMessage: 'Select the option',
          },
          refinance_LTV: {
            value: refinance_LTV_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          refinance_closing_cost: {
            value: refinance_closing_cost_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          refinance_months_of_reserves: {
            value: refinance_months_of_reserves_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          fix_to_flip_LTC: {
            value: fix_to_flip_LTC_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          fix_to_flip_LTV: {
            value: fix_to_flip_LTV_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          fix_to_flip_amortization: {
            value: fix_to_flip_amortization_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          fix_to_flip_closing_cost: {
            value: fix_to_flip_closing_cost_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          fix_to_flip_interest_rate: {
            value: fix_to_flip_interest_rate_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          fix_to_flip_months_of_reserves: {
            value: fix_to_flip_months_of_reserves_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          rental_LTV: {
            value: rental_LTV_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          rental_amortization: {
            value: rental_amortization_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          rental_closing_cost: {
            value: rental_closing_cost_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          rental_interest_rate: {
            value: rental_interest_rate_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          rental_months_of_reserves: {
            value: rental_months_of_reserves_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          maximum_active_lars: {
            value: maximum_active_lars_preview,
            error: false,
            errorMessage: 'Select the option',
          },
          maximum_loan_amount: {
            value: maximum_loan_amount_preview,
            error: false,
            errorMessage: 'Select the option',
          },
        });
      });
  }, []);

  const onSubmitSummary = (e) => {
    e.preventDefault();
    let error = false;

    Object.entries(application).forEach((i) => {
      if (i[1] instanceof Object) {
        if (i[1].value instanceof Object) {
          if (!(i[1]?.value?.preview).toString()) {
            setApplication((prevState) => ({
              ...prevState,
              [i[0]]: {
                ...prevState[i[0]],
                error: true,
              },
            }));
            error = true;
          }
        } else if (!i[1]?.value?.toString()) {
          setApplication((prevState) => ({
            ...prevState,
            [i[0]]: {
              ...prevState[i[0]],
              error: true,
            },
          }));
          error = true;
        }
      }
    });

    if (error) {
      return;
    }

    const reqObj = {
      fix_to_flip_LTC: application.fix_to_flip_LTC.value.value,
      fix_to_flip_LTV: application.fix_to_flip_LTV.value.value,
      fix_to_flip_amortization: application.fix_to_flip_amortization.value.value,
      fix_to_flip_closing_cost: application.fix_to_flip_closing_cost.value.value,
      fix_to_flip_interest_rate: application.fix_to_flip_interest_rate.value.value,
      fix_to_flip_months_of_reserves: application.fix_to_flip_months_of_reserves.value.value,
      minimum_loan_amount: application.minimum_loan_amount.value.value,
      refinance_LTV: application.refinance_LTV.value.value,
      refinance_closing_cost: application.refinance_closing_cost.value.value,
      refinance_months_of_reserves: application.refinance_months_of_reserves.value.value,
      rental_LTV: application.rental_LTV.value.value,
      rental_amortization: application.rental_amortization.value.value,
      rental_closing_cost: application.rental_closing_cost.value.value,
      rental_interest_rate: application.rental_interest_rate.value.value,
      rental_months_of_reserves: application.rental_months_of_reserves.value.value,
      maximum_active_lars: application.maximum_active_lars.value.value,
      maximum_loan_amount: application.maximum_loan_amount.value.value,
    };

    API()
      .post('/superadmin/settings/LAR-application', reqObj)
      .then(() => {
        message.success('You successfully updated application setting');
      });
  };

  const onSelectApplication = (value, name) => {
    setApplication((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
        error: false,
      },
    }));
  };

  return (
    <div className="lar_creation">
      <form className="lar_summary" onSubmit={onSubmitSummary}>
        <div className="lar_application">
          <p className="section_title">LAR Application</p>
          <DropdownMenu
            title="Minimum loan amount"
            className="bordered"
            options={minLoanAmountOptions}
            error={application.minimum_loan_amount?.error}
            errorMessage={application.minimum_loan_amount?.errorMessage}
            onSelect={(val) => onSelectApplication(val, 'minimum_loan_amount')}
            value={application.loading ? application?.minimum_loan_amount?.value?.preview : ''}
          />
          <DropdownMenu
            title="Maximum loan amount"
            className="bordered"
            options={maxLoanAmountOptions}
            error={application.maximum_loan_amount?.error}
            errorMessage={application.maximum_loan_amount?.errorMessage}
            onSelect={(val) => onSelectApplication(val, 'maximum_loan_amount')}
            value={application.loading ? application?.maximum_loan_amount?.value?.preview : ''}
          />
          <DropdownMenu
            title={"Maximum number of live LAR's"}
            className="bordered"
            options={maxLiveLARS}
            error={application.maximum_active_lars?.error}
            errorMessage={application.maximum_active_lars?.errorMessage}
            onSelect={(val) => onSelectApplication(val, 'maximum_active_lars')}
            value={application.loading ? application?.maximum_active_lars?.value?.preview : ''}
          />
        </div>
        <div className="refinance_summary">
          <p className="section_title">Long Term Refinance</p>
          <DropdownMenu
            className="bordered"
            error={application.refinance_LTV?.error}
            errorMessage={application.refinance_LTV?.errorMessage}
            onSelect={(val) => onSelectApplication(val, 'refinance_LTV')}
            options={ltvRefiOptions}
            title="LTV"
            value={application.loading ? application?.refinance_LTV?.value?.preview : ''}
          />
          <DropdownMenu
            title="Closing cost (% of loan amount)"
            className="bordered"
            error={application.refinance_closing_cost?.error}
            errorMessage={application.refinance_closing_cost?.errorMessage}
            options={closingCostOptions}
            onSelect={(val) => onSelectApplication(val, 'refinance_closing_cost')}
            value={application.loading ? application?.refinance_closing_cost?.value?.preview : ''}
          />
          <DropdownMenu
            title="Months of Reserves Required"
            className="bordered"
            error={application.refinance_months_of_reserves?.error}
            errorMessage={application.refinance_months_of_reserves?.errorMessage}
            options={monthOptions}
            onSelect={(val) => onSelectApplication(val, 'refinance_months_of_reserves')}
            value={application.loading ? application?.refinance_months_of_reserves?.value?.preview : ''}
          />
        </div>
        <LarCreationApplicationForm
          title="Short term"
          monthOptions={monthOptions}
          interest={
            application.loading
              ? {
                  val: application?.fix_to_flip_interest_rate?.value?.preview,
                  error: application.fix_to_flip_interest_rate.error,
                  errorMessage: application.fix_to_flip_interest_rate.errorMessage,
                  name: 'fix_to_flip_interest_rate',
                }
              : null
          }
          amortization={
            application.loading
              ? {
                  val: application?.fix_to_flip_amortization?.value?.preview,
                  error: application.fix_to_flip_amortization.error,
                  errorMessage: application.fix_to_flip_amortization.errorMessage,
                  name: 'fix_to_flip_amortization',
                }
              : null
          }
          cost={
            application.loading
              ? {
                  val: application?.fix_to_flip_closing_cost?.value?.preview,
                  error: application.fix_to_flip_closing_cost.error,
                  errorMessage: application.fix_to_flip_closing_cost.errorMessage,
                  name: 'fix_to_flip_closing_cost',
                }
              : null
          }
          ltv={
            application.loading
              ? {
                  val: application?.fix_to_flip_LTV?.value?.preview,
                  error: application.fix_to_flip_LTV.error,
                  errorMessage: application.fix_to_flip_LTV.errorMessage,
                  name: 'fix_to_flip_LTV',
                }
              : null
          }
          ltc={
            application.loading
              ? {
                  val: application?.fix_to_flip_LTC?.value?.preview,
                  error: application.fix_to_flip_LTC.error,
                  errorMessage: application.fix_to_flip_LTC.errorMessage,
                  name: 'fix_to_flip_LTC',
                }
              : null
          }
          month={
            application.loading
              ? {
                  val: application?.fix_to_flip_months_of_reserves?.value?.preview,
                  error: application.fix_to_flip_months_of_reserves.error,
                  errorMessage: application.fix_to_flip_months_of_reserves.errorMessage,
                  name: 'fix_to_flip_months_of_reserves',
                }
              : null
          }
          ltcLtvOptions={ltcLtvOptions}
          data={application}
          onSelectApplication={onSelectApplication}
          closingCostOptions={closingCostOptions}
          amortizationOptions={amortizationOptions}
          interestOptions={interestOptions}
        />
        <LarCreationApplicationForm
          title="Long term rental"
          monthOptions={monthOptions}
          ltcLtvOptions={ltcLtvOptions}
          closingCostOptions={closingCostOptions}
          onSelectApplication={onSelectApplication}
          interest={
            application.loading
              ? {
                  val: application?.rental_interest_rate?.value?.preview,
                  error: application.rental_interest_rate.error,
                  errorMessage: application.rental_interest_rate.errorMessage,
                  name: 'rental_interest_rate',
                }
              : null
          }
          amortization={
            application.loading
              ? {
                  val: application?.rental_amortization?.value?.preview,
                  error: application.rental_amortization.error,
                  errorMessage: application.rental_amortization.errorMessage,
                  name: 'rental_amortization',
                }
              : null
          }
          cost={
            application.loading
              ? {
                  val: application?.rental_closing_cost?.value?.preview,
                  error: application.rental_closing_cost.error,
                  errorMessage: application.rental_closing_cost.errorMessage,
                  name: 'rental_closing_cost',
                }
              : null
          }
          ltv={
            application.loading
              ? {
                  val: application?.rental_LTV?.value?.preview,
                  error: application.rental_LTV.error,
                  errorMessage: application.rental_LTV.errorMessage,
                  name: 'rental_LTV',
                }
              : null
          }
          month={
            application.loading
              ? {
                  val: application?.rental_months_of_reserves?.value?.preview,
                  error: application.rental_months_of_reserves.error,
                  errorMessage: application.rental_months_of_reserves.errorMessage,
                  name: 'rental_months_of_reserves',
                }
              : null
          }
          amortizationOptions={amortizationOptions}
          interestOptions={interestOptions}
        />
        <Button text="Save" type="submit" className="btn_darkblue submit" />
      </form>
    </div>
  );
}
