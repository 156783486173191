function NoResults() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      <p>There are no available loan programs that meet your scenario</p>
      <p>You can revise the scenario to change the results</p>
    </div>
  );
}

export default NoResults;
