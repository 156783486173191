import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo(({ direction, ...params }) => {
  const transform = {
    up: 'scale(1, -1)',
    down: 'scale(1, 1)',
    left: 'rotate(90)',
    right: 'rotate(270)',
  }[direction];

  return (
    <GenericIcon {...params} transform={transform}>
      <path d="M6 9L12 15L18 9" />
    </GenericIcon>
  );
});
