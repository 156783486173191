import { Button, Result } from 'antd';
import styles from './ErrorBoundary.module.css';

function CustomErrorBoundary({ error, resetError }) {
  return (
    <Result
      className={styles.wrapper}
      status="error"
      title="Something went wrong"
      subTitle={error.message}
      extra={[
        <Button type="dark" size="large" onClick={resetError}>
          Try again
        </Button>,
      ]}
    />
  );
}

export default CustomErrorBoundary;
