import { Modal, Row } from 'antd';
import { BidTable } from '../DiligenceItemPopup/flavours';
import CustomButton from '../../../UI/Button';

function FinalUnderwritingPreview({ visible, closeActiveModal, submit = () => null, ...restProps }) {
  return (
    <Modal
      centered
      className="diligence-document-view"
      destroyOnClose
      footer={
        <Row align="middle" justify="end" wrap={false} style={{ columnGap: 16 }}>
          <CustomButton key="back" onClick={closeActiveModal} text="Cancel" />
          <CustomButton key="submit" className="btn_darkblue" disabled={false} onClick={submit} text="Confirm" />
        </Row>
      }
      onCancel={closeActiveModal}
      title="Please confirm final underwriting"
      open={visible}
      width={670}
    >
      <BidTable isPreview {...restProps} diligenceMode />
    </Modal>
  );
}
export default FinalUnderwritingPreview;
