import React from 'react';
import { Select } from 'antd';

function SelectWrapper(props) {
  const { value, options, title, disabled, error, errorMessage, className, onChange, size, showSearch, placeholder } =
    props;

  return (
    <div
      className={`${className} ${error ? 'input_error' : ''} select-wrapper drop_down_wrapper ${
        disabled ? 'drop_down_wrapper_disabled' : ''
      }`}
    >
      {title && <span className="drop_down_wrapper__title">{title}</span>}
      <Select
        showSearch={showSearch}
        size={size}
        onChange={onChange}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
      >
        {options.map((i) => (
          <Select.Option value={i.value} key={i.value}>
            {i.preview}
          </Select.Option>
        ))}
      </Select>
      {error && <p className="errorMessage">{errorMessage}</p>}
    </div>
  );
}

export default SelectWrapper;
