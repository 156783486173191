import { Col, Row, Tooltip, Typography } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import ChatIndicator from 'components/UI/ChatIndicator';
import styles from './ScenarioNavItem.module.css';

export default function ScenarioNavItem({ data, larName, isActive, onClick }) {
  const role = useSelector((s) => s.user.userData?.role);
  const isLender = /lender/i.test(role);
  const status = data?.bid_status ? data.bid_status?.name : data?.status;

  return (
    <div className={classNames(styles.root, { [styles.active]: isActive })} onClick={onClick}>
      <div className={styles.header}>
        <Typography.Title className={styles.title} level={5}>
          #{data?.id}, {data?.scenario?.name || larName}
        </Typography.Title>
        {data.updated_at && (
          <Typography.Text className={styles.date}>
            {moment(data.updated_at).format('hh:mm A, D MMM YY')}
          </Typography.Text>
        )}
      </div>
      <Row justify="space-between" gutter={10}>
        <Col>
          {data?.scenario?.broker_full_name ? (
            <Typography.Text>{data?.scenario?.broker_full_name}</Typography.Text>
          ) : (
            <Typography.Text>Lender {data?.number}</Typography.Text>
          )}
        </Col>
      </Row>
      <Row justify="space-between" align="middle" gutter={10}>
        <Col>
          {!isLender && (
            <Tooltip
              title={
                status === 'Quote Requested' &&
                data.quote_provided_date &&
                moment(data.quote_provided_date).format('D MMM YYYY')
              }
            >
              <Typography.Text>{status}</Typography.Text>
            </Tooltip>
          )}
        </Col>
        <Col>
          <ChatIndicator
            type="secondary"
            isVisible={data?.scenario ? data?.scenario?.chat_exist : data?.chat_exist}
            hasIndicator={data?.scenario ? data?.scenario?.new_messages : data?.new_messages}
          />
        </Col>
      </Row>
    </div>
  );
}

ScenarioNavItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  larName: PropTypes.string,
  onClick: PropTypes.func,
};
