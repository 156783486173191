import React from 'react';
import DropdownMenu from '../../CustomComponents/DropdownMenu';

function LarCreationApplicationForm(props) {
  const {
    title,
    interestOptions,
    ltc,
    amortizationOptions,
    closingCostOptions,
    monthOptions,
    ltcLtvOptions,
    amortization,
    onSelectApplication,
    interest,
    cost,
    ltv,
    month,
  } = props;

  return (
    <div className="acquisition_rental">
      <p className="section_title">{title}</p>
      <DropdownMenu
        title="Interest rate"
        className="bordered"
        options={interestOptions}
        error={interest?.error}
        errorMessage={interest?.errorMessage}
        onSelect={(e) => onSelectApplication(e, interest?.name)}
        value={interest?.val}
      />
      <DropdownMenu
        title="Amortization (years)"
        className="bordered"
        options={amortizationOptions}
        error={amortization?.error}
        errorMessage={amortization?.errorMessage}
        onSelect={(e) => onSelectApplication(e, amortization?.name)}
        value={amortization?.val}
      />
      <DropdownMenu
        title="Closing cost (% of loan amount)"
        className="bordered"
        options={closingCostOptions}
        error={cost?.error}
        errorMessage={cost?.errorMessage}
        onSelect={(e) => onSelectApplication(e, cost?.name)}
        value={cost?.val}
      />
      <DropdownMenu
        title="LTV"
        className="bordered"
        options={ltcLtvOptions}
        error={ltv?.error}
        errorMessage={ltv?.errorMessage}
        onSelect={(e) => onSelectApplication(e, ltv?.name)}
        value={ltv?.val}
      />
      {ltc ? (
        <DropdownMenu
          title="LTC"
          className="bordered"
          options={ltcLtvOptions}
          error={ltc?.error}
          errorMessage={ltc?.errorMessage}
          onSelect={(e) => onSelectApplication(e, ltc?.name)}
          value={ltc?.val}
        />
      ) : null}
      <DropdownMenu
        title="Months of Reserves Required"
        className="bordered"
        options={monthOptions}
        error={month?.error}
        errorMessage={month?.errorMessage}
        onSelect={(e) => onSelectApplication(e, month?.name)}
        value={month?.val}
      />
    </div>
  );
}

export default LarCreationApplicationForm;
