import { useContext, useEffect, useState } from 'react';
import { Divider, Form, Input, Row, Button } from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { PlusOutlined } from '@ant-design/icons';
import { BinIcon } from 'assets/icons';
import { useFieldsChangeNew } from '../../helpers/larHelpers';
import formSettings from '../../helpers/formSettings';
import LarAppContext from '../../context/LarAppContext';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './BusinessForm.module.css';

const formName = 'business';

export default function BusinessForm({ setSuccess, setSuccessLA, setProgress, setLAProgress, form, text, changeText }) {
  const { questions_for_lender } = useContext(LarAppContext);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [firstTextConvert, setFirstTextConvert] = useState(true);
  const [textLength, setTextLength] = useState(0);
  const MAX_LENGTH = 5000;

  const getSuccessStatus = () => true;

  useEffect(() => {
    if (text && firstTextConvert) {
      const contentBlock = htmlToDraft(text);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorStateTemp = EditorState.createWithContent(contentState);
        const textLength = editorStateTemp.getCurrentContent().getPlainText().length;
        setTextLength(textLength);
        setEditorState(editorStateTemp);
        setFirstTextConvert(false);
      }
    }
  }, [text, firstTextConvert]);

  const onChangeEditor = (state) => {
    const textLength = state.getCurrentContent().getPlainText().length;
    setTextLength(textLength);
    if (textLength < MAX_LENGTH) {
      setEditorState(state);
      const rawContentState = convertToRaw(state.getCurrentContent());
      const markup = draftToHtml(rawContentState, undefined, true);
      changeText(markup);
    }
  };

  const setSuccessStatus = (status = false) => {
    setSuccess(status);
    setProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const setSuccessStatusLA = (status = false) => {
    setSuccessLA(status);
    setLAProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const onSuccess = () => {
    form.submit();
  };

  const onReject = () => {};

  const onFieldsChange = useFieldsChangeNew(
    getSuccessStatus,
    setSuccessStatus,
    onSuccess,
    onReject,
    getSuccessStatus,
    setSuccessStatusLA,
  );

  // TODO: TEST SAVE DATA
  useEffect(() => {
    form.setFieldsValue({
      business_plan: text,
      questions_for_lender: questions_for_lender.map((el) => el.question),
    });
    onFieldsChange([1], [{ value: null }]);
    // eslint-disable-next-line
  }, []);

  return (
    <Form {...formSettings} form={form} name="business" onFieldsChange={onFieldsChange}>
      <div className={styles.subtitleWrapper}>
        <p>
          Use this section as an opportunity to provide lender’s with personalized information in addition to the data
          and facts contained in this loan request.
        </p>
        <p>Suggested Topics:</p>
        <ul>
          <li>
            The borrower’s experience, especially if they have made similar investments in this area or with this
            property type.
          </li>
          <li>The business plan for this investment property, including any renovation improvement plans.</li>
          <li>
            Explain how the borrower plans to repay the lender’s loan with a refinance, sale, or other capital event.
          </li>
        </ul>
      </div>
      <Form.Item label="" name="business_plan" className={styles.inputWrapper}>
        <Editor
          editorState={editorState}
          toolbarClassName={styles.toolbar}
          wrapperClassName={styles.editorWrapper}
          editorClassName={styles.editor}
          onEditorStateChange={onChangeEditor}
        />
        <span className={styles.length}>
          {textLength} /{MAX_LENGTH}
        </span>
      </Form.Item>
      <Divider />
      <div>
        <h3>
          <b>Questions to lender</b>
        </h3>
        <Form.List name="questions_for_lender">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }, i) => (
                <Form.Item label={i === 0 ? 'Ask the lender' : ' '} key={key}>
                  <Form.Item
                    style={{
                      display: 'inline-block',
                      marginBottom: 0,
                    }}
                    name={name}
                  >
                    <Input style={{ width: 405 }} placeholder="What's your question?" />
                  </Form.Item>
                  <Form.Item
                    style={{
                      display: 'inline-block',
                      marginBottom: 0,
                      marginLeft: 8,
                    }}
                  >
                    <BinIcon onClick={() => remove(name)} style={{ cursor: 'pointer' }} />
                  </Form.Item>
                </Form.Item>
              ))}
              <Row align="middle" justify="end">
                <Button onClick={() => add()} size="large" icon={<PlusOutlined size="large" />}>
                  Add question
                </Button>
              </Row>
            </>
          )}
        </Form.List>
      </div>
    </Form>
  );
}
