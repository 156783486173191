import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Select, Form, InputNumber, DatePicker, Row, message, Checkbox } from 'antd';
import classNames from 'classnames';

import { LeftArrowIcon } from 'assets/icons/icons';
import API from 'api';
import Loader from 'components/Loader';
import globalStyles from 'constants/globalStyles';
import moment from 'moment';
import numberToComma from 'constants/numberToComma';
import regExp from 'constants/regExp';
import TermsEditModal from './TermsEditModal/TermsEditModal';

import { getSelectOptions } from './PlaceBid.helpers';
import { financingTypes, incomeDocTypes, initialFormData, recourses } from './PlaceBid.constants';
import styles from './styles/PlaceBid.module.css';

function FormRowText({ label, value }) {
  return (
    <>
      <Col className="loan-bid-app__bid-form-label-col">
        <span>{label}</span>
      </Col>
      <Col>
        <div className="loan-bid-app__bid-form-value-text">{value}</div>
      </Col>
    </>
  );
}

function FormRowInputNumber({
  addonAfter = null,
  addonBefore = null,
  formData,
  label,
  max = null,
  name,
  onChange,
  rules = [],
  ...rest
}) {
  return (
    <>
      <Col className="loan-bid-app__bid-form-label-col">
        <span>{label}</span>
      </Col>
      <Col>
        <Form.Item rules={rules} name={name} initialValue={formData[name]}>
          <InputNumber
            addonAfter={addonAfter}
            addonBefore={addonBefore}
            className="loan-bid-app__bid-form-input"
            formatter={(value) => numberToComma(value)}
            max={max}
            min={0}
            name={name}
            onChange={(value) => onChange(value, name)}
            step={1000}
            style={{ width: '100%' }}
            value={formData[name]}
            {...rest}
          />
        </Form.Item>
      </Col>
    </>
  );
}

function FormRowSelect({ formData, label, name, onChange, options, rules = [] }) {
  return (
    <>
      <Col className="loan-bid-app__bid-form-label-col">
        <span>{label}</span>
      </Col>
      <Col>
        <Form.Item rules={rules} name={name} initialValue={formData[name]}>
          <Select name={name} onChange={(e) => onChange(e, name)} value={formData[name]} style={{ width: '100%' }}>
            {options.map((opt, optIdx) => (
              <Select.Option key={`${label}_${optIdx}`} value={opt.value}>
                {opt.preview || opt.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
}

function FormRowDatePicker({ formData, label, name, onChange, rules = [] }) {
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  }

  return (
    <>
      <Col className="loan-bid-app__bid-form-label-col">
        <span>{label}</span>
      </Col>
      <Col>
        <Form.Item
          initialValue={formData[name] ? moment(formData[name], ['YYYY-DD-MM', 'YY-DD-MM']) : ''}
          name={name}
          rules={rules}
        >
          <DatePicker
            disabledDate={disabledDate}
            format={['YYYY-DD-MM', 'YY-DD-MM']}
            onChange={onChange}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    </>
  );
}

function FormRowTextArea({ formData, label, name, onChange, uploadProps }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Col>
        <span style={{ marginRight: 10 }}>{label}</span>
      </Col>
      <Col>
        <div
          className={styles.additionalTermsText}
          dangerouslySetInnerHTML={{ __html: formData.additional_terms_and_comment }}
        />
        <button className={styles.additionalTermsButton} onClick={() => setModalOpen((prev) => !prev)} type="button">
          Edit
        </button>
      </Col>
      <TermsEditModal
        name={name}
        onCancel={() => setModalOpen(false)}
        onOk={onChange}
        open={modalOpen}
        value={formData.additional_terms_and_comment}
        uploadProps={uploadProps}
      />
    </>
  );
}

export default function PlaceBid({
  drawerType = false,
  openChooseBidDrawer,
  onPlaceBid = () => null,
  onCloseActiveDrawer,
  edit = false,
  handlePreviewBid = () => null,
  activeDrawer,
  title = true,
  diligenceItemId,
  diligenceMode = false,
  setPlaceBidEdit,
  getDataOnRender = true,
  bids = [],
  placeBidUrl = '/LAR/bid_process/lender/place_bid',
  larData = {},
}) {
  const params = useParams();
  const history = useHistory();
  const [formInstance] = Form.useForm();
  const larId = params?.id;

  const [bidData, setBidData] = useState({ ...larData, loading: true });
  const [formData, setFormData] = useState({ ...initialFormData });
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [checkedList, setCheckedList] = useState([]);
  const [formDataSnapshot, setFormDataSnapshot] = useState({ ...initialFormData });

  useEffect(() => {
    const values = formInstance.getFieldsValue();
    const required = Object.keys(values).filter(
      (i) => !['application_costs_and_fees_due', 'max_to_loan_value', 'loan_to_cost', 'income_doc_type'].includes(i),
    );
    values.lender_processing_fee = 0;
    if (values.lender_processing_fee) {
      delete values.bank_fee;
    }
    if (values.bank_fee) {
      delete values.lender_processing_fee;
    }

    if (required.filter((i) => values[i] === null || values[i] === undefined).length > 0 || checkedList.length < 1) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [formInstance.getFieldsValue(), checkedList.length]);

  const isUserChanges = useMemo(
    () => JSON.stringify(formData) !== JSON.stringify(formDataSnapshot),
    [formData, formDataSnapshot],
  );

  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  function updateDraft(next) {
    const prev = JSON.parse(localStorage.getItem('iHarmoniBidAppData') || '{}');
    localStorage.setItem(
      'iHarmoniBidAppData',
      JSON.stringify({
        ...prev,
        [larId]: next,
      }),
    );
  }

  function handleInputNumberChange(value, name) {
    setFormData((prev) => {
      const next = {
        ...prev,
        [name]: value,
      };

      updateDraft(next);
      return next;
    });
  }

  function handleFormChange({ target: { value, name } }) {
    setFormData((prev) => {
      const next = {
        ...prev,
        [name]: value,
      };
      updateDraft(next);
      return next;
    });
  }

  function onChangeDate(dateString, name) {
    setFormData((prev) => {
      const next = {
        ...prev,
        [name]: dateString,
      };

      updateDraft(next);
      return next;
    });
  }

  function handleSelectChange(value, name) {
    setFormData((prev) => {
      const next = {
        ...prev,
        [name]: value,
      };

      updateDraft(next);
      return next;
    });
  }

  function handleNavBack() {
    if (drawerType) {
      openChooseBidDrawer(true);
    } else {
      history.push(`/bid_application/lar_id_${larId}/choose_bid`);
    }
  }

  function setAllFormData(data) {
    const localBidData = JSON.parse(localStorage.getItem('iHarmoniBidAppData')) || {};
    const localCurrBidData = localBidData[larId];
    delete localCurrBidData?.id;
    delete localCurrBidData?.broker_fee;
    setFormData({
      ...localCurrBidData,
      ...data,
    });
    setFormDataSnapshot(data);
  }

  function getBidData() {
    setBidData((prev) => ({
      ...prev,
      loading: true,
    }));

    API()
      .get('/LAR/bid_process/lender/place_bid', { params: { lar_id: larId } })
      .then((res) => {
        const data = res.data.bid_data;

        const validClosingDate = ['< 20 days', '20-30 days', '31-45 days', '46-60 days', '> 61 days'];

        if (data.closing_date && !validClosingDate.includes(data.closing_date)) {
          data.closing_date_other = data.closing_date;
          data.closing_date = 'Choose a Date';
        }

        setAllFormData(data);

        setBidData({
          ...res.data,
          loading: false,
        });
      });
  }

  function getForm(loan_purpose, loan_term) {
    return [
      {
        type: 'select',
        label: 'Financing type',
        name: 'financing_type',
        value: '',
        options: getSelectOptions(financingTypes),
        rules: [{ required: true, message: 'Please select a financing type option.' }],
      },
      {
        type: 'text',
        label: 'Loan Type',
        value: loan_purpose,
        hide: loan_term !== 'Short Term',
      },
      {
        addonBefore: '$',
        label: 'Loan amount',
        max: 999999999,
        min: 1000,
        maxLength: 11, // 9 digits plus 2 commas
        name: 'loan_amount',
        type: 'inputNumber',
        rules: [{ required: true, message: 'Only numbers!', pattern: new RegExp(regExp.only_number) }],
        validation: regExp.two_number_one_decimal,
        value: '1000',
      },
      {
        type: 'select',
        label: 'Loan to value',
        name: 'max_to_loan_value',
        value: '',
        options: Array.from({ length: 9 }, (_, i) => ({
          value: i * 5 + 50,
          preview: `${i * 5 + 50}%`,
        })),
      },
      {
        type: 'select',
        label: 'Loan to cost',
        name: 'loan_to_cost',
        value: '',
        options: Array.from({ length: 8 }, (_, i) => ({
          value: i * 5 + 50,
          preview: `${i * 5 + 50}%`,
        })),
      },
      {
        type: 'select',
        label: 'Rate type',
        name: 'rate_type',
        value: '',
        options: [{ value: 'Fixed' }, { value: 'Variable' }, { value: 'Floating' }],
        rules: [{ required: true, message: 'Please select a rate type option.' }],
      },
      {
        type: 'select',
        label: 'Interest rate (%)',
        name: 'interest_rate',
        value: '',
        options: [
          {
            value: 'Choose an interest rate',
          },
          ...Array.from({ length: 65 }, (_, i) => ({
            value: i * 0.25 + 4,
            preview: `${i * 0.25 + 4}%`,
          })),
        ],
        rules: [{ required: true, message: 'Please select an interest rate option.' }],
        hide: !formData.rate_type || formData.rate_type === 'Floating',
      },
      {
        type: 'select',
        label: 'Floating rate index',
        name: 'variable_rate_index',
        value: '',
        options: [{ value: 'Prime' }, { value: 'SOFR' }],
        hide: !formData.rate_type || formData.rate_type !== 'Floating',
      },
      {
        addonBefore: '%',
        label: 'Rate over Index',
        max: 99.999,
        step: 0.001,
        formatter: (v) => (!v ? 0 : `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.').replace(/\.(?=\d{0,2}$)/g, '.')),
        maxLength: 6,
        name: 'rate_over_index',
        type: 'inputNumber',
        rules: [{ message: 'Only 3 decimals allowed', pattern: new RegExp(regExp.two_number_three_decimal) }],
        value: '',
        hide: !formData.rate_type || formData.rate_type !== 'Floating',
      },
      {
        addonBefore: '%',
        label: '',
        max: 99.999,
        step: 0.001,
        formatter: (v) => (!v ? 0 : `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.').replace(/\.(?=\d{0,2}$)/g, '.')),
        maxLength: 6,
        name: 'interest_rate_other',
        type: 'inputNumber',
        rules: [{ message: 'Only 3 decimals allowed', pattern: new RegExp(regExp.two_number_three_decimal) }],
        value: '',
        hide: formData.interest_rate !== 'Choose an interest rate',
      },
      {
        type: 'select',
        label: 'Arm option',
        name: 'arm_option',
        value: '',
        options: [{ value: '5/1' }, { value: '7/1' }, { value: '10/1' }],
        rules: [{ required: true, message: 'Please select an arm option.' }],
        hide: !formData.rate_type || formData.rate_type !== 'Variable',
      },
      {
        addonBefore: '$',
        label: 'Max. cash-out',
        max: 999999999,
        maxLength: 11, // 9 digits plus 2 commas
        name: 'maximum_cash_out_or_cash_due',
        type: 'inputNumber',
        rules: [{ required: true, message: 'Only numbers!', pattern: new RegExp(regExp.only_number) }],
        validation: regExp.two_number_one_decimal,
        value: '',
        hide: loan_purpose !== 'Refinance',
      },
      {
        type: 'select',
        label: 'Days to Close',
        name: 'closing_date',
        value: '',
        options:
          loan_term !== 'Short Term'
            ? [
                { value: '< 20 days' },
                { value: '20-30 days' },
                { value: '31-45 days' },
                { value: '46-60 days' },
                { value: '> 61 days' },
                { value: 'Choose a Date' },
              ]
            : [
                { value: '< 20 days' },
                { value: '20-30 days' },
                { value: '31-45 days' },
                { value: '46-60 days' },
                { value: 'Choose a Date' },
              ],
        rules: [{ required: true, message: 'Please select a days to close option.' }],
      },
      {
        label: 'Closing Date',
        type: 'date',
        name: 'closing_date_other',
        value: bidData?.closing_date || null,
        rules: [{ required: true, message: 'Choose closing date!' }],
        hide: formData.closing_date !== 'Choose a Date',
      },
      {
        type: 'select',
        label: 'Income doc. type',
        name: 'income_doc_type',
        onChange: (value, name) => {
          setFormData((prev) => {
            const next = {
              ...prev,
              DSCR: value === 'DSCR' ? prev.DSCR : null,
              [name]: value,
            };

            updateDraft(next);
            return next;
          });
        },
        value: '',
        options: getSelectOptions(incomeDocTypes),
      },
      {
        type: 'select',
        label: 'DSCR',
        name: 'DSCR',
        value: '',
        options: [{ value: 'Less than 1.0' }, { value: '1.0' }, { value: '1.1' }, { value: '1.2' }, { value: '1.3+' }],
        rules: [{ required: true, message: 'Please select a DSCR option.' }],
        hide: formData.income_doc_type !== 'DSCR',
      },
      {
        type: 'select',
        label: 'Loan term',
        name: 'loan_term',
        value: '',
        options:
          loan_term === 'Short Term'
            ? [
                { value: 6, preview: '6 Months' },
                { value: 12, preview: '12 Months' },
                { value: 18, preview: '18 Months' },
                { value: 24, preview: '24 Months' },
                { value: 30, preview: '30 Months' },
                { value: 36, preview: '36 Months' },
                { value: 48, preview: '48 Months' },
              ]
            : [
                { value: 5, preview: '5 Years' },
                { value: 7, preview: '7 Years' },
                { value: 10, preview: '10 Years' },
                { value: 15, preview: '15 Years' },
                { value: 20, preview: '20 Years' },
                { value: 25, preview: '25 Years' },
                { value: 30, preview: '30 Years' },
                { value: 35, preview: '35 Years' },
              ],
        rules: [{ required: true, message: 'Please select a loan term option.' }],
      },
      {
        type: 'select',
        label: 'Amortization',
        name: 'amortization',
        value: '',
        hide: loan_term === 'Short Term',
        options: [
          { value: 15, preview: '15 Years' },
          { value: 20, preview: '20 Years' },
          { value: 25, preview: '25 Years' },
          { value: 30, preview: '30 Years' },
          { value: 35, preview: '35 Years' },
          { value: 40, preview: '40 Years' },
        ],
        rules: [
          {
            required: true,
            message: 'Please select a amortization option.',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value >= getFieldValue('loan_term')) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Amortization must be greater than or equal to Loan Term'));
            },
          }),
        ],
      },
      {
        type: 'select',
        label: 'Recourse',
        name: 'recourse',
        value: '',
        options: getSelectOptions(recourses),
        rules: [{ required: true, message: 'Please select a recourse.' }],
      },
      {
        type: 'select',
        label: 'Payment type',
        name: 'payment_type',
        value: '',
        options: [{ value: 'P&I' }, { value: 'I/O' }],
        rules: [{ required: true, message: 'Please select a payment type.' }],
      },
      {
        type: 'select',
        label: 'Prepayment penalty',
        name: 'pre_payment_penalty',
        value: '',
        options:
          loan_term === 'Short Term'
            ? [{ value: '12 months' }, { value: '6 months' }, { value: 'None' }, { value: 'See additional terms' }]
            : [
                { value: '60 months' },
                { value: '48 months' },
                { value: '36 months' },
                { value: '24 months' },
                { value: '12 months' },
                { value: 'None' },
                { value: 'See additional terms' },
              ],
        rules: [{ required: true, message: 'Please select the prepayment penalty option.' }],
      },
      {
        type: 'select',
        label: 'Lender Fee %',
        name: 'bank_fee',
        value: '',
        options: Array.from({ length: 21 }, (_, i) => ({
          value: i * 0.25,
          preview: `${i * 0.25}%`,
        })),
        rules: [{ required: true, message: 'Please select a fee option.' }],
        hide: formData.lender_processing_fee > 0,
      },
      {
        addonBefore: '$',
        label: 'Lender fee as fixed amount',
        max: 9999,
        maxLength: 5,
        name: 'lender_processing_fee',
        type: 'inputNumber',
        rules: [{ message: 'Only numbers!', pattern: new RegExp(regExp.only_number) }],
        validation: regExp.two_number_one_decimal,
        value: '',
        hide: formData.bank_fee > 0,
      },
      {
        type: 'select',
        label: 'YSP Payment Available',
        name: 'ysp_payment_available',
        value: '',
        options: [
          { preview: 'Yes', value: true },
          { preview: 'No', value: false },
        ],
        rules: [{ required: true, message: 'Please select a YSP Payment Available field.' }],
      },
      {
        addonBefore: '$',
        max: 999999999,
        maxLength: 9,
        type: 'inputNumber',
        label: 'Application Costs and Fees Due',
        name: 'application_costs_and_fees_due',
        validation: regExp.two_number_one_decimal,
        value: '',
      },
      {
        type: 'textarea',
        label: 'Additional terms (optional)',
        name: 'additional_terms_and_comment',
        value: '',
      },
    ];
  }
  const form = getForm(bidData.loan_purpose, bidData.loan_term);
  useEffect(() => {
    if (formInstance.getFieldsValue().rate_type === 'Fixed') {
      formInstance.setFieldValue('interest_rate', '');
    }
    if (formInstance.getFieldsValue().rate_type === 'Variable') {
      formInstance.setFieldValue('interest_rate', '');
      formInstance.setFieldValue('arm_option', '');
    }
    if (formInstance.getFieldsValue().rate_type === 'Floating') {
      formInstance.setFieldValue('rate_over_index', 0);
      formInstance.setFieldValue('variable_rate_index', '');
    }
  }, [formInstance.getFieldsValue().rate_type]);
  function getPostData(formData) {
    const filteredFormData = { ...formData };

    ['bid_type', 'loans'].forEach((i) => delete filteredFormData[i]);
    for (const field in filteredFormData) {
      if (field.includes('_other')) {
        const fieldName = field.replace('_other', '');

        if (['Other', 'Choose a Date', 'Choose an interest rate'].includes(filteredFormData[fieldName])) {
          filteredFormData[fieldName] = filteredFormData[field];
        }

        delete filteredFormData[field];
      }
      if ([null, undefined, NaN].includes(filteredFormData[field])) {
        delete filteredFormData[field];
      }
    }

    if (bidData.loan_term === 'Short Term') {
      delete filteredFormData.amortization;
    }

    if (!filteredFormData.application_costs_and_fees_due) {
      filteredFormData.application_costs_and_fees_due = 0;
    }

    return filteredFormData;
  }

  function handlePlaceBid() {
    setLoading(true);
    const data = getPostData(formData);
    if (diligenceMode) {
      data.item_id = diligenceItemId;
    } else {
      data.lar_id = +larId;
    }

    API()
      .post(placeBidUrl, data)
      .then(() => {
        updateDraft({});

        if (drawerType || diligenceMode) {
          onPlaceBid();
        } else {
          history.push({
            pathname: `/onboarding/loan_profile/${larId}`,
            state: { pageBg: '#F5F5F5' },
          });
        }
      })
      .catch((error) => {
        if (error.response.data.error === 'bid deadline') {
          message.error('Bid Deadline');
        }
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (getDataOnRender) {
      getBidData();
    } else {
      if (edit) {
        setAllFormData(bids[0]);
      }

      setBidData((prev) => ({
        ...prev,
        loading: false,
      }));
    }

    return () => {
      setBidData({ loading: true });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="loan-bid-app__place-page">
      {bidData.loading ? (
        <div
          style={{
            position: 'absolute',
            width: '20%',
            height: '40%',
            top: '40%',
            left: '48%',
            zIndex: 10,
          }}
        >
          <Loader
            color={{
              '--bgColor': 'var(--main-blue-color)',
              '--size': '100px',
              '--wrapper_size': '100px',
            }}
          />
        </div>
      ) : (
        <div
          className={classNames({
            'loan-bid-app__place-page-inner_small': diligenceMode,
            'loan-bid-app__place-page-inner': true,
          })}
        >
          {title && (
            <>
              {(drawerType && activeDrawer?.data?.editMode) || (
                <div className="loan-bid-app__back-link" onClick={handleNavBack}>
                  <LeftArrowIcon style={{ color: globalStyles.main_blue_color }} />
                  <span className="loan-bid-app__back-link-text">Submit a quote</span>
                </div>
              )}
              <div className="loan-bid-app__title-block">
                {drawerType && activeDrawer?.data?.editMode ? (
                  <div className="loan-bid-app__title">Revise Quote</div>
                ) : (
                  <div className="loan-bid-app__title">
                    {bidData?.bid_data?.bid_type === 'public'
                      ? 'Visible'
                      : bidData?.bid_data?.bid_type === 'private'
                      ? 'Hidden'
                      : 'Unknown'}{' '}
                    bid
                  </div>
                )}
              </div>
            </>
          )}
          <Form
            form={formInstance}
            className={classNames({
              'loan-bid-app__bid-form_small-spacing': diligenceMode,
              'loan-bid-app__bid-form': true,
            })}
            onFinishFailed={(e) => console.log(e)}
            onFinish={
              diligenceMode
                ? () =>
                    handlePreviewBid({
                      bid_preview: getPostData(formData),
                      onSubmit: handlePlaceBid,
                    })
                : handlePlaceBid
            }
          >
            {form.map((el, idx) => {
              if (el.hide) {
                return null;
              }

              switch (el.type) {
                case 'text':
                  return <FormRowText key={idx} {...el} />;

                case 'inputNumber':
                  return (
                    <FormRowInputNumber {...el} formData={formData} key={idx} onChange={handleInputNumberChange} />
                  );

                case 'select':
                  return (
                    <FormRowSelect {...el} formData={formData} key={idx} onChange={el.onChange ?? handleSelectChange} />
                  );

                case 'date':
                  return (
                    <FormRowDatePicker
                      {...el}
                      formData={formData}
                      key={idx}
                      onChange={(_, d) => onChangeDate(d, 'closing_date_other')}
                    />
                  );

                case 'textarea':
                  return (
                    <FormRowTextArea
                      {...el}
                      formData={formData}
                      key={idx}
                      onChange={handleFormChange}
                      uploadProps={uploadProps}
                    />
                  );

                default:
                  return null;
              }
            })}
            <Form.Item
              name="loans"
              valuePropName="checked"
              onChange={(e) => {
                const name = e.target.id;
                const value = e.target.checked;
                handleFormChange({ target: { name, value } });
                setCheckedList((prev) => {
                  if (value) {
                    return [...prev, name];
                  }
                  return prev.filter((el) => el !== name);
                });
              }}
              rules={[{ required: true, message: 'Please check the checkbox' }]}
              style={{ gridColumnStart: 1, gridColumnEnd: 3, marginBottom: 10 }}
            >
              <Checkbox>
                Loans will be settled by our organization. We will not attempt to “broker” this loan to another lender.
              </Checkbox>
            </Form.Item>
            {diligenceMode ? (
              <>
                <div />
                <Row align="center" justify="end" style={{ width: '100%', marginTop: 24, columnGap: 16 }}>
                  {edit && <Button text="Cancel" onClick={() => setPlaceBidEdit(false)} />}
                  <Button disabled={!isUserChanges} type="submit" text={edit ? 'Edit' : 'Review'} primary />
                </Row>
              </>
            ) : (
              <Row justify="end" gutter={16} style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                <Col span={8}>
                  <Button
                    size="large"
                    onClick={drawerType ? () => onCloseActiveDrawer() : () => history.goBack()}
                    block
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={8} flex="auto">
                  <Button
                    block
                    size="large"
                    type="dark"
                    htmlType="submit"
                    disabled={!isUserChanges || isButtonDisabled}
                    loading={loading}
                  >
                    {edit || (drawerType && activeDrawer?.data?.editMode) ? 'Update quote' : 'Place a quote'}
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </div>
      )}
    </div>
  );
}
