import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo(({ ...params }) => (
  <GenericIcon {...params}>
    <path d="M4 21V8C4 7.20435 4.31607 6.44129 4.87868 5.87868C5.44129 5.31607 6.20435 5 7 5H17C17.7956 5 18.5587 5.31607 19.1213 5.87868C19.6839 6.44129 20 7.20435 20 8V14C20 14.7956 19.6839 15.5587 19.1213 16.1213C18.5587 16.6839 17.7956 17 17 17H8L4 21Z" />
    <path d="M10 11H14" />
    <path d="M12 9V13" />
  </GenericIcon>
));
