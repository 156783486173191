import { getFileIcon, humanFileSize } from 'utils/files';
import { Input, Upload } from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useState, useMemo } from 'react';
import Modal from 'components/Modals/Modal/Modal';
import regExp from 'constants/regExp';
import renderImage from 'constants/renderImage';
import Video from 'assets/images/Video.svg';
import SimpleButton from 'components/UI/SimpleButton';
import styles from './FileUploadModal.module.css';

function FileUploadModal({ acceptedMimes = [], initState, onSubmit, visible, ytAllowed = false, ...props }) {
  const [desc, setDesc] = useState('');
  const [fileList, setFileList] = useState([]);
  const [thumb, setThumb] = useState(null);
  const [yt, setYT] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (!isEmpty(fileList)) {
      if (fileList[0].url) {
        setThumb(fileList[0].url);
        return;
      }

      if (/^image\//.test(fileList[0].originFileObj.type)) {
        renderImage(fileList[0].originFileObj).then(setThumb);

        return;
      }

      if (/^video\//.test(fileList[0].originFileObj.type)) {
        setThumb(Video);
      }
    }
  }, [fileList]);

  useEffect(() => {
    initState.desc && setDesc(initState.desc);
    initState.file && setFileList([initState.file]);
    initState.yt && setYT(initState.yt);
  }, [initState]);

  useEffect(() => {
    if (visible) {
      return;
    }

    setDesc('');
    setFileList([]);
    setThumb(null);
    setYT('');
  }, [visible]);

  const validLink = useMemo(() => {
    if (yt.length < 1) {
      return true;
    }

    return regExp.youtube_link.test(yt);
  }, [yt]);

  const onUpload = ({ file }) => {
    if (file) {
      const fileName = file.name.split('.').slice(0, -1).join('.');
      const fileType = file.name.split('.').at(-1).toUpperCase();
      const fileSize = humanFileSize(file.size);
      setFileList([file]);
      setFileName(fileName);
      setFileType(fileType);
      setFileSize(fileSize);
    }
  };

  const onChangeFileName = (event) => {
    setFileName(event.target.value);
  };

  const onEditFileName = () => {
    setIsEditable((prev) => !prev);
  };

  const handleSubmit = () => {
    onSubmit(
      {
        desc,
        file: fileList[0],
        file_name: fileName,
        yt: validLink ? yt : '',
      },
      initState.i,
    );
  };

  return (
    <Modal
      onOk={handleSubmit}
      title={ytAllowed ? 'Upload video or photo' : 'Upload document'}
      open={visible}
      wrapClassName={styles.root}
      {...props}
    >
      {isEmpty(fileList) ? (
        !yt ? (
          <Upload.Dragger accept={acceptedMimes.join()} onChange={onUpload}>
            + Add file
          </Upload.Dragger>
        ) : null
      ) : (
        <>
          <div className={styles.thumb}>
            <picture>
              {thumb ? (
                <img src={thumb} alt={fileList[0].name} />
              ) : (
                <div className={styles.fileIcon}>{getFileIcon(fileType)}</div>
              )}
            </picture>
          </div>
          <p>
            You can change the file name and/or a description so the lenders know what to expect when they view this
          </p>
        </>
      )}
      {!isEmpty(fileList) && (
        <div className={styles.fileInfoWrapper}>
          <div className={styles.row}>
            <div className={styles.leftCol}>File Name</div>
            <div className={styles.rightCol}>
              <div className={styles.fileNameWrapper}>
                <Input onChange={onChangeFileName} value={fileName} disabled={!isEditable} />
                <SimpleButton text={isEditable ? 'Save' : 'Edit'} handleClick={onEditFileName} />
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.leftCol}>File Type</div>
            <div className={styles.rightCol}>{fileType}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.leftCol}>File Size</div>
            <div className={styles.rightCol}>{fileSize}</div>
          </div>
        </div>
      )}
      {ytAllowed && fileList.length < 1 && (
        <label>
          YouTube link
          <Input onChange={({ target: t }) => setYT(t.value)} value={yt} />
          {validLink || <span className={styles.errorMessage}>This link is incorrect!</span>}
        </label>
      )}
      <label>
        Description
        <Input.TextArea onChange={({ target: t }) => setDesc(t.value)} value={desc} />
      </label>
    </Modal>
  );
}

export default FileUploadModal;
