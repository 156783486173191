export const activityLogColumns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'date',
    sorter: (a, b) => a.created_at - b.created_at,
  },
  {
    title: 'Company',
    dataIndex: 'company_name',
    key: 'company',
  },
  {
    title: 'User',
    dataIndex: 'user_name',
    key: 'user',
  },
  {
    title: 'Event',
    dataIndex: 'event',
    key: 'event',
  },
];

export const conversationLogColumns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'date',
    sorter: (a, b) => a.created_at - b.created_at,
  },
  {
    title: 'From',
    dataIndex: 'sender_name',
    key: 'from',
  },
  {
    title: 'To',
    dataIndex: 'recipient_name',
    key: 'to',
  },
  {
    title: 'Event',
    dataIndex: 'event',
    key: 'event',
  },
];
