export const PDF = 'pdf';
export const PNG = 'png';
export const JPG = 'jpg';
export const DOC = 'doc';
export const DOCX = 'docx';
export const XLS = 'xls';
export const XLSX = 'xlsx';
export const PPT = 'ppt';
export const PPTX = 'pptx';
export const TXT = 'txt';
export const TXT_FILE = 'text/plain';

export const DOCS_LIST = [PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX, PNG, JPG];
