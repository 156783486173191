import '../Lender/styles/registration.css';
import { useDispatch, useSelector } from 'react-redux';
import { message as toast, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import API, { BDRAPI } from 'api';
import Button from 'components/UI/Button';
import fillError from 'constants/fillRegistrationErrors';
import fillFields from 'constants/fillRegistrationFields';
import otherConst from 'constants/otherConst';
import RegistrationSubHeader from 'components/Registration/RegistrationSubHeader';
import RenderInputs from 'components/RenderInputs';
import CustomButton from 'components/UI/Button';
import { closePopup } from '../../../store/actions/popupsActions';
import Modal from '../../../components/Modals/Modal/Modal';
import ManualEMSModal from '../../../components/Modals/ManualEMSModal';

function BrokerProfile({ isTeam }) {
  const [fields, setFields] = useState({
    sectionTitle_0: {
      title: 'Сreate Personal Profile',
      style: {
        fontWeight: 400,
      },
    },
    sideTitle_1: {
      title: 'Personal Information',
      bordered: true,
      borderTitleDistance: '16px',
    },
    dividedInputs_1: {
      style: {
        marginTop: '12px',
      },
      inputs: {
        name: {
          value: '',
          error: null,
          errorMessage: 'Fill this label',
          title: 'Name',
        },
        surname: {
          value: '',
          error: null,
          errorMessage: 'Fill this label',
          title: 'Lastname',
        },
      },
    },
    phone: {
      value: '',
      error: null,
      type: 'number',
      errorMessage: 'Fill this label',
      title: 'Mobile phone',
    },
    work_phone: {
      value: '',
      error: null,
      type: 'number',
      errorMessage: 'Fill this label',
      title: 'Work phone',
      optional: true,
    },
    sideTitle_2: {
      title: 'Professional Information',
      bordered: true,
      borderTitleDistance: '30px',
    },
    sectionTitle_3: {
      title: 'Office Location',
      type: 'heading-20',
      style: {
        margin: '38px 0 24px',
      },
    },
    checkbox_useCompanyAddress: {
      boxes: {
        useCompanyAddress: {
          title: 'Use company address',
          style: { margin: 0 },
          skip: true,
          value: false,
        },
      },
    },
    dividedInputs_4: {
      inputs: {
        city: {
          title: 'City',
          value: '',
          error: null,
          errorMessage: 'Fill this label',
        },
        state: {
          title: 'State',
          type: 'select',
          value: '',
          error: null,
          errorMessage: 'Fill this label',
          options: otherConst.states,
        },
      },
    },
    dividedInputs_5: {
      inputs: {
        zip: {
          title: 'Zip',
          maxLength: 5,
          value: '',
          regex: '^[0-9]*$',
          error: null,
          errorMessage: 'Please enter 5 symbols',
        },
      },
    },
  });
  const [disabledAction, setDisabledAction] = useState(true);
  const [companyAddress, setCompanyAddress] = useState({});
  const [edit, setEdit] = useState(null);
  const [doneTabs, setDoneTabs] = useState({
    company: false,
    profile: false,
  });
  const [activeModal, setActiveModal] = useState({ show: false, data: {} });
  const activeExitModal = useSelector((state) => state.popups.show);
  const role = useSelector((store) => store.user.userData?.role);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const globalAPI = useMemo(() => new BDRAPI(), []);
  const prospectId = useMemo(() => Number(new URLSearchParams(location.search).get('id')), [location]);

  const progress = isTeam
    ? [
        {
          title: 'Registration',
          completed: !disabledAction,
        },
      ]
    : [
        {
          title: 'Company Profile',
          completed: doneTabs.company,
          onClick: () => onSave('link', `/registration/broker/company${window.location.search}` ?? ''),
          clickCondition: true,
        },
        {
          title: 'Personal Profile',
          active: true,
          completed: !disabledAction,
        },
      ];

  const autocompleteAddressFields = (address, city, state, zip) => {
    onChange({ target: { value: city } }, 'dividedInputs_4.inputs.city');
    onChange({ target: { value: state.value ?? state } }, 'dividedInputs_4.inputs.state');
    onChange({ target: { value: zip } }, 'dividedInputs_5.inputs.zip');
  };

  const closeActiveModal = () => {
    setActiveModal((prev) => ({ ...prev, show: false }));
  };

  const onChange = (event, route) => {
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const { value } = event.target;

    setFields((state) => {
      const tempFields = { ...state };

      if (routeLength === 3) {
        tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
          ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
          value,
          error: false,
        };
      } else if (routeLength === 2) {
        tempFields[routeArr[0]][routeArr[1]] = {
          ...tempFields[routeArr[0]][routeArr[1]],
          value,
          error: false,
        };
      } else if (routeLength === 1) {
        tempFields[routeArr[0]] = {
          ...tempFields[routeArr[0]],
          value,
          error: false,
        };
      }

      return tempFields;
    });
  };

  const selectTag = (value, route) => {
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const tempFields = { ...fields };

    if (routeLength === 3) {
      const nestedValue = tempFields[routeArr[0]][routeArr[1]][routeArr[2]];
      tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
        ...nestedValue,
        tags: [...nestedValue.tags, value],
        error: false,
      };
    } else if (routeLength === 2) {
      const nestedValue = tempFields[routeArr[0]][routeArr[1]];
      tempFields[routeArr[0]][routeArr[1]] = {
        ...nestedValue,
        tags: [...nestedValue.tags, value],
        error: false,
      };
    } else if (routeLength === 1) {
      const nestedValue = tempFields[routeArr[0]];
      tempFields[routeArr[0]] = {
        ...nestedValue,
        tags: [...nestedValue.tags, value],
        error: false,
      };
    }

    setFields(tempFields);
  };

  const onTagDelete = (item, route) => {
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const tempFields = { ...fields };

    if (routeLength === 3) {
      const nestedValue = tempFields[routeArr[0]][routeArr[1]][routeArr[2]];
      tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
        ...nestedValue,
        tags: nestedValue.tags.filter((i) => i !== item),
      };
    } else if (routeLength === 2) {
      const nestedValue = tempFields[routeArr[0]][routeArr[1]];
      tempFields[routeArr[0]][routeArr[1]] = {
        ...nestedValue,
        tags: nestedValue.tags.filter((i) => i !== item),
      };
    } else if (routeLength === 1) {
      const nestedValue = tempFields[routeArr[0]];
      tempFields[routeArr[0]] = {
        ...nestedValue,
        tags: nestedValue.tags.filter((i) => i !== item),
      };
    }

    setFields(tempFields);
  };

  const onUpload = (event, route) => {
    event.preventDefault();
    const file = event.target.files[0];
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const tempFields = { ...fields };

    if (!file) {
      return;
    }

    if (routeLength === 3) {
      tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
        ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
        value: URL.createObjectURL(file),
        file,
      };
    } else if (routeLength === 2) {
      tempFields[routeArr[0]][routeArr[1]] = {
        ...tempFields[routeArr[0]][routeArr[1]],
        value: URL.createObjectURL(file),
        file,
      };
    } else if (routeLength === 1) {
      tempFields[routeArr[0]] = {
        ...tempFields[routeArr[0]],
        value: URL.createObjectURL(file),
        file,
      };
    }

    setFields(tempFields);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const then = (res) => {
      const { city, state, street_address, zip } = !isTeam ? res.data : res.data.company_address;
      setCompanyAddress({
        city,
        state,
        street_address,
        zip,
      });
    };

    if (!isTeam) {
      const applyTabs = (res) => {
        const { company_is_full, profile_is_full, agreement } = res.data;

        setDoneTabs({
          company: company_is_full,
          profile: profile_is_full,
          agreement,
        });
      };

      const user_id = urlParams.get('id');
      if (user_id) {
        API()
          .get('/prospects/BDR/check_status', {
            params: { id: Number(user_id) },
          })
          .then(applyTabs);
        API()
          .get('/prospects/BDR/company', {
            params: { id: Number(user_id) },
          })
          .then((res) => {
            then(res);
            if (res.data.individual_company) {
              setFields((state) => {
                const temp = { ...state };
                temp.checkbox_useCompanyAddress.boxes.useCompanyAddress.title = 'Use sole proprietor address';
                return temp;
              });
            }
          });
      } else {
        API().get('/prospects/BDR/check_status').then(applyTabs);
        API()
          .get('/prospects/BDR/company')
          .then((res) => {
            then(res);
            if (res.data.individual_company) {
              setFields((state) => {
                const temp = { ...state };
                temp.checkbox_useCompanyAddress.boxes.useCompanyAddress.title = 'Use sole proprietor address';
                return temp;
              });
            }
          });
      }
    } else {
      API().get('/user_settings/company/members/info').then(then);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (!isTeam) {
      const then = (res) => {
        setEdit(res.data.actions.includes('Edit'));
        fillFields(res.data, true, fields, setFields);
      };

      API()
        .get('/prospects/BDR/profile/default-communications')
        .then((res) => {
          fillFields(res.data, true, fields, setFields);
        })
        .finally(() => {
          window.scrollTo(0, 0);

          const user_id = urlParams.get('id');
          if (user_id) {
            API()
              .get('/prospects/BDR/profile', {
                params: { id: user_id },
              })
              .then((res) => then(res));
          } else {
            API()
              .get('/prospects/BDR/profile')
              .then((res) => then(res));
          }
        });
    } else {
      setEdit(true);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fields.checkbox_useCompanyAddress) {
      const useAddress = fields.checkbox_useCompanyAddress.boxes.useCompanyAddress.value;
      const companyData = useAddress
        ? { ...companyAddress }
        : {
            city: '',
            state: '',
            street_address: '',
            zip: '',
          };

      fillFields(companyData, useAddress, fields, setFields);
    }

    // eslint-disable-next-line
  }, [fields.checkbox_useCompanyAddress?.boxes.useCompanyAddress.value]);

  const onError = (routes) => {
    const tempFields = { ...fields };

    routes.forEach((route) => {
      const routeArr = route.split('.');
      const routeLength = routeArr.length;

      if (routeLength === 3) {
        tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
          ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
          error: true,
        };
      } else if (routeLength === 2) {
        tempFields[routeArr[0]][routeArr[1]] = {
          ...tempFields[routeArr[0]][routeArr[1]],
          error: true,
        };
      } else if (routeLength === 1) {
        tempFields[routeArr[0]] = {
          ...tempFields[routeArr[0]],
          error: true,
        };
      }
    });

    setFields(tempFields);
  };

  useEffect(() => {
    let unfilledFieldsCount = 0;
    Object.entries(fields).forEach((i) => {
      const field = i[1];
      if (i[0].includes('dividedInputs')) {
        Object.entries(field.inputs).forEach((item) => {
          const input = item[1];
          if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
            unfilledFieldsCount += 1;
          } else if (item[0] === 'zip' && input.value.length < 5) {
            unfilledFieldsCount += 1;
          }
        });
      } else if (i[0].includes('radioButtons')) {
        // eslint-disable-line
      } else if (i[0].includes('switchButtons')) {
        // eslint-disable-line
      } else if (i[0].includes('selectTags')) {
        if (!i[1].optional && !i[1].tags.length) {
          unfilledFieldsCount += 1;
        }
      } else if (i[0].includes('upload')) {
        const upload = i[1];
        if (!upload.optional && !upload.file && !upload.value) {
          unfilledFieldsCount += 1;
        }
      } else if (i[0].includes('checkbox')) {
        // eslint-disable-line
      } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle')) {
        // eslint-disable-line
      } else {
        const input = i[1];
        if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
          unfilledFieldsCount += 1;
        }
      }
    });

    setDisabledAction(!!unfilledFieldsCount);
  }, [fields]);

  const submitInvitation = ({ cc, send_copy_to_self, message }) => {
    globalAPI.inviteUser(prospectId, cc, message, send_copy_to_self, true).then(() => {
      toast.success('Email successfully sent');
      history.push('/onboarding/prospects');
    });
  };

  const onSave = (type, link) => {
    const request = {};
    const temp = { ...fields };

    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get('id');

    if (disabledAction && type === 'submit') {
      const routes = [];
      Object.entries(temp).forEach((i) => {
        const field = i[1];
        if (i[0].includes('dividedInputs')) {
          Object.entries(field.inputs).forEach((item) => {
            const input = item[1];
            if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
              routes.push(`${i[0]}.inputs.${item[0]}`);
            } else if (item[0] === 'year_established' && input.value.length < 4) {
              routes.push(`${i[0]}`);
            } else if (item[0] === 'zip' && input.value.length < 5) {
              routes.push(`${i[0]}.inputs.${item[0]}`);
            }
          });
        } else if (i[0].includes('radioButtons')) {
          // eslint-disable-line
        } else if (i[0].includes('switchButtons')) {
          // eslint-disable-line
        } else if (i[0].includes('selectTags')) {
          if (!i[1].optional && !i[1].tags.length) {
            routes.push(`${i[0]}`);
          }
        } else if (i[0].includes('upload')) {
          // eslint-disable-line
        } else if (i[0].includes('checkbox')) {
          // eslint-disable-line
        } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle')) {
          // eslint-disable-line
        } else {
          const input = i[1];
          if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
            routes.push(`${i[0]}`);
          } else if (i[0] === 'year_established' && input.value.length < 4) {
            routes.push(`${i[0]}`);
          }
        }
      });
      onError(routes);
      window.scrollTo(0, 0);
      return;
    }

    Object.entries(temp).forEach((i) => {
      const field = i[1];
      if (i[0].includes('dividedInputs')) {
        Object.entries(field.inputs).forEach((item) => {
          if (!item[1].skip) {
            request[item[0]] = item[1].value;
          }
        });
      } else if (i[0].includes('radioButtons')) {
        if (!i[1].skip) {
          const fieldName = i[0].split('_').slice(1).join('_');
          request[fieldName] = field.value;
        }
      } else if (i[0].includes('switchButtons')) {
        Object.entries(field.switches).forEach((item) => {
          if (!item[1].skip) {
            request[item[0]] = item[1].value;
          }
        });
      } else if (i[0].includes('selectTags')) {
        if (!i[1].skip) {
          const fieldName = i[0].split('_').slice(1).join('_');
          request[fieldName] = i[1].tags.join(', ');
        }
      } else if (i[0].includes('upload')) {
        if (!i[1].skip && i[1].file) {
          const fieldName = i[0].split('_').slice(1).join('_');
          request[fieldName] = i[1].file;
        }
      } else if (i[0].includes('checkbox')) {
        Object.entries(field.boxes).forEach((item) => {
          if (!item[1].skip) {
            request[item[0]] = item[1].value;
          }
        });
      } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle') || i[0].includes('selectTags')) {
        // eslint-disable-line
      } else if (!i[1].skip) {
        request[i[0]] = field.value;
      }
    });

    const finalObj = {};

    Object.entries(request).forEach((i) => {
      if (Array.isArray(i[1]) ? i[1].length : i[1] || i[1] === false || i[1] === 0) {
        finalObj[i[0]] = i[1];
      }
    });

    if (user_id) {
      finalObj.id = user_id;
    }

    if (finalObj.work_phone === '1') {
      delete finalObj.work_phone;
    }

    API()
      .post(isTeam ? '/user_settings/company/members/new-member' : '/prospects/BDR/profile', finalObj)
      .then((res) => {
        if (!res.data.error) {
          if (type === 'submit') {
            if (isTeam) {
              history.push('/onboarding/dashboard');
            } else {
              setActiveModal((state) => ({ ...state, show: true }));
            }
          } else if (type === 'link') {
            history.push(link);
          } else {
            message.success('Custom Landing Page data successfully saved!');
          }
        }
      })
      .catch((res) => {
        const errors = res.response.data['wrong columns'] || [];
        const errorObj = {};

        errors.forEach((i) => {
          Object.entries(i).forEach((i) => {
            errorObj[i[0]] = i[1];
          });
        });

        window.scrollTo(0, 0);
        fillError(errorObj, true, fields, setFields);
      });

    // fix id from
  };

  const handleExit = () => {
    history.push('/');
    dispatch(closePopup());
  };

  const handleSaveAndExit = () => {
    onSave('link', '/');
    dispatch(closePopup());
  };

  const onCloseActiveModal = () => {
    dispatch(closePopup());
  };

  const nextStep = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get('id');

    const url = user_id ? `/registration/broker/partner?id=${user_id}` : '/registration/broker/partner';
    history.push(url);
  };

  return (
    <div className="registration lender-register">
      <RegistrationSubHeader
        maxWidth={isTeam ? '800px' : '946px'}
        progress={progress}
        actions={
          isTeam
            ? [<Button key={1} text="Complete" onClick={() => onSave('submit')} className="btn_darkblue" />]
            : edit
            ? [
                <Button key={1} text="Save" onClick={() => onSave('save')} className="btn_darkblue" />,
                <Button key={2} text="Next" onClick={() => onSave('submit')} className="btn_darkblue" />,
              ]
            : edit === false
            ? [<Button key={1} text="Next" onClick={nextStep} className="btn_darkblue" />]
            : []
        }
      />
      <div className="registration-content">
        <RenderInputs
          edit={edit}
          data={fields}
          onTagDelete={onTagDelete}
          selectTag={selectTag}
          onChange={onChange}
          onUpload={onUpload}
          autocompleteAddressFields={autocompleteAddressFields}
        />
      </div>
      {/^bdr$/i.test(role) && (
        <Modal
          footer={
            <>
              <CustomButton onClick={handleExit} text="Exit" />
              <CustomButton onClick={handleSaveAndExit} primary text="Save progress & exit" />
            </>
          }
          onCancel={onCloseActiveModal}
          visible={activeExitModal === 'exit'}
        >
          Exit without saving progress?
        </Modal>
      )}
      <ManualEMSModal
        onCancel={closeActiveModal}
        onSubmit={submitInvitation}
        templateRoute={prospectId ? `/prospects/BDR/send_invite?id=${prospectId}&send_verification=1` : undefined}
        title="Send verification"
        visible={activeModal.show}
      />
    </div>
  );
}
export default BrokerProfile;
