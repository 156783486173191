import { useState } from 'react';
import { Input } from 'antd';
import classNames from 'classnames';
import { usePlacesWidget } from 'react-google-autocomplete';
import lock from 'assets/images/lock.svg';
import lockOpen from 'assets/images/lockOpen.svg';
import 'components/UI/AutoCompleteTextInput/custom_text_input.css';

function AutoCompleteTextInput({
  htmlFor,
  value,
  title,
  className,
  errorMessage,
  error,
  type,
  showPassIcon,
  classNameWrapper,
  description,
  hint,
  wrapperStyle,
  icon,
  iconClick,
  hideLabel,
  setSelectedAddress,
  removeCustomStyle,
  ...rest
}) {
  const [activePass, setActivePass] = useState(false);
  const { ref: autoFillProp } = usePlacesWidget({
    apiKey: process.env.REACT_APP_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      const address_components = place?.address_components;
      const components = {};

      address_components.forEach((v1) => {
        v1.types.forEach((v2) => {
          components[v2] =
            v2 === 'administrative_area_level_1' ? { value: v1.short_name, preview: v1.long_name } : v1.long_name;
        });
      });

      const address = (components.street_number ? `${components.street_number} ` : '') + (components.route ?? '');

      const city = components.locality ?? '';
      const state = components.administrative_area_level_1 ?? '';
      const zip = components.postal_code ?? '';

      setSelectedAddress(address, city, state, zip);
    },
    inputAutocompleteValue: 'country',
    options: {
      types: ['geocode'],
      language: 'en',
      componentRestrictions: { country: 'us' },
    },
  });

  return (
    <div
      style={wrapperStyle}
      className={classNames(
        removeCustomStyle ? '' : 'input_container',
        {
          input_error: error,
          input_hint: hint,
          input_show_icon: showPassIcon || icon,
        },
        classNameWrapper,
      )}
    >
      {title ? (
        hideLabel ? (
          <div className="hidden_title_wrapper" data-hidden-title={title} />
        ) : (
          <label htmlFor={htmlFor} className="input_title">
            {title}
          </label>
        )
      ) : null}
      {hint && (
        <div className="hint">
          <svg
            fill="none"
            height="24"
            stroke="#bfbfbf"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" />
            <path d="M12 8H12.01" />
            <path d="M11 12H12V16H13" />
          </svg>
          <div className="tool_tip">
            <span>{hint}</span>
          </div>
        </div>
      )}
      <div className="wrapper_input">
        <Input
          value={value}
          ref={(c) => {
            c && autoFillProp && (autoFillProp.current = c.input);
          }}
          className={`${removeCustomStyle ? '' : 'custom_input'} ${className ?? ''}`}
          type={activePass ? 'text' : type || 'text'}
          name={htmlFor}
          id={htmlFor}
          {...rest}
        />
        {showPassIcon ? (
          activePass ? (
            <img src={lockOpen} alt="lockOpen" className="show_pass_icon" onClick={() => setActivePass(!activePass)} />
          ) : (
            <img src={lock} alt="lock" className="show_pass_icon" onClick={() => setActivePass(!activePass)} />
          )
        ) : null}
        {icon && <img src={icon} alt="Lock" className="show_pass_icon" onClick={iconClick} />}
      </div>
      {error && <p className="errorMessage">{errorMessage}</p>}
      {description && <p className="input_descpription">{description}</p>}
    </div>
  );
}

export default AutoCompleteTextInput;
