import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateDashboardMetricsTimestamp } from 'store/actions/dashboard';
import parseFromET from 'constants/parseFromET';
import DashboardWidget from 'components/Dashboard/DashboardWidget';

function TimerWidget({ auctionEnd, auctionStart }) {
  const dispatch = useDispatch();
  const [closestTime, setClosestTime] = useState({
    countdown: '99:99:99',
    displayed: 'Loading...',
    label: 'start',
    moment: moment.now(),
  });

  useEffect(() => {
    const nextFinish = parseFromET(auctionEnd);
    const nextStart = parseFromET(auctionStart);

    const interval = setInterval(() => {
      const now = moment.now();

      // Request timers update if one of the dates is in the past
      if (nextStart < now || nextFinish < now) {
        dispatch(updateDashboardMetricsTimestamp());
      }

      // Select the closest time
      const closest =
        nextStart < nextFinish
          ? {
              label: 'start',
              moment: nextStart,
              time: auctionStart,
            }
          : {
              label: 'finish',
              moment: nextFinish,
              time: auctionEnd,
            };

      // Calculate countdown in "00:00:00" format
      const delta = Math.max(closest.moment.diff(now), 0);
      const dh = Math.floor(delta / 3.6e6);
      const dm = Math.floor((delta - dh * 3.6e6) / 6e4);
      const ds = Math.floor((delta - dh * 3.6e6 - dm * 6e4) / 1e3);
      const countdown = [dh, dm, ds].map((n) => n.toString().padStart(2, 0)).join(':');

      // Calculate displayed time in "11:30 PM ET" format
      const date = new Date(closest.time);
      const h = date.getHours();
      const m = date.getMinutes().toString().padStart(2, 0);
      const displayed = `${h % 12}:${m} ${h >= 12 ? 'PM' : 'AM'} ET`;

      setClosestTime({
        ...closest,
        countdown,
        displayed,
      });
    }, 1e3);

    return () => clearInterval(interval);
  }, [auctionEnd, auctionStart]);

  return (
    <DashboardWidget
      desc={`Until auction ${closestTime.label}`}
      extra={closestTime.displayed}
      title={closestTime.countdown}
    />
  );
}

export default TimerWidget;
