import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Select, Spin, Input, Typography, Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import { getReassignableAllowedRoles } from 'utils/role';
import Modal from 'components/Modals/Modal';
import siteName from 'constants/siteName';

function ReassignLARModal({ visible, team, onClose, onReassignLar }) {
  const [message, setMessage] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [userId, setUserId] = useState(null);
  const { role } = useSelector((state) => state.user.userData);
  const user = team?.find((i) => i.user_id === userId);
  const isStepsUI = user?.available_roles?.some((r) => ['Lender Account Executive', 'Broker Loan Manager'].includes(r));

  const reassignTeam = useMemo(() => {
    const allowedRoles = getReassignableAllowedRoles(role);

    return team?.filter(({ available_roles }) => available_roles.some((role) => allowedRoles.includes(role)));
  }, [team, role]);

  const handleSubmit = () => {
    setLoading(true);
    onReassignLar({ userId, message })
      .then(() => handleClose())
      .finally(() => setLoading(false));
  };

  const handleOk = () => {
    if (isStepsUI) {
      if (step === 1) {
        if (userId) {
          setStep(2);
        } else {
          onClose?.();
        }
      } else if (step === 2 && message) {
        handleSubmit();
      }
    } else {
      handleSubmit();
    }
  };

  const handleClose = () => {
    setStep(1);
    setUserId(null);
    setMessage('');
    onClose?.();
  };

  return (
    <Modal
      open={visible}
      okText={isStepsUI ? (step === 1 ? 'Next' : 'Confirm') : 'Send'}
      className="wrapper_prospect"
      title={isStepsUI && step === 2 ? 'Confirm Reassign Control of this Deal' : 'Reassign deal management'}
      okButtonProps={{ loading, disabled: (step === 1 && !userId) || (step === 2 && !message) }}
      onCancel={handleClose}
      onOk={handleOk}
    >
      {step === 1 && (
        <>
          <span className="description" style={{ textAlign: 'justify' }}>
            When you select confirm, {siteName} will transfer control of this deal to your colleague. The deal will be
            removed from your dashboard and no longer accessible to you.
          </span>
          <br />
          <br />
          <Spin spinning={!team}>
            <div>
              <label>Team member</label>
              <Select
                value={userId}
                style={{ width: '100%', marginTop: 10 }}
                showSearch
                notFoundContent="None available"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                onChange={setUserId}
              >
                {!isEmpty(reassignTeam) &&
                  reassignTeam.map((el) => (
                    <Select.Option key={el.user_id} value={el.user_id} label={el.user_name}>
                      {el.user_name}
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </Spin>
        </>
      )}
      {step === 2 && (
        <Row gutter={[32, 20]}>
          <Col span={24}>
            <Typography.Paragraph>
              Please provide a brief note for the person that will receive control of this deal.
            </Typography.Paragraph>
            <Input.TextArea rows={4} onChange={(e) => setMessage(e.target.value)} />
          </Col>
          <Col span={24}>
            <Typography.Paragraph>
              {siteName} will send an email to the assignee with your note and a link to access the deal they now
              control. The deal will no longer be accessible on your pipeline
            </Typography.Paragraph>
            <Typography.Paragraph>
              Note: If you are the admin, then the deal will remain visible on
            </Typography.Paragraph>
          </Col>
        </Row>
      )}
    </Modal>
  );
}

export default ReassignLARModal;
