import { HouseIcon } from 'assets/icons/icons';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import styles from './UniqueData.module.css';

function UniqueData({ label, value, corp, onClick, visible = true }) {
  if (!value || isEmpty(value) || !visible) {
    return null;
  }

  return (
    <div className="unique_data">
      <span className="label">{label}</span>
      {Array.isArray(value) ? (
        value.filter(Boolean).map((v) => (
          <p className={classNames('value', onClick && styles.clickable)} key={v} onClick={onClick}>
            {v}
          </p>
        ))
      ) : (
        <p className={classNames('value', onClick && styles.clickable)} onClick={onClick}>
          {value}
        </p>
      )}
      {Boolean(corp) && (
        <div className="lar-profile_info__corp">
          <span className="lar-profile_info__corp-text">{corp}</span>
          <HouseIcon className="lar-profile_info__corp-img" />
        </div>
      )}
    </div>
  );
}

export default UniqueData;
