import { BinIcon, FileInvoiceIcon, TickIcon, PencilIcon, WarningTriangleIcon } from 'assets/icons';
import CustomButton from 'components/UI/Button';
import classes from './styles/FormsUnderwritingView.module.css';

const statusButtons = new Map([
  [
    'created',
    {
      buttonColour: '#52c41a',
      buttonText: 'Download',
      hint: (
        <>
          <TickIcon colour="green-6" /> Successfully created
        </>
      ),
    },
  ],
  [
    'error',
    {
      buttonColour: '#faad14',
      buttonText: 'Try create again',
      hint: (
        <>
          <WarningTriangleIcon colour="gold-6" /> Error occured
        </>
      ),
    },
  ],
]);

function Item({ hint, buttonColour, buttonText, title }) {
  return (
    <div className={classes.item}>
      <b>{title}</b>
      <span className={classes.hint}>{hint}</span>
      <CustomButton
        primary
        style={{ backgroundColor: buttonColour }}
        text={
          buttonText || (
            <>
              <FileInvoiceIcon colour="white" /> Create
            </>
          )
        }
      />
      <button aria-label="actionEdit" className={classes.actionButton}>
        <PencilIcon />
      </button>
      <button aria-label="actionDelete" className={classes.actionButton}>
        <BinIcon />
      </button>
    </div>
  );
}

export default function FormsUnderwritingView({ viewDiligence }) {
  return (
    <div className={`${classes.root} document-view`}>
      <div className="document-view-content">
        <b>Available forms:</b>
        {viewDiligence.documents.map((item) => (
          <Item key={item.id} {...item} {...statusButtons.get(item.status)} />
        ))}
      </div>
    </div>
  );
}
