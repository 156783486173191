import { AlarmOctagonIcon, ClipboardTickIcon } from 'assets/icons';
import { Form, Radio, Select, message as toast, Spin, Button, Tooltip } from 'antd';
import { Fragment, useEffect, useState, useMemo, useCallback } from 'react';
import { LARAPI } from 'api';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import infoIcon from 'assets/icons/info-circle-grey.svg';
import classes from './styles/ScheduleAuction.module.css';

const scheduleTableMapping = [
  {
    label: 'Scheduling Deadline',
    key: 'scheduling_deadline',
  },
  {
    label: 'Lenders Invited to the Upcoming Auction',
    key: 'lender_invites_to_upcoming_auction',
  },
  {
    label: 'Preview Period Begins',
    key: 'preview_period_begins',
  },
  {
    label: 'Auction Begins',
    key: 'auction_begins',
  },
  {
    label: 'Bid Deadline (Initial Quote Submitted)',
    key: 'bid_deadline',
  },
  {
    label: 'Auction Ends',
    key: 'auction_ends',
  },
];

export default function ScheduleAuction() {
  const { id } = useParams();
  const history = useHistory();
  const role = useSelector((s) => s.user.userData?.role);
  const [data, setData] = useState({ availableSlots: [] });
  const [ready, setReady] = useState(false);
  const [form] = Form.useForm();
  const goodToGo = Form.useWatch('goodToGo', form);

  const API = useMemo(() => new LARAPI(Number(id)), [id]);

  function navigateToEdit() {
    history.push(`/lar_app/create?lar_id=${id}&scenario=false`);
  }

  function navigateToDashboard() {
    history.push('/onboarding/dashboard');
  }

  function submit({ goodToGo, timeSlot }) {
    if (!goodToGo) {
      API.cancelScheduledAuction().finally(navigateToDashboard);

      return;
    }

    setReady(false);

    API.schedule(...(timeSlot === 'now' ? [] : timeSlot.split('/')))
      .then(() => {
        toast.success('You successfully scheduled this auction');
        navigateToDashboard();
      })
      .finally(() => {
        setReady(true);
      });
  }

  const getData = useCallback(() => {
    setReady(false);

    API.getSchedule()
      .then((data) => {
        setData(data);
      })
      .finally(() => {
        setReady(true);
      });
  }, [API]);
  useEffect(getData, [getData]);

  function rebootSmartmatch() {
    setReady(false);

    API.rebootSmartmatch().then(() => {
      toast.success('You successfully rebooted smartmatch for this auction');
      getData();
      // Not setting ready to true because data will be
      // fetched automatically after reboot and will set
      // it to true when it's done
    });
  }

  const availableSlots = useMemo(
    () => [
      ...(['development', 'staging'].includes(process.env.REACT_APP_NODE_ENV)
        ? [
            {
              label: 'Right here, right now',
              value: 'now',
            },
          ]
        : []),
      ...data.availableSlots.map((s) => ({
        label: `${s.date}`,
        value: `${s.date}/${s.slot}`,
      })),
    ],
    [data.availableSlots],
  );

  const cancel = () => {
    history.push('/onboarding/dashboard');
  };

  const handleClick = () => {
    if (data.lendersMatch > 0) {
      form.submit();
      return;
    }

    navigateToEdit();
  };

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <Spin spinning={!ready} wrapperClassName={classes.spinWrapper}>
          <h1>Schedule your loan auction</h1>
          {data.lendersMatch > 0 ? (
            <>
              <div className={classes.goodNews}>
                <ClipboardTickIcon colour="green-7" />
                Good news. There are loan programs that meet many of your loan requests
              </div>
              <div className={classes.basicData}>
                <div>Loan auction request ID (LAR)</div>
                <div>{`#${id}`}</div>
                <div>Loan programs available</div>
                <div>{data.lendersMatch}</div>
                {data.selected_date && (
                  <>
                    <div>Current Auction Schedule</div>
                    <div>
                      {data.selected_time ? `${data.selected_time},` : ''} {data.selected_date}
                    </div>
                  </>
                )}
              </div>
              <Form
                className={classes.form}
                form={form}
                initialValues={{ goodToGo: true, timeSlot: availableSlots[0]?.value }}
                onFinish={submit}
              >
                <Form.Item name="goodToGo">
                  <Radio.Group
                    options={[
                      {
                        label: <>Select auction time </>,
                        value: true,
                      },
                      {
                        label: 'Don’t schedule the auction',
                        value: false,
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item name="timeSlot">
                  <Select disabled={!goodToGo} options={availableSlots} />
                </Form.Item>
              </Form>
              <div className={classes.scheduleGrid}>
                <div />

                <div>All times Eastern U.S.</div>
                {scheduleTableMapping.map(({ key, label, tooltip }) => (
                  <Fragment key={key}>
                    <div>{label}</div>
                    <div className={classes.timeWrapper}>
                      {data.auction_time?.[key]}
                      {tooltip && (
                        <Tooltip title={tooltip}>
                          <img src={infoIcon} alt="tooltip" />
                        </Tooltip>
                      )}
                    </div>
                  </Fragment>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className={classes.badNews}>
                <AlarmOctagonIcon colour="gold-7" />
                There are no available loan programs that meet your specific loan request
              </div>
              <div>You can revise the loan request to change the results</div>
            </>
          )}
          <div className={classes.footer}>
            <Button onClick={cancel} size="large">
              Cancel
            </Button>
            {/^superadmin$|^pla$/i.test(role) && process.env.REACT_APP_NODE_ENV === 'development' && (
              <Button onClick={rebootSmartmatch} size="large">
                Reboot smartmatch
              </Button>
            )}
            <Button onClick={handleClick} size="large" type="dark" disabled={!data.can_schedule}>
              {data.lendersMatch > 0 ? (data.selected_date ? 'Update schedule' : 'Schedule') : 'Revise'}
            </Button>
          </div>
        </Spin>
      </div>
    </div>
  );
}
