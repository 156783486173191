import { useEffect, useState } from 'react';
import moment from 'moment';
import MainLayout from 'components/Layouts/MainLayout';
import styles from './Header.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getRatesTracker } from 'store/async-actions/lar';

function Header() {
  const [datetime] = useState(getDatetime());

  const dispatch = useDispatch();
  const { ratesTracker } = useSelector((state) => state.lar.data);

  useEffect(() => {
    if (!ratesTracker) {
      dispatch(getRatesTracker());
    }
  }, []);

  function getDatetime() {
    return moment().tz('Cuba');
  }

  return (
    <MainLayout>
      <header className={styles.root}>
        <div>Here are today's highlights</div>
        <div>{`Today is ${datetime.format('MMMM D, YYYY')}`}</div>
        <div>{`Current Time: ${datetime.format('h:mm a')} ET`}</div>
        <div>
          Prime Index: {ratesTracker?.prime_rate ?? '###'}, SOFR Index: {ratesTracker?.sofr_rate ?? '###'}
        </div>
      </header>
    </MainLayout>
  );
}

export default Header;
