import React from 'react';
import './styles/section_title.css';

function SectionTitle({ title, style, type, wrapperStyle, className = '' }) {
  const renderByType = () => {
    switch (type) {
      case 'h6':
        return <h6 style={style}>{title}</h6>;
      case 'heading-20':
      case 'subheading-16':
      case 'body-14':
      case 'body-16':
      case 'regular-24':
        return (
          <span className={type} style={style}>
            {title}
          </span>
        );
      default:
        return <h3 style={style}>{title}</h3>;
    }
  };

  return (
    <div style={wrapperStyle} className={`section_title ${className}`}>
      {renderByType()}
    </div>
  );
}

export default SectionTitle;
