import { useEffect, useState } from 'react';
import { getFileIcon } from 'utils/files';
import renderImage from 'constants/renderImage';
import Video from 'assets/images/Video.svg';
import YouTubeLogo from 'assets/images/YouTubeLogo.svg';
import styles from './Thumbnail.module.css';

function Thumbnail({ attachment, showPreview }) {
  const [base64, setBase64] = useState(null);
  const fileType = attachment.file?.name.split('.').at(-1).toUpperCase();

  useEffect(() => {
    if (!attachment.file?.originFileObj) {
      return;
    }

    if (!/^image\//.test(attachment.file.originFileObj.type)) {
      return;
    }

    renderImage(attachment.file.originFileObj).then(setBase64);
  }, [attachment.file?.originFileObj]);

  if (attachment.file?.url) {
    return (
      <img
        alt={attachment.file_name}
        onClick={() => showPreview(attachment.file.url, attachment.file_name)}
        src={attachment.file.url}
      />
    );
  }

  if (attachment.yt) {
    return (
      <img
        alt=""
        style={{
          width: '50%',
          objectFit: 'contain',
        }}
        src={YouTubeLogo}
      />
    );
  }

  if (!attachment.file?.originFileObj) {
    return null;
  }

  if (/^video\//.test(attachment.file.originFileObj.type)) {
    return (
      <img
        alt={attachment.file_name}
        style={{
          width: '50%',
          objectFit: 'contain',
        }}
        src={Video}
      />
    );
  }

  if (!/^image\//.test(attachment.file.originFileObj.type)) {
    return <div className={styles.fileIcon}>{getFileIcon(fileType)}</div>;
  }

  return <img alt={attachment.file_name} onClick={() => showPreview(base64, attachment.file_name)} src={base64} />;
}

export default Thumbnail;
