import { Card, Table, Typography } from 'antd';

import styles from './LoanProfilePropertyIncome.module.css';

export default function LoanProfilePropertyIncome({ data }) {
  function valueToLocaleString(value) {
    if (!value) return 0;

    return `$${Math.floor(value).toLocaleString()}`;
  }

  const columns = [
    {
      title: '',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Historical',
      dataIndex: 'historical',
      key: 'historical',
    },
    {
      title: 'Proforma',
      dataIndex: 'proforma',
      key: 'proforma',
    },
  ];

  const dataSource = [
    {
      key: '1',
      label: 'Time Period',
      historical: data.historical?.time_period,
      proforma: data.proforma?.time_period,
    },
    {
      key: '2',
      label: 'Rental Income',
      historical: valueToLocaleString(data.historical?.rental_income),
      proforma: valueToLocaleString(data.proforma?.rental_income),
    },
    {
      key: '3',
      label: 'Operating Expenses',
      historical: valueToLocaleString(data.historical?.operating_expenses),
      proforma: valueToLocaleString(data.proforma?.operating_expenses),
    },
    {
      key: '4',
      label: 'NOI',
      historical: valueToLocaleString(data.historical?.NOI),
      proforma: valueToLocaleString(data.proforma?.NOI),
    },
  ];

  return dataSource.every((i) => !i.historical && !i.proforma) ? null : (
    <Card>
      <Typography.Title level={4}>Property Income</Typography.Title>
      <Table className={styles.table} columns={columns} dataSource={dataSource} pagination={false} />
    </Card>
  );
}
