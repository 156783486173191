import { isObject, isString } from 'lodash';

function unCamelCase(string) {
  if (isString(string)) {
    string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
    string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
  }

  return string;
}

function sortAlphabetically(fields) {
  return fields.sort(({ field: A }, { field: B }) => (A.toLowerCase() < B.toLowerCase() ? -1 : 1));
}

export function getLARFields(context, customMergeFields = []) {
  let fields = [];

  if (customMergeFields.length > 0) {
    fields = customMergeFields.map((item) => {
      const field = item.name;
      return {
        field,
        description: unCamelCase(field),
      };
    });
  }

  const formattedFields = fields.map((field) => {
    if (isObject(field)) {
      return field;
    }

    return {
      field,
      description: unCamelCase(field),
    };
  });

  return sortAlphabetically(formattedFields);
}
