import API from './API';

export { default as BannersAPI } from './BannersAPI';
export { default as BDRAPI } from './BDRAPI';
export { default as BrokerLandingAPI } from './BrokerLandingAPI';
export { default as ChatAPI } from './ChatAPI';
export { default as DashboardAPI } from './DashboardAPI';
export { default as EMSAPI } from './EMSAPI';
export { default as LARAPI } from './LARAPI';
export { default as SettingsAPI } from './SettingsAPI';

export default API;
