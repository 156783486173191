import API from '../API';

export default class BDRAPI {
  async getUsers(active, userType, page, pageSize, sortingColumn = 'id', sortingDirection = 'desc') {
    return API()
      .get(`/prospects/BDR${active ? '/users' : ''}`, {
        params: {
          order_by: sortingColumn,
          order_type: sortingDirection,
          page,
          per_page: pageSize,
          user_type: userType,
        },
      })
      .then(({ data }) => ({
        users: data.prospects,
        total: data.total,
      }));
  }

  async searchForUsers(userType, query) {
    return API()
      .get('/prospects/BDR/search', {
        params: {
          search: query,
          user_type: userType,
        },
      })
      .then((res) => res?.data?.users?.map((u) => ({ ...u, full_name: u.name })) || []);
  }

  async cancelCreation(id) {
    return API()
      .post('/prospects/BDR/cancel_creation', {
        id,
      })
      .then((res) => res.data);
  }

  async inviteUser(id, cc, message, sendCopyToSelf, sendVerification) {
    return API()
      .post('/prospects/BDR/send_invite', {
        cc,
        id,
        message,
        send_copy_to_self: sendCopyToSelf,
        send_verification: sendVerification,
      })
      .then((res) => res.data);
  }
}
