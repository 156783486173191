import classNames from 'classnames';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'antd';

import FinishedAuctionsSteps from './FinishedAuctionsSteps/FinishedAuctionsSteps';
import LARCard from 'components/LARDB/LARCard/LARCard';
import { currencyFormatter } from 'constants/getBidValuesData';
import { TrophyIcon } from 'assets/icons/icons';

import styles from './FinishedAuctionsTableColumns.module.css';

export function DealInfoColumnTitle() {
  return (
    <div>
      <div className={styles.columnTitle}>Deal Info</div>
      <div className={styles.columnSubtitle}>Id, Type, Style, Amount, Address</div>
    </div>
  );
}

export function DealInfoColumnContent({ item }) {
  return (
    <div className={classNames([styles.dealInfoWrapper])}>
      <div className={styles.dealInfoId}>{item.id}</div>
      <div className={styles.dealInfoType}>
        {item.type} <span>{item.style}</span>
      </div>
      <div className={styles.dealInfoAmount}>
        <strong>{currencyFormatter(item.amount)}</strong>{' '}
        <span>
          {item.address.address},{item.address.city},{item.address.state}
        </span>
      </div>
    </div>
  );
}

export function AuctionStatsColumnTitle() {
  return (
    <div className={styles.auctionStatsTitle}>
      <div className={styles.columnTitle}>Deal Stats</div>
      <div className={styles.columnSubtitle}>Views, Quotes</div>
    </div>
  );
}

export function AuctionStatsColumnContent({ item }) {
  return (
    <div className={classNames([styles.auctionStatsWrapper])}>
      <div className={styles.auctionStatsItem}>{item.views}</div>
      <div className={styles.auctionStatsItem}>{item.quotes}</div>
    </div>
  );
}

export function EventColumnTitle() {
  return (
    <div>
      <div className={styles.columnTitle}>Event</div>
    </div>
  );
}

export function EventColumnContent({ item }) {
  return <div className={styles.event}>{item}</div>;
}

export function SelectionProcessColumnTitle() {
  return (
    <div>
      <div className={styles.columnTitle}>Selection Process</div>
      <div className={styles.columnSubtitle}>Reviews, Sent to borrower, Decision</div>
    </div>
  );
}

export function SelectionProcessColumnContent({ item }) {
  const [open, setOpen] = useState(false);

  const isLastStep = item.decision.done;
  const isWinner = item.decision.text === 'You won';
  const history = useHistory();
  const { id, winnerBid, ...steps } = item;
  const isVisibleViewWinnerBtn = isLastStep && winnerBid && !isWinner;

  function handleWinnerClick() {
    history.push(`/onboarding/winner/${id}`);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleCancel() {
    setOpen(false);
  }

  return (
    <div className={styles.selectionProcessWrapper}>
      <FinishedAuctionsSteps steps={steps} />
      <div className={styles.selectionProcessActions}>
        {isVisibleViewWinnerBtn && (
          <Button onClick={handleOpen} size="large">
            View Winner
          </Button>
        )}
        {isWinner && (
          <Button size="large" icon={<TrophyIcon className={styles.trophyIcon} />} onClick={handleWinnerClick}>
            You won
          </Button>
        )}
      </div>
      <Modal open={open} onCancel={handleCancel} footer={[]} className={styles.winnerModal}>
        <div className="quote-preview">
          <div className="quote">
            <LARCard data={winnerBid} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
