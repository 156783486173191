import './styles/scoring.css';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import Button from 'components/UI/Button';
import regExp from 'constants/regExp';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import TextInput from 'components/CustomComponents/TextInput';

const defaultScoring = {
  loan_score_min: {
    title: 'Loan Score Min',
    value: '',
    error: false,
  },
  loan_score_max: {
    title: 'Loan Score Max',
    value: '',
    error: false,
  },
  loan_score_weight: {
    title: 'Weight',
    value: '',
    error: false,
  },
  LTV_min: {
    title: 'LTV Score Min',
    value: '',
    error: false,
  },
  LTV_max: {
    title: 'LTV Score Max',
    value: '',
    error: false,
  },
  LTV_weight: {
    title: 'Weight',
    value: '',
    error: false,
  },
  LTC_min: {
    title: 'LTC Score Min',
    value: '',
    error: false,
  },
  LTC_max: {
    title: 'LTC Score Max',
    value: '',
    error: false,
  },
  LTC_weight: {
    title: 'Weight',
    value: '',
    error: false,
  },
  credit_score_min: {
    title: 'Credit Score Min',
    value: '',
    error: false,
  },
  credit_score_max: {
    title: 'Credit Score Max',
    value: '',
    error: false,
  },
  credit_score_weight: {
    title: 'Weight',
    value: '',
    error: false,
  },
  rental_score_min: {
    title: 'Rental Score Min',
    value: '',
    error: false,
  },
  rental_score_max: {
    title: 'Rental Score Max',
    value: '',
    error: false,
  },
  rental_score_weight: {
    title: 'Weight',
    value: '',
    error: false,
  },
  flip_min: {
    title: 'Flips Score Min',
    value: '',
    error: false,
  },
  flip_max: {
    title: 'Flips Score Max',
    value: '',
    error: false,
  },
  flip_weight: {
    title: 'Weight',
    value: '',
    error: false,
  },
};

export default function Scoring() {
  const [scoring, setScoring] = useState(defaultScoring);
  const [error, setError] = useState(false);

  useEffect(() => {
    API()
      .get('/superadmin/settings/score')
      .then((res) => {
        const tempScoring = { ...scoring };

        Object.entries(res.data).forEach((i) => {
          if (tempScoring[i[0]]) {
            tempScoring[i[0]] = {
              ...tempScoring[i[0]],
              value: i[1],
            };
          }
        });

        setScoring(tempScoring);
      });

    // eslint-disable-next-line
  }, []);

  const onChange = (value, route) => {
    const tempFields = { ...scoring };

    tempFields[route] = { ...tempFields[route], value, error: false };

    setError(false);
    setScoring(tempFields);
  };

  const submitScoring = () => {
    const request = {};
    let weightMiddle = 0;

    Object.entries(scoring).forEach((i) => {
      if (i[0].includes('weight')) {
        weightMiddle += +i[1].value;
      }
      request[i[0]] = +i[1].value;
    });

    if (+weightMiddle !== 6) {
      setError('Avarage weight value should be equal 1');
      return;
    }

    API()
      .post('/superadmin/settings/score', request)
      .then(() => {
        message.success('Scoring updated successfully');
      });
  };

  return (
    <div className="scoring-wrapper">
      <SectionTitle type="heading-20" title="Loan Scoring Margin Coefficients Multiplicators" />
      <div className="scoring-list">
        {Object.entries(scoring).map((i, idx) => {
          const field = i[1];
          return (
            <TextInput
              title={field.title}
              key={idx}
              onChange={(e) => {
                const { value } = e.target;
                if (i[0].includes('weight')) {
                  if (regExp.one_number_two_decimal.test(value) || !value) {
                    onChange(value, i[0]);
                  }
                } else if (regExp.one_number_two_decimal.test(value) || !value) {
                  if (+value > 1) {
                    onChange(1, i[0]);
                  } else {
                    onChange(value, i[0]);
                  }
                }
              }}
              maxLength={4}
              value={field.value}
              error={field.error}
              errorMessage="Fill this field"
            />
          );
        })}
      </div>

      <div className="scoring-footer">
        {error && <span style={{ color: 'red' }}>{error}</span>}
        <Button text="Save" onClick={submitScoring} className="btn_darkblue" />
      </div>
    </div>
  );
}
