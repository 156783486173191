import React from 'react';
import OnboardingPreferencesLandingPages from '../../pages/Onboarding/BrokerAdmin/Preferences/LandingPages';
import OnboardingPreferencesCommunication from '../../pages/Onboarding/BrokerAdmin/Preferences/Communication';
import OnboardingPreferencesFees from '../../pages/Onboarding/BrokerAdmin/Preferences/Fees';
import OnboardingPreferencesBrokerPayments from '../../pages/Onboarding/BrokerAdmin/Preferences/Payments';

export const mainNavigationOnboardingPreferencesBroker = [
  {
    exact: true,
    pathname: 'landing_pages',
    label: 'Landing Page Branding',
    path: '/onboarding/preferences/landing_pages',
    main: () => <OnboardingPreferencesLandingPages />,
  },
  {
    exact: true,
    pathname: 'communication',
    label: 'Borrower Communication',
    path: '/onboarding/preferences/communication',
    main: () => <OnboardingPreferencesCommunication />,
  },
  {
    exact: true,
    pathname: 'fees',
    label: 'Broker Fees',
    path: '/onboarding/preferences/fees',
    main: () => <OnboardingPreferencesFees />,
  },
  {
    exact: true,
    pathname: 'payments',
    label: 'Payments',
    path: '/onboarding/preferences/payments',
    main: () => <OnboardingPreferencesBrokerPayments />,
  },
];
