import React from 'react';
import AuthPoster from '../AuthPoster';

export default function BorrowerExpired() {
  return (
    <div className="wrapper_lender_registration">
      <div className="lender_registration_expired">
        <AuthPoster btnText="Send contact request" title="Your invitation expired" />
      </div>
    </div>
  );
}
