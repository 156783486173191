import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <path d="M14 3.5V7.5C14 7.76522 14.1054 8.01957 14.2929 8.20711C14.4804 8.39464 14.7348 8.5 15 8.5H19" />
    <path d="M5 8.5V5.5C5 4.96957 5.21071 4.46086 5.58579 4.08579C5.96086 3.71071 6.46957 3.5 7 3.5H14L19 8.5V19.5C19 20.0304 18.7893 20.5391 18.4142 20.9142C18.0391 21.2893 17.5304 21.5 17 21.5H12" />
    <path d="M6 17.5C7.65685 17.5 9 16.1569 9 14.5C9 12.8431 7.65685 11.5 6 11.5C4.34315 11.5 3 12.8431 3 14.5C3 16.1569 4.34315 17.5 6 17.5Z" />
    <path d="M4.5 17.5L3 22.5L6 21L9 22.5L7.5 17.5" />
  </GenericIcon>
));
