import { GET_SCENARIOS } from '../types/scenarios';

const initialState = {
  scenarios: {
    isReceived: false,
    total: 0,
  },
};

const feesWorksheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCENARIOS:
      return {
        ...state,
        scenarios: {
          ...state.scenarios,
          isReceived: true,
          total: action.payload?.scenarios?.length,
        },
      };
    default:
      return state;
  }
};

export default feesWorksheetReducer;
