import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <path d="M5 9H19" />
    <path d="M5 15H19" />
    <path d="M11 4L7 20" />
    <path d="M17 4L13 20" />
  </GenericIcon>
));
