export const LONG_TERM = 'Long Term';
export const SHORT_TERM = 'Short Term';
export const PURCHASE = 'Purchase';
export const REFINANCE = 'Refinance';
export const BUSINESS = 'Business';

export const DRAFT = 'Draft';
export const INCOMPLETE = 'Incomplete';
export const PENDING_REVIEW = 'Pending Review';
export const PRE_AUCTION = 'Pre Auction';
export const PLA_REVIEW = 'PLA Review';
export const SCHEDULED = 'Scheduled';
export const LOAN_PROCESSING = 'Loan Processing';
export const LIVE_AUCTION = 'Live Auction';
export const QUOTE_SELECTION = 'Quote Selection';
export const INVITES_SENT = 'Invites Sent';
export const NO_WINNER_SELECTED = 'No winner selected';
export const ACTIVE = 'Active';
export const ARCHIVE = 'Archive';
export const CLOSED = 'Closed';
export const PLA_VERIFIED = 'PLA Verified';
export const LAUNCH_APPROVED = 'Launch Approved';

export const I_O = 'I/O';
export const P_I = 'P/I';

export const INCOMPLETE_LAR_STATUSES = [DRAFT, INCOMPLETE, PENDING_REVIEW, PRE_AUCTION];

export const ACTIVE_AUCTIONS = [LIVE_AUCTION, QUOTE_SELECTION, LOAN_PROCESSING, CLOSED];

export const REPORT_FOR_PLA = [LOAN_PROCESSING, ARCHIVE, INVITES_SENT, NO_WINNER_SELECTED];
