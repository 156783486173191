import './bid_card.css';
import classNames from 'classnames';

export default function BidCard({ img, title, handleChoose, bidId, activeBid, description }) {
  return (
    <div
      className={classNames('bid-card', bidId === activeBid && 'bid-card_active')}
      onClick={() => handleChoose(bidId)}
    >
      <div className="bid-card__img-wrapper">
        <img alt="" className="bid-card__img" src={img} width="100%" />
      </div>
      <div className="bid-card__title">{title}</div>
      <p className="bid-card__desc">{description}</p>
    </div>
  );
}
