import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { useCallback, useState, useEffect } from 'react';
import { Typography } from 'antd';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Modal from 'components/Modals/Modal/Modal';
import styles from './TermsEditModal.module.css';

export default function TermsEditModal({
  value,
  name,
  open = false,
  onOk = () => null,
  onCancel = () => null,
  uploadProps,
  ...rest
}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [error, setError] = useState('');
  const [text, setText] = useState(value);

  const resetState = useCallback(() => {
    if (!value) {
      return;
    }

    const contentBlock = htmlToDraft(value);

    if (!contentBlock) {
      return;
    }

    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)));
  }, [value]);

  useEffect(resetState, [resetState]);
  useEffect(() => open || resetState(), [open, resetState]);

  return (
    <Modal
      {...rest}
      title="Add additional bid terms"
      open={open}
      onCancel={onCancel}
      okButtonProps={{
        disabled: error,
      }}
      onOk={() => {
        onOk({ target: { value: text, name } });
        onCancel();
      }}
    >
      <div className={styles.editorWrapper}>
        <Editor
          toolbar={{
            options: ['inline', 'fontSize', 'list', 'textAlign'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
          }}
          editorState={editorState}
          onEditorStateChange={(state) => {
            setEditorState(state);
            const rawContentState = convertToRaw(state.getCurrentContent());
            const markup = draftToHtml(rawContentState, undefined, true, (_, text) => text);
            if (markup.length > 4999) {
              setError('The maximum allowed length for this field is 5000 characters.');
            } else {
              setError('');
            }

            setText(markup);
          }}
        />
      </div>
      {error && <Typography.Text type="danger">{error}</Typography.Text>}
    </Modal>
  );
}
