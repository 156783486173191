import classNames from 'classnames';
import { useCallback, useEffect, useReducer, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import { Spin, message } from 'antd';
import { isEmpty } from 'lodash';

import { updateDashboardMetricsTimestamp } from 'store/actions/dashboard';
import { DashboardAPI, LARAPI } from 'api';

import { ClockIcon, DollarIcon, SharpIcon, RejectIcon } from 'assets/icons';
import { TrophyIcon } from 'assets/icons/icons';
import ActionsBar from 'components/OnBoarding/Scenarios/ActionsBar';
import Header from 'components/OnBoarding/Scenarios/Header';
import LoanProfile from 'pages/Onboarding/LoanProfile';
import MainLayout from 'components/Layouts/MainLayout';
import PassModal from 'components/OnBoarding/Scenarios/PassModal';
import Placeholder from 'components/OnBoarding/Scenarios/Placeholder';
import PublicMessages from 'components/OnBoarding/PublicMessages/PublicMessages';
import ReassignLARModal from 'components/LoanProfile/ReassignLARModal';
import RespondDrawer from 'components/OnBoarding/Scenarios/RespondDrawer';
import ScenarioBid from 'components/OnBoarding/ScenarioBid';
import PlaceBidDrawer from './BidApplication/PlaceBidDrawer';
import Response from 'components/OnBoarding/ScenarioBids/Responses/Response';
import ScenarioNav from 'components/OnBoarding/Scenarios/ScenarioNav';
import Box from 'components/UI/Box';
import Timeline from 'components/Timeline';
import Tooltip from 'components/UI/Tooltip/Tooltip';

import styles from './styles/Scenarios.module.css';
import ShareLoanProfileModal from 'components/LoanProfile/ShareLoanProfileModal';

const sortingOptions = [
  {
    Icon: SharpIcon,
    name: 'id',
  },
  {
    Icon: DollarIcon,
    name: 'property_value',
  },
  {
    Icon: ClockIcon,
    name: 'last_update',
  },
];

function Scenarios() {
  const dispatch = useDispatch();
  const { id: activeItemId } = useParams();
  const history = useHistory();

  const [activeDrawer, setActiveDrawer] = useState({ show: '', data: null });
  const [activeModal, setActiveModal] = useState({ show: '', data: null });
  const [activeSortingOption, setActiveSortingOption] = useState('id');
  const [data, setData] = useState({ ready: false, items: [], auction: {} });
  const [forceProfileUpdate, updateLoanProfile] = useReducer((s) => ++s, 0);
  const [showNav, toggleNav] = useReducer((s) => !s, true);
  const [team, setTeam] = useState([]);

  const { timestamp } = useSelector((s) => s.chat);
  const role = useSelector((s) => s.user.userData?.role);
  const isLender = /lender/i.test(role);
  const isAdmin = /Lender Admin|Broker Admin/i.test(role);

  function closeActiveDrawer(navigateUp = false) {
    setActiveDrawer({ show: '', data: null });

    updateItemList();

    if (navigateUp) {
      history.push('/onboarding/scenarios');
    } else {
      updateLoanProfile();
    }
  }

  const API = useMemo(() => new DashboardAPI(), []);

  const setActiveItemId = useCallback(
    (id) => {
      const currentId = Number(activeItemId);

      if (currentId === id) {
        return;
      }

      history.push(`/onboarding/scenarios/${id}`);
    },
    [activeItemId, history],
  );

  const updateItemList = useCallback(
    (isRerender = false) => {
      API.getScenariosList(activeSortingOption).then((items) => {
        setData({
          items,
          ready: true,
        });

        if ((isRerender || !+activeItemId) && !isEmpty(items)) {
          setActiveItemId(items[0].id);
        }

        if (isRerender && isEmpty(items)) {
          history.push('/onboarding/scenarios');
        }
      });
    },
    [API, activeItemId, timestamp, setActiveItemId, activeSortingOption],
  );

  useEffect(updateItemList, [updateItemList]);

  const larAPI = useMemo(() => new LARAPI(Number(activeItemId)), [activeItemId]);

  const activeItem = useMemo(
    () => data.items.find((i) => i.id === Number(activeItemId)) || {},
    [data.items, activeItemId],
  );

  function handleReassign(data) {
    return larAPI.reassign(data.userId, data.message).then(() => {
      message.success(
        isAdmin
          ? 'Deal is successfully reassigned. You are Admin, so you still can see the deal'
          : 'Scenario successfully reassigned!',
        isAdmin ? 5 : 3,
      );
      dispatch(updateDashboardMetricsTimestamp());
      updateItemList(true);
    });
  }

  useEffect(() => {
    if (!activeItem.id) {
      return;
    }

    larAPI.getTeam().then((data) => setTeam(data));
  }, [larAPI, activeItem]);

  /* Handle websocket events */
  // This is all untested btw
  const token = useSelector((s) => s.user.userData?.token);
  const socket = useMemo(() => io(`${process.env.REACT_APP_API_URL}/scenario`, { query: { jwt: token } }), [token]);
  useEffect(() => {
    if (!token) {
      return;
    }

    socket.on('connect', () => {
      socket.emit('connect_to_scenario');
    });

    socket.on('scenario_updates', ({ action, bid }) => {
      switch (action) {
        case 'new_respond':
          // TODO: Insert new item in the correct place
          // depending on the selected sorting method
          setData((prev) => ({
            ...prev,
            items: [...prev.items, bid],
          }));
          break;

        case 'bid_deleted':
        case 'bid_updated':
        case 'new_bid':
        case 'respond_updated':
        default:
          // TODO: Handle these events
          console.log('Scenarios ws unhandled event');
      }
    });

    socket.connect();
    return () => {
      socket.emit('disconnect_from_scenario');
      socket.disconnect();
    };
  }, [socket, activeItemId, token]);

  function submitShare({ cc, message, send_copy_to_self, to }) {
    larAPI.share(cc, message, send_copy_to_self, to).then(() => setActiveModal({ show: '', data: null }));
  }

  return (
    <MainLayout title="Active Scenarios">
      <div
        className={classNames(styles.root, {
          [styles.noNavRoot]: !showNav,
          [styles.smallContentWidth]: activeDrawer.show,
        })}
      >
        <Header
          activeSortingOption={activeSortingOption}
          setActiveSortingOption={setActiveSortingOption}
          showNav={showNav}
          id={activeItemId}
          sortingOptions={sortingOptions}
          toggleNav={toggleNav}
        />
        {showNav && (
          <Spin spinning={!data.ready}>
            <ScenarioNav
              larId={activeItemId}
              activeItemId={activeItem.id}
              items={data.items}
              onSetActiveItemId={setActiveItemId}
            />
          </Spin>
        )}
        {activeItemId ? (
          <>
            <ActionsBar
              isQuoteSelected={activeItem.bid?.timeline.some((item) => item.action === 'Quote Accepted')}
              bidPlaced={activeItem?.bid?.bid_status === 'Quote Provided'}
              canSendMessage={activeItem?.scenario?.can_send_message}
              hasNewMessages={activeItem?.scenario?.new_messages}
              id={activeItem.id}
              isResponded={!!activeItem.response}
              openChooseBidDrawer={(_, editMode = false) => {
                setActiveDrawer({
                  show: 'place_bid',
                  data: {
                    editMode,
                  },
                });
              }}
              onOpenRespondDrawer={() => {
                setActiveDrawer({
                  show: 'respond',
                  data: {},
                });
              }}
              onSetActiveModal={setActiveModal}
            />
            <main>
              <PublicMessages larId={activeItem.id} isScenario />
              <Timeline timeline={activeItem.bid?.timeline} />
              {activeItem.bid?.timeline.some((item) => item.action === 'Quote Accepted') && (
                <Tooltip
                  type="success"
                  icon={<TrophyIcon className={styles.trophyIcon} />}
                  size="large"
                  text={`You're quote was accepted. This scenario has been converted to an Active Loan #${activeItemId} and will be removed from scenarios list at 00:00 ET`}
                />
              )}
              {activeItem?.status === 'Quote rejected' && activeItem.bid?.reject_reason && (
                <Tooltip
                  size="large"
                  title="Quote rejected"
                  icon={<RejectIcon colour="white" strokeWidth="1" />}
                  text={activeItem.bid?.reject_reason || 'Direct quote terms are not suitable for borrower'}
                />
              )}
              {activeItem.bid?.loan_amount > 0 &&
                isLender &&
                activeItem.bid?.action_indicator?.last_action_indicator !== 'Rescinded' && (
                  <Box>
                    <ScenarioBid meta={activeItem.bid} />
                  </Box>
                )}
              {activeItem.response && <Response data={activeItem.response} />}
              <LoanProfile minimal forceUpdate={forceProfileUpdate} />
            </main>
          </>
        ) : (
          <Placeholder />
        )}
        <PlaceBidDrawer
          activeDrawer={activeDrawer}
          edit={!['viewed', null].includes(activeItem.status || activeItem?.bid?.bid_status)}
          mask={false}
          onClose={() => closeActiveDrawer()}
          onPlaceBid={() => closeActiveDrawer()}
          openChooseBidDrawer={() => {
            setActiveDrawer({ show: '', data: null });
          }}
          title={false}
          visible={['place_bid', 'edit_bid'].includes(activeDrawer.show)}
        />
        <ReassignLARModal
          team={team}
          visible={activeModal.show === 'reassign_lar'}
          onReassignLar={handleReassign}
          onClose={() => setActiveModal({ show: '', data: null })}
        />
        <ShareLoanProfileModal
          larId={activeItem?.id}
          onCloseActiveModal={() => setActiveModal({ show: '', data: null })}
          submitShare={submitShare}
          team={team}
          visible={activeModal.show === 'share_profile'}
        />
        <RespondDrawer
          currentBidData={activeItem}
          onClose={() => closeActiveDrawer()}
          visible={activeDrawer.show === 'respond'}
        />
        <PassModal
          API={larAPI}
          onCancel={() => setActiveModal({ show: '', data: null })}
          rescind={activeItem.status === 'Quote Provided' || activeItem?.bid?.bid_status === 'Quote Provided'}
          visible={activeModal.show === 'pass'}
        />
      </div>
    </MainLayout>
  );
}

export default Scenarios;
