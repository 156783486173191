import { SearchOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import IconButton from '../../UI/IconButton';
import styles from './styles/ChatHeader.module.css';
import hideIcon from '../../../assets/icons/arrow-bar-left.svg';
import backIcon from '../../../assets/icons/arrow-left.svg';
import archiveIcon from '../../../assets/icons/archive.svg';
import MainLayout from '../../Layouts/MainLayout';

function ChatHeader({
  type,
  handleHideArchive,
  handleHideMessages,
  handleSearch,
  handleArchive,
  showArchive,
  isOpenMessagesList,
}) {
  const handleShowArchive = () => {
    handleArchive(true);
  };

  return (
    <MainLayout title={!type ? 'Messages' : ''}>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          {showArchive && (
            <IconButton
              icon={backIcon}
              alt="back"
              text="Back"
              handleClick={handleHideArchive}
              classes="icon-button-blue"
            />
          )}
          <h3 className={styles.title}>{showArchive ? 'Archived conversations' : 'Messages'}</h3>
          <IconButton
            icon={hideIcon}
            alt="hide"
            handleClick={handleHideMessages}
            classes={(type === 'sidebar' && styles.hide) || (!isOpenMessagesList && styles.show)}
          />
        </div>
        <div className={styles.searchAndArchiveWrapper}>
          <Tooltip title="Archived chats">
            <IconButton
              icon={archiveIcon}
              alt="show-archive"
              handleClick={handleShowArchive}
              classes="icon-button-with-bg"
            />
          </Tooltip>
          <Input
            className={styles.inputWrapper}
            addonAfter={<SearchOutlined style={{ fontSize: '16px' }} />}
            onChange={({ target }) => handleSearch(target.value)}
            placeholder="Member, Scenario, Auction, Active Loan"
          />
        </div>
      </div>
    </MainLayout>
  );
}

export default ChatHeader;
