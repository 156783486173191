import 'components/UI/Button/custom_button.css';
import classNames from 'classnames';

export default function CustomButton({
  autofocus,
  className,
  disabled,
  hoverDisabled,
  img,
  onClick,
  primary,
  svg,
  text,
  type,
  ...rest
}) {
  return (
    <button
      {...rest}
      autoFocus={autofocus}
      className={classNames(
        'btn_default',
        {
          btn_darkblue: primary,
          btn_disabled: disabled,
          btn_hover_disabled: hoverDisabled,
          btn_only_text: !svg && !img,
        },
        className,
      )}
      disabled={disabled}
      onClick={onClick || null}
      type={type ?? 'button'}
    >
      {svg || (img ? <img style={{ marginRight: 4 }} src={img} alt="" /> : null)}
      {text && <span>{text}</span>}
    </button>
  );
}
