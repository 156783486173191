import PropTypes from 'prop-types';
import { Badge } from 'antd';
import classNames from 'classnames';

import { ConversationIcon, MessageIcon } from 'assets/icons';

import styles from './ChatIndicator.module.css';

function ChatIndicator({ isVisible, hasIndicator, hasNoBackground, type, badgeColor, className }) {
  if (!isVisible) {
    return null;
  }

  const isLightIcon = !hasNoBackground && hasIndicator;
  const iconColor = isLightIcon ? 'white' : 'grey-7';
  let color;

  if (hasNoBackground) {
    color = 'grey';
  } else if (badgeColor === 'yellow') {
    color = 'yellow';
  } else {
    color = 'red';
  }

  return (
    <div className={classNames(styles.wrapper, className, hasIndicator && styles.hasIndicator)}>
      <Badge dot={hasIndicator} offset={[-4, 18]} color={color}>
        {type === 'primary' ? <MessageIcon colour={iconColor} /> : <ConversationIcon colour={iconColor} />}
      </Badge>
    </div>
  );
}

export default ChatIndicator;

ChatIndicator.defaultProps = {
  type: 'primary',
};

ChatIndicator.propTypes = {
  isVisible: PropTypes.bool,
  hasIndicator: PropTypes.bool,
  hasNoBackground: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary']),
  badgeColor: PropTypes.string,
  className: PropTypes.string,
};
