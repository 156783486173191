import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import 'components/UI/Accordion/accordion.css';

const { Panel } = Collapse;

function Accordion({ panels, defaultActiveKeys, maxWidth }) {
  return (
    <Collapse defaultActiveKey={defaultActiveKeys} collapsible="header">
      {panels.map(({ header, key, content, extra }) => (
        <Panel
          className="custom-panel"
          header={header}
          key={key}
          extra={
            <>
              {extra}
              <div className="accordion-title-line" />
            </>
          }
        >
          <section style={{ maxWidth }}>{content}</section>
        </Panel>
      ))}
    </Collapse>
  );
}

export default Accordion;

Accordion.propTypes = {
  panels: PropTypes.array.isRequired,
  defaultActiveKey: PropTypes.array,
  maxWidth: PropTypes.string,
};
