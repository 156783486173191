import { Modal } from 'antd';
import styles from './styles.module.css';

export default function ApiChangeStatusModal({ isOpen, onCancel }) {
  return (
    <Modal
      okText="Confirm"
      width={660}
      okButtonProps={{ type: 'dark' }}
      title={<p style={{ fontSize: 18 }}>Confirm API Token Status Change</p>}
      bodyStyle={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 10, paddingTop: 0 }}
      // onOk={handleContinue}
      open={isOpen}
      onCancel={onCancel}
    >
      <div className={styles.container}>
        You are about to change status of the API token for the selected company.
        <ul style={{ marginLeft: -20 }}>
          <li>Enabling the token will allow the API connections to be re-established.</li>
          <li>Disabling the token will disconnect any active API connections and prevent future connections.</li>
        </ul>
      </div>
    </Modal>
  );
}
