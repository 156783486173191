import React from 'react';
import { Select, Space, Tag } from 'antd';

function SelectMultiple(props) {
  const { title, wrapperStyle, error, errorMessage, onChange, options, value, name, disabled, onDelete, showSearch } =
    props;

  return (
    <div style={{ width: '100%', ...wrapperStyle }} className={`select-tags ${error ? 'input_error' : ''}`}>
      {title && (
        <label htmlFor={name} className="input_title">
          {title}
        </label>
      )}
      <Select
        disabled={disabled}
        name={name}
        onChange={(e) => onChange(e, name)}
        showSearch={showSearch}
        style={{ width: '100%' }}
        value=""
      >
        {options
          .filter((i) => !value.includes(i))
          .map((i, index) => (
            <Select.Option value={typeof i === 'object' ? i.value : i} key={index}>
              {typeof i === 'object' ? i.preview : i}
            </Select.Option>
          ))}
      </Select>
      {error && <p className="errorMessage">{errorMessage}</p>}
      <Space size="8px" direction="horizontal">
        {value.map((i, index) => (
          <Tag closable={!disabled} key={index} onClose={() => onDelete(i, name)}>
            {i}
          </Tag>
        ))}
      </Space>
    </div>
  );
}

export default SelectMultiple;
