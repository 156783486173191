import { Form, Input, Select } from 'antd';

function Type({ form, setTotalUnitsRange, onFieldsChange }) {
  const propertyType = Form.useWatch('property_type', form);
  const setFieldAsync = async (name, value) => {
    await form.setFields([
      {
        name,
        value,
      },
    ]);
  };

  return (
    <>
      <Form.Item label="Type" className="scenario-required-field auction-required-field">
        <Form.Item label="" name="property_type">
          <Select
            style={{ width: 215 }}
            placeholder="Select a property type"
            onChange={(e) => {
              if (e === '2-4 Unit') {
                setTotalUnitsRange([2, 4]);
              }
              if (e === 'MultiFamily 5-20') {
                setTotalUnitsRange([5, 1000]);
              }
              setFieldAsync('property_type', e).then(() => {
                const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
                  name: el[0],
                  value: el[1],
                }));
                onFieldsChange([1], valuesArr);
              });
            }}
            options={[
              { value: 'SFR', label: 'SFR' },
              { value: 'Town/Rowhome', label: 'Town/Rowhome' },
              { value: 'Manufactured', label: 'Manufactured' },
              { value: 'Condo', label: 'Condo' },
              { value: '2-4 Unit', label: '2-4 unit' },
              { value: 'MultiFamily 5-20', label: 'Multifamily 5+' },
              ...[
                'Portfolio',
                'Mixed-Use',
                'Land (Speculative)',
                'Mobile Home Park',
                'Commercial - Retail',
                'Commercial - Office',
                'Commercial - Industrial',
                'Commercial - Lodging',
                'Commercial - Other',
              ].map((value) => ({ value, label: value })),
              { value: 'Short Term Rental', label: 'Short term rental (AirBnB)' },
              { value: 'Other', label: 'Other' },
            ]}
          />
        </Form.Item>
      </Form.Item>
      {['Portfolio', 'Mixed-Use', 'Other'].includes(propertyType) && (
        <Form.Item
          className="scenario-required-field auction-required-field"
          label="Description"
          name="property_type_description"
          style={{ marginBottom: '24px' }}
        >
          <Input.TextArea autoSize style={{ maxWidth: 445 }} rows={2} />
        </Form.Item>
      )}
    </>
  );
}

export default Type;
