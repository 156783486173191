import { Row } from 'antd';
import React from 'react';
import SectionTitle from '../../../components/OnBoarding/SectionTitle';
import LenderDocsBreadcrumb from '../../../components/OnBoarding/LenderAdmin/LenderDocsBreadcrumb';
import LenderDocsCreateForm from '../../../components/OnBoarding/LenderAdmin/LenderDocsCreateForm';

function LenderDocsCreate() {
  return (
    <div>
      <div className="section_title_wrapper" style={{ marginBottom: 24 }}>
        <LenderDocsBreadcrumb />
      </div>
      <div className="section_title_wrapper">
        <Row justify="space-between" align="middle">
          <SectionTitle title="Create Loan Application Document" />
        </Row>
      </div>
      <div style={{ marginTop: '24px' }}>
        <LenderDocsCreateForm />
      </div>
    </div>
  );
}
export default LenderDocsCreate;
