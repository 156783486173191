import { Breadcrumb, Button } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useReducer } from 'react';

import { ArrowCircleIcon, FlagIcon, HomeIcon, MessagePlusIcon } from 'assets/icons';
import ArchivalReasonPlaque from 'components/ArchivalReasonPlaque';
import ConvertToLARModal from './ConvertToLARModal';
import EndScenarioModal from './EndScenarioModal';
import ReassignButton from '../../ReassignButton';

import styles from './styles/Header.module.css';

export default function Header({ API, openPublicMessageDrawer = () => null, scenario = {} }) {
  const { id } = useParams();
  const history = useHistory();

  const [convertToLARModalVisible, toggleConvertToLARModal] = useReducer((s) => !s, false);
  const [endScenarioModalVisible, toggleEndScenarioModal] = useReducer((s) => !s, false);

  const isArchive = !!scenario.reject_reason;

  function navigateToLoanProfile() {
    history.push(`/onboarding/loan_profile/${id}`);
  }

  function navigateToDashboard() {
    history.push('/onboarding/dashboard');
  }

  function convertToLAR() {
    API.convertToLAR().then(navigateToDashboard).finally(toggleConvertToLARModal);
  }

  function endScenario({ reason, additionalInfo }) {
    API.archive(reason, additionalInfo).then(navigateToDashboard).finally(toggleEndScenarioModal);
  }

  // function navigateToEdit() {
  //   history.push(`/lar_app/create?lar_id=${id}&scenario=true`);
  // }

  return (
    <header className={styles.root}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link
            to={{
              pathname: '/onboarding/dashboard',
              state: {
                activeTab: 'scenarios',
              },
            }}
          >
            Scenarios
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Scenario #{id}</Breadcrumb.Item>
      </Breadcrumb>
      <ArchivalReasonPlaque
        className={styles.archivalReasonPlaque}
        id={Number(id)}
        onUnarchive={navigateToDashboard}
        reason={scenario.reject_reason}
      />
      <h1>{scenario.name || `Scenario #${id}`}</h1>
      <div className={styles.brokerScenariosButtons}>
        {isArchive || (
          <Button onClick={openPublicMessageDrawer}>
            <MessagePlusIcon />
            Public Message
          </Button>
        )}
        <Button onClick={navigateToLoanProfile}>
          <HomeIcon />
          View profile
        </Button>
        <ReassignButton isLender={false} larId={id} />
        {isArchive || (
          <>
            <Button onClick={toggleConvertToLARModal}>
              <ArrowCircleIcon />
              Convert to Auction
            </Button>
            <Button onClick={toggleEndScenarioModal}>
              <FlagIcon />
              End this scenario
            </Button>
            {/* <Button onClick={navigateToEdit}>
              <EditIcon />
            </Button> */}
          </>
        )}
      </div>
      <ConvertToLARModal
        larId={id}
        onCancel={toggleConvertToLARModal}
        onOk={convertToLAR}
        open={convertToLARModalVisible}
      />
      <EndScenarioModal onCancel={toggleEndScenarioModal} onOk={endScenario} visible={endScenarioModalVisible} />
    </header>
  );
}
