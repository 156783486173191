import React from 'react';

export default function PaginationItemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <span className="btn_darkblue pagination_btn">Previous</span>;
  }
  if (type === 'next') {
    return <span className="btn_darkblue pagination_btn">Next</span>;
  }
  return originalElement;
}
