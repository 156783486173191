import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  Button,
} from 'antd';
import React, { useEffect, useState } from 'react';
import BinIcon from 'assets/icons/BinIcon';
import { PlusOutlined } from '@ant-design/icons';

const generateKey = (data) => {
  const key = Math.round(Math.random() * 1000);
  const isExist = data.findIndex((el) => el.key === key) !== -1;
  if (isExist) {
    return generateKey(data);
  }
  return key;
};

function EditableCell({
  editing,
  dataIndex,
  title,
  type,
  record,
  index,
  children,
  main_borrower_first_name,
  main_borrower_last_name,
  dataSourceLength,
  ...restProps
}) {
  return (
    <td {...restProps}>
      {editing ? (
        type === 'number' ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
            ]}
          >
            {type === 'number' ? <InputNumber max={850} min={350} style={{ minWidth: 120 }} /> : null}
          </Form.Item>
        ) : type === 'text' ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            initialValue={
              dataIndex === 'first_name' && dataSourceLength === 1
                ? main_borrower_first_name
                : dataIndex === 'last_name' && dataSourceLength === 1
                ? main_borrower_last_name
                : ' '
            }
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
            ]}
          >
            {type === 'text' ? <Input /> : null}
          </Form.Item>
        ) : type === 'select' ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
            ]}
          >
            {type === 'select' ? (
              <Select>
                <Select.Option value={10}>10%</Select.Option>
                <Select.Option value={20}>20%</Select.Option>
                <Select.Option value={30}>30%</Select.Option>
                <Select.Option value={40}>40%</Select.Option>
                <Select.Option value={50}>50%</Select.Option>
                <Select.Option value={60}>60%</Select.Option>
                <Select.Option value={70}>70%</Select.Option>
                <Select.Option value={80}>80%</Select.Option>
                <Select.Option value={90}>90%</Select.Option>
                <Select.Option value={100}>100%</Select.Option>
              </Select>
            ) : null}
          </Form.Item>
        ) : null
      ) : (
        children
      )}
    </td>
  );
}

export default function EntityOwnersTable(props) {
  const { dataSource, setDataSource, main_borrower_first_name, main_borrower_last_name } = props;
  const dataSourceLength = dataSource?.length;
  const [error, setError] = useState('');
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      percent_ownership: '',
      fico: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    form.resetFields();
    setEditingKey('');
  };

  function getPercentageSum(data) {
    const percentageSum = data.reduce((acc, cur) => acc + cur.percent_ownership, 0);
    return percentageSum;
  }

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        if (getPercentageSum(newData) > 100) {
          setError('Total ownership cannot be greater than 100%');
          return;
        }
        setDataSource(newData);
        setEditingKey('');
        setError('');
      } else {
        newData.push(row);
        if (getPercentageSum(newData) > 100) {
          setError('Total ownership cannot be greater than 100%');
          return;
        }
        setDataSource(newData);
        setEditingKey('');
        setError('');
      }
      form.resetFields();
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo);
    }
  };

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const defaultColumns = [
    {
      title: 'First name',
      type: 'text',
      dataIndex: 'first_name',
      editable: true,
      key: 'first_name',
    },
    {
      title: 'Last name',
      dataIndex: 'last_name',
      type: 'text',
      editable: true,
      key: 'last_name',
    },
    {
      title: '% Ownership',
      dataIndex: 'percent_ownership',
      type: 'select',
      editable: true,
      key: 'percent_ownership',
    },
    {
      title: 'FICO',
      type: 'number',
      editable: true,
      key: 'fico',
      dataIndex: 'fico',
    },
    {
      title: 'Signer',
      dataIndex: 'signer',
      key: 'signer',
      render: (_, record) => (
        <Checkbox
          onChange={(e) => {
            setDataSource((prevState) => {
              const newState = [...prevState];
              const elemIndex = newState.findIndex((el) => el.key === record.key);
              if (elemIndex >= 0) {
                newState[elemIndex].signer = e.target.checked;
              }
              return newState;
            });
          }}
          checked={record.signer}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        const elemIndex = dataSource.findIndex((el) => el.key === record.key);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
                whiteSpace: 'nowrap',
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={cancel}
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              Cancel
            </Popconfirm>
          </span>
        ) : (
          <Space>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              Edit
            </Typography.Link>
            {elemIndex >= 1 && editingKey === '' ? (
              <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                <BinIcon />
              </Popconfirm>
            ) : null}
          </Space>
        );
      },
    },
  ];

  const handleAdd = (usePrefill = false) => {
    const key = generateKey(dataSource);
    const newData = {
      key,
      first_name: usePrefill ? main_borrower_first_name : null,
      fico: null,
      last_name: usePrefill ? main_borrower_last_name : null,
      signer: true,
      percent_ownership: null,
      relationships: null,
    };
    setDataSource([...dataSource, newData]);
    setEditingKey(key);
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        type: col.type,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        main_borrower_first_name,
        main_borrower_last_name,
        handleSave,
        dataSourceLength,
      }),
    };
  });

  useEffect(() => {
    if (dataSource.length === 0) {
      handleAdd(true);
    }
    // eslint-disable-next-line
  }, [dataSource]);

  const disabledAddOwner = dataSourceLength >= 3;
  return (
    <>
      <Form form={form} component={false}>
        <Table
          columns={columns}
          dataSource={dataSource}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={false}
          rowClassName={() => 'editable-row'}
          size="middle"
        />
      </Form>
      <Row align="middle" justify="end" style={{ marginTop: 8 }}>
        <Tooltip title={disabledAddOwner ? 'Max length is 3!' : null}>
          <Button
            size="large"
            icon={<PlusOutlined />}
            onClick={() => handleAdd()}
            disabled={disabledAddOwner || editingKey !== ''}
          >
            Add entity owner
          </Button>
        </Tooltip>
      </Row>
      {error && <span className="error">{error}</span>}
    </>
  );
}
