import { List, Modal } from 'antd';
import { useContext, useMemo, useRef, useReducer } from 'react';
import API from 'api';
import getBidValuesData, { currencyFormatter } from 'constants/getBidValuesData';
import LARDBContext from 'pages/Onboarding/LARDB/LARDBContext';
import BidTableItem from './BidTableItem';
import ActionsFooter from '../ActionsFooter';

// TODO: This component is not currently used anywhere, but should be in the future
export default function BidTable({
  closeDiligenceItem,
  goToEditBid,
  role,
  viewDiligence,
  setViewDiligence,
  setPlaceBidEdit,
  isPreview,
  placeBidEdit,
  diligenceMode = false,
}) {
  const [deleteModalData, setDeleteModalData] = useReducer((_, id) => (id ? { item_id: id } : {}), {});
  const { loan_purpose, loan_style } = useContext(LARDBContext);
  const tableRef = useRef();

  function handleDeleteBid() {
    const bid_id = deleteModalData.item_id;

    API()
      .delete('/LAR/dd_center/bid', {
        params: {
          bid_id,
          item_id: viewDiligence.id,
        },
      })
      .then(() => {
        setViewDiligence((prev) => ({
          ...prev,
          bids: prev.bids.filter((i) => bid_id !== i.id),
        }));

        setDeleteModalData();
      });
  }

  const bidValuesData = useMemo(() => {
    const data = getBidValuesData(loan_purpose, loan_style);

    data.unshift({
      name: 'Loan Amount',
      value: 'loan_amount',
      format: currencyFormatter,
    });

    return data;
  }, [loan_purpose, loan_style]);

  return (
    <div className="document-view">
      <Modal
        onCancel={() => setDeleteModalData()}
        onOk={handleDeleteBid}
        title="Delete bid"
        visible={'item_id' in deleteModalData}
      >
        <p>Do you really want to delete bid?</p>
      </Modal>
      <div className="bid-table bid-table_small-spacing" ref={tableRef}>
        <div className="bid-table__inner">
          <div className="bid-table-list__list-item bid-table-list__list-item_starter">
            <div className="bid-table-list__list-item-header bid-table-list__list-item-header_starter-item">
              <div className="bid-table-list__list-item-header-amount">
                <b>Description</b>
              </div>
            </div>
            <div className="bid-table-list__list-item-body">
              <div className="bid-table-list__info-block">
                {bidValuesData?.map((el, id) => (
                  <div
                    key={el.name + id}
                    className="bid-table-list__info-block-item bid-table-list__info-block-item_label"
                  >
                    {el.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <List
            className={`bid-table-list ${
              diligenceMode ? 'bid-table-list_gray' : ''
            } bid-table-list_responsible bid-table-list_responsible--1200`}
            dataSource={[...viewDiligence?.bids].reverse()}
            grid={{ gutter: 0, column: viewDiligence?.bids?.length }}
            renderItem={(item, id) => (
              <List.Item>
                <BidTableItem
                  placeBidEdit={placeBidEdit}
                  isPreview={isPreview}
                  setPlaceBidEdit={setPlaceBidEdit}
                  diligenceMode={diligenceMode}
                  bidsLength={viewDiligence?.bids?.length}
                  bidValuesData={bidValuesData}
                  goToEditBid={goToEditBid}
                  id={id}
                  item={item}
                  openDeleteModal={setDeleteModalData}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
      <ActionsFooter
        closeDiligenceItem={closeDiligenceItem}
        documentType="bid_terms_updates"
        itemId={viewDiligence.id}
        role={role}
        stage={viewDiligence.item_stage}
      />
    </div>
  );
}
