import { acceptedMimes } from 'constants/mimes';
import { Form, Space } from 'antd';
import { useContext } from 'react';
import formSettings from '../../helpers/formSettings';
import LarAppUpload from './LarAppUpload';
import LarAppContext from '../../context/LarAppContext';

function UploadForm() {
  const { files } = useContext(LarAppContext);
  return (
    <Form {...formSettings}>
      <Space direction="vertical" size={24}>
        <Space direction="vertical" size="small">
          <h3>
            <b>Attach picture or video</b>
          </h3>
          <LarAppUpload
            acceptedMimes={acceptedMimes.larMedia}
            ytAllowed
            initFileList={files
              .filter((el) => el.type === 'youtube' || el.type === 'image')
              .map((el) => ({
                fromBack: true,
                id: el.id,
                file_name: el.file_name,
                desc: el.description, // Description provided by user
                yt: el.type === 'youtube' ? el.elem : undefined, // Undefined if there's a file
                file: {
                  name: `${el.type}.jpg`, // File name
                  url: el.type === 'youtube' ? undefined : el.elem, // Only present in files that came from backend
                },
              }))}
          />
        </Space>
        <Space direction="vertical" size="small">
          <h3>
            <b>Upload document</b>
          </h3>
          <LarAppUpload
            acceptedMimes={acceptedMimes.larDocs}
            initFileList={files
              .filter((el) => el.type === 'document')
              .map((el) => ({
                fromBack: true,
                id: el.id,
                file_name: el.file_name,
                desc: el.description, // Description provided by user
                yt: undefined, // Undefined if there's a file
                file: {
                  name: `${el.type}.pdf`, // File name
                  url: el.elem, // Only present in files that came from backend
                },
              }))}
          />
        </Space>
      </Space>
    </Form>
  );
}
export default UploadForm;
