import { memo, useEffect, useState, useMemo } from 'react';
import { SettingsAPI } from 'api';
import { useSelector } from 'react-redux';
import defaultLogo from 'assets/images/logo.png';
import styles from './styles/Logo.module.css';

export default memo(({ onClick = () => null }) => {
  const [brokerLogoSrc, setBrokerLogoSrc] = useState(null);
  const { fullLeftPanelSize, userData } = useSelector((s) => s.user);

  // Load custom logo for brokers, borrowers and lenders
  const API = useMemo(() => new SettingsAPI(), []);
  useEffect(() => {
    if (!/broker|borrower|lender/i.test(userData?.role)) {
      return;
    }

    API.getCompanyLogoURI().then(setBrokerLogoSrc);
  }, [API, userData]);

  return (
    <div className={styles.root} onClick={onClick}>
      {fullLeftPanelSize || <img src={brokerLogoSrc || defaultLogo} alt="Logo" />}
    </div>
  );
});
