import { Route } from 'react-router-dom';
import Navigations from 'routers/Navigations/index';
import PageHeader from 'components/OnBoarding/PageHeader/PageHeader';
import starportLogo from 'assets/images/starportLogo.png';
import Logo from '../../../components/OnBoarding/Logo';

export default function MainRouter() {
  const isConfirmPage = window.location.href.includes('/confirm');

  return (
    <>
      {isConfirmPage ? (
        <img src={starportLogo} alt="" style={{ width: '100%', alignSelf: 'center', padding: '0 8px' }} />
      ) : (
        <Logo />
      )}
      <PageHeader registration />
      <div className="main-registration-wrapper-page">
        {Navigations.mainNavigationRegisterBorrower.map((route) => (
          <Route path={route.path} exact component={route.main} key={route.path} />
        ))}
      </div>
    </>
  );
}
