import moment from 'moment';
import { useEffect, useState } from 'react';
import { message, Typography } from 'antd';

import API from 'api';

import Modal from '../../Modals/Modal';

export default function DelayArchivePopup({ id, isOpen, onCancel }) {
  const [isLoading, setIsLoading] = useState(false);
  const [archiveTime, setArchiveTime] = useState(new Date());
  const [nextTime, setNextTime] = useState(new Date());
  const [canDelay, setCanDelay] = useState(undefined);

  useEffect(() => {
    if (isOpen) {
      API()
        .get(`/LAR/auction_settings/delay_archive?lar_id=${id}`)
        .then((res) => {
          setArchiveTime(res.data.archive_time);
          setNextTime(res.data.next_date);
          setCanDelay(res.data.can_delay);
        });
    }
  }, [id, isOpen]);

  const handleDelay = () => {
    setIsLoading(true);
    API()
      .post('/LAR/auction_settings/delay_archive', { id })
      .then(() => {
        message.success('The deal was successfully extended');
      })
      .finally(() => {
        setIsLoading(false);
        onCancel();
      });
  };

  return (
    <Modal
      okText="Extend 3 business day"
      title={<p style={{ fontSize: 18 }}>Delay Deal Expiration</p>}
      bodyStyle={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 24, paddingTop: 10 }}
      onOk={handleDelay}
      open={isOpen}
      onCancel={onCancel}
      okButtonProps={{
        loading: isLoading,
        disabled: !canDelay,
      }}
    >
      <Typography.Paragraph>
        Starport deals are time-sensitive and expire after 7 business days. <br />
        You can extend the expiration if you need more time.
      </Typography.Paragraph>
      <Typography.Paragraph>
        This deal will Expire: <br />
        {moment(archiveTime).format('MMM DD, YYYY')}
      </Typography.Paragraph>
      <Typography.Paragraph>
        Available 3 Day Extension Date and Time: <br />
        {moment(nextTime).format('DD MMM h:mm A ')} ET
      </Typography.Paragraph>
    </Modal>
  );
}
