import React, { useState } from 'react';
import { Row } from 'antd';
import Collapse from '../../UI/Collapse';
import GreenSuccessIcon from '../../../assets/icons/GreenSuccessIcon';
import BlueSuccessIcon from '../../../assets/icons/BlueSuccessIcon';
import './Section.css';

function Section({
  title,
  children,
  initSuccess = false,
  initSuccessLA = false,
  activeKey,
  formKey,
  handleActiveFormItem,
}) {
  const [success, setSuccess] = useState(initSuccess);
  const [successLA, setSuccessLA] = useState(initSuccessLA);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { success, setSuccess, successLA, setSuccessLA });
    }
    return child;
  });

  return (
    <Collapse
      classNames={['lar-app-section']}
      expandIconPosition="end"
      bordered={false}
      activeKey={activeKey}
      formKey={formKey}
      handleActiveFormItem={handleActiveFormItem}
      header={
        <Row style={{ width: '100%', gap: '10px' }} align="middle">
          <div style={{ display: 'flex', gap: '8px' }}>
            <BlueSuccessIcon size={32} style={{ opacity: success ? 1 : 0 }} />
            <GreenSuccessIcon size={32} style={{ opacity: successLA ? 1 : 0 }} />
          </div>
          <h4 className="lar-app-section__title">{title}</h4>
        </Row>
      }
    >
      {childrenWithProps}
    </Collapse>
  );
}
export default Section;
