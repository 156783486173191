import { Form, Radio, Space } from 'antd';

function OccupancyType({ investment = true }) {
  return (
    <Form.Item name="occupancy_type" label="Occupancy type" required>
      <Radio.Group>
        <Space direction="vertical" size="large">
          <Radio value="Primary">Primary</Radio>
          <Radio value="Second home">Second home</Radio>
          {investment ? <Radio value="investment">Investment</Radio> : null}
        </Space>
      </Radio.Group>
    </Form.Item>
  );
}

export default OccupancyType;
