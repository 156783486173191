import { GET_DASHBOARD_METRICS, UPDATE_DASHBOARD_METRICS_TIMESTAMP, UPDATE_DASHBOARD_TIMER } from '../types/dashboard';

const initialState = {
  metrics: {},
  timestamp: new Date(),
};

const feesWorksheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_METRICS:
      return {
        ...state,
        metrics: action.payload,
      };
    case UPDATE_DASHBOARD_METRICS_TIMESTAMP:
      return {
        ...state,
        timestamp: new Date(),
      };
    case UPDATE_DASHBOARD_TIMER:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          timing_json: action.payload,
        },
      };
    default:
      return state;
  }
};

export default feesWorksheetReducer;
