import React from 'react';
import Login from '../../pages/Auth/Login';
import ResetPassword from '../../pages/Auth/ResetPassword';
import VerificationCode from '../../pages/Auth/VerificationCode';
import SetupPassword from '../../pages/Auth/SetupPassword';

export const mainNavigationAuth = [
  {
    exact: true,
    path: '/login',
    main: () => <Login />,
  },
  {
    exact: true,
    path: '/superlogin',
    main: () => <Login role="superadmin" />,
  },
  // {
  //     exact: true,
  //     path: "/borrowerlogin",
  //     main: () => <Login role={"borrower"} />,
  // },
  {
    exact: true,
    path: '/reset_password',
    main: () => <ResetPassword />,
  },
  {
    exact: true,
    path: '/verification_code',
    main: () => <VerificationCode />,
  },
  {
    exact: true,
    path: '/setup_password',
    main: () => <SetupPassword />,
  },
];
