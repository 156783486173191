import { Checkbox, Form, Input, Radio, Select, Space } from 'antd';
import PhoneComponent from '../../../../CustomComponents/PhoneInput';
import styles from './BorrowerIndividualForm.module.css';

export default function BorrowerIndividualForm({ form }) {
  const citizenship = Form.useWatch('main_borrower_citizenship', form);
  const employmentType = Form.useWatch('main_borrower_employment_type', form);
  const main_borrower_phone = Form.useWatch('main_borrower_phone', form);

  return (
    <>
      <Form.Item label="First name" name="main_borrower_first_name" className="auction-required-field">
        <Input maxLength={254} style={{ width: 215 }} />
      </Form.Item>
      <Form.Item label="Middle name" name="main_borrower_middle_name">
        <Input maxLength={254} style={{ width: 215 }} />
      </Form.Item>
      <Form.Item label="Last name" name="main_borrower_last_name" className="auction-required-field">
        <Input maxLength={254} style={{ width: 215 }} />
      </Form.Item>
      <Form.Item label="Suffix" name="main_borrower_suffix" normalize={(value) => value.replace(/[^a-zA-Z]+/g, '')}>
        <Input maxLength={254} style={{ width: 64 }} />
      </Form.Item>
      <Form.Item label="Email" name="main_borrower_email">
        <Input maxLength={254} style={{ width: 215 }} />
      </Form.Item>
      <Form.Item label="Mobile" name="main_borrower_phone" className="auction-required-field">
        <PhoneComponent
          className={styles.phone}
          onChange={(value) => {
            form.setFieldsValue({
              main_borrower_phone: value,
            });
          }}
          value={main_borrower_phone}
        />
      </Form.Item>
      <Form.Item
        label="Citizenship"
        name="main_borrower_citizenship"
        className="scenario-required-field auction-required-field"
      >
        <Select
          options={[
            'US Citizen',
            'Perm. Resident Alien',
            'Foreign National (No ITIN)',
            'Foreign National (ITIN)',
            'Foreign Investor',
          ].map((value) => ({ value, label: value }))}
          style={{ width: 215 }}
        />
      </Form.Item>
      {citizenship === 'Foreign Investor' && (
        <>
          <Form.Item initialValue={false} label="U.S. Entity" name="main_borrower_us_entity" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item
            initialValue={false}
            label="U.S. Bank Account"
            name="main_borrower_us_bank_account"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </>
      )}
      <Form.Item name="main_borrower_marital_status" label="Marital status">
        <Radio.Group>
          <Space direction="vertical" size="large">
            <Radio value="Married">Married</Radio>
            <Radio value="Single">Single</Radio>
            <Radio value="Separated">Separated</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="main_borrower_employment_type"
        label="Employment type"
        className="scenario-required-field auction-required-field"
      >
        <Radio.Group>
          <Space direction="vertical" size="large">
            <Radio value="Self-employed">Self-employed</Radio>
            <Radio value="Employed">Employed</Radio>
            <Radio value="Unemployed">Unemployed</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {employmentType === 'Self-employed' && (
        <Form.Item
          initialValue={false}
          name="main_borrower_self_employed_longer_than_24_months"
          valuePropName="checked"
          label="Self-employed longer than 24 months"
        >
          <Checkbox />
        </Form.Item>
      )}
    </>
  );
}
