import '../../styles/invite_users_form.css';
import { Col, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import CustomButton from 'components/UI/Button';
import siteName from 'constants/siteName';
import InviteUsersTable from './InviteUsersTable';

export default function InviteUsersForm({
  email,
  error,
  role,
  openSendInvitesModal,
  roleList,
  userList,
  onDeleteUser,
  onAddToTable,
  onSaveUserInTable,
  onUpdateUserData,
}) {
  const history = useHistory();
  const isBroker = /broker/i.test(role);

  return (
    <>
      <div className="invite-users-form">
        <div className="invite-users-form__inner">
          <div className="invite-users-form__header">
            <div className="invite-users-form__desc-block">
              <div className="invite-users-form__desc-title-container">
                <span className="invite-users-form__desc-title">
                  Invitations will only be delivered to invitees that are not already associated with a Company on{' '}
                  {siteName}.
                </span>
              </div>
              <p className="invite-users-form__desc-text">
                Users with an existing account will be required to confirm your invitation. Users without an existing
                account will be invited to create a new account.
              </p>
            </div>
          </div>
          <div className="invite-users-form__invited-users-table-container">
            <InviteUsersTable
              email={email}
              error={error}
              roleList={roleList}
              userList={userList}
              onDeleteUser={onDeleteUser}
              onUpdateUserData={onUpdateUserData}
              onAddToTable={onAddToTable}
              onSaveUserInTable={onSaveUserInTable}
            />
          </div>
          <div className="invite-users-form__footer">
            <CustomButton onClick={() => history.push('/onboarding/company-settings/users')} text="Cancel" />
            <CustomButton
              disabled={userList.dataSource.length === 0 || userList.dataSource.some((el) => !el.full_name)}
              onClick={openSendInvitesModal}
              primary
              text="Send Invites"
            />
          </div>
        </div>
      </div>
      {isBroker && (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title level={5}>Mortgage Loan Officer</Typography.Title>
            <Typography>
              A loan manager is the “Deal Owner”. They can create and submit new loan requests, accept and reject
              quotes, create and manage borrower prospects, and manage a team inside Starport.
            </Typography>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Broker Transaction Manager</Typography.Title>
            <Typography>
              A transaction manager is able to manage the document gathering process by accepting or rejecting items,
              moving items through the verification stages, and communicate with lenders and brokers.
            </Typography>
          </Col>
        </Row>
      )}
    </>
  );
}
