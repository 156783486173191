import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <g clipPath="url(#clip0_6336_10859)">
      <path d="M16 3L20 7L16 11" stroke="#40A9FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 7H20" stroke="#40A9FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 13L4 17L8 21" stroke="#40A9FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 17H13" stroke="#40A9FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_6336_10859">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </GenericIcon>
));
