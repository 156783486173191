import { UPDATE_CHAT_TIMESTAMP } from '../types/chat';

const initialState = {
  timestamp: new Date(),
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CHAT_TIMESTAMP: {
      return {
        ...state,
        timestamp: new Date(),
      };
    }
    default:
      return state;
  }
};

export default chatReducer;
