import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <path d="M3 21H21" />
    <path d="M3 10H21" />
    <path d="M5 6L12 3L19 6" />
    <path d="M4 10V21" />
    <path d="M20 10V21" />
    <path d="M8 14V17" />
    <path d="M12 14V17" />
    <path d="M16 14V17" />
  </GenericIcon>
));
