import { Radio, Space } from 'antd';
import DocumentAttachField from '../DocumentAttachField';

function CompleteDocument({ activeDocument, onChange, removeDocument }) {
  return (
    <>
      <Space size={8} direction="vertical">
        <span>How borrower responds</span>
        <Radio.Group
          onChange={({ target }) => onChange('item_action', target.value)}
          value={activeDocument.item_action.value}
        >
          <Space size={18} direction="vertical">
            {Object.entries(activeDocument.item_action.buttons).map(([, field], index) => (
              <Radio key={index} value={field.value}>
                {field.text}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Space>
      <DocumentAttachField activeDocument={activeDocument} onChange={onChange} removeDocument={removeDocument} />
    </>
  );
}

export default CompleteDocument;
