import './styles/LandingPopup.css';
import { Modal } from 'antd';
import CustomButton from 'components/UI/Button';

export default function BrokerLandingPopup({
  cancelFunc,
  cancelText,
  children,
  color,
  footer,
  onClose,
  submitFunc,
  submitText,
  ...rest
}) {
  return (
    <Modal footer={null} onCancel={onClose} {...rest}>
      <form onSubmit={submitFunc}>
        {children}
        {footer ?? (
          <div className="broker-landing-popup-footer">
            <CustomButton
              primary
              style={{ background: color, color: '#fff', border: `1px solid ${color}` }}
              text={submitText}
              type="submit"
            />
            <CustomButton
              className="btn_darkblue_transparent"
              hoverDisabled
              onClick={cancelFunc}
              style={{ color, background: '#fff' }}
              text={cancelText}
            />
          </div>
        )}
      </form>
    </Modal>
  );
}
