import { useCallback, useEffect, useMemo, useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Select, Button, message } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { fetchFeesWorksheet } from 'store/async-actions/feesWorksheet';
import { LARAPI } from 'api';
import { useLARDBContext } from 'pages/Onboarding/LARDB/hooks/LARDBContext';
import FeesWorksheetCollapse from '../FeesWorksheetCollapse';
import Row from './Row';
import ExtendedRow from './ExtendedRow';
import readableFields from './readableFields';
import getTotal from './getTotal';
import styles from './LARDBFeesWorksheet.module.css';

function LARDBFeesWorksheet() {
  const [additionalInputs, setAdditionalInputs] = useState({
    initial_escrow: 0,
    origination_fees: 0,
    prepaid: 0,
    project_costs: 0,
    services: 0,
    taxes: 0,
  });
  const [currentValues, setCurrentValues] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [initialAdditionalInputs, setInitialAdditionalInputs] = useState({});
  const [collapseAll, toggleCollapseAll] = useReducer((s) => !s, false);
  const [form] = Form.useForm();
  const { lar_id, lar_status } = useLARDBContext();
  const brokerFee = Form.useWatch('broker_fee', form);
  const dispatch = useDispatch();
  const API = useMemo(() => new LARAPI(lar_id), [lar_id]);
  const isEqual =
    JSON.stringify(initialValues) === JSON.stringify(form.getFieldsValue()) &&
    JSON.stringify(initialAdditionalInputs) === JSON.stringify(additionalInputs) &&
    brokerFee === initialValues.broker_fee;

  const currencyInputParams = useMemo(
    () => ({
      bordered: false,
      controls: false,
      disabled: !/Quote Selection/i.test(lar_status),
      formatter: (value) => (value ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''),
      parser: (value) => (value ? value?.replace(/\$|(,*)/g, '') : undefined),
      placeholder: '$00,000.00',
    }),
    [lar_status],
  );

  const deleteAdditionalField = useCallback(
    (category, index) => {
      const fieldsValue = form.getFieldsValue();
      const newFieldsValue = {};

      for (let i = index; i < additionalInputs[category]; ++i) {
        newFieldsValue[`${category}.additional_field_name.${i}`] =
          fieldsValue[`${category}.additional_field_name.${i + 1}`];
        newFieldsValue[`${category}.additional_field.${i}.period`] =
          fieldsValue[`${category}.additional_field.${i + 1}.period`];
        newFieldsValue[`${category}.additional_field.${i}.quantity`] =
          fieldsValue[`${category}.additional_field.${i + 1}.quantity`];
        newFieldsValue[`${category}.additional_field.${i}`] = fieldsValue[`${category}.additional_field.${i + 1}`];
      }

      form.setFieldsValue(newFieldsValue);

      setAdditionalInputs((prev) => ({
        ...prev,
        [category]: prev[category] - 1,
      }));
    },
    [additionalInputs, form],
  );

  function addAdditionalInput(parent) {
    setAdditionalInputs((prev) => ({
      ...prev,
      [parent]: prev[parent] + 1,
    }));
  }

  const handleFieldsChange = useCallback(
    (data) => {
      setCurrentValues((prev) => ({
        ...prev,
        ...data,
        ...form.getFieldsValue(),
      }));
    },
    [form],
  );

  const fetchData = useCallback(
    () =>
      API.getFeesWorksheet().then((data) => {
        Object.entries(data)
          .filter(([key, field]) => !key.includes('.additional.') && field?.pretty_name)
          .forEach(([key, field]) => {
            data[key] = field?.value;
            data[`${key}.period`] = field?.period;
            data[`${key}.quantity`] = field?.quantity;
          });

        // Spread additional fields
        Object.entries(data)
          .filter(([key]) => key.includes('.additional.'))
          .forEach(([key, field]) => {
            const [category, , , i] = key.split('.');

            delete data[key];
            data[`${category}.additional_field_name.${i}`] = field.pretty_name;
            data[`${category}.additional_field.${i}`] = field.value;
            if (field.period) {
              data[`${category}.additional_field.${i}.period`] = field.period;
            }
            if (field.quantity) {
              data[`${category}.additional_field.${i}.quantity`] = field.quantity;
            }

            setAdditionalInputs((prev) => {
              const res = {
                ...prev,
                [category]: Math.max(prev[category], Number(i) + 1),
              };

              setInitialAdditionalInputs(res);
              return res;
            });
          });

        form.resetFields();
        form.setFieldsValue(data);
        handleFieldsChange(data);
        setInitialValues(form.getFieldsValue());
      }),
    [API, form, handleFieldsChange],
  );
  useEffect(fetchData, [fetchData]);

  function submit(data) {
    const additionalFields = Object.fromEntries(
      Object.entries(data).filter(([key]) => /\.additional_field\./.test(key)),
    );
    const additionalFieldNames = Object.entries(data).filter(([key]) => /\.additional_field_name\./.test(key));

    // Merge additional fields
    const mergedData = {
      ...Object.fromEntries(
        Object.entries(data).flatMap(([key, value]) =>
          /additional_field|\.(period|quantity)$/i.test(key)
            ? []
            : [
                [
                  key,
                  {
                    period: data[`${key}.period`],
                    pretty_name: readableFields.get(key) || key,
                    quantity: data[`${key}.quantity`] || undefined,
                    value: value || 0,
                  },
                ],
              ],
        ),
      ),
      ...Object.fromEntries(
        additionalFieldNames.map(([key, name]) => {
          const i = Number(key.split('.').at(-1));
          const category = key.split('.')[0];
          const fixedName = /\.additional\./i.test(name)
            ? name
            : `${category}.additional.${(name || 'Other').replaceAll('.', '')}`;

          return [
            `${fixedName}.${i}`,
            {
              period: additionalFields[`${key.replace('additional_field_name', 'additional_field')}.period`],
              pretty_name: name || 'Other',
              quantity:
                additionalFields[`${key.replace('additional_field_name', 'additional_field')}.quantity`] || undefined,
              value: Number(additionalFields[key.replace('additional_field_name', 'additional_field')]) || 0,
            },
          ];
        }),
      ),
    };

    API.updateFeesWorksheet(mergedData).then(() => {
      message.success('Successfully updated fees worksheet');
      dispatch(fetchFeesWorksheet(lar_id));
    });
  }

  const getSum = useCallback(
    (categoryRegex = /.+/) =>
      currencyInputParams.formatter(
        Object.entries(currentValues)
          .reduce(
            (prev, [key, value]) =>
              (categoryRegex.test(key) && !/additional_field_name|\.(period|quantity)$/i.test(key) && value
                ? (() => {
                    const period = currentValues[`${key}.period`];
                    const periodQuantity = currentValues[`${key}.quantity`];

                    return period ? getTotal(Number(value), period, periodQuantity, false) : Number(value) || 0;
                  })()
                : 0) + prev,
            0,
          )
          .toFixed(2),
      ),
    [currencyInputParams, currentValues],
  );

  const AddFeeButton = useCallback(
    ({ children = 'Add fee', id }) => {
      if (currencyInputParams.disabled) {
        return null;
      }

      return (
        <button className={styles.addItemButton} onClick={() => addAdditionalInput(id)} type="button">
          <PlusOutlined />
          {children}
        </button>
      );
    },
    [currencyInputParams.disabled],
  );

  // TODO: Refactoring

  return (
    <div className={styles.root}>
      <Form className={styles.form} form={form} onChange={handleFieldsChange} onFinish={submit} scrollToFirstError>
        <div className={styles.formDescriptionWrapper}>
          <h3 className={styles.formDescriptionText}>
            This fees worksheet is where you finalize the fees, reserves, monthly payment, etc.
          </h3>
          <h3 className={styles.formDescriptionText}>
            This will auto-generate a visual quote comparison analyzer page and term sheet. You will be able to preview
            the terms and return to this worksheet to make changes.
          </h3>
        </div>
        <div className={styles.uncollapsable}>
          <div className={styles.estimatedClosingDayOfMonthWrapper}>
            <span className={styles.estimatedClosingDayOfMonth}>Originator success fee</span>
          </div>
          <Form.Item name="broker_fee" className={styles.brokerFeeSelect}>
            <Select
              options={Array.from({ length: 21 }, (_, i) => ({
                value: i * 0.25,
                label: `${i * 0.25}%`,
              }))}
            />
          </Form.Item>
          <Button icon={collapseAll ? <PlusOutlined /> : <MinusOutlined />} onClick={() => toggleCollapseAll()}>
            {collapseAll ? 'Uncollapse all' : 'Collapse all'}
          </Button>
          <Button disabled={isEqual} onClick={() => form.submit()} type="secondary">
            Save
          </Button>
        </div>
        <FeesWorksheetCollapse
          additionalHeaderInfo={getSum(/^project_costs\..+/i)}
          collapseAll={collapseAll}
          title="Total project cost"
        >
          <div className={styles.formGrid}>
            {['renovation_budget', 'mortgage_payoff_refi', 'other'].map((id) => (
              <Row currencyInputParams={currencyInputParams} id={`project_costs.${id}`} key={id} />
            ))}
            {Array.from({ length: additionalInputs.project_costs }, (_, i) => (
              <Row
                key={i}
                id="project_costs"
                currencyInputParams={currencyInputParams}
                additional={{
                  allowedNames: [
                    { value: 'project_costs.additional.remaining_balance', label: 'Remaining balance' },
                    { value: 'project_costs.additional.other', label: 'Other' },
                  ],
                  delete: deleteAdditionalField,
                  i,
                }}
              />
            ))}
          </div>
          <AddFeeButton id="project_costs">Add cost</AddFeeButton>
        </FeesWorksheetCollapse>
        <FeesWorksheetCollapse
          additionalHeaderInfo={getSum(/^(origination_fees|services|taxes|prepaid|initial_escrow)\..+/i)}
          collapseAll={collapseAll}
          title="Total one time fees"
        >
          <FeesWorksheetCollapse
            additionalHeaderInfo={getSum(/^origination_fees\..+/i)}
            collapseAll={collapseAll}
            isSubItem
            title="Origination fees"
          >
            <div className={styles.formGrid}>
              <div>Lender (Origination) fee</div>
              <div className={styles.notAnInput}>Based on the loan amount</div>
              <div>Originator (Broker) fee</div>
              <div className={styles.notAnInput}>Based on the loan amount</div>
              {['application_fees', 'processing_fee'].map((id) => (
                <Row currencyInputParams={currencyInputParams} id={`origination_fees.${id}`} key={id} />
              ))}
              {Array.from({ length: additionalInputs.origination_fees }, (_, i) => (
                <Row
                  additional={{ i, delete: deleteAdditionalField }}
                  currencyInputParams={currencyInputParams}
                  id="origination_fees"
                  key={i}
                />
              ))}
            </div>
            <AddFeeButton id="origination_fees" />
          </FeesWorksheetCollapse>
          <FeesWorksheetCollapse
            additionalHeaderInfo={getSum(/^services\..+/i)}
            collapseAll={collapseAll}
            isSubItem
            title="Services"
          >
            <div className={styles.formGrid}>
              {['appraisal_fee', 'credit_report_fee'].map((id) => (
                <Row currencyInputParams={currencyInputParams} id={`services.${id}`} key={id} />
              ))}
              {Array.from({ length: additionalInputs.services }, (_, i) => (
                <Row
                  additional={{ i, delete: deleteAdditionalField }}
                  currencyInputParams={currencyInputParams}
                  id="services"
                  key={i}
                />
              ))}
            </div>
            <AddFeeButton id="services" />
          </FeesWorksheetCollapse>
          <FeesWorksheetCollapse
            additionalHeaderInfo={getSum(/^taxes\..+/i)}
            collapseAll={collapseAll}
            isSubItem
            title="Taxes and other government fees"
          >
            <div className={styles.formGrid}>
              {['transfer_taxes', 'mortgage_recording_fees'].map((id) => (
                <Row currencyInputParams={currencyInputParams} id={`taxes.${id}`} key={id} />
              ))}
              {Array.from({ length: additionalInputs.taxes }, (_, i) => (
                <Row
                  additional={{ i, delete: deleteAdditionalField }}
                  currencyInputParams={currencyInputParams}
                  id="taxes"
                  key={i}
                />
              ))}
            </div>
            <AddFeeButton id="taxes" />
          </FeesWorksheetCollapse>
          <FeesWorksheetCollapse
            additionalHeaderInfo={
              <div className={classNames([styles.headerGrid, styles.headerGridBigger])}>
                <div>Monthly Amount</div>
                <div>Period</div>
                <div>Quantity</div>
                <div>{getSum(/^prepaid\..+/i)}</div>
              </div>
            }
            title="Prepaid"
            isSubItem
            collapseAll={collapseAll}
          >
            <div className={classNames([styles.bigFormGrid, styles.biggerFormGrid])}>
              {['real_estate_taxes', 'hazard_insurance_premium', 'prepaid_interest'].map((id) => (
                <ExtendedRow
                  onChange={handleFieldsChange}
                  currencyInputParams={currencyInputParams}
                  form={form}
                  id={`prepaid.${id}`}
                  key={id}
                />
              ))}
              {Array.from({ length: additionalInputs.prepaid }, (_, i) => (
                <ExtendedRow
                  onChange={handleFieldsChange}
                  additional={{ i, delete: deleteAdditionalField }}
                  currencyInputParams={currencyInputParams}
                  form={form}
                  id="prepaid"
                  key={i}
                />
              ))}
            </div>
            <AddFeeButton id="prepaid" />
          </FeesWorksheetCollapse>
          <FeesWorksheetCollapse
            additionalHeaderInfo={
              <div className={classNames([styles.headerGrid, styles.headerGridBigger])}>
                <div>Monthly Amount</div>
                <div>Period</div>
                <div>Quantity</div>
                <div>{getSum(/^initial_escrow\..+/i)}</div>
              </div>
            }
            title="Initial escrow at closing"
            isSubItem
            collapseAll={collapseAll}
          >
            <div className={classNames([styles.bigFormGrid, styles.biggerFormGrid])}>
              {['real_estate_taxes', 'hazard_insurance_premium', 'prepaid_interest'].map((id) => (
                <ExtendedRow
                  onChange={handleFieldsChange}
                  currencyInputParams={currencyInputParams}
                  form={form}
                  id={`initial_escrow.${id}`}
                  key={id}
                />
              ))}
              {Array.from({ length: additionalInputs.initial_escrow }, (_, i) => (
                <ExtendedRow
                  onChange={handleFieldsChange}
                  additional={{ i, delete: deleteAdditionalField }}
                  currencyInputParams={currencyInputParams}
                  form={form}
                  id="initial_escrow"
                  key={i}
                />
              ))}
            </div>
            <AddFeeButton id="initial_escrow" />
          </FeesWorksheetCollapse>
        </FeesWorksheetCollapse>
        <FeesWorksheetCollapse
          additionalHeaderInfo={getSum(/^monthly\..+/i)}
          title="Recurring monthly fees"
          collapseAll={collapseAll}
        >
          <div className={styles.formGrid}>
            {['real_estate_property_taxes', 'property_insurance', 'mortgage_insurance', 'homeowners_assn_dues'].map(
              (id) => (
                <Row currencyInputParams={currencyInputParams} id={`monthly.${id}`} key={id} />
              ),
            )}
          </div>
        </FeesWorksheetCollapse>
        {currencyInputParams.disabled || (
          <div className={styles.buttonsWrapper}>
            <Button
              danger
              onClick={() => {
                fetchData().then(() => message.success('You reset the form'));
              }}
            >
              Cancel changes
            </Button>
            <Button disabled={isEqual} onClick={() => form.submit()} type="secondary">
              Save
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
}

export default LARDBFeesWorksheet;
