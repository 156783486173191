import { EditIcon } from 'assets/icons';
import { Modal } from 'antd';
import { NoteBigIcon, TrashIcon } from 'assets/icons/icons';
import { useReducer } from 'react';
import classNames from 'classnames';
import globalStyles from 'constants/globalStyles';

export default function BidTableItem({
  item,
  id,
  bidsLength,
  openDeleteModal,
  bidValuesData,
  diligenceMode,
  isPreview,
  placeBidEdit,
  setPlaceBidEdit,
}) {
  const [termsVisible, toggleTermsVisible] = useReducer((state) => !state, false);

  function Title() {
    if (diligenceMode) {
      return (
        <div className="bid-table-list__list-item-header-label" style={{ marginBottom: 34 }}>
          <b
            style={{
              alignItems: 'center',
              color: '#1f1f1f',
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {id === 0 ? 'Original bid' : 'Final Underwriting'}
          </b>
        </div>
      );
    }

    return (
      <div className="bid-table-list__list-item-header-label" style={{ marginBottom: 34 }}>
        <b
          style={{
            alignItems: 'center',
            color: '#1f1f1f',
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {id === bidsLength - 1 ? 'Original bid' : id === 0 ? 'New bid' : `${bidsLength - 1 - id}st revision`}
        </b>
      </div>
    );
  }

  return (
    <div
      className={classNames({
        'bid-table-list__list-item_focus-active': !diligenceMode && id === 0,
        'bid-table-list__list-item_gray': diligenceMode,
        'bid-table-list__list-item': true,
      })}
    >
      <div className="bid-table-list__list-item-header">
        <Title />
      </div>
      <div className="bid-table-list__list-item-body">
        <div className="bid-table-list__info-block">
          {bidValuesData?.map((el, i) => (
            <div className="bid-table-list__info-block-item" key={i}>
              {el.format ? el.format(item[el.value]) : item[el.value]}
            </div>
          ))}
        </div>
      </div>
      <div className="bid-table-list__list-item-footer">
        <div className="bid-table-list__list-item-action">
          {item.additional_terms_and_comment && (
            <span
              className="bid-table-list__list-item-link bid-table-list__list-item-link_with-icon"
              onClick={toggleTermsVisible}
            >
              <NoteBigIcon
                style={{
                  color: globalStyles.main_blue_color,
                  marginRight: 4,
                }}
              />
              <span className="bid-table-list__list-item-link-text">Additional terms</span>
            </span>
          )}
        </div>
      </div>
      {isPreview || (
        <>
          {id !== 0 && !placeBidEdit && (
            <div className="bid-table-list__list-item-icon-block">
              <EditIcon onClick={() => setPlaceBidEdit(true)} style={{ color: '#bfbfbf', cursor: 'pointer' }} />
            </div>
          )}
          {id !== bidsLength - 1 && id !== 0 && (
            <div className="bid-table-list__list-item-icon-block">
              <TrashIcon onClick={() => openDeleteModal(item.id)} style={{ color: '#bfbfbf', cursor: 'pointer' }} />
            </div>
          )}
        </>
      )}
      <Modal footer={null} onCancel={toggleTermsVisible} title="Additional terms" open={termsVisible}>
        <div dangerouslySetInnerHTML={{ __html: item?.additional_terms_and_comment }} />
      </Modal>
    </div>
  );
}
