import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import API from 'api';
import { Button, Modal, message } from 'antd';
import DropdownMenu from 'components/CustomComponents/DropdownMenu';
import LARCard from 'components/LARDB/LARCard';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import { useSelector } from 'react-redux';

const rejectReasons = [
  'Other loan quotes provided were better',
  'Loan Amount was insufficient',
  'Interest rate was higher than requested',
  'Lender points were too high',
  "General loan terms didn't meet the loan request",
  'Closing days too long',
  'Not specified',
  'Have better loan offer from other source',
];

export default function ConfirmAuctionWinner() {
  const [activeDrawer, setActiveDrawer] = useState({ show: '', data: null });
  const [loading, setLoading] = useState(false);
  const [interestedLoading, setInterestedLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState(rejectReasons[0]);
  const location = useLocation();
  const data = location.state;
  const history = useHistory();
  const role = useSelector((state) => state?.user?.userData?.role);

  function handleOpenRejectQuoteModal() {
    setActiveDrawer({ show: 'reject-quote-modal', data: null });
  }

  function closeActiveDrawer() {
    setActiveDrawer({ show: '', data: null });
  }

  function confirmWinner() {
    setInterestedLoading(true);

    API()
      .post('/LAR/scenario/borrower_accept_quote', {
        lar_id: Number(data.lar_id),
      })
      .then(() => {
        message.success('Thank you for your selection');
        API()
          .get('/LAR/scenario/borrower_accept_quote', { params: { id: Number(data.lar_id) } })
          .then(({ data }) => {
            if (data.is_registered) {
              setTimeout(() => {
                history.push('/');
              }, 5000);
              return;
            }

            if (data.landing) {
              setTimeout(() => {
                setInterestedLoading(false);
                window.location.replace(data.landing);
              }, 5000);
            }
          });
      });
  }

  function rejectQuote() {
    setLoading(true);
    setInterestedLoading(true);

    API()
      .post('/LAR/scenario/borrower_reject_quote', {
        lar_id: Number(data.lar_id),
        bid_id: Number(data.id),
        reason: rejectReason,
      })
      .then(() => {
        message.success('Thank you for your selection');
        API()
          .get('/LAR/scenario/borrower_accept_quote', { params: { id: Number(data.lar_id) } })
          .then(({ data }) => {
            if (data.is_registered) {
              setTimeout(() => {
                history.push('/');
              }, 5000);
              return;
            }

            if (data.landing) {
              setTimeout(() => {
                setInterestedLoading(false);
                window.location.replace(data.landing);
              }, 5000);
            }
          });
      })
      .finally(() => setLoading(false));
  }

  return (
    <div className="confirm-wrapper">
      <div className="confirm-quote">
        <div className="quote-title">
          <SectionTitle title={<b>Please review this quote</b>} type="regular-24" />
        </div>
        <div className="quote-preview">
          <div className="quote">
            <LARCard data={data} />
          </div>
        </div>
        <div className="quote-footer">
          <Button onClick={confirmWinner} type="dark" size="large" loading={interestedLoading}>
            {/borrower/i.test(role) ? 'Interested' : 'Confirm the winning quote'}
          </Button>
          <Button onClick={handleOpenRejectQuoteModal} size="large" loading={interestedLoading}>
            {/borrower/i.test(role) ? 'Not Interested' : 'Reject'}
          </Button>
        </div>
      </div>
      <Modal
        allowClear
        title="Are you sure you want to reject this direct quote?"
        okText="Yes"
        open={activeDrawer.show === 'reject-quote-modal'}
        onCancel={closeActiveDrawer}
        footer={[
          <Button size="large" onClick={closeActiveDrawer}>
            Cancel
          </Button>,
          <Button size="large" type="dark" onClick={rejectQuote} loading={loading}>
            Yes
          </Button>,
        ]}
      >
        <DropdownMenu
          options={rejectReasons.map((i) => ({
            preview: i,
            value: i,
          }))}
          className="bordered"
          onSelect={({ value }) => setRejectReason(value)}
          title="Reason"
          value={rejectReason}
          vectorIcon
        />
      </Modal>
    </div>
  );
}
