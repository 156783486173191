import { Button } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import Keys from 'constants/helper';
import styles from './RightLeftButtons.module.css';

function RightLeftButtons({ larId }) {
  const { tab } = useParams();
  const navLoop = JSON.parse(sessionStorage.getItem(Keys.LARDB_NAVIGATION_LOOP) || '[]');
  const curI = navLoop.findIndex((id) => larId === id);

  if (curI < 0 || navLoop.length < 2) {
    return null;
  }

  return (
    <div className={styles.rightLeftButtons}>
      <Link to={`/onboarding/lar_db/${navLoop.at((curI - 1) % navLoop.length)}/${tab}`}>
        <Button icon={<LeftOutlined />} />
      </Link>
      <Link to={`/onboarding/lar_db/${navLoop.at((curI + 1) % navLoop.length)}/${tab}`}>
        <Button icon={<RightOutlined />} />
      </Link>
    </div>
  );
}

export default RightLeftButtons;
