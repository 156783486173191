import { useLocation } from 'react-router-dom';
import React from 'react';

function useQuery(params) {
  const { search } = useLocation();

  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  return params.map((i) => query.get(i));
}

export default useQuery;
