import { Avatar } from 'antd';
import avatar_placeholder from 'assets/images/avatar_placeholder_purple.png';

function CustomAvatar({
  companyLogoFormat = false,
  fullName,
  icon,
  name,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  size,
  square,
  src,
  style = {},
  surname,
  ...rest
}) {
  if (!fullName) {
    fullName = [name || '', surname || ''].join(' ');
  }

  const initials = fullName
    ?.split(' ')
    .filter((_, i, full) => i === 0 || i === full.length - 1)
    .map((i) => i.charAt(0).toUpperCase())
    .join('');

  if (companyLogoFormat) {
    return (
      <img
        alt=""
        style={{
          height: '3rem',
          ...style,
        }}
        src={src || avatar_placeholder}
        {...rest}
      />
    );
  }

  return (
    <Avatar
      alt={fullName}
      icon={icon}
      onClick={onClick}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      shape={square ? 'square' : 'circle'}
      size={size}
      src={src || initials ? src : avatar_placeholder}
      style={{
        color: 'black',
        fontSize: +size ? size / 2 : '100%',
        ...style,
      }}
      {...rest}
    >
      {initials}
    </Avatar>
  );
}

export default CustomAvatar;
