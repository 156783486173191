import './styles/next.css';
import { useLocation } from 'react-router-dom';
import Button from '../../../components/UI/Button';
import placeholder from '../../../assets/images/happens-next-placeholder.png';
import SectionTitle from '../../../components/OnBoarding/SectionTitle';

export default function HappensNext() {
  const location = useLocation();

  function goToNext() {
    const larId = location.state.selectedWinnerForLAR || 1;

    // TODO: Find out why router freaks out when using history.push here
    // and replace this with history.push
    window.location = `/onboarding/lar_db/${larId}/loan_processing`;
  }

  // TODO: There's hardcoded lorem ipsum here
  return (
    <div className="happens-next">
      <div className="next-wrapper">
        <SectionTitle wrapperStyle={{ textAlign: 'center' }} title="Congratulations" type="regular-24" />
        <span className="title">You're one big step closer to receiving a loan</span>
        <img src={placeholder} alt="" />
        <span>
          Your soft quote is based on limited information included in the loan application. In order for the lender to
          provide a firm quote, they need additional information.
        </span>
        <span>
          This is a normal and ordinary part of every lending process. To accelerate things, your lender has already
          prepared a required list of documents and information.
        </span>
        <span>
          The sooner you're able to provide the information, the sooner you're loan can proceed to closing and funding.
          We're using the streamlined technology to assist you, but we need you to get involved at this point. Gather
          the items, scan them, photograph them, make sure they are clear and legible. Then upload them into this
          portal.
        </span>
        <span className="subtitle">
          <b>Your loan officer is available to help all along the way.</b>
        </span>
        <Button
          className="btn_red_big"
          onClick={goToNext}
          style={{ backgroundColor: 'var(--colour-brand-5)' }}
          text="Access diligence center"
        />
      </div>
    </div>
  );
}
