import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import helper from '../constants/helper';
import Keys from '../constants/helper';
import { setUserData } from 'store/actions/userActions';

export default function PrivateRoute({ children, ...rest }) {
  const tokenFromStorage = localStorage.getItem(helper.JWT_TOKEN);
  const [jwtToken, setJwtToken] = useState(tokenFromStorage);
  const [content, setContent] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const cleanup = () => {
    setJwtToken(null);
    setContent(null);
  };

  const redirectUser = () => {
    setContent(
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />,
    );
  };

  const loginFromToken = async (token) => {
    try {
      const jwtData = jwtDecode(token);
      if (jwtData?.role) {
        setJwtToken(token);
        localStorage.setItem(Keys.JWT_TOKEN, token);
        dispatch(
          setUserData({
            role: jwtData?.role,
            token,
          }),
        );
      }
    } catch (e) {
      message.error(`BAD TOKEN!, ERR: ${e.message}`, 5);
      history.push('/404');
      localStorage.clear();
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tokenFromLink = urlParams.get('jwt') || urlParams.get('token');

    if (tokenFromLink) {
      loginFromToken(tokenFromLink).then(() => {
        setContent(children);
      });
    } else if (jwtToken) {
      setContent(children);
    } else if (!jwtToken && !tokenFromLink) {
      redirectUser();
    }

    return () => {
      cleanup();
    };
    // eslint-disable-next-line
  }, [location.search, history]);

  return <Route {...rest} render={() => content} />;
}
