import { Helmet } from 'react-helmet';

function MainLayout({ children, title = '' }) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {title && `${title} | `}
          Starport
        </title>
      </Helmet>
      {children}
    </>
  );
}

export default MainLayout;
