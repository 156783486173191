import { Form } from 'antd';

const useCreateLarForm = () => {
  const [project] = Form.useForm();
  const [property] = Form.useForm();
  const [borrower] = Form.useForm();
  const [address] = Form.useForm();
  const [product] = Form.useForm();
  const [borrowerFunds] = Form.useForm();
  const [credit_history] = Form.useForm();
  const [business] = Form.useForm();
  const [brokerFee] = Form.useForm();
  const [property_income_and_expense] = Form.useForm();

  const forms = {
    project,
    property,
    address,
    property_income_and_expense,
    product,
    borrowerFunds,
    borrower,
    credit_history,
    business,
    brokerFee,
  };

  return forms;
};
export default useCreateLarForm;
