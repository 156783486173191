export const PRODUCT_SUBTITLE =
  'Select a specific loan product or leave blank to let the lenders provide their suggested loan product.';

export const PROPERTY_TYPES = [
  'SFR',
  'Town/Rowhome',
  'Manufactured',
  'Condo',
  '2-4 Unit',
  'MultiFamily 5-20',
  'Portfolio',
  'Mixed-Use',
  'Land (Speculative)',
  'Mobile Home Park',
  'Commercial - Retail',
  'Commercial - Office',
  'Commercial - Industrial',
  'Commercial - Lodging',
  'Commercial - Other',
  'Short Term Rental',
  'Other',
];
