import { Input } from 'antd';
import { PencilIcon } from 'assets/icons';
import CustomButton from '../../../UI/Button';

export default function Description({
  changeEditField,
  editFields,
  isPLA,
  setViewDiligence,
  submitEditField,
  viewDiligence,
}) {
  return (
    <div className={`description ${editFields.item_description_error ? 'edit-field-error' : ''}`}>
      <div className="description-actions">
        {editFields.item_description !== undefined ? (
          <>
            <CustomButton onClick={() => changeEditField('item_description')} text="Cancel" />
            <CustomButton
              className="btn_darkblue"
              onClick={() => {
                if (!editFields.item_description) {
                  changeEditField('item_description_error', true);
                  return;
                }

                setViewDiligence((prev) => ({
                  ...prev,
                  item_description: editFields.item_description,
                }));

                submitEditField({ item_description: editFields.item_description }).then(() =>
                  changeEditField('item_description'),
                );
              }}
              text="Save"
            />
          </>
        ) : (
          isPLA && <PencilIcon onClick={() => changeEditField('item_description', viewDiligence.item_description)} />
        )}
      </div>

      <b>Document description</b>
      {editFields.item_description !== undefined ? (
        <Input.TextArea
          onChange={(e) => changeEditField('item_description', e.target.value)}
          rows={5}
          value={editFields.item_description}
        />
      ) : (
        <span>{viewDiligence.item_description}</span>
      )}
    </div>
  );
}
