const selectData = [
  {
    value: 'id',
    label: 'ID',
  },
  {
    value: 'type',
    label: 'Type',
  },
  {
    value: 'style',
    label: 'Style',
  },
  {
    value: 'amount',
    label: 'Amount',
  },
  {
    value: 'address',
    label: 'Address',
  },
];

export default selectData;
