import classNames from 'classnames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Spin, message } from 'antd';

import { fetchCongratulations, postCongratulations } from 'store/async-actions/lar';
import { setChatState } from 'store/actions/userActions';
import Keys from 'constants/helper';

import { TrophyIcon } from 'assets/icons/icons';
import { EditSecondIcon, MessageIcon } from 'assets/icons';

import styles from 'pages/Onboarding/Winner/Winner.module.css';

function Winner() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, loading, error } = useSelector((state) => state.lar);
  const { role } = useSelector((state) => state.user.userData);

  const isLender = /lender/i.test(role);
  const isBroker = /broker/i.test(role);

  function redirectToDD() {
    postCongratulations(id)
      .then(({ access_token }) => {
        if (access_token) {
          localStorage.setItem(Keys.JWT_TOKEN, access_token);
        }
      })
      .finally(() => {
        history.push(`/onboarding/lar_db/${id}/loan_processing`);
      });
  }

  function handleOpenChat() {
    const role = isLender ? 'Broker Loan Manager' : 'Lender Account Executive';
    dispatch(setChatState({ recipient_role: role, lar_id: id, type: 'sidebar' }));
  }

  useEffect(() => {
    dispatch(fetchCongratulations(id));

    if (error) {
      message.error('Something went wrong. Please try again');
    }
  }, []);

  return (
    <div className={styles.root}>
      <div className={classNames(styles.block, styles.congratulationsBlock)}>
        <h2 className={styles.title}>
          <TrophyIcon /> Congratulations!
        </h2>
        <p>
          This deal is moving forward towards a closing.
          <br />
          We suggest you contact each other as soon as possible to speak about the deal and the next steps on the
          journey towards a closing.
        </p>
      </div>
      <div className={classNames(styles.block, styles.half)}>
        <h3>Winning Lender</h3>
        <div className={styles.iconWrapper}>
          <TrophyIcon className={styles.trophyIcon} />
        </div>
        {loading ? (
          <Spin />
        ) : (
          <>
            <div className={styles.blockRow}>
              <p>
                <strong>Lender Account Executive (LAE): </strong>
                {data.congratulations?.lender?.full_name}
              </p>
              <p>
                <strong>Lender Name: </strong>
                {data.congratulations?.lender?.company_name}
              </p>
            </div>
            <div className={styles.blockRow}>
              <p>
                <strong>LAE Email: </strong>
                <a href={`mailto:${data.congratulations?.lender?.email}`}>{data.congratulations?.lender?.email}</a>
              </p>
              <p>
                <strong> LAE Phone: </strong>{' '}
                <a href={`tel:${data.congratulations?.lender?.phone}`}>
                  {data.congratulations?.lender?.phone?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3')}
                </a>
              </p>
              {data.congratulations?.lender?.work_phone && (
                <p>
                  <strong> LAE Other Phone: </strong>{' '}
                  <a href={`tel:${data.congratulations?.lender?.work_phone}`}>
                    {data.congratulations?.lender?.work_phone?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3')}
                  </a>
                </p>
              )}
            </div>
            {isBroker && (
              <Button type="dark" size="large" icon={<MessageIcon colour="white" />} onClick={handleOpenChat}>
                Send message to lender
              </Button>
            )}
          </>
        )}
      </div>
      <div className={classNames(styles.block, styles.half)}>
        <h3>Originator</h3>
        <div className={styles.iconWrapper}>
          <EditSecondIcon strokeWidth="0" />
        </div>
        {loading ? (
          <Spin />
        ) : (
          <>
            <div className={styles.blockRow}>
              <p>
                <strong>Name: </strong>
                {data.congratulations?.broker?.full_name}
              </p>
              <p>
                <strong>NMLS: </strong>
                {data.congratulations?.broker?.nmls}
              </p>
              <p>
                <strong>Company: </strong>
                {data.congratulations?.broker?.company_name}
              </p>
              <p>
                <strong>Email: </strong>
                <a href={`mailto:${data.congratulations?.broker?.email}`}>{data.congratulations?.broker?.email}</a>
              </p>
              <p>
                <strong>Phone: </strong>
                <a href={`tel:${data.congratulations?.broker?.phone}`}>
                  {data.congratulations?.broker?.phone?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3')}
                </a>
              </p>
              {isLender && (
                <Button type="dark" size="large" icon={<MessageIcon colour="white" />} onClick={handleOpenChat}>
                  Send message to broker
                </Button>
              )}
            </div>

            <h3>Borrower</h3>
            <div className={styles.blockRow}>
              <p>
                <strong>Name: </strong>
                {data.congratulations?.borrower?.full_name}
              </p>
            </div>
          </>
        )}
      </div>
      <div className={styles.block}>
        <h3>Disclaimer</h3>
        <p>
          The quote provided by the lender is not a commitment to make a loan. Loans are subject to borrower
          qualifications, including income, property evaluation, sufficient equity in the property to meet LTV
          requirements, and final credit approval. Lender approvals are subject to underwriting guidelines, interest
          rates, and program guidelines, and are subject to change without notice based on applicant’s eligibility and
          market conditions. Refinancing an existing loan may result in total finance charges being higher over life of
          loan. Reduction in payments may reflect longer loan term. Terms of the loan may be subject to payment of
          points and fees by the applicant.
        </p>
      </div>
      <div className={styles.block}>
        <h3>Ground Rules</h3>
        <ol className={styles.list}>
          <li>
            All communications intended for the borrower should be directed to the Originator. Lender should never
            contact the Borrower without permission from the Originator.
          </li>
          <li>
            The Starport Due Diligence center is now available and is pre-populated with the lender's required
            documents. Both the Lender and Originator can review the list, add new items, or remove items.
          </li>
          <li>
            If the Lender and Originator do not have a pre-existing relationship, then the Lender may require additional
            documentation and agreements from the Originator. This is a one-time formal process that establishes the
            business relationships between the lender and originator organizations. Starport is not involved in the
            direct lender to originator relationship.
          </li>
          <li>
            The Starport user membership agreement obligates both the lender and originator to notify Starport when the
            deal is closed and funded.
          </li>
          <li>
            Starport's team of account managers are available to help you. Contact{' '}
            <a href="mailto:support@starportco.com">support@starportco.com</a> if you require any assistance.
          </li>
        </ol>
      </div>
      <div className={styles.block}>
        <h3 className={styles.blockRow}>Next Steps</h3>
        <ul className={styles.list}>
          <li>
            Your soft quote is based on the information included in the loan application. In order for the lender to
            provide a firm quote, they need additional information.
          </li>
          <li>
            This is a normal and ordinary part of every lending process. Your lender has already prepared a required
            list of documents and information.
          </li>
          <li>
            The sooner you're able to provide the information, the sooner you're loan can proceed to closing and
            funding. We're using the best-inc-class technology to assist you, but we need you to get involved at this
            point. Gather the items required, making sure they are clear and legible. Then upload them into this portal.
          </li>
          <li>I'm available to help all along the way.</li>
        </ul>
      </div>
      <div className={styles.actions}>
        <Button type="dark" size="large" onClick={redirectToDD}>
          Continue to Diligence Center
        </Button>
        {/* <Button
          size="large"
          icon={<CalendarOutlined />}
          href="https://calendar.app.google/JnbQitZpL39sw3j89"
          target="_blank"
        >
          Schedule Diligence Center Training
        </Button> */}
      </div>
    </div>
  );
}

export default Winner;
