import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import arrow from '../../assets/images/dropdown-menu-arrow.svg';
import chevron_down from '../../assets/images/chevron-down.svg';
import './styles/dropdown-menu.css';
import TextInput from './TextInput';

function DropdownMenu(props) {
  const {
    value,
    options,
    onSelect,
    title,
    suffix,
    vectorIcon,
    disabled,
    error,
    errorMessage,
    search,
    className,
    updateOnRerender,
  } = props;
  const [searchValue, setSearchValue] = useState(() => value ?? '');

  const onSelected = (value) => {
    onSelect(value);
    if (search) {
      setSearchValue(value.preview);
    }
  };

  useEffect(() => {
    if (updateOnRerender) {
      setSearchValue(value);
    }

    // eslint-disable-next-line
  }, [value, updateOnRerender]);

  const menuItems = options
    ?.filter((item) => {
      if (search) {
        const previewToLower = item.preview?.toLowerCase();
        return previewToLower?.includes(searchValue?.toLowerCase());
      }
      return item.preview !== value;
    })
    ?.map((item, index) => ({
      label: item.preview,
      key: index,
      onClick: () => onSelected(item),
    }));

  const overlay = useMemo(() => <Menu items={menuItems} />, [menuItems]);

  return (
    <div
      className={`${className} ${error ? 'input_error' : ''} ${
        disabled ? 'drop_down_wrapper drop_down_wrapper_disabled' : 'drop_down_wrapper'
      }`}
    >
      {title ? <span className="drop_down_wrapper__title">{title}</span> : null}
      <Dropdown overlay={overlay} disabled={disabled} trigger={['click']}>
        <div className="wrapper_drop_down">
          {search ? (
            <TextInput onChange={(e) => setSearchValue(e.target.value)} disabled={disabled} value={searchValue} />
          ) : null}
          {!search && value?.toString() ? `${value}${suffix ?? ''}` : null}
          {vectorIcon ? <img src={chevron_down} alt="arrow" /> : <img src={arrow} alt="arrow" />}
        </div>
      </Dropdown>
      {error ? <p className="errorMessage">{errorMessage}</p> : null}
    </div>
  );
}

export default DropdownMenu;
