import { useEffect, useState } from 'react';
import { Form } from 'antd';
import formSettings from '../../helpers/formSettings';
import { getDefaultSuccess, onChangeValidator, useFieldsChangeNew } from '../../helpers/larHelpers';
import LoanFormWrapper from 'components/LarAppNew/FormWrappers/LoanFormWrapper';
import PropertyFormPurchaseLong from './PropertyFormPurchaseLong';
import PropertyFormPurchaseShort from './PropertyFormPurchaseShort';

const MAX_PROPERTY_VALUES = 99999;

function PropertyForm({
  form,
  isPurchaseLong,
  isPurchaseShort,
  isRefinance,
  loan_opportunity,
  loan_purpose,
  setLAProgress,
  setProgress,
  setSuccess,
  setSuccessLA,
}) {
  const formName = 'property';
  const [totalUnitsRange, setTotalUnitsRange] = useState([0, null]);

  const getSuccessStatus = (fieldsArr) => {
    onChangeValidator(form, fieldsArr, formName, {
      isRefinance,
      isPurchaseLong,
      isPurchaseShort,
    });
    const {
      property_type_description,
      property_type,
      annual_property_tax,
      annual_property_insurance,
      number_of_rental_units,
      number_of_rental_units_occupied,
    } = form.getFieldsValue();
    const valuesArr = fieldsArr.map((fieldObj) => fieldObj.value);

    if (property_type_description?.length > 1024) {
      form.setFields([
        {
          name: 'property_type_description',
          errors: ['The maximum allowed length for this field is 1024 characters.'],
        },
      ]);
    }

    if (property_type === '2-4 Unit' || property_type === 'MultiFamily 5-20') {
      if (number_of_rental_units_occupied > number_of_rental_units) {
        form.setFields([
          {
            name: 'number_of_rental_units_occupied',
            errors: ['Units currently occupied should be <= Total units'],
          },
        ]);

        return false;
      }
    }

    if (annual_property_tax > MAX_PROPERTY_VALUES) {
      form.setFields([
        {
          name: 'annual_property_tax',
          errors: ['Please enter a value less than 100,000'],
        },
      ]);
    }

    if (annual_property_insurance > MAX_PROPERTY_VALUES) {
      form.setFields([
        {
          name: 'annual_property_insurance',
          errors: ['Please enter a value less than 100,000'],
        },
      ]);
    }

    if (['Other', 'Portfolio', 'Mixed-Use'].includes(property_type)) {
      return !!property_type_description;
    }

    if (isPurchaseShort) {
      if (property_type === 'Other') {
        return !!property_type_description && !!annual_property_tax && !!annual_property_insurance;
      }
      return !!annual_property_tax && !!annual_property_insurance;
    }

    return getDefaultSuccess(valuesArr);
  };

  const setSuccessStatus = (status = false) => {
    setSuccess(status);
    setProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const setSuccessStatusLA = (status = false) => {
    setSuccessLA(status);
    setLAProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const onSuccess = () => {
    form.submit();
  };

  const onFieldsChange = useFieldsChangeNew(
    getSuccessStatus,
    setSuccessStatus,
    onSuccess,
    () => null,
    getSuccessStatus,
    setSuccessStatusLA,
  );

  // TODO: TEST SAVE DATA
  useEffect(() => {
    const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
      name: el[0],
      value: el[1],
    }));
    onFieldsChange([1], valuesArr);
  }, []);

  return (
    <Form {...formSettings} form={form} name={formName} onFieldsChange={onFieldsChange}>
      <LoanFormWrapper
        form={form}
        loan_purpose={loan_purpose}
        loan_opportunity={loan_opportunity}
        PurchaseLongForm={
          <PropertyFormPurchaseLong
            setTotalUnitsRange={setTotalUnitsRange}
            onFieldsChange={onFieldsChange}
            form={form}
            totalUnitsRange={totalUnitsRange}
          />
        }
        PurchaseShortForm={
          <PropertyFormPurchaseShort
            setTotalUnitsRange={setTotalUnitsRange}
            form={form}
            totalUnitsRange={totalUnitsRange}
            onFieldsChange={onFieldsChange}
          />
        }
        RefinanceForm={
          <PropertyFormPurchaseLong
            setTotalUnitsRange={setTotalUnitsRange}
            form={form}
            totalUnitsRange={totalUnitsRange}
            onFieldsChange={onFieldsChange}
          />
        }
      />
    </Form>
  );
}

export default PropertyForm;
