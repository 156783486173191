import {
  GET_ACTIVITY_LOG_LOADING,
  GET_ACTIVITY_LOG_ERROR,
  GET_ACTIVITY_LOG_SUCCESS,
  GET_CONGRATULATIONS_ERROR,
  GET_CONGRATULATIONS_LOADING,
  GET_CONGRATULATIONS_SUCCESS,
  GET_ACTIVITY_LOG_FILTER_LOADING,
  GET_ACTIVITY_LOG_FILTER_SUCCESS,
  GET_ACTIVITY_LOG_FILTER_ERROR,
  GET_CONVERSATION_LOG_LOADING,
  GET_CONVERSATION_LOG_ERROR,
  GET_CONVERSATION_LOG_SUCCESS,
  GET_CONVERSATION_LOG_FILTER_LOADING,
  GET_CONVERSATION_LOG_FILTER_ERROR,
  GET_CONVERSATION_LOG_FILTER_SUCCESS,
  UPDATE_PUBLIC_MESSAGES,
  GET_RATES_TRACKER,
} from 'store/types/lar';

const initialState = {
  data: {
    congratulations: null,
    activityLog: null,
    activityLogFilter: null,
    conversationLog: null,
    conversationFilter: null,
    publicMessages: [],
    ratesTracker: null,
  },
  error: null,
  loading: false,
};

const larReducer = (state = initialState, action) => {
  switch (action.type) {
    // Congratulations
    case GET_CONGRATULATIONS_LOADING:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_CONGRATULATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_CONGRATULATIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          congratulations: action.payload,
        },
        loading: false,
        error: null,
      };

    // Activity Log
    case GET_ACTIVITY_LOG_LOADING:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_ACTIVITY_LOG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          activityLog: action.payload,
        },
        loading: false,
        error: null,
      };

    // Activity log filter
    case GET_ACTIVITY_LOG_FILTER_LOADING:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_ACTIVITY_LOG_FILTER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ACTIVITY_LOG_FILTER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          activityLogFilter: action.payload,
        },
        loading: false,
        error: null,
      };

    // Conversation Log
    case GET_CONVERSATION_LOG_LOADING:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_CONVERSATION_LOG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_CONVERSATION_LOG_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          conversationLog: action.payload,
        },
        loading: false,
        error: null,
      };

    // Conversation log filter
    case GET_CONVERSATION_LOG_FILTER_LOADING:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_CONVERSATION_LOG_FILTER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_CONVERSATION_LOG_FILTER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          conversationFilter: action.payload,
        },
        loading: false,
        error: null,
      };
    // public messages
    case UPDATE_PUBLIC_MESSAGES:
      return {
        ...state,
        data: {
          ...state.data,
          publicMessages: [...state.data.publicMessages, action.payload],
        },
      };
    case GET_RATES_TRACKER:
      return {
        ...state,
        data: {
          ...state.data,
          ratesTracker: action.payload,
        },
      };
    default:
      return state;
  }
};

export default larReducer;
