import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <g clipPath="url(#clip0_6336_10865)">
      <path d="M12 6V3" stroke="#40A9FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.25 7.75L18.4 5.6" stroke="#40A9FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18 12H21" stroke="#40A9FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.25 16.25L18.4 18.4" stroke="#40A9FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 18V21" stroke="#40A9FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.75001 16.25L5.60001 18.4"
        stroke="#40A9FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6 12H3" stroke="#40A9FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.75001 7.75L5.60001 5.6"
        stroke="#40A9FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6336_10865">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </GenericIcon>
));
