const columns = [
  {
    title: '#',
    dataIndex: 'id',
    width: 20,
    render: (text, item) =>
      item.delay
        ? text
        : {
            children: item.messageType,
            props: { colSpan: 4 },
          },
  },
  {
    title: 'Message Type',
    className: 'messages--message-type',
    dataIndex: 'messageType',
    width: 300,
    render: (text, item) => (item.delay ? text : { props: { colSpan: 0 } }),
  },
  {
    title: 'Action required',
    dataIndex: 'action',
    width: 300,
    render: (text) => text || { props: { colSpan: 0 } },
  },
];

export default columns;
