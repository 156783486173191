import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { LARAPI } from 'api';
import { ExchangeIcon } from 'assets/icons';
import { updateDashboardMetricsTimestamp } from 'store/actions/dashboard';
import ReassignLARModal from '../LoanProfile/ReassignLARModal';

export default function ReassignButton({ larId, isLender, className, onReassign }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [team, setTeam] = useState([]);

  const role = useSelector((state) => state.user.userData.role);
  const isAdmin = /Lender Admin|Broker Admin/i.test(role);
  const larAPI = useMemo(() => new LARAPI(Number(larId)), [larId]);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    larAPI.getTeam().then((data) => setTeam(data));
  }, [larAPI, larId]);

  const handleReassign = (data) =>
    larAPI.reassign(data.userId, data.message, isLender ? 'lender' : 'broker').then(() => {
      message.success(
        isAdmin
          ? 'Deal is successfully reassigned. You are Admin, so you still can see the deal'
          : 'Deal is successfully reassigned.',
        isAdmin ? 5 : 3,
      );
      dispatch(updateDashboardMetricsTimestamp());
    });

  return (
    <>
      <Button className={className} onClick={handleOpen}>
        <ExchangeIcon />
        Reassign
      </Button>
      <ReassignLARModal
        team={team}
        visible={isOpen}
        onReassignLar={onReassign || handleReassign}
        onClose={handleClose}
      />
    </>
  );
}

ReassignButton.propTypes = {
  larId: PropTypes.number.isRequired,
  isLender: PropTypes.bool.isRequired,
  onReassign: PropTypes.func,
};
