import { acceptedMimes } from 'constants/mimes';
import { Badge, Col, Form, Input, message, Spin, Button, Row, Divider, Space, Avatar } from 'antd';
import { EditOutlined, SelectOutlined } from '@ant-design/icons';
import { formatPhone } from 'constants/formatPhone';
import { replacedTextData } from 'constants/common';
import { PencilIcon } from 'assets/icons';
import { setUserData } from 'store/actions/userActions';
import { useColor } from 'react-color-palette';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo, useRef, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import CustomAvatar from 'components/UI/Avatar';
import CustomButton from 'components/UI/Button';
import formRules from 'constants/formRules';
import ImageCropModal from 'components/ImageCropModal';
import jwt from 'jsonwebtoken';
import LegacyAPI from 'api';
import UpdateRoleDropdown from './UpdateRoleDropdown';
import styles from './Profile.module.css';

// TODO: It's a hack to remove "0" from empty phones when editing
// Backend should send "" or null or undefined in phone when it's empty, not 0 or "0"
function preprocessUserData(data) {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      if (/phone/i.test(key) && Number(value) === 0) {
        return [key, ''];
      }

      return [key, value];
    }),
  );
}

const initState = {
  company_logo: {
    value: '',
    preview: null,
    error: false,
    errorMessage: 'You should upload company logo',
    required: true,
  },
  domain: {
    value: '',
    error: false,
    errorMessage: 'Fill this label',
    required: true,
  },
  broker_pages_enabled: {
    value: false,
    required: false,
  },
};

export default function Profile({ API }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { slug: userId } = useParams();
  const uploadRef = useRef(null);
  const userData = useSelector((store) => store.user.userData);
  const [editMode, toggleEditMode] = useReducer((state) => !state, false);
  const [color, setColor] = useColor('hex', '#f5f5f5');
  const [loading, setLoading] = useState(false);
  const [avatarCropModal, setAvatarCropModal] = useState({ visible: false, file: null });
  const [pic, setPic] = useState({ file: null, thumb: null });
  const [profileData, setProfileData] = useState({});
  const [fields, setFields] = useState(initState);
  const [previewData, setPreviewData] = useState({});
  const canChangeRole =
    /broker admin|lender admin/i.test(userData.role) &&
    userData.id !== profileData.id &&
    !/admin/i.test(profileData.role);

  const displayedData = useMemo(
    () => [
      {
        label: 'Business email',
        value: profileData.email,
      },
      {
        label: 'Work phone',
        value: formatPhone(`+1${profileData.work_phone}`),
      },
      {
        label: 'Mobile phone',
        value: formatPhone(`+1${profileData.phone}`),
      },
    ],
    [profileData],
  );

  useEffect(() => {
    LegacyAPI()
      .get('/LAR/landing/broker')
      .then((res) => {
        setPreviewData(res.data.landing);
        setFields((prevState) => ({
          ...prevState,
          company_logo: {
            ...prevState.company_logo,
            preview: res.data.landing.company_logo,
          },
          domain: {
            ...prevState.domain,
            value: res.data.landing.domain ?? '',
          },
          broker_pages_enabled: {
            ...prevState.broker_pages_enabled,
            value: res.data.landing.broker_pages_enabled ?? false,
          },
        }));
        setColor((prevState) => ({
          ...prevState,
          hex: res.data.landing.color_code ?? '#f5f5f5',
        }));
      });

    // eslint-disable-next-line
  }, [setColor]);

  useEffect(() => {
    if (editMode) {
      return;
    }

    setLoading(false);
    form.resetFields();
    setPic({ file: null, thumb: null });
  }, [editMode, form]);

  function handleSubmit({ name, surname, email, work_phone, phone }) {
    setLoading(true);

    API.updateProfileInfo(
      name,
      surname,
      email,
      Number(work_phone) || undefined,
      Number(phone),
      pic.file,
      Number(userId) || undefined,
    )
      .then(({ user }) => {
        if (userData.id === user.id) {
          dispatch(setUserData({ ...profileData, ...user, avatar: user.profile_photo }));
        }

        setProfileData(preprocessUserData(user));
        toggleEditMode();
      })
      .catch((reason) => {
        message.error(reason?.response?.data?.error || "Couldn't update profile data");
        setLoading(false);
      });
  }

  useEffect(() => {
    if ([NaN, userData.id].includes(Number(userId))) {
      setProfileData(preprocessUserData(userData));
    } else {
      API.getProfile(userId).then(({ user }) => setProfileData(preprocessUserData(user)));
    }
  }, [API, userId, userData]);

  function handleSelectPic() {
    setAvatarCropModal({ visible: true, file: uploadRef.current.files[0] });
  }

  function handleCroppedPhoto(blob, dataURL) {
    setPic({
      file: blob,
      thumb: dataURL,
    });

    setAvatarCropModal((prev) => ({ ...prev, visible: false }));
  }

  function resetFileInput() {
    uploadRef.current.value = null;
  }

  const previewLanding = () => {
    let result = previewData;

    Object.entries(fields).forEach((i) => {
      if (i[0] === 'broker_picture' || i[0] === 'company_logo') {
        result[i[0]] = i[1].preview;
      } else {
        result[i[0]] = i[1].value || i[1].preview || '';
      }
    });

    LegacyAPI()
      .get('/LAR/landing/broker/broker-custom-landing')
      .then((res) => {
        result = { ...result, ...res.data.broker_custom_landing };
        result.color_code = color.hex;
        result.company_NMLS = res.data.company_landing.nmls;

        jwt.sign(result, 'shhhhh', (err, token) => {
          if (token) {
            // history.push('/portal/preview?token=' + token)
            const win = window.open(`/portal/preview?token=${token}`, '_blank');
            win.focus();
          }
        });
      });
  };

  return (
    <div className={styles.root}>
      <Row gutter={20} align="middle" justify="space-between">
        <Col>
          <h1>{userData.id !== profileData.id ? 'User Info' : 'My Info'}</h1>
        </Col>
        {userData.role !== 'Borrower' && (
          <Col>
            {editMode || (
              <Button type="text" icon={<EditOutlined />} onClick={toggleEditMode}>
                Edit
              </Button>
            )}
          </Col>
        )}
      </Row>
      <Spin spinning={loading}>
        <div className={styles.main}>
          {editMode ? (
            <Form
              className={styles.form}
              form={form}
              initialValues={profileData}
              layout="vertical"
              onFinish={handleSubmit}
              requiredMark={false}
            >
              <div className={styles.avatarWrapper}>
                <input
                  hidden
                  type="file"
                  ref={uploadRef}
                  accept={acceptedMimes.avatar}
                  onChange={handleSelectPic}
                  onClick={resetFileInput}
                />
                <Badge count={<PencilIcon colour="white" />} onClick={() => uploadRef.current?.click()}>
                  <CustomAvatar
                    name={profileData.name}
                    src={pic.thumb || profileData.avatar || profileData.profile_photo}
                    surname={profileData.surname}
                    size={80}
                  />
                </Badge>
              </div>
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Last name"
                name="surname"
                rules={[{ required: true, message: 'Please input your last name!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Business email" name="email" rules={[{ ...formRules.email, required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Work phone" name="work_phone" rules={[formRules.phone]} validateTrigger="onSubmit">
                <Input addonBefore="+1" maxLength={10} />
              </Form.Item>
              <Form.Item
                label="Mobile phone"
                name="phone"
                validateTrigger="onSubmit"
                rules={[{ ...formRules.phone, required: true }]}
              >
                <Input addonBefore="+1" maxLength={10} />
              </Form.Item>
              <CustomButton text="Cancel" onClick={toggleEditMode} />
              <CustomButton primary text="Save" onClick={() => form.submit()} />
            </Form>
          ) : (
            <>
              <CustomAvatar
                name={profileData.name}
                src={profileData.avatar || profileData.profile_photo}
                surname={profileData.surname}
                size={80}
                className={styles.avatar}
              />
              <div>
                <div className={styles.userName}>
                  {profileData.name} {profileData.surname}
                </div>
                {canChangeRole ? (
                  <UpdateRoleDropdown userData={profileData} />
                ) : (
                  replacedTextData[profileData.role] || profileData.role
                )}
              </div>
              {profileData.company?.name && profileData.company?.logo && (
                <>
                  <Divider />
                  <>
                    <div className={styles.dataLabel}>Company</div>
                    <div className={styles.dataInfo}>
                      <Space>
                        <Avatar src={profileData.company.logo} />
                        {profileData.company.name}
                      </Space>
                    </div>
                  </>
                </>
              )}
              <Divider />
              <div className={styles.dataWrapper}>
                {displayedData.map(({ label, value }) => (
                  <div key={label}>
                    <label className={styles.dataLabel}>{label}:</label>
                    <div className={styles.dataInfo}>{value}</div>
                  </div>
                ))}
              </div>
              {/broker/i.test(profileData.role) && (
                <div className={styles.personalLoanPageWrapper}>
                  <div className={styles.personalLoanPageHeader}>
                    <label>Personal loan portal page:</label>
                    <Button onClick={previewLanding} size="large" type="dark" icon={<SelectOutlined rotate={90} />}>
                      Preview Landing Page
                    </Button>
                  </div>
                  <div>
                    Get more deals more quickly by giving clients your own personalized loan portal where they can
                    submit deals, upload documents, and track progress towards their closing. Your unique URL link is
                    available on the "Borrowers" page.
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Spin>
      <ImageCropModal
        file={avatarCropModal.file}
        format="avatar"
        onCancel={() => setAvatarCropModal((prev) => ({ ...prev, visible: false }))}
        onOk={handleCroppedPhoto}
        visible={avatarCropModal.visible}
        title="Adjust Your Picture"
      />
    </div>
  );
}
