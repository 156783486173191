import './styles/reminders.css';
import { Checkbox, Divider, message, Select, Space, Spin, Switch, TimePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { num2Word } from '../../../../constants/num2Word';
import API from '../../../../api';
import Button from '../../../../components/UI/Button';

function pluralise(n, terms) {
  return `${n} ${num2Word(n, terms)}`;
}

const createSimpleOptions = (count, terms, fromZero = false) => {
  const arr = Array.apply(null, { length: !fromZero ? count + 1 : count }).map(Number.call, Number);
  if (!fromZero) {
    arr.shift();
  }

  return arr.map((el) => ({ value: el, label: pluralise(el, terms) }));
};

const dayOptions = createSimpleOptions(7, ['day', 'days']);
const reminderOptions = createSimpleOptions(10, ['reminder', 'reminders']);

// borrower_select_begin_sending_reminders: 1
// borrower_select_quote_notification_about_last: false
// borrower_select_quote_reminders_count: 1
// borrower_select_quote_reminders_period_in_days: 1
// borrower_select_quote_reminders_time: "09:00 AM"
// borrower_select_quote_reminders_timezone: "ET"
// broker_select_begin_sending_reminders: 1
// broker_select_quote_reminders_count: 1
// broker_select_quote_reminders_period_in_days: 1
// broker_select_quote_reminders_time: "09:00 AM"
// broker_select_quote_reminders_timezone: "ET"
// broker_select_quote_send_notification_about_last: false
// diligence_begin_sending_reminders: 1
// diligence_notification_about_last: false
// diligence_sending_reminders_count: 1
// diligence_sending_reminders_period_in_days: 1
// diligence_sending_reminders_time: "09:00 AM"
// diligence_sending_reminders_timezone: "ET"
// lar_incomplete_begin_sending_reminders: 1
// lar_incomplete_reminders_count: 1
// lar_incomplete_reminders_period_in_days: 1
// lar_incomplete_reminders_time: "09:00 AM"
// lar_incomplete_reminders_timezone: "ET"
// lar_incomplete_send_notification_about_last: false
// lender_DD_begin_sending_reminders: 1
// lender_DD_notification_about_last: false
// lender_DD_sending_reminders_count: 1
// lender_DD_sending_reminders_period_in_days: 1
// lender_DD_sending_reminders_time: "09:00 AM"
// lender_DD_sending_reminders_timezone: "ET"

function RemindSelect(props) {
  const { opts, value, onChange, name, ...restProps } = props;
  const handleChange = (newValue) => {
    onChange(name, newValue);
  };
  return (
    <Select name={name} onChange={handleChange} value={value} {...restProps}>
      {opts.map((el, idx) => (
        <Select.Option key={`remind_select_${el.value}_${idx}`} value={el.value}>
          {el.label ?? el.value}
        </Select.Option>
      ))}
    </Select>
  );
}
function DescItem({ title }) {
  return <span className="reminder__desc">{title}</span>;
}
function BeginRemindItem({ title, value, name, handleSelectChange }) {
  return (
    <div>
      <Space direction="vertical" size={5}>
        <label>{title}</label>
        <RemindSelect
          onChange={handleSelectChange}
          name={name}
          value={value}
          opts={dayOptions}
          style={{ minWidth: 180 }}
        />
      </Space>
    </div>
  );
}
function RemindOptions({ day, time, endAfter, handleSelectChange, handleTimeChange }) {
  return (
    <div>
      <div>Send reminders every</div>
      <RemindSelect
        onChange={handleSelectChange}
        name={day.name}
        value={day.value}
        opts={dayOptions}
        style={{ minWidth: 180 }}
      />
      <DescItem title="at" />
      <Space size={16}>
        <TimePicker
          onChange={(_, value) => handleTimeChange(time.name, value)}
          name={time.name}
          value={moment(time.value, 'hh:mm A')}
          style={{ maxWidth: 120 }}
          format="hh:mm A"
        />
      </Space>
      <DescItem title="ending after" />
      <RemindSelect
        onChange={handleSelectChange}
        name={endAfter.name}
        value={endAfter.value}
        opts={reminderOptions}
        style={{ minWidth: 180 }}
      />
    </div>
  );
}
function ReminderItem(props) {
  const {
    title,
    begin,
    every,
    sendLastMsg,
    handleSelectChange,
    handleCheckBoxChange,
    handleTimeChange,
    onChangeSwitch,
    toWhom,
  } = props;

  return (
    <div className="reminder_inner">
      <div className="reminder_title">{title}</div>
      <div style={{ marginTop: 15 }}>
        <Switch {...props.switch} onChange={(v) => onChangeSwitch(v, props.switch.name)} />
      </div>
      <Space style={{ marginTop: 10 }} direction="vertical" size={32}>
        <BeginRemindItem {...begin} handleSelectChange={handleSelectChange} />
        <RemindOptions handleTimeChange={handleTimeChange} handleSelectChange={handleSelectChange} {...every} />
        <Checkbox onChange={handleCheckBoxChange} checked={sendLastMsg.value} name={sendLastMsg.name}>
          Send a message to the
          {toWhom} that the final reminder has been sent
        </Checkbox>
      </Space>
    </div>
  );
}

function Reminders() {
  const [initialRemindersState, setInitialRemindersState] = useState(null);
  const [remindersData, setRemindersData] = useState({ loading: false });
  const data = [
    {
      title: 'Incomplete LAR Reminder (Broker)',
      begin: {
        name: 'lar_incomplete_broker_begin_sending_reminders',
        title: 'Begin sending reminders after initial LAR creation',
        value: `in ${pluralise(remindersData?.settings?.lar_incomplete_broker_begin_sending_reminders, [
          'day',
          'days',
        ])}`,
      },
      switch: {
        name: 'lar_incomplete_broker_active',
        defaultChecked: remindersData?.settings?.lar_incomplete_broker_active,
      },
      every: {
        day: {
          name: 'lar_incomplete_broker_reminders_period_in_days',
          value: pluralise(remindersData?.settings?.lar_incomplete_broker_reminders_period_in_days, ['day', 'days']),
        },
        time: {
          name: 'lar_incomplete_broker_reminders_time',
          value: remindersData?.settings?.lar_incomplete_broker_reminders_time,
        },
        endAfter: {
          name: 'lar_incomplete_broker_reminders_count',
          value: pluralise(remindersData?.settings?.lar_incomplete_broker_reminders_count, ['reminder', 'reminders']),
        },
      },
      sendLastMsg: {
        name: 'lar_incomplete_broker_send_notification_about_last',
        value: remindersData?.settings?.lar_incomplete_broker_send_notification_about_last,
      },
    },
    {
      title: 'Incomplete LAR Reminder (Borrower)',
      begin: {
        name: 'lar_incomplete_borrower_begin_sending_reminders',
        title: 'Begin sending reminders after initial LAR creation',
        value: `in ${pluralise(remindersData?.settings?.lar_incomplete_borrower_begin_sending_reminders, [
          'day',
          'days',
        ])}`,
      },
      switch: {
        name: 'lar_incomplete_borrower_active',
        defaultChecked: remindersData?.settings?.lar_incomplete_borrower_active,
      },
      every: {
        day: {
          name: 'lar_incomplete_borrower_reminders_period_in_days',
          value: pluralise(remindersData?.settings?.lar_incomplete_borrower_reminders_period_in_days, ['day', 'days']),
        },
        time: {
          name: 'lar_incomplete_borrower_reminders_time',
          value: remindersData?.settings?.lar_incomplete_borrower_reminders_time,
        },
        endAfter: {
          name: 'lar_incomplete_borrower_reminders_count',
          value: pluralise(remindersData?.settings?.lar_incomplete_borrower_reminders_count, ['reminder', 'reminders']),
        },
      },
      sendLastMsg: {
        name: 'lar_incomplete_borrower_send_notification_about_last',
        value: remindersData?.settings?.lar_incomplete_borrower_send_notification_about_last,
      },
    },
    {
      title: 'Broker Select Quote Reminder',
      begin: {
        name: 'broker_select_begin_sending_reminders',
        title: 'Begin sending reminders after auction end',
        value: `in ${pluralise(remindersData?.settings?.broker_select_begin_sending_reminders, ['day', 'days'])}`,
      },
      switch: {
        name: 'broker_select_quote_reminders_active',
        defaultChecked: remindersData?.settings?.broker_select_quote_reminders_active,
      },
      every: {
        day: {
          name: 'broker_select_quote_reminders_period_in_days',
          value: pluralise(remindersData?.settings?.broker_select_quote_reminders_period_in_days, ['day', 'days']),
        },
        time: {
          name: 'broker_select_quote_reminders_time',
          value: remindersData?.settings?.broker_select_quote_reminders_time,
        },
        endAfter: {
          name: 'broker_select_quote_reminders_count',
          value: pluralise(remindersData?.settings?.broker_select_quote_reminders_count, ['reminder', 'reminders']),
        },
      },
      sendLastMsg: {
        name: 'broker_select_quote_send_notification_about_last',
        value: remindersData?.settings?.broker_select_quote_send_notification_about_last,
      },
    },
    {
      title: 'Borrower select winning lender reminder',
      begin: {
        name: 'borrower_select_begin_sending_reminders',
        title: 'Begin sending reminders after broker sends quotes to borrower ',
        value: `in ${pluralise(remindersData?.settings?.borrower_select_begin_sending_reminders, ['day', 'days'])}`,
      },
      switch: {
        name: 'borrower_select_quote_reminders_active',
        defaultChecked: remindersData?.settings?.borrower_select_quote_reminders_active || false,
      },
      every: {
        day: {
          name: 'borrower_select_quote_reminders_period_in_days',
          value: pluralise(remindersData?.settings?.borrower_select_quote_reminders_period_in_days, ['day', 'days']),
        },
        time: {
          name: 'borrower_select_quote_reminders_time',
          value: remindersData?.settings?.borrower_select_quote_reminders_time,
        },
        endAfter: {
          name: 'borrower_select_quote_reminders_count',
          value: pluralise(remindersData?.settings?.borrower_select_quote_reminders_count, ['reminder', 'reminders']),
        },
      },
      sendLastMsg: {
        name: 'borrower_select_quote_notification_about_last',
        value: remindersData?.settings?.borrower_select_quote_notification_about_last,
      },
    },
    {
      title: 'Lender DD review reminder',
      begin: {
        name: 'lender_DD_begin_sending_reminders',
        title: 'Begin sending reminder after diligence items were initially send to lender',
        value: `in ${pluralise(remindersData?.settings?.lender_DD_begin_sending_reminders, ['day', 'days'])}`,
      },
      switch: {
        name: 'lender_DD_sending_reminders_active',
        defaultChecked: remindersData?.settings?.lender_DD_sending_reminders_active || false,
      },
      every: {
        day: {
          name: 'lender_DD_sending_reminders_period_in_days',
          value: pluralise(remindersData?.settings?.lender_DD_sending_reminders_period_in_days, ['day', 'days']),
        },
        time: {
          name: 'lender_DD_sending_reminders_time',
          value: remindersData?.settings?.lender_DD_sending_reminders_time,
        },
        endAfter: {
          name: 'lender_DD_sending_reminders_count',
          value: pluralise(remindersData?.settings?.lender_DD_sending_reminders_count, ['reminder', 'reminders']),
        },
      },
      sendLastMsg: {
        name: 'lender_DD_notification_about_last',
        value: remindersData?.settings?.lender_DD_notification_about_last,
      },
    },
    {
      title: 'Borrower (cc broker) reminder to complete owed items',
      begin: {
        name: 'complete_owed_items_reminders',
        title: 'Begin sending reminder after',
        value: `in ${pluralise(remindersData?.settings?.complete_owed_items_reminders, ['day', 'days'])}`,
      },
      switch: {
        name: 'complete_owed_items_reminders_active',
        defaultChecked: remindersData?.settings?.complete_owed_items_reminders_active || false,
      },
      every: {
        day: {
          name: 'complete_owed_items_reminders_period_in_days',
          value: pluralise(remindersData?.settings?.complete_owed_items_reminders_period_in_days, ['day', 'days']),
        },
        time: {
          name: 'complete_owed_items_reminders_time',
          value: remindersData?.settings?.complete_owed_items_reminders_time,
        },
        endAfter: {
          name: 'complete_owed_items_reminders_count',
          value: pluralise(remindersData?.settings?.complete_owed_items_reminders_count, ['reminder', 'reminders']),
        },
      },
      sendLastMsg: {
        name: 'complete_owed_items_about_last',
        value: remindersData?.settings?.complete_owed_items_about_last,
      },
    },
  ];

  const getRemindersData = () => {
    API()
      .get('/superadmin/settings/reminders')
      .then((res) => {
        setInitialRemindersState({ ...res.data.settings });
        setRemindersData((prevState) => ({
          ...prevState,
          ...res.data,
          loading: true,
        }));
      });
  };
  const saveRemindersSettings = () => {
    setRemindersData((prevState) => ({ ...prevState, loading: false }));
    API()
      .post('/superadmin/settings/reminders', {
        ...remindersData?.settings,
        borrower_select_quote_active: !!remindersData?.settings.borrower_select_quote_active,
        broker_select_quote_active: !!remindersData?.settings.broker_select_quote_active,
        complete_owed_items_active: !!remindersData?.settings.complete_owed_items_active,
        diligence_sending_active: !!remindersData?.settings.diligence_sending_active,
        lar_incomplete_borrower_active: !!remindersData?.settings.lar_incomplete_borrower_active,
        lar_incomplete_broker_active: !!remindersData?.settings.lar_incomplete_broker_active,
        lender_DD_sending_active: !!remindersData?.settings.lender_DD_sending_active,
      })
      .then(() => {
        setInitialRemindersState({ ...remindersData?.settings });
        setRemindersData((prevState) => ({ ...prevState, loading: true }));
        message.info('Reminders successfully saved!');
      });
  };
  const resetToInitial = () => {
    setRemindersData((prevState) => ({
      ...prevState,
      settings: {
        ...initialRemindersState,
      },
    }));
  };
  const handleSelectChange = (name, value) => {
    setRemindersData((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        [name]: value,
      },
    }));
  };
  const handleCheckSwitch = (val, name) => {
    setRemindersData((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        [name]: val,
      },
    }));
  };
  const handleCheckBoxChange = (e) => {
    const { name, checked } = e.target;
    setRemindersData((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        [name]: checked,
      },
    }));
  };
  const handleTimeChange = (name, value) => {
    if (value === '' || !value) {
      handleSelectChange(name, '09:00 AM');
    } else {
      handleSelectChange(name, value);
    }
  };
  const cleanup = () => {
    setRemindersData({ loading: false });
    setInitialRemindersState(null);
  };

  useEffect(() => {
    getRemindersData();
    return () => cleanup();
  }, []);

  return (
    <div className="wrapper_reminders">
      <p className="section_title">Reminders</p>
      <div>
        <Space direction="vertical" size={8}>
          <span className="section_title_desc">
            Reminders will not be sent on Blackout days. Reminders will resume the next business day
          </span>
          <span className="section_title_desc">
            Customize how often broker, borrowers, and lenders receive reminders
          </span>
        </Space>
      </div>
      <div style={{ marginTop: 40 }}>
        <Spin spinning={!remindersData.loading}>
          <Space direction="vertical" size={55}>
            {remindersData.loading &&
              data.map((el, idx) => (
                <ReminderItem
                  handleTimeChange={handleTimeChange}
                  handleCheckBoxChange={handleCheckBoxChange}
                  handleSelectChange={handleSelectChange}
                  onChangeSwitch={handleCheckSwitch}
                  toWhom={el.sendLastMsg.name === 'lender_DD_notification_about_last' ? 'LOM and LOM Support' : 'PLA'}
                  key={`reminder_item_${idx}`}
                  {...el}
                />
              ))}
          </Space>
        </Spin>
      </div>
      <Divider style={{ borderColor: '#D9D9D9', margin: '48px 0' }} />
      <Space size="large">
        <Button onClick={resetToInitial} style={{ minWidth: 150 }} className="dark-grey" text="Cancel" />
        <Button style={{ minWidth: 150 }} text="Save" className="btn_darkblue submit" onClick={saveRemindersSettings} />
      </Space>
    </div>
  );
}
export default Reminders;
