import './style/loan_package.css';
import { Row, Space, List, Collapse, Col, message } from 'antd';
import { useEffect, useState } from 'react';
import { DownloadIcon, EyeIcon, TrashIcon } from '../../../assets/icons/icons';
import API from '../../../api';
import Button from '../../UI/Button';
import helper from '../../../constants/helper';

function LoanPackageDocumentList(props) {
  const { dd_center_items, setViewDiligenceVisible, setViewDiligence } = props;
  function Item(props) {
    const { ddCenterItem, setViewDiligenceVisible, setViewDiligence } = props;
    const {
      // dd_center_id,
      // documents,
      id,
      // item_description,
      // item_level,
      item_name,
      // item_stage,
      // item_sub_stage,
      // item_type,
      // loan_package_id,
      // rejects,
      updated_at,
      updated_by,
      // updated_by_id,
    } = ddCenterItem;
    return (
      <List.Item>
        <Row style={{ width: '100%' }}>
          <Col span={6}>
            <span style={{ fontWeight: 'bold' }}>
              <span style={{ marginRight: 10 }}>1.</span>
              <span>{item_name}</span>
            </span>
          </Col>
          <Col span={6}>
            <span style={{ fontWeight: 500 }}>{updated_at}</span>
          </Col>
          <Col span={5}>
            <span style={{ fontWeight: 500 }}>
              By
              {updated_by}
            </span>
          </Col>
          <Col span={7}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Space align="center">
                <a
                  aria-label="downloadItem"
                  href={`${
                    process.env.REACT_APP_API_URL
                  }/LAR/dd_center/item-download?item_id=${id}&jwt=${localStorage.getItem(helper.JWT_TOKEN)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <DownloadIcon style={{ color: '#8C8C8C', cursor: 'pointer' }} />
                </a>
                <EyeIcon
                  onClick={() => {
                    setViewDiligenceVisible(true);
                    setViewDiligence(ddCenterItem);
                  }}
                  style={{
                    color: '#8C8C8C',
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                  }}
                />
              </Space>
            </div>
          </Col>
        </Row>
      </List.Item>
    );
  }
  return (
    <Collapse expandIconPosition="end" bordered={false}>
      <Collapse.Panel header={<span style={{ fontWeight: 'bold' }}>Document list</span>} key="1">
        <List
          dataSource={dd_center_items}
          renderItem={(ddCenterItem) => (
            <Item
              setViewDiligenceVisible={setViewDiligenceVisible}
              setViewDiligence={setViewDiligence}
              ddCenterItem={ddCenterItem}
            />
          )}
        />
      </Collapse.Panel>
    </Collapse>
  );
}

function LoanPackageItem(props) {
  const { loanPackage, setViewDiligenceVisible, setViewDiligence, dd_center_id, getLoanPackages } = props;
  const {
    action,
    created_at,
    // created_by_email,
    // created_by_id,
    // created_by_name,
    dd_center_items,
    // dd_center_items_count,
    id,
    package_name,
    sent_to,
    zip_path,
  } = loanPackage;

  const downloadPackage = () => {
    window.open(zip_path, '_blank');
  };
  const resendLoanPackage = () => {
    API()
      .post('/LAR/dd_center/loan_package-resend', {
        email: sent_to,
        template: 'Here is my first package',
        subject: 'My subject',
        loan_package_id: id,
      })
      .then(() => {
        message.info('Loan package has been resend successfully!');
      });
  };

  const deleteLoanPackage = () => {
    API()
      .delete('/LAR/dd_center/loan_package', {
        params: {
          dd_center_id,
          loan_package_id: id,
        },
      })
      .then(() => {
        message.info('Loan package has been deleted successfully!');
        getLoanPackages();
      });
  };

  return (
    <div className="loan-package-item">
      <div className="loan-package-item__inner">
        <div className="loan-package-item__data">
          <Row justify="space-between" align="top">
            <Space direction="vertical">
              <span>{package_name}</span>
              <span className="description-12">{created_at}</span>
              {action === 'sent' ? (
                <span>
                  <span>Sent to:</span> <span>{sent_to}</span>
                </span>
              ) : null}
            </Space>
            <Space>
              <Button onClick={downloadPackage} text="Download" svg={<DownloadIcon />} />
              <Button text="Delete" onClick={deleteLoanPackage} svg={<TrashIcon />} />
              {action === 'sent' ? <Button text="Resend" onClick={resendLoanPackage} className="btn_darkblue" /> : null}
            </Space>
          </Row>
        </div>
        <div className="loan-package-item__document-list">
          <LoanPackageDocumentList
            setViewDiligenceVisible={setViewDiligenceVisible}
            setViewDiligence={setViewDiligence}
            dd_center_items={dd_center_items}
          />
        </div>
      </div>
    </div>
  );
}

function LoanPackagesOverview(props) {
  const { dd_center_id, setViewDiligenceVisible, setViewDiligence } = props;
  const [loanPackagesList, setLoanPackagesList] = useState({ loading: false, data: [] });
  const getLoanPackages = () => {
    API()
      .get(`/LAR/dd_center/loan_package?dd_center_id=${dd_center_id}`)
      .then((res) => {
        setLoanPackagesList((prevState) => ({
          ...prevState,
          data: res.data.loan_packages,
          loading: true,
        }));
      });
  };

  useEffect(() => {
    getLoanPackages();
    // eslint-disable-next-line
  }, []);

  return (
    <List
      className="loan-packages-overview"
      // loading={initLoading}
      itemLayout="horizontal"
      // loadMore={loadMore}
      dataSource={loanPackagesList.data}
      renderItem={(loanPackage) => (
        <List.Item>
          <LoanPackageItem
            getLoanPackages={getLoanPackages}
            dd_center_id={dd_center_id}
            setViewDiligenceVisible={setViewDiligenceVisible}
            setViewDiligence={setViewDiligence}
            loanPackage={loanPackage}
          />
        </List.Item>
      )}
    />
  );
}
export default LoanPackagesOverview;
