import { Form, Input, Modal, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ArchiveIcon } from 'assets/icons';
import { updateDashboardMetricsTimestamp } from 'store/actions/dashboard';

import styles from './styles/PassModal.module.css';

export default function PassModal({ API, rescind, onCancel, ...props }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  function submitForm({ reason, additionalInfo }) {
    API.pass(reason, additionalInfo).then(() => {
      onCancel?.(true);
      dispatch(updateDashboardMetricsTimestamp());
      history.push('/onboarding/scenarios');
    });
  }

  return (
    <Modal
      {...props}
      title={rescind ? 'Rescind' : 'Pass'}
      onOk={form.submit}
      okText={rescind ? 'Rescind' : 'Pass'}
      onCancel={() => onCancel?.(false)}
    >
      <Form form={form} layout="vertical" onFinish={submitForm}>
        <Form.Item label="Select the reason" name="reason" required>
          <Select
            options={[
              { value: 'The loan request does not meet our lending criteria' },
              { value: 'Our team is too busy with other loan requests' },
              { value: 'We cannot be competitive with the requested terms' },
              { value: 'We have already seen this loan request' },
              { value: 'We need more detailed information to place a quote' },
            ]}
          />
        </Form.Item>
        <Form.Item label="Add additional information" name="additionalInfo">
          <Input.TextArea placeholder="Additional information" />
        </Form.Item>
      </Form>
      {!rescind && (
        <div className={styles.note}>
          <ArchiveIcon />
          This scenario will be archived when you pass
        </div>
      )}
    </Modal>
  );
}
