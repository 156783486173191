import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <path d="M3 21H21" />
    <path d="M9 8H10" />
    <path d="M9 12H10" />
    <path d="M9 16H10" />
    <path d="M14 8H15" />
    <path d="M14 12H15" />
    <path d="M14 16H15" />
    <path d="M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21" />
  </GenericIcon>
));
