import { BinIcon, EditIcon } from 'assets/icons';
import { ChevronIcon } from 'assets/icons/icons';
import { Modal, Space, message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from 'api';
import CustomButton from 'components/UI/Button';
import UniversalTable from 'components/UniversalTable';

function DeleteMandateModal({ visible, deleteMandate, onCloseActiveModal }) {
  return (
    <Modal
      className="wrapper_prospect wrapper_prospect_delete"
      title={
        <div className="wrapper_prospect__title">
          <span className="title">Delete mandate</span>
          <span className="description">Are you sure you want to delete a mandate?</span>
        </div>
      }
      centered
      onOk={onCloseActiveModal}
      open={visible}
      footer={
        <div className="wrapper_remove_prospect_btns">
          <CustomButton key="back" onClick={onCloseActiveModal} text="Cancel" />
          <CustomButton key="submit" onClick={deleteMandate} primary text="Confirm" />
        </div>
      }
      onCancel={onCloseActiveModal}
    />
  );
}

export default function SuperadminMandatesTable() {
  const [activeModal, setActiveModal] = useState({ show: '', id: null });
  const history = useHistory();
  const [sortBy, setSortBy] = useState({
    order_by: 'recent_update',
    order_type: false,
  });

  const columns = [
    {
      title: 'Mandate name',
      dataIndex: 'mandate_name',
      key: 'mandate_name',
    },
    {
      title: 'Lender company',
      dataIndex: 'lender_company',
      key: 'lender_company',
    },
    {
      title: 'Active/Not active',
      dataIndex: 'status',
      key: 'status',
      render: (bool) => (bool ? 'Active' : 'Not active'),
    },
    {
      title: 'Assigned to',
      dataIndex: 'assigned_to',
      key: 'assigned_to',
    },
    {
      title: 'Recent update',
      dataIndex: 'recent_update',
      key: 'recent_update',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, obj) => (
        <Space size="large">
          <EditIcon
            onClick={() => {
              history.push({
                pathname: `/onboarding/mandates/edit_mandate/${obj.id}`,
                state: {
                  company_id: obj.company_id,
                },
              });
            }}
          />
          <BinIcon onClick={() => setActiveModal({ show: 'delete_mandate', id: obj.id })} />
        </Space>
      ),
    },
  ];
  const [mandatesData, setMandatesData] = useState({ mandates: [], loading: true });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const onCloseActiveModal = () => setActiveModal({ show: '', data: null });

  const tableHeadClick = (column) => {
    setSortBy((prevState) => ({
      order_by: column.dataIndex,
      order_type: prevState.order_by === column.dataIndex ? !prevState.order_type : true,
    }));
  };

  const chevron = (active) => (
    <ChevronIcon
      style={{
        display: 'flex',
        color: '#8c8c8c',
        transition: '.3s',
        transform: active && sortBy.order_type ? 'rotate(180deg)' : 'rotate(0)',
      }}
    />
  );

  const columnsModificated = columns.map((el) => ({
    ...el,
    title:
      el.key !== 'actions' ? (
        <div className="inline">
          <span>{el.title}</span>
          {chevron(sortBy.order_by === el.key)}
        </div>
      ) : null,
    onHeaderCell:
      el.key !== 'actions'
        ? (column) => ({
            onClick: () => tableHeadClick(column),
          })
        : null,
  }));

  const getMandates = () => {
    API()
      .get(
        `/superadmin/mandates?order_by=${sortBy.order_by}&order=${sortBy.order_type ? 'asc' : 'desc'}&page=${
          pagination.current
        }&per_page=${pagination.pageSize}`,
      )
      .then((res) => {
        setMandatesData((prevState) => ({
          ...prevState,
          mandates: res?.data?.mandates,
          loading: false,
        }));
        setPagination((prevState) => ({ ...prevState, total: res?.data?.total_count }));
      });
  };

  const deleteMandate = () => {
    API()
      .delete(`/user_settings/mandates/lender/creation?id=${activeModal.id}`)
      .then(() => {
        message.success('Mandate deleted successfully');
        onCloseActiveModal();
      });
  };

  useEffect(() => {
    getMandates();
    // eslint-disable-next-line
  }, [pagination.current, pagination.pageSize, sortBy]);

  return (
    <div>
      <UniversalTable
        setPagination={setPagination}
        loading={mandatesData.loading}
        withHeader={false}
        dataSource={mandatesData.mandates}
        columns={columnsModificated}
        withPagination
        pagination={pagination}
        rowKey={(row) => row.id}
      />
      <DeleteMandateModal
        visible={activeModal.show === 'delete_mandate'}
        deleteMandate={deleteMandate}
        onCloseActiveModal={onCloseActiveModal}
      />
    </div>
  );
}
