import { List } from 'antd';
import './styles/custom_list.css';

const defaultStyle = {
  borderRadius: '8px',
};

function CustomList({ style, data, header, loading, loadMoreBtn, bordered }) {
  const loadMore = !loading && loadMoreBtn ? loadMoreBtn : null;

  return (
    <List
      header={header}
      loading={loading}
      loadMore={loadMore}
      className={`custom-list ${bordered ? 'custom-list_bordered' : ''}`}
      itemLayout="horizontal"
      dataSource={data}
      style={{
        ...defaultStyle,
        ...style,
      }}
      bordered={bordered}
      renderItem={(item) => (
        <List.Item actions={item.actions}>
          <List.Item.Meta avatar={item.avatar} title={item.title} description={item.date} />
        </List.Item>
      )}
    />
  );
}

export default CustomList;
