import { Drawer } from 'antd';
import PlaceBid from './PlaceBid';

export default function PlaceBidDrawer({
  activeDrawer,
  edit,
  mask = false,
  onClose,
  onPlaceBid = () => null,
  openChooseBidDrawer,
  title = true,
  visible,
}) {
  return (
    <Drawer destroyOnClose mask={mask} onClose={onClose} placement="right" open={visible} width={500}>
      <PlaceBid
        activeDrawer={activeDrawer}
        drawerType
        edit={edit}
        onCloseActiveDrawer={onClose}
        onPlaceBid={onPlaceBid}
        openChooseBidDrawer={openChooseBidDrawer}
        title={title}
      />
    </Drawer>
  );
}
