import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter';
import { ClockIcon, InfoCircleIcon, TickIcon, WarningTriangleIcon } from 'assets/icons';
import { message, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useReducer, useMemo, useState } from 'react';
import API from 'api';
import CustomButton from 'components/UI/Button';
import classes from './styles/UnderwritingFooter.module.css';
import ItemRejectionPopup from './ItemRejectionPopup';

function RegularFooter({ sentToBorrowerAt, borrowerViewedAt, submitApprove }) {
  return (
    <div className={classes.regularFooter}>
      {borrowerViewedAt ? (
        <span>
          Borrower viewed item at
          {borrowerViewedAt}
        </span>
      ) : sentToBorrowerAt ? (
        <span>
          Sent to borrower on
          {sentToBorrowerAt}
        </span>
      ) : (
        <span />
      )}
      {borrowerViewedAt || <CustomButton onClick={submitApprove} primary text="Continue" />}
    </div>
  );
}

function LoanDecisionFooter({ itemId, closeDiligenceItem }) {
  const { id } = useParams();
  const [action, setAction] = useState(null);
  const btnData = {
    defaultIconColor: '#8c8c8c',
    buttons: [
      {
        action: 'approve',
        activeStyle: { background: '#d9f7be', color: '#389e0d' },
        Icon: TickIcon,
      },
      {
        action: 'suspend',
        activeStyle: { background: '#bae7ff', color: '#2951e7' },
        Icon: ClockIcon,
      },
      {
        action: 'decline',
        activeStyle: { background: '#fff1b8', color: '#d48806' },
        Icon: WarningTriangleIcon,
      },
    ],
  };

  const btnsView = btnData.buttons.map((btn) => {
    const isActive = action === btn.action;

    return (
      <CustomButton
        key={btn.action}
        style={isActive ? btn.activeStyle : {}}
        onClick={() => setAction(btn.action)}
        text={
          <>
            <btn.Icon colour={isActive ? btn.activeStyle.color : btnData.defaultIconColor} />{' '}
            {capitalizeFirstLetter(btn.action)}
          </>
        }
      />
    );
  });

  const onConfirm = () => {
    API()
      .post(`/LAR/dd_center/item/underwriting/loan_decision?lar_id=${id}`, {
        item_id: itemId,
        action, // 'allowed': ['approve', 'suspend', 'decline']
      })
      .then(() => {
        message.success(`${action.toUpperCase()}. Item successfully confirmed!`);
        closeDiligenceItem(true);
      })
      .catch(({ response }) => message.error(response?.data?.error));
  };

  return (
    <div className={classes.loanDecisionFooter}>
      {btnsView}
      <Tooltip title="Once confirmed, this loan decision cannot be changed. If the decision changes, then you can add a new Loan Decision item.">
        <InfoCircleIcon />
      </Tooltip>
      <CustomButton disabled={!action} onClick={onConfirm} primary text="Confirm" />
    </div>
  );
}

function FinalFooter({ submitApprove, toggleRejectPopupVisible }) {
  return (
    <div>
      <CustomButton text="Contact PLA" onClick={() => alert('Not implemented!')} />
      <CustomButton onClick={submitApprove} primary text="Approve" />
      <CustomButton onClick={toggleRejectPopupVisible} text="Reject" />
    </div>
  );
}

export default function UnderwritingFooter({
  borrowerViewedAt,
  documentType,
  sentToBorrowerAt,
  onSubmitApprove,
  itemId,
  closeDiligenceItem,
}) {
  const [rejectPopupVisible, toggleRejectPopupVisible] = useReducer((state) => !state, false);

  function submitReject(reason) {
    alert(`Not implemented! ${reason}`);
  }

  const ExactComponent = useMemo(() => {
    switch (documentType) {
      case 'lender_loan_documents':
      case 'revised_loan_quote':
      case 'underwriting':
        return RegularFooter;

      case 'loan_decisions_and_conditions':
        return LoanDecisionFooter;

      case 'final_approval':
        return FinalFooter;

      default:
        return () => null;
    }
  }, [documentType]);

  return (
    <>
      <ExactComponent
        borrowerViewedAt={borrowerViewedAt}
        sentToBorrowerAt={sentToBorrowerAt}
        closeDiligenceItem={closeDiligenceItem}
        submitApprove={onSubmitApprove}
        itemId={itemId}
        toggleRejectPopupVisible={toggleRejectPopupVisible}
      />
      <ItemRejectionPopup onCancel={toggleRejectPopupVisible} onOk={submitReject} visible={rejectPopupVisible} />
    </>
  );
}
