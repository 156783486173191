import { Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import SectionTitle from '../../../components/OnBoarding/SectionTitle';
import CustomButton from '../../../components/UI/Button';
import { FileDownloadIcon } from '../../../assets/icons/icons';
import LenderDocsLoanAppTable from '../../../components/OnBoarding/LenderAdmin/LenderDocsLoanAppTable';
import LenderDocsBreadcrumb from '../../../components/OnBoarding/LenderAdmin/LenderDocsBreadcrumb';

function LenderDocsLoanApp() {
  const history = useHistory();

  const goToCreateDoc = () => {
    history.push('/onboarding/documents/loan_application/create_document');
  };

  return (
    <div>
      <div className="section_title_wrapper" style={{ marginBottom: 24 }}>
        <LenderDocsBreadcrumb />
      </div>
      <div className="section_title_wrapper">
        <Row justify="space-between" align="middle">
          <SectionTitle title="Loan Application" />
          <CustomButton
            className="btn_darkblue"
            onClick={goToCreateDoc}
            svg={<FileDownloadIcon />}
            text="Сreate Document"
          />
        </Row>
      </div>
      <div style={{ marginTop: '24px' }}>
        <LenderDocsLoanAppTable />
      </div>
    </div>
  );
}
export default LenderDocsLoanApp;
