import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <path d="M5 5.5V21.5" />
    <path d="M19 5.5V14.5" />
    <path d="M5 5.5C5.93464 4.58387 7.19124 4.07072 8.5 4.07072C9.80876 4.07072 11.0654 4.58387 12 5.5C12.9346 6.41614 14.1912 6.92929 15.5 6.92929C16.8088 6.92929 18.0654 6.41614 19 5.5" />
    <path d="M5 14.5C5.93464 13.5839 7.19124 13.0707 8.5 13.0707C9.80876 13.0707 11.0654 13.5839 12 14.5C12.9346 15.4161 14.1912 15.9293 15.5 15.9293C16.8088 15.9293 18.0654 15.4161 19 14.5" />
  </GenericIcon>
));
