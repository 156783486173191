import { useState } from 'react';

import UniversalTable from 'components/UniversalTable';
import { apiUsageColumns } from './columns.jsx';
import ConfirmTokenResetModal from './Modals/ConfirmTokenResetModal';
import ApiChangeStatusModal from './Modals/ApiChangeStatusModal';

const data = [
  {
    companyName: 'Lender Corporation',
    companyAdmin: 'Leon Cooperini',
    status: 'Active',
    apiKey: 'sdd222sdsZzzdssdss22...',
  },
  {
    companyName: 'Lender Corporation',
    companyAdmin: 'Leon Cooperini',
    status: 'Active',
    apiKey: 'sdd222sdsZzzdssdss22...',
  },
  {
    companyName: 'Lender Corporation',
    companyAdmin: 'Leon Cooperini',
    status: 'Disabled',
    apiKey: 'sdd222sdsZzzdssdss22...',
  },
  {
    companyName: 'Lender Corporation',
    companyAdmin: 'Leon Cooperini',
    status: 'Disabled',
    apiKey: 'sdd222sdsZzzdssdss22...',
  },
];

function ApiUsageTable() {
  const [confirmTokenVisible, setConfirmTokenVisible] = useState(false);
  const [apiChangeStatusVisible, setApiChangeStatusVisible] = useState(false);

  function toggleApiChange() {
    setApiChangeStatusVisible((prev) => !prev);
  }
  function toggleConfirmToken() {
    setConfirmTokenVisible((prev) => !prev);
  }
  const actionsItems = [
    { label: <div onClick={toggleApiChange}>Disable</div> },
    { label: <div onClick={toggleConfirmToken}>Refresh</div> },
  ];

  const columns = apiUsageColumns(actionsItems);

  return (
    <>
      <UniversalTable columns={columns} withPagination={false} dataSource={data} loading={false} withHeader={false} />
      <ConfirmTokenResetModal isOpen={confirmTokenVisible} onCancel={toggleConfirmToken} />
      <ApiChangeStatusModal onCancel={toggleApiChange} isOpen={apiChangeStatusVisible} />
    </>
  );
}

export default ApiUsageTable;
