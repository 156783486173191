import { BinIcon, PlusIcon } from 'assets/icons';
import { Checkbox, Dropdown, Menu, Table, Tooltip } from 'antd';
import { HorizontalDotsIcon, InfoCircleIcon } from 'assets/icons/icons';
import { useReducer, useCallback, useState } from 'react';
import CustomButton from 'components/UI/Button';
import PaginationItemRender from 'constants/PaginationItemRender';
import SwitchBox from 'components/CustomComponents/SwitchBox';
import Modal from 'components/Modals/Modal';

export default function EditMasterlistTable({
  data = [],
  goToDocumentAddPage,
  goToDocumentEditPage,
  handleRemoveDoc,
  handleCheckRequired,
  loading,
}) {
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [selectedRows, toggleSelectedRow] = useReducer(
    (state, id) => (id ? (state.includes(id) ? state.filter((stateId) => id !== stateId) : [...state, id]) : []),
    [],
  );

  const deleteSelected = useCallback(() => {
    setDeletePopupOpen(false);
    handleRemoveDoc(selectedRows);
    toggleSelectedRow();
  }, [selectedRows, handleRemoveDoc]);

  const columns = [
    {
      title: '',
      key: 'select',
      width: 100,
      render: (val, item) => (
        <Checkbox checked={selectedRows.includes(item.id)} onClick={() => toggleSelectedRow(item.id)} />
      ),
    },
    {
      title: (
        <Tooltip title="Select up to 10 items that are required to be included in the initial loan package">
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            Required <InfoCircleIcon fill="currentColor" />
          </span>
        </Tooltip>
      ),
      dataIndex: 'required',
      key: 'required',
      width: 50,
      render: (val, item) => <SwitchBox value={val} onClick={() => handleCheckRequired(item.id)} />,
    },
    {
      title: 'Document name',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: 'Document type',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (val) => (val ? <span>{`${val[0].toUpperCase()}${val.slice(1).replaceAll('_', ' ')}`}</span> : null),
    },
    {
      title: 'Document category',
      dataIndex: 'category',
      key: 'category',
      width: 150,
    },
    {
      title: 'Document description',
      dataIndex: 'description',
      key: 'description',
      width: 360,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <CustomButton
            disabled={selectedRows.length === 0}
            onClick={() => setDeletePopupOpen(true)}
            primary
            style={{ padding: '4px 10px', fontsize: '14px', whiteSpace: 'nowrap' }}
            svg={<BinIcon colour="white" />}
            text="Delete"
          />
          <CustomButton
            onClick={goToDocumentAddPage}
            primary
            style={{ padding: '4px 10px', fontsize: '14px', whiteSpace: 'nowrap' }}
            svg={<PlusIcon colour="white" />}
            text="Add item"
          />
        </div>
      ),
      dataIndex: 'user_actions',
      key: 'user_actions',
      width: 50,
      render: (val, item) => (
        <Dropdown
          overlay={
            <Menu
              style={{ minWidth: 160 }}
              className="custom-dropdown-menu custom-dropdown-menu_default"
              items={[
                {
                  label: 'Remove',
                  onClick: () => handleRemoveDoc([item.id]),
                },
                {
                  label: 'Edit',
                  onClick: () => goToDocumentEditPage(item.id),
                },
              ]}
            />
          }
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'end',
            }}
          >
            <button
              aria-label="openSettings"
              style={{
                border: 'none',
                cursor: 'pointer',
                padding: 0,
                display: 'flex',
                background: 'rgba(215, 211, 248, 0.4)',
              }}
            >
              <HorizontalDotsIcon style={{ color: '#5744E4' }} />
            </button>
          </div>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="section_table_wrapper table_with_pagination table_with_middle-align">
      <Modal onOk={deleteSelected} open={deletePopupOpen} onCancel={() => setDeletePopupOpen(false)}>
        Are you sure you want to delete selected items?
      </Modal>
      <Table
        columns={columns}
        loading={loading}
        pagination={{
          style: {
            marginRight: 20,
          },
          showSizeChanger: true,
          itemRender: PaginationItemRender,
        }}
        scroll={{ x: 1200 }}
        rowKey="id"
        border
        bordered={false}
        dataSource={data}
      />
    </div>
  );
}
