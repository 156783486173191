import { Checkbox, Form } from 'antd';

function CheckOwner({ isDisplayOccupied }) {
  if (!isDisplayOccupied) {
    return null;
  }

  return (
    <>
      <Form.Item label="Occupied" name="is_currently_occupied" valuePropName="checked">
        <Checkbox />
      </Form.Item>
    </>
  );
}

export default CheckOwner;
