import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Checkbox, Button } from 'antd';

import API from 'api';
import Keys from 'constants/helper';
import regExp from 'constants/regExp';
import useNestedCheckError from 'constants/useNestedCheckError';
import TextInput from 'components/CustomComponents/TextInput';
import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter';
import { setUserData, updateChatConnection } from 'store/actions/userActions';
import { useRedirectToDefaultPage } from 'hooks/useRedirectToDefaultPage';

import 'pages/Auth/Login/login.css';

const initState = {
  email: {
    value: '',
    error: null,
    required: true,
    errorMessage: 'Fill this label',
  },
  password: {
    value: '',
    error: null,
    required: true,
    errorMessage: 'Fill this label',
  },
};

function Login() {
  const [field, setFields] = useState(initState);
  const [responseError, setResponseError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isKeepMe, setIsKeepMe] = useState(JSON.parse(localStorage.getItem(Keys.KEEP_ME) || false));
  const { checkEmpty } = useNestedCheckError(field, setFields);
  const { redirectToDefaultPage } = useRedirectToDefaultPage();
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (event) => {
    event.preventDefault();

    const { error, obj } = checkEmpty(field);
    setFields(obj);
    if (error) {
      return;
    }

    if (!regExp.email.test(field.email.value)) {
      setFields((prevState) => ({
        ...prevState,
        email: {
          ...prevState.email,
          error: true,
          errorMessage: 'Incorrect email format',
        },
      }));
      return;
    }
    if (field.password.value.length <= 7) {
      setFields((prevState) => ({
        ...prevState,
        password: {
          ...prevState.password,
          error: true,
          errorMessage: 'Enter at least 8 characters',
        },
      }));
      return;
    }

    const requestUrl = '/sign_in/login';
    const requestDataLogin = 'email';

    setLoading(true);
    // TODO: return toLowerCase
    API()
      .post(requestUrl, {
        // [requestDataLogin]: field.email.value.toLowerCase(),
        [requestDataLogin]: field.email.value,
        password: field.password.value,
      })
      .then((res) => {
        if (res.data.registration_status === 'Incomplete by User') {
          const role = res.data.role.split(' ').pop();
          localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token);
          setTimeout(() => history.push(`/registration/${role}/confirm`), 1000);
          return;
        }
        const userData = {
          role: res.data.role,
          avatar: res.data.profile_photo,
          ...res.data,
        };

        localStorage.setItem(Keys.KEEP_ME, isKeepMe);
        localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token);
        dispatch(updateChatConnection());
        dispatch(setUserData({ ...userData, token: res.data.access_token }));
        // Can timeut be removed without breaking anything?
        setTimeout(redirectToDefaultPage, 1e3);
      })
      .catch((e) => {
        console.error(e);
        const error = e.response?.data?.error;

        if (error) {
          if (error === 'cant find user') {
            setFields((prevState) => ({
              ...prevState,
              email: {
                ...prevState.email,
                error: true,
              },
            }));
          } else if (error === 'wrong password') {
            setFields((prevState) => ({
              ...prevState,
              password: {
                ...prevState.password,
                error: true,
              },
            }));
          } else if (error === 'wrong data') {
            setFields((prevState) => ({
              ...prevState,
              email: {
                ...prevState.email,
                error: true,
              },
              password: {
                ...prevState.password,
                error: true,
              },
            }));
          }
          setResponseError(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = (event) => {
    const { value, name } = event.target;
    setFields((prevState) => ({
      ...prevState,
      [name]: {
        value,
        error: null,
      },
    }));
    setResponseError(null);
  };

  return (
    <div className="wrapper_login wrapper_auth">
      <h3 className="auth_title">Log in to your account</h3>
      <form className="wrapper_auth__inputs" onSubmit={onSubmit}>
        <TextInput
          value={field.email.value.trim()}
          title="Email"
          error={field.email.error}
          htmlFor="email"
          errorMessage={field.email.errorMessage}
          onChange={onChange}
          disabled={loading}
        />
        <TextInput
          value={field.password.value}
          error={field.password.error}
          showPassIcon
          title="Password"
          autoComplete="on"
          type="password"
          errorMessage={field.password.errorMessage}
          htmlFor="password"
          onChange={onChange}
          disabled={loading}
        />
        {responseError && (
          <div className="wrapper_form_error">
            <span>{capitalizeFirstLetter(responseError)}</span>
          </div>
        )}
        <Checkbox disabled={loading} onChange={(e) => setIsKeepMe(e.target.checked)}>
          Keep me signed in
        </Checkbox>
        <Button size="large" type="dark" htmlType="submit" loading={loading}>
          Continue
        </Button>
      </form>
      <Link to="/reset_password" className="forgot_password_link">
        Forgot password
      </Link>
    </div>
  );
}

export default Login;
