import { Col, Row, Card, Divider, Typography } from 'antd';

import MapContainer from '../../../../../components/GoogleApiWrapper/MapWidget';

export default function LoanProfileConstructionDetails({ data = {} }) {
  const list = [
    { title: 'Loan Style and Project Use', value: data.loan_style_and_project_use },
    { title: 'Property Type to be Built', value: data.property_type_to_be_build },
    { title: 'Property Description', value: data.property_description },
    { title: 'Number of Units/Keys/Rooms', value: data.number_units },
    { title: 'Will the property be owner occupied?', value: data.owner_occupied ? 'Yes' : 'No' },
    { title: 'Buildable or Renovation Area (Sq. Ft)', value: data.buildable_area_sq_ft },
    { title: 'Lot Size (Sq. Ft)', value: data.lot_size_sq_ft },
    { title: 'Date of Land Purchase', value: data.land_purchase_date },
    {
      title: 'Current Land Value',
      value: data.current_land_value ? `$${data.current_land_value.toLocaleString()}` : null,
    },
    { title: 'is there a loan on the land?', value: data.land_loan ? 'Yes' : 'No' },
    { title: 'Land Loan Remaining Balance', value: data.land_loan_remaining_balance },
  ];

  const list2 = [
    { title: 'The Location is Rural', value: data.location_is_rural ? 'Yes' : 'No' },
    { title: 'Property Address:', value: data.property_address },
    { title: 'Property Address: ', value: data.property_address2 },
  ];

  return (
    <Card>
      <Typography.Title level={4}>Construction Details</Typography.Title>
      <Divider style={{ margin: '0 0 10px 0' }} />
      <Row gutter={[40, 8]}>
        <Col span={12}>
          <div style={{ position: 'relative', minHeight: '260px' }}>
            <MapContainer
              geocoder
              location={{
                city: data.property_city,
                state: data.property_state,
                zip: data.property_postal_code,
                address: data.property_address,
              }}
            />
          </div>
        </Col>
        <Col span={12}>
          <Row gutter={[8, 8]}>
            {list
              .filter((i) => i.value)
              .map((item, index) => (
                <Col span={24} key={index}>
                  <Row justify="space-between">
                    <Col>
                      <Typography.Text type="secondary">{item.title}</Typography.Text>
                    </Col>
                    <Col>{item.value}</Col>
                  </Row>
                </Col>
              ))}
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[8, 8]}>
            {list2
              .filter((i) => i.value)
              .map((item, index) => (
                <Col span={24} key={index}>
                  <Row justify="space-between" key={index}>
                    <Col>
                      <Typography.Text type="secondary">{item.title}</Typography.Text>
                    </Col>
                    <Col>{item.value}</Col>
                  </Row>
                </Col>
              ))}
            <Col span={24}>
              <Row justify="space-between" gutter={[16, 16]}>
                <Col span={8}>
                  <Typography.Text type="secondary">Property City:</Typography.Text>
                  <div>{data.property_city}</div>
                </Col>
                <Col span={8}>
                  <Typography.Text type="secondary">Property State:</Typography.Text>
                  <div>{data.property_state}</div>
                </Col>
                <Col span={8}>
                  <Typography.Text type="secondary">Property Postal Code:</Typography.Text>
                  <div>{data.property_postal_code}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
