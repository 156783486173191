import moment from 'moment';
import 'moment-timezone';

export const convertToTimezone = (time, userTimezone) => {
  // time: time to convert
  // userTimezone: user (local storage) timezone

  if (userTimezone === 'ET') {
    return moment(time, 'LT').tz('America/New_York').format('LT');
  }
  if (userTimezone === 'CT') {
    return moment(time, 'LT').tz('America/Chicago').format('LT');
  }
  if (userTimezone === 'MT') {
    return moment(time, 'LT').tz('America/Denver').format('LT');
  }
  if (userTimezone === 'PT') {
    return moment(time, 'LT').tz('America/Los_Angeles').format('LT');
  }
  // if no timezone returns default time from args
  return time;
};

// export const convertFromTimezone = (time, fromTimezone) => {
//     if (fromTimezone === 'ET') {
//         return moment(time, 'hh:mm:ss').tz("America/New_York").format('hh:mm:ss');
//     } else if (fromTimezone === 'CT') {
//         return moment(time, 'hh:mm:ss').tz("America/Chicago").format('hh:mm:ss');
//     } else if (fromTimezone === 'MT') {
//         return moment(time, 'hh:mm:ss').tz("America/Denver").format('hh:mm:ss');
//     } else if (fromTimezone === 'PT') {
//         return moment(time, 'hh:mm:ss').tz("America/Los_Angeles").format('hh:mm:ss');
//     } else {
//         //if no timezone returns default time from args
//         return time
//     }
//
// }
