import { Dropdown, Menu } from 'antd';
import UniversalTable from '../../UniversalTable';
import { HorizontalDotsIcon } from '../../../assets/icons/icons';

const data = [
  {
    document_name: 'LAR Loan Application',
    description: 'Description',
    purpose: 'Acquisition, Refinance',
    style: 'Fix to flip, Single family Rental',
    updated: 'Kate Spilberg (12 Apr 2021)',
  },
  {
    document_name: 'LAR Loan Application',
    description: 'Description',
    purpose: 'Acquisition, Refinance',
    style: 'Fix to flip, Single family Rental',
    updated: 'Kate Spilberg (12 Apr 2021)',
  },
  {
    document_name: 'LAR Loan Application',
    description: 'Description',
    purpose: 'Acquisition, Refinance',
    style: 'Fix to flip, Single family Rental',
    updated: 'Kate Spilberg (12 Apr 2021)',
  },
  {
    document_name: 'LAR Loan Application',
    description: 'Description',
    purpose: 'Acquisition, Refinance',
    style: 'Fix to flip, Single family Rental',
    updated: 'Kate Spilberg (12 Apr 2021)',
  },
];

function LenderDocsLoanAppTable() {
  const columns = [
    {
      title: 'Document name',
      dataIndex: 'document_name',
      key: 'document_name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Purpose',
      dataIndex: 'purpose',
      key: 'purpose',
    },
    {
      title: 'Style',
      dataIndex: 'style',
      key: 'style',
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: () => (
        <div style={{ justifyContent: 'end', display: 'flex' }}>
          <Dropdown overlay={<Menu className="custom-dropdown-menu custom-dropdown-menu_default" items={[]} />}>
            <button
              aria-label="openSettings"
              style={{
                border: 'none',
                cursor: 'pointer',
                display: 'flex',
                padding: 0,
              }}
            >
              <HorizontalDotsIcon style={{ color: '#8c8c8c' }} />
            </button>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <UniversalTable columns={columns} withPagination={false} dataSource={data} loading={false} withHeader={false} />
  );
}
export default LenderDocsLoanAppTable;
