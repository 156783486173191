import { memo } from 'react';
import colours from './colours';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params} colour="#fdfdff">
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      fill={colours.get(params.colour || 'green-6')}
      stroke={colours.get(params.colour || 'green-6')}
    />
    <path d="M9 12L11 14L15 10" />
  </GenericIcon>
));
