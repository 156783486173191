import { useCallback, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navigations from '../routers/Navigations';

export function useRedirectToDefaultPage() {
  const history = useHistory();
  const store = useSelector((store) => store.user.userData);

  const pathname = useMemo(
    () =>
      Navigations.mainNavigationOnboarding.find((i) => (i.userAccess ? i.userAccess.includes(store?.role) : null))
        ?.path,
    [store?.role],
  );

  const redirectToDefaultPage = useCallback(() => pathname && history.push({ pathname }), [history, pathname]);

  const RedirectToDefaultPage = useMemo(
    () =>
      function () {
        return pathname ? <Redirect to={pathname} /> : null;
      },
    [pathname],
  );

  return { RedirectToDefaultPage, redirectToDefaultPage };
}
