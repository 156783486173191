import './styles/custom_text_area.css';
import { Input } from 'antd';
import React from 'react';

function CustomTextArea({
  label,
  htmlFor,
  value,
  placeholder,
  onChange,
  rows,
  errorMessage,
  error,
  onBlur,
  style,
  disabled,
  maxLength = null,
  showCount = false,
}) {
  return (
    <div style={style} className={`custom_text_area ${error ? 'custom_text_area_error' : ''}`}>
      {label ? (
        <label htmlFor={htmlFor ?? null} className="" style={{ marginBottom: 8 }}>
          {label}
        </label>
      ) : null}
      <Input.TextArea
        rows={rows ?? 4}
        disabled={disabled}
        id={htmlFor ?? null}
        maxLength={maxLength}
        name={htmlFor ?? null}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder ?? ''}
        showCount={showCount}
        value={value}
      />
      {error ? <span className="error">{errorMessage}</span> : null}
    </div>
  );
}

export default CustomTextArea;
