import {
  AuctionStatsColumnContent,
  AuctionStatsColumnTitle,
  DealInfoColumnContent,
  DealInfoColumnTitle,
  EventColumnContent,
  EventColumnTitle,
  SelectionProcessColumnContent,
  SelectionProcessColumnTitle,
} from './FinishedAuctionsTableColumns';

const columns = [
  {
    title: () => <DealInfoColumnTitle />,
    render: (item) => <DealInfoColumnContent item={item} />,
    dataIndex: 'dealType',
    width: '44%',
  },
  {
    title: () => <AuctionStatsColumnTitle />,
    render: (item) => <AuctionStatsColumnContent item={item} />,
    dataIndex: 'auctionStats',
    width: '13%',
  },
  {
    title: () => <EventColumnTitle />,
    render: (item) => <EventColumnContent item={item} />,
    dataIndex: 'event',
    width: '10%',
  },
  {
    title: () => <SelectionProcessColumnTitle />,
    render: (item) => <SelectionProcessColumnContent item={item} />,
    dataIndex: 'selectionProcess',
    width: '33%',
  },
];

export default columns;
