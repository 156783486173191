import { Card, Col, Divider, Row, Space, Table, Typography } from 'antd';

import styles from './LoanProfileBorrowerInformation.module.css';

export default function LoanProfileBorrowerInformation({ data = {} }) {
  const dataSource = data.owners?.map((owner) => ({
    key: owner.id,
    first_name: <b>{owner?.first_name}</b>,
    last_name: <b>{owner?.last_name}</b>,
    owner_ship: owner?.percent_ownership ? `${Math.round(owner.percent_ownership * 100)}%` : '0%',
    fico: owner?.credit_rating,
  }));

  const columns = [
    { title: 'First', dataIndex: 'first_name', key: 'first_name' },
    { title: 'Last', dataIndex: 'last_name', key: 'last_name' },
    { title: 'OwnerShip', dataIndex: 'owner_ship', key: 'owner_ship', align: 'right' },
    { title: 'FICO', dataIndex: 'fico', key: 'fico', align: 'right' },
  ];

  const businessInfo = [
    { label: 'Assets Under Management', value: data.borrower_aum },
    { label: 'Borrower Funds Available', value: data.borrower_funds_available },
    { label: 'CRE Investment Experience', value: data.borrowers_experience },
    { label: 'Rentals Owned', value: data.rentals_owned },
    { label: 'Flips Completed (past 36 months)', value: data.flips_completed },
    { label: 'Borrower Type', value: data.borrower_type },
    { label: 'Entity Status', value: data.entity_status },
    { label: 'Entity Type', value: data.borrower_type === 'Individual' ? null : data.entity_type },
    { label: 'Entity Domiciled', value: data.entity_domiciled },
  ];

  const personalInfo = [
    { label: 'Citizenship', value: data.citizenship },
    { label: 'FICO', value: data.FICO },
    { label: 'Employment Status', value: data.employment_status },
    { label: 'Primary Home', value: data.primary_home },
    { label: 'Borrower Primary Home Location', value: data.borrower_primary_home_location },
    { label: 'Has Filed Bankruptcy?', value: data.bankruptcy ? 'Yes' : 'No' },
    { label: 'Has completed a foreclosure?', value: data.foreclosure ? 'Yes' : 'No' },
  ];

  return (
    <Card>
      <Typography.Title level={4}>Borrower Information</Typography.Title>
      <Divider style={{ margin: '0 0 10px 0' }} />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space direction="vertical">
            <Space>
              <Typography.Text type="secondary">Borrower Name</Typography.Text>
              <span>{data.borrower_name}</span>
            </Space>
            <Space>
              <Typography.Text type="secondary">Borrower Company</Typography.Text>
              <span>{data.borrower_company}</span>
            </Space>
          </Space>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Row gutter={[8, 8]} justify="space-between">
                <Col span={24}>
                  <Typography.Text strong>Business Information</Typography.Text>
                </Col>
                {businessInfo
                  .filter((i) => i.value)
                  .map((item, index) => (
                    <Col span={24} key={index}>
                      <Row gutter={[8, 8]} justify="space-between">
                        <Col>
                          <Typography.Text type="secondary">{item.label}</Typography.Text>
                        </Col>
                        <Col>{item.value}</Col>
                      </Row>
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[8, 8]} justify="space-between">
                <Col span={24}>
                  <Typography.Text strong>Personal Information</Typography.Text>
                </Col>
                {personalInfo
                  .filter((i) => i.value)
                  .map((item, index) => (
                    <Col span={24} key={index}>
                      <Row gutter={[8, 8]} justify="space-between">
                        <Col>
                          <Typography.Text type="secondary">{item.label}</Typography.Text>
                        </Col>
                        <Col>{item.value}</Col>
                      </Row>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Typography.Text strong>Owners</Typography.Text>
        </Col>
        <Col span={24}>
          <Table className={styles.table} columns={columns} dataSource={dataSource} pagination={false} />
        </Col>
      </Row>
    </Card>
  );
}
