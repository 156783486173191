import classNames from 'classnames';
import classes from './styles/BannerIconSelect.module.css';

export default function BannerIconSelect({ options = [], value = '', setValue = () => null }) {
  return (
    <div className={classes.root}>
      {options.map((o) => (
        <div
          className={classNames({
            [classes.iconOption]: true,
            [classes.selectedIconOption]: o.value === value,
          })}
          key={o.value}
          onClick={() => setValue(o.value)}
        >
          <o.Icon size="2em" colour="black" />
        </div>
      ))}
    </div>
  );
}
