import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button, Input, message as antdMessage, Tooltip, Typography } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ChatAPI } from 'api';
import useResize from 'constants/useResize';
import useRecorder from 'constants/useRecorder';
import useRecordingsList from 'constants/use-recording-list';
import { formatMinutes, formatSeconds } from 'utils/format-time';

import { CloseIcon, MicrophoneIcon, SendMessageIcon } from 'assets/icons/icons';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { BinIcon, PlusIcon } from 'assets/icons';

import Message from '../Message';
import MessageReply from '../MessageReply';
import ChatAttachFile from '../ChatAttachFile';
import ChatMessagingHeader from '../ChatMessagingHeader';

import styles from './ChatMessaging.module.css';

function ChatMessaging({
  activeChat,
  activeForward,
  activeReply,
  getChats,
  isOpenMessagesList,
  isSending,
  message,
  documents,
  onSetActiveChat,
  onSetActiveForward,
  onSetActiveReply,
  onSetMessage,
  onSubmitMessage,
  onSetDocuments,
  type,
}) {
  const [shouldSubmitAudio, setShouldSubmitAudio] = useState(false);
  const [error, setError] = useState('');
  const [timeTitle, setTimeTitle] = useState('');
  const { innerWidth } = useResize();
  const { recorderState, ...handlers } = useRecorder();
  const { recordingMinutes, recordingSeconds, initRecording, audio } = recorderState;
  const { recordings, deleteAudio } = useRecordingsList(audio);
  const { startRecording, saveRecording, cancelRecording } = handlers;
  const { chatState } = useSelector((s) => s.user);
  const audioRef = useRef(null);
  const chatBody = useRef(null);
  const isViewOnly = chatState?.mode === 'viewOnly';

  const API = useMemo(() => new ChatAPI(), []);

  // Audio timeline doesn't appear without this:
  useEffect(() => {
    if (audioRef.current !== null && recordings.length) {
      audioRef.current.currentTime = 999;
      setTimeout(() => (audioRef.current.currentTime = 0), 100);
    }
  }, [recordings]);

  // Timeout as we have a timeout on audio ref in upper useEffect and we delete audio here, which causes crash
  useEffect(() => {
    if (shouldSubmitAudio && recordings.length) {
      setShouldSubmitAudio(false);
      setTimeout(() => {
        onSubmitMessage(undefined, recordings);
        deleteAudio(recordings[0].key);
      }, 150);
    }
  }, [recordings, shouldSubmitAudio, deleteAudio, onSubmitMessage]);

  const deleteMessage = useCallback(
    ({ message_data }) => {
      API.deleteMessage(message_data.id)
        .then(() => API.getMessages({ chat_id: activeChat?.chat?.chat_id }))
        .then(onSetActiveChat);
    },
    [API, onSetActiveChat, activeChat?.chat?.chat_id],
  );

  const onMessageAction = useCallback(
    (key, message) => {
      switch (key) {
        case 'reply':
          onSetActiveForward(null);
          onSetActiveReply(message);
          break;

        case 'delete':
          deleteMessage(message);
          break;

        case 'forward':
        default:
          onSetActiveReply(null);
          onSetActiveForward(message);

          if (innerWidth < 750) {
            onSetActiveChat(null);
          }
      }
    },
    [deleteMessage, innerWidth, onSetActiveChat, onSetActiveForward, onSetActiveReply],
  );

  useEffect(() => {
    recordingMinutes >= 2 && saveRecording();
  }, [recordingMinutes, saveRecording]);

  const closeActiveReply = () => {
    onSetActiveReply(null);
    onSetActiveForward(null);
  };

  const formattedMessages = useMemo(
    () =>
      activeChat?.messages
        .filter((m) => m.message_data.body !== 'Deleted message')
        .map((message, index, messages) => {
          const result = {
            ...message,
            isLastOne: false,
          };
          const currentMessageTime = new Date(message.message_data.sent_at);
          const nextMessageTime = new Date(messages[index + 1]?.message_data.sent_at);

          if (index === messages.length - 1 || message.sender.id !== messages[index + 1].sender.id) {
            result.isLastOne = true;
          }

          if (nextMessageTime) {
            result.isLastOne = nextMessageTime > currentMessageTime;
          }

          return result;
        }),
    [activeChat],
  );

  const handleArchive = () => {
    API.archive(activeChat.chat.chat_id)
      .then(() => {
        onSetActiveChat((prev) => ({
          ...prev,
          chat: {
            ...prev.chat,
            in_archive: !prev.chat.in_archive,
          },
        }));
        getChats();
      })
      .then(() => {
        antdMessage.info(`Chat ${!activeChat.chat.in_archive ? 'archived' : 'unarchived'}`);
      });
  };

  // Scroll to the newest messages when opening a chat
  useEffect(() => {
    chatBody?.current && (chatBody.current.scrollTop = chatBody.current.scrollHeight);
  }, [activeChat, chatBody]);

  useEffect(() => {
    if (message.length > 524) {
      setError('The maximum allowed length for this field is 524 characters.');
    } else {
      setError('');
    }
  }, [message]);

  const handleTimeTitle = useCallback((value) => {
    setTimeTitle(value);
  }, []);

  const handleAddFile = (e) => {
    setError('');
    const fullList = [...documents, ...Object.values(e.target.files)];
    if (fullList.length > 3) {
      setError('Max limit is 3 files');
    }
    if (fullList.some((file) => file.size > 10485760)) {
      setError('Max file size is 10 mb');
    }
    const docs = fullList.filter((file) => file.size < 10485760);
    onSetDocuments(docs.slice(0, 3));
  };

  const handleRemoveFile = useCallback(
    (index) => () => {
      const docs = [...documents];
      docs.splice(index, 1);
      onSetDocuments(docs);
    },
    [documents],
  );

  const handleClearDocuments = useCallback(() => {
    onSetDocuments([]);
  }, []);

  const handleSubmit = () => {
    if (initRecording) {
      saveRecording();
      setShouldSubmitAudio(true);
    } else if (recordings.length) {
      setShouldSubmitAudio(true);
    } else {
      onSubmitMessage();
    }
  };

  return (
    <div
      className={classNames(styles.root, 'chat-messaging', {
        'active-chat': activeChat,
      })}
    >
      {activeChat && (
        <>
          <div onClick={() => onSetActiveForward(null)} className="forward-bg" />
          <ChatMessagingHeader
            type={type}
            activeChat={activeChat}
            isOpenMessagesList={isOpenMessagesList}
            onArchive={handleArchive}
            onSetActiveChat={onSetActiveChat}
          />
          <div className="chat-body" ref={chatBody}>
            {!isEmpty(formattedMessages) && (
              <>
                <div className="chat-body-title">
                  <div className="chat-body-title-time">{timeTitle}</div>
                </div>
                {formattedMessages.length > 0 &&
                  formattedMessages.map((msg, index) => (
                    <Message
                      msg={msg}
                      key={msg.message_data.id}
                      prevMsg={activeChat.messages[index - 1]}
                      onMessageAction={onMessageAction}
                      onGetTimeTitle={handleTimeTitle}
                    />
                  ))}
              </>
            )}
          </div>
          {activeChat && (
            <MessageReply activeForward={activeForward} activeReply={activeReply} closeActiveReply={closeActiveReply} />
          )}
          {!isViewOnly && (
            <form
              onSubmit={onSubmitMessage}
              className={classNames('chat-footer', documents.length > 0 && styles.withAttachFiles)}
            >
              {documents.length > 0 && (
                <>
                  <div className={styles.attachFilesHeader}>
                    <Typography.Title level={5} className={styles.attachFilesTitle}>
                      {isSending ? 'Please wait while we upload your files' : 'Attach files'}
                    </Typography.Title>
                    <Button
                      className={styles.attachFilesClearAll}
                      icon={<CloseIcon />}
                      onClick={handleClearDocuments}
                    />
                  </div>
                  <div className={styles.filesWrapper}>
                    {documents.map((file, index) => (
                      <ChatAttachFile key={index} file={file} onRemove={handleRemoveFile(index)} />
                    ))}
                    {documents.length > 0 && documents.length < 3 && (
                      <label className={styles.plusButton}>
                        <input
                          hidden
                          multiple
                          type="file"
                          accept=".png,.jpg,.doc,.docx,.xml,.xlsx,.pdf"
                          onChange={handleAddFile}
                        />
                        <PlusIcon width={24} height={24} />
                      </label>
                    )}
                  </div>
                </>
              )}
              <div className={classNames(styles.formInner, documents.length > 0 && styles.formInnerTransparent)}>
                <div className={styles.actions}>
                  {!message.length && !initRecording && !recordings.length && !documents.length ? (
                    <>
                      <div className={styles.iconWrapper}>
                        <MicrophoneIcon
                          className={classNames({
                            hidden: message.length || initRecording || recordings.length,
                          })}
                          onClick={startRecording}
                        />
                      </div>
                      <Tooltip
                        title={
                          <>
                            Max file size is 10mb <br /> PNG, JPG, PDF, Docx, XSLX files allowed
                          </>
                        }
                      >
                        <label htmlFor="files" className={styles.iconWrapper}>
                          <input
                            hidden
                            multiple
                            id="files"
                            type="file"
                            accept=".png,.jpg,.doc,.docx,.xml,.xlsx,.pdf"
                            onChange={handleAddFile}
                          />
                          <UploadIcon className={styles.icon} />
                        </label>
                      </Tooltip>
                    </>
                  ) : (
                    <div className={styles.iconWrapper}>
                      <Button
                        disabled={isSending || message.length > 524}
                        loading={isSending}
                        className={styles.actionButton}
                        onClick={handleSubmit}
                      >
                        {!isSending && <SendMessageIcon />}
                      </Button>
                    </div>
                  )}
                </div>
                {recordings.length ? (
                  <div className="recording-wrapper validation">
                    <BinIcon onClick={() => deleteAudio(recordings[0].key)} />
                    <audio className="recorded-audio" controls ref={audioRef} src={recordings[0].audio} />
                  </div>
                ) : initRecording ? (
                  <div className="recording-wrapper">
                    <BinIcon onClick={cancelRecording} />
                    <div className="recording-info">
                      <span>
                        Recording started: {formatMinutes(recordingMinutes)}:{formatSeconds(recordingSeconds)}
                      </span>
                    </div>
                    <div onClick={saveRecording} className="recording-stop">
                      <div />
                    </div>
                  </div>
                ) : !chatState?.hideInput ? (
                  <Input.TextArea
                    autoSize
                    className={styles.input}
                    placeholder="Send message..."
                    value={message}
                    onChange={({ target }) => onSetMessage(target.value)}
                  />
                ) : null}
              </div>
              {error && <Typography.Text type="danger">{error}</Typography.Text>}
            </form>
          )}
        </>
      )}
    </div>
  );
}

export default ChatMessaging;
