import { AlarmClockIcon, ClockIcon, EyeIcon, SendIcon, FileInvoiceIcon } from 'assets/icons';
import { Collapse } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Keys from 'constants/helper';
import CustomButton from 'components/UI/Button';
import Modal from 'components/Modals/Modal/Modal';
import styles from './AuctionTutorial.module.css';

export default function AuctionTutorial() {
  const [isOpen, setIsOpen] = useState(false);
  const auctionTutorial = localStorage.getItem(Keys.AUCTION_TUTORIAL_POPUP);
  const { timing_json } = useSelector((store) => store.dashboard.metrics);

  useEffect(() => {
    if (timing_json?.event === 'Auction Live') {
      localStorage.setItem(Keys.AUCTION_END_TIME, timing_json.next_event_time);
      localStorage.removeItem(Keys.AUCTION_PREVIEW_POPUP);
      if (!auctionTutorial || (auctionTutorial && !moment().isSame(auctionTutorial, 'day'))) {
        localStorage.setItem(Keys.AUCTION_TUTORIAL_POPUP, timing_json.next_event_time);
        setIsOpen(true);
      }
    }
  }, [timing_json]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      destroyOnClose
      onCancel={handleClose}
      title="The auctions have started"
      footer={<CustomButton onClick={handleClose} primary text="Close" />}
    >
      <div>It's simple and easy to participate:</div>
      <ol className={styles.list}>
        <li className={styles.listItem}>
          <span className={styles.listItemMarker}>1</span>
          <div>Select “Place a Quote”</div>
        </li>
        <li className={styles.listItem}>
          <span className={styles.listItemMarker}>2</span>
          <div>Select "Visible or Hidden" Quote</div>
        </li>
        <li className={styles.listItem}>
          <span className={styles.listItemMarker}>3</span>
          <div>Submit your quote before bid hurdle deadline</div>
        </li>
      </ol>
      <Collapse defaultActiveKey={['1']}>
        <Collapse.Panel header="Tips" key="1" className={styles.collapse}>
          <SendIcon colour="brand-5" />
          Submit a quote before the bid hurdle deadline even if you are in mid-review. You’ll have additional time until
          the end of the auction to revise and refine your quote.
          <EyeIcon colour="brand-5" />
          Hidden or Visible quote type cannot be changed once selected. Choose carefully.
          <AlarmClockIcon colour="brand-5" />
          Only lender’s which submit quotes before the Bid Deadline will be able to participate in the auction.
          <FileInvoiceIcon colour="brand-5" />
          Only place quote terms you are confident you can underwrite with the loan information provided because every
          loan term is a data point that is used to build your Lender Performance Score.
          <ClockIcon colour="brand-5" />
          All times are Eastern Time zone. Be mindful of the auction end time in your local time zone.
        </Collapse.Panel>
      </Collapse>
    </Modal>
  );
}
