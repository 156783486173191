import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import API from 'api';
import CustomButton from 'components/UI/Button';
import DropdownMenu from 'components/CustomComponents/DropdownMenu';
import styles from './AdminReassignModal.module.css';

function AdminReassignModal({ companyId, companyName, reqRole, visible, onCancel, onAfterReassign }) {
  const [adminOptions, setAdminOptions] = useState([]);
  const [selectValue, setSelectValue] = useState('');
  const role = useSelector((state) => state.user.userData?.role);

  useEffect(() => {
    API()
      .get('/user_settings/company/members', {
        params: {
          company_id: role === 'superadmin' ? companyId : undefined,
        },
      })
      .then(({ data }) => {
        setAdminOptions(data.members);
      });
  }, [reqRole, companyId, role]);

  function onSubmitSetAdmin() {
    if (!selectValue?.value) {
      return;
    }

    const user = adminOptions.find((i) => i.id === selectValue.value);

    API()
      .post('/user_settings/company/members', {
        company_id: companyId,
        id: selectValue.value,
        role_name: user.available_roles.includes('Lender Admin') ? 'Lender Admin' : 'Broker Admin',
      })
      .then(onAfterReassign);
  }

  const userSelect =
    adminOptions
      ?.filter((i) => i.available_roles.find((i) => i === 'Broker Admin' || i === 'Lender Admin'))
      .map((opt) => ({ preview: opt.full_name, value: opt.id })) ?? [];

  return (
    <Modal
      wrapClassName="wrapper_prospect"
      title="Set admin"
      centered
      onOk={onCancel}
      open={visible}
      footer={
        <div className="wrapper_remove_prospect_btns">
          <CustomButton key="back" onClick={onCancel} text="Cancel" />
          <CustomButton disabled={!selectValue} key="submit" onClick={onSubmitSetAdmin} primary text="Confirm" />
        </div>
      }
      onCancel={onCancel}
    >
      {adminOptions.length > 0 ? (
        <>
          <p>
            Please fill the name of the person you want to transfer
            {companyName} administrator rights to.
          </p>
          <p>Note: this action can't be undone.</p>
          <DropdownMenu
            className={styles.dropDown}
            onSelect={setSelectValue}
            options={userSelect}
            search
            value={selectValue}
            vectorIcon
          />
        </>
      ) : (
        <p>There is nobody you can transfer the administrator rights to.</p>
      )}
    </Modal>
  );
}

export default AdminReassignModal;
