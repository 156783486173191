import { useEffect, useState } from 'react';
import useQuery from '../../../../constants/useQuery';
import useLarApi from '../../hooks/useLarApi';
import LarApp from '../../../../pages/Onboarding/LarAppNew/LarApp';
import LarAppLoader from 'components/LarAppNew/LarAppLoader/LarAppLoader';

function LarAppLoadWrapper() {
  const [pageLoading, setPageLoading] = useState(true);
  const [lar_id] = useQuery(['lar_id']);
  const { getLar } = useLarApi();
  const [createLarForm, setCreateLarForm] = useState({
    loan_purpose_name: null,
    loan_term_name: null,
    use: null,
    refi_loan_type_name: null,
  });

  useEffect(() => {
    if (lar_id) {
      setPageLoading(true);
      getLar(lar_id)
        .then((res) => {
          setCreateLarForm(res.data);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setPageLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [lar_id]);

  if (pageLoading) {
    return <LarAppLoader />;
  }

  return <LarApp createLarForm={createLarForm} lar_id={lar_id} />;
}

export default LarAppLoadWrapper;
