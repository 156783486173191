import API from '../API';

export default class SettingsAPI {
  async getProfile(id) {
    return API()
      .get('/profile', {
        params: {
          user_id: id,
        },
      })
      .then((res) => res?.data);
  }

  async updateProfileInfo(name, surname, email, workPhone, mobilePhone, pic, id) {
    const formData = new FormData();

    formData.append('email', email);
    formData.append('name', name);
    formData.append('phone', mobilePhone);
    formData.append('surname', surname);
    id && formData.append('user_id', id);
    pic && formData.append('profile_photo', pic);
    workPhone && formData.append('work_phone', workPhone);

    return API()
      .post('/profile', formData)
      .then((res) => res?.data);
  }

  async updatePassword(password, newPassword) {
    return API().post('/profile/password', {
      confirm_password: newPassword,
      curr_password: password,
      new_password: newPassword,
    });
  }

  async updateTwoStepMode(mode) {
    // TODO
    console.log('"Two-step" verification method change request: feature not implemented. Parameters: ', {
      mode,
    });

    return new Promise((resolve) => resolve());
  }

  async getSecuritySettings() {
    return API()
      .get('/profile/password')
      .then((res) => res.data);
  }

  async revokeAllOtherSessions() {
    return API().post('/profile/revoke-other-sessions');
  }

  async updateSecurityQuestion(password, question, answer) {
    // TODO
    console.log('"Security" question change request: feature not implemented. Parameters: ', {
      password,
      question,
      answer,
    });

    return new Promise((resolve) => resolve());
  }

  async getNotificationSettings() {
    // TODO
    console.log('Notification settings get request: feature not implemented');
    return new Promise((resolve) =>
      resolve({
        data: {
          enabled: true,
          interval: '30 minutes',
        },
      }),
    );
  }

  async updateNotificationSettings(enabled, interval) {
    // TODO
    console.log('Notification settings change request: feature not implemented. Parameters: ', {
      enabled,
      interval,
    });

    return new Promise((resolve) => resolve());
  }

  async getNMLSSettings(personal = true) {
    return API()
      .get(personal ? '/profile/nmls' : '/user_settings/company/broker/nmls')
      .then((res) => res.data);
  }

  async updateNMLSSettings(
    nmls,
    homeStates,
    realEstateStates,
    selectedHomeStates,
    selectedRealEstateStates,
    personal = true,
  ) {
    return API().post(personal ? '/profile/nmls' : '/user_settings/company/broker/nmls', {
      home_states: homeStates,
      nmls,
      real_estate_states: realEstateStates,
      selected_home_states: selectedHomeStates,
      selected_real_estate_states: selectedRealEstateStates,
    });
  }

  async getCompanySettings() {
    return API()
      .get('/user_settings/company/profile')
      .then((res) => res.data);
  }

  async updateCompanySettings(city, name, state, type, website, zip, logo) {
    const formData = new FormData();
    formData.append('city', city);
    formData.append('name', name);
    formData.append('state', state);
    formData.append('type', type);
    formData.append('website', website);
    formData.append('zip', zip);
    logo && formData.append('logo', logo);

    return API()
      .post('/user_settings/company/profile', formData)
      .then((res) => res.data);
  }

  async getCompanyMembers(page = 1, pageSize = 10, status) {
    return API()
      .get('/user_settings/company/members', {
        params: {
          page,
          per_page: pageSize,
          status,
        },
      })
      .then((res) => res.data);
  }

  async toggleUserDisabled(id) {
    return API()
      .post('/user_settings/company/disable', {
        user_id: id,
      })
      .then((res) => res.data);
  }

  async deleteUser(id) {
    return API()
      .delete('/user_settings/company/members', { params: { id } })
      .then((res) => res.data);
  }

  async searchCompanyLARs(query) {
    return API()
      .get(`/user_settings/company/lars/search?search=${query}`)
      .then((res) => res.data);
  }

  async getCompanyTeams(page = 1, pageSize = 10) {
    // TODO
    console.log('Company teams get request: feature not implemented. Parameters: ', {
      page,
      per_page: pageSize,
    });

    return new Promise((resolve) => resolve({ teams: [], total: 0 }));
  }

  async createTeam(name, leader) {
    // TODO
    console.log('Team creation request: feature not implemented. Parameters:', {
      name,
      leader,
    });

    return new Promise((resolve) => resolve());
  }

  async bulkReassignTeams(currentTeam, newTeam) {
    // TODO
    console.log('Bulk team reassignment request: feature not implemented. Parameters:', {
      current_team: currentTeam,
      new_team: newTeam,
    });

    return new Promise((resolve) => resolve());
  }

  async inviteCompanyMember(to, message, sendCopyToSelf) {
    const inviteList = to.map((u) => ({
      email: u.email,
      name: u.name,
      role_name: u.role_name,
      surname: u.surname,
    }));

    return API().post('/user_settings/company/members', {
      invite_list: inviteList,
      message,
      send_copy_to_self: sendCopyToSelf,
    });
  }

  async getCompanyLogoURI() {
    return API()
      .get('/profile/company_logo')
      .then((res) => res.data?.logo);
  }

  async checkEmailAvailability(email) {
    return API()
      .post('/user_settings/company/members/check_email', { email })
      .then(() => true)
      .catch(() => false);
  }

  async getLarScheduleStatus(id) {
    return API()
      .get('/LAR/auction_settings/scheduling', { params: { lar_id: +id } })
      .then((res) => res);
  }

  async postLarScheduleStatus(id) {
    return API()
      .post('/LAR/auction_settings/scheduling', { lar_id: +id })
      .then((res) => res);
  }
}
