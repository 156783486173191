import { useEffect, useMemo, useRef, useState } from 'react';
import { List, Spin } from 'antd';

import getBidValuesData from 'constants/getBidValuesData';

import BidTableItem from './BidTableItem/BidTableItem';
import BidTableStartItem from './BidTableStartItem/BidTableStartItem';
import './bid_table.css';

function BidTable({
  bids,
  goToEditBid,
  handleChangeBidsOrder,
  loading,
  loan_purpose,
  loan_term,
  openChooseBidDrawer,
  orderBy,
}) {
  const [scroll, setScroll] = useState(false);
  const tableRef = useRef();

  useEffect(() => {
    const list = tableRef.current.querySelector('.bid-table-list');
    const overflown = list.scrollHeight > list.clientHeight || list.scrollWidth > list.clientWidth;
    if (overflown) {
      setScroll(true);
    }
  }, []);

  const bidValuesData = useMemo(() => getBidValuesData('showAll'), [loan_purpose, loan_term]);

  return (
    <Spin spinning={!loading} tip="Loading...">
      <div className={`bid-table ${scroll ? 'bid-table_scroll' : ''}`} ref={tableRef}>
        <div className="bid-table__inner">
          <BidTableStartItem
            bidValuesData={bidValuesData}
            handleChangeBidsOrder={handleChangeBidsOrder}
            orderBy={orderBy?.by}
          />
          <List
            className="bid-table-list bid-table-list_responsible bid-table-list_responsible--1200"
            dataSource={bids}
            grid={{ gutter: 0, column: bids?.length }}
            renderItem={(item) =>
              item?.loan_amount &&
              item.loan_amount > 0 && (
                <List.Item>
                  <BidTableItem
                    bidValuesData={bidValuesData}
                    goToEditBid={goToEditBid}
                    item={item}
                    openChooseBidDrawer={openChooseBidDrawer}
                  />
                </List.Item>
              )
            }
          />
        </div>
      </div>
    </Spin>
  );
}

export default BidTable;
