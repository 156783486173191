import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Popover } from 'antd';
import { BrokerLandingAPI } from 'api';
import CustomAvatar from 'components/UI/Avatar';
import forceLogOff from 'constants/forceLogOff';
import styles from './styles/AvatarPopover.module.css';

function AvatarPopover({ registration = false, children }) {
  const { avatar, name, surname, email, role } = useSelector((store) => store.user.userData);
  const history = useHistory();

  const API = useMemo(() => new BrokerLandingAPI(), []);

  function handleButtonClick(type) {
    switch (type) {
      case 'manage-account':
        history.push('/onboarding/settings');
        break;

      case 'manage-company':
        history.push('/onboarding/company-settings');
        break;

      case 'manage-users':
        history.push('/onboarding/company-settings/users');
        break;

      case 'log-out':
        if (/borrower/i.test(role)) {
          return API.getCompanyDomains().then(({ login }) => {
            forceLogOff(login);
          });
        }

        forceLogOff();
        break;

      default:
        break;
    }
  }

  return (
    <Popover
      content={
        <>
          <span>Account</span>
          <div className={styles.miniProfile}>
            <CustomAvatar src={avatar} name={name} surname={surname} size="large" />
            <div>
              {name} {surname}
            </div>
            <div>{email}</div>
          </div>
          {registration || (
            <div>
              <button onClick={() => handleButtonClick('manage-account')}>Manage Account</button>
              {/ admin/i.test(role) && (
                <button onClick={() => handleButtonClick('manage-company')}>Manage Company</button>
              )}
              {/superadmin/i.test(role) || /BDR/i.test(role) || /Borrower/i.test(role) || /PLA/i.test(role) || (
                <button onClick={() => handleButtonClick('manage-users')}>Manage Users</button>
              )}
            </div>
          )}
          <div>
            <button onClick={() => handleButtonClick('log-out')}>Log Out</button>
          </div>
        </>
      }
      overlayClassName={styles.root}
      trigger="click"
    >
      {children}
    </Popover>
  );
}

export default AvatarPopover;
