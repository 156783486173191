import { Button } from 'antd';

import Modal from '../../Modals/Modal';

import styles from './styles.module.css';
import { useEffect, useState } from 'react';
import API from 'api';

export default function LaunchDealModal({ isOpen, onCancel, onOk, id }) {
  const [data, setData] = useState(undefined);

  console.log(data);
  const array = [
    { title: 'Starport Quoting Process', items: ['Lenders Invited', 'Duration', 'Extensions', 'Event Begin'] },
    { title: 'Scenario', items: ['Up to 20', 'Up to 7 business days', '3 days', 'Immediately*'] },
    {
      title: 'Live Arena Auction',
      items: [
        'Up to 20',
        data ? `${data.auction_begins} - ${data.auction_ends}` : '###',
        '3 days during quote selection',
        data ? data.auction_begins : '###',
      ],
    },
  ];
  async function getAuctionInfo() {
    try {
      const res = await API().get('/LAR/invite/schedule_auction', { params: { lar_id: id } });
      setData(res.data.auction_time);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    isOpen && getAuctionInfo();
  }, [isOpen]);

  const [toggleButtons, setToggleButtons] = useState('scenario');

  const handleChangeButton = (name) => () => setToggleButtons(name);

  function handleContinue() {
    onCancel();
    onOk(toggleButtons === 'scenario');
  }

  return (
    <Modal
      okText={toggleButtons === 'scenario' ? 'Continue' : 'Schedule Auction'}
      width={700}
      title={<p style={{ fontSize: 18 }}>Launch Your Loan Request</p>}
      bodyStyle={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 24, paddingTop: 0 }}
      onOk={handleContinue}
      open={isOpen}
      onCancel={onCancel}
    >
      <div className={styles.divider} />
      <div className={styles.containerButtons}>
        <Button
          onClick={handleChangeButton('scenario')}
          style={{ flex: 1, height: 40 }}
          type={toggleButtons === 'scenario' ? 'primary' : 'ghost'}
        >
          Scenario
        </Button>
        <p className={styles.textOR}>OR</p>
        <Button
          onClick={handleChangeButton('auction')}
          style={{ flex: 1, height: 40 }}
          type={toggleButtons === 'auction' ? 'primary' : 'ghost'}
        >
          Arena Auction
        </Button>
      </div>
      <div className={styles.divider} />
      <div className={styles.containerColumns}>
        {array.map((el, elIndex) => (
          <div key={elIndex} className={styles.column}>
            <p className={styles.columnTitle} style={{ fontWeight: 'bolder' }}>
              {el.title}
            </p>
            {el.items.map((item, index) => (
              <p className={styles.columnTitle} key={elIndex.toString() + index}>
                {item}
              </p>
            ))}
          </div>
        ))}
      </div>
      <div className={styles.divider} />
      <p className={styles.footerText}>
        * Scenarios launched after 8pm Eastern time, will be delayed until 9am next business day
      </p>
    </Modal>
  );
}
