import 'components/LoanProfile/BidTable/bid_table.css';
import { SwiperSlide, Swiper } from 'swiper/react/swiper-react';
import { useContext, useMemo } from 'react';
import BidTableItem from '../BidTableItem';
import getBidValuesData from 'constants/getBidValuesData';
import LARDBContext from 'pages/Onboarding/LARDB/LARDBContext';
import { breakpoints } from 'constants/slider';

function UserBidTable({
  quotes = [],
  handleChooseQuote = () => null,
  isMaxQuotes,
  isQuotesSent,
  openSelectWinQuoteModal,
  selectedQuotes = [],
  resendQuotes,
  winnerSelected,
  setSwiper,
}) {
  const { loan_purpose, loan_style, lar_status } = useContext(LARDBContext);

  const isWinnerSelection = lar_status === 'Quote Selection';

  const bidValuesData = useMemo(
    () => getBidValuesData('showAll', loan_style, quotes[0].rate_type) || [],
    [loan_purpose, loan_style],
  );
  return (
    <div className="bid-table bid-table_user">
      <div className="bid-table__inner">
        <div className="bid-table-list__list-item bid-table-list__list-item_starter">
          <div className="bid-table-list__list-item-header bid-table-list__list-item-header_starter-item" />
          <div className="bid-table-list__list-item-body">
            <div className="bid-table-list__info-block">
              {bidValuesData.map((el, i) => (
                <div
                  className="bid-table-list__info-block-item bid-table-list__info-block-item_label"
                  key={el.name + i}
                >
                  {el.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <Swiper
          breakpoints={breakpoints}
          onBreakpoint={(swiper) => setSwiper(swiper)}
          onSwiper={(swiper) => setSwiper(swiper)}
          slidesPerView="auto"
          allowTouchMove={false}
          style={{ marginLeft: 0, width: '100%' }}
        >
          {quotes.map((el, id) => {
            const selected = selectedQuotes.find((quoteId) => quoteId === el.quote_id);

            return (
              <SwiperSlide key={`${id}_`}>
                <BidTableItem
                  bidValuesData={bidValuesData}
                  handleChooseQuote={handleChooseQuote}
                  isMaxQuotes={isMaxQuotes}
                  isQuotesSent={isQuotesSent}
                  isWinnerSelection={isWinnerSelection}
                  item={el}
                  openSelectWinQuoteModal={openSelectWinQuoteModal}
                  resendQuotes={resendQuotes}
                  selected={selected}
                  winnerSelected={winnerSelected}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default UserBidTable;
