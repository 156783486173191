import React from 'react';
import BrokerProfile from '../../pages/Registration/Broker/BrokerProfile';
import CompanyProfile from '../../pages/Registration/Broker/CompanyProfile';
import BrokerPartnerPDF from '../../pages/Registration/Broker/BrokerPartnerPDF';
import Confirm from '../../pages/Registration/Confirm';
import LenderReview from '../../pages/Registration/Lender/Review';

export const mainNavigationRegisterBroker = [
  {
    exact: true,
    path: '/registration/broker/profile/:id?',
    main: () => <BrokerProfile />,
  },
  {
    exact: true,
    path: '/registration/broker/company/:id?',
    main: (props) => <CompanyProfile {...props} />,
  },
  {
    exact: true,
    path: '/registration/broker/partner/:id?',
    main: () => <BrokerPartnerPDF />,
  },
  {
    exact: true,
    path: '/registration/broker/confirm',
    main: () => <Confirm type="broker" />,
  },
  {
    exact: true,
    path: '/registration/broker/review',
    main: () => <LenderReview hideEdit />,
  },
];
