import 'components/UI/Collapse/custom_collapse.css';
import { Collapse as AntDCollapse } from 'antd';

export default function Collapse({
  children,
  activeKey,
  header,
  formKey,
  expandIconPosition = 'start',
  classNames = [],
  handleActiveFormItem,
}) {
  /* TODO: This may be removed after updating antd to version 4.21 or higher */
  const tmp =
    {
      start: 'left',
      end: 'right',
    }[expandIconPosition] || expandIconPosition;

  return (
    <AntDCollapse
      className={`custom-collapse ${classNames.filter(Boolean).join(' ')}`}
      defaultActiveKey={activeKey}
      activeKey={activeKey}
      expandIconPosition={tmp}
      onChange={() => handleActiveFormItem(formKey)}
    >
      <AntDCollapse.Panel style={{ padding: 0 }} header={header} key={formKey}>
        {children}
      </AntDCollapse.Panel>
    </AntDCollapse>
  );
}
