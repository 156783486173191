import { CircleXFilledIcon, CircleTickFilledIcon, FileDownloadIcon, FileUploadIcon } from 'assets/icons';
import { memo } from 'react';
import classes from './styles/List.module.css';
import ChatButton from 'components/UI/ChatButton/ChatButton';
import { BROKERS, PLA } from 'constants/roles';

export default memo(({ item, role, handleActionButtonClick }) => (
  <div className={classes.actions}>
    {/^superadmin|borrower/i.test(role) ? (
      <FileUploadIcon onClick={() => handleActionButtonClick('upload', item)} />
    ) : ['pending_lender_review', 'lender_approve'].includes(item.item_stage) ? (
      <>
        {role !== PLA && !BROKERS.includes(role) && (
          <>
            {item.item_stage === 'pending_lender_review' && (
              <CircleTickFilledIcon onClick={() => handleActionButtonClick('accept', item)} />
            )}
            <CircleXFilledIcon onClick={() => handleActionButtonClick('reject', item)} />
          </>
        )}
        <FileDownloadIcon onClick={() => handleActionButtonClick('download', item)} />
      </>
    ) : null}
    <ChatButton
      text="Comment"
      onClick={() => handleActionButtonClick('comment', item)}
      hasIndicator={item.notes_status !== null}
      hasNoBackground={item?.notes_status === 'grey'}
      badgeColor="yellow"
    />
  </div>
));
