import { Table, Popconfirm, Select, Input, Button, Typography, Space } from 'antd';
import { CloseIcon } from 'assets/icons';
import { CheckOutlined } from '@ant-design/icons';
import { replacedTextData } from 'constants/common';

function InviteUsersTable({
  email,
  error,
  roleList,
  userList,
  onDeleteUser,
  onUpdateUserData,
  onAddToTable,
  onSaveUserInTable,
}) {
  const emailDomain = `@${email?.split('@').at(-1)}`;
  const columns = [
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      width: 350,
      render: (_, record) =>
        record.email && record.full_name ? (
          record.email
        ) : (
          <>
            <Input
              name="email"
              addonAfter={emailDomain}
              value={record.email}
              status={error.email && !record.email ? 'error' : null}
              onChange={({ target }) => onUpdateUserData(record.index, { email: target.value })}
            />
            {error.email && <Typography.Text type="danger">{error.email}</Typography.Text>}
          </>
        ),
    },
    {
      title: 'First name',
      key: 'name',
      dataIndex: 'name',
      width: 160,
      render: (_, record) =>
        record.name && record.full_name ? (
          record.name
        ) : (
          <>
            <Input
              name="name"
              status={error.name && !record.name ? 'error' : null}
              value={record.name}
              onChange={({ target }) => onUpdateUserData(record.index, { name: target.value })}
            />
            {error.name && <Typography.Text type="danger">{error.name}</Typography.Text>}
          </>
        ),
    },
    {
      title: 'Last name',
      key: 'surname',
      dataIndex: 'surname',
      width: 160,
      render: (_, record) =>
        record.surname && record.full_name ? (
          record.surname
        ) : (
          <>
            <Input
              name="email"
              status={error.surname && !record.surname ? 'error' : null}
              value={record.surname}
              onChange={({ target }) => onUpdateUserData(record.index, { surname: target.value })}
            />
            {error.surname && <Typography.Text type="danger">{error.surname}</Typography.Text>}
          </>
        ),
    },
    {
      title: 'Role',
      key: 'role_name',
      dataIndex: 'role_name',
      width: 160,
      render: (_, record) => (
        <div id="invite-users-table__role-select">
          <Select
            style={{ width: 230 }}
            defaultValue={_}
            onChange={(value) => onUpdateUserData(record.index, { role_name: value })}
          >
            {roleList.map((role, id) => (
              <Select.Option value={role} key={id}>
                {replacedTextData[role] || role}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },
    {
      width: 50,
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      render: (i, record) => (
        <Space>
          {!record?.full_name ? (
            <Button
              type="link"
              icon={<CheckOutlined colour="currentColor" width="1.125em" />}
              onClick={() => onSaveUserInTable(record.index)}
            >
              Save
            </Button>
          ) : null}
          {userList.dataSource.length >= 1 ? (
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete this invitation?"
              onConfirm={() => onDeleteUser(record.index)}
            >
              <Button type="text" size="small" icon={<CloseIcon />} />
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  return (
    <div id="invite-users-table">
      <div className="invite-users-table">
        <Table
          bordered
          columns={columns}
          dataSource={userList.dataSource}
          pagination={false}
          rowClassName={() => 'editable-row'}
        />
      </div>
      <div className="invite-users-table__footer">
        <button className="invite-users-form__add-user-btn" onClick={onAddToTable}>
          <span style={{ display: 'flex' }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 5V19"
                stroke="var(--main_blue_color)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 12H19"
                stroke="var(--main_blue_color)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span style={{ marginLeft: '10px' }}>Add</span>
        </button>
      </div>
    </div>
  );
}

export default InviteUsersTable;
