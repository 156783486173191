import { Button, Col, Form, Input, message, Radio, Row, Table } from 'antd';
import { useState } from 'react';

import API from '../../../../../api';

import ScenarioTableFilters from './ScenarioTableFilters';

import styles from './ScenariosTable.module.css';

const columns = [
  {
    title: 'Mandate Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Recent Update',
    dataIndex: 'recent_update',
    key: 'recent_update',
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'score',
  },
  {
    title: 'Lender',
    dataIndex: 'lender',
    key: 'lender',
  },
  {
    title: 'LAE',
    dataIndex: 'LAE',
    key: 'LAE',
  },
  {
    title: 'Recent Login',
    dataIndex: 'recent_login',
    key: 'recent_login',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Active LARs',
    dataIndex: 'active_LARs',
    key: 'active_LARs',
  },
];

export default function ScenariosTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('id');
  const [filters, setFilters] = useState({});

  const handleModeChange = (e) => {
    setFilters({});
    setMode(e.target.value);
    setData([]);
  };

  const handleFilterChange = (e, name) => {
    if (name) {
      setFilters({ ...filters, [name]: e });
    } else {
      setFilters({ ...filters, [e.target.name]: e.target.value || Number(e.target.checked) });
    }
  };

  const handleAnalyze = () => {
    setLoading(true);

    const params = { ...filters };
    if (filters.dial_source !== undefined) {
      params.dial_source = filters.dial_source ? 1 : 0;
    }
    params.loan_type = filters.loan_type ? filters.loan_type.join(',') : undefined;
    params.payment_type = filters.payment_type ? filters.payment_type.join(',') : undefined;
    params.recourse = filters.recourse ? filters.recourse.join(',') : undefined;

    API()
      .get('/user_settings/mandates/superadmin/smartmatch_analyzer', {
        params,
      })
      .then((res) => setData(res.data.items))
      .catch((err) => {
        message.error(err?.response?.data?.error || 'Error');
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={styles.root}>
      <Row className={styles.header} gutter={[16, 16]} justify="space-between" align="middle">
        <Col>
          <Radio.Group onChange={handleModeChange} value={mode} style={{ marginBottom: 8 }}>
            <Radio.Button value="id">Deal ID</Radio.Button>
            <Radio.Button value="manual">Manually</Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <Row align="middle" gutter={16}>
            <Col>
              {mode === 'id' && (
                <Form.Item label="Enter Deal ID" className={styles.formItem}>
                  <Input name="lar_id" size="large" onChange={handleFilterChange} />
                </Form.Item>
              )}
            </Col>
            <Col>
              <Button size="large" type="dark" loading={loading} onClick={handleAnalyze}>
                Run Analyzer
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={styles.tableWrapper}>
        {mode === 'manual' && <ScenarioTableFilters data={filters} onChange={handleFilterChange} />}
        <Table scroll={{ x: 1000 }} loading={loading} columns={columns} dataSource={data} />
      </div>
    </div>
  );
}
