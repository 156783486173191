import { Dropdown, Menu } from 'antd';
import UniversalTable from '../../UniversalTable';
import { HorizontalDotsIcon } from '../../../assets/icons/icons';

const data = [
  {
    category: 'Loan Application',
  },
  {
    category: 'Loan Application',
  },
  {
    category: 'Loan Application',
  },
  {
    category: 'Loan Application',
  },
];

function LenderDocsTable() {
  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: () => (
        <div style={{ justifyContent: 'end', display: 'flex' }}>
          <Dropdown overlay={<Menu className="custom-dropdown-menu custom-dropdown-menu_default" items={[]} />}>
            <button
              aria-label="openSettings"
              style={{
                border: 'none',
                cursor: 'pointer',
                display: 'flex',
                padding: 0,
              }}
            >
              <HorizontalDotsIcon style={{ color: '#8c8c8c' }} />
            </button>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <UniversalTable columns={columns} withPagination={false} dataSource={data} loading={false} withHeader={false} />
  );
}
export default LenderDocsTable;
