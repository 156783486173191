import {
  BankIcon,
  BriefcaseIcon,
  FileInvoiceIcon,
  HomeIcon,
  SitemapIcon,
  SqrtIcon,
  TrendingIcon,
  UserIcon,
} from 'assets/icons';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import siteName from 'constants/siteName';
import Teams from 'pages/Onboarding/CommonPages/Teams';
import CompanyInfo from '../CompanyInfo';
import CompanyLARs from '../CompanyLARs';
import NMLS from '../NMLS';
import Profile from '../Profile';
import Users from '../Users';

export function useCompanyCategories() {
  const role = useSelector((store) => store.user.userData?.role);

  return useMemo(
    () => [
      {
        Component: Profile,
        hidden: true,
        id: 'profile',
        title: 'Profile',
      },
      {
        Component: CompanyInfo,
        description: 'Manage your company profile',
        Icon: BankIcon,
        id: 'info',
        title: 'Company Info',
      },
      {
        Component: (params) => <NMLS {...params} company />,
        description: 'Manage your company NMLS information',
        Icon: BriefcaseIcon,
        id: 'nmls',
        title: 'Company NMLS',
      },
      {
        Component: CompanyLARs,
        description: 'Review your company loan history',
        Icon: HomeIcon,
        id: 'loans',
        title: 'Loans',
      },
      {
        description: 'Manage your loan criteria and guidelines',
        hidden: !/lender/i.test(role),
        Icon: SqrtIcon,
        id: 'mandates',
        redirect: '/onboarding/mandates',
        title: 'Mandates',
      },
      {
        Component: Teams,
        description: 'Manage company loan teams',
        Icon: SitemapIcon,
        id: 'teams',
        title: 'Teams',
      },
      {
        Component: Users,
        description: `Manage ${siteName} membership of your employees`,
        Icon: UserIcon,
        id: 'users',
        title: 'Users',
      },
      {
        description: 'Manage your automated document requests',
        hidden: !/lender/i.test(role),
        Icon: FileInvoiceIcon,
        id: 'masterlists',
        redirect: '/onboarding/masterlists',
        title: 'Masterlists',
      },
      {
        description: 'Manage your company landing page',
        hidden: !/broker/i.test(role),
        Icon: TrendingIcon,
        id: 'company-landing-page',
        redirect: '/onboarding/preferences/landing_pages',
        title: 'Company landing page',
      },
    ],
    [role],
  );
}
