import { useContext, useEffect } from 'react';
import { Checkbox, Divider, Form, InputNumber, Radio, Space } from 'antd';
import moment from 'moment';
import regExp from 'constants/regExp';
import { onChangeValidator, useFieldsChangeNew } from '../../helpers/larHelpers';
import formSettings from '../../helpers/formSettings';
import LarAppContext from '../../context/LarAppContext';
import BorrowerIndividualForm from './BorrowerIndividualForm/BorrowerIndividualForm';
import BorrowerEntityForm from './BorrowerEntityForm/BorrowerEntityForm';

function BorrowerForm(props) {
  const {
    setSuccess,
    setSuccessLA,
    setProgress,
    setLAProgress,
    form,
    dataSource,
    setDataSource,
    isRefinance,
    isPurchaseLong,
    isPurchaseShort,
  } = props;
  const {
    borrower_entity_type_name,
    main_borrower_first_name,
    main_borrower_middle_name,
    main_borrower_last_name,
    main_borrower_suffix,
    main_borrower_email,
    main_borrower_phone,
    main_borrower_over_21,
    main_borrower_citizenship,
    main_borrower_marital_status,
    main_borrower_employment_type,
    main_borrower_self_employed_longer_than_24_months,
    entity_name,
    entity_type,
    entity_formation_date,
    business_state,
    main_borrower_first_time_buyer,
    main_borrower_rental_properties,
    main_borrower_flips,
    use,
  } = useContext(LarAppContext);
  const formName = 'borrower';
  const borrowerType = Form.useWatch('borrower_entity_type_name', form);
  const isIndividual = borrowerType === 'Individual';
  const isEntity = borrowerType === 'Legal';

  const getSuccessStatus = (fieldsArr) => {
    const borrowerEmail = fieldsArr.find((el) => el?.name?.includes('main_borrower_email'));
    onChangeValidator(form, fieldsArr, formName, {
      isRefinance,
      isPurchaseLong,
      isPurchaseShort,
    });

    if (borrowerEmail?.value && !regExp.email.test(borrowerEmail.value)) {
      form.setFields([
        {
          name: 'main_borrower_email',
          errors: ['Incorrect email format'],
        },
      ]);
      return false;
    }

    const {
      borrower_entity_type_name: typeName,
      entity_type: entityType,
      main_borrower_citizenship: borrowerCitizenship,
      main_borrower_employment_type: borrowerEmploymentType,
      main_borrower_rental_properties: mainBorrowerRentalProperties,
      main_borrower_flips: mainBorrowerFlips,
    } = form.getFieldsValue();

    if (isPurchaseLong || isRefinance) {
      const fields = [mainBorrowerRentalProperties];
      const isOwned = !fields.includes(undefined) && !fields.includes(null);

      if (typeName === 'Legal') {
        return !!entityType && !!borrowerCitizenship && !!borrowerEmploymentType && isOwned;
      }
      if (typeName === 'Individual') {
        return !!borrowerCitizenship && !!borrowerEmploymentType && isOwned;
      }
    }

    const fields = [mainBorrowerRentalProperties, mainBorrowerFlips];
    const isOwnedAndFlips = !fields.includes(undefined) && !fields.includes(null);

    if (isPurchaseShort && typeName) {
      return !!borrowerCitizenship && !!borrowerEmploymentType && isOwnedAndFlips;
    }
    if (isPurchaseShort) {
      return isOwnedAndFlips;
    }
  };

  const getSuccessStatusLA = (fieldsArr) => {
    const borrowerEmail = fieldsArr.find((el) => el?.name?.includes('main_borrower_email'));
    onChangeValidator(form, fieldsArr, formName, {
      isRefinance,
      isPurchaseLong,
      isPurchaseShort,
    });

    if (borrowerEmail?.value && !regExp.email.test(borrowerEmail.value)) {
      form.setFields([
        {
          name: 'main_borrower_email',
          errors: ['Incorrect email format'],
        },
      ]);
      return false;
    }

    const {
      borrower_entity_type_name: typeName,
      entity_type: entityType,
      main_borrower_citizenship: borrowerCitizenship,
      main_borrower_employment_type: borrowerEmploymentType,
      main_borrower_first_name: firstName,
      main_borrower_last_name: lastName,
      main_borrower_phone: mobile,
      main_borrower_rental_properties: mainBorrowerRentalProperties,
      main_borrower_flips: mainBorrowerFlips,
    } = form.getFieldsValue();

    const isSuccess = !!borrowerCitizenship && !!borrowerEmploymentType && !!firstName && !!lastName && !!mobile;

    if (isPurchaseLong || isRefinance) {
      const fields = [mainBorrowerRentalProperties];
      const isOwned = !fields.includes(undefined) && !fields.includes(null);

      if (typeName === 'Legal') {
        return !!entityType && isSuccess && isOwned;
      }
      if (typeName === 'Individual') {
        return isSuccess && isOwned;
      }
    }

    if (isPurchaseShort) {
      const fields = [mainBorrowerRentalProperties, mainBorrowerFlips];
      const isOwnedAndFlips = !fields.includes(undefined) && !fields.includes(null);

      if (typeName === 'Legal') {
        return isSuccess && isOwnedAndFlips;
      }
      if (typeName === 'Individual') {
        return isSuccess && isOwnedAndFlips;
      }
    }
  };

  const setSuccessStatus = (status = false) => {
    setSuccess(status);
    setProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const setSuccessStatusLA = (status = false) => {
    setSuccessLA(status);
    setLAProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const onSuccess = () => {
    form.submit();
  };

  const onReject = () => {};

  const onFieldsChange = useFieldsChangeNew(
    getSuccessStatus,
    setSuccessStatus,
    onSuccess,
    onReject,
    getSuccessStatusLA,
    setSuccessStatusLA,
  );

  // TODO: TEST SAVE DATA
  useEffect(() => {
    form.setFieldsValue({
      borrower_entity_type_name,
      main_borrower_first_name,
      main_borrower_middle_name,
      main_borrower_last_name,
      main_borrower_suffix,
      main_borrower_email,
      main_borrower_phone,
      main_borrower_over_21,
      main_borrower_citizenship,
      main_borrower_marital_status,
      main_borrower_employment_type,
      main_borrower_self_employed_longer_than_24_months,
      entity_name,
      entity_type,
      entity_formation_date: entity_formation_date ? moment(entity_formation_date) : moment(new Date(), 'YYYY-MM'),
      business_state,
      main_borrower_first_time_buyer,
      main_borrower_rental_properties: main_borrower_rental_properties || 0,
      main_borrower_flips: main_borrower_flips || 0,
    });
    const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
      name: el[0],
      value: el[1],
    }));
    onFieldsChange([1], valuesArr);
    // eslint-disable-next-line
  }, []);

  const setFieldAsync = async (name, value) => {
    await form.setFields([
      {
        name,
        value,
      },
    ]);
  };

  return (
    <Form {...formSettings} name="borrower" form={form} onFieldsChange={onFieldsChange}>
      <Form.Item
        name="borrower_entity_type_name"
        label="Borrower"
        className={isPurchaseShort ? 'auction-required-field' : 'scenario-required-field auction-required-field'}
      >
        <Radio.Group
          onChange={(e) => {
            setFieldAsync('borrower_entity_type_name', e.target.value).then(() => {
              const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
                name: el[0],
                value: el[1],
              }));
              onFieldsChange([1], valuesArr);
            });
          }}
        >
          <Space direction="vertical" size="large">
            <Radio value="Legal">Entity</Radio>
            <Radio value="Individual">Individual</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {isIndividual ? (
        <BorrowerIndividualForm form={form} />
      ) : isEntity ? (
        <BorrowerEntityForm
          onFieldsChange={onFieldsChange}
          main_borrower_first_name={main_borrower_first_name}
          main_borrower_last_name={main_borrower_last_name}
          form={form}
          dataSource={dataSource}
          setDataSource={setDataSource}
          isPurchaseShort={isPurchaseShort}
        />
      ) : null}
      <Divider />
      <div>
        <h3>
          <b>Experience</b>
        </h3>
        <Form.Item
          label="First time buyer"
          name="main_borrower_first_time_buyer"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox />
        </Form.Item>
        {isPurchaseLong && use === 'Consumer' ? null : (
          <>
            <Form.Item
              tooltip="Include all rental properties currently owned"
              label="Rental Properties Owned"
              name="main_borrower_rental_properties"
              className="scenario-required-field auction-required-field"
            >
              <InputNumber min={0} max={1000} style={{ width: 90 }} />
            </Form.Item>
            <Form.Item
              tooltip="Include all properties currently owned or sold within the past 36 months"
              label="Property Flips Completed"
              name="main_borrower_flips"
              className={isPurchaseShort && 'scenario-required-field auction-required-field'}
            >
              <InputNumber min={0} max={200} style={{ width: 90 }} />
            </Form.Item>
          </>
        )}
      </div>
    </Form>
  );
}
export default BorrowerForm;
