import './styles/ems_list.css';
import { EMSAPI } from 'api';
import { setBreadCrumbHistory } from 'store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import EMSSearch from 'components/OnBoarding/EMS/EMSSearch';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import UniversalTable from 'components/UniversalTable';

const columns = [
  {
    dataIndex: 'id',
    title: 'ID',
    width: 40,
  },
  {
    dataIndex: 'name',
    title: 'Trigger (Name)',
    width: 150,
  },
  {
    dataIndex: 'dev_name',
    title: 'Dev name',
    width: 150,
  },
  {
    dataIndex: 'type_name',
    title: 'Type',
    width: 120,
  },
  {
    dataIndex: 'last_update',
    title: 'Last update',
    width: 240,
  },
  {
    dataIndex: 'description',
    title: 'Description',
    width: 450,
  },
];

export default function EMSList() {
  const [listData, setListData] = useState({ total_count: 0, triggers: [], ready: false });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const breadcrumbHistory = useSelector((store) => store.user.breadCrumbHistory);
  const dispatch = useDispatch();
  const history = useHistory();

  const API = useMemo(() => new EMSAPI(), []);

  useEffect(() => {
    setListData((prev) => ({
      ...prev,
      ready: false,
    }));

    API.getTriggersList(pagination.current, pagination.pageSize).then((data) => {
      setListData({
        ...data,
        ready: true,
      });
    });
  }, [API, pagination]);

  function openEMS(id) {
    const newURI = `/onboarding/ems/${id}`;

    dispatch(setBreadCrumbHistory([...breadcrumbHistory, { preview: 'Trigger Detail', to: newURI }]));

    history.push(newURI);
  }

  return (
    <div id="ems-wrapper">
      <SectionTitle title="Event Management System" />
      <EMSSearch API={API} openEMS={openEMS} />
      <UniversalTable
        columns={columns}
        dataSource={listData.triggers}
        loading={!listData.ready}
        onRow={({ id }) => ({ onClick: () => openEMS(id) })}
        pagination={{ ...pagination, total: listData.total_count }}
        rowKey="id"
        scroll={{ x: 1150 }}
        setPagination={setPagination}
        withHeader={false}
      />
    </div>
  );
}
