import { Modal, Button } from 'antd';
import { useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { numberToCurrency } from 'constants/numberToComma';
import { NoteBigIcon } from 'assets/icons/icons';
import getBidValuesData from 'constants/getBidValuesData';
import globalStyles from 'constants/globalStyles';
// import PDFAPI from 'api/PDFAPI';
// import useQuery from 'constants/useQuery';
import { BORROWER, BROKERS } from 'constants/roles';

import SectionTitle from 'components/OnBoarding/SectionTitle';

import styles from './LARCard.module.css';

const hiddenFields = ['ysp_payment_available'];

export default function LARCard({ data = {}, noFooter = false, isConfirm = false }) {
  const [termsVisible, toggleTermsVisible] = useReducer((state) => !state, false);
  // const [id] = useQuery(['lar_id']);
  // const API = useMemo(() => new PDFAPI(), []);
  const { role } = useSelector((state) => state.user.userData);

  const rows = useMemo(() => {
    const bidsRows = getBidValuesData(data.loan_purpose, data.loan_term_name, data.rate_type);

    if ([BORROWER, ...BROKERS].includes(role)) {
      return bidsRows.filter((bidRow) => !hiddenFields.includes(bidRow.value));
    }

    if (/borrower/i.test(role)) {
      return bidsRows.filter((bidRow) => bidRow.value !== 'ysp_payment_available');
    }

    return bidsRows;
  }, [data.loan_purpose, data.loan_term_name, role, data.rate_type]);

  // const showPDF = (e) => {
  //   e.stopPropagation();
  //   API.showPDF(id, data.id);
  // };

  const showConfirmButton = !isConfirm && !data.previewMode && !/borrower/i.test(role);

  return (
    <>
      <div className="lar-card">
        <div className="lar-card-wrapper">
          <div className="quote-title-wrapper">
            <SectionTitle type="heading-20" title={`Lender ${data?.number}`} style={{ marginBottom: '14px' }} />
          </div>
          <SectionTitle
            style={{ fontWeight: 600, marginTop: 8 }}
            type="regular-24"
            title={numberToCurrency(data?.loan_amount)}
            className="quote-loan-amount"
          />
          {rows?.map(
            (row) =>
              row &&
              row.name !== 'Loan Amount' && (
                <div className="card-row" key={row.name}>
                  <span className="key">{row.name}</span>
                  <span className="value">
                    {(() => {
                      const value =
                        row.value === 'total_origination_fee'
                          ? data.origination_fee + data.broker_fee
                          : data[row.value];

                      return row.format ? row.format(value) : value;
                    })()}
                  </span>
                </div>
              ),
          )}
          <div className="bid-table-list__list-item-footer">
            {data.additional_terms_and_comment && (
              <span
                className="additional-field"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleTermsVisible();
                }}
              >
                <NoteBigIcon
                  style={{
                    color: globalStyles.main_blue_color,
                    marginRight: 4,
                  }}
                />
                <span className="bid-table-list__list-item-link-text">View Lender Comments</span>
              </span>
            )}
          </div>
        </div>
        {!noFooter && (
          <>
            {data.bid_status !== 'winner' && (
              <div className={styles.footer}>
                {/* <Button size="large" onClick={showPDF}>
                  View Rate Sheet
                </Button> */}

                {showConfirmButton && (
                  <Button type="dark" size="large">
                    Select as winning quote
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <Modal footer={null} onCancel={toggleTermsVisible} title="Additional terms" open={termsVisible}>
        <div dangerouslySetInnerHTML={{ __html: data.additional_terms_and_comment }} />
      </Modal>
    </>
  );
}
