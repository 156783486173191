import moment from 'moment';
import { num2Word } from './num2Word';
import { numberToCurrency } from './numberToComma';

export function daysToCloseFormatter(value) {
  const parsedDate = moment(value, 'YYYY-DD-MM', true);
  const delta = parsedDate.isValid() ? parsedDate.diff(moment(), 'days') : null;

  return delta ? delta + num2Word(delta, [' day', ' days']) : value;
}

export function currencyFormatter(value) {
  return numberToCurrency(value);
}

export function boolFormatter(value) {
  return value ? 'Yes' : 'No';
}

export function twoDecimalFormatter(value) {
  return value?.toFixed(2);
}

export default function getBidValuesData(loan_purpose, loan_term, rate_type = '') {
  if (loan_purpose === 'showAll') {
    return [
      {
        format: currencyFormatter,
        name: 'Loan Amount',
        value: 'loan_amount',
      },
      {
        name: 'Loan to value (%)',
        value: 'max_to_loan_value',
        format: twoDecimalFormatter,
      },
      {
        name: 'Loan to cost (%)',
        value: 'loan_to_cost',
        format: twoDecimalFormatter,
      },
      {
        name: 'Rate type',
        value: 'rate_type',
      },
      {
        name: 'Interest rate (%)',
        value: 'interest_rate',
      },
      {
        name: 'Arm option',
        value: 'arm_option',
      },
      {
        name: 'Floating rate index',
        value: 'variable_rate_index',
      },
      {
        name: 'Rate over Index',
        value: 'rate_over_index',
      },
      {
        name: 'Amortization',
        value: 'amortization',
      },
      {
        format: currencyFormatter,
        name: 'Max. cash-out',
        value: 'maximum_cash_out_or_cash_due',
      },
      {
        format: daysToCloseFormatter,
        name: 'Days to Close',
        value: 'closing_date',
      },
      {
        name: 'Income doc. type',
        value: 'income_doc_type',
      },
      {
        name: 'DSCR',
        value: 'DSCR',
      },
      {
        name: 'Loan term',
        value: 'loan_term',
      },
      {
        name: 'Payment type',
        value: 'payment_type',
      },
      {
        name: 'Prepayment penalty',
        value: 'pre_payment_penalty',
      },
      {
        name: 'Lender Fee %',
        value: 'bank_fee',
      },
      {
        format: currencyFormatter,
        name: 'Lender fee as fixed amount',
        value: 'lender_processing_fee',
      },
      {
        format: boolFormatter,
        name: 'YSP Payment Available',
        value: 'ysp_payment_available',
      },
      {
        format: currencyFormatter,
        name: 'Application Costs and Fees Due',
        value: 'application_costs_and_fees_due',
      },
    ];
  }

  // REFI

  if (loan_purpose === 'Refinance') {
    return [
      {
        format: currencyFormatter,
        name: 'Loan Amount',
        value: 'loan_amount',
      },
      {
        name: 'Loan to value (%)',
        value: 'max_to_loan_value',
        format: twoDecimalFormatter,
      },
      {
        name: 'Loan to cost (%)',
        value: 'loan_to_cost',
        format: twoDecimalFormatter,
      },
      {
        name: 'Rate type',
        value: 'rate_type',
      },
      rate_type === 'Variable' && {
        name: 'Interest rate (%)',
        value: 'interest_rate',
      },
      rate_type === 'Variable' && {
        name: 'Arm option',
        value: 'arm_option',
      },
      rate_type === 'Floating' && {
        name: 'Floating rate index',
        value: 'variable_rate_index',
      },
      rate_type === 'Floating' && {
        name: 'Rate over Index',
        value: 'rate_over_index',
      },
      rate_type === 'Fixed' && {
        name: 'Interest rate (%)',
        value: 'interest_rate',
      },
      {
        name: 'Amortization',
        value: 'amortization',
      },
      {
        format: currencyFormatter,
        name: 'Max. cash-out',
        value: 'maximum_cash_out_or_cash_due',
      },
      {
        format: daysToCloseFormatter,
        name: 'Days to Close',
        value: 'closing_date',
      },
      {
        name: 'Income doc. type',
        value: 'income_doc_type',
      },
      {
        name: 'DSCR',
        value: 'DSCR',
      },
      {
        name: 'Loan term',
        value: 'loan_term',
      },
      {
        name: 'Payment type',
        value: 'payment_type',
      },
      {
        name: 'Prepayment penalty',
        value: 'pre_payment_penalty',
      },
      {
        name: 'Lender Fee %',
        value: 'bank_fee',
      },
      {
        format: currencyFormatter,
        name: 'Lender fee as fixed amount',
        value: 'lender_processing_fee',
      },
      {
        format: boolFormatter,
        name: 'YSP Payment Available',
        value: 'ysp_payment_available',
      },
      {
        format: currencyFormatter,
        name: 'Application Costs and Fees Due',
        value: 'application_costs_and_fees_due',
      },
    ];
  }

  // Short term
  if (loan_term === 'Short Term') {
    return [
      {
        format: currencyFormatter,
        name: 'Loan Amount',
        value: 'loan_amount',
      },
      {
        name: 'Max Loan to value (%)',
        value: 'max_to_loan_value',
        format: twoDecimalFormatter,
      },
      {
        name: 'Loan to cost (%)',
        value: 'loan_to_cost',
        format: twoDecimalFormatter,
      },
      {
        name: 'Rate type',
        value: 'rate_type',
      },
      rate_type === 'Variable' && {
        name: 'Interest rate (%)',
        value: 'interest_rate',
      },
      rate_type === 'Variable' && {
        name: 'Arm option',
        value: 'arm_option',
      },
      rate_type === 'Floating' && {
        name: 'Floating rate index',
        value: 'variable_rate_index',
      },
      rate_type === 'Floating' && {
        name: 'Rate over Index',
        value: 'rate_over_index',
      },
      rate_type === 'Fixed' && {
        name: 'Interest rate (%)',
        value: 'interest_rate',
      },
      {
        name: 'Days to close',
        value: 'closing_date',
        format: daysToCloseFormatter,
      },
      {
        name: 'Income doc. type',
        value: 'income_doc_type',
      },
      {
        name: 'Loan term',
        value: 'loan_term',
      },
      {
        name: 'Payment type',
        value: 'payment_type',
      },
      {
        name: 'Prepayment penalty',
        value: 'pre_payment_penalty',
      },
      {
        name: 'Lender Fee %',
        value: 'bank_fee',
      },
      {
        name: 'Lender fee as fixed amount',
        value: 'lender_processing_fee',
        format: currencyFormatter,
      },
      {
        format: boolFormatter,
        name: 'YSP Payment Available',
        value: 'ysp_payment_available',
      },
      {
        format: currencyFormatter,
        name: 'Application Costs and Fees Due',
        value: 'application_costs_and_fees_due',
      },
    ];
  }

  // Purchase
  return [
    {
      format: currencyFormatter,
      name: 'Loan Amount',
      value: 'loan_amount',
    },
    {
      name: 'Loan to value (%)',
      value: 'max_to_loan_value',
      format: twoDecimalFormatter,
    },
    {
      name: 'Loan to cost (%)',
      value: 'loan_to_cost',
      format: twoDecimalFormatter,
    },
    {
      name: 'Rate type',
      value: 'rate_type',
    },
    rate_type === 'Variable' && {
      name: 'Interest rate (%)',
      value: 'interest_rate',
    },
    rate_type === 'Variable' && {
      name: 'Arm option',
      value: 'arm_option',
    },
    rate_type === 'Floating' && {
      name: 'Floating rate index',
      value: 'variable_rate_index',
    },
    rate_type === 'Floating' && {
      name: 'Rate over Index',
      value: 'rate_over_index',
    },
    rate_type === 'Fixed' && {
      name: 'Interest rate (%)',
      value: 'interest_rate',
    },
    {
      name: 'Amortization',
      value: 'amortization',
    },
    {
      name: 'Days to Close',
      value: 'closing_date',
      format: daysToCloseFormatter,
    },
    {
      name: 'Income doc. type',
      value: 'income_doc_type',
    },
    {
      name: 'DSCR',
      value: 'DSCR',
    },
    {
      name: 'Loan term',
      value: 'loan_term',
    },
    {
      name: 'Payment type',
      value: 'payment_type',
    },
    {
      name: 'Prepayment penalty',
      value: 'pre_payment_penalty',
    },
    {
      name: 'Lender Fee %',
      value: 'bank_fee',
    },
    {
      name: 'Lender fee as fixed amount',
      value: 'lender_processing_fee',
      format: currencyFormatter,
    },
    {
      format: boolFormatter,
      name: 'YSP Payment Available',
      value: 'ysp_payment_available',
    },
    {
      format: currencyFormatter,
      name: 'Application Costs and Fees Due',
      value: 'application_costs_and_fees_due',
    },
  ];
}
