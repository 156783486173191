import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Button, Input, Checkbox, Divider, Select, message as toast, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';

import API from 'api';
import { BORROWER, ME } from 'constants/deal-types';
import { fetchBorrowersActive, fetchBorrowersProspects } from 'store/async-actions/users';
import { removeSelectedBorrower } from 'store/actions/usersActions';

import { initialStateModal } from 'pages/Onboarding/BrokerAdmin/Borrowers';
import AddBorrowerPopup from 'components/Popups/AddBorrowerPopup';
import SectionTitle from 'components/OnBoarding/SectionTitle';

import styles from 'pages/Onboarding/LarAppNew/NewDeal/NewDeal.module.css';

function NewDeal() {
  const [activeAuthor, setActiveAuthor] = useState(ME);
  const [pesonalizedMessage, setPesonalizedMessage] = useState('');
  const [fields, setFields] = useState(() => initialStateModal);
  const [sendCopyToSelf, setSendCopyToSelf] = useState(false);
  const [activeModal, setActiveModal] = useState({ show: '', data: null });
  const [activeBorrower, setActiveBorrower] = useState(null);
  const [template, setTemplate] = useState('');
  const [loadingBtn, setLoadingBtn] = useState(false);
  // const [type, setType] = useState('');

  const { role } = useSelector((store) => store.user.userData);
  const isBorrower = /borrower/i.test(role);

  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.userData);
  const { loading, error, data } = useSelector((state) => state.users);

  const handleSelectChange = (value) => {
    setActiveBorrower(value.id);
  };

  const onCloseActiveModal = () => {
    setActiveModal({ show: '', data: null });
  };

  const onChange = (value) => {
    setActiveBorrower(value);
  };

  const fetchBorrowers = useCallback(() => {
    dispatch(fetchBorrowersProspects());
    dispatch(fetchBorrowersActive());
  }, []);

  useEffect(() => {
    fetchBorrowers();
  }, [fetchBorrowers]);

  useEffect(() => {
    if (data.borrowers.selected) {
      setActiveBorrower(data.borrowers.selected.id);
      dispatch(removeSelectedBorrower());
    }

    if (!activeBorrower) {
      return;
    }

    API()
      .get(`/prospects/broker/invite?prospect_id=${activeBorrower}`)
      .then(({ data }) => {
        setTemplate('email_template' in data ? data.email_template : data);
      })
      .catch((err) => console.error(err.response?.data?.error));
  }, [activeBorrower]);

  const handleSubmit = () => {
    if (activeAuthor === BORROWER) {
      submitInvitation({
        cc: [],
        message: pesonalizedMessage,
        send_copy_to_self: sendCopyToSelf,
      });
      return null;
    }
    API()
      .get('/LAR/creation/data_for_form', {
        params: {
          borrower_id: activeBorrower,
          creator: isBorrower ? 'Borrower' : 'Broker',
          // type,
        },
      })
      .then((res) => {
        console.log(res);
        history.push(`/lar_app/create${res.data.token}`);
      });
  };

  const submitInvitation = ({ cc, message, send_copy_to_self }) => {
    setLoadingBtn(true);
    API()
      .post('/prospects/broker/invite', {
        cc,
        message,
        prospect_id: activeBorrower,
        send_copy_to_self,
      })
      .then(() => {
        toast.success('Email successfully sent');
        history.push('/onboarding/dashboard');
      })
      .finally(() => setLoadingBtn(false));
  };

  const handleCancel = () => {
    history.push('/onboarding/dashboard');
  };

  function handleOpenModal() {
    setActiveModal({ show: 'add_borrower', data: null });
  }

  if (error) {
    message.error('Something went wrong');
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link
            to={{
              pathname: '/onboarding/borrowers',
            }}
          >
            Borrowers
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create new deal</Breadcrumb.Item>
      </Breadcrumb>
      <SectionTitle title="Add a new deal" />
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.leftCol}>
            Step 1: <span>Select borrower</span>
          </div>
          <div className={styles.rightCol}>
            <Select
              className={styles.select}
              showSearch
              allowClear
              placeholder="Select a borrower"
              optionFilterProp="children"
              onChange={onChange}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={[...data.borrowers.active, ...data.borrowers.prospects].map((borrower) => ({
                label: borrower.full_name,
                value: borrower.id,
              }))}
              value={!loading && activeBorrower}
              loading={loading}
            />
            <Button type="dark" icon={<PlusOutlined />} onClick={handleOpenModal}>
              Add new borrower
            </Button>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.leftCol}>
            Step 2: <span>Select application author</span>
          </div>
          <div className={classNames([styles.rightCol, styles.rightColColumn])}>
            <div className={styles.buttonWrapper}>
              <button
                className={classNames(styles.typeBtn, styles.authorBtn, activeAuthor === ME && styles.active)}
                onClick={() => {
                  setActiveAuthor(ME);
                }}
              >
                Me
              </button>
              I will complete the deal application on behalf of the borrower
            </div>
            {activeBorrower && (
              <>
                <div className={styles.buttonWrapper}>
                  <button
                    className={classNames(styles.typeBtn, styles.authorBtn, activeAuthor === BORROWER && styles.active)}
                    disabled={!activeBorrower}
                    onClick={() => {
                      setActiveAuthor(BORROWER);
                    }}
                  >
                    Borrower
                  </button>
                  Send a request to the borrower to complete the deal application
                </div>
                {activeAuthor === BORROWER && (
                  <div className={styles.wrapper}>
                    <div className={styles.templateWrapper}>
                      <label>Message</label>
                      <div>
                        <b>
                          From:
                          {currentUser.name} {currentUser.surname}
                        </b>
                        <br />
                        <b>
                          Subject:
                          {template.subject}
                        </b>
                        <p>{ReactHtmlParser(template.message)}</p>
                        <p>{'<Link to this item appears here>'}</p>
                      </div>
                    </div>
                    <div className={styles.messageWrapper}>
                      <label>Add a personalised message (Optional)</label>
                      <Input.TextArea
                        onChange={({ target: { value: v } }) => setPesonalizedMessage(v)}
                        placeholder="Message"
                        rows={4}
                        value={pesonalizedMessage}
                      />
                      <Checkbox
                        checked={sendCopyToSelf}
                        onChange={({ target: { checked: c } }) => setSendCopyToSelf(c)}
                      >
                        Send a copy to myself
                      </Checkbox>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* <div className={styles.row}>
          <div className={styles.leftCol}>
            Step 3: <span>Select type</span>
          </div>
          <div className={classNames([styles.rightCol, styles.rightColColumn])}>
            <div>
              <Radio.Group onChange={(e) => setType(e.target.value)} value={type}>
                <Space direction="vertical">
                  <Radio value="scenario">Scenario</Radio>
                  <Radio value="auction">Auction</Radio>
                </Space>
              </Radio.Group>
            </div>
          </div>
        </div> */}
        <Divider />
        <div className={styles.footerButtons}>
          <Button size="large" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            size="large"
            type="dark"
            loading={loadingBtn}
            onClick={handleSubmit}
            disabled={!activeBorrower}

            // disabled={!activeBorrower || !type}
          >
            {activeAuthor === ME ? 'Start Now' : 'Save & Send'}
          </Button>
        </div>
      </div>
      <AddBorrowerPopup
        setFields={setFields}
        initialStateModal={initialStateModal}
        activeModal={activeModal}
        onCloseActiveModal={onCloseActiveModal}
        fields={fields}
        setUpdateTabs
        handleNewBorrower={handleSelectChange}
        fetchBorrowers={fetchBorrowers}
      />
    </>
  );
}

export default NewDeal;
