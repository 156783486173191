import '../styles/list.css';
import { Checkbox, Dropdown, Menu, Space, Modal, Button } from 'antd';
import { ChevronIcon } from 'assets/icons/icons';
import { createPortal } from 'react-dom';
import { LARAPI } from 'api';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { TransformWrapper } from 'react-zoom-pan-pinch';
import { useCallback, useEffect, useMemo, useState, useReducer, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from 'components/UI/Button';
import DocumentViewer from 'components/OnBoarding/DocumentViewer';
import LARDBContext from 'pages/Onboarding/LARDB/LARDBContext';
import UniversalTable from 'components/UniversalTable';
import { getPrettySubStatusName } from '../../constants/diligenceSubStatuses';
import AddItemPopup from '../../AddDiligenceItem/AddItemPopup';
import DiligenceItemPopup from '../../DiligenceItemPopup/DiligenceItemPopup';
import ListItemActions from '../ListItemActions';
import ListItemExpand from '../ListItemExpand';
import MoveToLenderReviewModal from '../MoveToLenderReviewModal';
import MoveToStatusModal from '../../MoveToStatusModal';
import SearchBox from '../SearchBox';
import selectedItems from '../selectedItems';
import TableHeader from '../TableHeader';
import InviteBorrowerButton from './InviteBorrowerButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

class Column {
  constructor(id, label, order, setOrder, setViewDiligence, render, hidden, sortable = true, flexLabel = false) {
    this.dataIndex = id;
    this.onHeaderCell =
      sortable && label
        ? () => ({
            onClick: () => {
              setOrder({
                order_by: id,
                order_type: order.order_by === id ? !order.order_type : true,
              });
            },
          })
        : null;
    this.onCell = (item) => ({
      onClick: setViewDiligence ? () => setViewDiligence(item) : undefined,
    });
    this.title = label ? (
      <div className={`inline ${flexLabel ? 'flex' : ''}`} style={{ minHeight: 38 }}>
        <span>{label}</span>
        {order?.order_by === id && (
          <ChevronIcon
            style={{
              color: '#8c8c8c',
              display: 'flex',
              transform: `rotate(${180 * order.order_type}deg)`,
              transition: '.3s',
            }}
          />
        )}
      </div>
    ) : null;
    this.render = render;
    this.hidden = hidden ?? false;
  }
}

export default function DiligenceList({ extraContentSlotRef, isActive }) {
  // Holds null or the type of add item popup, i.e. "completed", "regular", etc.
  const [activeAddItemPopup, setActiveAddItemPopup] = useState(null);
  const [modal, setModal] = useState({ visible: null, data: {} });
  const bulkUploadRef = useRef();
  const [fullscreenViwer, setFullscreenViewer] = useState({
    mime: null,
    uri: null,
    visible: false,
  });
  const [moveBackModalVisible, toggleMoveBackModal] = useReducer((s) => !s, false);
  const role = useSelector((store) => store.user.userData?.role);
  const isBroker = /broker/i.test(role);
  const history = useHistory();

  const handleModal = (visibleKey, data = {}) => {
    setModal({
      visible: visibleKey,
      data,
    });
  };
  const handleCloseModal = () => {
    if (modal.visible === 'bulk_upload') {
      bulkUploadRef.current.value = '';
    }
    handleModal(null, {});
  };

  function handleBulkAccept() {
    handleAcceptSelectedItems();
  }

  function moveToLenderReady() {
    handleAcceptSelectedItems('lender_ready');
  }

  function moveToLenderReview() {
    handleAcceptSelectedItems('pending_lender_review');
  }

  const msgHandlers = [
    toggleMoveBackModal,
    handleBulkAccept,
    handleDeleteSelectedItems,
    batchDownload,
    handleRestoreSelectedItems,
    moveToLenderReady,
    moveToLenderReview,
  ];
  const [ddCentreData, updateDDCentreData] = useReducer((state, update) => ({ ...state, ...update }), {
    ready: false,
    dd_center_id: null,
    statistic: {},
  });
  const [status, setStatus] = useState('owed');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const [checked, toggleChecked] = useReducer((state, id) => {
    if (undefined === id) {
      if (ddCentreData.dd_center_items.length === state.size) {
        selectedItems(0, [() => null, () => null], status, role);
        return new Set();
      }

      selectedItems(ddCentreData.dd_center_items.length, msgHandlers, status, role);
      return new Set(ddCentreData.dd_center_items.map((item) => item.id));
    }

    if (id === null) {
      selectedItems(0, [() => null, () => null], status);
      return new Set();
    }

    const copy = new Set(state);
    state.has(id) ? copy.delete(id) : copy.add(id);
    const filteredItems = ddCentreData.dd_center_items.filter((item) => copy.has(item.id));
    const calculateView = filteredItems.every((i) =>
      i.documents && i.documents.length === 1 ? i.documents.every((doc) => !doc.is_sample) : i.documents?.length,
    );
    selectedItems(copy.size, msgHandlers, status, role, calculateView);
    return copy;
  }, new Set());

  useEffect(() => {
    if (!isActive) {
      selectedItems(0, [() => null, () => null], status, role);
    }

    return () => selectedItems(0, [() => null, () => null], status, role);
  }, [isActive, status, role]);

  function handleDeleteSelectedItems() {
    API.archiveItems(checked)
      .then(() => {
        updateDiligenceList();
        toggleChecked(null);
      })
      .catch(({ response }) => console.error(response.data.error));
  }

  function handleAcceptSelectedItems(moveTo) {
    API.acceptItems(checked, null, null, null, moveTo)
      .then(() => {
        updateDiligenceList();
        toggleChecked(null);
        handleCloseModal();
      })
      .catch(({ response }) => {
        console.error(response.data.error);
      });
  }

  function handleRestoreSelectedItems() {
    API.archiveItems([...checked])
      .then(() => {
        updateDiligenceList();
        toggleChecked(null);
        handleCloseModal();
      })
      .catch(({ response }) => {
        console.error(response.data.error);
      });
  }

  const [order, setOrder] = useReducer(
    (_, newState) => {
      toggleChecked(null);
      return newState;
    },
    { order_by: 'updated_at', order_type: false },
  );

  const [viewDiligence, setViewDiligence] = useState(null);
  const context = useContext(LARDBContext);
  const userData = useSelector((store) => store.user.userData);

  const API = useMemo(() => new LARAPI(context.lar_id).DDCentre, [context.lar_id]);

  const handleActionButtonClick = useCallback(
    (buttonType, item) => {
      if (buttonType === 'download') {
        const params = new URLSearchParams({
          items_id: item.id,
          jwt: userData?.token,
        });

        window.open(`${process.env.REACT_APP_API_URL}/LAR/dd_center/item/download?${params}`);
        return;
      }

      setViewDiligence({
        ...item,
        acceptAction: buttonType === 'accept',
        defaultTab: buttonType === 'comment' ? 'discussion' : undefined,
        rejectAction: buttonType === 'reject',
        uploadAction: buttonType === 'upload',
      });
    },
    [userData?.token],
  );

  const addRequestedItemOverlay = useMemo(
    () => (
      <Menu
        items={[
          {
            key: 'completed',
            label: 'Complete document',
          },
          {
            key: 'regular',
            label: 'Basic document',
          },
          {
            key: 'question_or_form',
            label: 'Question form',
          },
        ]}
        onClick={({ key }) => setActiveAddItemPopup(key)}
      />
    ),
    [],
  );

  const tableColumns = useMemo(
    () =>
      [
        ...(['lender_approve', 'pending_lender_review'].includes(status) && isBroker
          ? []
          : [
              new Column(
                'select',
                (
                  <Checkbox
                    checked={checked.size > 0 && ddCentreData.dd_center_items?.length === checked.size}
                    onChange={() => toggleChecked()}
                  />
                ),
                null,
                () => {},
                null,
                (_, { id }) => <Checkbox checked={checked.has(id)} onChange={() => toggleChecked(id)} />,
                // There's nothing borrower can do with bulk-selected items
                /borrower/i.test(userData?.role),
                false,
              ),
            ]),
        new Column('id', 'ID', order, setOrder, setViewDiligence),
        new Column('item_name', 'Document Name', order, setOrder, setViewDiligence),
        new Column('updated_at', 'Last Item Update', order, setOrder, setViewDiligence),
        new Column(
          'item_sub_stage',
          'Substatus',
          order,
          setOrder,
          setViewDiligence,
          (substage) => <span>{getPrettySubStatusName(substage)}</span>,
          status !== 'owed' && status !== 'pending_verification',
        ),
        new Column(
          'actions',
          (
            <Space>
              {/borrower/i.test(userData?.role) || (
                <Dropdown trigger="click" overlay={addRequestedItemOverlay}>
                  <CustomButton text="Add requested item" />
                </Dropdown>
              )}
            </Space>
          ),
          order,
          setOrder,
          null,
          (_, item) => (
            <ListItemActions item={item} role={userData?.role} handleActionButtonClick={handleActionButtonClick} />
          ),
          false,
          false,
          true,
        ),
      ].filter((c) => !c.hidden),
    // eslint-disable-next-line
    [
      status,
      order,
      setOrder,
      checked,
      ddCentreData.dd_center_items?.length,
      userData?.role,
      handleActionButtonClick,
      addRequestedItemOverlay,
    ],
  );

  const updateDiligenceList = useCallback(() => {
    updateDDCentreData({ ready: false });

    API.getItemsList(status, order.order_by, order.order_type ? 'asc' : 'desc', pagination.current, pagination.pageSize)
      .then(({ data }) => {
        updateDDCentreData({ ...data, ready: true });
      })
      .catch(() => {
        setModal((prev) => ({
          ...prev,
          visible: 'no-available-popup',
        }));
      });
  }, [order, pagination, status, API]);

  useEffect(updateDiligenceList, [updateDiligenceList]);

  const masterlistInfo = useMemo(() => {
    if (!ddCentreData.masterlist) {
      return null;
    }

    if (/^superadmin$/i.test(userData?.role)) {
      return (
        <Link to={`/onboarding/masterlists/${ddCentreData.masterlist.masterlist_id}/edit`}>
          {ddCentreData.masterlist.name}
        </Link>
      );
    }

    if (/^pla$|^lom$/i.test(userData?.role)) {
      return <span className="ddCentreData.masterlist-name">{ddCentreData.masterlist.name}</span>;
    }

    return null;
  }, [ddCentreData.masterlist, userData?.role]);

  function batchDownload() {
    const params = new URLSearchParams({
      items_id: [...checked].join(','),
      jwt: userData?.token,
    });

    window.open(`${process.env.REACT_APP_API_URL}/LAR/dd_center/item/download?${params}`);

    toggleChecked(null);
  }

  function submitMoveBack(_, reason) {
    API.moveItemsBack([...checked], reason).then(() => {
      updateDiligenceList();
      toggleChecked(null);
    });
  }

  return (
    <div id="diligence-list">
      {isBroker && <InviteBorrowerButton />}
      <LARDBContext.Provider value={{ ...context, dd: { ...ddCentreData, API } }}>
        {extraContentSlotRef?.current &&
          createPortal(<SearchBox setViewDiligence={setViewDiligence} />, extraContentSlotRef.current)}
        {masterlistInfo}
        <UniversalTable
          className="universal-table_default-padding universal-table_centered-content diligence-table"
          columns={tableColumns}
          dataSource={ddCentreData.dd_center_items}
          expandable={{
            expandedRowRender: (item) => <ListItemExpand {...item} />,
          }}
          headerContent={
            <TableHeader setStatus={(newStatus) => toggleChecked(null) || setStatus(newStatus)} status={status} />
          }
          loading={!ddCentreData.ready}
          pagination={{ ...pagination, total: ddCentreData.total }}
          rowKey="id"
          scroll={{ x: 1000 }}
          setPagination={(newPagination) => toggleChecked(null) || setPagination(newPagination)}
          withHeader
        />
        <DiligenceItemPopup
          setViewDiligence={setViewDiligence}
          status={status}
          updateDiligenceList={updateDiligenceList}
          viewDiligence={viewDiligence}
        />
        <AddItemPopup
          setType={setActiveAddItemPopup}
          type={activeAddItemPopup}
          updateDiligenceList={updateDiligenceList}
        />
      </LARDBContext.Provider>
      <MoveToLenderReviewModal
        visible={modal.visible === 'move_to_lender_review'}
        modal={modal}
        onCloseActiveModal={handleCloseModal}
        onOk={handleAcceptSelectedItems}
        onCancel={handleCloseModal}
      />
      <MoveToStatusModal
        newStatus={status === 'lender_ready' ? 'pending_verification' : 'pending_lender_review'}
        onCancel={toggleMoveBackModal}
        onSubmit={submitMoveBack}
        visible={moveBackModalVisible}
      />
      <Modal
        title="This Deal Is No Longer Available for You"
        open={modal.visible === 'no-available-popup'}
        footer={[
          <Button onClick={() => history.push('/')} type="dark">
            Close
          </Button>,
        ]}
        onCancel={() => history.push('/')}
      >
        <p>
          This deal has concluded, and a winner has been selected. It will be removed from your pipeline at midnight.
        </p>
      </Modal>
      {fullscreenViwer.visible && (
        <div className="fullscreen-wrapper">
          <TransformWrapper wheel={{ step: 0.1 }}>
            {(transformWrapperParams) => (
              <DocumentViewer
                activeDocument={fullscreenViwer.uri}
                arrowsColor="white"
                errorHeight={600}
                hasZoomIn={{
                  setFullscreen: (visible) => {
                    setFullscreenViewer((prev) => ({
                      ...prev,
                      visible,
                    }));
                  },
                  ...transformWrapperParams,
                }}
                isImage={fullscreenViwer.mime?.split('/')[0] === 'image'}
                loading={
                  <div className="doc-loading">
                    <LoadingOutlined style={{ fontSize: 108 }} />
                  </div>
                }
                scale={0.9}
              />
            )}
          </TransformWrapper>
        </div>
      )}
    </div>
  );
}
