import { Button, Form, Input } from 'antd';
import { formatPhone } from 'constants/formatPhone';
import { PencilIcon, PlusIcon } from 'assets/icons';
import { useEffect, useState } from 'react';
import CustomButton from 'components/UI/Button';
import formRules from 'constants/formRules';
import classes from './styles/Contacts.module.css';

function ContactCard({ meta = {}, removeContact = () => null, updateContact = () => null }) {
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(meta.id < 0);

  function cancelEditing() {
    removeContact(meta.id);
  }

  function save(form) {
    updateContact({ ...meta, ...form });
    setEditMode(false);
  }

  if (editMode) {
    return (
      <div className={classes.contactCardForm}>
        <b>{meta.id < 0 ? 'Add new' : 'Edit'} point of contact</b>
        <Form form={form} initialValues={meta} onFinish={save}>
          <Form.Item name="full_name" rules={[{ required: true, message: 'Please enter the name' }]}>
            <Input placeholder="Full name" />
          </Form.Item>
          <Form.Item name="email" rules={[{ ...formRules.email, required: true }]}>
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item name="phone" rules={[{ ...formRules.phone, required: true }]}>
            <Input addonBefore="+1" placeholder="Mobile phone" maxLength={10} />
          </Form.Item>
          <Form.Item name="work_phone" rules={[{ ...formRules.phone, required: true }]}>
            <Input addonBefore="+1" placeholder="Work phone" maxLength={10} />
          </Form.Item>
        </Form>
        <div className={classes.contactCardFormButtonsWrapper}>
          <CustomButton text={meta.id < 0 ? 'Cancel' : 'Delete'} onClick={cancelEditing} />
          <CustomButton primary text="Save" onClick={() => form.submit()} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.contactCard}>
      <b>{meta.full_name}</b>
      <div className={classes.greyText}>{meta.email}</div>
      {meta.phone && (
        <div className={classes.greyText}>
          mobile:
          {formatPhone(`+1${meta.phone}`)}
        </div>
      )}
      {meta.work_phone && (
        <div className={classes.greyText}>
          work:
          {formatPhone(`+1${meta.work_phone}`)}
        </div>
      )}
      <button aria-label="actionEdit" className={classes.editButton} onClick={() => setEditMode(true)}>
        <PencilIcon />
      </button>
    </div>
  );
}

export default function Contacts({ API }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    API.getPointsOfContact().then(setItems);
  }, [API]);

  function createContact() {
    setItems((prev) => [
      ...prev,
      {
        id: -new Date(),
        email: '',
        full_name: '',
        phone: '',
        work_phone: '',
      },
    ]);
  }

  function removeContact(id) {
    function removeLocal() {
      setItems((prev) => prev.filter((c) => c.id !== id));
    }

    if (id < 0) {
      return removeLocal();
    }

    API.deletePointOfContact(id).then(removeLocal);
  }

  function updateContact({ id, email, full_name: name, phone, work_phone: workPhone }) {
    let method = 'updatePointOfContact';
    const args = [id, name, email, phone, workPhone];

    // If ID is below 0 then it's a local
    // contact not synchronised with backend
    if (id < 0) {
      args.shift();
      method = 'createPointOfContact';
    }

    API[method](...args).then((item) => {
      setItems((prev) => prev.map((c) => (c.id === id ? item : c)));
    });
  }

  return (
    <>
      <b>Client point of contact</b>
      <div>
        <div className={classes.contactCardsWrapper}>
          {items.map((contact) => (
            <ContactCard key={contact.id} meta={contact} removeContact={removeContact} updateContact={updateContact} />
          ))}
        </div>
        <Button icon={<PlusIcon />} onClick={createContact}>
          Add new point of contact
        </Button>
      </div>
    </>
  );
}
