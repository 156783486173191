export default function numberToComma(number = '') {
  if (!number) {
    return '0';
  }
  const numberArray = number.toString().split('.');
  const value = numberArray[0];
  const decimal = numberArray[1];
  return number
    ? value
        .toString()
        .split('')
        .reverse()
        .map((val, i) => ((i + 1) % 3 === 0 && i + 1 !== value.toString().length ? `,${val}` : val))
        .reverse()
        .join('') + (decimal ? `.${decimal.slice(0, 2)}` : '')
    : '';
}

export function numberFromComma(comma = '') {
  return comma ? comma.toString().split(',').join('') : '';
}

export function numberToCurrency(number = '') {
  return number ? `$${numberToComma(number)}` : '$0';
}

export function numberFromCurrency(currency = '') {
  return currency && currency.includes('$') ? numberFromComma(currency.substring(1)) : numberFromComma(currency);
}
