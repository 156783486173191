import { Button } from 'antd';
import classNames from 'classnames';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { getFileIcon, humanFileSize, onDownloadFile } from 'utils/files';
import { getTypeFromUrl } from 'utils/url';
import styles from './ChatAttachFile.module.css';

export default function ChatAttachFile({ file, preview, name, size, onRemove }) {
  const isImage = preview && /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(preview.split('?')[0]);
  const type = preview ? getTypeFromUrl(preview) : getTypeFromUrl(file?.name);

  function downloadFile() {
    if (preview) {
      onDownloadFile(preview, file?.name || name);
    }
  }

  return (
    <div
      className={classNames(styles.root, onRemove && styles.rootAction, preview && styles.pointer)}
      onClick={downloadFile}
    >
      {isImage || file?.type?.includes('image') ? (
        <div className={styles.imageWrapper}>
          <img src={preview || URL.createObjectURL(file)} alt="" className={styles.image} />
        </div>
      ) : (
        <div className={styles.fileWrapper}>
          <div className={styles.fileTypeImageWrap}>{getFileIcon(type)}</div>
          <div className={styles.fileInfo}>
            <div className={styles.fileName}>{file?.name || name}</div>
            <div>{humanFileSize(file?.size || size)}</div>
          </div>
        </div>
      )}
      {onRemove && (
        <div className={styles.actions}>
          <Button className={styles.button} icon={<TrashIcon />} onClick={onRemove} />
        </div>
      )}
    </div>
  );
}
