import siteName from '../../../../constants/siteName';

const diligenceSubStatuses = [
  { title: 'Answered', value: 'answered', colour: 'yellow' },
  { title: 'Approved', value: 'approved', colour: 'green' },
  { title: 'Lender rejected', value: 'lender_rejected', colour: 'red' },
  { title: 'Lender', value: 'lender', colour: 'green' },
  { title: 'Reanswered', value: 're_answered', colour: 'yellow' },
  { title: 'Reapproved', value: 're_approved', colour: 'blue' },
  { title: 'Reuploaded', value: 're_uploaded', colour: 'yellow' },
  { title: 'Uploaded', value: 'uploaded', colour: 'yellow' },
  { title: 'Waiting', value: 'waiting', colour: 'blue' },
  { title: 'MLO rejected', value: 'broker_rejected', colour: 'red' },
  { title: `${siteName} rejected`, value: 'iharmoni_rejected', colour: 'red' },
];

export function getPrettySubStatusName(status) {
  return diligenceSubStatuses.find((i) => i.value === status)?.title ?? status;
}

export function getSubStatusColour(status) {
  return diligenceSubStatuses.find((i) => i.value === status)?.colour ?? 'red';
}

export default diligenceSubStatuses;
