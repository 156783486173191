import { Button } from 'antd';
import PropTypes from 'prop-types';
import ChatIndicator from '../ChatIndicator';
import styles from './ChatButton.module.css';

function ChatButton({ text, type, hasIndicator, hasNoBackground, badgeColor, onClick }) {
  const hasBg = hasIndicator && !hasNoBackground;

  return (
    <Button
      className={styles.button}
      type={hasBg ? 'primary' : 'text'}
      icon={
        <ChatIndicator
          isVisible
          hasIndicator={hasIndicator}
          hasNoBackground={hasNoBackground}
          type={type}
          badgeColor={badgeColor}
        />
      }
      onClick={onClick}
    >
      {text}
    </Button>
  );
}

export default ChatButton;

ChatButton.defaultProps = {
  text: 'Chat',
  hasIndicator: false,
};

ChatButton.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  badgeColor: PropTypes.string,
  hasIndicator: PropTypes.bool,
  hasNoBackground: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
