import './styles/borrowers.css';
import { Button, Modal, message as toast } from 'antd';
import { closePopup, openPopup } from 'store/actions/popupsActions';
import { PlusOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddBorrowerPopup from 'components/Popups/AddBorrowerPopup';
import API from 'api';
import BorrowersLandingLink from 'components/OnBoarding/BrokerAdmin/BorrowersLandingLink';
import BorrowersTabs from 'components/OnBoarding/BrokerAdmin/BorrowersTabs';
import CustomButton from 'components/UI/Button';
import MainLayout from 'components/Layouts/MainLayout/MainLayout';
import ManualEMSModal from 'components/Modals/ManualEMSModal';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import SelectRequestDrawer from 'components/OnBoarding/BrokerAdmin/SelectRequestDrawer';
import SendInviteDrawer from 'components/OnBoarding/BrokerAdmin/SendInviteDraver';
import UserDrawer from 'components/UserDrawer';

export class InputParams {
  constructor(title, value, maxLength) {
    this.error = false;
    this.errorMessage = 'Fill this field';
    this.maxLength = maxLength ?? 255;
    this.required = true;
    this.title = title ?? '';
    this.value = value ?? '';
  }
}

export const initialStateModal = {
  email: new InputParams('Email'),
  name: new InputParams('First name'),
  phone: new InputParams('Phone', ''),
  surname: new InputParams('Last name'),
};

export default function Borrowers() {
  const [activeModal, setActiveModal] = useState({ show: '', data: null });
  const [activeDrawer, setActiveDrawer] = useState({ show: '', data: null });
  const [fields, setFields] = useState(() => initialStateModal);
  const [link, setLink] = useState('');
  const [linksPopup, setLinksPopup] = useState({ value: '' });
  const [updateTabs, setUpdateTabs] = useState(0);
  const { show: activeUserDrawer, data: userDrawerData } = useSelector((state) => state.popups);
  const dispatch = useDispatch();

  function createLARHandler(id) {
    setActiveModal({ show: 'create_lar', data: { id } });
  }

  function acceptCreateLARHandler(id) {
    window.open(`/lar_app/create?borrower_id=${id}&scenario=false`, '_blank');
    onCloseActiveModal();
  }

  function createScenarioHandler(borrowerId) {
    window.open(`/lar_app/create?borrower_id=${borrowerId}&scenario=true`, '_blank');
  }

  useEffect(() => {
    // Backend always returns links with own origin
    // This function should fix an issue
    // When backend and frontend have different origins
    // Without hardcoded values
    function fixOrigin(link) {
      const uri = new URL(link);
      uri.host = window.location.host;
      uri.protocol = window.location.protocol;

      return uri.toString();
    }

    API()
      .get('/LAR/landing/broker/company-domains')
      .then(({ data }) => {
        if (data.separated.broker_domain || data.separated.company_domain) {
          setLink(data.login);

          setLinksPopup((prev) => ({
            ...prev,
            value: fixOrigin(data.login),
          }));
        }

        setLinksPopup((prev) => ({
          ...prev,
          disabled: !data.separated.broker_domain || !data.separated.company_domain,
          loading: true,
        }));
      })
      .catch(({ response }) => {
        setLink(null);
        setLinksPopup((prev) => ({
          ...prev,
          disabled: response.data.error,
          loading: true,
        }));

        console.error(response.data.error);
      });
  }, []);

  function deleteCancelBorrower() {
    if (activeModal.show === 'cancel_invite') {
      API()
        .delete('/prospects/broker/invite', {
          params: { prospect_id: activeModal.data.id },
        })
        .then(() => {
          setUpdateTabs((prevState) => prevState + 1);
          onCloseActiveModal();
          toast.success('Invite canceled successfully');
        });
    } else {
      API()
        .delete('/prospects/broker', {
          params: { id: activeModal.data.id },
        })
        .then(() => {
          setUpdateTabs((prevState) => prevState + 1);
          onCloseActiveModal();
          toast.success('Borrower successfully deleted from the company');
        });
    }
  }

  const onCloseDrawer = () => setActiveDrawer({ show: '', data: null });

  function submitInvitation({ cc, message, send_copy_to_self }) {
    API()
      .post('/prospects/broker/invite', {
        cc,
        message,
        prospect_id: activeModal.data.id,
        send_copy_to_self,
      })
      .then(() => {
        setUpdateTabs((prevState) => prevState + 1);
        onCloseActiveModal();
        toast.success('Email successfully sent');
      });
  }

  function submitInvitationDrawer({ cc, message, send_copy_to_self }) {
    API()
      .post('/prospects/broker/invite', {
        cc,
        message,
        prospect_id: activeDrawer.data.id,
        send_copy_to_self,
      })
      .then(() => {
        setUpdateTabs((prevState) => prevState + 1);
        toast.success('Email successfully sent');
      })
      .then(() => {
        onCloseDrawer();
      });
  }

  function onCloseActiveModal() {
    setActiveModal({ show: '', data: null });
  }

  const hideProfile = () => {
    dispatch(closePopup());
  };

  const restoreBorrower = useCallback((id) => {
    API()
      .post('/prospects/broker/restore-borrower', { id })
      .then(() => {
        toast.success('Borrower has been restored successfully');
        setUpdateTabs((prevState) => prevState + 1);
      });
  }, []);

  return (
    <MainLayout title="Borrowers">
      <div className="borrowers_wrapper table_with_header_wrapper table_with_pagination_wrapper">
        <div className="section_title_wrapper">
          <SectionTitle title="Borrowers" />
        </div>
        <div
          className={linksPopup.loading ? '' : 'loading_wrap'}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 12,
            alignItems: 'center',
            gap: 50,
            minHeight: linksPopup.loading ? 'auto' : 50,
          }}
        >
          <BorrowersLandingLink link={link} />
          <Button
            onClick={() => setActiveModal({ show: 'add_borrower', data: null })}
            size="large"
            type="dark"
            icon={<PlusOutlined />}
          >
            Add borrower
          </Button>
        </div>
        <div className="section_table_wrapper table_with_header table_with_pagination">
          <div style={{ marginTop: 20 }}>
            <BorrowersTabs
              createLARHandler={createLARHandler}
              createScenarioHandler={createScenarioHandler}
              linksPopup={linksPopup}
              onCloseDrawer={onCloseDrawer}
              restoreBorrower={restoreBorrower}
              setActiveModal={setActiveModal}
              setFields={setFields}
              showProfile={(user) =>
                dispatch(
                  openPopup({
                    data: user.user_json,
                    show: 'showUserDrawer',
                  }),
                )
              }
              updateTabs={updateTabs}
            />
          </div>
          <SelectRequestDrawer
            acceptCreateLARHandler={acceptCreateLARHandler}
            createScenarioHandler={createScenarioHandler}
            createLARHandler={createLARHandler}
            onClose={onCloseDrawer}
            activeDrawer={activeDrawer}
            setActiveDrawer={setActiveDrawer}
            visible={activeDrawer.show === 'create_drawer'}
          />
          <SendInviteDrawer
            mainRecipient={
              activeDrawer.data?.id
                ? {
                    full_name: activeDrawer.data.full_name,
                    user_id: activeDrawer.data.id,
                  }
                : undefined
            }
            onCancel={onCloseActiveModal}
            onSubmit={submitInvitationDrawer}
            templateRoute={
              activeDrawer.data?.id ? `/prospects/broker/invite?prospect_id=${activeDrawer.data.id}` : undefined
            }
            onClose={onCloseDrawer}
            activeDrawer={activeDrawer}
            visible={activeDrawer.show === 'send_application'}
          />
          <AddBorrowerPopup
            setFields={setFields}
            initialStateModal={initialStateModal}
            activeModal={activeModal}
            onCloseActiveModal={onCloseActiveModal}
            fields={fields}
            setUpdateTabs
            fetchBorrowers={() => setUpdateTabs((prevState) => prevState + 1)}
          />
          <Modal
            className="wrapper_prospect wrapper_prospect_delete"
            title={
              <div className="wrapper_prospect__title">
                <span className="title">
                  {activeModal.show === 'cancel_invite' ? 'Cancel Invite' : 'Remove borrower'}
                </span>
                <span className="description">
                  {activeModal.show === 'cancel_invite'
                    ? 'Are you sure you want to cancel borrower invite'
                    : 'Are you sure you want to delete this borrower. This action cannot be undone. Any deals associated with this borrower will be deleted.'}
                </span>
              </div>
            }
            open={['delete_borrower', 'cancel_invite'].includes(activeModal.show)}
            onOk={onCloseActiveModal}
            centered
            footer={
              <div className="wrapper_remove_prospect_btns">
                <CustomButton key="back" onClick={onCloseActiveModal} text="Cancel" />
                <CustomButton className="btn_darkblue" key="submit" onClick={deleteCancelBorrower} text="Confirm" />
              </div>
            }
            onCancel={onCloseActiveModal}
          />
          <ManualEMSModal
            mainRecipient={
              activeModal.data?.id
                ? {
                    full_name: activeModal.data.full_name,
                    user_id: activeModal.data.id,
                  }
                : undefined
            }
            onCancel={onCloseActiveModal}
            onSubmit={submitInvitation}
            templateRoute={
              activeModal.data?.id ? `/prospects/broker/invite?prospect_id=${activeModal.data.id}` : undefined
            }
            title={`Send ${activeModal.show === 'send_a_reminder' ? 'a reminder' : 'an invite'}`}
            visible={['send_a_reminder', 'send_invite'].includes(activeModal.show)}
          />
          <Modal
            centered
            className="wrapper_prospect"
            title={
              <div className="wrapper_prospect__title">
                <span className="title">Create LAR</span>
                <span className="description">Create a new LAR for the borrower</span>
              </div>
            }
            onOk={onCloseActiveModal}
            open={activeModal.show === 'create_lar'}
            footer={
              <div className="wrapper_remove_prospect_btns">
                <CustomButton key="back" onClick={onCloseActiveModal} text="Cancel" />
                <CustomButton
                  className="btn_darkblue"
                  key="submit"
                  onClick={() => acceptCreateLARHandler(activeModal.data.id)}
                  text="Create LAR"
                />
              </div>
            }
            onCancel={onCloseActiveModal}
          >
            Creating a new LAR will delete any LAR data the borrower might have already input. Are you sure this is what
            you want?
          </Modal>
        </div>
      </div>
      <UserDrawer onClose={hideProfile} user={userDrawerData} visible={activeUserDrawer === 'showUserDrawer'} />
    </MainLayout>
  );
}
