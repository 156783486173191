import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <path d="M7 15H5C3.89543 15 3 15.8954 3 17V19C3 20.1046 3.89543 21 5 21H7C8.10457 21 9 20.1046 9 19V17C9 15.8954 8.10457 15 7 15Z" />
    <path d="M19 15H17C15.8954 15 15 15.8954 15 17V19C15 20.1046 15.8954 21 17 21H19C20.1046 21 21 20.1046 21 19V17C21 15.8954 20.1046 15 19 15Z" />
    <path d="M13 3H11C9.89543 3 9 3.89543 9 5V7C9 8.10457 9.89543 9 11 9H13C14.1046 9 15 8.10457 15 7V5C15 3.89543 14.1046 3 13 3Z" />
    <path d="M6 15V14C6 13.4696 6.21071 12.9609 6.58579 12.5858C6.96086 12.2107 7.46957 12 8 12H16C16.5304 12 17.0391 12.2107 17.4142 12.5858C17.7893 12.9609 18 13.4696 18 14V15" />
    <path d="M12 9V12" />
  </GenericIcon>
));
