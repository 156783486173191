import { useState } from 'react';
import { Modal, Input, Button } from 'antd';

function BorrowerCommentPopup({ onCancel, onOk, visible }) {
  const [reason, setReason] = useState('');

  function clearText() {
    setReason('');
  }

  return (
    <Modal
      footer={[
        <Button key="link" type="link" onClick={clearText}>
          Clear
        </Button>,
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => onOk(reason) || onCancel()}>
          OK
        </Button>,
      ]}
      onCancel={onCancel}
      onOk={() => onOk(reason) || onCancel()}
      open={visible}
    >
      <h2>Send comment to Borrower</h2>
      <Input.TextArea onChange={(e) => setReason(e.target.value)} rows={5} value={reason} />
    </Modal>
  );
}

export default BorrowerCommentPopup;
