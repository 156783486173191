import './styles/fees.css';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import Button from 'components/UI/Button';
import FeesInputLayout from 'components/OnBoarding/Preferences/FeesInputLayout';
import SectionTitle from 'components/OnBoarding/SectionTitle';

const initState = {
  iharmoni_success_fee_current: {
    value: '',
    disabled: true,
    error: false,
    errorMessage: 'Fill this label',
  },
  iharmoni_success_fee_proposed: {
    value: '',
    disabled: true,
    error: false,
    errorMessage: 'Fill this label',
  },
  default_broker_fee_current: {
    value: '',
    disabled: true,
    error: false,
    errorMessage: 'Fill this label',
  },
  default_broker_fee_proposed: {
    value: '',
    disabled: false,
    error: false,
    errorMessage: 'Fill this label',
  },
  total_success_fee_compensation_current: {
    value: '',
    disabled: true,
    error: false,
    errorMessage: 'Fill this label',
  },
  total_success_fee_compensation_proposed: {
    value: '',
    disabled: true,
    error: false,
    errorMessage: 'Fill this label',
  },
};

export default function Fees({ reqGet, reqPost, resText }) {
  const [fields, setFields] = useState(initState);

  useEffect(() => {
    API()
      .get(reqGet ?? '/user_settings/company/broker/fee')
      .then((res) => {
        Object.entries(res.data).forEach((i) => {
          if (i[1].toString().split('.').length !== 2) {
            res.data[i[0]] = `${i[1]}.0`;
          }
        });
        setFields((prevState) => ({
          default_broker_fee_current: {
            ...prevState.default_broker_fee_current,
            value: res.data.default_broker_fee_current?.toString() ?? '',
          },
          default_broker_fee_proposed: {
            ...prevState.default_broker_fee_proposed,
            value: res.data.default_broker_fee_proposed?.toString() ?? '',
          },
          iharmoni_success_fee_current: {
            ...prevState.iharmoni_success_fee_current,
            value: res.data.iharmoni_success_fee_current?.toString() ?? '',
          },
          iharmoni_success_fee_proposed: {
            ...prevState.iharmoni_success_fee_proposed,
            value: res.data.iharmoni_success_fee_proposed?.toString() ?? '',
          },
          total_success_fee_compensation_current: {
            ...prevState.total_success_fee_compensation_current,
            value: res.data.total_success_fee_compensation_current?.toString() ?? '',
          },
          total_success_fee_compensation_proposed: {
            ...prevState.total_success_fee_compensation_proposed,
            value: res.data.total_success_fee_compensation_proposed?.toString() ?? '',
          },
        }));
      });
  }, [reqGet]);

  useEffect(() => {
    setFields((state) => ({
      ...state,
      total_success_fee_compensation_proposed: {
        ...state.total_success_fee_compensation_proposed,
        value: (+fields.default_broker_fee_proposed.value || 0) + (+fields.iharmoni_success_fee_proposed.value || 0),
      },
    }));

    // eslint-disable-next-line
  }, [fields.default_broker_fee_proposed.value]);

  function onCancel() {
    setFields((prev) => ({
      ...prev,
      default_broker_fee_proposed: {
        ...prev.default_broker_fee_proposed,
        value: prev.default_broker_fee_current.value,
      },
    }));
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!fields.default_broker_fee_proposed.value) {
      setFields((prevState) => ({
        ...prevState,
        default_broker_fee_proposed: {
          ...prevState.default_broker_fee_proposed,
          error: true,
        },
      }));
      return;
    }

    if (fields.default_broker_fee_proposed.value > 5) {
      setFields((prevState) => ({
        ...prevState,
        default_broker_fee_proposed: {
          ...prevState.default_broker_fee_proposed,
          error: true,
          errorMessage: 'Maximum value is 5',
        },
      }));
      return;
    }

    const reqObj = {
      default_broker_fee_proposed: +fields.default_broker_fee_proposed.value,
    };

    API()
      .post(reqPost || '/user_settings/company/broker/fee', reqObj)
      .then(() => {
        message.success(resText || 'You successfully uploaded fees');
      })
      .then(() => {
        setFields((prev) => ({
          ...prev,
          default_broker_fee_current: {
            ...prev.default_broker_fee_current,
            value: prev.default_broker_fee_proposed.value,
          },
          total_success_fee_compensation_current: {
            ...prev.total_success_fee_compensation_current,
            value: prev.total_success_fee_compensation_proposed.value,
          },
        }));
      });
  };

  return (
    <form className="wrapper_fees" onSubmit={onSubmit}>
      <SectionTitle title="Broker Fees" />
      <div className="wrapper_every_input_head">
        <div />
        <span>Current</span>
        <span>Proposed</span>
      </div>
      <div className="wrapper_every_input_body">
        <FeesInputLayout
          title="Broker Fee"
          setFields={(e) => setFields(e)}
          current={fields.default_broker_fee_current}
          currentName="default_broker_fee_current"
          proposedName="default_broker_fee_proposed"
          proposed={fields.default_broker_fee_proposed}
        />
        <FeesInputLayout
          title="Starport Fee"
          setFields={(e) => setFields(e)}
          currentName="iharmoni_success_fee_current"
          proposedName="iharmoni_success_fee_proposed"
          current={fields.iharmoni_success_fee_current}
          proposed={fields.iharmoni_success_fee_proposed}
        />
        <FeesInputLayout
          title="Total Fee"
          setFields={(e) => setFields(e)}
          currentName="total_success_fee_compensation_current"
          proposedName="total_success_fee_compensation_proposed"
          current={fields.total_success_fee_compensation_current}
          proposed={fields.total_success_fee_compensation_proposed}
        />
      </div>
      <div style={{ display: 'flex', gap: '1em' }}>
        <Button text="Cancel" onClick={onCancel} />
        <Button text="Save" className="btn_darkblue" type="submit" />
      </div>
    </form>
  );
}
