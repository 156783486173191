import '../../BDR/styles/prospects.css';
import { Badge, Table, Tabs, Modal, message, Row, Col, Space, Button, Select, Typography } from 'antd';
import { CSVLink } from 'react-csv';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import API from 'api';
import { formatPhone } from 'constants/formatPhone';
import { replacedTextData } from 'constants/common';
import siteName from 'constants/siteName';
import globalStyles from 'constants/globalStyles';
import { BinIcon, EditIcon } from 'assets/icons';
import AddProspectModal from 'components/OnBoarding/Prospects/AddProspectModal';
import CustomButton from 'components/UI/Button';
import CustomSearchInput from 'components/CustomComponents/CustomSearchInput';
import PaginationItemRender from 'constants/PaginationItemRender';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import { companyType, roleOptions, userTypes } from './reportFilters';

import styles from './Users.module.css';

const responseSizes = {
  All: 1300,
  Iharmoni: 1300,
  Borrowers: 1700,
  Brokers: 2100,
  Lenders: 2000,
};

export default function Users() {
  const history = useHistory();
  const activeUsersTab = history?.location?.state?.tab;
  const [report, setReport] = useState('');
  const [prospects, setProspects] = useState({ loading: false });
  const [activeTab, setActiveTab] = useState(activeUsersTab || 'All');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [activeModal, setActiveModal] = useState({ show: false, data: null });
  const [exportFilters, setExportFilters] = useState({});
  const [findUserData, setFindUserData] = useState({
    loading: false,
    data: [],
    resultsUi: [],
  });
  const { userData } = useSelector((store) => store.user);

  const newColumns = [
    ...(activeTab === 'Borrowers'
      ? [
          {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 10,
            render: (val) => val + 1,
          },
          {
            title: 'Name',
            dataIndex: 'full_name',
            key: 'full_name',
            render: (val, { id }) => (
              <Typography className={styles.name}>
                {userData.id === id ? (
                  <Badge
                    dot
                    offset={[8, 0]}
                    style={{
                      width: 12,
                      height: 12,
                      background: globalStyles.main_blue_color,
                    }}
                  >
                    {val}
                  </Badge>
                ) : (
                  val
                )}
              </Typography>
            ),
          },
          {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
          },
          {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: formatPhone,
          },
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
          },
          {
            title: 'Associated Broker',
            dataIndex: 'broker',
            key: 'broker',
          },
          {
            title: 'Date Created',
            dataIndex: 'created_at',
            key: 'created_at',
          },
        ]
      : [
          {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 10,
            render: (val) => val + 1,
          },
          {
            title: 'Full Name',
            dataIndex: 'full_name',
            key: 'full_name',
            render: (val, { id }) => (
              <Typography className={styles.name}>
                {userData.id === id ? (
                  <Badge
                    dot
                    offset={[8, 0]}
                    style={{
                      width: 12,
                      height: 12,
                      background: globalStyles.main_blue_color,
                    }}
                  >
                    {val}
                  </Badge>
                ) : (
                  val
                )}
              </Typography>
            ),
          },
          activeTab !== 'Borrowers'
            ? {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                render: (v) => replacedTextData[v] || v,
              }
            : {},
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
          },
          ...(activeTab === 'Lenders'
            ? [
                {
                  title: 'Lender Company',
                  dataIndex: 'company',
                  key: 'company',
                },
                {
                  title: 'Active LARs',
                  dataIndex: 'active_LARs',
                  key: 'active_LARs',
                },
                {
                  title: 'Total LARs',
                  dataIndex: 'total_LARs',
                  key: 'total_LARs',
                },
              ]
            : []),
          ...(activeTab === 'Brokers'
            ? [
                {
                  title: 'Broker Company',
                  dataIndex: 'company',
                  key: 'company',
                },
                {
                  title: 'Active LARs',
                  dataIndex: 'active_LARs',
                  key: 'active_LARs',
                },
                {
                  title: 'Total LARs',
                  dataIndex: 'total_LARs',
                  key: 'total_LARs',
                },
                {
                  title: 'Total Borrowers',
                  dataIndex: 'total_borrowers',
                  key: 'total_borrowers',
                },
              ]
            : []),
          activeTab !== 'Iharmoni' && activeTab !== 'All'
            ? {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
              }
            : {},
          {
            title: 'Recent login',
            dataIndex: 'last_login',
            key: 'last_login',
          },
        ]),
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 50,
    },
    {
      dataIndex: 'enabled',
      title: 'Access',
      render: (_, { enabled }) => (
        <Badge status={enabled ? 'success' : 'error'} text={enabled ? 'Enabled' : 'Disabled'} />
      ),
    },
    {
      title: '',
      dataIndex: 'user_actions',
      key: 'user_actions',
      width: 54,
      render: (_, item) => (
        <div className="users_actions">
          <EditIcon onClick={(e) => editUser(e, item)} />
          <BinIcon onClick={(e) => openDelete(e, item.id)} />
        </div>
      ),
    },
  ];

  const generalColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 10,
      render: (val) => val + 1,
    },
    {
      title: 'Starport User Type',
      dataIndex: 'user_type',
      key: 'user_type',
    },
    {
      title: 'First Name',
      dataIndex: 'name',
      key: 'name',
      render: (val, { id }) => (
        <Typography className={styles.name}>
          {userData.id === id ? (
            <Badge
              dot
              offset={[8, 0]}
              style={{
                width: 12,
                height: 12,
                background: globalStyles.main_blue_color,
              }}
            >
              {val}
            </Badge>
          ) : (
            val
          )}
        </Typography>
      ),
    },

    {
      title: 'Last Name',
      dataIndex: 'surname',
      key: 'surname',
      render: (val, { id }) => (
        <Typography className={styles.name}>
          {userData.id === id ? (
            <Badge
              dot
              offset={[8, 0]}
              style={{
                width: 12,
                height: 12,
                background: globalStyles.main_blue_color,
              }}
            >
              {val}
            </Badge>
          ) : (
            val
          )}
        </Typography>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (v) => replacedTextData[v] || v,
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
    },
    {
      title: 'Access',
      dataIndex: 'accecc',
      key: 'accecc',
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
    },
    {
      title: 'Company Type',
      dataIndex: 'company_type',
      key: 'company_type',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Mobile phone',
      dataIndex: 'phone',
      key: 'phone',
      render: formatPhone,
    },
    {
      title: 'Work phone',
      dataIndex: 'work_phone',
      key: 'work_phone',
      render: formatPhone,
    },
    {
      title: '',
      dataIndex: 'user_actions',
      key: 'user_actions',
      width: 54,
      render: (_, item) => (
        <div className="users_actions">
          <EditIcon onClick={(e) => editUser(e, item)} />
          <BinIcon onClick={(e) => openDelete(e, item.id)} />
        </div>
      ),
    },
  ];

  const openDelete = (e, id) => {
    e.stopPropagation();
    e.preventDefault();

    setActiveModal({
      show: true,
      data: { id },
    });
  };

  const editUser = (e, item) => {
    e.stopPropagation();
    e.preventDefault();

    history.push(`/onboarding/edit_user/${item.id}`, { user: item });
  };

  const handleSearchUsers = (value) => {
    setFindUserData((prevState) => ({
      ...prevState,
      loading: true,
    }));
    API()
      .get(`/superadmin/user/search?search=${value}`)
      .then((res) => {
        const resultsUi = res.data.users.map((user) => (
          <div
            className="custom-search__result-item"
            onClick={() =>
              history.push({
                pathname: `/onboarding/users/${user.id}`,
                state: { tab: activeTab },
              })
            }
            key={user.id}
          >
            {user.name}
            <span style={{ float: 'right', marginLeft: 30 }}>{`id: ${user.id}`}</span>
          </div>
        ));
        setFindUserData((prevState) => ({
          ...prevState,
          resultsUi,
          loading: false,
        }));
        setTimeout(() => {
          setFindUserData((prevState) => ({
            ...prevState,
            loading: false,
          }));
        }, 200);
      });
  };

  const clearFindUsers = () => {
    setFindUserData((prevState) => ({
      ...prevState,
      resultsUi: [],
    }));
  };

  const tabCallback = (val) => {
    setActiveTab(val);
    setPagination((state) => ({
      ...state,
      current: 1,
    }));
    history.push({
      state: { tab: val },
    });
  };

  useEffect(() => {
    API()
      .get('/superadmin/user/users', {
        params: {
          page: pagination.current,
          per_page: pagination.pageSize,
          ...(activeTab === 'All' && exportFilters),
          user_type: activeTab,
        },
      })
      .then((res) => {
        setPagination((prevState) => ({ ...prevState, total: res.data.total }));
        setProspects({
          loading: true,
          total: res.data.total,
          prospects: (res.data.prospects ?? res.data.users).map((i, index) => ({
            ...i,
            index,
          })),
        });
      });
  }, [activeTab, exportFilters, pagination.current, pagination.pageSize]);

  useEffect(() => {
    API()
      .get('/superadmin/user/report', { params: exportFilters })
      .then((res) => setReport(res.data));
  }, [exportFilters]);

  const deleteProspects = () => {
    API()
      .delete('/superadmin/user', { params: { id: activeModal.data.id } })
      .then(() => {
        setProspects((prevState) => ({
          ...prevState,
          prospects: prevState.prospects.filter((i) => i.id !== activeModal.data.id),
        }));
        setActiveModal({ show: null, data: null });

        message.success('User successfully deleted');
      });
  };

  const onCloseActiveModal = () => setActiveModal({ show: null, data: null });

  const handleChangeFilters = (name) => (value) => {
    setExportFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClearFilters = () => {
    setExportFilters({});
  };

  const tableColumns = activeTab === 'All' ? generalColumns : newColumns;

  return (
    <div className="prospects_page_wrapper table_with_header_wrapper table_with_pagination_wrapper">
      <Row gutter={[20, 15]} justify="space-between" align="middle">
        <Col>
          <SectionTitle title="Users" />
        </Col>
        <Col>
          <Space>
            <CSVLink
              size="large"
              className="btn_default btn_darkblue"
              filename={`Starport Users - ${moment().format('MMM DD YYYY')}`}
              data={report}
            >
              Export CSV
            </CSVLink>
            <CustomButton
              text="Create user"
              onClick={() => history.push('/onboarding/create_user')}
              className="btn_darkblue"
            />
          </Space>
        </Col>
        <Col span={24}>
          <Row gutter={20} justify="space-between">
            <Col span={6}>
              <CustomSearchInput
                enterButton
                placeholder="Search users"
                clearFunc={clearFindUsers}
                loading={findUserData.loading}
                results={findUserData.resultsUi}
                searchFunc={handleSearchUsers}
              />
            </Col>
            <Col span={16}>
              <Row gutter={15} justify="end">
                <Col span={5}>
                  <Select
                    allowClear
                    className={styles.select}
                    placeholder="Select a member type"
                    options={userTypes.map((r) => ({ value: r, label: r }))}
                    value={exportFilters.user_type_filter}
                    onChange={handleChangeFilters('user_type_filter')}
                  />
                </Col>
                <Col span={5}>
                  <Select
                    allowClear
                    className={styles.select}
                    placeholder="Select a role"
                    options={roleOptions.map((r) => ({ value: r, label: replacedTextData[r] || r }))}
                    value={exportFilters.role}
                    onChange={handleChangeFilters('role')}
                  />
                </Col>
                <Col span={5}>
                  <Select
                    allowClear
                    className={styles.select}
                    placeholder="Select a company type"
                    options={companyType.map((r) => ({ value: r, label: r }))}
                    value={exportFilters.company_type}
                    onChange={handleChangeFilters('company_type')}
                  />
                </Col>
                <Col span={5}>
                  <Select
                    allowClear
                    className={styles.select}
                    placeholder="Select a status"
                    options={[
                      { value: 'Pending', label: 'Pending' },
                      { value: 'Active', label: 'Active' },
                    ]}
                    value={exportFilters.account_status}
                    onChange={handleChangeFilters('account_status')}
                  />
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="btn_darkblue"
                    disabled={!Object.values(exportFilters).filter((i) => i).length}
                    onClick={handleClearFilters}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="section_table_wrapper table_with_header table_with_pagination">
        <Table
          rowKey="id"
          columns={tableColumns}
          dataSource={prospects.prospects}
          pagination={{
            showSizeChanger: true,
            current: pagination.current,
            total: pagination.total ?? null,
            itemRender: PaginationItemRender,
          }}
          className={styles.table}
          scroll={{ x: responseSizes[activeTab] ?? 1500 }}
          title={() => (
            <Tabs defaultActiveKey={activeTab} onChange={tabCallback}>
              <Tabs.TabPane tab="General" key="All" />
              <Tabs.TabPane tab={siteName} key="Iharmoni" />
              <Tabs.TabPane tab="Borrowers" key="Borrowers" />
              <Tabs.TabPane tab="Brokers" key="Brokers" />
              <Tabs.TabPane tab="Lenders" key="Lenders" />
            </Tabs>
          )}
          onRow={(record) => ({
            onClick: () =>
              history.push({
                pathname: `/onboarding/users/${record.id}`,
                state: { tab: activeTab },
              }),
          })}
          onChange={setPagination}
        />
      </div>
      <AddProspectModal activeModal={activeModal} setProspects={setProspects} onClose={onCloseActiveModal} />
      <Modal
        className="wrapper_prospect wrapper_prospect_delete"
        title={
          <div className="wrapper_prospect__title">
            <span className="title">Delete user account</span>
            <span className="description">Are you sure you want to delete this user’s account?</span>
          </div>
        }
        open={activeModal.show}
        onOk={onCloseActiveModal}
        centered
        footer={
          <div className="wrapper_remove_prospect_btns">
            <CustomButton key="back" onClick={onCloseActiveModal} text="Cancel" />
            <CustomButton key="submit" onClick={deleteProspects} text="Confirm" className="btn_darkblue" />
          </div>
        }
        onCancel={onCloseActiveModal}
      />
    </div>
  );
}
