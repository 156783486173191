import { Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthHeader from '../../components/AuthHeader';
import Navigations from '../../routers/Navigations/index';
import { useRedirectToDefaultPage } from '../../hooks/useRedirectToDefaultPage';
import './styles/auth_main.css';

function AuthRouter() {
  const { RedirectToDefaultPage } = useRedirectToDefaultPage();
  const location = useLocation();
  const store = useSelector((store) => store.user.userData);

  if (store?.role && !new URLSearchParams(location.search).get('jwt')) {
    return <RedirectToDefaultPage />;
  }

  const routes = Navigations.mainNavigationAuth.map((route) => (
    <Route path={route.path} exact component={route.main} key={route.path} />
  ));

  return (
    <div className="main_wrapper_auth">
      <AuthHeader />
      <div className="main_wrapper_auth_body">{routes}</div>
    </div>
  );
}

export default AuthRouter;
