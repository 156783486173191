import { message } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import CustomButton from 'components/UI/Button';
import regExp from 'constants/regExp';
import QuestionFormItem from './QuestionFormItem';
import ActionsFooter from '../ActionsFooter';

export default function QuestionForm({ closeDiligenceItem, editFields, setEditFields, viewDiligence, role }) {
  const [answers, setAnswers] = useState([]);
  const [questionsArray, setQuestionsArray] = useState([]);

  useEffect(() => {
    setQuestionsArray(
      Object.keys(viewDiligence)
        .filter((key) => /^question_[0-9]+$/.test(key))
        .map((key) => {
          const id = key.split('_').pop();

          return {
            id,
            question_answer: viewDiligence[`question_answer_${id}`],
            question_type: viewDiligence[`question_type_${id}`],
            question: viewDiligence[`question_${id}`],
          };
        }),
    );
  }, [viewDiligence]);

  useEffect(() => {
    if (answers.length < questionsArray.length) {
      setAnswers(questionsArray.map((q) => (q.question ? q.question_answer || '' : null)));
    }
  }, [answers, questionsArray]);

  function saveQuestions() {
    const formData = new FormData();
    formData.append('item_id', viewDiligence.id);

    answers.forEach((answer, i) => {
      formData.append(`question_answer_${i + 1}`, answer);
    });

    API()
      .post('/LAR/dd_center/item/upload', formData)
      .then(() => closeDiligenceItem(true));
  }

  function cancelEdit() {
    setEditFields((state) => ({
      ...state,
      question_answers: {},
    }));
  }

  function submitEdit() {
    const emailQuestionI = questionsArray.findIndex((i) => i.question_type === 'email');

    if (emailQuestionI >= 0) {
      const emailValue = answers[emailQuestionI];

      if (!regExp.email.test(emailValue)) {
        message.error('Incorrect email format');
        return;
      }
    }

    saveQuestions();
  }

  return (
    <div className="document-view">
      <div className="document-view-content">
        <div className="question-list">
          <b>Please complete the information below</b>
          {questionsArray.map((i, idx) => {
            const { id, question, question_type } = i;

            if (!question) {
              return null;
            }

            return (
              <QuestionFormItem
                disabled={viewDiligence.item_stage !== 'owed'}
                editFields={editFields}
                idx={idx}
                key={id}
                question_answer={answers[idx]}
                question_type={question_type}
                question={question}
                questionsArray={questionsArray}
                setAnswer={(answer) => {
                  setAnswers((a) => {
                    const aCopy = [...a];
                    aCopy[idx] = answer;
                    return aCopy;
                  });
                }}
              />
            );
          })}
          {viewDiligence.item_stage === 'owed' && (
            <div className="actions">
              <CustomButton onClick={cancelEdit} text="Cancel" />
              <CustomButton primary onClick={submitEdit} text={answers.includes('') ? 'Save' : 'Submit'} />
            </div>
          )}
        </div>
      </div>
      <ActionsFooter
        closeDiligenceItem={closeDiligenceItem}
        documentType="question_or_form"
        instantAccept={viewDiligence.acceptAction}
        instantReject={viewDiligence.rejectAction}
        itemId={viewDiligence.id}
        role={role}
        stage={viewDiligence.item_stage}
      />
    </div>
  );
}
