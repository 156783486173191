import { message } from 'antd';
import { useState } from 'react';
import API from 'api';

const useLarApi = () => {
  const [actionExecuting, setActionExecuting] = useState(false);

  const getLar = async (lar_id) => {
    try {
      return await API().get(`/LAR/creation?lar_id=${lar_id}`);
    } catch (e) {
      console.error(e);
    } finally {
      setActionExecuting(false);
    }
  };

  const createLar = async (larData) => {
    setActionExecuting(true);
    try {
      return await API().post('LAR/creation', larData);
    } catch (e) {
      console.error(e);
      message.error(e?.response?.data?.error || 'Create LAR error!');
      return new Error('Create LAR error');
    } finally {
      setActionExecuting(false);
    }
  };

  const saveFile = async (data, onErr = () => message.error('File save failed')) => {
    setActionExecuting(true);
    try {
      return await API().post('/LAR/creation/file', data);
    } catch (e) {
      console.error(e);
      onErr();
      return e;
    } finally {
      setActionExecuting(false);
    }
  };

  const deleteFile = async (id, lar_id, onErr = () => message.error('File deleting failed')) => {
    setActionExecuting(true);
    try {
      return await API().delete(`/LAR/creation/file?elem_id=${id}`, {
        params: {
          lar_id,
        },
      });
    } catch (e) {
      console.error(e);
      onErr();
      return e;
    } finally {
      setActionExecuting(false);
    }
  };

  const endLarCreation = async (larData) => {
    setActionExecuting(true);

    try {
      return await API().post('/LAR/creation/end_creation', larData);
    } catch (e) {
      message.error('Creating LAR failed');
      return e;
    } finally {
      setActionExecuting(false);
    }
  };

  const larScheduling = async (id) => {
    setActionExecuting(true);

    try {
      return await API().get('/LAR/invite/schedule_auction', { params: { id } });
    } catch (e) {
      message.error('Failed');
    } finally {
      setActionExecuting(false);
    }
  };

  const getEndLarCreationData = async (id) => {
    setActionExecuting(true);

    try {
      return await API().get('/LAR/creation/end_creation', { params: { id } });
    } catch (e) {
      console.error(e);
    } finally {
      setActionExecuting(false);
    }
  };

  const saveLar = async (larData, type) => {
    setActionExecuting(true);
    try {
      if (['purchase_long', 'purchase_short', 'refi'].includes(type)) {
        return await API().post(`/LAR/creation/${type}`, larData);
      }
      throw new Error('Unknown lar type');
    } catch (e) {
      console.error(e);
      message.error(e?.response?.data?.error || 'Save error!');
    } finally {
      setActionExecuting(false);
    }
  };

  return {
    actionExecuting,
    saveLar,
    deleteFile,
    getEndLarCreationData,
    endLarCreation,
    saveFile,
    createLar,
    getLar,
    larScheduling,
  };
};

export default useLarApi;
