import { useMemo } from 'react';
import { LARAPI } from 'api';
import { Typography } from 'antd';

import Modal from 'components/Modals/Modal';

function DeleteLARModal({ larId, onCancel, onDeleteSuccess, ...rest }) {
  const API = useMemo(() => new LARAPI(larId), [larId]);

  const handleDelete = () => {
    API.delete(false).then(onDeleteSuccess);
  };

  return (
    <Modal
      {...rest}
      okText="Confirm"
      title="Are you sure you want to delete this deal?"
      onOk={handleDelete}
      onCancel={onCancel}
    >
      <Typography.Paragraph>
        When you confirm this deal and all related messages and activity will be permanently removed. This action cannot
        be undone.
      </Typography.Paragraph>
    </Modal>
  );
}

export default DeleteLARModal;
