import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { message, Table, Tooltip } from 'antd';
import API, { SettingsAPI } from 'api';
import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter';
import DisableUserModal from 'pages/Onboarding/Settings/DisableUserModal';
import CustomSearchInput from 'components/CustomComponents/CustomSearchInput';
import PaginationItemRender from 'constants/PaginationItemRender';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import { HandStopIcon } from 'assets/icons';

function UserList({ type }) {
  const [activeModal, setActiveModal] = useState({ show: '', data: null });
  const [findUserData, setFindUserData] = useState({
    loading: false,
    data: [],
    resultsUI: [],
  });
  const settingsAPI = useMemo(() => new SettingsAPI(), []);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [prospects, setProspects] = useState({ ready: false, total: 0 });
  const history = useHistory();
  const location = useLocation();

  const toggleUserDisabled = useCallback(
    (id) => {
      setProspects((prev) => ({
        ...prev,
        ready: false,
      }));

      settingsAPI
        .toggleUserDisabled(id)
        .then(({ user_status }) => {
          message.success(`User successfully ${/disabled/i.test(user_status) ? 'dis' : 'en'}abled`);

          API()
            .get('/superadmin/user/users', {
              params: {
                page: pagination.current,
                per_page: pagination.pageSize,
                user_type: capitalizeFirstLetter(type),
              },
            })
            .then(({ data }) => {
              setProspects({
                ready: true,
                total: data.total,
                prospects: (data.prospects ?? data.users).map((i, index) => ({
                  ...i,
                  index,
                })),
              });
            });
        })
        .catch(({ response }) => message.error(response?.data?.error))
        .finally(() => {
          setProspects((prev) => ({
            ...prev,
            ready: true,
          }));

          onCloseActiveModal();
        });
    },
    [settingsAPI, pagination, type],
  );

  const columns = useMemo(
    () => [
      {
        dataIndex: 'index',
        render: (val) => val + 1,
        title: '#',
        width: 10,
      },
      {
        dataIndex: 'full_name',
        title: type === 'brokers' ? 'Broker name' : 'Lender name',
      },
      {
        dataIndex: 'email',
        title: 'Email',
      },
      {
        dataIndex: 'phone',
        title: 'Phone',
      },
      {
        dataIndex: 'city',
        title: 'City',
      },
      {
        dataIndex: 'state',
        title: 'State',
      },
      {
        dataIndex: 'active_LARs',
        title: 'Active LARs',
      },
      {
        dataIndex: 'last_login',
        title: 'Last Activity',
        width: 250,
      },
      {
        key: '',
        title: 'Actions',
        render: (_, { id, full_name, enabled }) => (
          <Tooltip
            title={enabled ? 'Disabling a user will prevent the user from accessing their account' : 'Enable user'}
          >
            <span
              onClick={(e) => {
                e.stopPropagation();

                if (enabled) {
                  setActiveModal({
                    show: 'disable_user',
                    data: {
                      id,
                      full_name,
                    },
                  });
                } else {
                  toggleUserDisabled(id);
                }
              }}
            >
              <HandStopIcon crossed={enabled} />
            </span>
          </Tooltip>
        ),
      },
    ],
    [type, toggleUserDisabled],
  );

  function onCloseActiveModal() {
    setActiveModal({ show: '', data: null });
  }

  function searchUsers(value) {
    setFindUserData((prevState) => ({
      ...prevState,
      loading: true,
    }));

    API()
      .get('/superadmin/user/search', {
        params: {
          search: value,
          user_type: capitalizeFirstLetter(type),
        },
      })
      .then(({ data }) => {
        const resultsUI = data.users.map((user) => (
          <div
            className="custom-search__result-item"
            onClick={() =>
              history.push({
                pathname: `${location.pathname}/${user.id}`,
                state: { type },
              })
            }
            key={user.id}
          >
            {user.name}
            <span style={{ float: 'right', marginLeft: 30 }}>{`id: ${user.id}`}</span>
          </div>
        ));

        setFindUserData((prev) => ({
          ...prev,
          resultsUI,
          loading: false,
        }));
      });
  }

  const clearFindUsers = () => {
    setFindUserData((prev) => ({
      ...prev,
      resultsUI: [],
    }));
  };

  useEffect(() => {
    API()
      .get('/superadmin/user/users', {
        params: {
          page: pagination.current,
          per_page: pagination.pageSize,
          user_type: capitalizeFirstLetter(type),
        },
      })
      .then(({ data }) => {
        setProspects({
          ready: true,
          total: data.total,
          prospects: (data.prospects ?? data.users).map((i, index) => ({ ...i, index })),
        });
      });
  }, [type, pagination]);

  return (
    <>
      <DisableUserModal
        activeModal={activeModal}
        onCloseActiveModal={onCloseActiveModal}
        onSubmit={toggleUserDisabled}
        visible={activeModal.show === 'disable_user'}
      />
      <div className="prospects_page_wrapper table_with_header_wrapper table_with_pagination_wrapper">
        <div className="section_title_wrapper">
          <SectionTitle title={capitalizeFirstLetter(type)} />
        </div>
        <div style={{ maxWidth: 300 }}>
          <CustomSearchInput
            clearFunc={clearFindUsers}
            enterButton
            loading={findUserData.loading}
            placeholder={`Search ${type}`}
            results={findUserData.resultsUI}
            searchFunc={searchUsers}
          />
        </div>
        <div className="section_table_wrapper table_with_header table_with_pagination">
          <Table
            border
            bordered={false}
            columns={columns}
            dataSource={prospects.prospects}
            loading={!prospects.ready}
            pagination={{
              ...pagination,
              itemRender: PaginationItemRender,
              showSizeChanger: true,
              total: prospects.total,
            }}
            onChange={(e) => setPagination(e)}
            scroll={{ x: 1500 }}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => {
                history.push({
                  pathname: `/onboarding/${type}/${record.id}`,
                  state: { type },
                });
              },
            })}
          />
        </div>
      </div>
    </>
  );
}

export default UserList;
