import React from 'react';
import Picker from '../../CustomComponents/Picker';

function InvitesAuction(props) {
  const { data, title, onChangeDate, disabledHours } = props;

  return data
    ? [
        <p className="section_title" key={1}>
          {title}
        </p>,
        <div className="auction_block" key={2}>
          <p className="title">Scheduling Deadline</p>
          <div className="two_col two_col_timezone">
            <Picker
              name="scheduling_deadline"
              data={data}
              onChangeDate={onChangeDate}
              format="hh:mm A"
              showFormat
              title={title}
            />
          </div>
        </div>,
        <div className="auction_block" key={6}>
          <p className="title">Lenders Invited to the Upcoming Auction</p>
          <div className="two_col two_col_timezone">
            <Picker
              name="lender_invites_to_upcoming_auction"
              data={data}
              onChangeDate={onChangeDate}
              format="hh:mm A"
              showFormat
              title={title}
            />
          </div>
        </div>,
        <div className="auction_block" key={7}>
          <p className="title">Preview Period Begins</p>
          <div className="two_col two_col_timezone">
            <Picker
              name="preview_period_begins"
              data={data}
              onChangeDate={onChangeDate}
              format="hh:mm A"
              showFormat
              title={title}
            />
          </div>
        </div>,
        <div className="auction_block" key={4}>
          <p className="title">Auction Begins</p>
          <div className="two_col two_col_timezone">
            <Picker
              name="auction_begins"
              data={data}
              disabledHours={disabledHours}
              onChangeDate={onChangeDate}
              format="hh:mm A"
              showFormat
              title={title}
            />
          </div>
        </div>,
        <div className="auction_block" key={5}>
          <p className="title">Bid Deadline (Initial Quote Submitted)</p>
          <div className="two_col two_col_timezone">
            <Picker
              name="bid_deadline"
              data={data}
              onChangeDate={onChangeDate}
              format="hh:mm A"
              showFormat
              title={title}
            />
          </div>
        </div>,
        <div className="auction_block" key={25}>
          <p className="title">Auction Ends</p>
          <div className="two_col two_col_timezone">
            <Picker
              name="auction_ends"
              data={data}
              disabledHours={disabledHours}
              onChangeDate={onChangeDate}
              format="hh:mm A"
              showFormat
              title={title}
            />
          </div>
        </div>,
      ]
    : null;
}
export default InvitesAuction;
