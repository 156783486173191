import API from 'api';
import {
  fetchBorrowersProspectsError,
  fetchBorrowersProspectsLoading,
  fetchBorrowersProspectsSuccess,
} from 'store/actions/usersActions';

export const fetchBorrowersProspects = () => async (dispatch) => {
  try {
    dispatch(fetchBorrowersProspectsLoading());
    const response = await API().get('/prospects/broker?user_type=prospect');
    dispatch(fetchBorrowersProspectsSuccess(response.data.borrowers));
  } catch (e) {
    dispatch(fetchBorrowersProspectsError(e));
  }
};

export const fetchBorrowersActive = () => async (dispatch) => {
  try {
    dispatch(fetchBorrowersProspectsLoading());
    const response = await API().get('/prospects/broker?user_type=user');
    dispatch(fetchBorrowersProspectsSuccess(response.data.borrowers));
  } catch (e) {
    dispatch(fetchBorrowersProspectsError(e));
  }
};
