import { Checkbox, Form, Radio, Space } from 'antd';
import { useContext, useEffect } from 'react';
import LarAppContext from '../../../context/LarAppContext';
import { PRODUCT_SUBTITLE } from 'constants/forms';

function ProductFormMain({ form, isPurchaseLong = false }) {
  const {
    income_doc_type_DSCR,
    income_doc_type_stated,
    income_doc_type_full_doc,
    income_doc_type_1_3_bank_statements: doc_type_1_3,
    income_doc_type_6_12_bank_statements: doc_type_6_12,
    income_doc_type_24_bank_statements: doc_type_24,
    income_doc_type_k_1_only,
    income_doc_type_VOE_only,
    bank_account_type,
    DSCR_rations,
    interest_rate_type,
    arm_options,
    loan_term,
    payment_type,
    prepayment,
  } = useContext(LarAppContext);
  const income_doc_type_1_3_bank_statements = Form.useWatch('income_doc_type_1_3_bank_statements', form);
  const income_doc_type_6_12_bank_statements = Form.useWatch('income_doc_type_6_12_bank_statements', form);
  const income_doc_type_24_bank_statements = Form.useWatch('income_doc_type_24_bank_statements', form);
  const DSCR = Form.useWatch('income_doc_type_DSCR', form);

  const rateType = Form.useWatch('interest_rate_type', form);

  const bankStatemensSelected =
    income_doc_type_1_3_bank_statements || income_doc_type_6_12_bank_statements || income_doc_type_24_bank_statements;

  // TODO: TEST SAVE DATA
  useEffect(() => {
    form.setFieldsValue({
      income_doc_type_DSCR,
      income_doc_type_stated,
      income_doc_type_full_doc,
      income_doc_type_1_3_bank_statements: doc_type_1_3,
      income_doc_type_6_12_bank_statements: doc_type_6_12,
      income_doc_type_24_bank_statements: doc_type_24,
      income_doc_type_k_1_only,
      income_doc_type_VOE_only,
      bank_account_type,
      DSCR_rations,
      interest_rate_type,
      arm_options,
      loan_term,
      payment_type,
      prepayment,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <p style={{ color: '#8C8C8C' }}>{PRODUCT_SUBTITLE}</p>
      <Form.Item
        label="Income doc type"
        className={isPurchaseLong ? 'scenario-required-field auction-required-field' : 'auction-required-field'}
      >
        <div>
          <Space direction="vertical" size="middle">
            <Form.Item
              initialValue={false}
              style={{ marginBottom: 0 }}
              name="income_doc_type_DSCR"
              valuePropName="checked"
            >
              <Checkbox>DSCR</Checkbox>
            </Form.Item>
            <Form.Item
              initialValue={false}
              style={{ marginBottom: 0 }}
              name="income_doc_type_stated"
              valuePropName="checked"
            >
              <Checkbox>Stated</Checkbox>
            </Form.Item>
            <Form.Item
              initialValue={false}
              style={{ marginBottom: 0 }}
              name="income_doc_type_full_doc"
              valuePropName="checked"
            >
              <Checkbox>Full doc</Checkbox>
            </Form.Item>
            <Form.Item
              initialValue={false}
              style={{ marginBottom: 0 }}
              name="income_doc_type_1_3_bank_statements"
              valuePropName="checked"
            >
              <Checkbox>1-3 Mo. bank statements</Checkbox>
            </Form.Item>
            <Form.Item
              initialValue={false}
              style={{ marginBottom: 0 }}
              name="income_doc_type_6_12_bank_statements"
              valuePropName="checked"
            >
              <Checkbox>6-12 Mo. bank statements</Checkbox>
            </Form.Item>
            <Form.Item
              initialValue={false}
              style={{ marginBottom: 0 }}
              name="income_doc_type_24_bank_statements"
              valuePropName="checked"
            >
              <Checkbox>24 Mo. bank statements</Checkbox>
            </Form.Item>
            <Form.Item
              initialValue={false}
              style={{ marginBottom: 0 }}
              name="income_doc_type_k_1_only"
              valuePropName="checked"
            >
              <Checkbox>K-1 only</Checkbox>
            </Form.Item>
            <Form.Item
              initialValue={false}
              style={{ marginBottom: 0 }}
              name="income_doc_type_VOE_only"
              valuePropName="checked"
            >
              <Checkbox>VOE only</Checkbox>
            </Form.Item>
          </Space>
        </div>
      </Form.Item>
      {bankStatemensSelected ? (
        <Form.Item name="bank_account_type" label="Bank account type" className="auction-required-field">
          <Radio.Group>
            <Space direction="vertical" size="large">
              <Radio value="Personal">Personal</Radio>
              <Radio value="Business">Business</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      ) : null}
      {DSCR ? (
        <Form.Item name="DSCR_rations" label="DSCR ratios">
          <Radio.Group>
            <Space direction="vertical" size="large">
              {['Less than 1.0', '1.0', '1.1', '1.2', '1.3+'].map((val) => (
                <Radio value={val} key={val}>
                  {val}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      ) : null}
      <Form.Item name="interest_rate_type" label="Rate type" initialValue={[]} className="auction-required-field">
        <Checkbox.Group className="custom-checkbox-block">
          <Checkbox value="Fixed">Fixed</Checkbox>
          <Checkbox value="Variable">Variable</Checkbox>
        </Checkbox.Group>
      </Form.Item>
      {rateType?.includes('Variable') ? (
        <Form.Item name="arm_options" label="Arm option" initialValue={[]}>
          <Checkbox.Group className="custom-checkbox-block custom-checkbox-block_3">
            <Checkbox value="5/1">5/1</Checkbox>
            <Checkbox value="7/1">7/1</Checkbox>
            <Checkbox value="10/1">10/1</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      ) : null}
      <Form.Item name="loan_term" label="Loan term" initialValue={[]} className="auction-required-field">
        <Checkbox.Group className="custom-checkbox-block custom-checkbox-block_4">
          <Checkbox value={5}>5</Checkbox>
          <Checkbox value={7}>7</Checkbox>
          <Checkbox value={10}>10</Checkbox>
          <Checkbox value={15}>15</Checkbox>
          <Checkbox value={20}>20</Checkbox>
          <Checkbox value={25}>25</Checkbox>
          <Checkbox value={30}>30</Checkbox>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item name="payment_type" label="Payment type" initialValue={[]}>
        <Checkbox.Group className="custom-checkbox-block">
          <Checkbox value="P&I">P&I</Checkbox>
          <Checkbox value="I/O">I/O</Checkbox>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item name="prepayment" label="Prepayment" initialValue={[]}>
        <Checkbox.Group className="custom-checkbox-block">
          <Checkbox value="No Prepay">No Prepay</Checkbox>
          <Checkbox value="Allow Prepayment">Allow Prepayment</Checkbox>
        </Checkbox.Group>
      </Form.Item>
    </>
  );
}

export default ProductFormMain;
