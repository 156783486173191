import { setBreadCrumbHistorySetValue } from 'store/actions/userActions';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import BreadCrumbHistory from 'components/BreadCrumbHistory';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import MainLayout from '../../../../components/Layouts/MainLayout/MainLayout';

import ScenariosTable from './ScenariosTable';

const initialBreadcrumbHistory = [
  {
    preview: 'Users',
    to: {
      pathname: '/onboarding/dashboard',
    },
  },
  {
    preview: 'Scenarios',
    to: '/onboarding/scenarios',
  },
];

function Scenarios() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [breadcrumbHistory] = useState(initialBreadcrumbHistory);
  const goBack = (key) => dispatch(setBreadCrumbHistorySetValue(key));

  return (
    <MainLayout title="Smartmatch analyzer">
      <div className="scenarios-page">
        <div className="scenarios-page__inner">
          <BreadCrumbHistory history={breadcrumbHistory} goBack={goBack} historyWeb={history} separator="/" />
          <div style={{ marginTop: 16 }} />
          <div className="section_title_wrapper">
            <SectionTitle title="Smartmatch analyzer" />
          </div>
          <div style={{ marginTop: 10 }} />
          <ScenariosTable />
        </div>
      </div>
    </MainLayout>
  );
}
export default Scenarios;
