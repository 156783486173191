import { Route, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from 'components/OnBoarding/Logo';
import Navigations from 'routers/Navigations/index';
import PageHeader from 'components/OnBoarding/PageHeader/PageHeader';

export default function MainRouter() {
  const history = useHistory();
  const location = useLocation();
  const role = useSelector((store) => store.user.userData?.role);

  function handleLogoClick() {
    const link =
      role === 'BDR'
        ? '/onboarding/prospects'
        : role === 'Borrower'
        ? '/'
        : role !== 'Prospect broker'
        ? `/onboarding/dashboard${location.search}`
        : location.pathname.includes('broker')
        ? `/registration/broker/company${location.search}`
        : `/registration/lender/company${location.search}`;

    history.push(link);
  }

  return (
    <>
      <Logo onClick={handleLogoClick} />
      <PageHeader registration />
      <div className="main-registration-wrapper-page">
        {Navigations.mainNavigationAuction.map((route) => (
          <Route path={route.path} exact component={route.main} key={route.path} />
        ))}
      </div>
    </>
  );
}
