import React, { useState } from 'react';
import '../UI/AutoCompleteTextInput/custom_text_input.css';
import { Input } from 'antd';
import lock from '../../assets/images/eye.svg';
import lockOpen from '../../assets/images/eye-off.svg';
import { numberFromCurrency, numberToCurrency } from '../../constants/numberToComma';

function TextInput(props) {
  const [activePass, setActivePass] = useState(false);

  const {
    htmlFor,
    value,
    autoComplete,
    title,
    label,
    placeholder,
    disabled,
    maxLength,
    style,
    autoFocus,
    onChange,
    className,
    errorMessage,
    error,
    type,
    showPassIcon,
    classNameWrapper,
    description,
    hint,
    wrapperStyle,
    icon,
    iconClick,
    onBlur,
    formatAsCurrency,
    addonAfter,
    hideLabel,
  } = props;

  return (
    <div
      style={wrapperStyle}
      className={`input_container
            ${classNameWrapper ?? ''}
            ${hint ? 'input_hint' : ''}
            ${error ? 'input_error' : ''}
            ${showPassIcon || icon ? 'input_show_icon' : ''}`}
    >
      {(label ?? title) &&
        (hideLabel ? (
          <div className="hidden_title_wrapper" data-hidden-title={label ?? title} />
        ) : (
          <label htmlFor={htmlFor} className="input_title">
            {label ?? title}
          </label>
        ))}
      {hint ? (
        <div className="hint">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
              stroke="#BFBFBF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M12 8H12.01" stroke="#BFBFBF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 12H12V16H13" stroke="#BFBFBF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <div className="tool_tip">
            <span>{hint}</span>
          </div>
        </div>
      ) : null}
      <div className="wrapper_input">
        <Input
          value={formatAsCurrency ? (value ? numberToCurrency(value) : '') : value}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          onChange={(e) => {
            formatAsCurrency
              ? onChange({ target: { value: numberFromCurrency(e.target.value), name: htmlFor } })
              : onChange(e);
          }}
          onBlur={(e) => {
            if (onBlur) {
              formatAsCurrency
                ? onBlur({
                    target: { value: numberFromCurrency(e.target.value), name: htmlFor },
                    preventDefault: () => {},
                  })
                : onBlur(e);
            }
          }}
          style={style ?? null}
          disabled={disabled}
          addonAfter={addonAfter}
          maxLength={maxLength ?? null}
          placeholder={placeholder ?? null}
          className={`custom_input ${className ?? ''}`}
          type={activePass ? 'text' : type || 'text'}
          name={htmlFor}
          id={htmlFor}
        />
        {showPassIcon ? (
          activePass ? (
            <img
              src={lockOpen}
              alt="lockOpen"
              width={24}
              onClick={() => setActivePass(!activePass)}
              className="show_pass_icon"
            />
          ) : (
            <img
              src={lock}
              alt="lock"
              width={24}
              onClick={() => setActivePass(!activePass)}
              className="show_pass_icon"
            />
          )
        ) : null}
        {icon && <img src={icon} alt="lock" onClick={iconClick} className="show_pass_icon" />}
      </div>
      {error ? <p className="errorMessage">{errorMessage}</p> : null}
      {description ? <p className="input_descpription">{description}</p> : null}
    </div>
  );
}

export default TextInput;
