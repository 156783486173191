import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import API from '../../../api';
import CustomSearchInput from '../../CustomComponents/CustomSearchInput';

export default function SearchBox() {
  const [findItemData, setFindItemData] = useState([]);
  const [ready, setReady] = useState(true);
  const history = useHistory();

  function editMasterlist(id) {
    history.push(`/onboarding/masterlists/${id}/edit`);
  }

  function searchItems(name) {
    setReady(false);

    API()
      .get('/user_settings/masterlist/search', { params: { name } })
      .then(({ data }) => {
        const resultsUI = data.masterlists.map((item) => (
          <div className="custom-search__result-item" onClick={() => editMasterlist(item.id)} key={item.id}>
            {item.name}
            <span style={{ float: 'right', marginLeft: 30 }}>
              id:
              {item.id}
            </span>
          </div>
        ));

        setFindItemData(resultsUI);
        setReady(true);
      });
  }

  return (
    <CustomSearchInput
      clearFunc={() => setFindItemData([])}
      enterButton
      loading={!ready}
      placeholder="Search"
      results={findItemData}
      searchFunc={searchItems}
    />
  );
}
