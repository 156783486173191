import { LARAPI } from 'api';
import {
  fetchCongratulationsSuccess,
  fetchCongratulationsError,
  fetchCongratulationsLoading,
  fetchActivityLogSuccess,
  fetchActivityLogError,
  fetchActivityLogLoading,
  fetchActivityLogFilterLoading,
  fetchActivityLogFilterError,
  fetchActivityLogFilterSuccess,
  fetchConversationLogLoading,
  fetchConversationLogSuccess,
  fetchConversationLogError,
  fetchConversationLogFilterLoading,
  fetchConversationLogFilterSuccess,
  fetchConversationLogFilterError,
  fetchRatesTracker,
} from 'store/actions/lar';

export const fetchCongratulations = (larId) => async (dispatch) => {
  const API = new LARAPI(larId);
  try {
    dispatch(fetchCongratulationsLoading());
    const response = await API.getCongratulations();
    dispatch(fetchCongratulationsSuccess(response));
  } catch (e) {
    dispatch(fetchCongratulationsError(e));
  }
};

export const postCongratulations = async (larId) => {
  const API = new LARAPI(larId);
  try {
    return await API.postCongratulations();
  } catch (e) {
    console.log(e);
  }
};

export const getRatesTracker = () => async (dispatch) => {
  console.log('REQUEST 1');
  const API = new LARAPI();
  try {
    console.log('REQUEST');
    const response = await API.getRatesTracker();
    dispatch(fetchRatesTracker(response));
  } catch (e) {
    console.log(e);
  }
};

export const fetchActivityLog =
  (larId, userNames, userRoles, events, dateFrom, dateTo, orderBy, orderType, perPage, page, userView) =>
  async (dispatch) => {
    const API = new LARAPI(larId);
    try {
      dispatch(fetchActivityLogLoading());
      const response = await API.getActivityLogs(
        larId,
        userNames,
        userRoles,
        events,
        dateFrom,
        dateTo,
        orderBy,
        orderType,
        perPage,
        page,
        userView,
      );
      dispatch(fetchActivityLogSuccess(response.data));
    } catch (e) {
      dispatch(fetchActivityLogError(e));
    }
  };

export const fetchActivityLogFilter = (larId) => async (dispatch) => {
  const API = new LARAPI(larId);
  try {
    dispatch(fetchActivityLogFilterLoading());
    const response = await API.getActivityLogsFilters(larId);
    dispatch(fetchActivityLogFilterSuccess(response.data));
  } catch (e) {
    dispatch(fetchActivityLogFilterError(e));
  }
};

export const fetchConversationLog =
  (larId, userNames, userRoles, events, dateFrom, dateTo, orderBy, orderType, perPage, page) => async (dispatch) => {
    const API = new LARAPI(larId);
    try {
      dispatch(fetchConversationLogLoading());
      const response = await API.getConversationLogs(
        larId,
        userNames,
        userRoles,
        events,
        dateFrom,
        dateTo,
        orderBy,
        orderType,
        perPage,
        page,
      );
      dispatch(fetchConversationLogSuccess(response.data));
    } catch (e) {
      dispatch(fetchConversationLogError(e));
    }
  };

export const fetchConversationLogFilter = (larId) => async (dispatch) => {
  const API = new LARAPI(larId);
  try {
    dispatch(fetchConversationLogFilterLoading());
    const response = await API.getConversationLogsFilters(larId);
    dispatch(fetchConversationLogFilterSuccess(response.data));
  } catch (e) {
    dispatch(fetchConversationLogFilterError(e));
  }
};
