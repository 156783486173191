import DocumentAttachField from '../DocumentAttachField';

function BasicDocument({ activeDocument, onChange, removeDocument }) {
  return (
    <>
      <div className="add-item-disclaimer">
        <b>Borrower should upload a document to complete this item</b>
      </div>
      <DocumentAttachField activeDocument={activeDocument} onChange={onChange} removeDocument={removeDocument} />
    </>
  );
}

export default BasicDocument;
