import { Col, Form, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useFieldsChangeNew } from '../../helpers/larHelpers';
import formSettings from '../../helpers/formSettings';
import BorrowerFundsMain from './BorrowerFundsMain/BorrowerFundsMain';

export default function BorrowerFundsForm({ setSuccess, setSuccessLA, setProgress, setLAProgress, form }) {
  const formName = 'borrower_funds';
  const [total, setTotal] = useState(0);

  const getSuccessStatus = (fieldsArr) => {
    const total = fieldsArr
      .filter((el) => el.name.includes('funds_amount'))
      .reduce((previousValue, currentValue) => {
        if (!currentValue.value) {
          return previousValue;
        }
        return previousValue + currentValue.value;
      }, 0);
    setTotal(total);
    return true;
  };

  const setSuccessStatus = (status = false) => {
    setSuccess(status);
    setProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };
  const setSuccessStatusLA = (status = false) => {
    setSuccessLA(status);
    setLAProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const onFieldsChange = useFieldsChangeNew(
    getSuccessStatus,
    setSuccessStatus,
    () => form.submit(), // On success
    () => {}, // On reject
    getSuccessStatus,
    setSuccessStatusLA,
  );

  useEffect(() => {
    const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
      name: `${el[0]}_funds_amount`,
      value: el[1].funds_amount,
    }));

    onFieldsChange([1], valuesArr);

    // onFieldsChange is glitchy, listing it as a dependency
    // makes the effect execute a few times a second
    // eslint-disable-next-line
  }, [form]);

  return (
    <Form {...formSettings} form={form} name={formName} onFieldsChange={onFieldsChange}>
      <Row style={{ marginBottom: 8 }}>
        <Col span={6} />
        <Col span={16} offset={1}>
          <Space size={190}>
            <div>Amount</div>
            <div>Asset Type</div>
          </Space>
        </Col>
      </Row>
      <BorrowerFundsMain total={total} form={form} />
    </Form>
  );
}
