import { useEffect, useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Modal, Slider } from 'antd';
import styles from './ImageCropModal.module.css';

const formats = new Map([
  [
    'avatar',
    {
      borderRadius: 128,
      height: 256,
      width: 256,
    },
  ],
  [
    'logo',
    {
      height: 56,
      width: 210,
    },
  ],
]);

export default function ImageCropModal({ visible, file, onCancel, onOk, format, title = 'Adjust company logo' }) {
  const [thumb, setThumb] = useState(null);
  const [zoom, setZoom] = useState(1);
  const editorRef = useRef(null);

  useEffect(() => {
    if (!file) {
      return;
    }

    const fr = new FileReader();
    fr.onload = () => setThumb(fr.result);
    fr.readAsDataURL(file);
  }, [file]);

  function okClickHandler() {
    if (!editorRef.current) {
      return;
    }

    const img = editorRef.current.getImage();
    img.toBlob((blob) => onOk(blob, img.toDataURL()));
  }

  return (
    <Modal centered className={styles.root} onCancel={onCancel} onOk={okClickHandler} title={title} open={visible}>
      {thumb && (
        <AvatarEditor
          {...formats.get(format)}
          border={50}
          color={[0, 0, 0, 0.3]}
          disableBoundaryChecks
          image={thumb}
          ref={editorRef}
          rotate={0}
          scale={zoom}
        />
      )}
      <Slider defaultValue={zoom} min={0.1} max={3} step={0.1} onChange={setZoom} />
    </Modal>
  );
}
