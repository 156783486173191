import TeamSection from './TeamSection';
import './team_members.css';
import { isEmptyElInObj } from '../../../utils/empty';

function TeamMembers(props) {
  return (
    <div className={`team-members ${props?.inPopover ? 'team-members_in-popover' : ''}`} style={{ width: 382 }}>
      <div className="team-members__inner">
        {Object.keys(props.members).map((membersSection, idx) =>
          !isEmptyElInObj(props?.members, membersSection) ? (
            <TeamSection
              key={idx}
              sectionName={membersSection}
              teamPositionGroup={props?.members[membersSection]}
              larId={props.larId}
              updateData={props.updateData}
            />
          ) : null,
        )}
      </div>
    </div>
  );
}

export default TeamMembers;
