import React from 'react';
import { Badge } from 'antd';
import SectionTitle from '../SectionTitle';
import CustomAvatar from '../../UI/Avatar';

function UserPreview(props) {
  const { name, avatar, onView, buttonText, enabled } = props;

  return (
    <div className="user-preview-wrapper">
      <CustomAvatar fullName={name} src={avatar} />
      <SectionTitle type="subheading-16" title={name} />
      <Badge status={enabled ? 'success' : 'error'} text={enabled ? 'Enabled' : 'Disabled'} />
      <button onClick={onView}>{buttonText ?? 'View Profile'}</button>
    </div>
  );
}

export default UserPreview;
