function getTotal(fieldValue = 0, periodValue = 'months', quantityValue = 0, prependDollarSign = true) {
  const res =
    periodValue === 'days'
      ? (Number(fieldValue) / 30) * Number(quantityValue)
      : Number(fieldValue) * Number(quantityValue);

  return prependDollarSign ? `$${res.toFixed(2)}` : res;
}

export default getTotal;
