import { useState } from 'react';
import { message, Button } from 'antd';
import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter';
import { useHistory } from 'react-router-dom';
import API from 'api';
import Keys from 'constants/helper';
import TextInput from 'components/CustomComponents/TextInput';
import useNestedCheckError from 'constants/useNestedCheckError';
import './verification_code.css';

const initState = {
  code: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
  password: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
  repeatPassword: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
};

function VerificationCode() {
  const [field, setFields] = useState(initState);
  const [loading, setLoading] = useState(false);
  const { checkEmpty } = useNestedCheckError(field, setFields);
  const [responseError, setResponseError] = useState(null);
  const history = useHistory();

  const onSubmit = (event) => {
    event.preventDefault();
    const { error, obj } = checkEmpty(field);
    setFields(obj);

    if (error) {
      return;
    }

    if (field.password.value.length <= 7) {
      setFields((prevState) => ({
        ...prevState,
        password: {
          ...prevState.password,
          error: true,
          errorMessage: 'Enter at least 8 characters',
        },
      }));
      return;
    }
    if (field.repeatPassword.value !== field.password.value) {
      setFields((prevState) => ({
        ...prevState,
        repeatPassword: {
          ...prevState.repeatPassword,
          error: true,
          errorMessage: 'Passwords is not similar',
        },
      }));
      return;
    }

    setLoading(true);
    API()
      .post('/sign_in/reset_password', {
        password: field.password.value,
        code: field.code.value,
        email: sessionStorage.getItem(Keys.VERIFICATION_CODE_EMAIL),
      })
      .then(() => {
        message.success('You successfully changed password');
        history.push('/login');
      })
      .catch((e) => {
        if (e.response?.data?.error) {
          setResponseError(e.response.data.error);
        }
      })
      .finally(() => setLoading(false));
  };

  const onChange = (event) => {
    const { value, name } = event.target;
    setFields((prevState) => ({
      ...prevState,
      [name]: {
        value,
        error: null,
      },
    }));
    setResponseError(null);
  };

  return (
    <div className="wrapper_auth wrapper_verification">
      <h3 className="auth_title">Verification code sent</h3>
      <p className="auth_describe">Please enter the code sent to your email</p>
      <form className="wrapper_auth__inputs" onSubmit={onSubmit}>
        <TextInput
          value={field.code.value}
          title="Verification code"
          htmlFor="code"
          onChange={onChange}
          errorMessage={field.code.errorMessage}
          error={field.code.error}
        />
        <TextInput
          value={field.password.value}
          title="New password"
          error={field.password.error}
          showPassIcon
          htmlFor="password"
          errorMessage={field.password.errorMessage}
          onChange={onChange}
          autoComplete="on"
          type="password"
        />
        <TextInput
          value={field.repeatPassword.value}
          title="New password confirmation"
          showPassIcon
          htmlFor="repeatPassword"
          errorMessage={field.repeatPassword.errorMessage}
          type="password"
          autoComplete="on"
          error={field.repeatPassword.error}
          onChange={onChange}
        />
        {responseError ? (
          <div className="wrapper_form_error">
            <span>{capitalizeFirstLetter(responseError)}</span>
          </div>
        ) : null}
        <Button size="large" type="dark" htmlType="submit" loading={loading}>
          Change password
        </Button>
      </form>
    </div>
  );
}

export default VerificationCode;
