import Modal from '../../../Modals/Modal';
import ReadyLaunchDescription from '../ReadyLaunchDescription';

export default function ReadyLaunchModal({ isOpen, onCancel, onOk }) {
  function handleContinue() {
    onOk();
  }
  return (
    <Modal
      okText="Launch Now"
      width={700}
      title={<p style={{ fontSize: 18 }}>We’re ready for Launch</p>}
      bodyStyle={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 24, paddingTop: 0 }}
      onOk={handleContinue}
      open={isOpen}
      onCancel={onCancel}
    >
      <ReadyLaunchDescription />
    </Modal>
  );
}
