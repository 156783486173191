import './styles/actions_footer.css';
import { num2Word } from 'constants/num2Word';
import { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import CustomButton from 'components/UI/Button';
import LARDBContext from 'pages/Onboarding/LARDB/LARDBContext';
import ItemRejectionPopup from './ItemRejectionPopup';

function SubmitFooter({ documentsN, submitApprove }) {
  return (
    <div className="document-view-footer blue-submit">
      <b>
        {documentsN} {num2Word(documentsN, ['file', 'files'])}
      </b>
      <CustomButton text="Submit for review" primary onClick={submitApprove} disabled={documentsN < 1} />
      <div className="document-view-footer-description">
        When all files are added you can submit for review. Once submitted you will no longer be able to add, edit, or
        delete the files.
      </div>
    </div>
  );
}

function GreenApproveFooter({ updated_by, updated_at, submitApprove, toggleRejectPopupVisible, canAccept = true }) {
  return (
    <div className="document-view-footer green-approve">
      <span>
        Submitted by
        <b>{updated_by}</b> {updated_at}
      </span>
      <div>
        {canAccept && <CustomButton text="Accept" onClick={submitApprove} />}
        <CustomButton text="Reject" onClick={toggleRejectPopupVisible} />
      </div>
    </div>
  );
}

function BlueApproveFooter({ submitApprove, toggleRejectPopupVisible, canAccept = true, canReject = true }) {
  return (
    <div className="document-view-footer blue-approve">
      {(canAccept || canReject) && <span>Please check the information and approve if everything is correct.</span>}
      <div className="actions">
        {canAccept && <CustomButton primary onClick={submitApprove} text="Approve The Item" />}
        {canReject && <CustomButton onClick={toggleRejectPopupVisible} text="Decline" />}
      </div>
    </div>
  );
}

export default function DocumentViewFooter({
  closeDiligenceItem,
  documentsN,
  documentType,
  instantAccept = false,
  instantReject = false,
  itemId,
  role,
  stage,
  updated_at,
  updated_by,
}) {
  const [rejectPopupVisible, toggleRejectPopupVisible] = useReducer((state) => !state, instantReject);
  const { dd } = useContext(LARDBContext);

  const submitApprove = useCallback(() => {
    dd.API.acceptItems([itemId]).then(() => closeDiligenceItem(true));
  }, [closeDiligenceItem, itemId, dd.API]);

  const submitReject = useCallback(
    (reason) => {
      dd.API.rejectItems([itemId], reason).then(() => closeDiligenceItem(true));
    },
    [closeDiligenceItem, itemId, dd.API],
  );

  const ExactComponent = useMemo(() => {
    const isBroker = /broker/i.test(role);
    const isBorrower = /borrower/i.test(role);
    const isBorrowerAndBroker = /broker|borrower/i.test(role);
    const isLender = /lender/i.test(role);

    if (stage === 'not_applicable') {
      return () => null;
    }

    if (documentType === 'completed') {
      if (['owed', 'pending_verification', 'lender_ready'].includes(stage)) {
        return isBorrowerAndBroker
          ? isBorrower
            ? (props) => <BlueApproveFooter {...props} canReject={stage === 'owed'} canAccept={stage === 'owed'} />
            : BlueApproveFooter
          : () => null;
      }

      if (['pending_lender_review', 'lender_approve'].includes(stage)) {
        return isLender
          ? (props) => <BlueApproveFooter {...props} canAccept={stage !== 'lender_approve'} />
          : () => null;
      }
    }

    if (documentType === 'regular') {
      if (stage === 'owed') {
        return isBorrowerAndBroker ? SubmitFooter : () => null;
      }

      if (['pending_verification', 'lender_ready'].includes(stage)) {
        return isBroker ? GreenApproveFooter : () => null;
      }

      if (['pending_lender_review', 'lender_approve'].includes(stage)) {
        return isLender
          ? (props) => <GreenApproveFooter {...props} canAccept={stage !== 'lender_approve'} />
          : () => null;
      }
    }

    if (documentType === 'question_or_form') {
      if (['pending_verification', 'lender_ready'].includes(stage)) {
        return isBorrowerAndBroker
          ? isBorrower
            ? (props) => <BlueApproveFooter {...props} canReject={stage === 'owed'} canAccept={stage === 'owed'} />
            : BlueApproveFooter
          : () => null;
      }

      if (['pending_lender_review', 'lender_approve'].includes(stage)) {
        return isLender
          ? (props) => <BlueApproveFooter {...props} canAccept={stage !== 'lender_approve'} />
          : () => null;
      }
    }

    return () => null;
  }, [role, stage, documentType]);

  useEffect(() => {
    instantAccept && submitApprove();
  }, [instantAccept, submitApprove]);

  return (
    <>
      <ExactComponent
        documentsN={documentsN}
        submitApprove={submitApprove}
        toggleRejectPopupVisible={toggleRejectPopupVisible}
        updated_at={updated_at}
        updated_by={updated_by}
      />
      <ItemRejectionPopup onCancel={toggleRejectPopupVisible} onOk={submitReject} visible={rejectPopupVisible} />
    </>
  );
}
