import Loader from 'components/Loader';
import { useEffect, useState, useReducer, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import API from 'api';
import LARDBHeader from 'components/LARDB/LARDBHeader';
import LARDBInfo from 'components/LARDB/LARDBInfo';
import LARDBTabs from 'components/LARDB/LARDBTabs';
import NotesDrawer from 'components/Dashboard/NotesDrawer';
import UserDrawer from 'components/UserDrawer';
import LARDBContext from './LARDBContext';
import classes from './styles/LARDB.module.css';
import MainLayout from '../../../components/Layouts/MainLayout';

function LARDB() {
  const [createNoteDrawer, setCreateNoteDrawer] = useState({ visible: false, text: '' });
  const [notes, setNotes] = useState({ visible: false, data: null });
  const [showInfo, toggleShowInfo] = useReducer((state) => !state, false);
  const [userDrawer, setUserDrawer] = useState({ visible: false, data: null });
  const { id: larId } = useParams();
  const [data, setData] = useState({ lar_id: larId });

  const getNotes = useCallback(() => {
    API()
      .get('/LAR/notes', { params: { lar_id: larId } })
      .then(({ data }) => {
        setNotes((prev) => ({
          ...prev,
          data: {
            ...data,
            notes: Object.entries(data.notes).reverse(),
          },
        }));
      });
  }, [larId]);
  useEffect(getNotes, [getNotes]);

  const getLARDBData = useCallback(() => {
    API()
      .get('/LAR/dashboard/lar_dashboard', { params: { lar_id: larId } })
      .then(({ data }) => {
        setData((prev) => ({
          ...prev,
          ...data,
          ready: true,
        }));
      });
  }, [larId]);
  useEffect(getLARDBData, [getLARDBData]);

  return (
    <MainLayout title={`Loan Dashboard #${larId}`}>
      <div className={classes.root + (data?.ready ? '' : ' loading_wrap')}>
        <LARDBContext.Provider value={{ ...data, refresh: getLARDBData }}>
          <NotesDrawer
            createNoteDrawer={createNoteDrawer}
            notes={notes}
            setCreateNoteDrawer={setCreateNoteDrawer}
            setNotes={setNotes}
          />
          <UserDrawer
            onClose={() => setUserDrawer((prev) => ({ ...prev, visible: false }))}
            user={userDrawer.data}
            visible={userDrawer.visible}
          />
          {data?.ready ? (
            <>
              <LARDBHeader
                openNoteDrawer={() => {
                  setNotes((prev) => ({ ...prev, visible: true }));
                }}
                showInfo={showInfo}
                toggleShowInfo={toggleShowInfo}
              />
              <LARDBInfo show={showInfo} />
              <LARDBTabs
                getLARPDBData={getLARDBData}
                setDataLoading={() => {
                  setData((prev) => ({ ...prev, ready: false }));
                }}
                setUserDrawer={setUserDrawer}
              />
            </>
          ) : (
            <Loader
              color={{
                '--bgColor': 'var(--main-blue-color)',
                '--size': '100px',
                '--wrapper_size': '100px',
              }}
            />
          )}
        </LARDBContext.Provider>
      </div>
    </MainLayout>
  );
}

export default LARDB;
