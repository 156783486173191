import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { ShareIcon } from 'assets/icons';
import { setChatState } from 'store/actions/userActions';
import PlaceQuoteAction from './PlaceQuoteAction';
import ChatButton from 'components/UI/ChatButton';
import ReassignButton from '../../../ReassignButton';

import styles from './ActionsBar.module.css';

function ActionsBar({
  id = 0,
  auction,
  immediateAuction,
  bidPlaced = false,
  canSendMessage = false,
  onSetActiveDrawer,
  onSetActiveModal,
  onTogglePassModal,
  onOpenBidDrawer,
}) {
  const dispatch = useDispatch();
  const { role } = useSelector((store) => store.user.userData);

  function handleOpenChat() {
    const formattedUser = {
      lar_id: +id,
      recipient_role: 'Broker Loan Manager',
      type: 'sidebar',
    };

    dispatch(setChatState(formattedUser));
  }

  const handleOpenPublicMessageForm = () => {
    onSetActiveDrawer({
      show: 'post_message',
      data: {},
    });
  };

  const handleSetActiveModal = (name) => () => onSetActiveModal({ show: name });

  if (role === 'PLA') {
    return null;
  }

  return (
    <header className={styles.root}>
      {/lender/i.test(role) ? (
        <>
          <div>
            <PlaceQuoteAction
              auction={auction}
              isBidPlaced={bidPlaced}
              isImmediateAuction={immediateAuction}
              onOpenBidDrawer={onOpenBidDrawer}
            />
            {bidPlaced || <Button onClick={onTogglePassModal}>Pass</Button>}
            {canSendMessage && <ChatButton text="Message" type="secondary" onClick={handleOpenChat} />}
          </div>
          <div>
            <Button onClick={handleSetActiveModal('share_profile')}>
              <ShareIcon colour="grey-8" />
            </Button>
            <ReassignButton larId={id} isLender />
            {bidPlaced && <Button onClick={onTogglePassModal}>Rescind</Button>}
          </div>
        </>
      ) : (
        <div>
          <Button type="primary" onClick={handleOpenPublicMessageForm}>
            Post a message to all lenders
          </Button>
          <ReassignButton larId={id} isLender={false} />
        </div>
      )}
    </header>
  );
}

export default ActionsBar;
