import './styles/general.css';
import { Checkbox, DatePicker, Space, TimePicker, message } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import Button from 'components/UI/Button';
import CustomPicker from 'components/CustomComponents/CustomPicker';
import moment from 'moment';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import TextInput from 'components/CustomComponents/TextInput';
import Communication from '../../BrokerAdmin/Preferences/Communication';

const initState = {
  business_day_time: {
    value: ['10:00 AM', '07:00 PM'],
    errorMessage: 'Choose the time',
    error: null,
  },
  checkBoxesDayOff: [],
};

const initStateCheckboxes = [
  { preview: 'S', value: { blackout_sunday: false } },
  { preview: 'M', value: { blackout_monday: false } },
  { preview: 'T', value: { blackout_tuesday: false } },
  { preview: 'W', value: { blackout_wednesday: false } },
  { preview: 'R', value: { blackout_thursday: false } },
  { preview: 'F', value: { blackout_friday: false } },
  { preview: 'S', value: { blackout_saturday: false } },
];

const initStateAddDayOff = {
  name: {
    value: '',
    error: false,
    errorMessage: 'Fill this label',
  },
  date: {
    value: null,
    error: false,
    errorMessage: 'Choose the date',
  },
  responseError: null,
};

export default function General() {
  const [fields, setFields] = useState(initState);
  const [checkBoxes, setCheckBoxes] = useState(initStateCheckboxes);
  const [addDayOff, setAddDayOff] = useState(initStateAddDayOff);

  useEffect(() => {
    Promise.all([API().get('/superadmin/settings/general/blackout-day'), API().get('/superadmin/settings/general')])
      .then((res) => {
        const {
          business_day_end,
          business_day_start,
          blackout_friday,
          blackout_monday,
          blackout_saturday,
          blackout_sunday,
          blackout_thursday,
          blackout_tuesday,
          blackout_wednesday,
        } = res[1].data.general_settings;

        setFields((prevState) => ({
          ...prevState,
          checkBoxesDayOff: res[0].data.blackout_days,
          business_day_time: {
            ...prevState.business_day_time,
            value: [
              business_day_start
                .split(' ')
                .filter((_, i) => i <= 1)
                .join(' '),
              business_day_end
                .split(' ')
                .filter((_, i) => i <= 1)
                .join(' '),
            ],
          },
        }));

        setCheckBoxes([
          { preview: 'S', value: { blackout_sunday } },
          { preview: 'M', value: { blackout_monday } },
          { preview: 'T', value: { blackout_tuesday } },
          { preview: 'W', value: { blackout_wednesday } },
          { preview: 'R', value: { blackout_thursday } },
          { preview: 'F', value: { blackout_friday } },
          { preview: 'S', value: { blackout_saturday } },
        ]);
      })
      .catch(console.error);
  }, []);

  const onChangeDate = (dateString, name) => {
    const date = dateString.map((i) => i.toUpperCase());
    setFields((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: date,
        error: false,
      },
    }));
  };

  const onChangeDateAddDayOff = (dateString, name) => {
    setAddDayOff((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: dateString,
        error: null,
      },
    }));
  };

  const onCheckDayOff = (e) => {
    setFields((prevState) => ({
      ...prevState,
      checkBoxesDayOff: prevState.checkBoxesDayOff.map((i) => {
        if (i.name === e.name) {
          return {
            ...i,
            enabled: !i.enabled,
          };
        }
        return i;
      }),
    }));
  };

  const onCheck = (_, name) => {
    setCheckBoxes((prevState) =>
      prevState.map((i) => {
        if (name in i.value) {
          return {
            ...i,
            value: { [name]: !i.value[name] },
          };
        }
        return i;
      }),
    );
  };

  const submitDayOff = () => {
    let error = false;
    if (!addDayOff.date.value) {
      setAddDayOff((prevState) => ({
        ...prevState,
        date: {
          ...prevState.date,
          error: true,
        },
      }));
      error = true;
    }
    if (!addDayOff.name.value.trim()) {
      setAddDayOff((prevState) => ({
        ...prevState,
        name: {
          ...prevState.name,
          error: true,
        },
      }));
      error = true;
    }

    if (error) {
      return;
    }

    const reqDate = addDayOff.date.value
      .split('/')
      .filter((_, index) => index <= 1)
      .join('-');
    API()
      .post('/superadmin/settings/general/blackout-day', {
        name: addDayOff.name.value,
        enabled: true,
        date: reqDate,
      })
      .then((res) => {
        if (!res.data) {
          return;
        }
        setFields((prevState) => ({
          ...prevState,
          checkBoxesDayOff: res.data.blackout_days,
        }));
        setAddDayOff(initStateAddDayOff);

        message.success('You successfully created new blackout day');
      })
      .catch((e) => {
        setAddDayOff((prevState) => ({
          ...prevState,
          responseError: e.response.data.error,
        }));
      });
  };

  const deleteDayOff = (item) => {
    API()
      .delete(`/superadmin/settings/general/blackout-day?name=${item.name}`)
      .then(() => {
        setFields((prevState) => ({
          ...prevState,
          checkBoxesDayOff: prevState.checkBoxesDayOff.filter((i) => i.name !== item.name),
        }));

        message.success(`You successfully deleted day off ${item.name}`);
      });
  };

  const onChange = (e) => {
    setAddDayOff((prevState) => ({
      ...prevState,
      name: {
        ...prevState.name,
        value: e.target.value,
        error: false,
      },
      responseError: null,
    }));
  };

  const onSubmitBlackDaysForm = (e) => {
    e.preventDefault();
    let error = false;
    if (!fields.business_day_time.value.some((i) => i)) {
      setFields((prevState) => ({
        ...prevState,
        business_day_time: {
          ...prevState.business_day_time,
          error: true,
        },
      }));
      error = true;
    }

    if (error) {
      return;
    }
    const blackoutName = (name) => checkBoxes.filter((i) => i.value[name]).map((i) => i.value[name])[0] ?? false;
    const custom_blackout = fields.checkBoxesDayOff.map((i) => {
      delete i.date;
      return i;
    });

    API()
      .post('/superadmin/settings/general', {
        business_day_start_time: fields.business_day_time.value[0],
        business_day_end_time: fields.business_day_time.value[1],
        blackout_sunday: blackoutName('blackout_sunday'),
        blackout_monday: blackoutName('blackout_monday'),
        blackout_tuesday: blackoutName('blackout_tuesday'),
        blackout_wednesday: blackoutName('blackout_wednesday'),
        blackout_thursday: blackoutName('blackout_thursday'),
        blackout_friday: blackoutName('blackout_friday'),
        blackout_saturday: blackoutName('blackout_saturday'),
        custom_blackout,
      })
      .then(() => {
        setAddDayOff(initStateAddDayOff);
        message.success('You successfully updated blackout days');
      });
  };

  return (
    <div className="wrapper_general">
      <form className="blackout_days_form" onSubmit={onSubmitBlackDaysForm}>
        <div className="business_day">
          <CustomPicker
            error={fields.business_day_time.error}
            title="Business day begins at and Business day ends at"
            errorMessage={fields.business_day_time.errorMessage}
          >
            <TimePicker.RangePicker
              use12Hours
              format={['h:mm A', 'h:mm A']}
              allowClear={false}
              hideDisabledOptions
              value={[
                moment(fields.business_day_time.value[0], 'h:mm A'),
                moment(fields.business_day_time.value[1], 'h:mm A'),
              ]}
              onChange={(_, d) => onChangeDate(d, 'business_day_time')}
            />
          </CustomPicker>
        </div>
        <div className="blackout_days">
          <SectionTitle title="Blackout Days" />
          <p className="description">On these days, notifications and reminders will not be sent to users.</p>
          <div className="blackout_days__of_the_week">
            <p className="description">Blackout days of the week</p>
            <div className="checkboxes">
              {checkBoxes.map((i, index) => (
                <div
                  className={Object.values(i.value)[0] ? 'every_active every' : 'every'}
                  key={index}
                  onClick={() => onCheck(i, Object.keys(i.value)[0])}
                >
                  {i.preview} {i.date}
                </div>
              ))}
            </div>
          </div>

          <div className="day_off_manually_list">
            <p className="description">Blackout days of the year</p>
            <div className="checkboxes">
              {fields.checkBoxesDayOff?.map((i, index) => (
                <div key={index} className="every">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    onClick={() => deleteDayOff(i)}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5909 12.332L18.0441 7.8789C18.2554 7.66793 18.3743 7.38165 18.3745 7.08302C18.3748 6.7844 18.2564 6.49791 18.0455 6.28656C17.8345 6.07522 17.5482 5.95634 17.2496 5.95607C16.951 5.95581 16.6645 6.07418 16.4531 6.28515L12 10.7383L7.54687 6.28515C7.33553 6.07381 7.04888 5.95508 6.75 5.95508C6.45111 5.95508 6.16447 6.07381 5.95312 6.28515C5.74178 6.4965 5.62305 6.78314 5.62305 7.08203C5.62305 7.38092 5.74178 7.66756 5.95312 7.8789L10.4062 12.332L5.95312 16.7852C5.74178 16.9965 5.62305 17.2831 5.62305 17.582C5.62305 17.8809 5.74178 18.1676 5.95312 18.3789C6.16447 18.5902 6.45111 18.709 6.75 18.709C7.04888 18.709 7.33553 18.5902 7.54687 18.3789L12 13.9258L16.4531 18.3789C16.6645 18.5902 16.9511 18.709 17.25 18.709C17.5489 18.709 17.8355 18.5902 18.0469 18.3789C18.2582 18.1676 18.3769 17.8809 18.3769 17.582C18.3769 17.2831 18.2582 16.9965 18.0469 16.7852L13.5909 12.332Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                  <Checkbox onChange={() => onCheckDayOff(i)} checked={i.enabled}>
                    {i.name} ({i.date})
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>

          <div className="add_day_off_manually">
            <p className="description">Add day off manually</p>
            <div className="fields">
              <CustomPicker error={addDayOff.date.error} title="Month" errorMessage={addDayOff.date.errorMessage}>
                <DatePicker
                  onChange={(_, d) => onChangeDateAddDayOff(d, 'date')}
                  value={addDayOff.date.value ? moment(addDayOff.date.value, 'DD/MM/YYYY') : ''}
                  format="DD/MM/YYYY"
                  disabledDate={(current) => current && current < moment().startOf('year')}
                />
              </CustomPicker>
              <TextInput
                title="Name"
                maxLength={100}
                value={addDayOff.name.value}
                errorMessage={addDayOff.name.errorMessage}
                onChange={onChange}
                error={addDayOff.name.error}
              />
            </div>
            {addDayOff.responseError ? <p className="errorMessage">{addDayOff.responseError}</p> : null}
            <Button text="Add" className="btn_darkblue" onClick={submitDayOff} />
          </div>
          <Space className="btns-container" size="large" direction="horizontal" align="center">
            {/* <Button text={'Reset to Default'} */}
            {/*        onClick={resetToDefault}/> */}
            <Button text="Save changes" className="btn_darkblue" type="submit" />
          </Space>
        </div>
      </form>
      <div className="communication">
        <Communication
          regGet="/superadmin/settings/general/communication"
          superAdminRole
          resText="You successfully updated superadmin communication"
          regPost="/superadmin/settings/general/communication"
        />
      </div>
    </div>
  );
}
