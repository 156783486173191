import './styles/notes.css';
import { Badge, Modal, Spin, Button, Tooltip } from 'antd';
import { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';

import { BinIcon, EditIcon } from 'assets/icons';

import CustomAvatar from 'components/UI/Avatar';
import LARDBContext from 'pages/Onboarding/LARDB/LARDBContext';
import DiligenceNotesDrawer from './DiligenceNotesDrawer';
import DiligenceVoiceMessageDrawer from './DiligenceVoiceMessageDrawer';

function NoteMessage({ msg }) {
  const match = /moved to (.+)/i.exec(msg);

  if (!match) {
    return msg;
  }

  return (
    <Tooltip
      title={/lender accepted/i.test(match[1]) ? 'Item accepted' : /owed/i.test(match[1]) ? 'Item rejected' : null}
    >
      Moved to <b>{match[1]}</b>
      <Badge
        className="note-item__badge"
        status={/lender accepted/i.test(match[1]) ? 'success' : /owed|mlo review/i.test(match[1]) ? 'error' : null}
        style={{ marginLeft: 8 }}
      />
    </Tooltip>
  );
}

function Note({ noteData, userId, deleteNote, setDrawerData, showActivity = true }) {
  const [visibleDeletePopup, toggleVisibleDeletePopup] = useReducer((state) => !state, false);

  function handleDeleteModal(ok) {
    toggleVisibleDeletePopup();
    ok && deleteNote(noteData.id);
  }

  return (
    <>
      <div className="note-item">
        <div className="note-item__header">
          <span className="note-item__date">{noteData.created_at}</span>
          {showActivity && (
            <span className="note-item__comment">
              <NoteMessage msg={noteData.message} />
            </span>
          )}
        </div>
        <div className="note-item__user">
          <CustomAvatar
            className="note-item__user-avatar"
            fullName={noteData.created_by?.full_name}
            src={noteData.created_by?.avatar}
          />
          <span className="note-item__user-name">{noteData.created_by?.full_name}</span>
          <span className="note-item__user-role">{noteData.created_by?.role}</span>
        </div>
        <div className="note-item__desc">
          <div className="note-item__note-text">
            {noteData.type === 'audio_manual' ? <audio controls src={noteData.message} /> : noteData.additional_message}
          </div>
          {userId === noteData.created_by?.id && /manual/i.test(noteData.type) && (
            <div className="note-item__note-actions">
              {noteData.type === 'audio_manual' || (
                <button
                  aria-label="noteItem"
                  className="note-item__action-btn"
                  onClick={() => {
                    setDrawerData({
                      data: {
                        edit: true,
                        messageId: noteData.id,
                        text: noteData.additional_message,
                      },
                      visible: 'text',
                    });
                  }}
                >
                  <EditIcon />
                </button>
              )}
              <button aria-label="deleteNote" className="note-item__action-btn" onClick={toggleVisibleDeletePopup}>
                <BinIcon />
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal open={visibleDeletePopup} onCancel={() => handleDeleteModal()} onOk={() => handleDeleteModal(true)}>
        Are you sure you want to delete this note?
      </Modal>
    </>
  );
}

export default function DiligenceItemNotes({ viewDiligenceId, type = 'activity' }) {
  const [drawerData, setDrawerData] = useState({ visible: null, data: { text: '' } });
  const [notesData, setNotesData] = useState({ notes: [], ready: false });
  const { dd } = useContext(LARDBContext);
  const userId = useSelector((store) => store.user.userData?.id);

  const getNotes = useCallback(() => {
    setNotesData((prev) => ({ ...prev, ready: false }));

    // TODO: Request either only manual or only auto
    // messages from backend to save bandwidth
    dd.API.getItemNotes(viewDiligenceId).then(({ data }) => {
      setNotesData((prev) => ({
        ...prev,
        notes: data.notes.filter(({ type: msgType }) =>
          /manual/i.test(msgType) ? type === 'discussion' : type === 'activity',
        ),
        ready: true,
      }));
    });
  }, [viewDiligenceId, type, dd.API]);

  useEffect(getNotes, [getNotes]);

  function deleteNote(noteId) {
    dd.API.deleteItemNote(viewDiligenceId, noteId).then(() => {
      setNotesData((prev) => ({
        ...prev,
        notes: prev.notes.filter((note) => note.id !== noteId),
      }));
    });
  }

  function submitNote(message, noteId) {
    return dd.API.submitItemNote(viewDiligenceId, message, noteId).then(() => {
      getNotes();
      setDrawerData({ visible: null, data: { text: '' } });
    });
  }

  return (
    <>
      {type === 'discussion' && (
        <div className="diligence-item-notes__header">
          <div>This discussion board is visible to everyone with access to this deal</div>
          <div>
            <Button onClick={() => setDrawerData({ visible: 'text', data: { text: '' } })} type="dark" size="large">
              Add a comment
            </Button>
            <Button onClick={() => setDrawerData({ visible: 'voice', data: {} })} type="dark" size="large">
              Add a voice message
            </Button>
          </div>
        </div>
      )}
      <Spin spinning={!notesData.ready}>
        <div className="diligence-notes">
          {notesData.notes.map((note) => (
            <Note
              deleteNote={deleteNote}
              key={note.id}
              noteData={note}
              setDrawerData={setDrawerData}
              showActivity={type === 'activity'}
              userId={userId}
            />
          ))}
        </div>
      </Spin>
      <DiligenceNotesDrawer
        data={drawerData.data}
        setDrawerData={setDrawerData}
        submit={submitNote}
        visible={drawerData.visible === 'text'}
      />
      <DiligenceVoiceMessageDrawer
        setDrawerData={setDrawerData}
        submit={submitNote}
        visible={drawerData.visible === 'voice'}
      />
    </>
  );
}
