import { BinIcon } from 'assets/icons';
import { Drawer } from 'antd';
import { formatMinutes, formatSeconds } from 'utils/format-time';
import { useEffect } from 'react';
import arrowLeft from 'assets/images/arrow-left.svg';
import CustomButton from 'components/UI/Button';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import useRecorder from 'constants/useRecorder';

export default function DiligenceVoiceMessageDrawer({ setDrawerData, visible, submit }) {
  const { recorderState, startRecording, saveRecording, cancelRecording } = useRecorder();
  const { recordingMinutes, recordingSeconds, initRecording, audio } = recorderState;

  const recordingUnderLimit = recordingMinutes >= 2;

  useEffect(() => {
    recordingUnderLimit && saveRecording();
  }, [recordingUnderLimit, saveRecording]);

  function closeDrawer() {
    setDrawerData((state) => ({ ...state, visible: null }));
  }

  function handleSubmit() {
    fetch(audio)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], 'audio.ogg', { type: 'audio/ogg; codecs=opus;' });

        return submit(file);
      })
      .then(() => cancelRecording());
  }

  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      closeIcon={false}
      headerStyle={{ display: 'none' }}
      onClose={closeDrawer}
      placement="right"
      visible={visible}
      width={450}
    >
      <div className="drawer-wrapper notes create-note">
        <div className="drawer-header">
          <img onClick={closeDrawer} src={arrowLeft} alt="Close" />
          <strong>
            <SectionTitle type="body-14" title="Add a voice message" />
          </strong>
          <SectionTitle className="lar-identifier long" type="body-14" wrapperStyle={{ marginLeft: -2 }} />
        </div>
        <div
          className="create-note-body"
          style={{
            alignItems: 'start',
            display: 'flex',
            justifyContent: 'center',
            padding: '1em',
          }}
        >
          {audio ? (
            <div className="recording-wrapper validation">
              <BinIcon onClick={cancelRecording} />
              <audio className="recorded-audio" controls src={audio} />
            </div>
          ) : initRecording ? (
            <div className="recording-wrapper">
              <BinIcon onClick={cancelRecording} />
              <div className="recording-info">
                <span>
                  Recording started: {formatMinutes(recordingMinutes)}:{formatSeconds(recordingSeconds)}
                </span>
              </div>
              <CustomButton onClick={saveRecording} text="Stop Recording" />
            </div>
          ) : (
            <CustomButton onClick={startRecording} text="Start Recording" />
          )}
        </div>
        <div className="create-note-footer">
          <div className="buttons">
            <CustomButton onClick={closeDrawer} text="Cancel" />
            <CustomButton disabled={!audio} onClick={handleSubmit} primary text="Save Message" />
          </div>
        </div>
      </div>
    </Drawer>
  );
}
