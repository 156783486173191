import { Helmet } from 'react-helmet';

import styles from './LarCognitoforms.module.css';

export default function LarCognitoforms() {
  return (
    <div className={styles.wrapper}>
      <Helmet>
        <script src="https://www.cognitoforms.com/f/iframe.js" />
      </Helmet>
      <iframe
        title="Create new loan application"
        id="cognitoforms"
        src={process.env.REACT_APP_COGNITO_FORM_URL}
        height="1035"
      />
    </div>
  );
}
