import API from '../API';

export default class DDCentreAPI {
  #LARId = 0;

  constructor(LARId) {
    this.#LARId = LARId;
  }

  async getItemsList(stage, sortingColumn, sortingDirection, page, pageSize) {
    return API().get('LAR/dd_center', {
      params: {
        item_stage: stage,
        lar_id: this.#LARId,
        order_by: sortingColumn,
        order_type: sortingDirection,
        page,
        per_page: pageSize,
      },
    });
  }

  async search(query) {
    return API().get('/LAR/dd_center/search', {
      params: {
        lar_id: this.#LARId,
        search: query,
      },
    });
  }

  async getItem(id) {
    return API().get('/LAR/dd_center/item', {
      params: {
        item_id: id,
      },
    });
  }

  async archiveItems(itemIds) {
    return API().post('/LAR/dd_center/item/archive', {
      items_id: [...itemIds].join(','),
    });
  }

  async moveItems(itemIds, step, reason) {
    // Moving multiple items is not implemented on backend
    return API().post('/LAR/dd_center/item/move', {
      item_id: itemIds[0],
      reason,
      step,
    });
  }

  async moveItemsBack(itemIds, reason) {
    return API().post('/LAR/dd_center/item/move_back', {
      items_id: itemIds,
      reason,
    });
  }

  async acceptItems(itemIds, sendMessage, message, sendCopyToSelf, moveTo) {
    const data = {
      items_id: [...new Set(itemIds)],
      send_trigger: Boolean(sendMessage),
      move_to: moveTo,
    };

    if (sendMessage) {
      data.message = message;
      data.send_copy_to_self = sendCopyToSelf;
    }

    return API().post('/LAR/dd_center/item/accept', data);
  }

  async rejectItems(itemIds, reason) {
    // Rejecting multiple items is not implemented on backend
    return API().post('/LAR/dd_center/item/reject', {
      item_id: itemIds[0],
      message: reason,
    });
  }

  async getItemNotes(itemId) {
    return API().get('/LAR/dd_center/notes', {
      params: { item_id: itemId },
    });
  }

  async submitItemNote(itemId, message, noteId) {
    const formData = new FormData();
    formData.append('item_id', itemId);
    formData.append('message', message);
    undefined !== noteId && formData.append('message_id', noteId);

    return API().post('/LAR/dd_center/notes', formData);
  }

  async deleteItemNote(itemId, noteId) {
    return API().delete('/LAR/dd_center/notes', {
      params: {
        item_id: itemId,
        message_id: noteId,
      },
    });
  }

  async renameFile(itemId, fileId, name) {
    const formData = new FormData();
    formData.append('document_id', fileId);
    formData.append('document_name', name);
    formData.append('item_id', itemId);

    return API().post('/LAR/dd_center/item/document', formData);
  }
}
