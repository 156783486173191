import notificationBannerOptions from '../../../../constants/notificationBannerOptions';

export default function EMSMeta({ channelData, triggerData }) {
  const auctionName = notificationBannerOptions.actions.find((i) => i.id === channelData.action_name)
    ? notificationBannerOptions.actions.find((i) => i.id === channelData.action_name).label
    : channelData.action_name;
  return (
    <div className="trigger-info email">
      <div>
        <span>ID:</span>
        <b>{channelData.id}</b>
      </div>
      <div>
        <span>Nickname:</span>
        <b>{triggerData.name}</b>
      </div>
      <div>
        <span>Recipient User:</span>
        <b>{channelData.role_name}</b>
      </div>
      <div>
        <span>Message Type:</span>
        <b>{channelData.template_type}</b>
      </div>
      <div>
        <span>Use email template:</span>
        <b>{triggerData.type_name === 'Automated' ? 'No' : 'Yes'}</b>
      </div>
      <div>
        <span>Action:</span>
        <b>{auctionName}</b>
      </div>
      <div>
        <span>Updated at:</span>
        <b>{channelData.updated_at}</b>
      </div>
    </div>
  );
}
