import * as formulajs from '@formulajs/formulajs';
import { isEmpty, isObject, sum } from 'lodash';

import { AMORTIZATION, START_PERIOD, TYPE } from 'constants/formulas';
import { numberToCurrency } from 'constants/numberToComma';
import { I_O, LONG_TERM, REFINANCE, SHORT_TERM } from 'constants/larApp';

export function CUMPRINC(bid, ratesTracker) {
  const isLongTerm = bid.loan_term_name === LONG_TERM;
  const isShortTerm = bid.loan_term_name === SHORT_TERM;
  const term = isLongTerm ? bid.loan_term * 12 : bid.loan_term;
  const amortization = isShortTerm ? AMORTIZATION : bid.amortization;

  let interestRate = bid.interest_rate;
  if (bid.rate_type === 'Floating' && ratesTracker) {
    const variable_rate_index = ratesTracker[`${bid.variable_rate_index.toLowerCase()}_rate`];
    interestRate = variable_rate_index + bid.rate_over_index;
  }
  return Math.abs(
    Math.round(
      formulajs.CUMPRINC(interestRate / 12 / 100, amortization * 12, bid.loan_amount, START_PERIOD, term, TYPE),
    ),
  );
}

export function CUMIPMT(bid, ratesTracker) {
  const isLongTerm = bid.loan_term_name === LONG_TERM;
  const isShortTerm = bid.loan_term_name === SHORT_TERM;
  const term = isLongTerm ? bid.loan_term * 12 : bid.loan_term;
  const amortization = isShortTerm ? AMORTIZATION : bid.amortization;

  let interestRate = bid.interest_rate;
  if (bid.rate_type === 'Floating' && ratesTracker) {
    const variable_rate_index = ratesTracker[`${bid.variable_rate_index.toLowerCase()}_rate`];
    interestRate = variable_rate_index + bid.rate_over_index;
  }

  return Math.abs(
    Math.round(
      formulajs.CUMIPMT(interestRate / 12 / 100, amortization * 12, bid.loan_amount, START_PERIOD, term, TYPE),
    ),
  );
}

export function PMT(bid, ratesTracker) {
  const isShortTerm = bid.loan_term_name === SHORT_TERM;
  const amortization = isShortTerm ? AMORTIZATION : bid.amortization;

  let interestRate = bid.interest_rate;
  if (bid.rate_type === 'Floating' && ratesTracker) {
    const variable_rate_index = ratesTracker[`${bid.variable_rate_index.toLowerCase()}_rate`];
    interestRate = variable_rate_index + bid.rate_over_index;
  }

  return Math.round(-formulajs.PMT(interestRate / 12 / 100, amortization * 12 || 1, bid.loan_amount));
}

export function getPrincipal(bid, ratesTracker) {
  if (bid.payment_type === I_O) {
    return 0;
  }

  return CUMPRINC(bid, ratesTracker);
}

export function getInterest(bid, ratesTracker) {
  return CUMIPMT(bid, ratesTracker);
}

export function getBaloonPayment(bid, ratesTracker) {
  const isShortTerm = bid.loan_term_name === SHORT_TERM;
  const isIOType = bid.payment_type === I_O;

  if (isShortTerm && isIOType) {
    return bid.loan_amount;
  }

  return bid.loan_amount - getPrincipal(bid, ratesTracker);
}

export function getMortgagePayment(bid, ratesTracker) {
  return PMT(bid, ratesTracker);
}

export function getMonthlyValue(key, feesWorksheet) {
  return feesWorksheet[`monthly.${key}`]?.value ?? 0;
}

export function getMonthlyPayment(bid, feesWorksheet, ratesTracker) {
  const mortgagePayment = getMortgagePayment(bid, ratesTracker);

  const realEstatePropertyTaxes = getMonthlyValue('real_estate_property_taxes', feesWorksheet);
  const propertyInsurance = getMonthlyValue('property_insurance', feesWorksheet);
  const mortgageInsurance = getMonthlyValue('mortgage_insurance', feesWorksheet);
  const homeownersAssnDues = getMonthlyValue('homeowners_assn_dues', feesWorksheet);

  return mortgagePayment + realEstatePropertyTaxes + propertyInsurance + mortgageInsurance + homeownersAssnDues;
}

export function getDownPayment(feesWorksheet, bid) {
  const totalUses = getTotalUses(feesWorksheet, bid) || 0;
  const closingCost = getClosingCost(feesWorksheet, bid) || 0;
  const loanAmount = bid.loan_amount || 0;

  return totalUses - loanAmount - closingCost;
}

export function getCategorySum(feesWorksheet, prop) {
  const filteredData = Object.fromEntries(
    Object.entries(feesWorksheet)
      .filter(([key]) => key.includes(prop))
      .map(([key, value]) => {
        if (!isObject(value)) {
          return [key, value];
        }

        const isDays = value.period === 'days';
        const quantity = value.quantity || 1;
        const parsedValue = isDays ? (value.value / 30) * quantity : value.value * quantity;

        return [key, parsedValue];
      }),
  );

  return sum(Object.values(filteredData));
}

export function getSumOriginationFees(feesWorksheet, bid) {
  const originationFee = feesWorksheet?.closing_cost_table?.['Origination Fees'] ?? 0;

  return getLenderFee(bid) + getBrokerFee(feesWorksheet, bid) + originationFee;
}

export function getClosingCost(feesWorksheet, bid) {
  return sum([
    getSumOriginationFees(feesWorksheet, bid),
    getCategorySum(feesWorksheet, 'services.'),
    getCategorySum(feesWorksheet, 'taxes.'),
    getCategorySum(feesWorksheet, 'prepaid.'),
    getCategorySum(feesWorksheet, 'initial_escrow.'),
  ]);
}

export function getPurchasePrice(feesWorksheet) {
  return feesWorksheet.purchase_price || 0;
}

export function getTotalProjectCost(feesWorksheet) {
  return getCategorySum(feesWorksheet, 'project_costs.');
}

export function getTotalOneTimeFees(feesWorksheet) {
  return sum([
    getCategorySum(feesWorksheet, 'origination_fees.'),
    getCategorySum(feesWorksheet, 'services.'),
    getCategorySum(feesWorksheet, 'taxes.'),
    getCategorySum(feesWorksheet, 'prepaid.'),
    getCategorySum(feesWorksheet, 'initial_escrow.'),
  ]);
}

export function getLenderFee(bid) {
  const bankFee = bid.bank_fee ?? 0;
  const loanAmount = bid.loan_amount;

  return (bankFee / 100) * loanAmount;
}

export function getBrokerFee(feesWorksheet, bid) {
  const originatorFee = feesWorksheet?.originator_fee ?? 0;
  const loanAmount = bid.loan_amount;

  return (originatorFee / 100) * loanAmount;
}

export function getTotalUses(feesWorksheet, bid) {
  const purchasePrice = getPurchasePrice(feesWorksheet);
  const totalProjectCosts = getTotalProjectCost(feesWorksheet);
  const totalOneTimeFees = getTotalOneTimeFees(feesWorksheet);
  const lenderFee = getLenderFee(bid);
  const brokerFee = getBrokerFee(feesWorksheet, bid);

  if (bid.loan_purpose === REFINANCE) {
    return sum([totalProjectCosts, totalOneTimeFees, lenderFee, brokerFee]);
  }

  return sum([purchasePrice, totalProjectCosts, totalOneTimeFees, lenderFee, brokerFee]);
}

export function getDownPaymentCalculation(feesWorksheet, bid) {
  if (!isEmpty(feesWorksheet) && !isEmpty(bid)) {
    const totalUses = getTotalUses(feesWorksheet, bid);
    const closingCost = getClosingCost(feesWorksheet, bid);

    return `${numberToCurrency(totalUses)} - ${numberToCurrency(bid.loan_amount)} - ${numberToCurrency(
      closingCost,
    )} = ${numberToCurrency(getDownPayment(feesWorksheet, bid))}`;
  }
}

export function getPercentage(num1, num2) {
  if (num2 === 0) {
    return 0;
  }
  const percentage = (num1 / num2) * 100;
  return Math.round(percentage);
}
