import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';

function LenderDocsBreadcrumb() {
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link
          to={{
            pathname: '/onboarding/documents',
          }}
        >
          Documents
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Loan Application</Breadcrumb.Item>
    </Breadcrumb>
  );
}
export default LenderDocsBreadcrumb;
