import classNames from 'classnames';
import styles from '../QuoteTable.module.css';

function QuoteTableHeader({ term }) {
  const termText = term === 'Refinance' ? 'Remaining balance' : 'Loan Amount';

  return (
    <div className={styles.tableHeader}>
      <div className={classNames([styles.col, styles.head, styles.col1])}>Principal & Interest</div>
      <div className={classNames([styles.col, styles.head])}>Rate</div>
      <div className={classNames([styles.col, styles.head])}>Monthly payment</div>
      <div className={classNames([styles.col, styles.head, styles.col5])}>
        Total Uses - {termText} - Closing Cost = Down Payment
      </div>
    </div>
  );
}

export default QuoteTableHeader;
