import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';

export default GoogleApiWrapper({ apiKey: process.env.REACT_APP_MAPS_API_KEY })(({ geocoder, google, location }) => {
  const [coordinates, setCoordinates] = useState(null);

  useEffect(() => {
    if (!geocoder || !location) {
      setCoordinates(location);
    }

    const { city, address, zip, state } = location;

    Geocode.setApiKey(process.env.REACT_APP_MAPS_API_KEY);
    Geocode.setLocationType(state);

    process.env.REACT_APP_NODE_ENV === 'development' && Geocode.enableDebug();

    Geocode.fromAddress(`${zip} ${city} ${address} ${state}`).then(({ results }) => {
      const { lat, lng } = results[0].geometry.location;
      setCoordinates({ lat, lng });
    }, console.error);
  }, [geocoder, location]);

  if (!coordinates) {
    return null;
  }

  return (
    <Map
      center={coordinates}
      google={google}
      initialCenter={coordinates}
      mapType="roadmap"
      mapTypeControl={false}
      rotateControl={false}
      streetViewControl={false}
      tilt={0}
      zoom={18}
      zoomControl
    >
      <Marker position={coordinates} />
    </Map>
  );
});
