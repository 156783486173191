import { useCallback } from 'react';
import debounce from 'constants/debounce';
import larVariables from './larVariables';
import fieldsFormData from './fieldsFormData';

export const numValidator = (fieldObj, submit) => {
  const { value } = fieldObj;
  fieldObj.errors = [];

  if (value !== 0 && !value && submit) {
    fieldObj.errors = [
      {
        FICO: 'Please enter a score up to 850',
        main_borrower_flips: 'Please provide a figure',
        main_borrower_rental_properties: 'Please provide a figure',
      }[fieldObj.name] || 'Please input the field!',
    ];
  }

  if (fieldObj.name === 'current_payment' || fieldObj.name.includes('current_payment')) {
    if (value && value >= 50000) {
      fieldObj.errors.push('The monthly mortgage payment must be less than $50,000');
      return fieldObj;
    }
  }

  if (value?.toString()?.length > 10) {
    fieldObj.errors = ['Max length should be 10 symbols!'];
  }

  return fieldObj;
};

const textValidator = (fieldObj, submit, textLength = 254) => {
  const { value } = fieldObj;
  fieldObj.errors = [];

  if (!value && submit) {
    fieldObj.errors = [
      {
        address_all: 'Please enter an address',
      }[fieldObj.name] || 'Please input the field!',
    ];
  }

  if (value?.length > textLength) {
    fieldObj.errors = [`Max length should be ${textLength} symbols!`];
  }

  return fieldObj;
};

const dateValidator = (fieldObj, submit) => {
  const { value } = fieldObj;
  fieldObj.errors = [];

  if (!value && submit) {
    fieldObj.errors = ['Please choose the date!'];
  }

  return fieldObj;
};

const checkGroupValidator = (fieldObj, submit) => {
  const { value } = fieldObj;
  fieldObj.errors = [];

  if ((value?.length === 0 || !value) && submit) {
    fieldObj.errors = ['Select one or more options'];
  }

  return fieldObj;
};

const checkValidator = (fieldObj) => {
  const { value } = fieldObj;
  fieldObj.errors = [];

  if (value !== false && value !== true) {
    fieldObj.value = false;
  }

  return fieldObj;
};

const radioSelectValidator = (fieldObj, submit) => {
  const { value } = fieldObj;
  fieldObj.errors = [];

  if (!value && submit) {
    fieldObj.errors = [
      {
        borrower_entity_type_name: 'Please select an option',
        primary_home: 'Please select an option',
      }[fieldObj.name] || 'Please choose an option',
    ];
  }

  return fieldObj;
};

export const coBorrowersData = [
  {
    key: '1',
    first_name: '',
    fico: 350,
    last_name: '',
    relationships: 'Spouse',
  },
  {
    key: '2',
    first_name: '',
    fico: 350,
    last_name: '',
    relationships: 'Spouse',
  },
  {
    key: '3',
    first_name: '',
    fico: 350,
    last_name: '',
    relationships: 'Spouse',
  },
];

export const createLarOptions = {
  opportunityOptions: [
    {
      label: 'Long term',
      value: larVariables.loan_term_name.long,
    },
    {
      label: 'Short term',
      value: larVariables.loan_term_name.short,
    },
  ],
  purposeOptions: [
    {
      label: 'Purchase',
      value: larVariables.loan_purpose_name.purchase,
    },
    {
      label: 'Refinance',
      value: larVariables.loan_purpose_name.refinance,
    },
  ],
  useOptions: [
    {
      label: 'Business',
      value: larVariables.use.business,
    },
  ],
  refiOptions: [
    {
      label: 'Cash-out',
      value: larVariables.refi_loan_type_name.cash_out,
    },
    {
      label: 'Rate&Term',
      value: larVariables.refi_loan_type_name.rate_and_term,
    },
  ],
};

export const scrollToField = (formInstance, fieldName) => {
  formInstance.scrollToField(fieldName, {
    behavior: (actions) =>
      // list is sorted from innermost (closest parent to your target) to outermost (often the document.body or viewport)
      actions.forEach(({ el, top, left }) => {
        // implement the scroll anyway you want
        el.scrollTop = top - 200;
        el.scrollLeft = left;

        // If you need the relative scroll coordinates, for things like window.scrollBy styles logic or whatever, just do the math
        // const offsetTop = el.scrollTop - top
        // const offsetLeft = el.scrollLeft - left
      }),
    inline: 'start',
  });
};
export const createLarData = (formData) => {
  // {
  //     //broker_id: 32131, // for borrower, optional
  //     borrower_id: borrower_id, // for broker,
  //     loan_term_name: "Long Term", // 'allowed': ['Long Term', 'Short Term'] for refi - only long
  //     loan_purpose_name: "Purchase", // ['Refinance', 'Purchase']
  //     refi_loan_type_name: "Cash out", // 'allowed': ['Cash out', 'Rate & Term'] only for refi
  //     use: "Consumer", //  'allowed': ['Consumer', 'Business']
  //     //lar_id: 3213, // optional, for update
  // }
  const urlParams = new URLSearchParams(window.location.search);
  const data = {
    loan_term_name:
      formData.loan_purpose_name === larVariables.loan_purpose_name.refinance
        ? larVariables.loan_term_name.long
        : formData.loan_term_name,
    loan_purpose_name: formData.loan_purpose_name,
    refi_loan_type_name:
      formData.loan_purpose_name === larVariables.loan_purpose_name.refinance ? formData.refi_loan_type_name : null,
    use: formData.use,
  };

  const editValidate = (data) => {
    const lar_id = urlParams.get('lar_id');
    if (lar_id) {
      return {
        lar_id: +lar_id,
        ...data,
      };
    }
    return data;
  };

  const userValidate = (data) => {
    // const isBorrower = isRole("borrower", true)
    // const isBroker = isRole("broker", true)
    //
    const borrower_id = urlParams.get('borrower_id');
    if (borrower_id) {
      return {
        borrower_id: +borrower_id,
        ...data,
      };
    }
    //
    // if (isBorrower) {
    // }
    return data;
  };

  const validate = (data, ...validatedFunctions) =>
    validatedFunctions.reduce((acc, currentFunc) => currentFunc(acc), data);

  return validate(data, editValidate, userValidate);
};

export const getLarData = (data) => ({
  loan_purpose_name: data.data.loan_purpose_name,
  loan_term_name: data.data.loan_term_name,
  use: data.data.use,
  refi_loan_type_name: data.data.refi_loan_type_name,
});

export const fieldValidator = (
  formName,
  { name, value, errors },
  { isRefinance, isPurchaseLong, isPurchaseShort },
  values = null,
  submit = false,
  SA_values = {},
) => {
  const currentFormData = fieldsFormData[formName];
  if (!currentFormData) {
    return { name, value, errors };
  }

  const currentFieldData = currentFormData[name];
  const currentFieldType = currentFieldData?.type;

  if (currentFieldData?.customValidator) {
    return currentFieldData?.customValidator(
      formName,
      { name, value, errors },
      { isRefinance, isPurchaseLong, isPurchaseShort },
      values,
      submit,
      SA_values,
    );
  }

  if (currentFieldType === 'checkbox') {
    return checkValidator({ name, value, errors });
  }

  if (currentFieldType === 'date') {
    return dateValidator({ name, value, errors }, submit);
  }

  if (currentFieldType === 'checkboxGroup') {
    return checkGroupValidator({ name, value, errors }, submit);
  }

  if (currentFieldType === 'number') {
    return numValidator({ name, value, errors }, submit);
  }

  if (currentFieldType === 'radio' || currentFieldData?.type === 'select') {
    return radioSelectValidator({ name, value, errors }, submit);
  }

  if (currentFieldType === 'text') {
    return textValidator({ name, value, errors }, submit);
  }

  return { name, value, errors: [] };
};

export const useFieldsChange = (setSuccess, setProgress, name) => {
  // eslint-disable-next-line
  const debounceChange = useCallback(
    debounce((changedValues, allValues) => {
      const valuesArr = allValues.map((valueObj) => valueObj.value);
      const formSuccess = !valuesArr.includes(undefined) && !valuesArr.includes(null) && !valuesArr.includes('');
      if (changedValues.length !== 0) {
        setSuccess(formSuccess);
        setProgress((prevState) => ({
          ...prevState,
          [name]: formSuccess,
        }));
      }
    }),
    [setProgress, setSuccess],
  );

  return debounceChange;
};

export const useFieldsChangeNew = (
  getSuccessStatus,
  setSuccessStatus,
  onSuccess = () => null,
  onReject = () => null,
  getSuccessStatusLA,
  setSuccessStatusLA,
) => {
  // eslint-disable-next-line
  const debounceChange = useCallback(
    debounce((changedValues, allValues) => {
      if (changedValues.length === 0) {
        return;
      }
      const formSuccess = getSuccessStatus(allValues);
      const formSuccessLA = getSuccessStatusLA(allValues);
      setSuccessStatus(formSuccess);
      setSuccessStatusLA(formSuccessLA);
      if (formSuccess) {
        onSuccess();
      } else {
        onReject();
      }
    }, 50),
    [getSuccessStatus, getSuccessStatusLA, setSuccessStatus, setSuccessStatusLA, onSuccess, onReject],
  );
  return debounceChange;
};

export const getDefaultSuccess = (valuesArr) =>
  !valuesArr.includes(undefined) && !valuesArr.includes(null) && !valuesArr.includes('');

export const getSuccess = (fieldsArr, excludeFields = []) => {
  const filteredFields = fieldsArr.filter(
    (fieldObj) => !excludeFields.includes(fieldObj.name) && !excludeFields.includes(fieldObj.name[0]),
  );
  const valuesArr = filteredFields.map((fieldObj) => fieldObj.value);

  return !valuesArr.includes(undefined) && !valuesArr.includes(null) && !valuesArr.includes('');
};

export const onChangeValidator = (
  form,
  fieldsArr,
  formName,
  { isRefinance, isPurchaseLong, isPurchaseShort },
  SA_values = {},
) => {
  form.setFields(
    fieldsArr.map((el) =>
      fieldValidator(
        formName,
        {
          name: el.name,
          value: el.value,
          errors: el.errors,
        },
        { isRefinance, isPurchaseLong, isPurchaseShort },
        form.getFieldsValue(),
        false,
        SA_values,
      ),
    ),
  );
};
