import React from 'react';
import Button from '../../components/UI/Button';
import './styles/poster.css';

function AuthPoster({ title, btnText, btnClick }) {
  return (
    <div className="auth_poster">
      <p className="title">{title}</p>
      {btnText && <Button text={btnText} onClick={btnClick} className="btn_darkblue" />}
    </div>
  );
}

export default AuthPoster;
