import { Checkbox } from 'antd';
import { TickCircleIcon } from 'assets/icons';
import classNames from 'classnames';
import classes from './styles/Response.module.css';

function Yes({ label, selected }) {
  return (
    <div className={classNames({ [classes.yesSelected]: selected }, classes.yes)}>
      <TickCircleIcon colour={selected ? 'green-5' : undefined} empty={!selected} />
      {label}
    </div>
  );
}

export default function Response({
  data: {
    additional_information: additionalInformation,
    doc_type_1099_only: docType1099Only,
    doc_type_12_mo_statements: docType12MoStatements,
    doc_type_24_mo_statements: docType24MoStatements,
    doc_type_full: docTypeFull,
    doc_type_k_1_only: docTypeK1Only,
    doc_type_partial: docTypePartial,
    doc_type_VOE_only: docTypeVOEOnly,
    following_concerns: concerns,
    loan_program_ready: loanProgramReady,
    loan_type_DSCR: loanTypeDSCR,
    loan_type_DTI: loanTypeDTI,
    additional_comment: additionalComment,
  },
}) {
  return (
    <div className={classes.root}>
      <div>
        <Yes label="Yes, we are able to provide a loan program and/or terms" selected={loanProgramReady} />
        <Yes label="Yes, but we need more information" selected={!loanProgramReady} />
      </div>
      <div className={classes.loanTypes}>
        <h1>Loan types we would consider</h1>
        <div>Qualification</div>
        <div className={classes.qualificationItemsWrapper}>
          <div className={classNames({ [classes.qualificationItemSelected]: loanTypeDSCR }, classes.qualificationItem)}>
            DSCR
          </div>
          <div className={classNames({ [classes.qualificationItemSelected]: loanTypeDTI }, classes.qualificationItem)}>
            DTI
          </div>
        </div>
        {loanTypeDTI && (
          <>
            <div>Income Doc Typell</div>
            <ul className={classes.docTypeList}>
              <li>
                <Checkbox checked={docTypeFull}>Full Doc</Checkbox>
              </li>
              <li>
                <Checkbox checked={docTypePartial}>Partial Doc</Checkbox>
              </li>
              <li>
                <Checkbox checked={docType24MoStatements}>24 Mo. Bank Statements</Checkbox>
              </li>
              <li>
                <Checkbox checked={docType12MoStatements}>12 Mo. Bank Statements</Checkbox>
              </li>
              <li>
                <Checkbox checked={docTypeK1Only}>K-1 only</Checkbox>
              </li>
              <li>
                <Checkbox checked={docType1099Only}>1099 only</Checkbox>
              </li>
              <li>
                <Checkbox checked={docTypeVOEOnly}>VOE only</Checkbox>
              </li>
            </ul>
          </>
        )}
      </div>
      <div>
        <h1>Our concerns</h1>
        <div>{concerns}</div>
      </div>
      <div>
        <h1>Additional information we need</h1>
        <ol className={classes.additionalInfoList}>
          {additionalInformation.map((text, i) => (
            <li key={i}>{text}</li>
          ))}
        </ol>
        <p>{additionalComment}</p>
      </div>
    </div>
  );
}
