import './styles/communications.css';
import { Switch, message } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import Button from 'components/UI/Button';
import SectionTitle from 'components/OnBoarding/SectionTitle';

const initState = {
  borrowers_receive_lender_communications: false,
  borrowers_receive_loan_processing_communication: false,
  borrowers_receive_milestone_notifications: false,
};

export default function Communication({ regGet, regPost, resText, superAdminRole }) {
  const [checkboxes, setCheckboxes] = useState(initState);

  useEffect(() => {
    API()
      .get(regGet ?? '/user_settings/company/broker/communication')
      .then((res) => {
        setCheckboxes(res.data);
      });
  }, [regGet]);

  const onSwitch = (value, name) => {
    setCheckboxes((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetToDefault = () => {
    API()
      .post('/user_settings/company/broker/communication', { reset: true })
      .then((res) => {
        setCheckboxes((prev) => ({
          ...Object.fromEntries(
            Object.entries(prev).map(([key, value]) =>
              key.includes('borrowers_receive_') ? [key, true] : [key, value],
            ),
          ),
          ...res.data,
        }));
      })
      .then(() => {
        message.success('Successfully reset to default');
      });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    API()
      .post(regPost ?? '/user_settings/company/broker/communication', {
        borrowers_receive_lender_communications: !!checkboxes.borrowers_receive_lender_communications,
        borrowers_receive_loan_processing_communication: !!checkboxes.borrowers_receive_loan_processing_communication,
        borrowers_receive_milestone_notifications: !!checkboxes.borrowers_receive_milestone_notifications,
      })
      .then((res) => {
        setCheckboxes((prevState) => ({
          ...prevState,
          borrower_communication_last_update: res.data.borrower_communication_last_update,
        }));
        message.success(resText ?? 'Company profile successfully updated');
      });
  };

  return (
    <form className="wrapper_communications" onSubmit={onSubmit}>
      <SectionTitle title="Borrower Communication" />
      <div className="switches_wrapper">
        <div className="switch">
          <Switch
            checked={checkboxes.borrowers_receive_milestone_notifications}
            onChange={(e) => onSwitch(e, 'borrowers_receive_milestone_notifications')}
          />
          <div className="switch-text">
            <span className="switch-title">Borrowers receive milestone notifications</span>
            <span className="switch-subtitle">
              Miestones allow you to stay up to date on the progress from start to finish. <br />
              When this setting is off, then only the broker will receive milestone notifications
            </span>
          </div>
        </div>
        <div className="switch">
          <Switch
            checked={checkboxes.borrowers_receive_loan_processing_communication}
            onChange={(e) => onSwitch(e, 'borrowers_receive_loan_processing_communication')}
          />
          <div className="switch-text">
            <span className="switch-title">
              Borrowers receive loan processing communication, excluding lender communications
            </span>
            <span className="switch-subtitle">
              Loan processing communications regarding will be sent directly to the borrower and the broker will be
              copied on each message. <br />
              When this setting is "off", then only the broker will receive these communications
            </span>
          </div>
        </div>
        <div className="switch">
          <Switch
            checked={checkboxes.borrowers_receive_lender_communications}
            onChange={(e) => onSwitch(e, 'borrowers_receive_lender_communications')}
          />
          <div className="switch-text">
            <span className="switch-title">Borrowers receive lender communications</span>
            <span className="switch-subtitle">
              Lender communication will be sent directly to the borrower and the broker will be copied on each message.{' '}
              <br />
              Lender communications include initial loan decisions, revised loan quotes, final underwriting, loan
              documents, and any other lender underwriting requests. When this setting is "off", only the broker will
              receive lender communications.
            </span>
          </div>
        </div>
      </div>
      <div className="footer">
        {checkboxes.borrower_communication_last_update ? (
          <p className="update">
            Last Update <span>{checkboxes.borrower_communication_last_update}</span>
          </p>
        ) : null}
        <div className="wrapper_btn">
          <Button
            text="Reset to Default"
            onClick={() => (superAdminRole ? setCheckboxes(initState) : resetToDefault())}
          />
          <Button text="Save" className="btn_darkblue" type="submit" />
        </div>
      </div>
    </form>
  );
}
