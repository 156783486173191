import { useRef, useState } from 'react';
import { Input } from 'antd';
import Button from '../../UI/Button';
import TextInput from '../../CustomComponents/TextInput';

function PDFAddNew({ submitNewDocument }) {
  const [fields, setFields] = useState({
    regular_document: {
      value: null,
      link: null,
      error: false,
      errorMessage: 'Fill this field',
      required: true,
    },
    document_name: {
      value: '',
      error: false,
      errorMessage: 'Fill this field',
      required: true,
    },
    document_note: {
      value: '',
    },
  });
  const uploadRef = useRef();

  const changeField = (name, fields) => {
    setFields((state) => ({
      ...state,
      [name]: {
        ...state[name],
        ...fields,
        error: false,
      },
    }));
  };

  const submitDocument = (e) => {
    e.preventDefault();
    const request = {};
    let error = false;
    const tempFields = { ...fields };

    Object.entries(tempFields).forEach((i) => {
      if (i[1].required && !i[1].value) {
        error = true;
        tempFields[i[0]].error = true;
      } else {
        request[i[0]] = i[1].value;
      }
    });

    if (!error) {
      submitNewDocument(request);
    } else {
      setFields(tempFields);
    }
  };

  return (
    <form onSubmit={submitDocument} className="add-new-doc">
      <input
        type="file"
        ref={uploadRef}
        hidden
        onChange={(e) => {
          const file = e.target.files[0];

          changeField('regular_document', { value: file });

          // TODO: need to show regular document with the other documents
        }}
      />
      {fields.regular_document.value && (
        <div className="document">
          <span>
            Document:
            {fields.regular_document.value.name}
          </span>
        </div>
      )}
      <Button
        onClick={() => uploadRef.current.click()}
        className={`btn_darkblue ${fields.regular_document.error ? 'error' : ''}`}
        style={{ padding: '5px 48px' }}
        text="Browse"
      />
      <div className="add-new-form">
        <TextInput
          onChange={(e) => changeField('document_name', { value: e.target.value })}
          error={fields.document_name.error}
          placeholder="File Name"
          errorMessage="File Name cannot be empty"
          value={fields.document_name.value}
        />
        <Input.TextArea
          value={fields.document_note.value}
          rows={5}
          placeholder="Additional note"
          onChange={(e) => changeField('document_note', { value: e.target.value })}
        />
        <Button type="submit" className="btn_darkblue" style={{ padding: '5px 48px' }} text="Submit document" />
      </div>
    </form>
  );
}

export default PDFAddNew;
