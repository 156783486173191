import { numberFromComma } from 'constants/numberToComma';

const getProjectData = (projectForm) => {
  const projectFormData = projectForm.getFieldsValue();

  const calculatedLoanAmount = projectFormData.property_value * (projectFormData.LTV / 100);

  if (projectFormData.refi_cash_out === calculatedLoanAmount) {
    projectFormData.custom_amount_maximum_possible = true;
    projectFormData.refi_cash_out = null;
  }

  if (projectFormData.custom_amount_maximum_possible) {
    projectFormData.refi_cash_out = null;
  }

  if (projectFormData.custom_amount_maximum_possible === undefined && projectForm.refi_cash_out === undefined) {
    projectFormData.custom_amount_maximum_possible = false;
    projectFormData.refi_cash_out = null;
  }

  return {
    ...projectFormData,
    closing_date: projectFormData?.closing_date?.format('YYYY-DD-MM'),
  };
};

const getBorrowerFundsData = (borrowerFundsForm) =>
  Object.entries(borrowerFundsForm.getFieldsValue())
    .filter(([, valueObj]) => !!valueObj.funds_amount && !!valueObj.funds_individual)
    .map(([key, valueObj]) => ({
      source: key,
      value: valueObj.funds_amount,
      type: valueObj.funds_individual,
    }));

const getIncomeExpenseData = (incomeExpenseForm) =>
  Object.entries(incomeExpenseForm.getFieldsValue()).map(([key, valueObj]) => ({
    name: key,
    value: Math.floor(valueObj.value[valueObj.type]) || 0,
  }));

const getAddressData = (addressForm) => {
  const { address_all, ...addressFormData } = addressForm.getFieldsValue();
  return addressFormData;
};

const getBorrowerData = (borrowerForm) => {
  const borrowerFormData = borrowerForm.getFieldsValue();
  return {
    ...borrowerFormData,
    entity_formation_date:
      borrowerFormData?.entity_name === 'Existing' ? borrowerFormData?.entity_formation_date?.format('YYYY-MM') : null,
  };
};

const getCreditHistoryData = (creditHistoryForm) =>
  Object.fromEntries(
    Object.entries(creditHistoryForm.getFieldsValue()).map(([key, value]) => {
      if (key.includes('date')) {
        return [key, value?.format('YYYY-DD-MM') || null];
      }
      return [key, value];
    }),
  );

const getBusinessData = (businessForm) => {
  const businessFormData = businessForm.getFieldsValue() || {};

  return {
    ...businessFormData,
    questions_for_lender: businessFormData.questions_for_lender?.map((question) => ({ question })) || [],
  };
};

const getEntityOwnersData = (entityOwners, isIndividual) =>
  !isIndividual
    ? entityOwners.map((el) => ({
        first_name: el.first_name,
        last_name: el.last_name,
        signer: el.signer,
        percent_ownership: el.percent_ownership,
        credit_rating: el.fico,
        to_delete: el.to_delete,
      }))
    : [];

const getCoBorrowersData = (coBorrowers, isIndividual) =>
  isIndividual
    ? coBorrowers.map((el) => ({
        first_name: el.first_name,
        last_name: el.last_name,
        relationship: el.relationships,
        credit_rating: el.fico,
        to_delete: false,
      }))
    : [];

const getPropertyData = (propertyForm) => {
  const propertyData = propertyForm.getFieldsValue();

  if (propertyData.property_type === 'Other' && propertyData.property_type_other) {
    propertyData.property_type = propertyData.property_type_other;
    delete propertyData.property_type_other;
  }

  return propertyData;
};

export const getSaveData = (forms, entityOwners, coBorrowers, isIndividual, { isPurchaseShort }) => {
  const projectData = getProjectData(forms.project);
  const propertyData = getPropertyData(forms.property);
  const addressData = getAddressData(forms.address);
  const productData = forms.product.getFieldsValue();
  const borrowerFundsData = getBorrowerFundsData(forms.borrowerFunds);
  const incomeExpenseData = getIncomeExpenseData(forms.property_income_and_expense);
  const borrowerData = getBorrowerData(forms.borrower);
  const creditHistoryData = getCreditHistoryData(forms.credit_history);
  const businessData = getBusinessData(forms.business);
  const entityOwnersData = getEntityOwnersData(entityOwners, isIndividual);
  const coBorrowersData = getCoBorrowersData(coBorrowers, isIndividual);

  const data = {
    ...projectData,
    requesting_amount: Number(numberFromComma(projectData.requesting_amount)),
    ...propertyData,
    ...addressData,
    ...productData,
    funds: borrowerFundsData,
    incomes: incomeExpenseData,
    ...borrowerData,
    ...creditHistoryData,
    ...businessData,
    entity_owners: entityOwnersData,
    co_borrowers: coBorrowersData,
    broker_fee: forms.brokerFee.getFieldsValue().default_broker_fee_proposed,
  };

  if (isPurchaseShort) {
    delete data.incomes;
  }

  return data;
};
