import React, { useRef, useState } from 'react';
import './styles/onboarding_docs_every.css';
import { Modal } from 'antd';
import Button from '../../UI/Button';
import DocumentViewer from '../DocumentViewer';
import CustomButton from '../../UI/Button';

const iconUploadDoc = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 11V17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 14L12 11L15 14" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

function OnBoardingDocsEvery({ props, activeDocument }) {
  const ref = useRef();
  const [activeModal, setActiveModal] = useState({ show: null, data: null });

  const handleUploadFile = (event) => {
    event.preventDefault();

    if (!event.target.files[0]) {
      return;
    }
    props.uploadFile(event.target.files[0], activeDocument);
  };

  if (!activeDocument) {
    return null;
  }

  return (
    <div className="iharmoni_documents__disclosures">
      <div className="head">
        {/* <div className={'options'}> */}
        {/*    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
        {/*        <path */}
        {/*            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" */}
        {/*            stroke="#8C8C8C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> */}
        {/*        <path */}
        {/*            d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" */}
        {/*            stroke="#8C8C8C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> */}
        {/*        <path */}
        {/*            d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" */}
        {/*            stroke="#8C8C8C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> */}
        {/*    </svg> */}
        {/* </div> */}
        <span className="title">{activeDocument.title}</span>
        <span className="file_desc">{activeDocument?.updated_at ?? 'File is not uploaded yet'}</span>
        <div
          style={activeDocument?.path ? { gridTemplateColumns: 'repeat(2, max-content)' } : null}
          className="wrapper_upload_document"
        >
          <input type="file" ref={ref} accept="application/pdf" onChange={handleUploadFile} hidden />
          <Button
            text={activeDocument?.path ? 'Change document' : 'Upload document'}
            onClick={() => {
              if (activeDocument?.path) {
                setActiveModal({
                  show: 'new_upload',
                  data: null,
                });
              } else ref.current.click();
            }}
            className="btn_darkblue svg_no_fill"
            svg={iconUploadDoc}
          />
          {activeDocument?.path ? (
            <a className="btn_default btn_darkblue" href={activeDocument.path} download={activeDocument.name}>
              Download document
            </a>
          ) : null}
        </div>
      </div>
      <div className="body">
        <DocumentViewer activeDocument={{ url: activeDocument?.path }} />
      </div>
      <Modal
        className="wrapper_prospect wrapper_prospect_delete"
        title={
          <div className="wrapper_prospect__title">
            <span className="title">Overwrite File</span>
            <span className="description">Are you sure you want to overwrite file?</span>
            <span className="description">This action will overwrite existing files and cannot be undone.</span>
          </div>
        }
        visible={activeModal.show === 'new_upload'}
        onOk={() => setActiveModal({ show: null, data: null })}
        centered
        footer={
          <div className="wrapper_remove_prospect_btns">
            <CustomButton key="back" onClick={() => setActiveModal({ show: null, data: null })} text="Cancel" />
            <CustomButton
              key="submit"
              onClick={() => {
                ref.current.click();
                setActiveModal({ show: null, data: null });
              }}
              text="Confirm"
              className="btn_darkblue"
            />
          </div>
        }
        onCancel={() => setActiveModal({ show: null, data: null })}
      />
    </div>
  );
}

export default OnBoardingDocsEvery;
