import { getLARFields } from 'constants/stringReplaceData';
import { message as toast } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMergeFields } from 'constants/useMergeFields';
import API from 'api';
import Button from 'components/UI/Button';
import CustomTable from 'components/UI/CustomTable';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import TemplateEditor from 'components/TemplateEditor';
import untagify from 'constants/untagify';
import useNestedCheckError from 'constants/useNestedCheckError';

let initialState = {
  file: {
    value: null,
    file: null,
  },
  title: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
  message: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
};

const initialPageData = {
  action_required: '',
  delay: null,
  document_url: null,
  enabled: null,
  id: null,
  message: '',
  role_name: '',
  subject: '',
  template_type: '',
  trigger_id: null,
};

// TODO: pageData is passed as an argument here, no need to request /superadmin/trigger-config again
export default function BrowserNotificationTemplate() {
  const [fields, setFields] = useState(initialState);
  const [pageData, setPageData] = useState(initialPageData);
  const [disableRerender, setDisableRerender] = useState(false);
  const [configData, setConfigData] = useState({});
  const { checkEmpty } = useNestedCheckError(fields, setFields);
  const { configId, triggerId } = useParams();
  const history = useHistory();
  const ref = useRef();
  const [mergeValue, setMergeValue] = useMergeFields('');

  const handleUploadFile = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    setFields((prevState) => ({
      ...prevState,
      file: {
        ...prevState.file,
        value: URL.createObjectURL(file),
        file,
      },
    }));
    // props.uploadFile(event.target.files[0], activeDocument)
  };

  // const onChange = (event, name) => {
  //     setFields((prevState) => ({
  //         ...prevState,
  //         [name]: {
  //             ...prevState[name],
  //             error: false,
  //             value: event.target.value,
  //         },
  //     }))
  // }

  useEffect(() => {
    API()
      .get('/superadmin/trigger-config', {
        params: { config_id: configId },
      })
      .then((res) => {
        const { document_url, subject, message } = res.data;
        setPageData(res.data);
        initialState = {
          file: {
            ...initialState.file,
            value: document_url,
          },
          title: {
            ...initialState.title,
            value: subject,
          },
          message: {
            ...initialState.message,
            value: message,
          },
        };
        setFields(initialState);
      });
  }, [configId]);

  useEffect(() => {
    API()
      .get('/superadmin/triggers', { params: { trigger_id: triggerId } })
      .then((res) => {
        setConfigData(res.data.trigger);
      });
  }, [triggerId]);

  useEffect(() => {
    setMergeValue(fields?.message?.value);
    // eslint-disable-next-line
  }, [fields?.message?.value]);

  const saveConfig = (event) => {
    event.preventDefault();
    const { error, obj } = checkEmpty(fields);
    setFields(obj);

    if (error) {
      return;
    }

    const FD = new FormData();

    FD.append('id', configId);
    FD.append('subject', untagify(fields.title.value));
    FD.append('message', untagify(fields.message.value));
    if (fields.file.file) {
      FD.append('file', fields.file.file ?? fields.file.value);
    }

    API()
      .post('/superadmin/browser-config', FD)
      .then(() => {
        toast.success('Browser notification message saved');
      });
  };

  const sendNotification = () => {
    const { error, obj } = checkEmpty(fields);
    setFields(obj);

    if (error) {
      return;
    }
    showNotification(
      fields.title.value,
      fields.message.value,
      'http://localhost:3000/',
      'NotificationId1',
      fields.file.value,
    );
  };

  const showNotification = (header, msg, link, tag, icon) => {
    const notification = new Notification(header, {
      icon,
      // eslint-disable-next-line
      body: msg.split('${name}').join(`Username`),
      // link: link,
      // tag: tag
    });
    notification.onclick = function (event) {
      event.preventDefault();
      window.open(link, '_blank');
    };
  };

  return (
    <div className="lar_created_wrapper bnt_wrapper">
      <div className="wrapper_lar_create_team" style={{ marginBottom: 24 }}>
        <SectionTitle title="Browser Notification Message" />
      </div>
      <div className="trigger-info">
        <div>
          <span>ID:</span>
          <b>{pageData.id}</b>
        </div>
        <div>
          <span>Nickname:</span>
          <b>{configData.name}</b>
        </div>
        <div>
          <span>Recipient User:</span>
          <b>{pageData.role_name}</b>
        </div>
        <div>
          <span>Message Type:</span>
          <b>{pageData.template_type}</b>
        </div>
        <div>
          <span>Action:</span>
          <b>{pageData.action_required?.name}</b>
        </div>
        <div>
          <span>Updated at:</span>
          <b>{pageData.updated_at}</b>
        </div>
      </div>
      <CustomTable onSubmit={saveConfig}>
        <div className="wrapper_file" onClick={() => ref.current.click()}>
          {fields.file.value
            ? [<img src={fields.file.value} className="preview_file" key={1} alt="preview_file" />]
            : [
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" key={1}>
                  <path
                    d="M7.46863 1.37573H6.53113C6.44779 1.37573 6.40613 1.4174 6.40613 1.50073V6.40698H1.75024C1.66691 6.40698 1.62524 6.44865 1.62524 6.53198V7.46948C1.62524 7.55282 1.66691 7.59448 1.75024 7.59448H6.40613V12.5007C6.40613 12.5841 6.44779 12.6257 6.53113 12.6257H7.46863C7.55196 12.6257 7.59363 12.5841 7.59363 12.5007V7.59448H12.2502C12.3336 7.59448 12.3752 7.55282 12.3752 7.46948V6.53198C12.3752 6.44865 12.3336 6.40698 12.2502 6.40698H7.59363V1.50073C7.59363 1.4174 7.55196 1.37573 7.46863 1.37573Z"
                    fill="black"
                    fillOpacity="0.85"
                  />
                </svg>,
                <span key={2}>Upload graphics 360x220</span>,
              ]}
        </div>
        {/* input file */}
        <input
          type="file"
          ref={ref}
          accept="image/jpeg,image/jpg,image/png, .jpeg,.jpg,.png"
          onChange={handleUploadFile}
          hidden
        />
        {/* input file */}
        <TemplateEditor
          title="Title"
          text={fields.title.value}
          wrapperClassName="wrapper_template wrapper_template_input"
          toolbar={{ options: [] }}
          mention={{
            separator: ' ',
            trigger: '$',
            suggestions: getLARFields(configData.context, configData.merge_fields).map((i) => ({
              text: `{${i.field}}`,
              value: `{${i.field}}`,
            })),
          }}
          disableRerender={disableRerender}
          setDisableRerender={setDisableRerender}
          setText={(e) => {
            setFields((state) => ({
              ...state,
              title: {
                ...state.title,
                value: e,
              },
            }));
          }}
        />

        <TemplateEditor
          title="Message"
          wrapperClassName="wrapper_template wrapper_template_no-toolbar"
          text={fields.message.value}
          mention={{
            separator: ' ',
            trigger: '$',
            suggestions: getLARFields(configData.context, configData.merge_fields).map((i) => ({
              text: `{${i.field}}`,
              value: `{${i.field}}`,
            })),
          }}
          disableRerender={disableRerender}
          setDisableRerender={setDisableRerender}
          setText={(e) => {
            setFields((state) => ({
              ...state,
              message: {
                ...state.message,
                value: e,
              },
            }));
          }}
        />

        <div className="notification_message_preview">
          <span className="title">Notification message preview</span>
          <div className="preview">
            {/* eslint-disable-next-line */}
            <span dangerouslySetInnerHTML={{ __html: mergeValue }} />
          </div>
        </div>

        <div className="wrapper_form_btn four_btn">
          <Button
            text="Cancel"
            style={{ marginRight: 'auto' }}
            onClick={() => history.push(`/onboarding/ems/${triggerId}`)}
          />
          <Button text="Preview to me" onClick={sendNotification} />
          <Button text="Reset to default" onClick={() => setFields(initialState)} />
          <Button className="btn_darkblue" type="submit" text="Save" />
        </div>
      </CustomTable>
    </div>
  );
}
