import './styles/milestones.css';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import Button from 'components/UI/Button';
import TextInput from 'components/CustomComponents/TextInput';

export default function Milestones() {
  const [data, setData] = useState({ loading: false });

  useEffect(() => {
    API()
      .get('/superadmin/settings/milestones')
      .then((res) => {
        setData({
          loading: true,
          milestones: res.data.milestones,
        });
      });
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    API()
      .post('/superadmin/settings/milestones', { data: data.milestones })
      .then(() => {
        message.success('You successfully updated blackout days');
      })
      .catch(console.error);
  };

  const onChange = (event, item, name) => {
    const protoData = data.milestones.map((i) => {
      if (i.id === item.id) {
        return {
          ...i,
          [name]: event.target.value,
        };
      }
      return i;
    });

    setData((prevState) => ({ ...prevState, milestones: protoData }));
  };

  return (
    <form className="wrapper_milestones" onSubmit={onSubmit}>
      <div className="section_title">
        <span className="title">Milestones</span>
        <Button text="Save" type="submit" className="btn_darkblue" />
      </div>
      <div className="milestones_list">
        <div className="header">
          <span />
          <span>Milestone name</span>
          <span>Description</span>
        </div>
        <div className="body">
          {data.loading
            ? data.milestones.map((i, index) => (
                <div className="every" key={i.id}>
                  <span>{index + 1}</span>
                  <TextInput value={i.name} onChange={(e) => onChange(e, i, 'name')} />
                  <TextInput value={i.description} onChange={(e) => onChange(e, i, 'description')} />
                </div>
              ))
            : null}
        </div>
      </div>
    </form>
  );
}
