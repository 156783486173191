import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <path d="M12 20C15.866 20 19 16.866 19 13C19 9.13401 15.866 6 12 6C8.13401 6 5 9.13401 5 13C5 16.866 8.13401 20 12 20Z" />
    <path d="M12 10V13H14" />
    <path d="M7 4L4.25 6" />
    <path d="M17 4L19.75 6" />
  </GenericIcon>
));
