import { Modal } from 'antd';
import CustomButton from '../../UI/Button';

export default function MCMNotificationDeletionModal({ onOk, onCancel, visible }) {
  return (
    <Modal
      centered
      className="wrapper_prospect wrapper_prospect_delete"
      footer={
        <div className="wrapper_remove_prospect_btns">
          <CustomButton key="back" onClick={onCancel} text="Cancel" />
          <CustomButton className="btn_darkblue" key="submit" onClick={onOk} text="Confirm" />
        </div>
      }
      onCancel={onCancel}
      onOk={onCancel}
      title={
        <div className="wrapper_prospect__title">
          <span className="title">Delete notification</span>
          <span className="description">Are you sure you want to delete notification?</span>
        </div>
      }
      open={visible}
    />
  );
}
