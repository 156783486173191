import { CloseIcon } from 'assets/icons/icons';
import CustomButton from 'components/UI/Button';
import questionInputTypes from '../../constants/questionInputTypes';
import SelectWrapper from 'components/CustomComponents/SelectWrapper';
import TextInput from 'components/CustomComponents/TextInput';

function QuestionForm({ activeDocument, addQuestion, changeAddItemQuestion, removeQuestion }) {
  return (
    <>
      <span className="add-item-title">Question list</span>
      <div className="questions-list">
        {activeDocument.questionList.map((i, idx) => (
          <div className={`question-wrapper ${idx ? 'additional-question-wrapper' : ''}`} key={i}>
            <div>
              <TextInput
                error={activeDocument.questionList[idx].questionError}
                errorMessage={activeDocument.questionList[idx].errorMessage}
                onChange={({ target }) => {
                  changeAddItemQuestion('question_value', idx, target.value);
                }}
                title={`Question ${idx + 1}${idx ? '' : ' (Needed)'}`}
                value={activeDocument.questionList[idx].question_value}
              />
              {Boolean(idx) && (
                <CloseIcon
                  onClick={() => removeQuestion(idx)}
                  style={{
                    color: '#8C8C8C',
                    margin: 'auto 0 15px auto',
                  }}
                />
              )}
            </div>
            <div>
              <SelectWrapper
                options={questionInputTypes}
                size="large"
                title="Input type"
                onChange={(value) => {
                  changeAddItemQuestion('input_type_value', idx, value);
                }}
                className="bordered"
                error={activeDocument.questionList[idx].typeError}
                errorMessage={activeDocument.questionList[idx].errorMessage}
                value={activeDocument.questionList[idx].input_type_value}
              />
            </div>
          </div>
        ))}
        {activeDocument.questionList.length < 3 && (
          <CustomButton className="btn_darkblue" onClick={addQuestion} style={{ marginTop: 12 }} text="Add" />
        )}
      </div>
    </>
  );
}

export default QuestionForm;
