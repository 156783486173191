import { TimePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import CustomPicker from './CustomPicker';

function Picker({ name, data, onChangeDate, title, disabledHours, disabled, format, showFormat }) {
  return (
    <CustomPicker error={data[name]?.error} errorMessage={data[name]?.errorMessage}>
      <TimePicker
        onChange={(_, d) => {
          onChangeDate(moment(d, ['h:mm A']).format('H:mm'), title.toLowerCase(), name);
        }}
        use12Hours
        disabled={disabled}
        disabledTime={() => ({ disabledHours: () => disabledHours })}
        popupClassName="picker"
        onClick={() => {
          if (!showFormat) {
            const element = document.querySelectorAll('.ant-picker-content ul:last-of-type');
            if (element) {
              element.forEach((i) => {
                i.style.display = 'none';
              });
            } else {
              element.forEach((i) => {
                i.style.display = '';
              });
            }
          }
        }}
        allowClear={false}
        format={format ?? 'hh:mm'}
        value={data[name]?.value ? moment(data[name]?.value, format ?? 'h:mm') : ''}
      />
    </CustomPicker>
  );
}
export default Picker;
