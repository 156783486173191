import { Form, Radio, Spin, Input, Checkbox, message, Button } from 'antd';
import { useEffect, useState, useReducer, useMemo } from 'react';
import CustomButton from 'components/UI/Button';
import fiddyStates from 'constants/fiddyStates';
import { EditOutlined } from '@ant-design/icons';
import styles from './styles/NMLS.module.css';

const homeStates = fiddyStates;
const realEstateStates = ['AZ', 'CA', 'GA', 'HI', 'OR', 'SC', 'UT', 'VA'];

function NMLS({ API, company = false }) {
  const [data, setData] = useState({});
  const [editMode, toggleEditMode] = useReducer((s) => !s, false);
  const [emptyExactStatesErrors, setEmptyExactStatesErrors] = useState([false, false]);
  const [form] = Form.useForm();
  const [homeStatesVisible, setHomeStatesVisible] = useState(false);
  const [idRequired, setIDRequired] = useState([false, false]);
  const [loading, setLoading] = useState(false);
  const [realEstateStatesVisible, setRealEstateStatesVisible] = useState(false);

  function submitForm({ nmls, homeStates, realEstateStates, ...selectedStates }) {
    // Some additional validation
    const newEmptyExactStatesErrors = [
      homeStates === 'selected_states' &&
        !Object.entries(selectedStates).find(([key, value]) => /^home/.test(key) && value),
      realEstateStates === 'selected_states' &&
        !Object.entries(selectedStates).find(([key, value]) => /^realEstate/.test(key) && value),
    ];
    setEmptyExactStatesErrors(newEmptyExactStatesErrors);
    if (newEmptyExactStatesErrors.includes(true)) {
      return;
    }

    setLoading(true);

    const selectedHomeStates =
      homeStates === 'selected_states'
        ? Object.entries(selectedStates).flatMap(([key, value]) =>
            /^home[A-Z]+/.test(key) && value ? [key.slice(-2)] : [],
          )
        : undefined;

    const selectedRealEstateStates =
      realEstateStates === 'selected_states'
        ? Object.entries(selectedStates).flatMap(([key, value]) =>
            /^realEstate[A-Z]+/.test(key) && value ? [key.slice(-2)] : [],
          )
        : undefined;

    API.updateNMLSSettings(
      Number(nmls) || undefined,
      homeStates,
      realEstateStates,
      selectedHomeStates,
      selectedRealEstateStates,
      !company,
    )
      .then(() => {
        message.success('NMLS settings successfully updated!');
        setData({
          home_states: homeStates,
          nmls: Number(nmls) || '',
          real_estate_states: realEstateStates,
          selected_home_states: selectedHomeStates,
          selected_real_estate_states: selectedRealEstateStates,
        });
        toggleEditMode();
      })
      .catch(() => {
        message.error("Couldn't update NMLS settings!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (editMode) {
      return;
    }

    form.resetFields();
  }, [editMode, form]);

  useEffect(() => {
    setLoading(true);

    API.getNMLSSettings(!company).then((sData) => {
      const data = {
        ...sData,
        home_states: sData.home_states || 'not_applicable',
        nmls: sData.nmls || undefined,
        real_estate_states: sData.real_estate_states || 'not_applicable',
      };

      setData(data);
      setHomeStatesVisible(data.home_states === 'selected_states');
      setRealEstateStatesVisible(data.real_estate_states === 'selected_states');
      setIDRequired([data.home_states !== 'not_applicable', data.real_estate_states !== 'not_applicable']);
      setLoading(false);
    });
  }, [API, company]);

  const formDefault = useMemo(() => {
    if (Object.keys(data).length <= 0) {
      return {};
    }

    return {
      ...Object.fromEntries(homeStates.map((state) => [`home${state}`, data.selected_home_states?.includes(state)])),
      ...Object.fromEntries(
        realEstateStates.map((state) => [`realEstate${state}`, data.selected_real_estate_states?.includes(state)]),
      ),
      homeStates: data.home_states,
      nmls: data.nmls,
      realEstateStates: data.real_estate_states,
    };
  }, [data]);

  function handleFormChange([{ name, value }]) {
    if (name[0] === 'homeStates') {
      setHomeStatesVisible(value === 'selected_states');
      setIDRequired((prev) => [value !== 'not_applicable', prev[1]]);
      return;
    }

    if (name[0] === 'realEstateStates') {
      setRealEstateStatesVisible(value === 'selected_states');
      setIDRequired((prev) => [prev[0], value !== 'not_applicable']);
    }
  }

  return (
    <div className={styles.root}>
      <h1>{company ? 'Company' : 'Personal'} NMLS Information</h1>
      {editMode || (
        <Button icon={<EditOutlined />} onClick={toggleEditMode}>
          Edit
        </Button>
      )}
      <Spin spinning={loading}>
        <div className={styles.main}>
          {editMode ? (
            <Form
              onFieldsChange={handleFormChange}
              form={form}
              initialValues={formDefault}
              layout="vertical"
              onFinish={submitForm}
            >
              <Form.Item
                label={`${company ? 'Company' : 'Personal'} NMLS ID`}
                name="nmls"
                rules={[
                  {
                    required: idRequired.includes(true),
                    message: 'Please enter your NMLS ID',
                  },
                  {
                    pattern: /^[0-9]{5,7}$/,
                    message: 'NMLS ID should only consist of digits and be at least 5 symbols long',
                  },
                ]}
              >
                <Input maxLength={7} />
              </Form.Item>
              <h2>Residential Real Estate Mortgage</h2>
              <Form.Item name="homeStates" rules={[{ required: true }]}>
                <Radio.Group
                  options={[
                    {
                      label: 'Licensing not required or not applicable',
                      value: 'not_applicable',
                    },
                    {
                      label: 'Licensed in all 50 states',
                      value: 'all_states',
                    },
                    {
                      label: 'Licensed in the following states',
                      value: 'selected_states',
                    },
                  ]}
                />
              </Form.Item>
              {homeStatesVisible && (
                <div className={styles.stateBox}>
                  {homeStates.map((state) => (
                    <Form.Item key={state} name={`home${state}`} valuePropName="checked">
                      <Checkbox>{state}</Checkbox>
                    </Form.Item>
                  ))}
                  {emptyExactStatesErrors[0] && (
                    <div className={styles.customError}>Please select at least one state.</div>
                  )}
                </div>
              )}
              <h2>Commercial Real Estate Mortgages</h2>
              <Form.Item name="realEstateStates" rules={[{ required: true }]}>
                <Radio.Group
                  options={[
                    {
                      label: 'Licensing not required or not applicable',
                      value: 'not_applicable',
                    },
                    {
                      label:
                        'Able to originate loans in all states that do not require licensing and we are licensed in the following states which do require licensing for commercial real estate mortgage originations',
                      value: 'selected_states',
                    },
                  ]}
                />
              </Form.Item>
              {realEstateStatesVisible && (
                <div className={styles.stateBox}>
                  {realEstateStates.map((state) => (
                    <Form.Item key={state} name={`realEstate${state}`} valuePropName="checked">
                      <Checkbox>{state}</Checkbox>
                    </Form.Item>
                  ))}
                  {emptyExactStatesErrors[1] && (
                    <div className={styles.customError}>Please select at least one state.</div>
                  )}
                </div>
              )}
              <CustomButton text="Cancel" onClick={toggleEditMode} />
              <CustomButton primary text="Save changes" onClick={() => form.submit()} />
            </Form>
          ) : (
            <>
              <div>
                <div className={styles.multiRow}>
                  <div>{company ? 'Company' : 'Personal'} NMLS ID</div>
                  <div>{data.nmls || ''}</div>
                </div>
              </div>
              <div>
                <div className={styles.multiRow}>
                  <div>Residential Real Estate Mortgage</div>
                  <div>Licensed in following states:</div>
                  <div>
                    {data.home_states === 'not_applicable'
                      ? 'Not applicable'
                      : data.home_states === 'all_states'
                      ? 'All 50 states'
                      : data.selected_home_states?.join(', ')}
                  </div>
                </div>
                <div className={styles.multiRow}>
                  <div>Commercial Real Estate Mortgages</div>
                  <div>Licensed in the following states:</div>
                  <div>
                    {data.real_estate_states === 'not_applicable'
                      ? 'Not applicable'
                      : data.real_estate_states === 'all_states'
                      ? 'All 50 states'
                      : data.selected_real_estate_states?.join(', ')}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Spin>
    </div>
  );
}

export default NMLS;
