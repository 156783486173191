import { Input } from 'antd';
import { isObject } from 'lodash';
import styles from './styles/PhoneInput.module.css';

function PhoneInput({ error, errorMessage, onChange, title, isObjectEvent = false, ...rest }) {
  function handleChange(e) {
    if (isObjectEvent && isObject(e)) {
      onChange(e);
      return;
    }

    const value = isObject(e) ? e.target.value : e;
    const valueToNumber = Number(value);
    if (!isNaN(valueToNumber)) {
      onChange(isObject(e) ? e.target.value : e);
    }
  }

  return (
    <div className={styles.root}>
      {title && <label className="input_title">{title}</label>}
      <Input addonBefore="+1" maxLength={10} onChange={handleChange} {...rest} />
      {error && <p className="errorMessage">{errorMessage}</p>}
    </div>
  );
}

export default PhoneInput;
