import { DatePicker, Divider, Form, Radio, Select, Space } from 'antd';
import moment from 'moment';
import otherConst from 'constants/otherConst';
import EntityOwnersTable from './EntityOwnersTable';
import BorrowerIndividualForm from '../BorrowerIndividualForm/BorrowerIndividualForm';

export default function BorrowerEntityForm({
  dataSource,
  setDataSource,
  form,
  main_borrower_first_name,
  main_borrower_last_name,
  onFieldsChange,
  isPurchaseShort,
}) {
  const entity_name = Form.useWatch('entity_name', form);

  const setFieldAsync = async (name, value) => {
    await form.setFields([
      {
        name,
        value,
      },
    ]);
  };

  return (
    <>
      <Form.Item name="entity_name" label="Entity status">
        <Radio.Group
          onChange={(e) => {
            setFieldAsync(
              'entity_formation_date',
              e.target.value === 'To be formed' ? null : moment(new Date(), 'YYYY-MM'),
            ).then(() => {
              const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
                name: el[0],
                value: el[1],
              }));
              onFieldsChange([1], valuesArr);
            });
          }}
        >
          <Space direction="vertical" size="large">
            <Radio value="Existing">Existing</Radio>
            <Radio value="To be formed">To be formed</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Entity type"
        name="entity_type"
        className={!isPurchaseShort && 'scenario-required-field auction-required-field'}
      >
        <Radio.Group>
          <Space direction="vertical" size="large">
            <Radio value="LLC">LLC</Radio>
            <Radio value="C-Corp">C-Corp</Radio>
            <Radio value="Trust">Trust</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {entity_name === 'Existing' && (
        <Form.Item
          required
          label="Formation date"
          name="entity_formation_date"
          initialValue={moment(new Date(), 'YYYY-MM')}
        >
          <DatePicker
            picker="month"
            style={{ width: 215 }}
            disabledDate={(currentDate) => currentDate.isAfter(moment(new Date(), 'YYYY-MM'))}
          />
        </Form.Item>
      )}
      <Form.Item label="Formation state" name="business_state">
        <Select style={{ width: 120 }}>
          {otherConst.states.map((el) => (
            <Select.Option key={`${el.value}business_state`} value={el.value} />
          ))}
        </Select>
      </Form.Item>
      <h3>Entity owners</h3>
      <EntityOwnersTable
        main_borrower_first_name={main_borrower_first_name}
        main_borrower_last_name={main_borrower_last_name}
        dataSource={dataSource}
        setDataSource={setDataSource}
      />
      <Divider />
      <h3>
        <b>Signer (Point of contact)</b>
      </h3>
      <BorrowerIndividualForm form={form} />
    </>
  );
}
