import styles from './Popup.module.css';
import { numberToCurrency } from '../../../../constants/numberToComma';

function PopupTable({ title, data }) {
  const sum = data.reduce((acc, cur) => acc + Number(cur.value), 0);

  return (
    <div>
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.list}>
        {data.map((item) => (
          <div className={styles.item}>
            <div>{item.label}</div>
            <div>{numberToCurrency(item.value)}</div>
          </div>
        ))}
        <div className={styles.item}>
          <div>Total {title}</div>
          <div className={styles.sum}>{numberToCurrency(sum)}</div>
        </div>
      </div>
    </div>
  );
}

export default PopupTable;
