import BorrowerExpired from '../../pages/Registration/Borrower/Expired';
import BorrowerRegistration from '../../pages/Registration/Borrower/BorrowerRegistration';
import InviteCanceled from '../../pages/Registration/Borrower/InviteCanceled';
import { REGISTRATION_BORROWER_INVITE_CANCELED } from '../../constants/routes';

export const mainNavigationRegisterBorrower = [
  {
    exact: true,
    path: '/registration/borrower/confirm',
    main: () => <BorrowerRegistration />,
  },
  {
    exact: true,
    path: '/registration/borrower/expired',
    main: () => <BorrowerExpired />,
  },
  {
    exact: true,
    path: REGISTRATION_BORROWER_INVITE_CANCELED,
    main: () => <InviteCanceled />,
  },
];
