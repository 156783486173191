import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import API from 'api';
import { setChatState } from 'store/actions/userActions';

export const columns = [
  {
    title: 'Users',
    dataIndex: 'users',
    key: 'users',
  },
  {
    title: 'User Role',
    dataIndex: 'roles',
    key: 'roles',
  },
  {
    title: 'Last Message',
    dataIndex: 'last_message',
    key: 'last_message',
  },
];

export default function MessagingLogsTable() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    activity_logs: [],
    total_count: 0,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (p) => {
    setPagination((state) => ({ ...state, ...p }));
  };

  function handleOpenChat(chatId) {
    dispatch(setChatState({ chat_id: chatId, lar_id: id, type: 'sidebar', hideInput: true, mode: 'viewOnly' }));
  }

  useEffect(() => {
    setLoading(true);
    API()
      .get('/LAR/dashboard/superadmin/chat_log', {
        params: {
          lar_id: id,
          page: pagination.current,
          per_page: pagination.pageSize,
        },
      })
      .then((res) => setData(res.data))
      .finally(() => setLoading(false));
  }, [id, pagination]);

  return (
    <>
      <h2>Messaging Logs</h2>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data.activity_logs}
        onChange={handleChange}
        onRow={(record) => ({
          onClick: () => handleOpenChat(record.chat_id),
        })}
        pagination={{
          current: pagination.current,
          showSizeChanger: true,
          total: pagination.total,
        }}
      />
    </>
  );
}
