import React from 'react';
import OnboardingSuperSettingsGeneral from '../../pages/Onboarding/SuperAdmin/Settings/General';
import OnboardingSuperSettingsLARCreation from '../../pages/Onboarding/SuperAdmin/Settings/LARCreation';
import OnboardingSuperSettingsTeam from '../../pages/Onboarding/SuperAdmin/Settings/Team';
import OnboardingSuperSettingsInvitesAuction from '../../pages/Onboarding/SuperAdmin/Settings/InvitesAuction';
// import OnboardingSuperSettingsDiligence from "../../pages/Onboarding/SuperAdmin/Settings/Diligence"
import OnboardingSuperSettingsMandates from '../../pages/Onboarding/SuperAdmin/Settings/Mandates';
import OnboardingSuperSettingsReminders from '../../pages/Onboarding/SuperAdmin/Settings/Reminders';
import OnboardingSuperSettingsBankFeeInfo from '../../pages/Onboarding/SuperAdmin/Settings/BankFeeInfo';
import OnboardingSuperSettingsBankFeeInfoEdit from '../../pages/Onboarding/SuperAdmin/Settings/BankFeeInfoEdit';
import OnboardingSuperSettingsCategories from '../../pages/Onboarding/SuperAdmin/Settings/Categories';
import OnboardingSuperSettingsMilestones from '../../pages/Onboarding/SuperAdmin/Settings/Milestones';
import OnboardingSuperSettingsScoring from '../../pages/Onboarding/SuperAdmin/Settings/Scoring';

export const mainNavigationSuperSettings = [
  {
    exact: true,
    label: 'General',
    path: '/onboarding/admin-settings/general',
    pathname: 'general',
    main: () => <OnboardingSuperSettingsGeneral />,
  },
  {
    exact: true,
    label: 'LAR Creation',
    path: '/onboarding/admin-settings/lar_creation',
    pathname: 'lar_creation',
    main: () => <OnboardingSuperSettingsLARCreation />,
  },
  {
    exact: true,
    label: 'Team',
    path: '/onboarding/admin-settings/team',
    pathname: 'team',
    main: () => <OnboardingSuperSettingsTeam />,
  },
  {
    exact: true,
    label: 'Invites/Auction',
    path: '/onboarding/admin-settings/invites_auction',
    pathname: 'invites_auction',
    main: () => <OnboardingSuperSettingsInvitesAuction />,
  },
  // {
  //     exact: true,
  //     label: "Diligence",
  //     path: "/onboarding/admin-settings/diligence",
  //     pathname: "diligence",
  //     main: () => <OnboardingSuperSettingsDiligence />,
  // },
  {
    exact: true,
    label: 'Mandates Reminders',
    path: '/onboarding/admin-settings/mandates_reminders',
    pathname: 'mandates_reminders',
    main: () => <OnboardingSuperSettingsMandates />,
  },
  {
    exact: true,
    label: 'Reminders',
    path: '/onboarding/admin-settings/reminders',
    pathname: 'reminders',
    main: () => <OnboardingSuperSettingsReminders />,
  },
  {
    exact: true,
    label: 'Bank & Fee info',
    path: '/onboarding/admin-settings/bank_fee_info',
    pathname: 'bank_fee_info',
    main: () => <OnboardingSuperSettingsBankFeeInfo />,
  },
  {
    exact: true,
    path: '/onboarding/admin-settings/bank_fee_info/edit',
    pathname: 'bank_fee_info',
    main: () => <OnboardingSuperSettingsBankFeeInfoEdit />,
  },
  {
    exact: true,
    label: 'Categories',
    path: '/onboarding/admin-settings/categories',
    pathname: 'categories',
    main: () => <OnboardingSuperSettingsCategories />,
  },
  {
    exact: true,
    label: 'Milestones',
    path: '/onboarding/admin-settings/milestones',
    pathname: 'milestones',
    main: () => <OnboardingSuperSettingsMilestones />,
  },
  {
    exact: true,
    label: 'Scoring',
    path: '/onboarding/admin-settings/scoring',
    pathname: 'scoring',
    main: () => <OnboardingSuperSettingsScoring />,
  },
];
