import { Button, message } from 'antd';
import { LARAPI } from 'api';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLARDBContext } from 'pages/Onboarding/LARDB/hooks/LARDBContext';
import ReassignLARModal from 'components/LoanProfile/ReassignLARModal';
import classes from './styles/Reassign.module.css';

export default function Reassign() {
  const history = useHistory();
  const [reassignLARModalOpen, setReassignLARModalOpen] = useState(false);
  const [team, setTeam] = useState([]);
  const { lar_id: id, broker_name: brokerName, broker_id: brokerId } = useLARDBContext();
  const { role } = useSelector((s) => s.user.userData);
  const isAdmin = /Lender Admin|Broker Admin/i.test(role);

  const API = useMemo(() => new LARAPI(id), [id]);

  useEffect(() => {
    API.getTeam().then((data) => setTeam(data));
  }, [API]);

  function handleReassign(data) {
    return API.reassign(data.userId, data.message, 'broker')
      .then(() => {
        message.success(
          isAdmin
            ? 'Deal is successfully reassigned. You are Admin, so you still can see the deal'
            : 'LAR successfully reassigned!',
          isAdmin ? 5 : 3,
        );
        history.push('/onboarding/dashboard');
      })
      .catch(() => message.success('Failed to reassign LAR'));
  }

  return (
    <>
      <b>Reassign</b>
      <div className={classes.content}>
        <div>
          Current loan manager:
          <b>{brokerName}</b>
        </div>
        <div>
          <Button onClick={() => setReassignLARModalOpen(true)}>Reassign</Button>
        </div>
      </div>
      <ReassignLARModal
        team={team}
        visible={reassignLARModalOpen}
        brokerId={brokerId}
        onReassignLar={handleReassign}
        onClose={() => setReassignLARModalOpen(false)}
      />
    </>
  );
}
