import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Box.module.css';

function Box({ ver, hor, children }) {
  return <div className={classNames(styles.box, styles[`ver-${ver}`], styles[`hor-${hor}`])}>{children}</div>;
}

export default Box;

Box.defaultProps = {
  ver: 'md',
  hor: 'md',
};

Box.propTypes = {
  ver: PropTypes.oneOf(['xs', 'sm', 'md']),
  hor: PropTypes.oneOf(['xs', 'sm', 'md']),
};
