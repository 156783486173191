import helper from './helper';

export function isRole(role, includes = false) {
  const userData = JSON.parse(localStorage.getItem(helper.USER_DATA));
  const currentUserRole = userData?.role?.toLowerCase();

  console.warn(
    'A legacy localStorage-reliant function isRole is being used. ' +
      'For performance reasons, please consider using data from store.user.userData?.role instead.',
  );

  return includes ? currentUserRole?.includes(role?.toLowerCase()) : currentUserRole === role?.toLowerCase();
}
