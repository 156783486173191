import {
  FETCH_BORROWERS_ACTIVE_ERROR,
  FETCH_BORROWERS_ACTIVE_LOADING,
  FETCH_BORROWERS_ACTIVE_SUCCESS,
  FETCH_BORROWERS_PROSPECTS_ERROR,
  FETCH_BORROWERS_PROSPECTS_LOADING,
  FETCH_BORROWERS_PROSPECTS_SUCCESS,
  SELECT_BORROWER,
  REMOVE_SELECTED_BORROWER,
} from 'store/types/users';

const initialState = {
  data: {
    borrowers: {
      prospects: [],
      active: [],
      selected: null,
    },
  },
  error: null,
  loading: false,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BORROWERS_PROSPECTS_LOADING:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FETCH_BORROWERS_PROSPECTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_BORROWERS_PROSPECTS_SUCCESS:
      return {
        ...state,
        data: {
          borrowers: {
            ...state.data.borrowers,
            prospects: action.payload,
          },
        },
        loading: false,
        error: null,
      };
    case FETCH_BORROWERS_ACTIVE_LOADING:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FETCH_BORROWERS_ACTIVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_BORROWERS_ACTIVE_SUCCESS:
      return {
        ...state,
        data: {
          borrowers: {
            ...state.data.borrowers,
            active: action.payload,
          },
        },
        loading: false,
        error: null,
      };
    case SELECT_BORROWER:
      return {
        ...state,
        data: {
          borrowers: {
            ...state.data.borrowers,
            selected: action.payload,
          },
        },
      };
    case REMOVE_SELECTED_BORROWER:
      return {
        ...state,
        data: {
          borrowers: {
            ...state.data.borrowers,
            selected: null,
          },
        },
      };
    default:
      return state;
  }
};

export default usersReducer;
