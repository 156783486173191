import './styles/prospects.css';
import { BinIcon, ChevronIcon, PencilIcon, PlusIcon, SendIcon } from 'assets/icons';
import { formatPhone } from 'constants/formatPhone';
import { HorizontalDotsIcon } from 'assets/icons/icons';
import { Table, Space, Button, Tabs, Dropdown, Menu, message } from 'antd';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import AddProspectModal from 'components/OnBoarding/Prospects/AddProspectModal';
import classNames from 'classnames';
import CustomButton from 'components/UI/Button';
import legacyAPI, { BDRAPI } from 'api';
import ManualEMSModal from 'components/Modals/ManualEMSModal';
import Modal from 'components/Modals/Modal/Modal';
import PaginationItemRender from 'constants/PaginationItemRender';
import SearchBox from 'components/OnBoarding/BDR/SearchBox';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import styles from './styles/Prospects.module.css';

class Column {
  constructor(label, key, sorting, setSorting, render, hasSorting = true) {
    this.title = hasSorting ? (
      <div
        className={styles.tableColumnHeader}
        onClick={() => {
          setSorting((prev) => ({
            column: key,
            direction: key === prev.column ? (prev.direction === 'asc' ? 'desc' : 'asc') : 'desc',
          }));
        }}
      >
        {label}
        {key === sorting.column && <ChevronIcon direction={sorting.direction === 'asc' ? 'up' : 'down'} />}
      </div>
    ) : (
      <div className={classNames([styles.tableColumnHeader, styles.tableColumnHeaderWithoutSorting])}>{label}</div>
    );
    this.dataIndex = key;
    this.render = render;
  }
}

function ProspectActionsMenu({ rowData, setActiveModal, activeTab }) {
  return (
    <Menu
      items={[
        {
          label: 'Send invite as an individual profile',
          disabled: !rowData.actions.includes('Invite'),
          onClick: () => {
            setActiveModal({
              show: 'send_an_invite',
              data: {
                user_type: activeTab,
                ...rowData,
              },
            });
          },
          icon: <SendIcon />,
        },
      ]}
    />
  );
}

function Prospects() {
  const [activeModal, setActiveModal] = useState({ show: null, data: null });
  const [activeTab, setActiveTab] = useState('broker');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [prospects, setProspects] = useState({ users: [], total: 1 });
  const [sorting, setSorting] = useState({ column: 'id', direction: 'desc' });
  const history = useHistory();

  const API = useMemo(() => new BDRAPI(), []);

  const getUsers = useCallback(
    () =>
      API.getUsers(false, activeTab, pagination.current, pagination.pageSize, sorting.column, sorting.direction).then(
        (data) => {
          setProspects(data);
        },
      ),
    [activeTab, pagination, sorting, API],
  );
  useEffect(getUsers, [getUsers]);

  const customList = useMemo(() => {
    if (prospects.users.length) {
      return prospects.users.map((i) => {
        const statusColor =
          i.status &&
          (i.status.includes('Incomplete') || i.status.includes('Verification') || i.status.includes('Invitation')
            ? {
                bg: 'rgba(35, 120, 4, 1)',
                color: 'rgba(217, 247, 190, 1)',
              }
            : i.status === 'Approval'
            ? { color: 'rgba(173, 104, 0, 1)', bg: '#FFF1B8' }
            : i.status.includes('Prospect')
            ? { bg: '#F0F0F0', color: 'rgba(75, 77, 81, 1)' }
            : null);

        return {
          ...i,
          key: i.id,
          full_name: i.full_name,
          phone_number: i.phone,
          email: i.email,
          invite_sent_at: i?.invite_sent_at,
          status: (
            <Space size="large">
              <p
                className="status"
                style={{
                  background: statusColor?.bg,
                  color: statusColor?.color,
                }}
              >
                {i?.status ?? 'NONE'}
              </p>
              <div className="wrapper_right_side_panel">
                {i.actions?.map((a, index) => (
                  <Button
                    key={index}
                    type="text"
                    onClick={(event) => {
                      event.stopPropagation();
                      if (a === 'Cancel Invite') {
                        setActiveModal({
                          show: 'cancel_invite',
                          data: {
                            user_type: activeTab,
                            ...i,
                          },
                        });
                      } else if (a === 'View' || a === 'Edit') {
                        history.push(`/registration/${activeTab}/company?id=${i.id}`);
                      } else if (a === 'Resend') {
                        setActiveModal({
                          show: 'send_a_reminder',
                          data: {
                            user_type: activeTab,
                            ...i,
                          },
                        });
                      } else if (a === 'Approve') {
                        setActiveModal({
                          show: 'approve_prospect',
                          data: {
                            user_type: activeTab,
                            ...i,
                          },
                        });
                      } else if (a === 'Invite') {
                        setActiveModal({
                          show: 'send_an_invite',
                          data: {
                            user_type: activeTab,
                            ...i,
                          },
                        });
                      }
                    }}
                  >
                    {a}
                  </Button>
                ))}
                <BinIcon
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveModal({
                      show: 'delete_prospect',
                      data: i,
                    });
                  }}
                />
                <PencilIcon
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveModal({
                      show: 'change_prospect',
                      data: {
                        user_type: activeTab,
                        ...i,
                      },
                    });
                  }}
                />
                {activeTab === 'broker' && (
                  <Dropdown
                    overlay={<ProspectActionsMenu activeTab={activeTab} rowData={i} setActiveModal={setActiveModal} />}
                    trigger="click"
                    overlayClassName="prospect-actions-dropdown"
                    placement="bottomLeft"
                  >
                    <HorizontalDotsIcon style={{ color: '#8c8c8c' }} />
                  </Dropdown>
                )}
              </div>
            </Space>
          ),
        };
      });
    }
  }, [prospects, activeTab, history]);

  function deleteProspects() {
    legacyAPI()
      .delete('/prospects/BDR', {
        params: {
          id: activeModal.data.id,
        },
      })
      .then(() => {
        setProspects((prev) => ({
          ...prev,
          users: prev.users.filter((i) => i.id !== activeModal.data.id),
        }));

        closeActiveModal();
        message.success('Prospect successfully deleted');
      });
  }

  function submitInvitation({ cc, send_copy_to_self, message: msg }) {
    API.inviteUser(activeModal.data.id, cc, msg, send_copy_to_self).then((data) => {
      setProspects((prev) => ({
        ...prev,
        users: prev.users.map((user) =>
          user.id === activeModal.data.id
            ? {
                ...user,
                actions: data?.actions,
                status: data?.status,
              }
            : user,
        ),
      }));

      closeActiveModal();
      getUsers();
      message.success('Email successfully sent');
    });
  }

  function cancelInvite() {
    legacyAPI()
      .post('/prospects/BDR/cancel_invite', { id: activeModal.data.id })
      .then(({ data }) => {
        setProspects((prev) => ({
          ...prev,
          users: prev.users.map((user) =>
            user.id === activeModal.data.id
              ? {
                  ...user,
                  status: data.status,
                  actions: data.actions,
                }
              : user,
          ),
        }));

        closeActiveModal();
        getUsers();
        message.success('Invite successfully canceled');
      });
  }

  function approveProspects() {
    legacyAPI()
      .post('/prospects/BDR/approve', { id: Number(activeModal.data.id) })
      .then(() => {
        setProspects((prev) => ({
          ...prev,
          users: prev.users.filter((user) => user.id !== activeModal.data.id),
        }));

        closeActiveModal();
        message.success('Prospect successfully approved');
      });
  }

  function closeActiveModal() {
    setActiveModal({ show: null, data: {} });
  }

  const columns = useMemo(
    () => [
      new Column('Full name', 'full_name', sorting, setSorting),
      new Column('Type', 'user_type', sorting, setSorting, false),
      new Column(
        'Phone number',
        'phone_number',
        sorting,
        setSorting,
        (n) => (Number(n) ? formatPhone(`+1${n}`) : null),
        false,
      ),
      new Column('Email', 'email', sorting, setSorting),
      new Column('Invites sent', 'invite_sent_at', sorting, setSorting),
      new Column('Status', 'status', sorting, setSorting),
    ],
    [sorting],
  );

  return (
    <div
      className={classNames(
        'prospects_page_wrapper',
        'table_with_header_wrapper',
        'table_with_pagination_wrapper',
        styles.root,
      )}
    >
      <SectionTitle title="Prospects" />
      <SearchBox
        userType={`prospect_${activeTab}`}
        onResultClick={({ id }) => {
          history.push(`/registration/${activeTab}/company?id=${id}`);
        }}
      />
      <CustomButton
        onClick={() => setActiveModal({ show: 'add_prospect' })}
        primary
        svg={<PlusIcon colour="white" />}
        text="Add prospect"
      />
      <div
        className={classNames(
          'section_table_wrapper',
          'table_with_header',
          'table_with_pagination',
          styles.tableWrapper,
        )}
      >
        <Table
          columns={columns}
          pagination={{
            ...pagination,
            itemRender: PaginationItemRender,
            showSizeChanger: true,
            total: prospects.total,
          }}
          onChange={(e) => setPagination(e)}
          scroll={{ x: 1350 }}
          rowKey="id"
          title={() => (
            <Tabs
              defaultActiveKey={activeTab}
              onChange={(val) => {
                setActiveTab(val);

                setPagination((prev) => ({
                  ...prev,
                  current: 1,
                }));
              }}
              items={[
                {
                  key: 'broker',
                  label: 'Originators',
                },
                {
                  key: 'lender',
                  label: 'Lenders',
                },
              ]}
            />
          )}
          bordered={false}
          dataSource={customList}
        />
      </div>
      <AddProspectModal activeModal={activeModal} setProspects={setProspects} onClose={closeActiveModal} />
      <Modal
        okText="Confirm"
        onCancel={closeActiveModal}
        onOk={deleteProspects}
        open={activeModal.show === 'delete_prospect'}
        title="Remove account"
      >
        Are you sure you want to remove this account?
      </Modal>
      <Modal
        okText="Approve"
        onCancel={closeActiveModal}
        onOk={approveProspects}
        open={activeModal.show === 'approve_prospect'}
        title="Approve account"
      >
        Are you sure you want to approve this account?
      </Modal>
      <Modal
        okText="Confirm"
        onCancel={closeActiveModal}
        onOk={cancelInvite}
        open={activeModal.show === 'cancel_invite'}
        title="Cancel invitation"
      >
        Are you sure you want to cancel this invitation? The current user application progress will be lost.
      </Modal>
      <ManualEMSModal
        mainRecipient={
          activeModal.data?.id
            ? {
                full_name: activeModal.data.full_name,
                user_id: activeModal.data.id,
              }
            : undefined
        }
        onCancel={closeActiveModal}
        onSubmit={submitInvitation}
        templateRoute={activeModal.data?.id ? `/prospects/BDR/send_invite?id=${activeModal.data.id}` : undefined}
        title={`Send ${activeModal.show === 'send_a_reminder' ? 'a reminder' : 'an invite'}`}
        visible={['send_a_reminder', 'send_an_invite'].includes(activeModal.show)}
      />
    </div>
  );
}

export default Prospects;
