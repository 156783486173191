import { useState } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { HorizontalDotsIcon } from 'assets/icons';
import styles from './BrokerWidgetTableCollapse.module.css';

export default function BrokerWidgetTableCollapse({ children, total }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div className={classNames(styles.collapsedList, isOpen && styles.collapsedListOpen)}>{children}</div>
      {total > 3 && <Button className={styles.iconButton} onClick={handleToggle} icon={<HorizontalDotsIcon />} />}
    </>
  );
}
