import { Link, Route, useHistory } from 'react-router-dom';
import Navigations from 'routers/Navigations';
import PageHeader from 'components/OnBoarding/PageHeader/PageHeader';
import Logo from 'components/OnBoarding/Logo';
import styles from './styles/MainLarApp.module.css';
import { PlugLar } from './Plug/PlugLarPage';

function MainLarAppNew() {
  const history = useHistory();
  const isFormPage = history.location.pathname === '/lar_app/form';
  // const { token } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  return (
    <>
      <div className={styles.headerWrap}>
        <Link to="/onboarding/dashboard" style={{ height: 56 }}>
          <Logo />
        </Link>
        <PageHeader className="onboarding_header_full_w" />
      </div>
      {token ? (
        <PlugLar token={token} />
      ) : (
        <div className={`${styles.contentWrap} ${isFormPage ? styles.contentGray : styles.contentWhite}`}>
          {Navigations.mainNavLarAppNew.map((route) => (
            <Route
              path={route.path}
              exact
              component={() => <route.main createLarForm={null} setCreateLarForm={null} />}
              key={route.path}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default MainLarAppNew;
