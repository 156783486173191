import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CustomButton from 'components/UI/Button';
import DashboardWidget from 'components/Dashboard/DashboardWidget';
import TimerWidget from 'components/Dashboard/TimerWidget';
import styles from './PLADashboardWidgets.module.css';

function PLADashboardWidgets({ activeItems = {}, auctionEnd, auctionStart }) {
  const history = useHistory();
  const location = useLocation();
  const role = useSelector((s) => s.user.userData?.role);
  const isBroker = /broker/i.test(role);
  const isLender = /lender/i.test(role);

  function toggleLoanManagingMode() {
    const dst = /active-loans/i.test(location.pathname) ? 'dashboard' : 'active-loans';

    history.push(`/onboarding/${dst}`);
  }

  return (
    <div className={styles.root}>
      <TimerWidget auctionEnd={auctionEnd} auctionStart={auctionStart} />
      <DashboardWidget
        desc="Live Auctions"
        title={activeItems.auctions}
        extra={
          <div style={{ display: 'flex', width: '100%', gap: 8 }}>
            {isBroker && (
              <CustomButton
                onClick={() => history.push('/onboarding/borrowers')}
                style={{
                  backgroundColor: 'var(--colour-brand-5)',
                  color: '#fff',
                  width: '100%',
                }}
                text="New"
              />
            )}
            {(isBroker || isLender) && (
              <CustomButton
                onClick={() => history.push('/onboarding/live_auctions')}
                style={{ width: '100%' }}
                text={<span style={{ color: '#40A9FF' }}>View</span>}
              />
            )}
          </div>
        }
      />
      <DashboardWidget
        desc="Active scenarios"
        title={activeItems.scenarios}
        extra={
          <div style={{ display: 'flex', width: '100%', gap: 8 }}>
            {isBroker && (
              <CustomButton
                onClick={() => history.push('/onboarding/borrowers')}
                style={{
                  backgroundColor: 'var(--colour-brand-5)',
                  color: '#fff',
                  width: '100%',
                }}
                text="New"
              />
            )}
            {isLender && (
              <CustomButton
                onClick={() => history.push('/onboarding/scenarios')}
                style={{ width: '100%' }}
                text={<span style={{ color: '#40A9FF' }}>View</span>}
              />
            )}
          </div>
        }
      />
      <DashboardWidget
        desc="Active loans"
        title={activeItems.loans}
        extra={
          <div style={{ display: 'flex', width: '100%', gap: 8 }}>
            {(isBroker || isLender) && (
              <CustomButton
                onClick={toggleLoanManagingMode}
                style={{ width: '100%' }}
                text={<span style={{ color: '#40a9ff' }}>Manage</span>}
              />
            )}
          </div>
        }
      />
    </div>
  );
}

export default PLADashboardWidgets;
