import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import TimelineItem from './TimelineItem';

import styles from './Timeline.module.css';

function Timeline({ timeline }) {
  if (isEmpty(timeline)) {
    return null;
  }

  const ref = useRef(null);
  const sortedTimeline = [...timeline].sort((timeLineA, timeLineB) => timeLineA.id - timeLineB.id);

  function scrollToEnd() {
    ref.current?.scrollIntoView({ behavior: 'instant', block: 'nearest' });
  }

  useEffect(() => {
    scrollToEnd();
  }, []);

  return (
    <ul className={styles.list}>
      {sortedTimeline.map((el) => (
        <TimelineItem title={el.action} status={el.color} date={el.created_at} key={el.id} />
      ))}
      <li ref={ref} />
    </ul>
  );
}

export default Timeline;

Timeline.defaultProps = {
  timeline: [],
};

Timeline.propTypes = {
  timeline: PropTypes.array,
};
