import './styles/diligence.css';
import { Tabs } from 'antd';
import { useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DiligenceList from './Verification/DiligenceList';
import classes from './styles/DiligenceTabs.module.css';

export default function DiligenceTabs() {
  const extraContentSlotRef = useRef(null);
  const history = useHistory();
  const { sub_tab: tabActiveKey, id } = useParams();

  const setTabActiveKey = useCallback(
    (newTab) => {
      history.push(`/onboarding/lar_db/${id}/loan_processing/${newTab}`);
    },
    [history, id],
  );

  return (
    <Tabs
      activeKey={tabActiveKey}
      className={classes.root}
      onTabClick={setTabActiveKey}
      tabBarExtraContent={<div ref={extraContentSlotRef} />}
    >
      <Tabs.TabPane tab="Verification" key="verification">
        <DiligenceList
          isActive={tabActiveKey === 'verification'}
          extraContentSlotRef={tabActiveKey === 'verification' ? extraContentSlotRef : null}
        />
      </Tabs.TabPane>
    </Tabs>
  );
}
