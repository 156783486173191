import { useReducer, useState } from 'react';
import { Modal } from 'antd';
import classNames from 'classnames';
import { BinIcon, InfoCircleIcon, PencilIcon, PlusIcon } from 'assets/icons';
import { humanFileSize, isDocType } from 'utils/files';
import useQuery from 'constants/useQuery';
import styles from './LarAppUpload.module.css';
import FileUploadModal from '../../../Modals/FileUploadModal';
import useLarApi from '../../../hooks/useLarApi';
import Thumbnail from 'components/LarAppNew/Thumbnail/Thumbnail';

function LarAppUpload({ acceptedMimes = [], ytAllowed = false, initFileList = [] }) {
  // TODO: fileList has all data that should be uploaded and even some extra
  // but uploading itself is not implemented yet
  // Format: an array of objects like
  // {
  // 	desc: "abc", // Description provided by user
  // 	yt: "https://youtu.be/dQw4w9WgXcQ", // Undefined if there's a file
  // 	file: { // Undefined if there's a youtube link
  // 		name: "def.jpg", // File name
  // 		originFileObj: File, // Only present in newly added files
  // 		url: "https://example.com/image.jpg", // Only present in files that came from backend
  // 	}
  // }

  const { saveFile, deleteFile } = useLarApi();
  const [lar_id] = useQuery(['lar_id']);
  const [fileList, setFileList] = useState(initFileList);
  const [fileUploadInitState, setFileUploadInitState] = useState({});
  const [fileUploadModalVisible, toggleFileUploadModal] = useReducer((s) => !s, false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);

  function showPreview(uri, name) {
    setPreviewImage(uri);
    setPreviewVisible(true);
    setPreviewTitle(name);
  }

  function openModal(initState) {
    setFileUploadInitState(initState || {});
    toggleFileUploadModal();
  }

  function onSubmitAttachment(attachment, i) {
    if (!attachment.file && !attachment.yt) {
      undefined !== i && deleteAttachment(i);
    } else {
      const formData = new FormData();
      if (attachment.file) {
        formData.append('file', attachment.file.originFileObj || null);
      }
      if (attachment.yt) {
        formData.append('link', attachment.yt || null);
      }
      if (attachment.file_name) {
        formData.append('file_name', attachment.file_name || null);
      }
      formData.append('lar_id', lar_id);
      formData.append('description', attachment.desc);
      saveFile(formData).then((res) => {
        if (res?.status === 200) {
          setFileList((prev) => {
            const copy = [...prev];
            undefined === i ? copy.push(attachment) : (copy[i] = attachment);

            return copy;
          });
        }
      });
    }

    toggleFileUploadModal();
  }

  function deleteAttachment(i, el) {
    if (el.fromBack) {
      deleteFile(el.id, +lar_id).then((r) => {
        if (r.status === 200) {
          setFileList((prev) => {
            const copy = [...prev];
            copy.splice(i, 1);
            return copy;
          });
        }
      });
    } else {
      setFileList((prev) => {
        const copy = [...prev];
        copy.splice(i, 1);

        return copy;
      });
    }
  }

  return (
    <div className={styles.root}>
      {fileList.map((attachment, i) => {
        const fileType = attachment.file?.name.split('.').at(-1);
        const fileSize = humanFileSize(attachment.file?.size);
        const isDoc = isDocType(fileType);

        return (
          <div className={styles.attachmentWrapper} key={i}>
            <picture className={classNames([styles.attachment, isDoc && styles.attachmentDoc])}>
              <Thumbnail attachment={attachment} showPreview={showPreview} />
              <div className={styles.attachmentActionsBar}>
                {attachment.fromBack ? null : (
                  <PencilIcon colour="white" onClick={() => openModal({ ...fileList[i], i })} />
                )}
                <BinIcon colour="white" onClick={() => deleteAttachment(i, attachment)} />
                <div className={styles.infoIconWrapper}>
                  <InfoCircleIcon colour="white" />
                  <div className={styles.attachmentInfo}>
                    <div>{attachment.file_name}</div>
                    <div>{fileType?.toUpperCase()}</div>
                    <div>{fileSize}</div>
                  </div>
                </div>
              </div>
            </picture>
          </div>
        );
      })}
      {fileList.length >= 5 || (
        <div className={styles.uploadButton} onClick={() => openModal(null)}>
          <PlusIcon colour="brand-5" size={40} strokeWidth={1} />
        </div>
      )}
      <Modal footer={null} onCancel={() => setPreviewVisible(false)} title={previewTitle} visible={previewVisible}>
        <img alt={previewTitle} src={previewImage} style={{ width: '100%' }} />
      </Modal>
      <FileUploadModal
        acceptedMimes={acceptedMimes}
        initState={fileUploadInitState}
        onCancel={toggleFileUploadModal}
        onSubmit={onSubmitAttachment}
        visible={fileUploadModalVisible}
        ytAllowed={ytAllowed}
        TITLE
      />
    </div>
  );
}

export default LarAppUpload;
