import '../styles/lar_db_info.css';
import { LockClosed, LockOpened } from 'assets/icons/icons';
import { numberToCurrency } from 'constants/numberToComma';
import { PencilIcon } from 'assets/icons';
import { Row, Col, Tooltip } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LARDBContext from 'pages/Onboarding/LARDB/LARDBContext';
import UniversalSlider from 'components/UniversalSlider';
import UserDrawer from 'components/UserDrawer';
import classNames from 'classnames';
import UniqueData from './UniqueData';

function LARDBInfo({ show }) {
  const [activeModal, setActiveModal] = useState({ show: false, data: null });
  const data = useContext(LARDBContext);
  const history = useHistory();

  const columns = useMemo(
    () => [
      [
        <UniqueData
          key={0}
          label="Borrower"
          value={data.borrower.user_json?.full_name}
          onClick={() => openUserDrawer(data.borrower.user_json)}
        />,
        <UniqueData key={1} label="Co-Borrowers" value={data.co_borrowers} />,
        <UniqueData
          key={2}
          label="Broker"
          value={data.broker.user_json?.full_name}
          corp={data.broker.user_json?.company_name}
          onClick={() => openUserDrawer(data.broker.user_json)}
        />,
        <UniqueData
          key={3}
          label="Lender"
          value={data.lender.user_json?.full_name}
          corp={data.lender.user_json?.company_name}
          onClick={() => openUserDrawer(data.lender.user_json)}
        />,
      ],
      [
        <UniqueData key={10} label="Date Submitted" value={[data.submitted?.time, data.submitted?.user]} />,
        <UniqueData key={11} label="Last update" value={[data.last_update?.time, data.last_update?.user]} />,
        <UniqueData
          key={12}
          label="Winning Lender Selected"
          value={`Target: ${data.winning_lender_selected}`}
          visible={data.winning_lender_selected}
        />,
        <UniqueData
          key={13}
          label="Closing date"
          value={[
            data.closing_date_actual ? `Actual: ${data.closing_date_actual}` : null,
            data.closing_date_scheduled ? `Scheduled: ${data.closing_date_scheduled}` : null,
            data.closing_date_target ? `Target: ${data.closing_date_target}` : null,
          ]}
          visible={data.closing_date_actual || data.closing_date_scheduled || data.closing_date_target}
        />,
      ],
      [
        <UniqueData key={20} label="Loan Style" value={data.loan_style} />,
        <UniqueData key={21} label="Loan purpose" value={data.loan_purpose} />,
        <UniqueData key={22} label="Property type" value={data.property_type} />,
        <UniqueData
          key={23}
          label="Loan Amount"
          value={typeof data.loan_amount === 'number' ? numberToCurrency(data.loan_amount) : data.loan_amount}
        />,
      ],
    ],
    [data],
  );

  function openUserDrawer(userData) {
    setActiveModal({
      show: 'user-drawer',
      data: userData,
    });
  }

  function closeActiveModal() {
    setActiveModal({ show: '', data: null });
  }

  return (
    <div
      className={classNames({
        lar_profile_info_collapsed: !show,
        lar_profile_info: true,
      })}
    >
      <div className="lar_profile_info__inner">
        <div className="lar-profile_info__content-block">
          <div className="lar-profile_info__img-container" style={{ minHeight: 264, minWidth: 326 }}>
            <UniversalSlider media={data.photos} height={264} />
          </div>
          <div className="lar-profile_info__info-list-block">
            <div className="lar-profile_info__info-list">
              <Row justify="space-between" wrap gutter={[24, 24]}>
                {columns.map((col, i) => (
                  <Col key={i}>{col}</Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
        <div className="lar-profile_info__actions-block">
          <Tooltip
            placement="topRight"
            title={data.can_edit ? undefined : 'Auction editing is only available before the auction starts'}
          >
            <div className="lar-profile_info__actions">
              <span className="lar-profile_info__action">{data.can_edit ? <LockOpened /> : <LockClosed />}</span>
              <span
                className="lar-profile_info__action"
                onClick={() => data.can_edit && history.push(`/lar_app/create?lar_id=${data.lar_id}&scenario=false`)}
              >
                <PencilIcon colour="purple-5" size="1.75em" />
              </span>
            </div>
          </Tooltip>
        </div>
      </div>
      <UserDrawer user={activeModal.data} visible={activeModal.show === 'user-drawer'} onClose={closeActiveModal} />
    </div>
  );
}

export default LARDBInfo;
