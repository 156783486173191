import '../../../LoanProfile/BidTable/bid_table.css';
import { Modal, Tooltip } from 'antd';
import { NoteBigIcon } from 'assets/icons/icons';
import { setChatState } from 'store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLARDBContext } from 'pages/Onboarding/LARDB/hooks/LARDBContext';
import { useReducer } from 'react';
import classNames from 'classnames';
// import fileInvoiceIcon from 'assets/icons/file-invoice.svg';
import globalStyles from 'constants/globalStyles';
import trophyIcon from 'assets/icons/trophy.svg';
// import IconButton from 'components/UI/IconButton';
// import PDFAPI from 'api/PDFAPI';
import { PLA } from 'constants/roles';
import ChatButton from 'components/UI/ChatButton/ChatButton';

function BidTableItem({
  bidValuesData,
  handleChooseQuote,
  isMaxQuotes,
  isQuotesSent,
  item,
  openSelectWinQuoteModal,
  selected,
  winnerSelected,
}) {
  const [termsVisible, toggleTermsVisible] = useReducer((state) => !state, false);
  const { role } = useSelector((state) => state.user.userData);
  const { ratesTracker } = useSelector((state) => state.lar.data);
  // const API = useMemo(() => new PDFAPI(), []);
  const { lar_id } = useLARDBContext();
  const dispatch = useDispatch();

  const handleOpenChat = () => {
    dispatch(setChatState({ bid_id: item.id, lar_id, type: 'sidebar' }));
  };

  // const showPDF = () => {
  //   console.log('show', lar_id, item.id);
  //   API.showPDF(lar_id, item.id);
  // };

  const getSelectText = () => {
    if (item.bid_status === 'winner') {
      return 'Winner';
    }

    if ((item.selected_as_quote && !isQuotesSent) || selected) {
      return 'Unselect';
    }

    return 'Select';
  };

  const selectText = getSelectText();
  const isDisabled = (isMaxQuotes && !selected) || role === PLA;
  const tooltipText = role === PLA ? '' : 'A maximum of three quotes can be selected.';
  // console.log(item, ratesTracker, 'main Element');
  const variable_rate_index =
    ratesTracker && item.rate_type === 'Floating' && ratesTracker[`${item.variable_rate_index.toLowerCase()}_rate`];

  const interestRate =
    item.rate_type === 'Floating' && item.variable_rate_index && variable_rate_index && item.interest_rate
      ? variable_rate_index + item.rate_over_index
      : item.interest_rate;

  return (
    <>
      <div>
        <div className="bid-table-list__list-item">
          <div className="bid-table-list__list-item-header-wrapper">
            <div className="bid-table-list__list-item-header-label bid-table-list__list-item-header-label-qs">
              Quote #{item.number}
            </div>
            <div className="bid-table-list__list-item-header-amount bid-table-list__list-item-header-amount-qs">
              {/broker/i.test(role) && <Tooltip title={item.company_name}>{item.company_name}</Tooltip>}
            </div>
            <div className="bid-table-list__list-item-header-buttons">
              <ChatButton text="Message" onClick={handleOpenChat} hasIndicator={item.new_messages} />
              {/* <IconButton icon={fileInvoiceIcon} alt="file invoice" handleClick={showPDF} /> */}
            </div>
            <div
              className={classNames([
                'bid-table-list__list-item-header-select',
                (winnerSelected || isDisabled) && 'disabled',
              ])}
            >
              <Tooltip title={isDisabled && tooltipText}>
                <div
                  className={classNames([
                    'bid-table-list__list-item-header-select-title',
                    item.bid_status === 'winner' && 'winner',
                    selected && 'selected',
                  ])}
                  aria-disabled={winnerSelected}
                >
                  {item.bid_status === 'winner' && <img style={{ marginRight: 8 }} src={trophyIcon} alt="trophy" />}
                  {selectText}
                </div>
              </Tooltip>

              <div className="bid-table-list__list-item-header-select-body">
                <button
                  className="bid-table-list__list-item-btn"
                  disabled={isMaxQuotes && !selected}
                  onClick={() => handleChooseQuote(item.quote_id)}
                >
                  {selected ? 'Remove from quotes' : 'Add to quotes'}
                </button>
                <button className="bid-table-list__list-item-btn" onClick={() => openSelectWinQuoteModal(item)}>
                  Select as winner
                </button>
              </div>
            </div>
          </div>
          <div className="bid-table-list__list-item-body">
            <div className="bid-table-list__info-block">
              {bidValuesData?.map((el, i) => (
                <div key={`${i}bid_value`} className="bid-table-list__info-block-item">
                  {(() => {
                    const value =
                      el.value === 'total_origination_fee' ? item.origination_fee + item.broker_fee : item[el.value];

                    if (el.value === 'interest_rate') return interestRate;
                    return el.format ? el.format(value) : value;
                  })()}
                </div>
              ))}
            </div>
          </div>
          <div className="bid-table-list__list-item-footer">
            {item.additional_terms_and_comment && (
              <span
                className="bid-table-list__list-item-link bid-table-list__list-item-link_with-icon"
                onClick={toggleTermsVisible}
              >
                <NoteBigIcon
                  style={{
                    color: globalStyles.main_blue_color,
                    marginRight: 4,
                  }}
                />
                <span className="bid-table-list__list-item-link-text">Additional terms</span>
              </span>
            )}
          </div>
          <Modal footer={null} onCancel={toggleTermsVisible} title="Additional terms" open={termsVisible}>
            <div dangerouslySetInnerHTML={{ __html: item.additional_terms_and_comment }} />
          </Modal>
        </div>
      </div>
      {item.newBid && <div style={{ textAlign: 'center', color: '#40a9ff', marginTop: 8 }}>New Bid</div>}
      {item.updatedBid && <div style={{ textAlign: 'center', color: '#389e0d', marginTop: 8 }}>Updated Bid</div>}
    </>
  );
}

export default BidTableItem;
