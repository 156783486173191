import { useHistory } from 'react-router-dom';
import API from 'api';
import Modal from 'components/Modals/Modal/Modal';
import NoResults from './NoResults/NoResults';
import SubmitScenario from './SubmitScenario/SubmitScenario';
import { useState } from 'react';

function EndScenarioModal({ visible, onCloseActiveModal, activeModal, lar_id }) {
  const history = useHistory();
  const isSmartmatchResults = activeModal?.data?.smartmatch_results;
  const [loading, setLoading] = useState(false);

  function submit() {
    setLoading(true);
    API()
      .post('/LAR/creation/end_creation', { id: +lar_id })
      .then(() => history.push('/onboarding/dashboard'))
      .finally(() => setLoading(false));
  }

  function handleOk() {
    if (isSmartmatchResults) {
      submit();
    } else {
      history.push(`/lar_app/create?lar_id=${lar_id}`);
    }
  }

  return (
    <Modal
      cancelText="Close"
      closable={false}
      maskClosable={false}
      okText={isSmartmatchResults ? 'Confirm' : 'Revise'}
      onCancel={onCloseActiveModal}
      onOk={handleOk}
      okButtonProps={{ loading }}
      open={visible}
      title={isSmartmatchResults ? 'Confirm the launch of this scenario' : 'Scenario Results'}
    >
      {isSmartmatchResults ? <SubmitScenario lar_id={lar_id} activeModal={activeModal} /> : <NoResults />}
    </Modal>
  );
}

export default EndScenarioModal;
