import './styles/landing_page.css';
import 'react-color-palette/lib/css/styles.css';
import { Checkbox, Modal, message } from 'antd';
import { ColorPicker, useColor } from 'react-color-palette';
import { getUserFromStorage } from 'utils/storage';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from 'api';
import AvatarUpload from 'components/AvatarUpload';
import Button from 'components/UI/Button';
import CustomButton from 'components/UI/Button';
import jwt from 'jsonwebtoken';
import SaveIcon from 'assets/icons/SaveIcon';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import TextInput from 'components/CustomComponents/TextInput';

const initState = {
  company_logo: {
    value: '',
    preview: null,
    error: false,
    errorMessage: 'You should upload company logo',
    required: true,
  },
  domain: {
    value: '',
    error: false,
    errorMessage: 'Fill this label',
    required: true,
  },
  broker_pages_enabled: {
    value: false,
    required: false,
  },
};

export default function LandingPages() {
  const [fields, setFields] = useState(initState);
  const [color, setColor] = useColor('hex', '#f5f5f5');
  const [activeModal, setActiveModal] = useState(null);
  const [previewData, setPreviewData] = useState({});
  const [savedDomain, setSavedDomain] = useState('');
  const { role } = getUserFromStorage();
  const history = useHistory();
  const size = (file) => parseFloat(file.size / 1024).toFixed(2);

  const uploadLogo = (file, name) => {
    if (size(file) > 5000) {
      setFields((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          error: true,
          errorMessage: 'File is too big',
        },
      }));
    } else {
      setFields((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: file,
          preview: URL.createObjectURL(file),
          error: false,
        },
      }));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let optionsError = false;
    const setFunc = (i) => {
      setFields((prevState) => ({
        ...prevState,
        [i[0]]: {
          ...prevState[i[0]],
          error: true,
        },
      }));
    };

    Object.entries(fields).forEach((i) => {
      if (!i[1].value) {
        if (!i.required) {
          return;
        }
        setFunc(i);
        optionsError = true;
      }
    });

    if (optionsError) {
      return;
    }

    setActiveModal('submit');
  };

  const setDomain = (showPopup = true) => {
    API()
      .post('/LAR/landing/broker/set_domain', {
        domain: fields.domain.value,
      })
      .then(() => {
        setSavedDomain(fields.domain.value);
      })
      .then(() => {
        showPopup && message.success('You successfully set domain');
      })
      .catch((err) => {
        if (err.response.data.error === 'This domain is already exists!') {
          setFields((prevState) => ({
            ...prevState,
            domain: {
              ...prevState.domain,
              error: true,
              errorMessage: 'This domain is already exists',
            },
          }));
        }
      });
  };

  const onSubmitForm = () => {
    setActiveModal(null);
    const formData = new FormData();

    Object.entries(fields).forEach((i) => {
      if (i[0] === 'domain') {
        return;
      }
      if (typeof i[1].value === 'boolean') {
        formData.append(i[0], +i[1].value);
      } else if (i[0] === 'broker_picture' || i[0] === 'company_logo') {
        if (i[1].value && i[1].preview) {
          formData.append(i[0], i[1].value);
        }

        // There should be a separate button or something for deleting a logo
        // formData.append('delete_company_logo', Number(!(i[1].value && i[1].preview)))
      } else {
        formData.append(i[0], i[1].value);
      }
    });

    formData.append('color_code', color.hex);

    API()
      .post(role.includes('Admin') ? '/LAR/landing/broker' : '/LAR/landing/broker/broker-custom-landing', formData)
      .then(() => {
        message.success('You successfully updated landing page');
      })
      .then(() => {
        setDomain(false);
      })
      .catch((err) => {
        if (err.response.data.error === 'This domain is already exists!') {
          setFields((prevState) => ({
            ...prevState,
            domain: {
              ...prevState.domain,
              error: true,
              errorMessage: 'This domain is already exists',
            },
          }));
        }
      });
  };

  useEffect(() => {
    API()
      .get('/LAR/landing/broker')
      .then((res) => {
        setPreviewData(res.data.landing);
        setFields((prevState) => ({
          ...prevState,
          company_logo: {
            ...prevState.company_logo,
            preview: res.data.landing.company_logo,
          },
          domain: {
            ...prevState.domain,
            value: res.data.landing.domain ?? '',
          },
          broker_pages_enabled: {
            ...prevState.broker_pages_enabled,
            value: res.data.landing.broker_pages_enabled ?? false,
          },
        }));
        setSavedDomain(res.data.landing.domain ?? '');
        setColor((prevState) => ({
          ...prevState,
          hex: res.data.landing.color_code ?? '#f5f5f5',
        }));
      });

    // eslint-disable-next-line
  }, [setColor]);

  const onChange = (e, name) => {
    setFields((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: false,
        value: e.target.value,
      },
    }));
  };

  const checkDomain = (e) => {
    setFields((prevState) => ({
      ...prevState,
      domain: {
        ...prevState.domain,
        error: false,
        value: e.target.value.trim(),
      },
    }));

    if (e.target.value.length > 2) {
      API()
        .post('/LAR/landing/broker/custom-landing/check_domain', { domain: e.target.value.trim() })
        .catch((err) => {
          if (err.response.data.error === 'Not available' && savedDomain !== e.target.value.trim()) {
            setFields((prevState) => ({
              ...prevState,
              domain: {
                ...prevState.domain,
                error: true,
                errorMessage: 'This domain is already exists',
              },
            }));
          }
          if (err.response.data.error === 'bad data') {
            setFields((prevState) => ({
              ...prevState,
              domain: {
                ...prevState.domain,
                error: true,
                errorMessage: 'Only letters, numbers, “.” and “+” symbols are allowed.',
              },
            }));
          }
        });
    }
  };

  const previewLanding = () => {
    let result = previewData;

    Object.entries(fields).forEach((i) => {
      if (i[0] === 'broker_picture' || i[0] === 'company_logo') {
        result[i[0]] = i[1].preview;
      } else {
        result[i[0]] = i[1].value || i[1].preview || '';
      }
    });

    API()
      .get('/LAR/landing/broker/broker-custom-landing')
      .then((res) => {
        result = { ...result, ...res.data.broker_custom_landing };
        result.color_code = color.hex;
        result.company_NMLS = res.data.company_landing.nmls;

        jwt.sign(result, 'shhhhh', (err, token) => {
          if (token) {
            const win = window.open(`/portal/preview?token=${token}`, '_blank');
            win.focus();
          }
        });
      });
  };

  function exitWithoutSaving() {
    history.push('/onboarding/company-settings');
  }

  return (
    <form className="wrapper_landing_page" onSubmit={onSubmit}>
      <SectionTitle title="Landing Page Branding" />
      <div className="company_info">
        <div className="domain input_container">
          <label className="input_title">Domain</label>
          <div className="placeholder">
            <span>{window.location.host}/portal/</span>
          </div>
          <TextInput
            style={{ color: savedDomain === fields.domain.value ? '' : fields.domain.error ? '#F5222D' : '#389E0D' }}
            value={fields.domain.value}
            maxLength={50}
            errorMessage={fields.domain.errorMessage}
            onChange={checkDomain}
            error={fields.domain.error}
          />
          <CustomButton
            disabled={fields.domain.error || fields.domain.value.trim() === '' || savedDomain === fields.domain.value}
            onClick={setDomain}
            text="Save"
            className="btn_darkblue"
          />
        </div>
      </div>
      <div className="wrapper_file_uploader">
        <p className="wrapper_file_uploader__title">Custom Landing Page Showcase</p>
        <p className="avatar_title">Company logo</p>
        <AvatarUpload
          companyLogoFormat
          description="Recommended: 4x3 aspect ratio high resolution photo in PNG or JPEG, 5mb maximum file size"
          errorMessage={fields.company_logo.error ? fields.company_logo.errorMessage : null}
          preview={fields.company_logo.preview}
          propsUpload={(file) => uploadLogo(file, 'company_logo')}
          btn_text="Upload Logo"
        />
      </div>
      <div className="wrapper_color_picker">
        <span className="title">Select an accent color</span>
        <span className="description">Brand сolor</span>
        <ColorPicker width={240} height={108} color={color} onChange={setColor} hideHSV hideRGB />
      </div>
      {role.includes('Admin') && (
        <Checkbox
          className="custom-checkbox"
          style={{ marginTop: '24px' }}
          onChange={(e) => {
            onChange({ target: { value: e.target.checked } }, 'broker_pages_enabled');
          }}
          checked={fields.broker_pages_enabled.value}
        >
          Broker loan officers can customise their own landing page
        </Checkbox>
      )}
      <div className="wrapper_btns">
        <Button text="Exit without Saving" onClick={exitWithoutSaving} />
        <Button text="Preview" className="btn_darkblue" onClick={previewLanding} />
        <Button svg={<SaveIcon />} text="Save" className="btn_darkblue" type="submit" />
      </div>
      <Modal
        className="wrapper_prospect wrapper_prospect_delete"
        title={
          <div className="wrapper_prospect__title">
            <span className="title">Change landing info</span>
            <span className="description">Do you want to change your landing page information?</span>
            <span className="description">This action will overwrite your current info.</span>
          </div>
        }
        visible={activeModal === 'submit'}
        onOk={() => setActiveModal({ show: null, data: null })}
        centered
        footer={
          <div className="wrapper_remove_prospect_btns">
            <CustomButton key="back" onClick={() => setActiveModal(null)} text="Cancel" />
            <CustomButton key="submit" onClick={onSubmitForm} text="Confirm" className="btn_darkblue" />
          </div>
        }
        onCancel={() => setActiveModal({ show: null, data: null })}
      />
    </form>
  );
}
