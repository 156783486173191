export const FINANCING_TYPES = [
  { value: 'financing_balance_sheet', label: 'Balance Sheet' },
  { value: 'financing_agency_fannie_freddie', label: 'Agency/Fannie/Freddie' },
  { value: 'financing_sba', label: 'SBA' },
  { value: 'financing_hud', label: 'HUD' },
  { value: 'financing_dscr', label: 'DSCR' },
  { value: 'financing_mezz_pref_equity', label: 'Mezz/Pref Equity' },
  { value: 'financing_equity', label: 'Equity' },
  { value: 'financing_other', label: 'Other' },
];

export const PROPERTY_TYPES = [
  { value: 'property_type_rental_home_1_4_units', label: 'Long Term Rental 1-4 units' },
  {
    value: 'property_type_short_term_rental_home_1_4_units_airbnb',
    label: 'Short Term Rental Home 1-4 units (AirBnb)',
  },
  { value: 'property_type_condominium', label: 'Condominium (Warrantable)' },
  { value: 'property_type_condo', label: 'Condo (Non-Warrantable)' },
  { value: 'property_type_multifamily_5_plus', label: 'Multifamily 5+ units' },
  { value: 'property_type_office', label: 'Office' },
  { value: 'property_type_retail', label: 'Retail' },
  { value: 'property_type_industrial_nnn', label: 'Industrial/NNN' },
  { value: 'property_type_hospitality', label: 'Hospitality' },
  { value: 'property_type_storage', label: 'Storage' },
  { value: 'property_type_senior_assisted_living', label: 'Senior/Assisted Living' },
  { value: 'property_type_mixed_use', label: 'Mixed-use' },
  { value: 'property_type_student', label: 'Student' },
  { value: 'property_type_mobile_home_park', label: 'Mobile Home Park' },
  { value: 'property_type_land', label: 'Land' },
  { value: 'property_type_subdivision_spec_home', label: 'Subdivision/Spec Home' },
  { value: 'property_type_portfolio', label: 'Portfolio of Rental Homes' },
  { value: 'property_type_other', label: 'Other' },
];

export const PURPOSE_TYPES = [
  { value: 'purpose_purchase', label: 'Purchase' },
  { value: 'purpose_refinance', label: 'Refinance' },
  {
    value: 'purpose_bridge_short_term_no_renovation',
    label: 'Bridge/Short Term (no renovation)',
  },
  {
    value: 'purpose_bridge_short_term_renovation_fix_to_flip',
    label: 'Bridge/Short Term (renovation/Fix to Flip)',
  },
  { value: 'purpose_construction', label: 'Construction' },
];

export const DEAL_SOURCE_TYPES = [
  { value: 'deal_source_brokers_accepted', label: '' },
  { value: 'deal_source_borrowers_accepted', label: '' },
];

export const RECOURSES_TYPES = [
  { value: 'recourse_full_recourse', label: 'Recourse' },
  { value: 'recourse_partial_recourse', label: 'Partial Recourse' },
  { value: 'recourse_non_recourse', label: 'Non-Recourse' },
];

export const PAYMENT_TYPES = [
  { value: 'payment_type_principal_interest', label: 'Principal & Interest' },
  { value: 'payment_type_interest_only', label: 'Interest Only' },
];

export const BORROWER_CITIZENSHIP_TYPES = [
  { value: 'borrower_citizenship_permanent_resident_alien', label: 'Permanent resident alien' },
  { value: 'borrower_citizenship_foreign_national_no_itin', label: 'Foreign national (No ITIN)' },
  { value: 'borrower_citizenship_foreign_national_itin', label: 'Foreign national (ITIN)' },
  { value: 'borrower_citizenship_non_permanent_resident', label: 'Non Permanent Resident' },
  { value: 'borrower_citizenship_foreign_investor', label: 'Foreign investor' },
  { value: 'borrower_citizenship_other', label: 'Other' },
];

export const FICO_TYPES = [
  { value: 'min_fico_required_less_than_600', label: 'Below 600' },
  { value: 'min_fico_required_600', label: '600' },
  { value: 'min_fico_required_640', label: '640' },
  { value: 'min_fico_required_680', label: '680' },
  { value: 'min_fico_required_greater_than_680', label: 'Must be 680 or greater' },
  { value: 'min_fico_required_credit_check_not_required', label: 'Credit check not required' },
];

export const FINANCING_AMOUNTS = {
  min: [
    '$50,000',
    '$100,000',
    '$200,000',
    '$300,000',
    '$400,000',
    '$500,000',
    '$1m',
    '$2m',
    '$3m',
    '$4m',
    '$5m',
    '$10m',
    '$15m',
    '$20m',
    '$25m',
    '$30m',
    '$35m',
    '$40m',
    '$45m',
    '$50m',
  ],
  max: [
    '$1m',
    '$2m',
    '$3m',
    '$4m',
    '$5m',
    '$10m',
    '$15m',
    '$20m',
    '$25m',
    '$30m',
    '$35m',
    '$40m',
    '$45m',
    '$50m',
    '$60m',
    '$70m',
    '$80m',
    '$90m',
    '$100m',
    'Greater than $100m',
  ],
};
