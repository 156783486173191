import PropTypes from 'prop-types';
import ScenarioNavItem from './ScenarioNavItem';
import styles from './ScenarioNav.module.css';
import { useSelector } from 'react-redux';

export default function ScenarioNav({ activeItemId, items, larName, onSetActiveItemId }) {
  const { role } = useSelector((s) => s.user.userData);
  const isLender = /lender/i.test(role);

  const handleSetActiveItemId = (id) => () => onSetActiveItemId(id);

  return (
    <nav className={styles.root}>
      {items?.length > 0 ? (
        items.map((item) => (
          <ScenarioNavItem
            key={item.id}
            data={item}
            larName={larName}
            isActive={item.id === activeItemId}
            onClick={handleSetActiveItemId(item.id)}
          />
        ))
      ) : (
        <div className={styles.placeholder}>
          {isLender ? 'No scenarios are available for you at the moment.' : 'New lender responses will appear here.'}
        </div>
      )}
    </nav>
  );
}

ScenarioNav.propTypes = {
  items: PropTypes.array.isRequired,
  activeItemId: PropTypes.number,
  larName: PropTypes.string,
  onSetActiveItemId: PropTypes.func,
};
