import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo(({ direction, ...params }) => (
  <GenericIcon {...params} transform={`scale(${direction === 'right' ? 1 : -1},1)`}>
    <path d="M5 12H19" />
    <path d="M13 18L19 12" />
    <path d="M13 6L19 12" />
  </GenericIcon>
));
