import './styles/notifications_page.css';
import { Link } from 'react-router-dom';
import ImportantNotifications from 'components/OnBoarding/Dashboard/ImportantNotifications';
import SectionTitle from 'components/OnBoarding/SectionTitle';

export default function NotificationsPage() {
  return (
    <div className="notifications-page">
      <div className="notifications-page__inner">
        <div className="notifications-page__title-container">
          <Link to="/onboarding/dashboard">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 12H19" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5 12L11 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5 12L11 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
          <SectionTitle title="Notifications" style={null} type="regular-24" wrapperStyle={null} />
        </div>
        <div className="notifications-page__notifications-container">
          <div className="notifications-page__important-notifications-container">
            <ImportantNotifications style={{ borderRadius: 0 }} showLoadMore header="Important" bordered />
          </div>
        </div>
      </div>
    </div>
  );
}
