import { Button, Modal } from 'antd';
import { PlusOutlined, MinusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Tooltip from 'components/UI/Tooltip';

function SubmitPopup({ handleCancel, isCollapsed, handleActiveFormsKeys, open }) {
  return (
    <Modal
      closable={false}
      footer={[
        <Button size="large" onClick={handleCancel}>
          Close
        </Button>,
        <Button
          size="large"
          type="dark"
          icon={isCollapsed ? <PlusOutlined /> : <MinusOutlined />}
          onClick={handleActiveFormsKeys}
        >
          {isCollapsed ? 'Uncollapse all' : 'Collapse all'}
        </Button>,
      ]}
      onCancel={handleCancel}
      open={open}
      title="Please complete all required fields"
    >
      <p>It looks like you forgot to fill in one or more fields.</p>
      <p>Please review the loan request and complete all the fields required.</p>
      <Tooltip
        icon={<InfoCircleOutlined />}
        text="Hint: Collapse all sections to quickly see where the Big Blue Dot is missing."
        type="info"
        size="small"
      />
    </Modal>
  );
}

export default SubmitPopup;
