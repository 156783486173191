import { Badge, Table, Select, Tooltip, message as toast, Button } from 'antd';
import { BinIcon, PencilIcon, HandStopIcon, SendIcon } from 'assets/icons';
import { useCallback, useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import ManualEMSModal from 'components/Modals/ManualEMSModal';
import Modal from 'components/Modals/Modal/Modal';
import DisableUserModal from '../DisableUserModal';
import styles from './Users.module.css';
import { replacedTextData } from 'constants/common';

const statuses = ['Active', 'Disabled', 'Pending'];

function Users({ API }) {
  const [data, setData] = useState({ teams: [], total: 0 });
  const [limitBy, setLimitBy] = useState({ status: null });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [ready, setReady] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const userId = useSelector((store) => store.user.userData?.id);
  const [activeModal, setActiveModal] = useState({ show: '', data: null });
  const userRole = useSelector((store) => store.user.userData?.role);
  const adminRoles = ['Lender Admin', 'Broker Admin'];

  function onCloseActiveModal() {
    setActiveModal({ show: '', data: null });
  }

  const editUser = useCallback(
    (id) => {
      history.push(`/onboarding/company-settings/profile/${id}`);
    },
    [history],
  );

  const getCompanyData = useCallback(() => {
    setReady(false);

    API.getCompanyMembers(pagination.current, pagination.pageSize, limitBy.status).then((data) => {
      setData(data);
      setReady(true);
    });
  }, [API, pagination, limitBy.status]);

  useEffect(getCompanyData, [getCompanyData]);

  const toggleUserDisabled = useCallback(
    (id) => {
      setReady(false);

      API.toggleUserDisabled(id)
        .then(({ user_status }) => {
          toast.info(`User successfully ${/disabled/i.test(user_status) ? 'dis' : 'en'}abled`);
          getCompanyData();
        })
        .catch(({ response }) => {
          toast.error(response?.data?.error);
        })
        .finally(() => {
          setReady(true);
          onCloseActiveModal();
        });
    },
    [API, getCompanyData],
  );

  const deleteUser = useCallback(
    (id) => {
      API.deleteUser(id)
        .then(() => {
          toast.info('User successfully deleted');
          getCompanyData();
        })
        .catch(({ response }) => {
          toast.error(response?.data?.error);
        })
        .finally(() => {
          setReady(true);
          onCloseActiveModal();
        });
    },
    [API, getCompanyData],
  );

  function reinviteUser() {
    setActiveModal((prev) => ({
      ...prev,
      show: 'send_invites',
    }));
  }

  const handleSubmitInvitations = useCallback(
    ({ to, message, send_copy_to_self }) => {
      API.inviteCompanyMember(to, message, send_copy_to_self)
        .then(() => {
          onCloseActiveModal();
          toast.success('Invitation(s) sent!');
        })
        .catch((e) => {
          if (e?.response?.data?.error === 'This user is already associated with company') {
            toast.error('At least one of the emails in the list is already associated with a company');
          } else {
            console.error(e);
            toast.error(e?.response?.data?.error || 'ERROR!');
          }
        });
    },
    [API],
  );

  const columns = useMemo(
    () => [
      {
        dataIndex: 'full_name',
        title: 'Name',
      },
      {
        dataIndex: 'email',
        title: 'Email',
      },
      {
        dataIndex: 'role',
        title: 'Member role',
        render: (_, { role }) => replacedTextData[role] || role,
      },
      {
        dataIndex: 'status',
        title: 'Status',
      },
      {
        dataIndex: 'enabled',
        title: 'Enabled',
        render: (_, { enabled }) => (
          <Badge status={enabled ? 'success' : 'error'} text={enabled ? 'Enabled' : 'Disabled'} />
        ),
      },
      {
        key: '',
        title: / admin/i.test(userRole) ? 'Actions' : null,
        render: (_, { id, full_name, enabled, status, email, role }) =>
          / admin/i.test(userRole) ? (
            <>
              {/pending/i.test(status) && (
                <>
                  <Tooltip title="Delete user">
                    <button
                      aria-label="deleteUser"
                      onClick={() => {
                        setActiveModal({
                          show: 'delete_user',
                          data: {
                            id,
                            full_name,
                          },
                        });
                      }}
                    >
                      <BinIcon />
                    </button>
                  </Tooltip>
                  <Tooltip title="Resend invitation">
                    <button
                      aria-label="reinviteUser"
                      onClick={() => {
                        const [name, ...surname] = full_name.split(' ');

                        setActiveModal({
                          show: 'reinvite_user',
                          data: {
                            email,
                            full_name,
                            id,
                            name,
                            role_name: role,
                            surname: surname.join(' '),
                          },
                        });
                      }}
                    >
                      <SendIcon />
                    </button>
                  </Tooltip>
                </>
              )}
              {userId === +id || (
                <Tooltip
                  title={
                    enabled ? 'Disabling a user will prevent the user from accessing their account' : 'Enable user'
                  }
                >
                  <button
                    aria-label="disableUser"
                    onClick={() => {
                      if (enabled) {
                        setActiveModal({
                          show: 'disable_user',
                          data: {
                            id,
                            full_name,
                          },
                        });
                      } else {
                        toggleUserDisabled(id);
                      }
                    }}
                  >
                    <HandStopIcon crossed={enabled} />
                  </button>
                </Tooltip>
              )}
              <Tooltip title="Edit profile">
                <button aria-label="editProfile" onClick={() => editUser(id)}>
                  <PencilIcon />
                </button>
              </Tooltip>
            </>
          ) : null,
      },
    ],
    [editUser, userId, toggleUserDisabled, userRole],
  );

  function addUsers() {
    const part = location.pathname.split('/').pop();
    history.push({
      pathname: `/onboarding/${part}/associate_new_members`,
      state: {
        link: '/onboarding/company-settings/users',
      },
    });
  }

  return (
    <div className={styles.root}>
      <h1>Users</h1>
      <Select
        className={adminRoles.includes(userRole) || styles.status}
        allowClear
        onChange={(status) => setLimitBy({ status })}
        options={statuses.map((value) => ({ value }))}
        placeholder="Select status"
        value={limitBy.status}
        size="large"
      />
      {adminRoles.includes(userRole) && (
        <Button onClick={addUsers} size="large" type="dark" icon={<PlusOutlined />}>
          Add users
        </Button>
      )}
      <Table
        columns={columns}
        dataSource={data.members}
        loading={!ready}
        onChange={(p) => setPagination(p)}
        pagination={{
          ...pagination,
          total: data.total,
        }}
        rowKey="id"
      />
      <DisableUserModal
        activeModal={activeModal}
        onCloseActiveModal={onCloseActiveModal}
        onSubmit={toggleUserDisabled}
        visible={activeModal.show === 'disable_user'}
      />
      <Modal
        centered
        okText="Send"
        onCancel={onCloseActiveModal}
        onOk={reinviteUser}
        title="Resend invitation"
        visible={activeModal.show === 'reinvite_user'}
      >
        Resend a company invitation email to {activeModal.data?.full_name}?
      </Modal>
      <Modal
        centered
        onCancel={onCloseActiveModal}
        onOk={() => deleteUser(activeModal.data?.id)}
        title="Delete user"
        visible={activeModal.show === 'delete_user'}
      >
        Are you sure you want to completely delete {activeModal.data?.full_name}
        's account?
      </Modal>
      <ManualEMSModal
        mainRecipient={[activeModal.data || {}]}
        onCancel={onCloseActiveModal}
        onSubmit={handleSubmitInvitations}
        templateRoute="/user_settings/company/members"
        title="Resend an invitation"
        visible={activeModal.show === 'send_invites'}
      />
    </div>
  );
}

export default Users;
