import { Row, Col, Divider, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { LeftArrowIcon } from '../../../assets/icons/icons';
import BidCard from '../../../components/BidCard';
import Button from '../../../components/UI/Button';
import auctionBidImg from '../../../assets/images/bid-card--auction.png';
import privateBidImg from '../../../assets/images/bid-card--private.png';
import API from '../../../api';
import Loader from '../../../components/Loader';
import globalStyles from '../../../constants/globalStyles';

function ChooseBid({ drawerType = false, openPlaceBidDrawer, onCloseActiveDrawer, activeDrawer }) {
  const history = useHistory();
  const params = useParams();
  const [bidData, setBidData] = useState({ loading: true });
  const [activeBid, setActiveBid] = useState(null);
  const larId = params?.id;
  const isChosenBid = bidData?.bid_data?.bid_type === 'private' || bidData?.bid_data?.bid_type === 'public';
  const errMsg = message;

  const handleNavBack = () => {
    history.push({
      pathname: `/onboarding/loan_profile/${larId}`,
      state: { pageBg: '#F5F5F5' },
    });
  };

  const handleChooseBid = (id) => {
    if (isChosenBid) {
      errMsg.error(
        `Already selected bid type: ${activeBid === 'private' ? 'Hidden.' : 'Public'} You can't change bid type`,
      );
    } else {
      setActiveBid(id);
    }
  };

  const goToPlaceBid = () => {
    if (drawerType) {
      openPlaceBidDrawer();
    } else {
      history.push(`/bid_application/lar_id_${larId}/place_bid`);
    }
  };

  const getBidData = () => {
    API()
      .get(`/LAR/bid_process/lender/place_bid?lar_id=${larId}`)
      .then((res) => {
        if (
          (res.data.bid_data.bid_type === 'private' || res.data.bid_data.bid_type === 'public') &&
          drawerType &&
          !activeDrawer?.data?.showChoosePageIfChosenBid
        ) {
          goToPlaceBid();
        }

        if (res.data.bid_data.bid_type) {
          setActiveBid(res.data.bid_data.bid_type);
        }

        setBidData(() => ({
          ...res.data,
          loading: false,
        }));
      })
      .catch(() => {
        errMsg.error('Something went wrong...Soon you will redirected to the previous page', 5, () => {
          history.goBack();
        });
      });
  };

  const cleanup = () => {
    setBidData({ loading: true });
  };

  const handleConfirm = () => {
    setBidData((prevState) => ({
      ...prevState,
      loading: true,
    }));

    if (bidData.bid_data.bid_type && bidData.bid_data.bid_type === activeBid) {
      goToPlaceBid();
    } else {
      API()
        .post('/LAR/bid_process/lender/select_bid_type', {
          lar_id: +larId,
          bid_type: activeBid,
        })
        .then((res) => {
          goToPlaceBid();
          setBidData(() => ({
            ...res.data,
            loading: false,
          }));
        })
        .catch((e) => {
          if (e.response.data.error === 'already selected') {
            goToPlaceBid();
          }
          message.error(e?.response?.data?.error);
        })
        .finally(() => {
          setBidData((prevState) => ({
            ...prevState,
            loading: false,
          }));
        });
    }
  };

  useEffect(() => {
    getBidData();

    return () => {
      cleanup();
      errMsg.destroy();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="loan-bid-app__choose-page" style={drawerType ? { marginTop: 0 } : {}}>
      {bidData.loading ? (
        <div
          style={{
            position: 'absolute',
            width: '20%',
            height: '40%',
            top: '40%',
            left: '48%',
            zIndex: 10,
          }}
        >
          <Loader
            color={{
              '--bgColor': 'var(--main-blue-color)',
              '--size': '100px',
              '--wrapper_size': '100px',
            }}
          />
        </div>
      ) : (
        <div className="loan-bid-app__choose-page-inner" style={{ visible: 'hidden' }}>
          <div className="loan-bid-app__back-link" onClick={drawerType ? onCloseActiveDrawer : handleNavBack}>
            <LeftArrowIcon style={{ color: globalStyles.main_blue_color }} />
            <span className="loan-bid-app__back-link-text">Return to auction</span>
          </div>
          <div className="loan-bid-app__title-block">
            <div className="loan-bid-app__title">Submit a quote</div>
            <div className="loan-bid-app__subtitle">Firstly, choose the type of bid do you want to place</div>
          </div>
          <Row style={{ marginTop: 27 }} justify="center" align="center" gutter={[24, 24]}>
            <Col>
              <BidCard
                activeBid={activeBid}
                bidId="public"
                description="After submitting your initial quote, you will be able to view the quotes that other lenders have submitted and they will be able to view your quote. You can change your quote at any time during the auction period."
                handleChoose={handleChooseBid}
                img={auctionBidImg}
                title="Visible Quote"
              />
            </Col>
            <Col>
              <BidCard
                activeBid={activeBid}
                bidId="private"
                description="Your quote remains hidden from the view of other lenders and vice versa. You can change your quote at any time during the auction period. After the auction ends and a winning quote is selected, then the non-winning lenders will be able to view the winning quote."
                handleChoose={handleChooseBid}
                img={privateBidImg}
                title="Hidden Quote"
              />
            </Col>
          </Row>
          <Divider style={{ borderColor: '#D9D9D9' }} />
          <Row justify="space-between" align="center" gutter={[0, 24]} wrap={false}>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#8C8C8C',
                marginRight: 30,
              }}
            >
              You cannot change quote type after
            </span>
            <Button disabled={!activeBid} text="Confirm" onClick={handleConfirm} className="btn_darkblue" />
          </Row>
        </div>
      )}
    </div>
  );
}
export default ChooseBid;
