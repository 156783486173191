function SubmitScenario({ activeModal, lar_id }) {
  return (
    <div
      style={{
        alignItems: 'start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <p>Good news. There are loan programs that meet many of your loan requests.</p>
      <h3>
        <b>
          Deal ID <span style={{ marginLeft: 150 }}>{lar_id}</span>
        </b>
      </h3>
      <h3>
        <b>
          Loan programs available
          <span style={{ marginLeft: 15 }}>{activeModal.data?.smartmatch_results}</span>
        </b>
      </h3>
      <p>
        We will invite lenders to review your scenario.
        <br />
        Lenders typically respond within 24 hours.
      </p>
    </div>
  );
}

export default SubmitScenario;
