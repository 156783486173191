import axios from 'axios';
import forceLogOff from 'constants/forceLogOff';
import helper from 'constants/helper';

export default function API(token = null) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  function getTokenFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get('token') || urlParams.get('jwt');
  }

  const tokenFromStorage = localStorage.getItem(helper.JWT_TOKEN);

  if (getTokenFromUrl() && !tokenFromStorage) {
    localStorage.setItem(helper.JWT_TOKEN, getTokenFromUrl());
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  } else if (localStorage.getItem(helper.JWT_TOKEN)) {
    headers.Authorization = `Bearer ${localStorage.getItem(helper.JWT_TOKEN)}`;
  }

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
    timeout: 6e4,
  });

  api.interceptors.request.use((request) => requestHandler(request));

  api.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error),
  );

  const successHandler = (response) => response;
  const requestHandler = (request) => request;

  function errorHandler(error) {
    console.error(error);

    if (error?.response?.status === 401) {
      forceLogOff();
      return Promise.reject({ ...error });
    }
    if (error?.response?.status === 403) {
      return Promise.reject({ ...error });
    }
    return Promise.reject({ ...error });
  }

  return api;
}
