import { Form, Input, Select } from 'antd';
import { useMemo } from 'react';

import Modal from 'components/Modals/Modal/Modal';
import siteName from 'constants/siteName';

// TODO: This component is similar to components/OnBoarding/ScenarioBids/EndScenarioModal
// It may be possible to merge them later
function WithdrawModal({ onCancel, onOk, cancellation, withdraw, loading, reasons = [], happensNext = '', ...rest }) {
  const [form] = Form.useForm();

  const reasonOptions = useMemo(() => {
    if (reasons.length) {
      return reasons.map((value) => ({ value }));
    }

    if (cancellation) {
      return [
        { value: 'Closing days too long' },
        { value: "General loan terms didn't meet the loan request" },
        { value: 'Have better loan offer from other source' },
        { value: 'Interest rate was higher than requested' },
        { value: 'Lender points were too high' },
        { value: 'Loan Amount was insufficient' },
        { value: 'Not specified' },
        { value: 'Other loan quotes provided were better' },
      ];
    }

    if (withdraw) {
      return [
        { value: 'Borrower or/broker was unresponsive' },
        { value: 'Diligence results revealed too large a variance from initial loan profile' },
        { value: 'Borrower insufficient funds for cash to close' },
        { value: 'Borrower inadequate credit or other criteria' },
        { value: 'Capital markets conditions changed since deal began' },
        { value: 'Other/Text Box' },
      ];
    }

    return [
      { value: "The borrower's funds are insufficient" },
      { value: 'Lender quotes were not accepted by the borrower' },
      { value: 'Diligence revealed too large a variance from application' },
      { value: 'Borrower did not accept our success fee agreement' },
      { value: 'Borrower is unresponsive' },
      ...['Borrower went with a different loan option', 'Borrower lost the deal and does not need a loan'].map(
        (value) => ({ value }),
      ),
      { value: 'Other/Text box' },
    ];
  }, [cancellation, withdraw, reasons]);

  function getTitle() {
    if (cancellation) {
      return 'Cancel auction';
    }

    if (withdraw) {
      return 'Withdraw';
    }

    return 'End this scenario';
  }

  function getLabel() {
    if (cancellation) {
      return 'Why are you cancelling from this active loan';
    }

    if (withdraw) {
      return 'Why are you withdrawing from this deal?';
    }

    return 'Why are you ending this scenario?';
  }

  return (
    <Modal
      {...rest}
      cancelText="Close"
      okText="Confirm"
      onCancel={onCancel}
      onOk={() => form.submit()}
      title={getTitle()}
      okButtonProps={{ loading }}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{ reason: reasonOptions[0].value }}
        requiredMark={false}
        onFinish={onOk}
      >
        <Form.Item label={getLabel()} name="reason">
          <Select options={reasonOptions} />
        </Form.Item>
        <Form.Item
          name="additionalInfo"
          rules={[
            { required: true, message: 'Additional explanation is required' },
            { max: 500, message: 'The input exceeds the maximum length of 500 characters' },
          ]}
          label="Please provide an additional explanation for the lender(s):"
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
      {withdraw || (
        <>
          <b>What happens next?</b>
          {happensNext ? (
            <p>{happensNext}</p>
          ) : (
            <ol>
              <li>{`This LAR will be moved to ${cancellation ? 'pre-auction stage' : 'the archive'}.`}</li>
              <li>{siteName} will notify the lenders.</li>
            </ol>
          )}
          {cancellation || (
            <p>
              From the archive you will be able to rerun a new scenario or loan auction. Alternatively, you can also
              permanately delete the LAR and all related diligence.
            </p>
          )}
        </>
      )}
    </Modal>
  );
}

export default WithdrawModal;
