import './styles/partner_pdf.css';
import { FullScreenIcon } from 'assets/icons/icons';
import { message as toast } from 'antd';
import { setUserData } from 'store/actions/userActions';
import { TransformWrapper } from 'react-zoom-pan-pinch';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import API, { BDRAPI } from 'api';
import CustomButton from 'components/UI/Button';
import DocumentViewer from 'components/OnBoarding/DocumentViewer';
import Keys from 'constants/helper';
import ManualEMSModal from 'components/Modals/ManualEMSModal';
import RegistrationSubHeader from 'components/Registration/RegistrationSubHeader';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import { getUserFromStorage } from 'utils/storage';

const initialState = {
  actions: [],
  agreement: false,
  company_is_full: false,
  profile_is_full: false,
};

export default function BrokerPartnerPDF() {
  const [activeModal, setActiveModal] = useState({ show: false, data: {} });
  const [activePage, setActivePage] = useState(1);
  const [approve, setApprove] = useState(false);
  const [data, setData] = useState(initialState);
  const [doneTabs, setDoneTabs] = useState({ company: false, profile: false });
  const [edit, setEdit] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [prospectData, setProspectData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const userData = useSelector((store) => store.user.userData);

  const prospectId = useMemo(() => Number(new URLSearchParams(location.search).get('id')), [location]);

  const progress = [
    {
      title: 'Company Profile',
      onClick: () => history.push(`/registration/broker/company${location.search}` ?? ''),
      clickCondition: true,
      completed: true,
    },
    {
      title: 'Personal Profile',
      onClick: () => history.push(`/registration/broker/profile${location.search}` ?? ''),
      clickCondition: true,
      completed: doneTabs.profile,
    },
  ];

  // TODO: add check on verification (if edit is in actions)

  useEffect(() => {
    if (prospectId) {
      API()
        .get('/prospects/BDR', { params: { id: prospectId } })
        .then(({ data }) => setProspectData(data));

      API()
        .get('/prospects/BDR/check_status', { params: { id: prospectId } })
        .then(({ data }) => {
          setDoneTabs({
            company: data.company_is_full,
            profile: data.profile_is_full,
          });
        });
    }

    API()
      .get('/prospects/BDR/broker_agreement', { params: { id: prospectId } })
      .then(({ data }) => {
        setData(data);
        setEdit(data.actions.includes('Edit password'));
        setApprove(data.actions.includes('Approve'));
        window.scrollTo(0, 0);
      });
  }, [prospectId]);

  useEffect(() => {
    if (prospectData.email && !activeModal.data.email) {
      setActiveModal((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          email: prospectData.email,
        },
      }));
    }
  }, [activeModal, prospectData.email]);

  const globalAPI = useMemo(() => new BDRAPI(), []);

  function submitInvitation({ cc, send_copy_to_self, message }) {
    globalAPI.inviteUser(prospectId, cc, message, send_copy_to_self, true).then(() => {
      toast.success('Email successfully sent');
      closeActiveModal();
      history.push('/onboarding/prospects');
    });
  }

  function onApprove() {
    if (userData?.role !== 'Prospect broker') {
      return approveSubmit();
    }

    API()
      .post('/prospects/BDR/broker_agreement')
      .then(() => approveSubmit('broker'));
  }

  function approveSubmit(type) {
    const isBroker = type === 'broker';
    const user = isBroker ? getUserFromStorage() : {};

    if (prospectId) {
      API()
        .post('/prospects/BDR/approve', { id: prospectId })
        .then(({ data }) => {
          toast.success(isBroker ? 'Broker fee approved successfully' : 'Broker has been approved successfully');

          setTimeout(() => {
            if (isBroker) {
              localStorage.setItem(Keys.JWT_TOKEN, data.access_token);
              dispatch(setUserData({ ...user, token: data.access_token, role: data.role }));
              history.push('/onboarding/dashboard');
            } else {
              history.push('/onboarding/prospects');
            }
          }, 1500);
        });
    } else {
      API()
        .post('/prospects/BDR/approve')
        .then(({ data }) => {
          toast.success(isBroker ? 'Broker fee approved successfully' : 'Broker has been approved successfully');

          setTimeout(() => {
            if (isBroker) {
              localStorage.setItem(Keys.JWT_TOKEN, data.access_token);
              dispatch(setUserData({ ...user, token: data.access_token, role: data.role }));
              history.push('/onboarding/dashboard');
            } else {
              history.push('/onboarding/prospects');
            }
          }, 1500);
        });
    }
  }

  function closeActiveModal() {
    setActiveModal((prev) => ({ ...prev, show: false }));
  }

  function submitAgreementUser() {
    API()
      .post('/prospects/BDR/broker_agreement')
      .then(() => {
        history.push(`/registration/broker/review?${location.search}`);
      });
  }

  return (
    <div className="wrapper_partner_pdf">
      <RegistrationSubHeader actions={[]} logo="BrokerCorp" maxWidth={840} progress={progress} />
      <div className="broker_partner_agreement">
        <SectionTitle title="Broker partner agreement" />
        <ManualEMSModal
          onCancel={closeActiveModal}
          onSubmit={submitInvitation}
          templateRoute={prospectId ? `/prospects/BDR/send_invite?id=${prospectId}&send_verification=1` : undefined}
          title="Send verification"
          visible={activeModal.show}
        />
        <div className="view-pdf-wrapper">
          <div className="fullscreen-trigger" onClick={() => setFullscreen(true)}>
            <FullScreenIcon />
          </div>
          <div className="broker_partner_agreement__docs_viewer">
            <DocumentViewer activeDocument={{ url: data?.agreement }} onPageChange={(e) => setActivePage(e)} />
          </div>
          {fullscreen && (
            <div className="fullscreen-wrapper">
              <TransformWrapper>
                {(wpapperParams) => (
                  <DocumentViewer
                    activeDocument={data?.agreement}
                    arrowsColor="white"
                    errorHeight={600}
                    hasZoomIn={{
                      setFullscreen,
                      ...wpapperParams,
                    }}
                    initialPage={activePage}
                    scale={0.9}
                  />
                )}
              </TransformWrapper>
            </div>
          )}
        </div>
        <p className="broker_partner_agreement__description">
          Review this Broker Partner agreement for accuracy and then select "Confirm Partner Agreement" to confirm you
          approval of this Broker Account.
        </p>
        <div className="broker_partner_agreement__btn">
          <CustomButton
            primary
            onClick={
              edit
                ? userData?.role === 'Prospect broker'
                  ? submitAgreementUser
                  : () => setActiveModal((state) => ({ ...state, show: true }))
                : approve
                ? onApprove
                : () => history.push('/onboarding/prospects')
            }
            text={
              edit
                ? userData?.role === 'BDR'
                  ? 'Confirm Agreement and Send Verification'
                  : 'Confirm Agreement and Submit Profile'
                : approve
                ? 'Approve'
                : 'Back'
            }
          />
        </div>
      </div>
    </div>
  );
}
