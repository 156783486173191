import React from 'react';
import { Space } from 'antd';
import { useHistory } from 'react-router-dom';
import SectionTitle from '../../../../components/OnBoarding/SectionTitle';
import SuperadminMandatesTable from '../../../../components/OnBoarding/SuperAdmin/Mandates/SuperadminMandatesTable';
import CustomButton from '../../../../components/UI/Button';
import { PlusIcon } from '../../../../assets/icons/icons';

function SuperadminMandates() {
  const history = useHistory();

  const goToCreateEditMandate = () => {
    history.push({
      pathname: '/onboarding/mandates/create_mandate',
      state: { pageBg: '#FFFFFF' },
    });
  };

  return (
    <div className="prospects_page_wrapper table_with_header_wrapper table_with_pagination_wrapper">
      <div className="section_title_wrapper">
        <SectionTitle title="Mandates" />
        <Space style={{ justifyContent: 'flex-end' }}>
          <CustomButton
            text="Create"
            onClick={() => goToCreateEditMandate('short')}
            className="btn_darkblue"
            svg={<PlusIcon />}
          />
        </Space>
      </div>
      <div className="section_table_wrapper table_with_header table_with_pagination">
        <SuperadminMandatesTable />
      </div>
    </div>
  );
}
export default SuperadminMandates;
