import larVariables from './larVariables';

/**
 * Determines the type of a LAR (Loan Application Request) entry based on its loan purpose and term.
 *
 * @param {Object} lar - The LAR entry containing the loan purpose name and term name.
 * @returns {Object} An object containing boolean values indicating whether the LAR entry is a refinance,
 * purchase with long term, or purchase with short term.
 */
const getLarType = ({ loan_term_name, loan_purpose_name }) => {
  const lpn = larVariables.loan_purpose_name;
  const ltn = larVariables.loan_term_name;

  const isRefinance = loan_purpose_name === lpn.refinance;
  const isPurchaseLong = loan_purpose_name === lpn.purchase && loan_term_name === ltn.long;
  const isPurchaseShort = loan_purpose_name === lpn.purchase && loan_term_name === ltn.short;

  return { isRefinance, isPurchaseLong, isPurchaseShort };
};

export default getLarType;
