const readableFields = new Map([
  ['initial_escrow.hazard_insurance_premium', 'Hazard insurance premium'],
  ['initial_escrow.prepaid_interest', 'Prepaid interest'],
  ['initial_escrow.real_estate_taxes', 'Real estate taxes'],
  ['monthly.homeowners_assn_dues', 'Homeowners assn. dues'],
  ['monthly.mortgage_insurance', 'Mortgage insurance'],
  ['monthly.property_insurance', 'Property insurance'],
  ['monthly.real_estate_property_taxes', 'Real estate property taxes'],
  ['origination_fees.application_fees', 'Application fees'],
  ['origination_fees.processing_fee', 'Processing fee'],
  ['prepaid.hazard_insurance_premium', 'Hazard insurance premium'],
  ['prepaid.prepaid_interest', 'Prepaid interest'],
  ['prepaid.real_estate_taxes', 'Real estate taxes'],
  ['project_costs.mortgage_payoff_refi', 'Mortgage payoff (Refi)'],
  ['project_costs.other', 'Other'],
  ['project_costs.renovation_budget', 'Renovation budget'],
  ['services.appraisal_fee', 'Appraisal fee'],
  ['services.credit_report_fee', 'Credit report fee'],
  ['taxes.mortgage_recording_fees', 'Mortgage recording fees'],
  ['taxes.transfer_taxes', 'Transfer taxes'],
]);

export default readableFields;
