import { memo } from 'react';
import colours from './colours';

export default memo(({ colour = 'grey-7', size = '1.6em', ...rest }) => (
  <svg
    fill="none"
    height={size}
    stroke={colours.get(colour) || colour}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  />
));
