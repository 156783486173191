import './styles/auth.css';
import { setUserData } from 'store/actions/userActions';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from 'api';
import Button from 'components/UI/Button';
import jwtDecode from 'jwt-decode';
import Keys from 'constants/helper';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import siteName from 'constants/siteName';
import TextInput from 'components/CustomComponents/TextInput';
import useQuery from 'constants/useQuery';

export default function AuctionAuth({ type }) {
  const [landingLinks, setLandingLinks] = useState({});
  const [data, setData] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [lar_id] = useQuery(['lar_id']);
  const [submitTrigger, setSubmitTrigger] = useState(0);

  function signUp() {
    let errors = false;
    const tempData = { ...data };

    Object.entries(tempData.fields).forEach((i) => {
      if (!i[1].value) {
        tempData.fields[i[0]].error = true;
        errors = true;
      }
    });

    if (errors) {
      setData(tempData);
      return;
    }

    API()
      .post('/LAR/quotes/borrower/registration', {
        password: tempData.fields.password.value,
        lar_id,
      })
      .then((res) => {
        const token = res.data.access_token;
        const jwtData = jwtDecode(token);
        localStorage.setItem(Keys.JWT_TOKEN, token);
        dispatch(
          setUserData({
            role: jwtData?.role,
            token,
          }),
        );

        history.push(`/winner-selection${window.location.search}`);
      });
  }

  const loginData = {
    description: 'In order to confirm your quote, please first login on our loan portal',
    descriptionWidth: '452px',
    title: 'Login as a borrower',
    fields: {
      email: {
        title: 'Email',
        value: '',
        error: false,
        errorMessage: 'Fill this field',
      },
      password: {
        title: 'Password',
        value: '',
        error: false,
        type: 'password',
        errorMessage: 'Fill this field',
      },
    },
    buttonText: 'Sign In',
    afterText: (
      <span>
        You can login to view your account or close this window. Thank you for participating in
        {siteName}
        's loan auction.
      </span>
    ),
    submitFunc: () => null,
  };
  const signupData = {
    description: 'In order to confirm your quote, please first create an account on our loan portal',
    descriptionWidth: '536px',
    title: 'Create an account',
    hint: 'Your password should be at least 8 characters long.',
    fields: {
      password: {
        title: 'Password',
        value: '',
        type: 'password',
        error: false,
        errorMessage: 'Fill this field',
      },
      passwordConfirmation: {
        title: 'Password confirmation',
        value: '',
        type: 'password',
        error: false,
        errorMessage: 'Fill this field',
      },
    },
    buttonText: 'Continue',
    beforeText: (
      <span className="description-12">
        By signing up I acknowledge and agree with
        <a target="_blank" rel="noopener noreferrer" href={landingLinks['E-consent'] ?? ''}>
          E-consent
        </a>
        ,
        <a target="_blank" rel="noopener noreferrer" href={landingLinks['Privacy policy'] ?? ''}>
          Privacy Policy
        </a>{' '}
        and
        <a rel="noopener noreferrer" target="_blank" href={landingLinks['Terms of Service'] ?? ''}>
          Terms of Service
        </a>{' '}
        of the platform
      </span>
    ),
    submitFunc: () => setSubmitTrigger((state) => state + 1),
  };

  useEffect(() => {
    if (type === 'sign-up') {
      API()
        .get('/common/iharmoni-terms')
        .then((res) => {
          setLandingLinks(res.data.documents);
        });
    }
  }, [type]);

  useEffect(() => {
    if (type === 'sign-up' && submitTrigger) {
      signUp();
    }

    // eslint-disable-next-line
  }, [submitTrigger, type]);

  const onChange = (route, value) => {
    setData((state) => ({
      ...state,
      fields: {
        ...state.fields,
        [route]: {
          ...state.fields[route],
          value,
        },
      },
    }));
  };

  useEffect(() => {
    setData(type === 'login' ? loginData : signupData);

    // eslint-disable-next-line
  }, []);

  if (!data) {
    return null;
  }

  return (
    <div className="auction-auth">
      <span style={{ maxWidth: data.descriptionWidth }}>{data.description}</span>
      <form onSubmit={data.submitFunc} className="auth-wrapper">
        <SectionTitle wrapperStyle={{ textAlign: 'center' }} type="heading-24" title={data.title} />
        <div>{data.hint}</div>
        <div className="inputs">
          {Object.entries(data.fields).map((i) => {
            const field = i[1];

            return (
              <TextInput
                error={field.error}
                errorMessage={field.errorMessage}
                onChange={(j) => onChange(i[0], j.target.value)}
                title={field.title}
                type={field.type}
                value={field.value}
              />
            );
          })}
        </div>
        {data.beforeText}
        <Button onClick={data.submitFunc} primary style={{ padding: '4px 0', width: '100%' }} text={data.buttonText} />
        {data.afterText}
      </form>
    </div>
  );
}
