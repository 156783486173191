const financingTypes = [
  'Balance  Sheet',
  'Agency',
  'CMBS',
  'Bridge',
  'Fix to Flip',
  'Construction',
  'SBA',
  'Gov’t',
  'Mezz/Pref Equity',
  'Distressed/Work-out',
  'Other',
];

const incomeDocTypes = [
  'Not required',
  'DSCR',
  'Stated',
  'Full doc',
  '1-3 Mo. bank statements',
  '6-12 Mo. bank statements',
  '24 Mo. bank statements',
  'K-1 only',
  '1099 only',
  'VOE only',
  '1 Year Tax Returns',
  '2 Year Tax Returns',
  'No Credit Score Required',
];

const recourses = ['Recourse', 'Non-Recourse', 'Partial Recourse', 'Other - see additional terms'];

const initialFormData = {
  financing_type: null,
  additional_terms_and_comment: '',
  arm_option: null,
  bank_fee: null,
  cash_due: null,
  closing_date: null,
  DSCR: null,
  income_doc_type: null,
  interest_rate: null,
  lender_processing_fee: null,
  loan_amount: null,
  loan_tern: null,
  loan_to_cost: null,
  loan_to_value: null,
  maximum_cash_out_or_cash_due: null,
  payment_type: null,
  pre_payment_penalty: null,
  rate_type: null,
  variable_rate_index: null,
  rate_over_index: null,
};

export { incomeDocTypes, initialFormData, financingTypes, recourses };
