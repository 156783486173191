export const breakpoints = {
  320: {
    slidesPerView: 1,
  },
  640: {
    slidesPerView: 2,
  },
  1e3: {
    slidesPerView: 3,
  },
  12e2: {
    slidesPerView: 5,
  },
};
