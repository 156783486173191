import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Drawer, Modal, Input } from 'antd';
import API from 'api';
import { BinIcon, PencilIcon } from 'assets/icons';
import { convertToTimezone } from 'constants/convertToTimezone';
import arrowLeft from 'assets/images/arrow-left.svg';
import arrowRight from 'assets/images/arrow-right.svg';
import CustomAvatar from 'components/UI/Avatar';
import CustomButton from 'components/UI/Button';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import 'assets/styles/lar_app_card.css';

const deleteNoteInitial = { id: null, text: '' };

function NotesDrawer({ notes, setCreateNoteDrawer, createNoteDrawer, setNotes }) {
  const [deleteNotePopup, setDeleteNotePopup] = useState(deleteNoteInitial);
  const timezone = useSelector((store) => store.user.userData.timezone);

  function saveNote(e, editMode) {
    e && e.preventDefault();

    API()
      .post('/LAR/notes', {
        lar_id: notes.data.lar_id,
        note_id: editMode ? createNoteDrawer.visible : undefined,
        note: createNoteDrawer.text,
      })
      .then(({ data }) => {
        setCreateNoteDrawer({ visible: false, text: '' });
        setNotes({
          visible: true,
          data: {
            ...data,
            notes: Object.entries(data.notes).reverse(),
          },
        });
      });
  }

  function deleteNote() {
    API()
      .delete(`/LAR/notes?${new URLSearchParams({ note_id: deleteNotePopup.id })}`)
      .then(({ data }) => {
        closeDeletePopup();
        setNotes({
          visible: true,
          data: {
            ...data,
            notes: Object.entries(data.notes).reverse(),
          },
        });
      });
  }

  function closeNotes() {
    setNotes((state) => ({ ...state, visible: false }));
  }

  function closeNotesDrawer() {
    setCreateNoteDrawer((state) => ({ ...state, visible: false }));
  }

  function closeDeletePopup() {
    setDeleteNotePopup(deleteNoteInitial);
  }

  return (
    <>
      <Drawer
        bodyStyle={{ padding: 0 }}
        closeIcon={false}
        key={10}
        onClose={closeNotes}
        placement="right"
        open={notes.visible}
        width={450}
        headerStyle={{ padding: 0 }}
      >
        {notes.data && notes.visible && (
          <div className="drawer-wrapper notes">
            <div className="drawer-header">
              <img onClick={closeNotes} src={arrowRight} alt="" />
              <strong>
                <SectionTitle type="body-14" title="Notes" />
              </strong>
              <SectionTitle
                className="lar-identifier"
                title={`LAR #${notes.data.lar_id} ${notes.data.user.full_name}`}
                type="body-14"
                wrapperStyle={{ marginLeft: -2 }}
              />
              <CustomButton
                className="btn_darkblue"
                onClick={() => setCreateNoteDrawer({ visible: true, text: '' })}
                text="Create note"
              />
            </div>
            <div className="drawer-scroll notes-list">
              <Modal
                centered
                className="wrapper_prospect wrapper_prospect_delete"
                title={
                  <div className="wrapper_prospect__title">
                    <span className="title">Delete Note</span>
                    <span style={{ textAlign: 'center' }} className="description">
                      Are you sure you want to delete this note?
                      <br />
                      This action cannot be undone
                    </span>
                    <span className="note-text">{deleteNotePopup.text}</span>
                  </div>
                }
                onCancel={closeDeletePopup}
                visible={deleteNotePopup.id}
                footer={
                  <div className="wrapper_remove_prospect_btns">
                    <CustomButton key="back" onClick={closeDeletePopup} text="Cancel" />
                    <CustomButton
                      className="btn_darkblue"
                      key="submit"
                      onClick={deleteNote}
                      style={{ background: '#FF4D4F', color: '#FFF', border: '#FF4D4F' }}
                      text="Confirm"
                    />
                  </div>
                }
              />
              {notes.data.notes.map((i, idx_0) => (
                <div className="note-group" key={idx_0}>
                  <div className="note-group-header">
                    <span>{i[0]}</span>
                  </div>
                  <div className="note-group-body">
                    {i[1].map((i, idx_1) => (
                      <div className="note-wrapper" key={`${idx_0}_${idx_1}`}>
                        <div className="notes-action">
                          <BinIcon onClick={() => setDeleteNotePopup({ text: i.note, id: i.id })} />
                          <PencilIcon onClick={() => setCreateNoteDrawer({ visible: i.id, text: i.note })} />
                        </div>
                        <span className="time-created">
                          {convertToTimezone(i.created_at, timezone)},{timezone}
                        </span>
                        <div className="note-creator">
                          <CustomAvatar fullName={notes.data.user.full_name} src={notes.data.user.profile_photo} />
                          <span className="creator-name">{notes.data.user.full_name}</span>
                        </div>
                        <span className="note-text">{i.note}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Drawer>
      <Drawer
        bodyStyle={{ padding: 0 }}
        closeIcon={false}
        key={11}
        onClose={closeNotesDrawer}
        placement="right"
        open={createNoteDrawer.visible}
        width={450}
        headerStyle={{ padding: 0 }}
      >
        {notes.data && notes.visible && (
          <div className="drawer-wrapper notes create-note">
            <div className="drawer-header">
              <img onClick={closeNotesDrawer} src={arrowLeft} alt="Back" />
              <strong>
                <SectionTitle type="body-14" title="Notes" />
              </strong>
              <SectionTitle
                className="lar-identifier long"
                title={`LAR #${notes.data.lar_id} ${notes.data.user.full_name}`}
                type="body-14"
                wrapperStyle={{ marginLeft: -2 }}
              />
            </div>
            <div className="create-note-body">
              <form onSubmit={(e) => saveNote(e)}>
                <Input.TextArea
                  onChange={(e) =>
                    setCreateNoteDrawer((state) => ({
                      ...state,
                      text: e.target.value,
                    }))
                  }
                  placeholder="Start typing your note here"
                  rows={15}
                  value={createNoteDrawer.text}
                />
              </form>
            </div>
            <div className="create-note-footer">
              <div className="buttons">
                <CustomButton onClick={() => setCreateNoteDrawer({ visible: false, text: '' })} text="Cancel" />
                <CustomButton
                  className="btn_darkblue"
                  onClick={(e) => saveNote(e, typeof createNoteDrawer.visible === 'number')}
                  text="Save Note"
                />
              </div>
              <span>{`Visible to ${notes.data.team}`}</span>
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
}

export default NotesDrawer;
