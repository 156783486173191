import { EditIcon } from 'assets/icons';
import { Input } from 'antd';
import CustomButton from 'components/UI/Button';

export default function KeyFields({ data, editFields, isPLA, setEditFields, setViewDiligence, submitEditField }) {
  function changeOrAddKeyField(value, id) {
    setEditFields((prev) => {
      const keyFieldIndex = prev.key_fields.findIndex((j) => id === j.id);

      return {
        ...prev,
        key_fields:
          keyFieldIndex >= 0
            ? [
                ...prev.key_fields.slice(0, keyFieldIndex),
                {
                  ...prev.key_fields[keyFieldIndex],
                  value,
                  error: false,
                },
                ...prev.key_fields.slice(keyFieldIndex + 1),
              ]
            : [
                ...prev.key_fields,
                {
                  id,
                  value,
                },
              ],
      };
    });
  }

  function saveKeyDataField(keyField) {
    if (!keyField.value) {
      setEditFields((prev) => {
        const keyFieldIndex = prev.key_fields.findIndex((i) => i.id === keyField.id);

        return {
          ...prev,
          key_fields: [
            ...prev.key_fields.slice(0, keyFieldIndex),
            { ...prev.key_fields[keyFieldIndex], error: true },
            ...prev.key_fields.slice(keyFieldIndex + 1),
          ],
        };
      });

      return;
    }

    setViewDiligence((prev) => {
      const keyFieldIndex = prev.key_fields.findIndex((i) => i.id === keyField.id);

      return {
        ...prev,
        key_fields: [
          ...prev.key_fields.slice(0, keyFieldIndex),
          { ...prev.key_fields[keyFieldIndex], field_value: keyField.value },
          ...prev.key_fields.slice(keyFieldIndex + 1),
        ],
      };
    });

    submitEditField({ field_id: keyField.id, field_value: keyField.value }).then(() => {
      setEditFields((prev) => ({
        ...prev,
        key_fields: prev.key_fields.filter((i) => i.id !== keyField.id),
      }));
    });
  }

  return (
    <div className="key-fields-wrapper">
      <b>Key Data Fields</b>
      <div className="key-fields">
        {data?.length ? (
          data.map((i, idx) => {
            const editField = editFields.key_fields.find((e) => e.id === i.id);

            return (
              <div key={idx} className="key-field">
                <div className="title">
                  <span>{i.field_name}</span>
                  <span className="grey">{`${i.updated_by} ${i.updated_at}`}</span>
                </div>
                <span className="grey">{i.field_description}</span>
                <div className={`value-wrapper ${editField ? 'edit' : ''}`}>
                  {editField ? (
                    <form
                      className={editField.error ? 'edit-field-error' : ''}
                      onSubmit={(e) => {
                        e?.preventDefault();
                        saveKeyDataField(editFields);
                      }}
                    >
                      <Input
                        value={editField?.value ?? i.field_value}
                        onChange={(e) => changeOrAddKeyField(e.target.value, i.id)}
                      />
                      <CustomButton
                        onClick={() =>
                          setEditFields((prev) => ({
                            ...prev,
                            key_fields: prev.key_fields.filter(({ id }) => id !== i.id),
                          }))
                        }
                        text="Cancel"
                      />
                      <CustomButton
                        onClick={(e) => {
                          e?.preventDefault();
                          saveKeyDataField(editField);
                        }}
                        className="btn_darkblue"
                        text="Save"
                      />
                    </form>
                  ) : (
                    <>
                      <span>{i.field_value ?? 'No value'}</span>
                      {isPLA && <EditIcon onClick={() => changeOrAddKeyField(undefined, i.id)} />}
                    </>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <span>No key data fields</span>
        )}
      </div>
    </div>
  );
}
