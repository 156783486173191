import { Table, DatePicker, Select, message, Button, Checkbox, Space } from 'antd';
import { CSVLink } from 'react-csv';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { isEmpty } from 'lodash';
import moment from 'moment';

import API from 'api';

import styles from 'pages/Onboarding/PLA/Report/ActivityLogTable/ActivityLogTable.module.css';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

function ActivityLogTable({
  columns,
  data,
  dataFilter,
  filter,
  id,
  title,
  type,
  pagination,
  onFilterDateRange,
  onFilterUser,
  onFilterClear,
  onFilterUserView,
  handlePagination,
}) {
  const { loading, error } = useSelector((state) => state.lar);
  const [report, setReport] = useState('');

  if (error) {
    message.error('Something went wrong. Please try again');
    return null;
  }

  useEffect(() => {
    API()
      .get(`/LAR/dashboard/PLA/log/csv?lar_id=${id}&type=${type}`)
      .then((res) => setReport(res.data));
  }, [id, type]);

  return (
    <div>
      <h2>{title}</h2>
      <div className={styles.wrapper}>
        <div className={styles.filter}>
          <RangePicker
            showNow
            format={dateFormat}
            value={[filter.dateFrom, filter.dateTo]}
            onChange={onFilterDateRange}
          />

          <Select
            showSearch
            allowClear
            value={filter.userNames}
            loading={loading}
            onChange={onFilterUser}
            placeholder="Select a user"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={dataFilter.filters.user_name.map((name) => ({
              label: name,
              value: name,
            }))}
          />
          <Button onClick={onFilterClear} loading={loading} disabled={isEmpty(filter)}>
            Reset Filters
          </Button>
        </div>
        <Space>
          {onFilterUserView && <Checkbox onChange={onFilterUserView}>Hide user views </Checkbox>}
          <CSVLink
            className="btn_default btn_darkblue"
            style={{ padding: '6px 16px', fontSize: '14px' }}
            filename={`Starport ${title} - ${moment().format('MMM DD YYYY')}`}
            data={report}
          >
            Download results to .csv
          </CSVLink>
        </Space>
      </div>

      <Table
        loading={loading}
        columns={columns}
        dataSource={data.activity_logs}
        rowKey={(record) => record.id}
        onChange={handlePagination}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {ReactHtmlParser(record.body)}
            </p>
          ),
          rowExpandable: (record) => record.body,
        }}
        pagination={{
          current: pagination?.current,
          showSizeChanger: true,
          total: pagination.total,
        }}
      />
    </div>
  );
}

export default ActivityLogTable;
