import { Col, Row, Space, Typography } from 'antd';
import { CalendarOutlined, EyeOutlined, StarOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Fragment, useMemo } from 'react';

import LoanProfileMedia from '../LoanProfileMedia';

import { ReactComponent as MailDoneIcon } from 'assets/icons/mail-done.svg';
import { ReactComponent as QuotesIcon } from 'assets/icons/quotes.svg';

import styles from './LoanProfileHero.module.css';

export default function LoanProfileHero({ data = {} }) {
  const { id } = useParams();
  const media = useMemo(() => data.documents?.filter((f) => f.type !== 'document') || [], [data]);

  const widgets = [
    {
      value: data.lender_statistic?.smartmatch_score ? Math.round(data.lender_statistic.smartmatch_score) : 0,
      icon: StarOutlined,
      description: 'Smartmatch Score',
    },
    { value: data.lender_statistic?.days_active, icon: CalendarOutlined, description: 'Days Active' },
    { value: data.lender_statistic?.lenders_invited, icon: MailDoneIcon, description: 'Lenders Invited' },
    { value: data.lender_statistic?.views, icon: EyeOutlined, description: 'Total Views' },
    { value: data.lender_statistic?.lenders_submitted, icon: QuotesIcon, description: 'Quotes Submitted' },
  ];

  const larInfo = useMemo(
    () => [
      { label: 'Financing Type: ', value: data.financing_type?.replaceAll(/["{}]/g, '') },
      { label: 'Property Type:', value: data.property_type },
      { label: 'Loan Purpose', value: data.loan_purpose },
      { label: 'Loan Amount', value: data.loan_amount ? `$${data.loan_amount.toLocaleString()}` : '' },
      { label: 'Deal Status', value: data.deal_status },
      { label: 'Target Closing Date', value: data.target_closing_date },
      { label: 'Deal Source', value: data.deal_source },
    ],
    [data],
  );

  return (
    <div className={styles.hero}>
      <Row gutter={[16, 16]}>
        {data.lender_statistic && (
          <Col span={24}>
            <div className={styles.widgets}>
              {widgets.map((widget) => (
                <div key={widget.description} className={styles.widget}>
                  <div className={styles.widgetTop}>
                    <widget.icon className={styles.widgetIcon} />
                    <div className={styles.widgetTitle}>{widget.value}</div>
                  </div>
                  <Typography.Text className={styles.textSecondary}>{widget.description}</Typography.Text>
                </div>
              ))}
            </div>
          </Col>
        )}
        <Col span={24}>
          <div className={styles.card}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className={styles.leftInfo}>
                  <Row gutter={[16, 8]}>
                    <Col span={24}>
                      <div className={styles.title}>LAR ID: {id}</div>
                    </Col>
                    {larInfo.map((item) => (
                      <Fragment key={item.id}>
                        <Col span={10} className={styles.label}>
                          {item.label}
                        </Col>
                        <Col span={14}>{item.value}</Col>
                      </Fragment>
                    ))}
                  </Row>
                </div>
              </Col>
              <Col span={12}>
                <LoanProfileMedia media={media} />
              </Col>
              <Col span={24}>
                <div className={styles.divider} />
              </Col>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Space direction="vertical" size={8}>
                      <div className={styles.textSecondary}>Business Plan</div>
                      <div dangerouslySetInnerHTML={{ __html: data.business_plan }} />
                    </Space>
                  </Col>
                  {data.mezz_pref_equity_description && (
                    <Col span={24}>
                      <Space direction="vertical" size={8}>
                        <div className={styles.textSecondary}>Mezz Pref Equity Description</div>
                        <div>{data.mezz_pref_equity_description}</div>
                      </Space>
                    </Col>
                  )}
                  {data.workout_distressed_description && (
                    <Col span={24}>
                      <Space direction="vertical" size={8}>
                        <div className={styles.textSecondary}>Workout Distressed Description</div>
                        <div>
                          {data?.workout_distressed_description?.split(',')?.[0]?.split(':')?.[1]?.replaceAll("'", '')}
                        </div>
                      </Space>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
