import { useSelector } from 'react-redux';
import { ProductFruits } from 'react-product-fruits';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { Result } from 'antd';

import { useIsMobile } from 'hooks/useIsMobile';
import MainRouter from 'routers/MainRouter';
import CustomErrorBoundary from 'components/ErrorBoundary';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
  environment: process.env.REACT_APP_NODE_ENV,
};

function App() {
  const { isMobile } = useIsMobile();
  const productFruitsWorkspace = process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE;
  const userData = useSelector((store) => store.user?.userData || {});

  const newUrl = new URL(window.location.href);

  if (isMobile && newUrl.pathname !== '/login' && newUrl.pathname !== '/lar_app/create') {
    return (
      <Result title="Please use your desktop computer for the best experience. Starport's mobile app is coming soon." />
    );
  }

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={CustomErrorBoundary}>
        <main id="main">
          <MainRouter />
        </main>
        {productFruitsWorkspace && userData?.email && (
          <ProductFruits
            language="en"
            user={{
              email: userData.email,
              firstname: userData.name,
              lastname: userData.surname,
              role: userData.role,
              username: userData.email,
            }}
            workspaceCode={productFruitsWorkspace}
          />
        )}
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
