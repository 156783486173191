export const rejectReasons = [
  'Received all the lender feedback needed',
  'Did not receive lender feedback needed',
  'Other loan quotes provided were better',
  'Loan Amount was insufficient',
  'Interest rate was higher than requested',
  'Lender points were too high',
  "General loan terms didn't meet the loan request",
  'Closing days too long',
  'Not specified',
  'Have better loan offer from other source',
];
