import { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Col, Drawer, Form, Input, Radio, Row, Space, message } from 'antd';
import { useParams } from 'react-router-dom';
import { DashboardAPI } from '../../../api';

const documentationOptions = [
  {
    value: '1. Credit Report or individual tradeline item',
    label: '1. Credit Report or individual tradeline item',
  },
  {
    value: '2. Borrower identification or citizenship document',
    label: '2. Borrower identification or citizenship document',
  },
  {
    value: '3. Assets - Bk Statements or investment Docs',
    label: '3. Assets - Bk Statements or investment Docs',
  },
  {
    value: '4. Property - Appraisal, market, population',
    label: '4. Property - Appraisal, market, population',
  },
  {
    value: '5. Tenant information - such as Lease or Rent Roll',
    label: '5. Tenant information - such as Lease or Rent Roll',
  },
  {
    value: '6. Real Estate Experience - REO Record',
    label: '6. Real Estate Experience - REO Record',
  },
  {
    value: '7. Project Data - Proforma or Exec Summary',
    label: '7. Project Data - Proforma or Exec Summary',
  },
  {
    value: '8. Financial - transfers, reserves, gifting',
    label: '8. Financial - transfers, reserves, gifting',
  },

  {
    value: '9. Borrower - additional signer, guarantor',
    label: '9. Borrower - additional signer, guarantor',
  },
  {
    value: '10. Employment - type, timing, dates, clarification',
    label: '10. Employment - type, timing, dates, clarification',
  },
];

function RespondForm({ onCloseDrawer, id, currentBidData }) {
  const { response } = currentBidData;
  const API = useMemo(() => new DashboardAPI(), []);
  const [additionalInfo, setAdditionalInfo] = useState(response?.additional_information || []);
  const [checkedList, setCheckedList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(currentBidData.status !== 'responded');
  const onCheckboxChange = (list) => {
    setCheckedList(list);
  };

  const handleAdditionalInfoChange = (values) => {
    setAdditionalInfo((prev) => [...new Set([...values, ...prev])]);
  };

  const handleFormChange = () => {
    setIsEdited(true);
  };

  const [form] = Form.useForm();
  const CheckboxGroup = Checkbox.Group;
  const onFinish = (values) => {
    setLoading(true);

    const data = {
      lar_id: +id,
      loan_program_ready: values.loan_program_ready,
      loan_type_DSCR: values.qualification.includes('loan_type_DSCR') || false,
      loan_type_DTI: values.qualification.includes('loan_type_DTI') || false,
      doc_type_full: loan_type_DTI ? values.doc_type.includes('doc_type_full') : undefined,
      doc_type_partial: loan_type_DTI ? values.doc_type.includes('doc_type_partial') : undefined,
      doc_type_24_mo_statements: loan_type_DTI ? values.doc_type.includes('doc_type_24_mo_statements') : undefined,
      doc_type_12_mo_statements: loan_type_DTI ? values.doc_type.includes('doc_type_24_mo_statements') : undefined,
      doc_type_k_1_only: loan_type_DTI ? values.doc_type.includes('doc_type_k_1_only') : undefined,
      doc_type_1099_only: loan_type_DTI ? values.doc_type.includes('doc_type_1099_only') : undefined,
      doc_type_VOE_only: loan_type_DTI ? values.doc_type.includes('doc_type_VOE_only') : undefined,
      following_concerns: values.following_concerns,
      additional_information: additionalInfo,
      additional_comment: values.additional_comment || '',
    };

    API.scenarioRespond(data)
      .then(() => {
        message.success('Response sent to originator');
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setTimeout(() => {
          onCloseDrawer();
        }, 100);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };
  const qualification = Form.useWatch('qualification', form);
  const loan_type_DTI = qualification?.includes('loan_type_DTI');

  useEffect(() => {
    const doc_type = [
      'doc_type_full',
      'doc_type_partial',
      'doc_type_24_mo_statements',
      'doc_type_12_mo_statements',
      'doc_type_k_1_only',
      'doc_type_1099_only',
      'doc_type_VOE_only',
    ].filter((el) => !!response?.[el]);
    const qualification = ['loan_type_DSCR', 'loan_type_DTI'].filter((el) => !!response?.[el]);

    form.setFieldsValue({
      loan_program_ready: response?.loan_program_ready,
      qualification,
      doc_type,
      following_concerns: response?.following_concerns || '',
      additional_comment: response?.additional_comment || '',
      additional_information: response?.additional_information || [],
    });
  }, [response, form]);

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      onChange={handleFormChange}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label={<b>Would you be able to provide any type of loan program and/or terms to this borrower?</b>}
        name="loan_program_ready"
        rules={[
          {
            required: true,
            message: 'Please choose the option!',
          },
        ]}
      >
        <Radio.Group>
          <Space direction="vertical" size="middle">
            <Radio value>Yes</Radio>
            <Radio value={false}>Possibly, but we would need more information</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <div style={{ marginBottom: 10 }}>
        <b>Loan type we may consider</b>
      </div>
      <Form.Item
        name="qualification"
        label="Qualification"
        rules={[
          {
            required: true,
            message: 'Please select the checkbox!',
          },
        ]}
      >
        <CheckboxGroup
          options={[
            { value: 'loan_type_DSCR', label: 'DSCR' },
            { value: 'loan_type_DTI', label: 'DTI' },
          ]}
          value={checkedList}
          onChange={onCheckboxChange}
        />
      </Form.Item>
      {loan_type_DTI && (
        <Form.Item
          label="Income Doc Type"
          name="doc_type"
          rules={[
            {
              required: true,
              message: 'Please input !',
            },
          ]}
        >
          <Checkbox.Group>
            <Space size="middle" direction="vertical">
              <Checkbox value="doc_type_full">Full Doc</Checkbox>
              <Checkbox value="doc_type_partial">Partial Doc</Checkbox>
              <Checkbox value="doc_type_24_mo_statements">24 Mo. Bank Statements</Checkbox>
              <Checkbox value="doc_type_12_mo_statements">12 Mo. Bank Statements</Checkbox>
              <Checkbox value="doc_type_k_1_only">K-1 only</Checkbox>
              <Checkbox value="doc_type_1099_only">1099 only</Checkbox>
              <Checkbox value="doc_type_VOE_only">VOE only</Checkbox>
            </Space>
          </Checkbox.Group>
        </Form.Item>
      )}
      <Form.Item label={<b>We have the following concerns</b>} name="following_concerns">
        <Input.TextArea maxLength={500} autoSize={{ minRows: 2, maxRows: 6 }} />
      </Form.Item>
      <Form.Item
        label={<b>We would need the following documentation and/or information</b>}
        name="additional_information"
        value={additionalInfo}
      >
        <Checkbox.Group onChange={handleAdditionalInfoChange} value={additionalInfo} options={documentationOptions} />
      </Form.Item>
      <Form.Item label={<b>Add more information</b>} name="additional_comment">
        <Input.TextArea maxLength={500} autoSize={{ minRows: 2, maxRows: 6 }} placeholder="" />
      </Form.Item>
      <Row justify="end" gutter={16}>
        <Col span={8}>
          <Button size="large" onClick={onCloseDrawer} block>
            Cancel
          </Button>
        </Col>
        <Col span={8} flex="auto">
          <Button size="large" type="dark" htmlType="submit" disabled={!isEdited} block loading={loading}>
            {currentBidData.status === 'responded' ? 'Update Response' : 'Submit'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

function RespondDrawer({ onClose, visible, currentBidData }) {
  const { id } = useParams();

  return (
    <Drawer destroyOnClose width={500} title="Respond" onClose={onClose} placement="right" mask={false} open={visible}>
      <RespondForm currentBidData={currentBidData} id={id} onCloseDrawer={onClose} />
    </Drawer>
  );
}

export default RespondDrawer;
