export default function useNestedCheckEmpty() {
  const checkEmpty = (fields) => {
    const obj = { ...fields };
    let error = null;
    Object.entries(fields).forEach((f) => {
      Object.entries(f[1]).forEach((i) => {
        if (!f[1].required) {
          return;
        }
        if (f[0] === 'file') {
          if (i[0] === 'value' && !i[1]) {
            obj[f[0]].error = true;
            obj[f[0]].errorMessage = obj[f[0]].errorMessage ? obj[f[0]].errorMessage : 'Fill this label';
            error = true;
          }
        } else if (i[0] === 'value' && !i[1]?.toString().length) {
          obj[f[0]].error = true;
          obj[f[0]].errorMessage = obj[f[0]].errorMessage ? obj[f[0]].errorMessage : 'Fill this label';
          error = true;
        }
      });
    });

    return { obj, error };
  };

  return { checkEmpty };
}
