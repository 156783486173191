import { Button, message } from 'antd';
import PropTypes from 'prop-types';
import { CopyIcon } from 'assets/icons';
import styles from './CopyButton.module.css';

function CopyButton({ link, messageText }) {
  function handleCopyClick() {
    message.success(messageText);
    navigator.clipboard.writeText(link);
  }

  return (
    <Button className={styles.button} type="text" onClick={handleCopyClick} icon={<CopyIcon colour="#40a9ff" />}>
      Copy
    </Button>
  );
}

export default CopyButton;

CopyButton.defaultProps = {
  messageText: 'Link is copied to clipboard',
};

CopyButton.propTypes = {
  link: PropTypes.string.isRequired,
  messageText: PropTypes.string,
};
