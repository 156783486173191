import API from '../API';

export default class EMSAPI {
  async getTriggersList(page, pageSize) {
    return API()
      .get('/superadmin/triggers', {
        params: {
          page,
          per_page: pageSize,
        },
      })
      .then((res) => res.data);
  }

  async getChannel(id) {
    return API()
      .get('/superadmin/trigger-config', { params: { config_id: id } })
      .then((res) => res.data);
  }

  async search(query) {
    return API()
      .get('/superadmin/triggers/search', { params: { search: query } })
      .then((res) => res?.data?.triggers || []);
  }
}
