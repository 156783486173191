import { Breadcrumb } from 'antd';

function BreadCrumbHistory({ separator = '/', history, goBack, historyWeb }) {
  return (
    <div>
      <Breadcrumb separator={separator}>
        {history.map((item, key) => {
          const href = typeof item.to === 'string' ? item.to : item.to?.pathname;

          return (
            <Breadcrumb.Item
              key={key}
              href={href ?? null}
              onClick={(e) => {
                e.preventDefault();

                if (href) {
                  goBack(key);
                  historyWeb.push(item.to);
                }
              }}
            >
              {item.preview}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
}

export default BreadCrumbHistory;
