import './BDR/styles/prospects.css';
import './SuperAdmin/Users/styles/styles.css';
import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter';
import { Select, Table, message } from 'antd';
import { setBreadCrumbHistorySetValue } from 'store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import API from 'api';
import BreadCrumbHistory from 'components/BreadCrumbHistory';
import copyIcon from 'assets/images/copy.svg';
import PaginationItemRender from 'constants/PaginationItemRender';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import UserDrawer from 'components/UserDrawer';
import UserPreview from 'components/OnBoarding/Users/UserPreview';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 125,
    render: (value) => (
      <span className="id-column">
        {value}
        <img
          src={copyIcon}
          onClick={() => {
            message.success('Text was copied to clipboard.');
            navigator.clipboard.writeText(value);
          }}
          alt=""
        />
      </span>
    ),
  },
  {
    title: 'LAR Name',
    dataIndex: 'LAR_name',
    key: 'LAR_name',
    width: 150,
  },
  {
    title: 'Loan Manager',
    dataIndex: 'loan_manager',
    key: 'loan_manager',
    width: 200,
  },
  {
    title: 'Borrower',
    dataIndex: 'borrower',
    key: 'borrower',
    width: 200,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 150,
    render: (value) => {
      let color;

      switch (value) {
        case 'Pre Auction':
          color = '#40A9FF';
          break;
        case 'Incomplete':
          color = '#8C8C8C';
          break;
        case 'Live Auction':
          color = '#73D13D';
          break;
        case 'Quote Selection':
          color = '#FAAD14';
          break;
        default:
          color = '#5744E4';
      }

      return (
        <span className="status-tag">
          <div style={{ backgroundColor: color }} className="color-indicator" />
          {value}
        </span>
      );
    },
  },
];

export default function User({ isBorrower, isPLA }) {
  const [prospects, setProspects] = useState({ loading: false });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [activeModal, setActiveModal] = useState({ show: false, data: null });
  const [breadcrumbHistory, setBreadcrumbHistory] = useState([]);
  const userData = useSelector((store) => store.user.userData);
  const dispatch = useDispatch();
  const history = useHistory();
  const activeUsersTab = history?.location?.state?.tab;
  const { id } = useParams();
  const isAdmin = userData?.role.includes('Admin');

  async function fetchGetUser() {
    const params = { id: +id };
    if (!isBorrower && !isPLA) {
      params.tab = 'LARs';
    }
    const res = await API().get(isBorrower || isPLA ? '/superadmin/user/LARs' : '/user_settings/company/members', {
      params,
    });
    console.log(res, 'response');
    setActiveModal((state) => ({ ...state, data: res.data.user }));
  }

  useEffect(() => {
    const params = { id: +id };

    if (!isBorrower && !isPLA) {
      params.tab = 'LARs';
    }

    // TODO: Need pagination here - backend
    API()
      .get(isBorrower || isPLA ? '/superadmin/user/LARs' : '/user_settings/company/members', {
        params,
      })
      .then((res) => {
        setPagination((prevState) => ({ ...prevState, total: res.data.total }));
        setProspects({ loading: true, ...res.data });
        setActiveModal((state) => ({ ...state, data: res.data.user }));

        const activeBreadcrumb = {
          preview: res.data.user.full_name,
          to: history.location.pathname,
        };

        if (isPLA) {
          const kind = history.location.pathname.split('/')[2];

          setBreadcrumbHistory([
            {
              preview: capitalizeFirstLetter(kind),
              to: {
                pathname: `/onboarding/${kind}`,
                state: { tab: activeUsersTab },
              },
            },
            activeBreadcrumb,
          ]);
        } else {
          setBreadcrumbHistory([
            {
              preview: 'Company users',
              to: {
                pathname: '/onboarding/company-settings/teams',
                state: { tab: activeUsersTab },
              },
            },
            activeBreadcrumb,
          ]);
        }
      });
  }, [history, activeUsersTab, id, isBorrower, isPLA]);

  const onCloseActiveModal = () => setActiveModal((state) => ({ ...state, show: false }));

  const onOpenActiveModal = () => setActiveModal((state) => ({ ...state, show: true }));

  const goBack = (key) => dispatch(setBreadCrumbHistorySetValue(key));

  const onSelectRole = (val) => {
    API()
      .post('/user_settings/company/members', { id: activeModal.data.id, role_name: val })
      .then(() => {
        setActiveModal((state) => ({
          ...state,
          data: {
            ...state.data,
            role_name: val,
          },
        }));
      });
  };

  const user = activeModal.data;
  return (
    <div className="prospects_page_wrapper table_with_header_wrapper table_with_pagination_wrapper">
      {breadcrumbHistory.length ? (
        <BreadCrumbHistory history={breadcrumbHistory} goBack={goBack} historyWeb={history} separator="/" />
      ) : null}
      <div className="section_title_wrapper">
        <UserPreview
          name={prospects?.user?.full_name}
          avatar={prospects?.user?.profile_photo}
          onView={onOpenActiveModal}
        />
      </div>
      <div className="user-role">
        {isAdmin ? (
          <Select onChange={onSelectRole} style={{ width: 250 }} value={activeModal?.data?.role_name}>
            {user?.available_roles.map((role, id) => (
              <Select.Option value={role} key={id}>
                {role}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <span>{activeModal?.data?.role_name}</span>
        )}
      </div>
      <div className="section_table_wrapper table_with_header table_with_pagination">
        <Table
          columns={columns}
          pagination={{
            showSizeChanger: true,
            defaultCurrent: pagination.current,
            total: pagination.total ?? null,
            itemRender: PaginationItemRender,
          }}
          onChange={(e) => setPagination(e)}
          scroll={{ x: 1100 }}
          rowKey="id"
          title={() => (
            <div className="table-header-wrapper">
              <SectionTitle title="LARS" type="subheading-16" />
            </div>
          )}
          border
          bordered={false}
          dataSource={prospects.LARs}
        />
      </div>
      <UserDrawer fetchGetUser={fetchGetUser} user={user} visible={activeModal.show} onClose={onCloseActiveModal} />
    </div>
  );
}
