export const CLOSE_POPUP = 'CLOSE_POPUP';
export const SHOW_POPUP = 'SHOW_POPUP';
export const GET_FEES_WORKSHEETS = 'GET_FEES_WORKSHEETS';
export const USER_DATA = 'USER_DATA';
export const BREAD_CRUMB_HISTORY = 'BREAD_CRUMB_HISTORY';
export const LEFT_PANEL_SIZE = 'LEFT_PANEL_SIZE';
export const BREAD_CRUMB_HISTORY_SET_VALUE = 'BREAD_CRUMB_HISTORY_SET_VALUE';
export const ACTIVE_ROUTE = 'ACTIVE_ROUTE';
export const CHAT_STATE = 'CHAT_STATE';
export const UPDATE_CHAT_CONNECTION = 'UPDATE_CHAT_CONNECTION';
export const UNREAD_CHAT_MESSAGES = 'UNREAD_CHAT_MESSAGES';
