function DQConfirmation() {
  return (
    <div>
      <h3>Direct Quote Selection</h3>
      <p>You have received a direct quote from a lender. You can do several things: </p>
      <ol>
        <li>Accept or Reject the quote</li>
        <li>Share the quote with the client borrower</li>
        <li>Send a message to the lender </li>
      </ol>
      <p>
        When you share the quote with the borrower, they will be able to respond by indicating if they are interested in
        this quote or not interested.
      </p>
      <p>
        However, for the deal to move forward, you must select the button on this page to accept or reject this quote.{' '}
      </p>
    </div>
  );
}

export default DQConfirmation;
