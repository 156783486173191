import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { DashboardAPI } from 'api';

import DashboardWidgets from 'components/Dashboard/DashboardWidgets';
import ItemTable from 'components/Dashboard/ItemTable';
import PLADashboardWidgets from 'components/Dashboard/PLADashboardWidgets';

import styles from './DashboardContent.module.css';

const tabs = {
  active_dials: 'Active loans',
  auctions: 'Auctions',
  scenarios: 'Scenarios',
};

function DashboardContent() {
  const activeTabFromStorage = sessionStorage.getItem('activeTab');
  const [activeTab, setActiveTab] = useState(activeTabFromStorage || 'auctions');
  const { role } = useSelector((s) => s.user.userData);
  const { metrics } = useSelector((store) => store.dashboard);
  const API = useMemo(() => new DashboardAPI(), []);
  const isPLA = /pla|superadmin/i.test(role);

  return (
    <div className={styles.root}>
      {isPLA ? (
        <PLADashboardWidgets
          activeItems={metrics.active_items}
          auctionEnd={metrics.next_auction_end}
          auctionStart={metrics.next_auction}
        />
      ) : (
        <DashboardWidgets activeTab={activeTab} metrics={metrics} setActiveTab={setActiveTab} />
      )}
      <ItemTable activeTab={isPLA ? undefined : activeTab} API={API} title={isPLA ? undefined : tabs[activeTab]} />
    </div>
  );
}

export default DashboardContent;
