import { Button, Modal, Tooltip } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import styles from './BorrowerSubmitPopup.module.css';
import CopyButton from 'components/UI/CopyButton/CopyButton';

function BorrowerSubmitPopup({ open, onCancel, larId, brokerName, link }) {
  const history = useHistory();

  function redirectToDashboard() {
    onCancel();
    history.push('/');
  }

  return (
    <Modal
      className={styles.borrowerSubmitPopup}
      open={open}
      title="Next Steps"
      onCancel={redirectToDashboard}
      footer={[
        <Button type="dark" size="large" onClick={redirectToDashboard}>
          Open Dashboard
        </Button>,
      ]}
    >
      <h4>Thanks for submitting this new loan request! </h4>
      <p>
        The next step is for me to review the information provided. If everything looks good, then I'll begin reaching
        out to lenders.
      </p>
      <p>
        To review or edit your loan request, you can login to my loan portal with the link below. I suggest you click on
        the button now to open a new browser with the loan portal. You can save it to your browser bookmarks.
      </p>
      <div className={styles.popupBodyInsert}>
        <div className={styles.popupLinkWrapper}>
          <p className={styles.popupLink}>
            Link: <Tooltip title={link}>{link}</Tooltip>
          </p>
          <CopyButton link={link} />
        </div>
        <Link to={`/onboarding/loan_profile/${larId}`} className={styles.openLoanProfileLink}>
          Open Loan Profile
        </Link>
      </div>
      <p>You'll also receive an automated email with the same information. </p>
      <p>Thank you for the opportunity to work on this deal. </p>
      <p>
        All the best,
        <br />
        {brokerName}
      </p>
    </Modal>
  );
}

export default BorrowerSubmitPopup;
