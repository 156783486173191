import { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { Modal, Radio, Table } from 'antd';
import { ChevronIcon, InfoCircleIcon } from 'assets/icons';
import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter';
import { LARAPI } from 'api';
import { useGetLARLink } from 'hooks/useGetLARLink';
import DeleteLARModal from 'components/LARDB/DeleteLARModal';
import NotesDrawer from 'components/Dashboard/NotesDrawer';
import SearchBox from 'components/Dashboard/SearchBox';
import Actions from 'components/Dashboard/ItemTable/Actions';
import styles from 'components/Dashboard/ItemTable/ItemTable.module.css';
import DealExpirationNote from 'components/LarAppNew/Modals/DealExpirationNoteModal/DealExpirationNoteModal';

const tabs = [
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'scenarios',
    label: 'Scenarios',
  },
  {
    value: 'auctions',
    label: 'Auctions',
  },
  {
    value: 'active_dials',
    label: 'Active deals',
  },
  {
    value: 'archived',
    label: 'Archived',
  },
];

class Column {
  constructor(title, dataIndex, sortBy, setSortBy, sortingDirection, setSortingDirection, render, width) {
    function headerCellClickHandler() {
      if (!sortBy) {
        return;
      }

      if (dataIndex !== sortBy) {
        setSortBy(dataIndex);
        return;
      }

      setSortingDirection((prev) => (prev === 'desc' ? 'asc' : 'desc'));
    }

    this.title = (
      <div onClick={headerCellClickHandler} className={styles.tableHeaderCell}>
        {title}
        {dataIndex === sortBy && <ChevronIcon direction={sortingDirection === 'desc' ? 'down' : 'up'} />}
      </div>
    );
    this.render = render;
    this.dataIndex = dataIndex;
    this.width = width;
  }
}

function ItemTable({ API, activeTab, title }) {
  const role = useSelector((s) => s.user.userData?.role);
  const isPLA = useMemo(() => /pla|superadmin/i.test(role), [role]);
  const [createNoteDrawer, setCreateNoteDrawer] = useState({ visible: false, text: '' });
  const [data, setData] = useState([]);
  const [deleteModalId, setDeleteModalId] = useState(0);
  const [notes, setNotes] = useState({ visible: false, data: null });
  const [pagination, setPagination] = useState({ default: { current: 1, pageSize: 50 } });
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [ready, setReady] = useState(false);
  const [sortBy, setSortBy] = useState('id');
  const [sortingDirection, setSortingDirection] = useState('desc');
  const [tab, setTab] = useState(activeTab || tabs[0].value);
  const { getLARLink } = useGetLARLink();
  const history = useHistory();

  const [expirationNoteModal, setExpirationNoteModal] = useState(false);

  function handleCloseExpirationNoteModal() {
    setExpirationNoteModal(false);
  }

  function handleOpenExpirationNoteModal(e) {
    e.stopPropagation();
    setExpirationNoteModal(true);
  }

  function onDeleteSuccess() {
    setDeleteModalId(0);
    updateData();
  }

  function getColumn(title, key, props) {
    const render = props?.render || undefined;
    const width = props?.width || undefined;
    const sortByValue = props?.sorting === false ? null : sortBy;
    return new Column(title, key, sortByValue, setSortBy, sortingDirection, setSortingDirection, render, width);
  }

  const updateData = useCallback(() => {
    const p = pagination[activeTab || tab] || pagination.default;
    setReady(false);
    const r = isPLA ? 'PLA' : 'borrower';

    API.getDashboardPipelines(r, {
      order_by: sortBy,
      order_type: sortingDirection,
      page: p.current,
      per_page: p.pageSize,
      lar_type: activeTab || tab,
    }).then(({ lars, total_count }) => {
      setData(lars);
      setPaginationTotal(total_count);
      setReady(true);
    });
  }, [API, role, pagination, activeTab, tab, sortBy, sortingDirection]);
  useEffect(updateData, [updateData]);

  const setNotesDrawer = useCallback((id) => {
    const API = new LARAPI(id);

    API.getNotes().then((data) => {
      setNotes((prev) => ({
        ...prev,
        data: {
          ...data,
          notes: Object.entries(data.notes).reverse(),
        },
        visible: true,
      }));
    });
  }, []);

  const actionsNeededCol = [getColumn('Property Type', 'property_type')];

  if (process.env.REACT_APP_NODE_ENV === 'development') {
    actionsNeededCol.push({
      dataIndex: 'active_actions',
      render: (n) =>
        n > 0 && <div className={classNames(styles.actionsNumber, styles[n > 4 ? 'red' : 'yellow'])}>{n}</div>,
      title: 'Actions needed',
    });
  }

  function dateToET(date) {
    return moment(moment.utc(date).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss.SSS'));
  }

  const columns = useMemo(
    () => [
      getColumn('Deal type', 'type', {
        render: (s) => (s ? capitalizeFirstLetter(s) : s),
      }),
      getColumn('ID', 'id'),
      ...(/pla|superadmin/i.test(role)
        ? [
            getColumn('Last Updated', 'last_updated', {
              render: (d) => (d ? moment(d, 'DD MMM YYYY, h:mm A').format('DD MMM h:mm A') : ''),
            }),
          ]
        : []),
      getColumn('Status', 'status'),
      ...(['scenarios', 'auctions'].includes(tab) && /pla|superadmin|borrower/i.test(role)
        ? [
            getColumn(
              <div onClick={handleOpenExpirationNoteModal} className={styles.expiresTitle}>
                Expires
                <InfoCircleIcon size={18} />
              </div>,
              'archiving_date',
              {
                render: (el, info) => {
                  const time = el && dateToET(el);
                  const today = el && dateToET(moment());
                  return info.status === 'Active' || info.status === 'Quote Selection' ? (
                    <div className={classNames(el && time.diff(today, 'days') < 1 && styles.dateRed)}>
                      {el && moment(el).format('DD MMM')}
                    </div>
                  ) : null;
                },
              },
            ),
          ]
        : []),
      ...(/borrower/i.test(role) ? [getColumn('Closing date', 'closing_date'), getColumn('Street', 'street')] : []),
      ...(/broker/i.test(role)
        ? [
            getColumn('Date Created', 'created_at', {
              render: (d) => (d !== 'None' ? new Date(d).toLocaleString() : ''),
            }),
            getColumn('Borrower', 'borrower'),
          ]
        : []),
      ...(/borrower/i.test(role) ? [] : [getColumn('Deal Owner', 'deal_owner')]),
      getColumn('Purpose', 'purpose'),
      ...(/borrower/i.test(role) ? [getColumn('Style', 'style')] : actionsNeededCol),
      {
        dataIndex: 'actions',
        render: (_, el) => (
          <Actions
            meta={el}
            setDeleteModalId={setDeleteModalId}
            setNotesDrawer={setNotesDrawer}
            updateData={updateData}
          />
        ),
        title: '',
      },
    ],
    [sortBy, sortingDirection, role, setDeleteModalId, setNotesDrawer, updateData],
  );

  function navigateToItem(id, type, status, restArgs) {
    history.push(getLARLink(id, type, status, restArgs));
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>{title || 'Deal Pipeline Report'}</h1>
        {Boolean(activeTab) || (
          <Radio.Group value={tab} onChange={({ target: t }) => setTab(t.value)}>
            {tabs.map(({ value, label }) => (
              <Radio.Button value={value} key={value}>
                {label}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
        <SearchBox API={API} navigateToItem={navigateToItem} />
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={!ready}
        onChange={(p) => {
          setPagination((prev) => ({
            ...prev,
            [activeTab || tab]: p,
          }));
        }}
        onRow={({ id, type, status, cognito_token, borrower_id }) => ({
          onClick: () => navigateToItem(id, type, status, { cognito_token, borrower_id }),
        })}
        pagination={{
          ...(pagination[activeTab || tab] || pagination.default),
          showSizeChanger: true,
          total: paginationTotal,
        }}
        rowKey="id"
        size="middle"
      />
      <DeleteLARModal
        larId={deleteModalId}
        onCancel={onDeleteSuccess}
        visible={deleteModalId > 0}
        onDeleteSuccess={onDeleteSuccess}
      />
      <NotesDrawer
        createNoteDrawer={createNoteDrawer}
        notes={notes}
        setCreateNoteDrawer={setCreateNoteDrawer}
        setNotes={setNotes}
      />
      <Modal
        footer=""
        title={<p style={{ fontSize: 18 }}>Deal Expiration Note</p>}
        open={expirationNoteModal}
        onCancel={handleCloseExpirationNoteModal}
        bodyStyle={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 24, paddingTop: 10 }}
      >
        <DealExpirationNote />
      </Modal>
    </div>
  );
}

export default ItemTable;
