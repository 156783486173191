import { memo } from 'react';
import colours from './colours';
import GenericIcon from './GenericIcon';

export default memo(({ variant = 1, colour = 'grey-7', ...params }) => (
  <GenericIcon colour={colour} {...params}>
    {variant === 1 ? (
      <>
        <path d="M10.5 12H14.5" />
        <path d="M19.5 4H5.5C4.39543 4 3.5 4.89543 3.5 6C3.5 7.10457 4.39543 8 5.5 8H19.5C20.6046 8 21.5 7.10457 21.5 6C21.5 4.89543 20.6046 4 19.5 4Z" />
        <path d="M5.5 8V18C5.5 18.5304 5.71071 19.0391 6.08579 19.4142C6.46086 19.7893 6.96957 20 7.5 20H17.5C18.0304 20 18.5391 19.7893 18.9142 19.4142C19.2893 19.0391 19.5 18.5304 19.5 18V8" />
      </>
    ) : (
      <>
        <path d="M0 0h24v24H0z" stroke="none" fill="none" />
        <path
          d="M20.54 5.23l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zM12 17.5L6.5 12H10v-2h4v2h3.5L12 17.5zM5.12 5l.81-1h12l.94 1H5.12z"
          fill={colours.get(colour) || colour}
          stroke="none"
        />
      </>
    )}
  </GenericIcon>
));
