import { Typography } from 'antd';
import { useMemo } from 'react';
import { LARAPI } from 'api';
import Modal from 'components/Modals/Modal/Modal';

function UnarchiveLARModal({ callback = () => null, larId, ...rest }) {
  const API = useMemo(() => new LARAPI(larId), [larId]);

  return (
    <Modal
      {...rest}
      onOk={() => API.unarchive().then(callback)}
      okText="Confirm"
      title="Are you sure you want to restore this deal?"
    >
      <Typography.Paragraph>
        Restored deals will be added to the Pending pipeline table where you can review, edit, and select to resubmit as
        a scenario or auction.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Note that all activity and messages will be erased from the deal when it is restored. Only information from the
        loan request form will be retained.
      </Typography.Paragraph>
    </Modal>
  );
}

export default UnarchiveLARModal;
