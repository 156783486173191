import { GET_DASHBOARD_METRICS, UPDATE_DASHBOARD_METRICS_TIMESTAMP, UPDATE_DASHBOARD_TIMER } from '../types/dashboard';

export const getDashboardMetrics = (payload) => ({
  type: GET_DASHBOARD_METRICS,
  payload,
});

export const updateDashboardMetricsTimestamp = () => ({
  type: UPDATE_DASHBOARD_METRICS_TIMESTAMP,
});

export const updateDashboardTimer = (payload) => ({
  type: UPDATE_DASHBOARD_TIMER,
  payload,
});
