import { Table, Tabs, Tooltip } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { LARAPI } from 'api';
import { useGetLARLink } from 'hooks/useGetLARLink';
import DeleteLARModal from 'components/LARDB/DeleteLARModal';
import larPlaceholderImg from 'assets/images/LAR_placeholder.svg';
import { ChevronIcon, InfoCircleIcon } from 'assets/icons';
import SearchBox from '../../SearchBox';
import NotesDrawer from '../../NotesDrawer';
import Actions from '../../ItemTable/Actions';
import styles from './BrokerTable.module.css';
import Modal from 'components/Modals/Modal';
import DealExpirationNote from 'components/LarAppNew/Modals/DealExpirationNoteModal/DealExpirationNoteModal';

class Column {
  constructor(title, dataIndex, sortBy, setSortBy, sortingDirection, setSortingDirection, render, width, tooltip) {
    function headerCellClickHandler() {
      if (!sortBy) {
        return;
      }

      if (dataIndex !== sortBy) {
        setSortBy(dataIndex);
        return;
      }

      setSortingDirection((prev) => (prev === 'desc' ? 'asc' : 'desc'));
    }

    this.title = (
      <Tooltip title={tooltip}>
        <div onClick={headerCellClickHandler} className={styles.tableHeaderCell}>
          {title}
          {dataIndex === sortBy && <ChevronIcon direction={sortingDirection === 'desc' ? 'down' : 'up'} />}
        </div>
      </Tooltip>
    );
    this.render = render;
    this.dataIndex = dataIndex;
    this.width = width;
  }
}

const tabs = [
  {
    key: 'pending',
    label: 'Pending',
  },
  {
    key: 'scenarios',
    label: 'Scenarios',
  },
  {
    key: 'auctions',
    label: 'Auctions',
  },
  {
    key: 'active',
    label: 'Active loans',
  },
  {
    key: 'archived',
    label: 'Archived',
  },
];

export default function BrokerTable({ API }) {
  const { getLARLink } = useGetLARLink();
  const history = useHistory();
  const role = useSelector((s) => s.user.userData?.role);

  const [data, setData] = useState({ lars: [], total_count: 0 });
  const [deleteModalId, setDeleteModalId] = useState(0);
  const [createNoteDrawer, setCreateNoteDrawer] = useState({ visible: false, text: '' });
  const [notes, setNotes] = useState({ visible: false, data: null });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const [isFetching, setIsFetching] = useState(false);
  const [orderBy, setOrderBy] = useState('id');
  const [orderType, setOrderType] = useState('desc');
  const [tab, setTab] = useState(tabs[0].key);

  const [expirationNoteModal, setExpirationNoteModal] = useState(false);

  function handleCloseExpirationNoteModal() {
    setExpirationNoteModal(false);
  }

  function handleOpenExpirationNoteModal(e) {
    e.stopPropagation();
    setExpirationNoteModal(true);
  }

  function handleCloseDeleteModal() {
    setDeleteModalId(0);
  }

  function handleDeleteSuccess() {
    setDeleteModalId(0);
    updateData();
  }

  function getColumn(title, key, props) {
    const render = props?.render || undefined;
    const width = props?.width || undefined;
    const sortByValue = props?.sorting === false ? null : orderBy;
    return new Column(title, key, sortByValue, setOrderBy, orderType, setOrderType, render, width, props?.tooltip);
  }

  const updateData = useCallback(() => {
    setIsFetching(false);

    API.getDashboardPipelines('broker', {
      order_by: orderBy,
      order_type: orderType,
      page: pagination.current,
      per_page: pagination.pageSize,
      lar_type: tab,
    }).then((data) => {
      data.lars = data?.lars.map((lar) => ({
        ...lar,
        ...(lar?.['city/state']
          ? {
              'city/state': lar['city/state'].replace('unknown,', 'NY, '),
            }
          : {}),
        type: tab,
      }));
      setData(data);
      setIsFetching(true);
    });
  }, [API, pagination, tab, orderBy, orderType]);

  useEffect(updateData, [updateData]);

  const setNotesDrawer = useCallback((id) => {
    const API = new LARAPI(id);

    API.getNotes().then((data) => {
      setNotes((prev) => ({
        ...prev,
        data: {
          ...data,
          notes: Object.entries(data.notes).reverse(),
        },
        visible: true,
      }));
    });
  }, []);

  function dateToET(date) {
    return moment(moment.utc(date).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss.SSS'));
  }

  const columns = useMemo(
    () => [
      getColumn('Picture', 'preview', {
        sorting: false,
        render: (_, el) => <img src={el.preview || larPlaceholderImg} width={130} height={80} alt="" />,
      }),
      ...(['active'].includes(tab) ? [getColumn('Closing Date', 'closing_date')] : []),
      getColumn('ID', 'id'),
      ...(!['auctions'].includes(tab)
        ? [
            getColumn('Last Updated', 'last_updated', {
              render: (d) => (d ? <div className={styles.date}>{moment(d).format('DD MMM h:mm A')}</div> : ''),
            }),
          ]
        : []),
      ...(tab !== 'active' ? [getColumn('Status', 'status')] : []),
      ...(['scenarios', 'auctions'].includes(tab)
        ? [
            getColumn(
              <div onClick={handleOpenExpirationNoteModal} className={classNames(styles.expiresTitle)}>
                Expires
                <InfoCircleIcon size={18} />
              </div>,
              'archiving_date',
              {
                render: (el, info) => {
                  const time = el && dateToET(el);
                  const today = el && dateToET(moment());
                  return info.status === 'Active' || info.status === 'Quote Selection' ? (
                    <div className={classNames(el && time.diff(today, 'days') < 1 && styles.dateRed)}>
                      {el && moment(el).format('DD MMM')}
                    </div>
                  ) : null;
                },
              },
            ),
          ]
        : []),
      ...(tab === 'scenarios' ? [getColumn('Days Active', 'days_active')] : []),
      getColumn('MLO', 'BLM'),
      ...(['scenarios', 'active'].includes(tab) ? [getColumn('Borrower/Deal Name', 'borrower/deal_name')] : []),
      ...(['active'].includes(tab)
        ? [
            getColumn('LAE/Company', 'LAE/company'),
            getColumn('Owed', 'owed', {
              render: (_, el) => <div className={classNames(styles.badge, styles.badgeRed)}>{el.owed || 0}</div>,
              width: 40,
            }),
            getColumn('MLO Review', 'MLO_review', {
              render: (_, el) => (
                <div className={classNames(styles.badge, styles.badgeOrange)}>{el.MLO_review || 0}</div>
              ),
              width: 40,
            }),
            getColumn('Lender Accepted', 'lender_accepted', {
              render: (_, el) => (
                <div className={classNames(styles.badge, styles.badgeGreen)}>{el.lender_accepted || 0}</div>
              ),
              width: 40,
            }),
          ]
        : []),
      ...(['pending', 'auctions', 'archived'].includes(tab) ? [getColumn('Borrower', 'borrower')] : []),
      ...(['pending', 'scenarios', 'archived'].includes(tab) ? [getColumn('City/State', 'city/state')] : []),
      ...(['pending', 'auctions', 'archived'].includes(tab) ? [getColumn('Deal Name', 'deal_name')] : []),
      ...(['auctions'].includes(tab)
        ? [
            getColumn('Auction Date', 'auction_date', {
              render: (_, el) => (el.auction_date ? moment(el.auction_date).format('ddd MMM DD') : ''),
            }),
            getColumn('Invites', 'invites'),
            getColumn('Viewed by', 'views', {
              tooltip: 'Number of invited lenders that viewed the deal profile',
            }),
            getColumn('Quotes Received', 'quotes'),
          ]
        : []),
      ...(['scenarios'].includes(tab)
        ? [
            getColumn('Invites', 'invites'),
            getColumn('Viewed by', 'views', {
              tooltip: 'Number of invited lenders that viewed the deal profile',
            }),
            getColumn('Responses', 'responses'),
            getColumn('Quotes', 'quotes'),
          ]
        : []),
      ...(process.env.REACT_APP_NODE_ENV === 'development'
        ? [
            {
              dataIndex: 'active_actions',
              render: (n) =>
                n > 0 && <div className={classNames(styles.actionsNumber, styles[n > 4 ? 'red' : 'yellow'])}>{n}</div>,
              title: 'Actions needed',
            },
          ]
        : []),
      {
        dataIndex: 'actions',
        render: (_, el) => (
          <Actions
            meta={el}
            setDeleteModalId={setDeleteModalId}
            setNotesDrawer={setNotesDrawer}
            updateData={updateData}
          />
        ),
        title: '',
      },
    ],
    [orderBy, tab, orderType, role, setDeleteModalId, setNotesDrawer, updateData],
  );

  function navigateToItem(id, type, status, restArgs) {
    history.push(getLARLink(id, type, status, restArgs));
  }

  const handleSetTab = (tab) => {
    setTab(tab);
    setOrderBy('id');
    setOrderType('desc');
    setPagination({ current: 1, pageSize: 10 });
  };

  console.log(data);

  return (
    <div className={classNames(styles.root)}>
      <div className={styles.header}>
        <Tabs defaultActiveKey={tab} items={tabs} type="card" onChange={handleSetTab} />
        <SearchBox API={API} navigateToItem={navigateToItem} />
      </div>
      <Table
        columns={columns}
        dataSource={data.lars}
        loading={!isFetching}
        onChange={(p) => {
          setPagination((prev) => ({
            ...prev,
            ...p,
          }));
        }}
        onRow={({ id, type, status, cognito_token, borrower_id }) => ({
          onClick: () => navigateToItem(id, type, status, { cognito_token, borrower_id }),
        })}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          total: data.total_count,
        }}
        rowKey="id"
        size="middle"
      />
      <DeleteLARModal
        larId={deleteModalId}
        visible={deleteModalId > 0}
        onCancel={handleCloseDeleteModal}
        onDeleteSuccess={handleDeleteSuccess}
      />
      <NotesDrawer
        createNoteDrawer={createNoteDrawer}
        notes={notes}
        setCreateNoteDrawer={setCreateNoteDrawer}
        setNotes={setNotes}
      />
      <Modal
        footer=""
        title={<p style={{ fontSize: 18 }}>Deal Expiration Note</p>}
        open={expirationNoteModal}
        onCancel={handleCloseExpirationNoteModal}
        bodyStyle={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 24, paddingTop: 10 }}
      >
        <DealExpirationNote />
      </Modal>
    </div>
  );
}
