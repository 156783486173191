import classNames from 'classnames';
import { Button } from 'antd';

function IconButton({ icon, alt, text = '', handleClick, classes = [] }) {
  return (
    <Button type="text" className={classNames(['icon-button', classes])} onClick={handleClick}>
      <img src={icon} alt={alt} />
      {text && <div className="icon-button-text">{text}</div>}
    </Button>
  );
}

export default IconButton;
