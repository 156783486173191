import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <path
      d="M0.8 19.28L10.4 9.68001L9 8.26001L8.28 8.97001C7.89 9.36001 7.26 9.36001 6.87 8.97001L6.16 8.26001C5.77 7.87001 5.77 7.24001 6.16 6.85001L11.82 1.19001C12.21 0.800007 12.84 0.800007 13.23 1.19001L13.94 1.90001C14.33 2.29001 14.33 2.92001 13.94 3.31001L13.23 4.00001L14.65 5.43001C15.04 5.04001 15.67 5.04001 16.06 5.43001C16.45 5.82001 16.45 6.46001 16.06 6.85001L17.47 8.26001L18.18 7.55001C18.57 7.16001 19.21 7.16001 19.6 7.55001L20.3 8.26001C20.69 8.65001 20.69 9.29001 20.3 9.68001L14.65 15.33C14.26 15.72 13.62 15.72 13.23 15.33L12.53 14.63C12.13 14.24 12.13 13.6 12.53 13.21L13.23 12.5L11.82 11.09L2.21 20.7C1.82 21.09 1.19 21.09 0.8 20.7C0.41 20.31 0.41 19.67 0.8 19.28ZM18.5 18C19.0304 18 19.5391 18.2107 19.9142 18.5858C20.2893 18.9609 20.5 19.4696 20.5 20V21H10.5V20C10.5 19.4696 10.7107 18.9609 11.0858 18.5858C11.4609 18.2107 11.9696 18 12.5 18H18.5Z"
      fill="#8C8C8C"
    />
  </GenericIcon>
));
