import './styles/users.css';
import { Badge, Table, Tabs } from 'antd';
import { BDRAPI } from 'api';
import { ChevronIcon } from 'assets/icons';
import { formatPhone } from 'constants/formatPhone';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { replacedTextData } from 'constants/common';
import PaginationItemRender from 'constants/PaginationItemRender';
import SearchBox from 'components/OnBoarding/BDR/SearchBox';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import UserDrawer from 'components/UserDrawer';
import classes from './styles/Users.module.css';

class Column {
  constructor(label, key, sorting, setSorting, render, hasSorting = true) {
    this.title = hasSorting ? (
      <div
        className={classes.tableColumnHeader}
        onClick={() => {
          setSorting((prev) => ({
            column: key,
            direction: key === prev.column ? (prev.direction === 'asc' ? 'desc' : 'asc') : 'desc',
          }));
        }}
      >
        {label}
        {key === sorting.column && <ChevronIcon direction={sorting.direction === 'asc' ? 'up' : 'down'} />}
      </div>
    ) : (
      <div className={classNames([classes.tableColumnHeader, classes.tableColumnHeaderWithoutSorting])}>{label}</div>
    );
    this.dataIndex = key;
    this.render = render;
  }
}

export default function Users() {
  const [activeModal, setActiveModal] = useState({ show: false, data: null });
  const [activeTab, setActiveTab] = useState('broker');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [paginationTotal, setPaginationTotal] = useState(1);
  const [ready, setReady] = useState(false);
  const [sorting, setSorting] = useState({ column: 'id', direction: 'desc' });
  const [users, setUsers] = useState([]);

  const API = useMemo(() => new BDRAPI(), []);
  useEffect(() => {
    setReady(false);

    API.getUsers(true, activeTab, pagination.current, pagination.pageSize, sorting.column, sorting.direction).then(
      ({ users, total }) => {
        setPaginationTotal(total);
        setReady(true);
        setUsers(users);
      },
    );
  }, [activeTab, pagination, API, sorting]);

  const columns = useMemo(
    () => [
      new Column('Name', 'full_name', sorting, setSorting),
      new Column('Type', 'user_type', sorting, setSorting, false),
      new Column('Role', 'role', sorting, setSorting, (v) => replacedTextData[v] || v),
      new Column('Company', 'company_name', sorting, setSorting),
      new Column('Email', 'email', sorting, setSorting),
      new Column('Phone', 'phone', sorting, setSorting, (n) => (Number(n) ? formatPhone(`+1${n}`) : null), false),
      new Column('Enabled', 'enabled', sorting, setSorting, (enabled) => (
        <Badge status={enabled ? 'success' : 'error'} text={enabled ? 'Enabled' : 'Disabled'} />
      )),
      new Column('Most recent login', 'last_login', sorting, setSorting),
    ],
    [sorting],
  );

  return (
    <div
      className={classNames(
        'table_with_header_wrapper',
        'table_with_pagination_wrapper',
        'users_bdr_page_wrapper',
        classes.root,
      )}
    >
      <SectionTitle title="Users" />
      <SearchBox
        userType={`user_${activeTab}`}
        onResultClick={(user) => {
          setActiveModal((prev) => ({
            ...prev,
            data: user,
            show: true,
          }));
        }}
      />
      <div
        className={classNames(
          'section_table_wrapper',
          'table_with_header',
          'table_with_pagination',
          classes.tableWrapper,
        )}
      >
        <Table
          bordered={false}
          columns={columns}
          dataSource={users}
          loading={!ready}
          rowKey="id"
          pagination={{
            current: pagination.current,
            itemRender: PaginationItemRender,
            showSizeChanger: true,
            total: paginationTotal ?? null,
          }}
          onRow={(row) => ({
            onClick: () => {
              setActiveModal((prev) => ({
                ...prev,
                data: row,
                show: true,
              }));
            },
          })}
          onChange={(e) => setPagination(e)}
          scroll={{ x: 1800 }}
          title={() => (
            <Tabs
              defaultActiveKey={activeTab}
              onChange={(val) => setActiveTab(val)}
              items={[
                {
                  key: 'broker',
                  label: 'Originators',
                },
                {
                  key: 'lender',
                  label: 'Lenders',
                },
              ]}
            />
          )}
        />
      </div>
      <UserDrawer
        onClose={() => setActiveModal((state) => ({ ...state, show: false }))}
        user={activeModal.data}
        visible={activeModal.show}
      />
    </div>
  );
}
