import { Button } from 'antd';

import Modal from 'components/Modals/Modal';
import LARCard from '../../../LARCard';

function LARDBSelectWinModal({ selectedQuote, visible, loading, onOk, onClose }) {
  return (
    <Modal
      className="confirm-quote"
      closable={false}
      footer={
        <Button size="large" type="dark" block loading={loading} onClick={() => onOk(selectedQuote?.quote_id)}>
          Confirm
        </Button>
      }
      okText="Confirm"
      onCancel={onClose}
      open={visible}
      title="Confirm the winning lender"
    >
      <div className="quote-preview quote-preview_blue">
        <div className="quote">
          <LARCard noFooter data={selectedQuote} />
        </div>
      </div>
      <p style={{ padding: '12px 24px' }}>
        Once you confirm the quote, the deal will be removed from auction and moved to Active Loan and due diligence.
        You will be able to show the loan quote and terms to the borrower from the due diligence center. Alternatively,
        you can go back and send the quote to the borrower before you confirm your quote selection
      </p>
      <p style={{ padding: '12px 24px' }}>
        The quote provided by the lender is not a commitment to make a loan. Loans are subject to borrower
        qualifications, including income, property evaluation, sufficient equity in the property to meet LTV
        requirements, and final credit approval. Lender approvals are subject to underwriting guidelines, interest
        rates, and program guidelines, and are subject to change without notice based on applicant’s eligibility and
        market conditions. Refinancing an existing loan may result in total finance charges being higher over life of
        loan. Reduction in payments may reflect longer loan term. Terms of the loan may be subject to payment of points
        and fees by the applicant.
      </p>
    </Modal>
  );
}

export default LARDBSelectWinModal;
