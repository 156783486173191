import './styles/payments.css';
import { Radio, message } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import AutoCompleteTextInput from 'components/UI/AutoCompleteTextInput';
import Button from 'components/UI/Button';
import DropdownMenu from 'components/CustomComponents/DropdownMenu';
import otherConst from 'constants/otherConst';
import PhoneComponent from 'components/CustomComponents/PhoneInput';
import regExp from 'constants/regExp';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import TextInput from 'components/CustomComponents/TextInput';

const checkEntityInitState = {
  payment_business_name: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
  },
  payment_mailing_address: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
  },
};

const checkIndividualInitState = {
  payment_city: {
    error: false,
    errorMessage: 'Fill this label',
    options: [],
    value: null,
  },
  payment_first_name: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
  },
  payment_last_name: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
  },
  payment_zip: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
  },
  payment_mailing_address: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
  },
  payment_state: {
    error: false,
    errorMessage: 'Fill this label',
    options: [],
    value: null,
  },
  payment_street_address: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
  },
};
const wireTransferInitState = {
  bank_aba_routing_number: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
  },
  bank_account_number: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
  },
  bank_name: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
  },
  bank_address: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
  },
  bank_company_name: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
    hint: 'Your company’s name as it appears on the bank account',
  },
  bank_contact_name: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
  },
  bank_contact_phone_number: {
    error: false,
    errorMessage: 'Fill this label',
    value: '',
  },
};

export default function Payments() {
  const [wireTransfer, setWireTransfer] = useState(wireTransferInitState);
  const [checkIndividual, setCheckIndividual] = useState(checkIndividualInitState);
  const [checkEntity, setCheckEntity] = useState(checkEntityInitState);
  const [radio, setRadio] = useState({
    prefered_payment: 'Wire Transfer',
    check_made: 'Entity',
  });

  function resetForm() {
    API()
      .get('/user_settings/company/broker/payments')
      .then((res) => {
        Object.entries(res.data).forEach((i) => {
          if (i[1].current) {
            if (i[0] === 'Wire Transfer') {
              setRadio((state) => ({
                ...state,
                prefered_payment: i[0],
              }));
            } else {
              const split = i[0].split(' ');
              setRadio({
                prefered_payment: split.slice(0, 2).join(' '),
                check_made: split[2],
              });
            }
          }
        });

        setCheckEntity((prevState) => ({
          ...prevState,
          payment_business_name: {
            ...prevState.payment_business_name,
            value: res.data['Bank Check Entity'].payment_business_name ?? '',
          },
          payment_mailing_address: {
            ...prevState.payment_mailing_address,
            value: res.data['Bank Check Entity'].payment_mailing_address ?? '',
          },
        }));

        setCheckIndividual((prevState) => ({
          ...prevState,
          payment_city: {
            ...prevState.payment_city,
            options: otherConst.cities,
            value: res.data['Bank Check Individual'].payment_city ?? '',
          },
          payment_first_name: {
            ...prevState.payment_first_name,
            value: res.data['Bank Check Individual'].payment_first_name ?? '',
          },
          payment_last_name: {
            ...prevState.payment_last_name,
            value: res.data['Bank Check Individual'].payment_last_name ?? '',
          },
          payment_zip: {
            ...prevState.payment_zip,
            value: res.data['Bank Check Individual'].payment_zip ?? '',
          },
          payment_mailing_address: {
            ...prevState.payment_mailing_address,
            value: res.data['Bank Check Individual'].payment_mailing_address ?? '',
          },
          payment_state: {
            ...prevState.payment_state,
            options: otherConst.states,
            value: res.data['Bank Check Individual'].payment_state ?? '',
          },
          payment_street_address: {
            ...prevState.payment_street_address,
            value: res.data['Bank Check Individual'].payment_street_address ?? '',
          },
        }));

        setWireTransfer((prevState) => ({
          ...prevState,
          bank_aba_routing_number: {
            ...prevState.bank_aba_routing_number,
            value: res.data['Wire Transfer'].bank_aba_routing_number ?? '',
          },
          bank_account_number: {
            ...prevState.bank_account_number,
            value: res.data['Wire Transfer'].bank_account_number ?? '',
          },
          bank_name: {
            ...prevState.bank_name,
            value: res.data['Wire Transfer'].bank_name ?? '',
          },
          bank_address: {
            ...prevState.bank_address,
            value: res.data['Wire Transfer'].bank_address ?? '',
          },
          bank_company_name: {
            ...prevState.bank_company_name,
            value: res.data['Wire Transfer'].bank_company_name ?? '',
          },
          bank_contact_name: {
            ...prevState.bank_contact_name,
            value: res.data['Wire Transfer'].bank_contact_name ?? '',
          },
          bank_contact_phone_number: {
            ...prevState.bank_contact_phone_number,
            value: res.data['Wire Transfer'].bank_contact_phone_number ?? '',
          },
        }));
      });
  }

  useEffect(resetForm, []);

  const onRadio = (e, name) => {
    setRadio((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  const checkEmpty = (fields) => {
    const protoObj = { ...fields };
    let error = false;
    Object.entries(protoObj).map((i) => {
      if (!i[1].value.length && i[1].required) {
        error = true;
        i[1].error = true;
        i[1].errorMessage = 'Fill this label';
        return i;
      }
      return i;
    });
    return { error, protoObj };
  };

  const onSelect = (value, name) => {
    setCheckIndividual((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value.value,
        error: null,
      },
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const reqObj = {};

    if (radio.prefered_payment === 'Wire Transfer') {
      reqObj.payments_type_name = 'Wire Transfer';
      const { error, protoObj } = checkEmpty(wireTransfer);
      setWireTransfer(protoObj);
      if (error) {
        return;
      }
      if (wireTransfer.bank_contact_phone_number.value?.length < 8) {
        setWireTransfer((prevState) => ({
          ...prevState,
          bank_contact_phone_number: {
            ...prevState.bank_contact_phone_number,
            error: true,
            errorMessage: 'Enter at least 8 characters',
          },
        }));
        return;
      }
      Object.entries(wireTransfer).forEach((i) => (reqObj[i[0]] = i[1].value));

      API()
        .post('/user_settings/company/broker/payments', reqObj)
        .then(() => {
          message.success('Banking info saved');
        });
    } else if (radio.check_made === 'Entity') {
      reqObj.payments_type_name = 'Bank Check Entity';
      const { error, protoObj } = checkEmpty(checkEntity);
      setCheckEntity(protoObj);
      if (error) {
        return;
      }

      if (checkIndividual.payment_zip.value?.length < 5) {
        setCheckIndividual((prevState) => ({
          ...prevState,
          payment_zip: {
            ...prevState.payment_zip,
            error: true,
            errorMessage: 'Enter at least 5 characters',
          },
        }));
        return;
      }

      Object.entries(checkEntity).forEach((i) => (reqObj[i[0]] = i[1].value));

      API()
        .post('/user_settings/company/broker/payments', reqObj)
        .then(() => {
          message.success('You successfully uploaded bank check entity');
        });
    } else {
      reqObj.payments_type_name = 'Bank Check Individual';
      const { error, protoObj } = checkEmpty(checkIndividual);
      setWireTransfer(protoObj);
      if (error) {
        return;
      }
      Object.entries(checkIndividual).forEach((i) => (reqObj[i[0]] = i[1].value));
      API()
        .post('/user_settings/company/broker/payments', reqObj)
        .then(() => {
          message.success('You successfully uploaded bank check entity');
        });
    }
  };

  const onChangeWire = (e, name) => {
    setWireTransfer((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: e.target.value,
        error: false,
      },
    }));
  };

  const onChangeEntity = (e, name) => {
    setCheckEntity((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: e.target.value,
        error: false,
      },
    }));
  };

  const onChangeIndividual = (e, name) => {
    setCheckIndividual((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: e.target.value,
        error: false,
      },
    }));
  };

  return (
    <div className="payments_wrapper">
      <SectionTitle title="Payments" />
      <form className="payments_wrapper__form" onSubmit={onSubmit}>
        <div className="prefered_payments">
          <span className="radio_title">Prefered payment</span>
          <Radio.Group onChange={(e) => onRadio(e, 'prefered_payment')} value={radio.prefered_payment}>
            <Radio value="Wire Transfer">Wire Transfer</Radio>
            <Radio value="Bank Check">Bank Check</Radio>
          </Radio.Group>
        </div>
        {radio.prefered_payment === 'Bank Check' ? (
          <div className="check_made">
            <span className="radio_title">Who check made out to</span>
            <Radio.Group onChange={(e) => onRadio(e, 'check_made')} value={radio.check_made}>
              <Radio value="Individual">Individual</Radio>
              <Radio value="Entity">Entity</Radio>
            </Radio.Group>
          </div>
        ) : null}
        <div className="inputs">
          {radio.prefered_payment !== 'Bank Check'
            ? [
                <TextInput
                  title="Bank name"
                  maxLength={255}
                  value={wireTransfer.bank_name.value}
                  error={wireTransfer.bank_name.error}
                  errorMessage={wireTransfer.bank_name.errorMessage}
                  onChange={(e) => onChangeWire(e, 'bank_name')}
                  key={14}
                />,
                <TextInput
                  title="Bank address"
                  maxLength={255}
                  value={wireTransfer.bank_address.value}
                  error={wireTransfer.bank_address.error}
                  errorMessage={wireTransfer.bank_address.errorMessage}
                  onChange={(e) => onChangeWire(e, 'bank_address')}
                  key={88}
                />,
                <TextInput
                  title="Bank contact name"
                  maxLength={255}
                  value={wireTransfer.bank_contact_name.value}
                  error={wireTransfer.bank_contact_name.error}
                  errorMessage={wireTransfer.bank_contact_name.errorMessage}
                  onChange={(e) => onChangeWire(e, 'bank_contact_name')}
                  key={17}
                />,
                <PhoneComponent
                  title="Bank contact phone number"
                  value={wireTransfer.bank_contact_phone_number.value}
                  key={321}
                  maxLength={20}
                  onChange={(value) => {
                    setWireTransfer((prevState) => ({
                      ...prevState,
                      bank_contact_phone_number: {
                        ...prevState.bank_contact_phone_number,
                        value,
                        error: false,
                      },
                    }));
                  }}
                  error={wireTransfer.bank_contact_phone_number.error}
                  errorMessage={wireTransfer.bank_contact_phone_number.errorMessage}
                />,
                <TextInput
                  title="Сompany name"
                  maxLength={255}
                  value={wireTransfer.bank_company_name.value}
                  hint={wireTransfer.bank_company_name.hint}
                  error={wireTransfer.bank_company_name.error}
                  errorMessage={wireTransfer.bank_company_name.errorMessage}
                  onChange={(e) => onChangeWire(e, 'bank_company_name')}
                  key={124}
                />,
                <TextInput
                  title="Account number"
                  maxLength={255}
                  value={wireTransfer.bank_account_number.value}
                  error={wireTransfer.bank_account_number.error}
                  errorMessage={wireTransfer.bank_account_number.errorMessage}
                  onChange={(e) => onChangeWire(e, 'bank_account_number')}
                  key={12}
                />,
                <TextInput
                  title="Aba Routing Number"
                  maxLength={255}
                  value={wireTransfer.bank_aba_routing_number.value}
                  error={wireTransfer.bank_aba_routing_number.error}
                  errorMessage={wireTransfer.bank_aba_routing_number.errorMessage}
                  onChange={(e) => onChangeWire(e, 'bank_aba_routing_number')}
                  key={123}
                />,
              ]
            : radio.check_made === 'Entity'
            ? [
                <TextInput
                  title="Business name"
                  maxLength={255}
                  value={checkEntity.payment_business_name.value}
                  error={checkEntity.payment_business_name.error}
                  errorMessage={checkEntity.payment_business_name.errorMessage}
                  onChange={(e) => onChangeEntity(e, 'payment_business_name')}
                  key={12}
                />,
                <AutoCompleteTextInput
                  title="Mailing address"
                  key={123}
                  setSelectedAddress={(addr, city, state, zip) => {
                    onChangeEntity(
                      {
                        target: {
                          value: `${addr} ${city}, ${state.value}, ${zip}`,
                        },
                      },
                      'payment_mailing_address',
                    );
                  }}
                  onChange={(e) => onChangeEntity(e, 'payment_mailing_address')}
                  value={checkEntity.payment_mailing_address.value}
                  maxLength={255}
                  error={checkEntity.payment_mailing_address.error}
                  errorMessage={checkEntity.payment_mailing_address.errorMessage}
                />,
              ]
            : [
                <TextInput
                  title="First Name"
                  key={21155}
                  onChange={(e) => onChangeIndividual(e, 'payment_first_name')}
                  maxLength={255}
                  value={checkIndividual.payment_first_name.value}
                  error={checkIndividual.payment_first_name.error}
                  errorMessage={checkIndividual.payment_first_name.errorMessage}
                />,
                <TextInput
                  title="Last Name"
                  key={2114}
                  onChange={(e) => onChangeIndividual(e, 'payment_last_name')}
                  maxLength={255}
                  value={checkIndividual.payment_last_name.value}
                  error={checkIndividual.payment_last_name.error}
                  errorMessage={checkIndividual.payment_last_name.errorMessage}
                />,
                <TextInput
                  title="Street"
                  key={2113}
                  onChange={(e) => onChangeIndividual(e, 'payment_street_address')}
                  maxLength={255}
                  value={checkIndividual.payment_street_address.value}
                  error={checkIndividual.payment_street_address.error}
                  errorMessage={checkIndividual.payment_street_address.errorMessage}
                />,
                <DropdownMenu
                  options={checkIndividual.payment_city.options}
                  title="City"
                  key={2112}
                  onChange={(e) => onChangeIndividual(e, 'payment_city')}
                  vectorIcon
                  onSelect={(value) => onSelect(value, 'payment_city')}
                  value={checkIndividual.payment_city.value}
                  error={checkIndividual.payment_city.error}
                  errorMessage={checkIndividual.payment_city.errorMessage}
                />,
                <div className="two_col" key={211}>
                  <DropdownMenu
                    options={checkIndividual.payment_state.options}
                    title="State"
                    vectorIcon
                    onSelect={(value) => onSelect(value, 'payment_state')}
                    error={checkIndividual.payment_state.error}
                    errorMessage={checkIndividual.payment_state.errorMessage}
                    value={checkIndividual.payment_state.value}
                  />
                  <TextInput
                    title="Zip Code"
                    onChange={(e) => {
                      if (regExp.only_number.test(e.target.value)) {
                        setCheckIndividual((prevState) => ({
                          ...prevState,
                          payment_zip: {
                            ...prevState.payment_zip,
                            value: e.target.value,
                            error: false,
                          },
                        }));
                      }
                    }}
                    maxLength={5}
                    value={checkIndividual.payment_zip.value}
                    error={checkIndividual.payment_zip.error}
                    errorMessage={checkIndividual.payment_zip.errorMessage}
                  />
                </div>,
                <AutoCompleteTextInput
                  title="Mailing address"
                  key={211111}
                  setSelectedAddress={(addr, city, state, zip) => {
                    onChangeIndividual(
                      {
                        target: {
                          value: `${addr} ${city}, ${state.value}, ${zip}`,
                        },
                      },
                      'payment_mailing_address',
                    );
                  }}
                  onChange={(e) => onChangeIndividual(e, 'payment_mailing_address')}
                  value={checkIndividual.payment_mailing_address.value}
                  maxLength={255}
                  error={checkIndividual.payment_mailing_address.error}
                  errorMessage={checkIndividual.payment_mailing_address.errorMessage}
                />,
              ]}
        </div>
        <div className="footer">
          <Button onClick={resetForm} text="Cancel" />
          <Button className="btn_darkblue" text="Save" type="submit" />
        </div>
      </form>
    </div>
  );
}
