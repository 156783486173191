const fillFields = (updateFields, condition, fields, setFields) => {
  const tempFields = { ...fields };

  Object.entries(fields).forEach((i) => {
    const field = i[1];
    if (i[0].includes('dividedInputs')) {
      Object.entries(field.inputs).forEach((item) => {
        if (updateFields[item[0]] || (!condition && !updateFields[item[0]])) {
          if (item[0] === 'state') {
            tempFields[i[0]].inputs[item[0]].value = updateFields[item[0]]?.code ?? updateFields[item[0]];
          } else {
            tempFields[i[0]].inputs[item[0]].value = updateFields[item[0]];
          }
          tempFields[i[0]].inputs[item[0]].error = false;
        }
      });
    } else if (i[0].includes('upload') || i[0].includes('radioButtons')) {
      const fieldName = i[0].split('_').slice(1).join('_');
      if (updateFields[fieldName]) {
        tempFields[i[0]].value = updateFields[fieldName];
        tempFields[i[0]].error = false;
      }
    } else if (i[0].includes('selectTags')) {
      const fieldName = i[0].split('_').slice(1).join('_');
      if (updateFields[fieldName]) {
        if (typeof updateFields[fieldName] === 'string') {
          tempFields[i[0]].tags = updateFields[fieldName].split(', ');
        } else {
          tempFields[i[0]].tags = updateFields[fieldName];
        }
        tempFields[i[0]].error = false;
      }
    } else if (i[0].includes('switchButtons')) {
      Object.entries(field.switches).forEach((item) => {
        if (updateFields[item[0]] || updateFields[item[0]] === false) {
          tempFields[i[0]].switches[item[0]].value = updateFields[item[0]];
          tempFields[i[0]].switches[item[0]].error = false;
        }
      });
    } else if (i[0].includes('checkbox')) {
      Object.entries(field.boxes).forEach((item) => {
        if (updateFields[item[0]]) {
          tempFields[i[0]].boxes[item[0]].value = updateFields[item[0]];
          tempFields[i[0]].boxes[item[0]].error = false;
        }
      });
    } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle')) {
      // eslint-disable-line
    } else if (updateFields[i[0]] || (!condition && updateFields[i[0]] === '')) {
      tempFields[i[0]].value = updateFields[i[0]];
      tempFields[i[0]].error = false;
    }
  });

  setFields(tempFields);
};

export default fillFields;
