import React, { useEffect, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { TransformComponent } from 'react-zoom-pan-pinch';
import png_viewer_placeholder from '../../assets/images/png_viewer_placeholder.png';
import PDFLoadError from '../Shared/PDFLoadError';
import { CloseWhiteIcon, ZoomInIcon, ZoomOutIcon } from '../../assets/icons/icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DocumentViewer({
  activeDocument,
  arrowsColor,
  errorHeight,
  hasZoomIn,
  initialPage,
  isImage,
  loading,
  onPageChange,
  prefix,
  scale,
}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(initialPage ?? 1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    setNumPages(null);
  }, [activeDocument]);

  useEffect(() => {
    onPageChange && onPageChange(pageNumber);
  }, [onPageChange, pageNumber]);

  const document = useMemo(
    () => (
      <Document
        file={activeDocument}
        onLoadError={console.error}
        loading={loading}
        error={
          isImage ? (
            <img src={activeDocument} alt="" />
          ) : (
            <PDFLoadError file={activeDocument?.url ?? activeDocument?.file ?? activeDocument} height={errorHeight} />
          )
        }
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page scale={scale} pageNumber={pageNumber} className="png_viewer" />
      </Document>
    ),
    [activeDocument, scale, pageNumber, errorHeight, loading, isImage],
  );

  return (typeof activeDocument === 'object' && (activeDocument?.url || activeDocument?.file)) ||
    (typeof activeDocument === 'string' && activeDocument) ? (
    <div className="wrapper_png_viewer">
      {hasZoomIn ? (
        <div className="fullscreen-pdf-wrapper">
          <CloseWhiteIcon onClick={() => hasZoomIn.setFullscreen(false)} />
          <TransformComponent>{document}</TransformComponent>
        </div>
      ) : (
        document
      )}
      {numPages > 1 ? (
        <div className="wrapper_btn">
          <div
            onClick={() => {
              if (pageNumber !== 1) {
                setPageNumber(pageNumber - 1);
              }
            }}
            className="prev"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 6L15 12L9 18"
                stroke={arrowsColor ?? '#94A3B8'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <span>
            {prefix}
            {pageNumber}/{numPages}
          </span>
          <div
            onClick={() => {
              if (numPages !== pageNumber) {
                setPageNumber(pageNumber + 1);
              }
            }}
            className="next"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 6L15 12L9 18"
                stroke={arrowsColor ?? '#94A3B8'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {hasZoomIn && (
            <div className="zoom-tools">
              <span onClick={() => hasZoomIn.zoomIn()}>
                <ZoomInIcon />
              </span>
              <span className="zoom-amount" onClick={() => hasZoomIn.resetTransform()}>
                {(hasZoomIn.state.scale * 100).toFixed(0)}%
              </span>
              <span onClick={() => hasZoomIn.zoomOut()}>
                <ZoomOutIcon />
              </span>
            </div>
          )}
        </div>
      ) : null}
    </div>
  ) : (
    <img src={png_viewer_placeholder} alt="png_viewer_placeholder" />
  );
}
export default DocumentViewer;
