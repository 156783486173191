import React from 'react';
import './styles/wrapper_picker.css';

function CustomPicker({ error, errorMessage, children, title, style }) {
  return (
    <div style={style} className={error ? 'wrapper_picker wrapper_picker_error' : 'wrapper_picker'}>
      {title ? <p className="wrapper_picker__title">{title}</p> : null}
      {children}
      {error ? <p className="errorMessage">{errorMessage}</p> : null}
    </div>
  );
}
export default CustomPicker;
