import { Button } from 'antd';
import { useDispatch } from 'react-redux';

import { setChatState } from 'store/actions/userActions';
import ChatButton from 'components/UI/ChatButton/ChatButton';

import styles from './styles/ActionsBar.module.css';
import { ShareIcon } from 'assets/icons';

function ActionsBar({
  hasNewMessages,
  id = 0,
  isQuoteSelected = false,
  bidPlaced = false,
  canSendMessage = false,
  isResponded = false,
  openChooseBidDrawer = () => null,
  onOpenRespondDrawer,
  onSetActiveModal,
}) {
  const dispatch = useDispatch();

  function handleOpenChat() {
    dispatch(
      setChatState({
        lar_id: id,
        recipient_role: 'Broker Loan Manager',
        type: 'sidebar',
      }),
    );
  }

  const handleOpenModal = (name) => () => {
    onSetActiveModal?.({ show: name });
  };

  return (
    <header className={styles.root}>
      {!isQuoteSelected && (
        <div>
          <Button onClick={onOpenRespondDrawer} type="primary">
            {isResponded ? 'Revise response' : 'Respond'}
          </Button>
          <Button onClick={openChooseBidDrawer} type="primary">
            {bidPlaced ? 'Revise quote' : 'Quote'}
          </Button>
          <Button onClick={handleOpenModal('reassign_lar')}>Reassign</Button>
          <Button onClick={handleOpenModal('share_profile')}>
            <ShareIcon colour="grey-8" />
          </Button>
          <Button onClick={handleOpenModal('pass')}>{bidPlaced ? 'Rescind' : 'Pass'}</Button>
          {canSendMessage && (
            <ChatButton text="Message" hasIndicator={hasNewMessages} onClick={handleOpenChat} type="secondary" />
          )}
        </div>
      )}
    </header>
  );
}

export default ActionsBar;
