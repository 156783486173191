import React from 'react';
import LenderCompany from '../../pages/Registration/Lender/Company';
import LenderUser from '../../pages/Registration/Lender/LenderUser';
import Confirm from '../../pages/Registration/Confirm';
import LenderReview from '../../pages/Registration/Lender/Review';

export const mainNavigationRegisterLender = [
  {
    exact: true,
    path: '/registration/lender/company/:id?',
    main: () => <LenderCompany />,
  },
  {
    exact: true,
    path: '/registration/lender/user/:id?',
    main: () => <LenderUser />,
  },
  {
    exact: true,
    path: '/registration/lender/confirm',
    main: () => <Confirm type="lender" />,
  },
  {
    exact: true,
    path: '/registration/lender/review',
    main: () => <LenderReview />,
  },
];
