import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import auctionSVG from 'assets/images/Auction.svg';
import clockSVG from 'assets/icons/clock-thin.svg';
import CustomButton from 'components/UI/Button';
import parseFromET from 'constants/parseFromET';

import styles from './LiveAuctionsPlaceholder.module.css';

export default function LiveAuctionsPlaceholder({ isUpcoming, auction }) {
  const [deltas, setDeltas] = useState([0, 1]);
  const role = useSelector((s) => s.user?.userData?.role);

  useEffect(() => {
    const interval = setInterval(() => {
      if (auction) {
        setDeltas(
          ['auction_start_time', 'auction_ends'].map((dataIndex) =>
            Math.max(parseFromET(auction[dataIndex]).diff(moment.now()), 0),
          ),
        );
      }
    }, 1e3);

    return () => clearInterval(interval);
  }, [auction]);

  if (isUpcoming) {
    return (
      <div className={styles.root}>
        <img src={clockSVG} width={72} height={72} alt="" />
        <b>Upcoming Auctions</b>
        <div>
          This is the list of upcoming auctions for next business days. <br /> They are not available at the moment, but
          will be available when auctions start
        </div>
      </div>
    );
  }

  if (deltas[0] < deltas[1]) {
    return (
      <div className={styles.root}>
        <img src={auctionSVG} alt="" />
        <b>Auction time hasn't come yet</b>
        <div>Loan auction request profiles will appear in this area when the upcoming auctions begin.</div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <img src={auctionSVG} alt="" />
      <b>There are no active auctions</b>
      {/broker/i.test(role) ? (
        // Broker
        <>
          <div>Create a new loan application to start.</div>
          <Link to="/onboarding/new-deal">
            <CustomButton text="Create new loan request" primary />
          </Link>
        </>
      ) : /admin/i.test(role) ? (
        // Lender admin
        <>
          <div>You can review your mandates to get more invites.</div>
          <Link to="/onboarding/mandates">
            <CustomButton text="Review Mandates" primary />
          </Link>
        </>
      ) : (
        // Normal lender
        <div>To get more invites, ask your company manager to review mandates.</div>
      )}
    </div>
  );
}
