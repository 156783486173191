import './styles/vertical_tabs.css';
import { Link } from 'react-router-dom';
import React from 'react';
import OnBoardingDocsEvery from './IHarmoniDocs/OnBoardingDocsEvery';
import siteName from '../../constants/siteName';

function urlise(str) {
  return str.toLocaleLowerCase().replace(/ /gm, '_');
}

function VerticalTabs(props) {
  const { activeTab, documents } = props;
  const activeDoc = activeTab.activeUrl;

  return (
    <div className="wrapper_vertical_tabs">
      <div className="wrapper_vertical_tabs__links">
        {documents.map((doc) => (
          <Link
            to={`/onboarding/${siteName.toLocaleLowerCase()}_docs/${urlise(doc.title)}`}
            key={doc.id}
            className={`every ${urlise(doc.title) === activeDoc ? 'every_active' : ''}`}
          >
            {doc.id}.{doc.title}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 6L15 12L9 18" stroke="#94A3B8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
        ))}
      </div>
      <div className="wrapper_vertical_tabs__body">
        <OnBoardingDocsEvery props={props} activeDocument={documents.find((d) => urlise(d.title) === activeDoc)} />
      </div>
    </div>
  );
}

export default VerticalTabs;
