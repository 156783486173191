import {
  DashboardIcon,
  FileCertificateIcon,
  FileInvoiceIcon,
  MessageIcon,
  PlayIcon,
  TrophyIcon,
  UserIcon,
} from 'assets/icons';
import { Redirect, useLocation } from 'react-router-dom';
import AddEditMasterlist from 'pages/Onboarding/Masterlists/AddEditMasterlist';
import AssociateNewUsers from 'pages/Onboarding/AssociateNewUser';
import BrokerLenderUser from 'pages/Onboarding/BrokerLenderUser';
import BrokerLendingProfile from 'pages/Onboarding/BrokerProfile/BrokerProfile';
import Chat from 'pages/Onboarding/Chat/Chat';
import Companies from 'pages/Onboarding/SuperAdmin/Companies/Companies';
import Company from 'pages/Onboarding/SuperAdmin/Companies/Company';
import CompanyUser from 'pages/Onboarding/SuperAdmin/Companies/CompanyUser';
import CreateEditMandate from 'pages/Onboarding/LenderAdmin/CreateEditMandate';
import CreateEditUser from 'pages/Onboarding/SuperAdmin/CreateEditUser';
import Dashboard from 'pages/Onboarding/Dashboard';
import DiligenceManagement from 'pages/Onboarding/DiligenceManagement/DiligenceManagement';
import EditEMS from 'pages/Onboarding/SuperAdmin/EMS/EditEMS';
import EditEMSMessage from 'pages/Onboarding/SuperAdmin/EMS/EditEMSMessage';
import EMSList from 'pages/Onboarding/SuperAdmin/EMS/EMSList';
import FinishedAuctions from 'pages/Onboarding/FinishedAuctions/FinishedAuctions';
import IHarmoniDocuments from 'pages/Onboarding/SuperAdmin/IHarmoniDocuments';
import ImportMasterlist from 'pages/Onboarding/Masterlists/ImportMasterlist';
import LARDB from 'pages/Onboarding/LARDB/LARDB';
import LenderDocs from 'pages/Onboarding/LenderAdmin/LenderDocs';
import LenderDocsCreate from 'pages/Onboarding/LenderAdmin/LenderDocsCreate';
import LenderDocsLoanApp from 'pages/Onboarding/LenderAdmin/LenderDocsLoanApp';
import LenderScenarios from 'pages/Onboarding/Scenarios';
import LiveAuctions from 'pages/Onboarding/LiveAuctions/LiveAuctions';
import LoanProfile from 'pages/Onboarding/LoanProfile';
import MainLarAppNew from 'pages/Onboarding/LarAppNew/MainLarAppNew';
import Mandates from 'pages/Onboarding/LenderAdmin/Mandates';
import MasterlistDocument from 'pages/Onboarding/Masterlists/MasterlistDocument';
import Masterlists from 'pages/Onboarding/Masterlists/Masterlists';
import NewDeal from 'pages/Onboarding/LarAppNew/NewDeal/NewDeal';
import NotificationsPage from 'pages/Onboarding/NotificationsPage';
import OnboardingBDTUsers from 'pages/Onboarding/BDR/Users';
import OnboardingBorrowers from 'pages/Onboarding/BrokerAdmin/Borrowers';
import OnboardingCompanyMembers from 'pages/Onboarding/BrokerAdmin/CompanyMembers';
import OnboardingPreferencesBroker from 'pages/Onboarding/BrokerAdmin/Preferences/Preferences';
import OnboardingProspects from 'pages/Onboarding/BDR/Prospects';
import OnboardingSuperSettings from 'pages/Onboarding/SuperAdmin/Settings/MainRouter';
import Report from 'pages/Onboarding/PLA/Report';
import ScenarioBids from 'pages/Onboarding/ScenarioBids';
import Scenarios from 'pages/Onboarding/PLA/Scenarios';
import ScheduleAuction from 'pages/Onboarding/ScheduleAuction';
import Settings from 'pages/Onboarding/Settings/Settings';
import siteName from 'constants/siteName';
import SuperadminMandates from 'pages/Onboarding/SuperAdmin/Mandates/Mandates';
import User from 'pages/Onboarding/SuperAdmin/User';
import UserList from 'components/Shared/UserList';
import Users from 'pages/Onboarding/SuperAdmin/Users/Users';
import Winner from 'pages/Onboarding/Winner';
import { getUserFromStorage } from 'utils/storage';
import ActiveLoans from '../../pages/Onboarding/ActiveLoans';
import ApiPage from 'pages/Onboarding/SuperAdmin/Api/ApiPage';
import { SeparatorVerticalIcon } from 'assets/icons/icons';

const brokerRoles = ['Broker Admin', 'Broker Loan Manager', 'Broker Transaction Manager'];

const lenderRoles = ['Lender Admin', 'Lender Account Executive', 'Lender Transaction Manager'];

function getRole() {
  const user = getUserFromStorage();
  return user?.role;
}

export const mainNavigationOnboarding = [
  {
    exact: true,
    hr: true,
    icon: <DashboardIcon colour="#fcfcff" size="1.75em" />,
    label: 'Dashboard',
    main: Dashboard,
    path: '/onboarding/dashboard',
    pathname: 'dashboard',
    userAccess: [
      'Broker Admin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Broker Without Company',
      'Lender Account Executive',
      'Lender Admin',
      'Lender Transaction Manager',
      'Lender Without Company',
      'PLA',
      'superadmin',
      'Borrower',
    ],
  },
  {
    exact: true,
    hidden: true,
    main: CreateEditUser,
    path: '/onboarding/create_user',
    pathname: 'users',
    userAccess: ['superadmin'],
  },
  {
    exact: true,
    path: '/lar_app/create',
    main: MainLarAppNew,
    userAccess: ['Broker Admin', 'superadmin'],
    hidden: true,
    pathname: 'lar-preview',
  },
  {
    exact: true,
    hidden: true,
    main: CreateEditUser,
    path: '/onboarding/edit_user/:id',
    pathname: 'users',
    userAccess: ['superadmin'],
  },
  {
    exact: true,
    path: '/onboarding/new-deal',
    type: 'button',
    label: 'New Loan Request',
    userAccess: ['Broker Admin', 'Broker Loan Manager', 'Borrower'],
    pathname: 'borrowers',
    icon: (
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4515_435138)">
          <path d="M24.5 0H0.5V24H24.5V0Z" fill="white" fillOpacity="0.01" />
          <path
            d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M12.5 18.5V22" stroke="white" strokeWidth="2" strokeLinejoin="round" />
          <path d="M18.5 12H22.5" stroke="white" strokeWidth="2" strokeLinejoin="round" />
          <path d="M2.5 12H6" stroke="white" strokeWidth="2" strokeLinejoin="round" />
          <path d="M12.5 5.5V2" stroke="white" strokeWidth="2" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_4515_435138">
            <rect width="24" height="24" fill="white" transform="translate(0.5)" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    exact: true,
    hidden: true,
    main: function ParamRedirect() {
      // TODO: queryParams handling is legacy, can be removed later
      const location = useLocation();
      const queryParams = new URLSearchParams(location.search);
      const tab = queryParams.get('tab') || sessionStorage.getItem('IharmoniDBTabName');

      return <Redirect to={`${location.pathname}/${tab || 'activity'}`} />;
    },
    path: '/onboarding/lar_db/:id',
    pathname: 'LAR_dashboard',
    userAccess: [
      'PLA',
      'Lender Admin',
      'Lender',
      'Broker Admin',
      'superadmin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Lender Transaction Manager',
      'Lender Account Executive',
      'Borrower',
    ],
  },
  {
    exact: true,
    hidden: true,
    main: LARDB,
    path: '/onboarding/lar_db/:id/:tab/:sub_tab?/:item_id?',
    pathname: 'LAR_dashboard',
    userAccess: [
      'PLA',
      'Lender Admin',
      'Lender',
      'Broker Admin',
      'superadmin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Lender Transaction Manager',
      'Lender Account Executive',
      'Borrower',
    ],
  },
  {
    exact: true,
    hidden: true,
    label: 'Lender Auction',
    main: LoanProfile,
    path: '/onboarding/loan_profile/:id',
    userAccess: [
      'PLA',
      'Broker Admin',
      'superadmin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Borrower',
      'Lender Admin',
      'Lender Account Executive',
      'Lender Transaction Manager',
      'Lender',
    ],
    pathname: 'loan_profile',
  },
  {
    exact: true,
    hidden: true,
    path: '/onboarding/lar_db/:id/diligence_management/:dd_center_id',
    main: DiligenceManagement,
    userAccess: [
      'PLA',
      'Lender Admin',
      'Lender',
      'Broker Admin',
      'superadmin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Lender Transaction Manager',
      'Lender Account Executive',
      'Borrower',
    ],
    pathname: 'LAR_diligence_management',
  },
  {
    exact: true,
    path: '/onboarding/messages',
    main: () => <Chat type="fullscreen" />,
    label: 'Messages',
    userAccess: [
      'PLA',
      'superadmin',
      ...brokerRoles,
      ...lenderRoles,
      'Borrower',
      'Broker Without Company',
      'Lender Without Company',
    ],
    pathname: 'messages',
    icon: <MessageIcon colour="#fcfcff" size="1.75em" />,
  },
  {
    exact: true,
    hidden: false,
    path: '/onboarding/finished-auctions',
    main: FinishedAuctions,
    label: 'Quote results',
    userAccess: ['Lender Admin', 'Lender Account Executive', 'Lender Transaction Manager', 'Lender Without Company'],
    pathname: 'finished-auctions',
    icon: <TrophyIcon colour="#fcfcff" size="1.75em" />,
  },
  {
    exact: true,
    hidden: false,
    path: '/onboarding/active-loans',
    main: ActiveLoans,
    label: 'Active loans',
    userAccess: ['Lender Admin', 'Lender Account Executive', 'Lender Transaction Manager', 'Lender Without Company'],
    pathname: 'active-loans',
    icon: <TrophyIcon colour="#fcfcff" size="1.75em" />,
  },
  {
    exact: true,
    hidden: true,
    main: NotificationsPage,
    path: '/onboarding/notifications',
    userAccess: [
      'Broker Admin',
      'PLA',
      'superadmin',
      'Lender Admin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Lender Account Executive',
      'Lender Transaction Manager',
      'Borrower',
      'Broker Without Company',
      'Lender Without Company',
    ],
    pathname: 'notifications',
  },
  {
    exact: true,
    icon: <FileCertificateIcon colour="#fcfcff" size="1.75em" />,
    label: 'Analyzer',
    main: Scenarios,
    path: '/onboarding/sa-scenarios',
    pathname: 'scenarios',
    userAccess: ['PLA', 'superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: ScenarioBids,
    path: '/onboarding/scenarios/:id',
    pathname: 'scenarios',
    userAccess: ['Broker Admin', 'Broker Loan Manager', 'Broker Transaction Manager', 'PLA', 'superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: () => <Report type="scenario" />,
    path: '/onboarding/scenarios/:id/report',
    pathname: 'scenario_report',
    userAccess: ['PLA', 'superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: () => <Report type="auction" />,
    path: '/onboarding/auction/:id/report',
    pathname: 'auction_report',
    userAccess: ['PLA', 'superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: ScenarioBids,
    path: '/onboarding/scenarios/:id/bids/:bidId',
    pathname: 'scenarios',
    userAccess: ['Broker Admin', 'Broker Loan Manager', 'Broker Transaction Manager', 'PLA', 'superadmin'],
  },
  {
    exact: true,
    icon: <FileCertificateIcon colour="#fcfcff" size="1.75em" />,
    label: 'Scenarios',
    main: LenderScenarios,
    path: '/onboarding/scenarios',
    pathname: 'scenarios',
    userAccess: ['Lender Account Executive', 'Lender Admin', 'Lender Transaction Manager'],
  },
  {
    exact: true,
    hidden: true,
    main: LenderScenarios,
    path: '/onboarding/scenarios/:id',
    pathname: 'scenarios',
    userAccess: ['Lender Account Executive', 'Lender Admin', 'Lender Transaction Manager'],
  },
  {
    exact: true,
    icon: <PlayIcon colour="#fcfcff" size="1.75em" />,
    label: 'Live auctions',
    main: LiveAuctions,
    path: '/onboarding/live_auctions',
    pathname: 'live_auctions',
    userAccess: [
      'PLA',
      'Broker Admin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Lender Account Executive',
      'Lender Admin',
      'Lender Transaction Manager',
    ],
  },
  {
    exact: true,
    hidden: true,
    main: LiveAuctions,
    path: '/onboarding/live_auctions/:id',
    pathname: 'live_auctions',
    userAccess: [
      'PLA',
      'Broker Admin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Lender Account Executive',
      'Lender Admin',
      'Lender Transaction Manager',
    ],
  },
  {
    exact: true,
    icon: <UserIcon colour="#fcfcff" size="1.75em" />,
    label: 'Borrowers',
    main: OnboardingBorrowers,
    path: '/onboarding/borrowers',
    pathname: 'borrowers',
    userAccess: ['Broker Admin', 'Broker Loan Manager'],
  },
  {
    exact: true,
    main: NewDeal,
    path: '/onboarding/new-deal',
    pathname: 'new-deal',
    userAccess: ['Broker Admin', 'Broker Loan Manager'],
  },
  {
    exact: true,
    icon: <UserIcon colour="#fcfcff" size="1.75em" />,
    label: 'Companies',
    main: Companies,
    path: '/onboarding/companies',
    pathname: 'companies',
    userAccess: ['superadmin'],
  },
  {
    hidden: true,
    main: Company,
    path: '/onboarding/companies/:id',
    pathname: 'company',
    userAccess: ['superadmin'],
  },
  {
    hidden: true,
    main: CompanyUser,
    path: '/onboarding/companies/:id/user/:userId',
    pathname: 'user_of_company',
    userAccess: ['superadmin'],
  },
  {
    exact: true,
    label: 'Prospects',
    main: OnboardingProspects,
    path: '/onboarding/prospects',
    pathname: 'prospects',
    userAccess: ['BDR'],
    icon: <UserIcon colour="#fcfcff" size="1.75em" />,
  },
  {
    exact: true,
    icon: <SeparatorVerticalIcon colour="#fcfcff" size="1.75em" />,
    label: 'API',
    main: ApiPage,
    path: '/onboarding/api',
    pathname: 'api',
    userAccess: ['superadmin'],
  },
  {
    exact: true,
    path: '/onboarding/users',
    main: () => {
      const role = getRole();
      if (brokerRoles.includes(role)) {
        return <OnboardingCompanyMembers isCompanyAdmin={role.includes('Admin')} />;
      }
      if (lenderRoles.includes(role)) {
        return (
          <OnboardingCompanyMembers
            isCompanyAdmin={role.includes('Admin')}
            tabs={['All', 'Lender Account Executive', 'Lender Transaction Manager']}
          />
        );
      }
    },
    label: 'Colleagues',
    userAccess: ['Broker Loan Manager', 'Broker Transaction Manager'],
    pathname: 'users',
    icon: <UserIcon colour="#fcfcff" size="1.75em" />,
  },
  {
    exact: true,
    path: '/onboarding/users',
    main: () => <OnboardingBDTUsers />,
    label: 'Active Users',
    userAccess: ['BDR'],
    pathname: 'users',
    icon: <UserIcon colour="#fcfcff" size="1.75em" />,
  },
  {
    exact: true,
    path: '/onboarding/:slug/associate_new_members',
    main: () => {
      if (getRole() === 'Lender Admin') {
        return <AssociateNewUsers roles={['Lender Account Executive', 'Lender Transaction Manager']} />;
      }
      return <AssociateNewUsers />;
    },
    userAccess: ['Lender Admin', 'BDR', 'Broker Admin'],
    pathname: 'associate_new_members',
    hidden: true,
  },
  {
    path: '/onboarding/users/:id',
    main: (i) => i.match.params.id !== 'associate_new_members' && <BrokerLenderUser />,
    userAccess: [
      'Lender Admin',
      'BDR',
      'Broker Admin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Lender Account Executive',
      'Lender Transaction Manager',
    ],
    pathname: 'users',
    hidden: true,
  },
  {
    exact: true,
    path: '/onboarding/users',
    main: () => <Users />,
    label: 'Users',
    userAccess: ['superadmin'],
    pathname: 'users',
    icon: <UserIcon colour="#fcfcff" size="1.75em" />,
  },
  {
    exact: true,
    main: User,
    path: '/onboarding/users/:id',
    pathname: 'users',
    userAccess: ['superadmin', 'PLA'],
    hidden: true,
  },
  {
    exact: true,
    path: '/onboarding/admin-settings/:slug/:slug?',
    main: () => <OnboardingSuperSettings />,
    label: 'Settings',
    userAccess: ['superadmin'],
    pathname: 'settings',
    icon: (
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 6.5H7C6.46957 6.5 5.96086 6.71071 5.58579 7.08579C5.21071 7.46086 5 7.96957 5 8.5V17.5C5 18.0304 5.21071 18.5391 5.58579 18.9142C5.96086 19.2893 6.46957 19.5 7 19.5H16C16.5304 19.5 17.0391 19.2893 17.4142 18.9142C17.7893 18.5391 18 18.0304 18 17.5V14.5"
          stroke="#FCFCFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 10.5C18.6569 10.5 20 9.15685 20 7.5C20 5.84315 18.6569 4.5 17 4.5C15.3431 4.5 14 5.84315 14 7.5C14 9.15685 15.3431 10.5 17 10.5Z"
          stroke="#FCFCFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    exact: true,
    icon: (
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 5.5H5C3.89543 5.5 3 6.39543 3 7.5V17.5C3 18.6046 3.89543 19.5 5 19.5H19C20.1046 19.5 21 18.6046 21 17.5V7.5C21 6.39543 20.1046 5.5 19 5.5Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path d="M3 7.5L12 13.5L21 7.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </svg>
    ),
    label: 'EMS',
    main: EMSList,
    path: '/onboarding/ems',
    pathname: 'ems',
    userAccess: ['superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: EditEMS,
    path: '/onboarding/ems/:triggerId',
    pathname: 'ems',
    userAccess: ['superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: EditEMSMessage,
    path: '/onboarding/ems/:triggerId/channel/:configId',
    pathname: 'ems',
    userAccess: ['superadmin'],
  },
  {
    exact: true,
    hr: true,
    icon: <FileInvoiceIcon colour="#fcfcff" size="1.75em" />,
    label: `${siteName} Docs`,
    path: `/onboarding/${siteName.toLocaleLowerCase()}_docs/disclosures`,
    pathname: `${siteName.toLocaleLowerCase()}_docs`,
    userAccess: ['superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: IHarmoniDocuments,
    path: `/onboarding/${siteName.toLocaleLowerCase()}_docs/:slug`,
    pathname: `${siteName.toLocaleLowerCase()}_docs`,
    userAccess: ['superadmin'],
  },
  {
    exact: true,
    icon: <FileInvoiceIcon colour="#fcfcff" size="1.75em" />,
    label: 'Mandates',
    main: SuperadminMandates,
    path: '/onboarding/mandates',
    pathname: 'mandates',
    userAccess: ['superadmin', 'PLA'],
  },
  {
    exact: true,
    hidden: true,
    main: Mandates,
    path: '/onboarding/mandates',
    userAccess: ['Lender Admin'],
  },
  {
    exact: true,
    path: '/onboarding/mandates/create_mandate',
    main: CreateEditMandate,
    userAccess: ['Lender Admin', 'Lender Transaction Manager', 'superadmin', 'PLA'],
    pathname: 'mandates',
    hidden: true,
  },
  {
    exact: true,
    path: '/onboarding/mandates/edit_mandate/:id',
    main: () => <CreateEditMandate edit />,
    userAccess: ['superadmin', 'Lender Admin', 'Lender Transaction Manager', 'PLA'],
    pathname: 'mandates',
    hidden: true,
  },
  {
    exact: true,
    path: '/onboarding/mandates/view_mandate/:id',
    main: () => <CreateEditMandate view />,
    userAccess: ['Lender Admin', 'Lender Transaction Manager', 'superadmin', 'PLA'],
    pathname: 'mandates',
    hidden: true,
  },
  {
    hidden: true,
    main: User,
    path: '/onboarding/brokers/:id',
    pathname: 'users',
    userAccess: ['PLA'],
  },
  {
    path: '/onboarding/lenders/:id',
    main: User,
    userAccess: ['PLA'],
    pathname: 'lenders',
    hidden: true,
  },
  {
    exact: true,
    icon: <UserIcon colour="#fcfcff" size="1.75em" />,
    label: 'Originators',
    main: () => <UserList type="brokers" />,
    path: '/onboarding/brokers',
    pathname: 'brokers',
    userAccess: ['PLA'],
  },
  {
    exact: true,
    icon: <UserIcon colour="#fcfcff" size="1.75em" />,
    label: 'Fulfillment Lenders',
    main: () => <UserList type="lenders" />,
    path: '/onboarding/lenders',
    pathname: 'lenders',
    userAccess: ['PLA'],
  },
  {
    exact: true,
    hidden: true,
    main: BrokerLendingProfile,
    path: '/onboarding/broker-profile',
    userAccess: ['Broker Loan Manager', 'Broker Admin'],
  },
  {
    exact: true,
    path: '/onboarding/preferences/:slug',
    main: OnboardingPreferencesBroker,
    userAccess: ['Broker Admin'],
  },
  {
    exact: true,
    path: '/onboarding/settings',
    main: Settings,
    hidden: true,
    userAccess: [
      'BDR',
      'Borrower',
      'Broker Admin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Broker Without Company',
      'Lender Account Executive',
      'Lender Admin',
      'Lender Transaction Manager',
      'Lender Without Company',
      'LOM',
      'PLA',
      'Prospect broker',
      'superadmin',
    ],
  },
  {
    exact: true,
    path: '/onboarding/settings/:category',
    main: Settings,
    hidden: true,
    userAccess: [
      'BDR',
      'Borrower',
      'Broker Admin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Broker Without Company',
      'Lender Account Executive',
      'Lender Admin',
      'Lender Transaction Manager',
      'Lender Without Company',
      'LOM',
      'PLA',
      'Prospect broker',
      'superadmin',
    ],
  },
  {
    exact: true,
    path: '/onboarding/company-settings',
    main: () => <Settings company />,
    hidden: true,
    userAccess: [
      'BDR',
      'Borrower',
      'Broker Admin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Broker Without Company',
      'Lender Account Executive',
      'Lender Admin',
      'Lender Transaction Manager',
      'Lender Without Company',
      'LOM',
      'PLA',
      'Prospect broker',
      'superadmin',
    ],
  },
  {
    exact: true,
    path: '/onboarding/company-settings/:category',
    main: () => <Settings company />,
    hidden: true,
    userAccess: [
      'BDR',
      'Borrower',
      'Broker Admin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Broker Without Company',
      'Lender Account Executive',
      'Lender Admin',
      'Lender Transaction Manager',
      'Lender Without Company',
      'LOM',
      'PLA',
      'Prospect broker',
      'superadmin',
    ],
  },
  {
    exact: true,
    path: '/onboarding/company-settings/:category/:slug',
    main: () => <Settings company />,
    hidden: true,
    userAccess: [
      'BDR',
      'Borrower',
      'Broker Admin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'Broker Without Company',
      'Lender Account Executive',
      'Lender Admin',
      'Lender Transaction Manager',
      'Lender Without Company',
      'LOM',
      'PLA',
      'Prospect broker',
      'superadmin',
    ],
  },
  {
    exact: true,
    icon: <FileInvoiceIcon colour="#fcfcff" size="1.75em" />,
    label: 'Lender Docs',
    main: LenderDocs,
    path: '/onboarding/documents',
    pathname: 'documents',
    userAccess: ['superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: LenderDocsLoanApp,
    path: '/onboarding/documents/loan_application',
    pathname: 'loan_application',
    userAccess: [
      'Lender Account Executive',
      'Lender Admin',
      'Lender Transaction Manager',
      'Lender Without Company',
      'superadmin',
    ],
  },
  {
    exact: true,
    hidden: true,
    main: LenderDocsCreate,
    path: '/onboarding/documents/loan_application/create_document',
    pathname: 'create_document',
    userAccess: [
      'Lender Account Executive',
      'Lender Admin',
      'Lender Transaction Manager',
      'Lender Without Company',
      'superadmin',
    ],
  },
  {
    exact: true,
    icon: <FileCertificateIcon colour="#fcfcff" size="1.75em" />,
    label: 'Masterlists',
    main: Masterlists,
    path: '/onboarding/masterlists',
    pathname: 'masterlists',
    userAccess: ['superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: Masterlists,
    path: '/onboarding/masterlists',
    userAccess: ['Lender Admin'],
  },
  {
    exact: true,
    hidden: true,
    main: ImportMasterlist,
    path: '/onboarding/masterlists/import',
    pathname: 'import_masterlist',
    userAccess: ['Lender Admin', 'superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: () => <AddEditMasterlist edit />,
    path: '/onboarding/masterlists/:masterlistId/edit',
    pathname: 'edit_masterlist',
    userAccess: ['Lender Admin', 'superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: AddEditMasterlist,
    path: '/onboarding/masterlists/add',
    pathname: 'edit_masterlist',
    userAccess: ['Lender Admin', 'superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: MasterlistDocument,
    path: '/onboarding/masterlists/:masterlistId/document/add',
    pathname: 'add_masterlist_doc',
    userAccess: ['Lender Admin', 'superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: () => <MasterlistDocument edit />,
    path: '/onboarding/masterlists/:masterlistId/document/:masterlistDocId/edit',
    pathname: 'edit_masterlist_doc',
    userAccess: ['Lender Admin', 'superadmin'],
  },
  {
    exact: true,
    hidden: true,
    main: ScheduleAuction,
    path: '/onboarding/auction/:id/schedule',
    pathname: 'schedule-auction',
    userAccess: ['Broker Admin', 'Broker Loan Manager', 'Broker Transaction Manager', 'superadmin', 'PLA'],
  },
  {
    exact: true,
    hidden: true,
    main: Winner,
    path: '/onboarding/winner/:id',
    pathname: 'winner',
    userAccess: [
      'Lender Account Executive',
      'Lender Admin',
      'Lender Transaction Manager',
      'Lender Without Company',
      'Broker Admin',
      'Broker Loan Manager',
      'Broker Transaction Manager',
      'superadmin',
    ],
  },
];
