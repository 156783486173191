import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message, Spin, Tabs, Button } from 'antd';
import { isEmpty } from 'lodash';
import { PLA } from 'constants/roles';
import { SendOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import io from 'socket.io-client';
import { LIVE_AUCTION, PRE_AUCTION, SCHEDULED } from 'constants/larApp';
import { useLARDBContext } from 'pages/Onboarding/LARDB/hooks/LARDBContext';
import Accordion from 'components/UI/Accordion';
import API from 'api';
import Keys from 'constants/helper';
import ManualEMSModal from 'components/Modals/ManualEMSModal';
import UserDrawer from 'components/UserDrawer';
import BorrowerCommentPopup from 'components/LARDB/Auction/BorrowerCommentPopup';
import LARDBFeesWorksheet from '../../LARDBFeesWorksheet';
import LARDBSelectWinModal from 'components/LARDB/Auction/Modals/LARDBSelectWinModal';
import QuoteSummary from '../../SelectQuotes/QuoteSummary/QuoteSummary';
import QuoteTermsExtra from '../../SelectQuotes/QuoteTerms/QuoteTermsExtra/QuoteTermsExtra';
import QuoteVisualizer from '../../SelectQuotes/QuoteVisualizer/QuoteVisualizer';
import RejectQuotesButton from './RejectQuotesButton';
import Table from './Table';
import Timeline from 'components/Timeline';
import styles from './LARDBAuction.module.css';
import { getRatesTracker } from 'store/async-actions/lar';

function LARDBAuction({ goToLoanProfile, getLARPDBData }) {
  const { lar_status, lar_id, lar_members } = useLARDBContext();
  const quotesFromStorage = JSON.parse(sessionStorage.getItem(Keys.WINNER_SELECTION_PREVIEW)) || {};
  const [activeModal, setActiveModal] = useState({ show: false, data: null });
  const [data, setData] = useState({ loading: true });
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('all');
  const [resendQuotes, setResendQuotes] = useState(false);
  const [timeline, setTimeline] = useState({ loading: true });
  const [quotes, setQuotes] = useState(quotesFromStorage[lar_id] ? quotesFromStorage[lar_id].map((el) => el.id) : []);
  const [swiper, setSwiper] = useState(null);
  const dispatch = useDispatch();
  const [swiper2, setSwiper2] = useState(null);
  const winnerSelected = data?.bids?.find((bid) => bid.bid_status === 'winner');
  const token = localStorage.getItem(Keys.JWT_TOKEN);
  const auctionSocket = io(`${process.env.REACT_APP_API_URL}/auction`, { query: { jwt: token, lar_id } });
  const role = useSelector((state) => state.user.userData.role);
  const { ratesTracker } = useSelector((state) => state.lar.data);
  const history = useHistory();
  const filteredQuotesByStatus = useMemo(() => {
    if (isEmpty(data.bids)) {
      return [];
    }
    return data.bids.filter((bid) => bid.bid_status !== 'rescinded');
  }, [data.bids]);

  const sortedQuotes = useMemo(
    () => filteredQuotesByStatus.sort((aBid, bBid) => aBid.number - bBid.number),
    [filteredQuotesByStatus],
  );

  const filteredQuotes = useMemo(() => {
    if (!isEmpty(quotes) && filter === 'selected') {
      return sortedQuotes.filter((el) => quotes.includes(el.id));
    }

    return sortedQuotes;
  }, [sortedQuotes, quotes, filter]);

  function nextSlide() {
    swiper.slideNext();
    swiper2.slideNext();
  }

  function prevSlide() {
    swiper.slidePrev();
    swiper2.slidePrev();
  }

  const tabs = [
    {
      key: 'select_quotes',
      label: 'Select Quotes',
      hidden: /lender/i.test(role),
      children: (
        <div className={styles.auctionInner}>
          <div className={styles.auctionInnerWrapper} />
          {data.timeline && !isEmpty(data.timeline) && (
            <div className={styles.timelineWrapper}>
              <Timeline timeline={data.timeline} />
            </div>
          )}
          <Spin spinning={data.loading && ![PRE_AUCTION, SCHEDULED].includes(lar_status)}>
            <Accordion
              panels={[
                {
                  key: 'quote_terms',
                  header: 'Quote Terms',
                  content: (
                    <Table
                      timeline={timeline}
                      selectedQuotes={quotes}
                      quotes={filteredQuotes}
                      resendQuotes={resendQuotes}
                      goToLoanProfile={goToLoanProfile}
                      handleChooseQuote={handleChooseQuote}
                      openSelectWinQuoteModal={openSelectWinQuoteModal}
                      setQuotes={setQuotes}
                      setSwiper={setSwiper}
                      swiper={swiper}
                      winnerSelected={winnerSelected}
                    />
                  ),
                  ...(quotes.length > 0
                    ? {
                        extra: (
                          <QuoteTermsExtra
                            data={data}
                            filter={filter}
                            nextSlide={nextSlide}
                            prevSlide={prevSlide}
                            setFilter={setFilter}
                            swiper={swiper}
                          />
                        ),
                      }
                    : {}),
                },
                ...(quotes.length > 0
                  ? [
                      {
                        content: <QuoteVisualizer quotes={sortedQuotes} setSwiper={setSwiper2} larId={lar_id} />,
                        header: 'Quote Visualizer (Cash to Close)',
                        key: 'quote_visualizer',
                      },
                      {
                        content: <QuoteSummary quotes={sortedQuotes} statuses={data.statuses} larId={lar_id} />,
                        header: 'Summary',
                        key: 'quote_summary',
                      },
                    ]
                  : []),
              ]}
              defaultActiveKeys={['quote_terms', 'quote_visualizer', 'quote_summary']}
              maxWidth="1230px"
            />
          </Spin>
          <div style={{ marginTop: 16 }} />
        </div>
      ),
    },
    ...(/Quote Selection|Loan Processing/i.test(lar_status)
      ? [
          {
            key: 'fees_worksheet',
            label: 'Fees Worksheet',
            children: <LARDBFeesWorksheet />,
          },
        ]
      : []),
  ];

  function handleChooseQuote(id) {
    setQuotes((prev) => {
      if (prev.find((el) => el === id)) {
        const filteredQuotes = prev.filter((el) => el !== id);
        storeQuoteInSessionStorage(lar_id, filteredQuotes);
        return filteredQuotes;
      }
      storeQuoteInSessionStorage(lar_id, [...prev, id]);
      return [...prev, id];
    });
  }

  function storeQuoteInSessionStorage(larId, quotesIds) {
    const quotes = sortedQuotes.filter((bid) => quotesIds.includes(bid.id));
    sessionStorage.setItem(Keys.WINNER_SELECTION_PREVIEW, JSON.stringify({ [larId]: quotes }));
  }

  function closeActiveModal() {
    setActiveModal((state) => ({ ...state, show: false }));
  }

  function openSendBorrowerCommentModal() {
    setActiveModal((state) => ({ ...state, show: 'borrower_comment' }));
  }

  function openSendQuotesModal() {
    setActiveModal((state) => ({ ...state, show: 'send_quotes' }));
  }

  function openSelectWinQuoteModal(el) {
    setActiveModal((prev) => ({
      ...prev,
      data: { selectedQuote: el },
      show: 'select_win_quote',
    }));
  }

  function sendCommentToBorrower(comment) {
    API()
      .post('/LAR/quotes/broker/comment_to_borrower', { lar_id, comment })
      .then(() => {
        message.success('You have sent a comment to borrower');
        closeActiveModal();
      })
      .catch((err) => {
        console.error(err.response?.data?.error);
        message.error('Error');
      });
  }

  function getBorrowerEmail() {
    API()
      .get('/LAR/quotes/broker/send_quotes', { params: { lar_id } })
      .then(({ data }) => {
        setData((prev) => ({
          ...prev,
          borrower_email: data.borrower_email,
        }));
      })
      .catch((err) => {
        console.error(err.response?.data?.error);
        message.error('Error');
      });
  }

  function getQuotes() {
    API()
      .get('/LAR/quotes/broker', { params: { lar_id } })
      .then(({ data }) => {
        setData((prev) => ({
          ...prev,
          ...data,
          loading: false,
        }));
      })
      .catch((err) => {
        console.error(err.response?.data?.error);
        message.error('Error');
      });
  }

  useEffect(() => {
    if (!ratesTracker) {
      dispatch(getRatesTracker());
    }
  }, []);

  function getTimeline() {
    API()
      .get('/LAR/quotes/broker/timeline', { params: { lar_id } })
      .then(({ data }) => {
        setTimeline({
          data,
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err.response?.data?.error);
        message.error('Error');
      });
  }

  function onSubmitQuotesModalSend(form) {
    API()
      .post('/LAR/quotes/broker/send_quotes', { ...form, lar_id, quotes_id: quotes })
      .then(({ data }) => {
        data.msg === 'ok' && getLARPDBData();

        // setQuotes([]);
        getTimeline();
        getQuotes();

        message.success('Quotes have been sent successfully!');

        setResendQuotes(false);
        closeActiveModal();
      });
  }

  function submitWinningLender() {
    setLoading(true);

    API()
      .post('/LAR/quotes/broker/confirm_winner', {
        lar_id,
        quote_id: activeModal?.data?.selectedQuote?.quote_id,
      })
      .then(() => {
        message.success('Winner selected successfully!');
        closeActiveModal();
        history.push(`/onboarding/winner/${lar_id}`);
      })
      .catch(({ response }) => {
        message.error(response.data.error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    auctionSocket.connect();
    auctionSocket.on('bids_update', ({ message_type, bid }) => {
      if (message_type === 'new') {
        setData((prev) => ({
          ...prev,
          bids: [{ ...bid, newBid: true }, ...prev.bids].sort(
            (aUpdatedAt, bUpdatedAt) => new Date(aUpdatedAt.updated_at) - new Date(bUpdatedAt.updated_at),
          ),
        }));
      }

      if (message_type === 'update') {
        setData((prev) => {
          const newBids = [...prev.bids];
          const updatedBidIdx = prev.bids.findIndex((el) => bid.quote_id === el.quote_id);

          if (updatedBidIdx >= 0) {
            newBids[updatedBidIdx] = { ...bid, updatedBid: true };
          }

          return {
            ...prev,
            bids: [...newBids],
          };
        });
      }

      if (message_type === 'delete') {
        setData((prev) => ({
          ...prev,
          bids: [...prev.bids.filter((el) => el.quote_id !== bid.quote_id)],
        }));
      }
    });

    if (![PRE_AUCTION, SCHEDULED].includes(lar_status)) {
      getQuotes();
    }

    if (![PRE_AUCTION, LIVE_AUCTION, SCHEDULED].includes(lar_status) && role !== PLA) {
      getBorrowerEmail();
      getTimeline();
    }

    return () => auctionSocket && auctionSocket.disconnect();
  }, []); // eslint-disable-line

  const openPreview = (larId, quotes) => {
    if (!quotes) {
      window.open(`/winner-selection?lar_id=${larId}&preview=true&quotes_sent=true`, '_blank').focus();
      return;
    }

    window.open(`/winner-selection?lar_id=${larId}&preview=true`, '_blank').focus();
  };

  const handlePreview = () => {
    openPreview(lar_id, quotes);
  };

  return (
    <>
      <UserDrawer user={activeModal.data} visible={activeModal.show === 'pla'} onClose={closeActiveModal} />
      <ManualEMSModal
        onCancel={closeActiveModal}
        onSubmit={onSubmitQuotesModalSend}
        optionalRecipients={Object.entries(lar_members)
          .map(([category, subcategories]) => ({
            items: Object.values(subcategories).flat(Infinity),
            label: category,
          }))
          .filter(({ label, items }) => label !== 'Borrower' && items.length > 0)}
        templateRoute={`/LAR/quotes/broker/send_quotes?lar_id=${lar_id}`}
        title="Send quotes"
        visible={activeModal.show === 'send_quotes'}
      />
      <ManualEMSModal
        mainRecipient={lar_members.Borrower.Borrower[0]}
        onCancel={closeActiveModal}
        onSubmit={submitWinningLender}
        optionalRecipients={Object.entries(lar_members)
          .map(([category, subcategories]) => ({
            items: Object.values(subcategories).flat(Infinity),
            label: category,
          }))
          .filter(({ label, items }) => label !== 'Borrower' && items.length > 0)}
        templateRoute={`/LAR/quotes/broker/select_winner?lar_id=${lar_id}`}
        title="Confirm the winning lender"
        visible={activeModal.show === 'confirm_win_quote'}
      />
      <LARDBSelectWinModal
        loading={loading}
        onClose={closeActiveModal}
        onOk={submitWinningLender}
        selectedQuote={activeModal?.data?.selectedQuote}
        visible={activeModal.show === 'select_win_quote'}
      />
      <BorrowerCommentPopup
        onCancel={closeActiveModal}
        onOk={sendCommentToBorrower}
        visible={activeModal.show === 'borrower_comment'}
      />
      <div className="lar-profile__auction">
        <div className={styles.auctionSection}>
          <div className={styles.auctionHeader}>
            <h2 className={styles.auctionTitle}>Select Winning Quote</h2>
            {!winnerSelected && role !== PLA && (
              <div className={styles.auctionButtons}>
                <div>
                  {quotes.length}
                  /3 Quotes Selected
                </div>
                {quotes.length > 0 ? (
                  <>
                    <Button onClick={openSendBorrowerCommentModal} size="large">
                      Add Comment
                    </Button>
                    <Button onClick={handlePreview} size="large">
                      Preview
                    </Button>
                    <Button
                      onClick={openSendQuotesModal}
                      size="large"
                      type="secondary"
                      icon={<SendOutlined style={{ fontSize: '16px' }} />}
                    >
                      Send
                    </Button>
                  </>
                ) : (
                  <RejectQuotesButton id={lar_id} />
                )}
              </div>
            )}
          </div>
          <div>
            <Tabs className={classNames(styles.tabs, 'custom-tabs')} defaultActiveKey="1" items={tabs} />
          </div>
        </div>
      </div>
    </>
  );
}

export default LARDBAuction;
