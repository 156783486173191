import { message as toast, Modal, Button } from 'antd';
import { useMemo, useState } from 'react';
import API from 'api';
import regExp from 'constants/regExp';
import PhoneComponent from '../../CustomComponents/PhoneInput';
import TextInput from '../../CustomComponents/TextInput';

function AddBorrowerPopup({
  setFields,
  initialStateModal,
  activeModal,
  onCloseActiveModal,
  fields,
  handleNewBorrower = () => {},
  fetchBorrowers = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const handleChange = ({ target }) => {
    setFields((prev) => ({
      ...prev,
      [target.name]: {
        ...prev[target.name],
        error: false,
        value: target.value,
      },
    }));
  };

  const isSubmitSendBtnDisabled = useMemo(() => {
    const { data, show } = activeModal;
    const { phone, name, surname, email } = fields;

    if (show !== 'edit_borrower') {
      return false;
    }

    return (
      data &&
      data.phone === phone.value &&
      data.full_name === `${name.value} ${surname.value}`.trim() &&
      data.email === (email?.value?.trim() || '')
    );
  }, [activeModal, fields]);

  async function createBorrower() {
    const editBorrower = activeModal.show === 'edit_borrower';

    try {
      validateFields(fields);
      const params = {
        name: fields.name.value,
        surname: fields.surname.value,
        phone: fields.phone.value,
        email: fields.email.value,
      };

      if (editBorrower) {
        params.id = activeModal.data.id;
      }

      setLoading(true);
      const res = await API().post('/prospects/broker', params);

      fetchBorrowers();
      handleNewBorrower(res.data.borrower);
      onCloseActiveModal();
      setFields(initialStateModal);

      toast.success(`You successfully ${editBorrower ? 'edited' : 'added'} borrower`);
    } catch (error) {
      if (error.type === 'validation') {
        error.fields.forEach((field) => {
          setFields((prev) => ({
            ...prev,
            [field.name]: {
              ...prev[field.name],
              error: true,
              errorMessage: field.message,
            },
          }));
        });
      } else if (error.type === 'request' && error.response?.data?.error === 'This email is already exists') {
        setFields((prev) => ({
          ...prev,
          email: {
            ...prev.email,
            error: true,
            errorMessage: error.response.data.error,
          },
        }));
      }
    } finally {
      setLoading(false);
    }
  }

  function validateFields(fields) {
    const errors = [];

    if (!fields.name.value) {
      errors.push({ name: 'name', message: 'Fill this field' });
    }

    if (!fields.surname.value) {
      errors.push({ name: 'surname', message: 'Fill this field' });
    }

    if (fields.phone.value.length !== 10) {
      errors.push({ name: 'phone', message: 'Phone number must be 10 numbers' });
    }

    if (!regExp.email.test(fields.email.value)) {
      errors.push({ name: 'email', message: 'Incorrect email format' });
    }

    if (errors.length > 0) {
      throw Object.assign(new Error(), { type: 'validation', fields: errors });
    }
  }

  const title = activeModal.show === 'edit_borrower' ? 'Edit' : 'Add';
  const description =
    activeModal.show === 'edit_borrower' ? 'Edit borrower prospect information' : 'Add a new borrower to the list';

  return (
    <Modal
      className="wrapper_prospect"
      afterClose={() => setFields(initialStateModal)}
      title={
        <div className="wrapper_prospect__title">
          <span className="title">{title} a borrower</span>
          <span className="description">{description}</span>
        </div>
      }
      open={['add_borrower', 'edit_borrower'].includes(activeModal.show)}
      onOk={onCloseActiveModal}
      centered
      footer={[
        <Button onClick={onCloseActiveModal} size="large">
          Cancel
        </Button>,
        <Button onClick={createBorrower} disabled={isSubmitSendBtnDisabled} size="large" type="dark" loading={loading}>
          Confirm
        </Button>,
      ]}
      onCancel={onCloseActiveModal}
    >
      <form className="wrapper_add_prospect_form" onSubmit={createBorrower}>
        <TextInput {...fields.name} htmlFor="name" onChange={handleChange} />
        <TextInput {...fields.surname} htmlFor="surname" onChange={handleChange} />
        <PhoneComponent
          {...fields.phone}
          onChange={(value) => {
            setFields((prev) => ({
              ...prev,
              phone: {
                error: false,
                value,
              },
            }));
          }}
        />
        <TextInput {...fields.email} htmlFor="email" onChange={handleChange} />
      </form>
    </Modal>
  );
}

export default AddBorrowerPopup;
