import { useState, useMemo } from 'react';
import { BDRAPI } from 'api';
import CustomSearchInput from 'components/CustomComponents/CustomSearchInput';

function SearchBox({ userType = 'user', onResultClick = () => null }) {
  const [findItemData, setFindItemData] = useState([]);
  const [ready, setReady] = useState(true);

  const API = useMemo(() => new BDRAPI(), []);

  function searchItems(query) {
    setReady(false);

    API.searchForUsers(userType, query).then((users) => {
      const resultsUI = users.map((user) => (
        <div className="custom-search__result-item" key={user.id} onClick={() => onResultClick(user)}>
          {user.full_name}
          <span style={{ float: 'right', marginLeft: 30 }}>
            id:
            {user.id}
          </span>
        </div>
      ));

      setFindItemData(resultsUI);
      setReady(true);
    });
  }

  return (
    <CustomSearchInput
      clearFunc={() => setFindItemData([])}
      enterButton
      loading={!ready}
      placeholder="Search"
      results={findItemData}
      searchFunc={searchItems}
    />
  );
}

export default SearchBox;
