import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { DashboardAPI } from 'api';
import { getDashboardMetrics } from 'store/actions/dashboard';
import BrokerWidgets from './BrokerWidgets';
import BrokerTable from './BrokerTable';

import styles from './BrokerDashboard.module.css';

export default function BrokerDashboard({ data }) {
  const dispatch = useDispatch();
  const API = useMemo(() => new DashboardAPI(), []);

  useEffect(() => {
    API.getMetrics().then((res) => {
      dispatch(getDashboardMetrics(res));
    });
  }, []);

  return (
    <div className={styles.content}>
      <BrokerWidgets data={data} />
      <BrokerTable API={API} />
    </div>
  );
}
