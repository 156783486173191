import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './BarChart.module.css';
import infoIcon from '../../../assets/icons/info-circle.svg';
import closeIcon from '../../../assets/icons/close-white.svg';
import Keys from '../../../constants/helper';
import Modal from '../../Modals/Modal/Modal';
import PopupTable from '../../LARDB/SelectQuotes/PopupTable/PopupTable';
import { numberToCurrency } from '../../../constants/numberToComma';
import { getCategorySum, getClosingCost, getDownPayment, getSumOriginationFees } from '../../../utils/formula';
import { closePopup, openPopup } from '../../../store/actions/popupsActions';
import { fetchFeesWorksheet } from '../../../store/async-actions/feesWorksheet';
import { LARAPI } from '../../../api';

function BarChart({ quote, customQuotes, theMostLoanAmount, barTitle, larId }) {
  const dispatch = useDispatch();
  const { show: activeExitModal, data } = useSelector((state) => state.popups);
  const { feesWorksheet } = useSelector((state) => state);

  const quotesFromStorage = JSON.parse(sessionStorage.getItem(Keys.WINNER_SELECTION_PREVIEW)) || {};
  const quotes =
    customQuotes?.map((item) => item.id) ||
    (quotesFromStorage[larId] ? quotesFromStorage[larId].map((item) => item.id) : []);

  const API = useMemo(() => new LARAPI(+larId), [larId]);

  const handleShowInfo = () => {
    dispatch(openPopup({ show: 'closingCost', data: popupTableData }));
  };

  const onCloseActiveModal = () => {
    dispatch(closePopup());
  };

  const downPayment = getDownPayment(feesWorksheet, quote) || 0;
  const closingCost = getClosingCost(feesWorksheet, quote) || 0;

  const barTitleSum = downPayment + closingCost;

  const closingCostBar = (closingCost / (closingCost + downPayment)) * 100;
  const downPaymentBar = (downPayment / (closingCost + downPayment)) * 100;

  const sumOfDPandCC = downPayment + closingCost;

  const barHeight = (sumOfDPandCC * 100) / theMostLoanAmount;

  const isWinner = quote.bid_status === 'winner';
  const selected = quotes.some((quoteId) => quoteId === quote.quote_id);
  const isHidden = !isWinner && !selected;

  useEffect(() => {
    dispatch(fetchFeesWorksheet(larId));
  }, [API, larId, dispatch]);

  // TODO: after finishing backend work, need to transfer data dynamically

  const sumOriginationFees = getSumOriginationFees(feesWorksheet, quote);
  const popupTableData = [
    { label: 'Origination fees', value: sumOriginationFees },
    { label: 'Services', value: getCategorySum(feesWorksheet, 'services.') },
    { label: 'Taxes and other government fees', value: getCategorySum(feesWorksheet, 'taxes.') },
    {
      label: 'Prepaid',
      value: getCategorySum(feesWorksheet, 'prepaid.'),
    },
    {
      label: 'Initial escrow at closing',
      value: getCategorySum(feesWorksheet, 'initial_escrow.'),
    },
  ];

  return (
    <div className={classNames([styles.quoteItem, isHidden && styles.hidden])}>
      {barTitle && <p className={styles.barTitle}>{barTitle}</p>}
      <div className={styles.quoteItemAmount}>{numberToCurrency(barTitleSum)}</div>
      <div className={styles.barWrapper}>
        <div style={{ height: `${barHeight}%` }} className={styles.barItemWrapper}>
          <div
            style={{ height: `${closingCostBar}%`, maxHeight: '100%', minHeight: '32px' }}
            className={styles.barItem}
          >
            <div className={styles.barInner} onClick={handleShowInfo}>
              <div className={styles.barContent}>
                closing cost
                <span style={{ display: 'flex' }}>
                  <img className={styles.info} src={infoIcon} alt="(i)" />
                </span>
              </div>
            </div>
          </div>
          <div
            style={{ height: `${downPaymentBar}%`, maxHeight: '100%', minHeight: '32px' }}
            className={styles.barItem}
          >
            <div className={classNames([styles.barInner, styles.barInnerDP])}>
              {downPaymentBar > 10 && <div className={styles.barContent}>down payment</div>}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.quoteItemNumber}>Lender #{quote.number}</div>
      <Modal
        className={styles.modal}
        closeIcon={<img src={closeIcon} alt="X" />}
        bodyStyle={{ backgroundColor: '#262626', padding: 0 }}
        footer=""
        onCancel={onCloseActiveModal}
        visible={activeExitModal === 'closingCost'}
      >
        <PopupTable title="Closing Cost" data={data} />
      </Modal>
    </div>
  );
}

export default BarChart;
