import { useContext, useEffect } from 'react';
import { DatePicker, Form, InputNumber, Select, Slider, Input } from 'antd';
import moment from 'moment';
import LarAppContext from '../../../context/LarAppContext';
import numberToComma from 'constants/numberToComma';

const getMarks = (num) => {
  const data = {};
  for (let i = num; i >= 50; i -= 10) {
    data[i] = `${i}`;
  }
  return data;
};

const marks = {
  50: '50',
  60: '60',
  70: '70',
  80: '80',
};

function ProjectFormPurchaseShort({ form, onFieldsChange }) {
  const {
    use,
    LTV,
    property_value,
    loan_style_name,
    closing_date,
    renovation_costs,
    requesting_amount,
    ARV,
    SA_values,
  } = useContext(LarAppContext);
  const purchasePrice = Form.useWatch('property_value', form);
  const renovationCosts = Form.useWatch('renovation_costs', form);
  const ltv = Form.useWatch('ltv', form);
  const arv = Form.useWatch('ARV', form);

  useEffect(() => {
    form.setFieldsValue({
      LTV,
      loan_style_name,
      closing_date: closing_date ? moment(closing_date) : moment(new Date(), 'YYYY-DD-MM').add(12, 'day'),
      renovation_costs,
      property_value,
      requesting_amount,
      ARV,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function handleChangeRequestingAmount() {
      form.setFieldsValue({
        requesting_amount: numberToComma(Math.round((arv * LTV) / 100)) || null,
      });
      const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
        name: el[0],
        value: el[1],
      }));
      onFieldsChange([6], valuesArr);
    }

    handleChangeRequestingAmount();
    // eslint-disable-next-line
  }, [purchasePrice, renovationCosts]);

  function handleChangeARV(e) {
    form.setFieldsValue({
      requesting_amount: numberToComma(Math.round((e * ltv) / 100)),
    });
    const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
      name: el[0],
      value: el[1],
    }));
    onFieldsChange([5], valuesArr);
  }

  function handleChangeLTV(e) {
    form.setFieldsValue({
      requesting_amount: numberToComma(Math.round((arv * e) / 100)),
    });
    const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
      name: el[0],
      value: el[1],
    }));
    onFieldsChange([4], valuesArr);
  }

  return (
    <>
      {use === 'Consumer' ? null : (
        <Form.Item
          label="Investment style"
          name="loan_style_name"
          className="scenario-required-field auction-required-field"
        >
          <Select style={{ width: 215 }}>
            <Select.Option value="Fix to Flip">Fix to Flip</Select.Option>
            <Select.Option value="Bridge (Fix to Refi)">Bridge (Fix to Refi)</Select.Option>
            <Select.Option value="Ground-up construction">Ground-up construction</Select.Option>
          </Select>
        </Form.Item>
      )}
      <Form.Item
        label="Estimated closing date"
        name="closing_date"
        initialValue={moment(new Date(), 'YYYY-DD-MM').add(11, 'day')}
        className="auction-required-field"
      >
        <DatePicker
          style={{ width: 215 }}
          disabledDate={(currentDate) => currentDate.isBefore(moment(new Date(), 'YYYY-DD-MM').add(11, 'day'))}
        />
      </Form.Item>
      <Form.Item
        label="Purchase price"
        name="property_value"
        className="scenario-required-field auction-required-field"
      >
        <InputNumber
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          style={{ width: 215 }}
          addonBefore="$"
        />
      </Form.Item>
      <Form.Item
        label="Const/reno budget"
        name="renovation_costs"
        className="scenario-required-field auction-required-field"
      >
        <InputNumber
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          style={{ width: 215 }}
          addonBefore="$"
        />
      </Form.Item>
      <Form.Item label="ARV" name="ARV" className="scenario-required-field auction-required-field">
        <InputNumber
          onChange={handleChangeARV}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          style={{ width: 215 }}
          addonBefore="$"
        />
      </Form.Item>
      <Form.Item label="Target LTV" className="scenario-required-field auction-required-field">
        <Form.Item
          name="LTV"
          style={{
            display: 'inline-block',
            marginBottom: 0,
          }}
        >
          <InputNumber
            onChange={handleChangeLTV}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            max={SA_values?.short_purchase_LTC ?? 80}
            style={{ width: 100 }}
            addonBefore="%"
          />
        </Form.Item>
        <Form.Item
          name="LTV"
          style={{
            display: 'inline-block',
            marginBottom: 0,
            marginLeft: 30,
          }}
        >
          <Slider
            onChange={handleChangeLTV}
            style={{ width: 220 }}
            min={50}
            max={SA_values?.short_purchase_LTC ?? 80}
            marks={SA_values?.short_purchase_LTC ? getMarks(SA_values?.short_purchase_LTC) : marks}
            step={1}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item label="Target loan amount" name="requesting_amount">
        <Input style={{ width: 215 }} disabled addonBefore="$" />
      </Form.Item>
    </>
  );
}

export default ProjectFormPurchaseShort;
