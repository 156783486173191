import { ArrowBarIcon } from 'assets/icons';
import { Breadcrumb, Button } from 'antd';
import { Link } from 'react-router-dom';
import classes from './styles/Header.module.css';

export default function Header({
  activeSortingOption,
  setActiveSortingOption,
  showNav = true,
  sortingOptions,
  toggleNav,
  id,
}) {
  return (
    <header className={classes.root}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link
            to={{
              pathname: '/onboarding/dashboard',
              state: {
                activeTab: 'scenarios',
              },
            }}
          >
            Scenarios
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Scenario #{id}</Breadcrumb.Item>
      </Breadcrumb>
      <h1>Scenarios</h1>
      <div className={classes.buttonsBar}>
        {showNav && (
          <>
            <span>Sort by</span>
            <div className={classes.sortingBox}>
              {sortingOptions.map(({ name, Icon }) => (
                <Button key={name} onClick={() => setActiveSortingOption(name)}>
                  <Icon colour={name === activeSortingOption ? 'brand-5' : 'grey-7'} />
                </Button>
              ))}
            </div>
          </>
        )}
        <Button onClick={toggleNav}>
          <ArrowBarIcon direction={showNav ? 'left' : 'right'} />
        </Button>
      </div>
    </header>
  );
}
