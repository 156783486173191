import { FullScreenIcon } from 'assets/icons/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { useState } from 'react';
import DocumentViewer from 'components/OnBoarding/DocumentViewer';
import ActionsFooter from '../ActionsFooter';

export default function CompleteDocumentView({ viewDiligence, viewFullscreen, closeDiligenceItem, role }) {
  const [selectedDocument, setSelectedDocument] = useState(
    viewDiligence?.documents?.length && { file: viewDiligence.documents[0].document_path },
  );

  if (viewDiligence.documents?.length < 1) {
    return null;
  }

  return (
    <div className="document-view">
      <div className="document-view-content">
        <Select
          className="document-select"
          onChange={(i) => {
            setSelectedDocument({
              file: i,
              name: i.split('/').pop(),
            });
          }}
          value={selectedDocument?.file}
        >
          {(viewDiligence.documents ?? []).map((i) => {
            const fileName = i.document_path.split('/').pop().split('?')[0];

            return (
              <Select.Option key={i.id} value={i.document_path}>
                {fileName}
              </Select.Option>
            );
          })}
        </Select>
        {selectedDocument?.id === 'add-new' || (
          <div className="fullscreen-trigger" onClick={() => viewFullscreen(selectedDocument.file)}>
            <FullScreenIcon />
          </div>
        )}
        <DocumentViewer
          activeDocument={selectedDocument?.file}
          errorHeight={600}
          loading={
            <div className="doc-loading">
              <LoadingOutlined style={{ fontSize: 108 }} />
            </div>
          }
          prefix="Page: "
          scale={0.6}
        />
      </div>
      <ActionsFooter
        closeDiligenceItem={closeDiligenceItem}
        documentType="completed"
        instantAccept={viewDiligence.acceptAction}
        instantReject={viewDiligence.rejectAction}
        itemId={viewDiligence.id}
        role={role}
        stage={viewDiligence.item_stage}
      />
    </div>
  );
}
