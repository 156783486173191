import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import successIcon from 'assets/icons/success.svg';
import failedIcon from 'assets/icons/failed.svg';
import styles from './TimelineItem.module.css';

function TimelineItem({ title, status, date }) {
  function getIcon() {
    switch (status) {
      case 'green':
        return successIcon;
      case 'red':
        return failedIcon;
      default:
        return null;
    }
  }
  return (
    <li className={styles.item}>
      <h4 className={styles.title}>{title}</h4>
      {getIcon() && (
        <div className={classNames(styles.iconWrapper, styles[status])}>
          <img src={getIcon()} alt={title} />
        </div>
      )}
      <p className={styles.date}>{date ? moment(date).format('DD MMM hh:mm A') : ''}</p>
    </li>
  );
}

export default TimelineItem;

TimelineItem.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  date: PropTypes.string,
};
