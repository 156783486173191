import { useContext, useEffect } from 'react';
import { Checkbox, Form } from 'antd';

import { larVariables } from '../../../helpers';
import LarAppContext from 'components/LarAppNew/context/LarAppContext';
import Type from '../Type';
import OccupancyType from '../OccupancyType';
import CheckOwner from '../CheckOwner';
import Units from '../Units';

function PropertyFormPurchaseLong({ form, totalUnitsRange, setTotalUnitsRange, onFieldsChange }) {
  const {
    use,
    property_type,
    property_type_description,
    occupancy_type,
    is_currently_occupied,
    owners_occupied,
    number_of_rental_units,
    number_of_rental_units_occupied,
  } = useContext(LarAppContext);

  const rentalUnits = Form.useWatch('number_of_rental_units', form);

  useEffect(() => {
    form.setFieldsValue({
      property_type,
      property_type_description,
      occupancy_type,
      is_currently_occupied,
      owners_occupied,
      number_of_rental_units,
      number_of_rental_units_occupied,
    });
  }, []);

  const propertyType = Form.useWatch('property_type', form);
  const isDisplayOccupied = propertyType !== '2-4 Unit' && propertyType !== 'MultiFamily 5-20';

  // TODO: Refactor this
  useEffect(() => {
    if (propertyType === '2-4 Unit' && (rentalUnits < 2 || rentalUnits > 4)) {
      form.setFieldsValue({
        number_of_rental_units: 2,
        number_of_rental_units_occupied: 0,
      });
    }

    if (propertyType === 'MultiFamily 5-20' && (rentalUnits < 5 || rentalUnits > 1000)) {
      form.setFieldsValue({
        number_of_rental_units: 5,
        number_of_rental_units_occupied: 0,
      });
    }
  }, [totalUnitsRange, rentalUnits, propertyType, form]);

  return (
    <>
      <Type
        form={form}
        number_of_rental_units={number_of_rental_units}
        onFieldsChange={onFieldsChange}
        setTotalUnitsRange={setTotalUnitsRange}
      />
      {use === larVariables.use.consumer && <OccupancyType investment={false} />}
      <CheckOwner isDisplayOccupied={isDisplayOccupied} />
      {propertyType === '2-4 Unit' || propertyType === 'MultiFamily 5-20' ? (
        <Units rentalUnits={rentalUnits} totalUnitsRange={totalUnitsRange} />
      ) : null}
      <Form.Item label="Owner occupied" name="owners_occupied" valuePropName="checked">
        <Checkbox />
      </Form.Item>
    </>
  );
}

export default PropertyFormPurchaseLong;
