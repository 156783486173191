import styles from './QuoteSummary.module.css';
import QuoteTable from './QuoteTable/QuoteTable';

function QuoteSummary({ quotes, statuses, larId }) {
  if (quotes.length === 0) {
    return null;
  }

  return (
    <div className={styles.section}>
      <div className={styles.plug} />
      <QuoteTable larId={larId} term={statuses?.loan_term} />
    </div>
  );
}

export default QuoteSummary;
