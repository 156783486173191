import './styles/broker-profile.css';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from 'api';
import AvatarUpload from 'components/AvatarUpload';
import Button from 'components/UI/Button';
import fillError from 'constants/fillRegistrationErrors';
import fillFields from 'constants/fillRegistrationFields';
import otherConst from 'constants/otherConst';
import RenderInputs from 'components/RenderInputs';
import SectionTitle from 'components/OnBoarding/SectionTitle';

const initialCompanyAddress = {
  city: '',
  state: '',
  street: '',
  zip: '',
};

function BrokerLendingProfile() {
  const history = useHistory();
  const [fields, setFields] = useState({
    broker_picture: {
      ignoreRender: true,
      value: null,
      file: null,
      error: null,
      errorMessage: 'Fill this label',
      optional: true,
      title: 'Company logo',
      buttonText: 'Upload a logo',
    },
    broker_full_name: {
      value: '',
      error: null,
      errorMessage: 'Fill this label',
      title: 'Full Name',
    },
    sectionTitle_3: {
      title: 'Address (Optional)',
      type: 'heading-20',
      style: {
        margin: '24px 0 12px',
      },
    },
    checkbox_useCompanyAddress: {
      boxes: {
        useCompanyAddress: {
          title: 'Use company address',
          style: { margin: 0 },
          skip: true,
          value: false,
        },
      },
    },
    dividedInputs_4: {
      inputs: {
        city: {
          title: 'City',
          value: '',
          error: null,
          errorMessage: 'Fill this label',
          optional: true,
        },
        state: {
          title: 'State',
          type: 'select',
          value: '',
          error: null,
          errorMessage: 'Fill this label',
          options: otherConst.states,
          optional: true,
        },
      },
    },
    dividedInputs_5: {
      inputs: {
        zip: {
          title: 'Zip',
          maxLength: 5,
          value: '',
          regex: '^[0-9]*$',
          error: null,
          errorMessage: 'Fill this label',
          optional: true,
        },
      },
    },
  });
  const [edit, setEdit] = useState(false);
  const [companyAddress, setCompanyAddress] = useState({
    initialCompanyAddress,
  });

  function isUsingCompanyAddress() {
    const city = fields.dividedInputs_4.inputs.city.value;
    const state = fields.dividedInputs_4.inputs.state.value;
    const zip = fields.dividedInputs_5.inputs.zip.value;

    return companyAddress.city === city && companyAddress.state === state && companyAddress.zip === zip;
  }

  useEffect(() => {
    // Detect if using company address and set the checkbox to reflect
    const fieldsCopy = { ...fields };
    fieldsCopy.checkbox_useCompanyAddress.boxes.useCompanyAddress.value = isUsingCompanyAddress();
    setFields(fieldsCopy);
    // eslint-disable-next-line
  }, [
    companyAddress,
    fields.dividedInputs_4.inputs.city.value,
    fields.dividedInputs_4.inputs.state.value,
    fields.dividedInputs_5.inputs.zip.value,
  ]);

  useEffect(() => {
    if (fields.checkbox_useCompanyAddress) {
      const useAddress = fields.checkbox_useCompanyAddress.boxes.useCompanyAddress.value;

      // Don't clean the whole form when removing the checkbox
      // and let the user change e.g. only the house number
      // while having the same city
      if (!useAddress) return;

      // Don't do anything if the address already matches the company address
      if (isUsingCompanyAddress()) return;

      fillFields({ ...companyAddress }, useAddress, fields, setFields);
    }

    // eslint-disable-next-line
  }, [fields.checkbox_useCompanyAddress?.boxes.useCompanyAddress.value]);

  useEffect(() => {
    getLandingData();
    getCompanyAddress();

    // eslint-disable-next-line
  }, []);

  const autocompleteAddressFields = (address, city, state, zip) => {
    onChange({ target: { value: city } }, 'dividedInputs_4.inputs.city');
    onChange({ target: { value: state.value ?? state } }, 'dividedInputs_4.inputs.state');
    onChange({ target: { value: zip } }, 'dividedInputs_5.inputs.zip');
  };

  const getLandingData = () => {
    API()
      .get('/LAR/landing/broker/broker-custom-landing')
      .then(({ data }) => {
        fillFields(data.broker_custom_landing, true, fields, setFields);

        if (data.company_landing_done) {
          setEdit(data.edit);
        } else {
          message.warn('You should set a domain in preferences first.');
        }
      });
  };

  const getCompanyAddress = () => {
    API()
      .get('/user_settings/company/broker')
      .then((res) => {
        const { state, city, zip } = res.data.broker_company;

        setCompanyAddress({ city, state, zip });
      });
  };

  const onSave = () => {
    const request = {};
    const temp = { ...fields };

    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get('id');

    Object.entries(temp).forEach((i) => {
      const field = i[1];

      if (i[0].includes('dividedInputs')) {
        Object.entries(field.inputs).forEach((item) => {
          if (
            !item[1].skip &&
            ((item[1].type === 'number' && (item[1].value || item[1].value === 0)) || item[1].type !== 'number')
          ) {
            request[item[0]] = item[1].value;
          }
        });
      } else if (i[0].includes('radioButtons')) {
        if (!i[1].skip) {
          const fieldName = i[0].split('_').slice(1).join('_');
          request[fieldName] = field.value;
        }
      } else if (i[0] === 'broker_picture') {
        if (typeof field.value !== 'string') {
          request[i[0]] = field.value;
        }
      } else if (i[0].includes('switchButtons')) {
        Object.entries(field.switches).forEach((item) => {
          if (!item[1].skip) {
            request[item[0]] = item[1].value;
          }
        });
      } else if (i[0].includes('selectTags')) {
        if (!i[1].skip) {
          const fieldName = i[0].split('_').slice(1).join('_');
          request[fieldName] = i[1].tags.join(', ');
        }
      } else if (i[0].includes('upload')) {
        if (!i[1].skip && i[1].file) {
          const fieldName = i[0].split('_').slice(1).join('_');
          request[fieldName] = i[1].file;
        }
      } else if (i[0].includes('checkbox')) {
        Object.entries(field.boxes).forEach((item) => {
          if (!item[1].skip) {
            request[item[0]] = item[1].value;
          }
        });
      } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle')) {
        // eslint-disable-line
      } else if (
        !i[1].skip &&
        ((i[1].type === 'number' && (i[1].value || i[1].value === 0)) || i[1].type !== 'number')
      ) {
        request[i[0]] = field.value;
      }
    });

    const FD = new FormData();

    Object.entries(request).forEach((i) => {
      if (i[1]) {
        FD.append(i[0], typeof i[1] === 'boolean' ? (i[1] ? 1 : 0) : i[1]);
      }
    });

    if (user_id) {
      FD.append('id', user_id);
    }

    API()
      .post('/LAR/landing/broker/broker-custom-landing', FD)
      .then(() => {
        message.success('Custom Landing Page successfully saved!');
      })
      .catch((res) => {
        const errors = res.response.data['wrong columns'];
        const errorObj = {};

        errors?.forEach((i) => {
          Object.entries(i).forEach((i) => {
            errorObj[i[0]] = i[1];
          });
        });

        fillError(errorObj, true, fields, setFields);
      });
  };

  const size = (file) => parseFloat(file.size / 1024).toFixed(2);

  const uploadLogo = (file, name) => {
    if (size(file) > 5000) {
      setFields((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          error: true,
          errorMessage: 'File is too big',
        },
      }));
    } else {
      setFields((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: file,
          preview: URL.createObjectURL(file),
          error: false,
        },
      }));
    }
  };

  const onChange = (event, route) => {
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const { value } = event.target;

    setFields((state) => {
      const tempFields = { ...state };

      if (routeLength === 3) {
        tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
          ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
          value,
          error: false,
        };
      } else if (routeLength === 2) {
        tempFields[routeArr[0]][routeArr[1]] = {
          ...tempFields[routeArr[0]][routeArr[1]],
          value,
          error: false,
        };
      } else if (routeLength === 1) {
        tempFields[routeArr[0]] = {
          ...tempFields[routeArr[0]],
          value,
          error: false,
        };
      }

      return tempFields;
    });
  };

  return (
    <div className="broker-profile-wrapper">
      <SectionTitle title="Your personal custom landing page" />
      <div style={{ marginTop: 12 }} />
      <SectionTitle
        type="body-14"
        title={
          'Please make sure your company settings under "Preferences" tab is complete or you will not be able to change your custom landing page settings'
        }
      />
      <div className="wrapper_file_uploader">
        <p className="avatar_title">Your picture</p>
        <AvatarUpload
          propsUpload={(file) => uploadLogo(file, 'broker_picture')}
          disabled={!edit}
          description="Recommended: 4x3 aspect ratio high resolution photo in PNG or JPEG, 5mb maximum file size"
          avatarPlaceholderIcon={
            <svg width="160" height="121" viewBox="0 0 160 121" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="160" height="121" rx="8" fill="#F5F5F5" />
              <path
                d="M80 41C69.158 41 60 50.158 60 61C60 71.842 69.158 81 80 81C90.842 81 100 71.842 100 61C100 50.158 90.842 41 80 41ZM80 77C75.698 77 71.73 75.196 68.82 72.32C70.326 68.706 73.85 66.144 78 66.144H82C86.15 66.144 89.674 68.706 91.18 72.32C88.27 75.196 84.302 77 80 77ZM80 51C83.454 51 86 53.544 86 57C86 60.456 83.454 63 80 63C76.548 63 74 60.456 74 57C74 53.544 76.548 51 80 51Z"
                fill="#C5C5C5"
              />
            </svg>
          }
          errorMessage={fields.broker_picture.error ? fields.broker_picture.errorMessage : null}
          preview={fields.broker_picture.preview ?? fields.broker_picture.value}
        />
      </div>
      <div className="broker-profile-content">
        <RenderInputs
          edit={edit}
          data={fields}
          autocompleteAddressFields={autocompleteAddressFields}
          hideErrors
          onChange={onChange}
        />
      </div>
      <div className="wrapper_btns">
        <Button text="Cancel" disabled={!edit} onClick={() => history.goBack()} />
        <Button text="Save" disabled={!edit} style={{ marginLeft: '24px' }} className="btn_darkblue" onClick={onSave} />
      </div>
    </div>
  );
}

export default BrokerLendingProfile;
