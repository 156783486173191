import { useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'antd';
import classNames from 'classnames';
import { numberToCurrency } from 'constants/numberToComma';
import ChatIndicator from 'components/UI/ChatIndicator';
import { formatAMPM } from '../../../../utils/date';
import { setChatState } from 'store/actions/userActions';
import styles from './BidTableItem.module.css';

function BidTableItem({ item, bidValuesData }) {
  const [termsVisible, toggleTermsVisible] = useReducer((state) => !state, false);
  const dispatch = useDispatch();
  const { id } = useParams();

  function getTime() {
    const isToday = new Date().toDateString() === new Date(item.updated_at).toDateString();
    if (isToday) {
      return formatAMPM(item.updated_at);
    }

    return item.updated_at;
  }

  function handleChatOpen() {
    dispatch(setChatState({ bid_id: item.id, lar_id: +id, chat_id: item.chat_id, type: 'sidebar' }));
  }

  return (
    <div className="bid-table-list__list-item">
      <div className="bid-table-list__list-item-header">
        <div className={classNames('bid-table-list__list-item-header-status', item.color && styles[item.color])}>
          <span>{item.is_owner ? 'My Bid' : item.action_indicator?.last_action_indicator || 'New Bid'}</span>
          <span>{getTime()}</span>
        </div>
        <div className="bid-table-list__list-item-header-label">
          {`Lender #${item.number}`}
          <Button
            type="text"
            onClick={handleChatOpen}
            icon={<ChatIndicator isVisible={item.chat_exist} hasIndicator={item.new_messages} />}
          />
        </div>
        <div className="bid-table-list__list-item-header-amount">{numberToCurrency(item.loan_amount)}</div>
      </div>
      <div className="bid-table-list__list-item-body">
        <div className="bid-table-list__info-block">
          {bidValuesData.map((el, id) => (
            <div className="bid-table-list__info-block-item" key={id}>
              {el.format ? el.format(item[el.value]) : item[el.value]}
            </div>
          ))}
        </div>
      </div>
      <div className="bid-table-list__list-item-footer">
        {item.additional_terms_and_comment && (
          <div className="bid-table-list__list-item-action">
            <span
              className="bid-table-list__list-item-link bid-table-list__list-item-link_with-icon"
              onClick={toggleTermsVisible}
            >
              <span className="bid-table-list__list-item-link-text">View Additional Terms</span>
            </span>
          </div>
        )}
      </div>
      <Modal footer={null} onCancel={toggleTermsVisible} title="Additional terms" open={termsVisible}>
        <div dangerouslySetInnerHTML={{ __html: item.additional_terms_and_comment }} />
      </Modal>
    </div>
  );
}

export default BidTableItem;
