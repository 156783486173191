import React from 'react';
import LarAppConfirm from 'pages/Onboarding/LarAppNew/LarAppConfirm/LarAppConfirm';
import LarAppLoadWrapper from '../../components/LarAppNew/FormWrappers/LarAppLoadWrapper';
import LarCognitoforms from '../../components/LarAppNew/LarCognitoforms';

export const mainNavLarAppNew = [
  {
    title: 'Create new loan application',
    path: '/lar_app/create',
    main: (props) => <LarCognitoforms {...props} />,
  },
  {
    title: 'Create new loan application',
    path: '/lar_app/form',
    main: (props) => <LarAppLoadWrapper {...props} />,
  },
  {
    exact: true,
    hidden: true,
    main: (props) => <LarAppConfirm {...props} />,
    path: '/lar_app/confirm/:id',
    pathname: 'lar-app-confirm',
    userAccess: ['Broker Admin', 'Broker Loan Manager', 'Broker Transaction Manager', 'superadmin'],
  },
];
export default mainNavLarAppNew;
