import { getLARFields } from 'constants/stringReplaceData';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMergeFields } from 'constants/useMergeFields';
import { message as toast } from 'antd';
import API from 'api';
import Button from 'components/UI/Button';
import CustomTable from 'components/UI/CustomTable';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import TemplateEditor from 'components/TemplateEditor';
import untagify from 'constants/untagify';

let initialState = {
  value: '',
  error: null,
  errorMessage: 'Fill this label',
};

const initialPageData = {
  action_required: '',
  delay: null,
  document_url: null,
  enabled: null,
  id: null,
  message: '',
  role_name: '',
  subject: '',
  template_type: '',
  trigger_id: null,
};

// TODO: pageData is passed as an argument here, no need to request /superadmin/trigger-config again
export default function MissionControlMessageTemplate(props) {
  const [message, setMessage] = useState(initialState);
  const [pageData, setPageData] = useState(initialPageData);
  const [configData, setConfigData] = useState({});
  const [disableRerender, setDisableRerender] = useState(false);
  const { configId, triggerId } = useParams();
  const history = useHistory();
  const [mergeValue, setMergeValue] = useMergeFields('');

  useEffect(() => {
    setMergeValue(message?.value);
    // eslint-disable-next-line
  }, [message?.value]);

  useEffect(() => {
    API()
      .get('/superadmin/trigger-config', {
        params: { config_id: configId },
      })
      .then((res) => {
        const { message } = res.data;
        setPageData(res.data);
        initialState = {
          ...initialState,
          value: message,
        };
        setMessage(initialState);
      });
  }, [configId]);

  useEffect(() => {
    API()
      .get('/superadmin/triggers', { params: { trigger_id: triggerId } })
      .then((res) => {
        setConfigData(res.data.trigger);
      });
  }, [triggerId]);

  const saveConfig = (event) => {
    event.preventDefault();
    if (!message?.value?.length) {
      setMessage((prevState) => ({
        ...prevState,
        error: true,
      }));
      return;
    }

    API()
      .post('/superadmin/MCM-config', {
        id: +configId,
        message: untagify(message.value),
      })
      .then(() => {
        toast.success(`${props.regular ? 'Regular ' : ''}Mission Control Message saved`);
      });
  };

  return (
    <div className="lar_created_wrapper mcmt_wrapper">
      <div className="wrapper_lar_create_team" style={{ marginBottom: 24 }}>
        <SectionTitle title={`${props.regular ? 'Regular' : ''} Mission Control Message`} />
      </div>
      <div className="trigger-info">
        <div>
          <span>ID:</span>
          <b>{pageData.id}</b>
        </div>
        <div>
          <span>Nickname:</span>
          <b>{configData.name}</b>
        </div>
        <div>
          <span>Recipient User:</span>
          <b>{pageData.role_name}</b>
        </div>
        <div>
          <span>Message Type:</span>
          <b>{pageData.template_type}</b>
        </div>
        <div>
          <span>Action:</span>
          <b>{pageData.action_required?.name}</b>
        </div>
        <div>
          <span>Updated at:</span>
          <b>{pageData.updated_at}</b>
        </div>
      </div>
      <CustomTable onSubmit={saveConfig}>
        <TemplateEditor
          wrapperClassName="wrapper_template wrapper_template_no-toolbar"
          title="Message"
          text={message.value}
          mention={{
            separator: ' ',
            trigger: '$',
            suggestions: getLARFields(configData.context, configData.merge_fields).map((i) => ({
              text: `{${i.field}}`,
              value: `{${i.field}}`,
            })),
          }}
          disableRerender={disableRerender}
          setDisableRerender={setDisableRerender}
          setText={(e) => {
            setMessage((state) => ({
              ...state,
              value: e,
            }));
          }}
        />
        <div className="notification_message_preview">
          <span className="title">Notification message preview</span>
          <div className="preview">
            {/* eslint-disable-next-line */}
            <span dangerouslySetInnerHTML={{ __html: mergeValue }} />
          </div>
        </div>
        <div className="wrapper_form_btn three_btn">
          <Button
            text="Cancel"
            style={{ marginRight: 'auto' }}
            onClick={() => history.push(`/onboarding/ems/${triggerId}`)}
          />
          <Button text="Reset to default" onClick={() => setMessage(initialState)} />
          <Button className="btn_darkblue" type="submit" text="Save" />
        </div>
      </CustomTable>
    </div>
  );
}
