import React from 'react';
import BrokerProfile from '../../pages/Registration/Broker/BrokerProfile';
import LenderUser from '../../pages/Registration/Lender/LenderUser';
import Confirm from '../../pages/Registration/Confirm';
import LenderReview from '../../pages/Registration/Lender/Review';

export const mainNavigationRegisterCompany = [
  {
    exact: true,
    path: '/company/broker/user',
    main: () => <BrokerProfile isTeam="company" />,
  },
  {
    exact: true,
    path: '/company/lender/user',
    main: () => <LenderUser isTeam="company" />,
  },
  {
    exact: true,
    path: '/company/invite/confirm',
    main: () => <Confirm type="company" />,
  },
  {
    exact: true,
    path: '/registration/lender/review',
    main: () => <LenderReview />,
  },
];
