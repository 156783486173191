import styles from './Popup.module.css';

function PopupInfo({ title, text }) {
  return (
    <div>
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.text}>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default PopupInfo;
