import { MenuOutlined } from '@ant-design/icons';
import { ShareIcon } from 'assets/icons';
import { useState } from 'react';
import CustomButton from 'components/UI/Button';
import classes from './styles/BrokerLandingHeader.module.css';

export default function BrokerLandingHeader({
  brandColour,
  desktop,
  landingData,
  login,
  previewMode,
  showApply,
  showLogIn,
  showShare,
}) {
  const [activeSidebar, setActiveSidebar] = useState(false);

  if (desktop) {
    return (
      <div className={`head ${classes.header}`}>
        {landingData.company_logo ? <img src={landingData.company_logo} alt="" /> : <div />}
        <div>
          <CustomButton
            className="btn_blue"
            disabled={previewMode}
            onClick={showApply}
            style={{ background: brandColour, borderColor: brandColour }}
            text="Apply"
          />
          {login && (
            <CustomButton
              className="btn_blue"
              disabled={previewMode}
              onClick={showLogIn}
              style={{ background: landingData.color_code, borderColor: brandColour }}
              text="Sign In"
            />
          )}
          <CustomButton disabled={previewMode} onClick={showShare} svg={<ShareIcon colour="black" />} text="Share" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={`head head_mobile ${classes.mobileHeader}`}>
        {landingData.company_logo ? <img src={landingData.company_logo} alt="" /> : <div />}
        <MenuOutlined onClick={() => setActiveSidebar(!activeSidebar)} />
      </div>
      <div className={classes.sidebar + (activeSidebar ? ` ${classes.activeSidebar}` : '')}>
        <h3>{landingData.broker_full_name}</h3>
        <span>{landingData.company_short_name}</span>
        <p>{landingData.address}</p>
        <p>
          {`${landingData.is_mobile ? 'Mobile' : 'Office'} phone`}:{landingData.direct_phone}
        </p>
        {landingData.email && (
          <div>
            <a style={{ color: landingData.color_code }} href={`mailto:${landingData.email}`}>
              {landingData.email}
            </a>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 6L15 12L9 18"
                stroke={landingData.color_code}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </div>
        )}
        <div className="buttons">
          <CustomButton
            className="btn_blue"
            disabled={previewMode}
            onClick={showApply}
            style={{ background: brandColour, borderColor: brandColour }}
            text="Apply Now"
          />
          <div className="inline">
            {login && (
              <CustomButton className="btn_default" disabled={previewMode} onClick={showLogIn} text="Sign In" />
            )}
            <CustomButton
              className="btn_default"
              disabled={previewMode}
              onClick={showShare}
              svg={<ShareIcon colour="black" />}
              text="Share"
            />
          </div>
        </div>
      </div>
    </>
  );
}
