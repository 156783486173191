import { BannersAPI } from 'api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationBanner from './NotificationBanner';
import styles from './NotificationBanners.module.css';

function NotificationBanners() {
  const [items, setItems] = useState([]);
  const history = useHistory();
  const token = useSelector((s) => s.user.userData?.token);

  const API = useMemo(() => new BannersAPI(), []);
  const refreshBanners = useCallback(() => API.getBanners().then(setItems), [API]);

  useEffect(() => {
    if (!token) {
      return;
    }

    const checkNewNotification = setInterval(() => refreshBanners(), 5000);

    return () => {
      clearInterval(checkNewNotification);
    };
  }, [token, refreshBanners]);

  function closeBanner(id) {
    API.closeBanner(id).then(() => {
      setItems((prev) => prev.filter((item) => id !== item.id));
    });
  }

  return (
    <div className={styles.root}>
      {items.map((item) => (
        <NotificationBanner
          history={history}
          key={item.id}
          meta={item}
          onCloseButtonClick={() => closeBanner(item.id)}
        />
      ))}
    </div>
  );
}

export default NotificationBanners;
