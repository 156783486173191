import { Badge, Button, Table, Typography } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { ChevronIcon } from 'assets/icons';
import { ReactComponent as ClockIcon } from 'assets/icons/clock-thin.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import BrokerWidgetTableCollapse from './BrokerWidgetTableCollapse';

import styles from './BrokerWidgets.module.css';

const getColumns = (isUpcoming) => [
  ...(isUpcoming
    ? [
        {
          title: 'Schedule',
          dataIndex: 'auction_date',
          key: 'auction_date',
        },
      ]
    : []),
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Borrower',
    dataIndex: 'borrower',
    key: 'borrower',
  },
  {
    title: 'Style',
    dataIndex: 'requesting_amount',
    key: 'requesting_amount',
    render: (v) => (v ? `$${v?.toLocaleString()}` : ''),
  },
  {
    title: 'Purpose',
    dataIndex: 'purpose',
    key: 'purpose',
  },
];

export default function BrokerWidgets() {
  const { metrics } = useSelector((store) => store.dashboard);
  const auctions = metrics?.auctions || [];
  const todayAuctions = auctions.filter((auction) => {
    const diff = moment(auction.auction_date, 'ddd MMM D').startOf('day').diff(moment().startOf('day'), 'days');
    return diff === 0;
  });
  const upcomingAuctions = auctions
    .filter((auction) => {
      const diff = moment(auction.auction_date, 'ddd MMM D').startOf('day').diff(moment().startOf('day'), 'days');
      return diff >= 1;
    })
    .reverse();

  return (
    <div className={styles.root}>
      <div className={styles.widget}>
        <div className={styles.widgetHeader}>
          <Typography.Title className={styles.widgetTitle} level={5}>
            Today's Auctions{' '}
            <Badge className={styles.badge} count={todayAuctions?.length} color="var(--colour-brand-5)" />
          </Typography.Title>
          <Button
            type="link"
            icon={<ChevronIcon size="1.4em" colour="currentColor" direction="right" />}
            href="/onboarding/live_auctions"
          />
        </div>
        {todayAuctions.length > 0 ? (
          <BrokerWidgetTableCollapse total={todayAuctions.length}>
            <Table pagination={false} className={styles.table} dataSource={todayAuctions} columns={getColumns()} />
          </BrokerWidgetTableCollapse>
        ) : (
          <div className={styles.empty}>
            <div className={styles.emptyIcon}>
              <ClockIcon width={48} height={48} className={styles.icon} />
            </div>
            <Typography.Title level={5}>You dont have any auctions scheduled for today</Typography.Title>
          </div>
        )}
      </div>
      <div className={styles.widget}>
        <div className={styles.widgetHeader}>
          <Typography.Title className={styles.widgetTitle} level={5}>
            Upcoming Auctions{' '}
            <Badge className={styles.badge} count={upcomingAuctions?.length} color="var(--colour-brand-5)" />
          </Typography.Title>
          <Button
            type="link"
            icon={<ChevronIcon size="1.4em" colour="currentColor" direction="right" />}
            href="/onboarding/live_auctions?tab=upcoming"
          />
        </div>
        {upcomingAuctions?.length > 0 ? (
          <BrokerWidgetTableCollapse total={upcomingAuctions.length}>
            <Table
              pagination={false}
              className={styles.table}
              dataSource={upcomingAuctions}
              columns={getColumns(true)}
            />
          </BrokerWidgetTableCollapse>
        ) : (
          <div className={styles.empty}>
            <div className={styles.emptyIcon}>
              <PlusIcon width={48} height={48} className={styles.icon} />
            </div>
            <Typography.Title level={5}>You don’t have any upcoming auctions</Typography.Title>
          </div>
        )}
      </div>
    </div>
  );
}
