import { message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from 'api';
import bellIcon from 'assets/images/bell-icon.svg';
import CustomList from '../../CustomComponents/CustomList';
import getNotificationList from './utils/getNotificationList';
import MCMNotificationDeletionModal from './MCMNotificationDeletionModal';

const initialModalState = { show: '', data: null };

export default function ImportantNotifications({
  bordered = false,
  header,
  larId,
  pageSize,
  showLoadMore,
  showViewMore,
  style = {},
}) {
  const [activeModal, setActiveModal] = useState(initialModalState);
  const [notificationsData, setNotificationsData] = useState({ loading: false });
  const [pagination, setPagination] = useState({ pageSize: pageSize || 10 });
  const history = useHistory();

  const getNotifications = useCallback(() => {
    const params = {
      important_page: 1,
      important_per_page: pagination.pageSize,
      lar_id: larId,
    };

    API()
      .get('/profile/mcm-notifications', { params })
      .then(({ data }) => {
        setNotificationsData({
          loading: true,
          notifications: getNotificationList(data.important.notifications, setActiveModal, bellIcon, '#1F3047', [
            (el) => {
              if (!el?.trigger_action_link) {
                return null;
              }

              return (
                <button className="custom-list__action-btn" onClick={() => history.push(el?.trigger_action_link)}>
                  <span className="custom-list__action-btn-text">{el.trigger_action}</span>
                </button>
              );
            },
          ]),
        });
      });
  }, [pagination, larId, history]);

  const handleLoadMore = useCallback(() => {
    setPagination((prev) => ({
      ...prev,
      pageSize: prev.pageSize + 10,
    }));
    setNotificationsData((prev) => ({
      ...prev,
      loading: false,
    }));

    getNotifications();
  }, [getNotifications]);

  const deleteNotification = useCallback(() => {
    API()
      .delete('/profile/mcm-notifications', { params: { id: activeModal.data.id } })
      .then(() => {
        setNotificationsData((prev) => ({
          ...prev,
          notifications: prev.notifications.filter((i) => i.id !== activeModal.data.id),
        }));
        setActiveModal(initialModalState);

        message.success('Notification successfully deleted');
      });
  }, [activeModal?.data?.id]);

  useEffect(() => {
    getNotifications();
    return () => setNotificationsData({ loading: false });
  }, [getNotifications]);

  return (
    <>
      <CustomList
        bordered={bordered}
        data={notificationsData.notifications}
        header={header}
        loading={!notificationsData.loading}
        loadMoreBtn={
          <>
            {showViewMore && notificationsData?.notifications?.length > 3 && (
              <button className="custom-list__view-more-btn" onClick={() => history.push('/onboarding/notifications')}>
                View More
              </button>
            )}
            {showLoadMore && (
              <button className="custom-list__view-more-btn" onClick={handleLoadMore}>
                Load More
              </button>
            )}
          </>
        }
        style={style}
      />
      <MCMNotificationDeletionModal
        onCancel={() => setActiveModal(initialModalState)}
        onOk={deleteNotification}
        visible={activeModal.show === 'delete_notification'}
      />
    </>
  );
}
