import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo(({ crossed = false, ...params }) => (
  <GenericIcon {...params}>
    {crossed ? (
      <>
        <path d="M3 3L21 21" />
        <path d="M17 7.5C17 7.10218 17.158 6.72064 17.4393 6.43934C17.7206 6.15804 18.1022 6 18.5 6C18.8978 6 19.2793 6.15804 19.5606 6.43934C19.8419 6.72064 20 7.10218 20 7.5V16C20 17.5913 19.3678 19.1174 18.2426 20.2426C17.1174 21.3679 15.5913 22 14 22H12C9.88598 21.708 8.04398 20.603 6.99998 19L4.29998 13.75C4.03477 13.3853 3.92528 12.9302 3.9956 12.4848C4.06593 12.0394 4.31031 11.6402 4.67498 11.375C5.03966 11.1098 5.49475 11.0003 5.94015 11.0706C6.38555 11.1409 6.78477 11.3853 7.04998 11.75L7.94998 13.5M7.99998 13.5V8V13.5ZM8.43998 4.438C8.64988 4.22837 8.91722 4.08568 9.2082 4.02797C9.49918 3.97027 9.80075 4.00014 10.0748 4.11381C10.3488 4.22748 10.5829 4.41985 10.7476 4.66658C10.9123 4.91332 11.0001 5.20335 11 5.5V7L8.43998 4.438ZM11 11.008V12V11.008ZM11 5.5V3.5C11 3.30302 11.0388 3.10796 11.1142 2.92597C11.1895 2.74399 11.3 2.57863 11.4393 2.43934C11.5786 2.30005 11.744 2.18956 11.926 2.11418C12.1079 2.0388 12.303 2 12.5 2C12.697 2 12.892 2.0388 13.074 2.11418C13.256 2.18956 13.4214 2.30005 13.5606 2.43934C13.6999 2.57863 13.8104 2.74399 13.8858 2.92597C13.9612 3.10796 14 3.30302 14 3.5V10L11 5.5ZM14 5.5C14 5.10218 14.158 4.72064 14.4393 4.43934C14.7206 4.15804 15.1022 4 15.5 4C15.8978 4 16.2793 4.15804 16.5606 4.43934C16.8419 4.72064 17 5.10218 17 5.5V12L14 5.5Z" />
      </>
    ) : (
      <path d="M8.00004 13.5V5.5C8.00004 5.10218 8.15808 4.72064 8.43938 4.43934C8.72069 4.15804 9.10222 4 9.50004 4C9.89787 4 10.2794 4.15804 10.5607 4.43934C10.842 4.72064 11 5.10218 11 5.5M11 5.5V12M11 5.5V3.5C11 3.10218 11.1581 2.72064 11.4394 2.43934C11.7207 2.15804 12.1022 2 12.5 2C12.8979 2 13.2794 2.15804 13.5607 2.43934C13.842 2.72064 14 3.10218 14 3.5V12V5.5C14 5.10218 14.1581 4.72064 14.4394 4.43934C14.7207 4.15804 15.1022 4 15.5 4C15.8979 4 16.2794 4.15804 16.5607 4.43934C16.842 4.72064 17 5.10218 17 5.5V12M17 7.5C17 7.10218 17.1581 6.72064 17.4394 6.43934C17.7207 6.15804 18.1022 6 18.5 6C18.8979 6 19.2794 6.15804 19.5607 6.43934C19.842 6.72064 20 7.10218 20 7.5V16C20 17.5913 19.3679 19.1174 18.2427 20.2426C17.1175 21.3679 15.5913 22 14 22H12C10.9565 21.856 9.96446 21.5113 9.09851 20.9917C8.23255 20.4721 7.51512 19.7912 7.00004 19L4.30004 13.75C4.03483 13.3853 3.92534 12.9302 3.99567 12.4848C4.06599 12.0394 4.31037 11.6402 4.67504 11.375C5.03972 11.1098 5.49481 11.0003 5.94021 11.0706C6.38561 11.1409 6.78483 11.3853 7.05004 11.75L7.95004 13.5" />
    )}
  </GenericIcon>
));
