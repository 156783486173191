import { combineReducers } from 'redux';
import auctionsReducer from '../reducers/auctions';
import chatReducer from '../reducers/chat';
import dashboardReducer from '../reducers/dashboard';
import scenariosReducer from '../reducers/scenarios';
import userReducer from '../reducers/user';
import popupsReducer from '../reducers/popups';
import feesWorksheetReducer from '../reducers/feesWorksheet';
import usersReducer from 'store/reducers/users';
import larReducer from 'store/reducers/lar';

export default combineReducers({
  auctions: auctionsReducer,
  chat: chatReducer,
  dashboard: dashboardReducer,
  scenarios: scenariosReducer,
  user: userReducer,
  popups: popupsReducer,
  feesWorksheet: feesWorksheetReducer,
  users: usersReducer,
  lar: larReducer,
});
