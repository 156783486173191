import { useState } from 'react';
import { message, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import API from 'api';
import Keys from 'constants/helper';
import regExp from 'constants/regExp';
import TextInput from 'components/CustomComponents/TextInput';
import './reset_password.css';

function ResetPassword() {
  const [email, setEmail] = useState({ value: '', error: null });
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onChange = (event) => {
    const { value } = event.target;
    setEmail({
      value,
      error: null,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (!email.value.length) {
      setEmail((prevState) => ({
        ...prevState,
        error: true,
        errorMessage: 'Fill this label',
      }));
      return null;
    }
    if (!regExp.email.test(email.value)) {
      setEmail((prevState) => ({
        ...prevState,
        error: true,
        errorMessage: 'Incorrect email format',
      }));
      return null;
    }

    setLoading(true);
    API()
      .post('/sign_in/reset_password/code', { email: email.value })
      .then(() => {
        message.info('Please check your email');

        sessionStorage.setItem(Keys.VERIFICATION_CODE_EMAIL, email.value);
        history.push('/verification_code');
      })
      .catch((e) => {
        if (e.response?.data?.error) {
          setEmail((prevState) => ({
            ...prevState,
            error: true,
            errorMessage: e.response.data.error,
          }));
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="wrapper_auth wrapper_reset_password">
      <h3 className="auth_title">Reset password</h3>
      <p className="auth_describe">Please enter email address you used on registration</p>
      <form className="wrapper_auth__inputs" onSubmit={onSubmit}>
        <TextInput
          value={email.value}
          title="Email"
          htmlFor="email"
          error={email.error}
          errorMessage={email.errorMessage}
          onChange={onChange}
        />
        <Button size="large" type="dark" htmlType="submit" loading={loading}>
          Send verification code
        </Button>
      </form>
    </div>
  );
}

export default ResetPassword;
