import { useContext, useEffect } from 'react';
import { Checkbox, DatePicker, Divider, Form, InputNumber, Radio, Select, Slider, Space, Typography } from 'antd';
import moment from 'moment';
import numberToComma from 'constants/numberToComma';
import LarAppContext from '../../../context/LarAppContext';
import styles from './ProjectFormRefi.module.css';

function getMarks(num) {
  if (!num) {
    return {
      50: '50',
      60: '60',
      70: '70',
      80: '80',
    };
  }

  const data = {};
  for (let i = num; i >= 50; i -= 10) {
    data[i] = `${i}`;
  }
  return data;
}

function ProjectFormRefi({ form, cashOut, maximumPossible, setCashOut, setMaximumPossible, onFieldsChange }) {
  const {
    closing_date,
    current_payment,
    custom_amount_maximum_possible,
    interest_rate,
    loan_style_name,
    LTV,
    mpi_hoa,
    mpi_other,
    mpi_property_insurance,
    mpi_real_estate_taxes,
    property_value,
    refi_cash_out,
    refi_loan_type_name,
    remaining_loan_balance,
    SA_values,
    use,
  } = useContext(LarAppContext);

  useEffect(() => {
    form.setFieldsValue({
      loan_style_name,
      closing_date: closing_date ? moment(closing_date) : moment(new Date(), 'YYYY-DD-MM').add(12, 'day'),
      property_value,
      remaining_loan_balance,
      interest_rate,
      refi_cash_out,
      current_payment,
      mpi_hoa,
      mpi_other,
      mpi_property_insurance,
      mpi_real_estate_taxes,
      custom_amount_maximum_possible,
      LTV: LTV || 50,
    });
    setCashOut(!!refi_cash_out);
    // eslint-disable-next-line
  }, []);

  const setNullCashoutField = async () => {
    await form.setFields([
      {
        name: 'refi_cash_out',
        value: null,
        errors: [],
      },
    ]);
  };

  const setNullMaxPossibleField = async () => {
    await form.setFields([
      {
        name: 'custom_amount_maximum_possible',
        value: null,
        errors: [],
      },
    ]);
  };

  useEffect(() => {
    const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
      name: el[0],
      value: el[1],
    }));
    onFieldsChange([1], valuesArr);
    // eslint-disable-next-line
  }, [cashOut]);

  const purchasePrice = Form.useWatch('property_value', form);
  const ltv = Form.useWatch('LTV', form);
  const payOff = Form.useWatch('remaining_loan_balance', form);
  const calculatedLoanAmount = purchasePrice * (ltv / 100);
  const isBadLTA = calculatedLoanAmount < payOff;
  const targetLoanAmount = calculatedLoanAmount;
  const targetLoanAmountError =
    purchasePrice &&
    (calculatedLoanAmount < SA_values.minimum_loan_amount || calculatedLoanAmount > SA_values.maximum_loan_amount);

  return (
    <>
      {use === 'Consumer' ? null : (
        <Form.Item
          label="Investment style"
          name="loan_style_name"
          className="scenario-required-field auction-required-field"
        >
          <Select style={{ width: 215 }}>
            <Select.Option value="Long Term Rental">Long Term Rental</Select.Option>
            <Select.Option value="Short Term Rental (AirBnB)">Short Term Rental (AirBnB)</Select.Option>
          </Select>
        </Form.Item>
      )}
      <Form.Item
        label="Estimated closing date"
        name="closing_date"
        initialValue={moment(new Date(), 'YYYY-DD-MM').add(11, 'day')}
      >
        <DatePicker
          style={{ width: 215 }}
          disabledDate={(currentDate) => currentDate.isBefore(moment(new Date(), 'YYYY-DD-MM').add(11, 'day'))}
        />
      </Form.Item>
      <Form.Item
        label="Estimated property value"
        name="property_value"
        className="scenario-required-field auction-required-field"
      >
        <InputNumber
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          style={{ width: 215 }}
          addonBefore="$"
        />
      </Form.Item>
      <Form.Item label="Estimated loan payoff" name="remaining_loan_balance">
        <InputNumber
          addonBefore="$"
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          style={{ width: 215 }}
        />
      </Form.Item>
      <Form.Item label="Current loan interest rate" name="interest_rate" className="auction-required-field">
        <InputNumber min={0} max={100} style={{ width: 215 }} addonAfter="%" />
      </Form.Item>
      <Form.Item label="Current mortgage payment" name="current_payment" className="auction-required-field">
        <InputNumber
          addonBefore="$"
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          style={{ width: 215 }}
        />
      </Form.Item>
      <Divider />
      <div className={styles.mortgagePaymentIncludesWrapper}>
        <h4 className={styles.title}>Mortgage payment includes:</h4>
        <Form.Item label="Real estate taxes" name="mpi_real_estate_taxes" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item label="Property Insurance" name="mpi_property_insurance" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item label="HOA" name="mpi_hoa" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item label="Other" name="mpi_other" valuePropName="checked">
          <Checkbox />
        </Form.Item>
      </div>
      <Divider />
      <Form.Item label="Target LTV">
        <Form.Item
          name="LTV"
          style={{
            display: 'inline-block',
            marginBottom: 0,
          }}
        >
          <InputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            max={SA_values?.refinance_LTV ?? 80}
            style={{ width: 100 }}
            addonAfter="%"
          />
        </Form.Item>
        <Form.Item
          name="LTV"
          style={{
            display: 'inline-block',
            marginBottom: 0,
            marginLeft: 30,
          }}
        >
          <Slider
            marks={getMarks(SA_values?.refinance_LTV)}
            max={SA_values?.refinance_LTV ?? 80}
            min={50}
            step={1}
            style={{ width: 220 }}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item label="Target Loan Amount Request">
        {targetLoanAmount ? `$${numberToComma(targetLoanAmount)}` : null}
        {isBadLTA && (
          <div className="ant-form-item-explain-error">The loan request must be greater than the loan payoff</div>
        )}
        {targetLoanAmountError && (
          <div>
            <Typography.Text type="danger">
              Refinance loan amount must be less than {SA_values.maximum_loan_amount.toLocaleString()} and more than{' '}
              {SA_values.minimum_loan_amount.toLocaleString()}.
            </Typography.Text>
          </div>
        )}
      </Form.Item>
      {/cash out/i.test(refi_loan_type_name) && (
        <Form.Item label="Cash-out">
          <Radio.Group
            onChange={(e) => {
              setCashOut(e.target.value);
              if (!e.target.value) {
                setNullCashoutField();
              }
            }}
            value={custom_amount_maximum_possible || cashOut}
          >
            <Space direction="vertical" size="large">
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      )}
      {(cashOut || custom_amount_maximum_possible) && (
        <>
          <Form.Item label="Cash-out amount" name="custom_amount_maximum_possible">
            <Radio.Group
              onChange={(e) => {
                setMaximumPossible(e.target.value);
                if (!e.target.value) {
                  setNullMaxPossibleField();
                }
              }}
              value={maximumPossible}
            >
              <Space direction="vertical" size="large">
                <Radio value>Maximum Possible</Radio>
                <Radio value={false}>Desired cash-out</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {!maximumPossible && (
            <Form.Item label=" " className="auction-required-field scenario-required-field">
              <Form.Item
                name="refi_cash_out"
                style={{
                  display: 'inline-block',
                  marginBottom: 0,
                }}
              >
                <InputNumber
                  min={0}
                  max={targetLoanAmount}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: 215 }}
                  addonBefore="$"
                />
              </Form.Item>
              <div style={{ marginLeft: 14, marginTop: 4, display: 'inline-block' }}>
                Max = ${numberToComma(targetLoanAmount)}
              </div>
            </Form.Item>
          )}
        </>
      )}
    </>
  );
}

export default ProjectFormRefi;
