import { Tooltip } from 'antd';
import classNames from 'classnames';
import { ShareIcon } from 'assets/icons';
import { numberToCurrency } from 'constants/numberToComma';
import ChatIndicator from 'components/UI/ChatIndicator';
import styles from './AuctionsListItem.module.css';

export default function ActionListItem({ data = {}, isActive, isPreviewClosed, hasTooltip, viewOnly, onClick }) {
  const isClickable = data?.started_now || (!viewOnly && !isPreviewClosed);
  const handleClickItem = (id) => () => {
    if (data?.started_now || (!viewOnly && !isPreviewClosed)) {
      onClick(id);
    }
  };

  return (
    <Tooltip
      placement="right"
      title="This is upcoming auction. It will be available when the auction preview period starts."
      open={hasTooltip && !isClickable ? undefined : false}
    >
      <li
        key={data.id}
        className={classNames(styles.item, {
          [styles.activeItem]: isActive,
          [styles.viewOnly]: !isClickable,
          [styles[data.color]]: data.color,
        })}
        onClick={handleClickItem(data.id)}
      >
        <div className={styles.leftColumn}>
          <span>#{data.id}</span>
          <b>
            {data.loan_purpose} {numberToCurrency(data.property_value)}
          </b>
          <span>{data.property_address}</span>
        </div>
        <div className={styles.rightColumn}>
          {data.bid_viewed && <p className={styles.itemCaptionText}>Viewed</p>}
          {data.bid_exist && <p className={styles.itemCaptionText}>Quote Placed</p>}
          {data.bid_exist && (
            <p className={styles.itemCaptionText}>{data.bid_type === 'public' ? 'Visible Quote' : 'Hidden Quote'}</p>
          )}
          <div className={styles.icons}>
            <ChatIndicator type="secondary" isVisible={data.chat_exist} hasIndicator={data.new_messages} />
            {data.shared && (
              <Tooltip title="This deal is shared">
                <ShareIcon />
              </Tooltip>
            )}
          </div>
        </div>
      </li>
    </Tooltip>
  );
}
