import { message } from 'antd';
import API from '../API';

export default class DashboardAPI {
  async getItemsList(sortingColumn, sortingDirection, page, pageSize, filters, rest = {}) {
    const { type } = rest;
    let uri = '/LAR/dashboard/pipeline';
    let params = {
      order_by: sortingColumn,
      order_type: sortingDirection,
      page,
      per_page: pageSize,
      ...(type && { type }),
    };

    if (filters.status_name === 'Live Auction') {
      uri = '/LAR/dashboard/active_lars';
      params = {
        order_by: sortingColumn,
        ...(type && { type }),
      };
    } else if (filters.type) {
      uri = '/LAR/dashboard/pipline';
      params = {
        ...params,
        lar_type: filters.type,
      };
    } else if ('owner_id' in filters) {
      uri = '/user_settings/company/lars';
      params = {
        order_by: sortingColumn,
        order_type: sortingDirection,
        owner_id: filters.owner_id,
        page,
        per_page: pageSize,
        ...(type && { type }),
      };
    } else if ('larId' in filters) {
      params = {
        ...params,
        lar_id: filters.larId,
      };
    }

    return API()
      .get(uri, { params })
      .then((res) => res.data);
  }

  async getDashboardPipelines(role, params) {
    if (role) {
      return API()
        .get(`/LAR/dashboard/${role}/pipline`, { params })
        .then((res) => res.data);
    }
  }

  async getActiveLoans(params) {
    return API()
      .get('/LAR/dashboard/lender/active_loans', { params })
      .then((res) => res.data);
  }

  async getLenderDashboard(period) {
    return API().get('/LAR/dashboard/lender', {
      params: {
        period,
      },
    });
  }

  async postLenderFavoriteLat(id) {
    return API().post('/LAR/dashboard/favorite_lar', { lar_id: id });
  }

  async scenarioRespond({
    lar_id,
    loan_program_ready,
    loan_type_DSCR,
    loan_type_DTI,
    doc_type_full,
    doc_type_partial,
    doc_type_24_mo_statements,
    doc_type_12_mo_statements,
    doc_type_k_1_only,
    doc_type_1099_only,
    doc_type_VOE_only,
    following_concerns,
    additional_information,
    additional_comment,
  }) {
    return API()
      .post('/LAR/scenario/respond', {
        lar_id,
        loan_program_ready,
        loan_type_DSCR,
        loan_type_DTI,
        doc_type_full,
        doc_type_partial,
        doc_type_24_mo_statements,
        doc_type_12_mo_statements,
        doc_type_k_1_only,
        doc_type_1099_only,
        doc_type_VOE_only,
        following_concerns,
        additional_information,
        additional_comment,
      })
      .catch((err) => {
        message.error(err?.response?.data?.error || 'respond LAR error!');
        throw err;
      })
      .then((res) => res.data);
  }

  async getScenariosList(sortingColumn) {
    return API()
      .get('/LAR/scenario', { params: { order_by: sortingColumn } })
      .then(({ data }) => {
        if (!data?.scenarios) {
          return [];
        }

        // It's easier to get data to a usable state myself than on backend :'<
        return data.scenarios.map((s) => ({
          bid: s.bid,
          quoteProvidedDate: s.bid.quote_provided_date,
          id: s.scenario.lar_id,
          response: s.bid.respond,
          scenario: s.scenario,
          status: s.bid.bid_status?.name || null,
          updated_at: s.bid.updated_at,
        }));
      });
  }

  async getScenarios() {
    return API()
      .get('/LAR/scenario')
      .then((res) => res.data);
  }

  async getMetrics() {
    return API()
      .get('/LAR/dashboard')
      .then((res) => res.data);
  }

  async search(query) {
    return API()
      .get('/LAR/dashboard/pipline/search', {
        params: {
          search: query,
        },
      })
      .then((res) => res.data?.lars || []);
  }

  async getLenderFinishedAuctions() {
    return API()
      .get('/LAR/dashboard/lender/finished_auctions')
      .then((res) => res.data);
  }
}
