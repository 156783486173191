import * as roles from 'constants/roles';

/**
 * Creates an abbreviation from a given string by taking the first letter of each word
 * and concatenating them into a single string.
 * If the input is not a string, returns it as-is.
 *
 * @param {string} role - The input string to be abbreviated
 * @returns {string} - The abbreviation of the input string
 */
export function makeAbbreviation(role = '') {
  if (typeof role !== 'string') {
    return role;
  }

  let updatedRole = role;
  const parsedRole = role.split(' ');

  if (parsedRole.length > 1) {
    updatedRole = parsedRole
      .map((word) => word.charAt(0))
      .join('')
      .toUpperCase();
  }

  return updatedRole;
}

/**
 * Retrieves the reassignable allowed roles based on the current user's role.
 * @param {string} currentUserRole - The role of the current user.
 * @returns {Array} An array of allowed roles that can be reassigned.
 */
export function getReassignableAllowedRoles(currentUserRole) {
  switch (currentUserRole) {
    case roles.BROKER_ADMIN:
    case roles.BROKER_LOAN_MANAGER:
    case roles.BROKER_TRANSACTION_MANAGER:
      return roles.BROKER_LOAN_MANAGER_ALLOWED_ROLES;
    case roles.LENDER_ADMIN:
    case roles.LENDER_ACCOUNT_EXECUTIVE:
    case roles.LENDER_TRANSACTION_MANAGER:
      return roles.LENDER_ACCOUNT_EXECUTIVE_ALLOWED_ROLES;
    default:
      return [];
  }
}
