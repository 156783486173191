import { isEmpty } from 'lodash';
import { PRE_AUCTION, SCHEDULED } from 'constants/larApp';
import { useLARDBContext } from 'pages/Onboarding/LARDB/hooks/LARDBContext';
import AuctionStartWidget from 'components/LARDB/Auction/AuctionStartWidget';
import AuctionWaitingWidget from 'components/LARDB/Auction/AuctionWaitingWidget';
import UserBidTable from 'components/LARDB/Auction/UserBidTable';
import styles from './Table.module.css';

function Table({
  timeline,
  quotes,
  selectedQuotes,
  resendQuotes,
  goToLoanProfile,
  handleChooseQuote,
  openSelectWinQuoteModal,
  setQuotes,
  setSwiper,
  swiper,
  winnerSelected,
}) {
  const isQuotesSent = timeline?.data?.quotes_sent;
  const { lar_status } = useLARDBContext();

  return (
    <div className={styles.info}>
      <div>
        {lar_status === PRE_AUCTION || lar_status === SCHEDULED ? (
          <AuctionStartWidget goToLoanProfile={goToLoanProfile} />
        ) : isEmpty(quotes) ? (
          <AuctionWaitingWidget />
        ) : (
          <UserBidTable
            quotes={quotes}
            handleChooseQuote={handleChooseQuote}
            isMaxQuotes={selectedQuotes.length >= 3}
            isQuotesSent={isQuotesSent}
            openSelectWinQuoteModal={openSelectWinQuoteModal}
            selectedQuotes={selectedQuotes}
            resendQuotes={resendQuotes}
            setQuotes={setQuotes}
            winnerSelected={winnerSelected}
            setSwiper={setSwiper}
            swiper={swiper}
          />
        )}
      </div>
    </div>
  );
}

export default Table;
