import React from 'react';
import OnboardingRouter from '../OnboardingRouter';

export const mainNavigation = [
  {
    exact: true,
    path: '/onboarding/:slug/:slug2?/:slug3?/:slug4?/:slug5?',
    main: () => <OnboardingRouter />,
  },
];
