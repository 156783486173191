import { Card, Col, Radio, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { DashboardAPI } from 'api';
import { getPercentage } from 'utils/formula';

import { ReactComponent as ClockIcon } from '../../../../assets/icons/clock.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check-new.svg';
import { ReactComponent as ChecksIcon } from '../../../../assets/icons/checks.svg';

import styles from './LenderDashboardWidgets.module.css';

function LenderDashboardWidgets() {
  const API = useMemo(() => new DashboardAPI(), []);

  const [period, setPeriod] = useState('30 days');
  const [statistics, setStatistics] = useState({});
  const totalSum = statistics.missed?.sum + statistics.quoted?.sum + statistics.viewed?.sum;
  const totalDeals = statistics.missed?.count + statistics.quoted?.count + statistics.viewed?.count;
  const percents = {
    missed: getPercentage(statistics.missed?.sum, totalSum),
    quoted: getPercentage(statistics.quoted?.sum, totalSum),
    viewed: getPercentage(statistics.viewed?.sum, totalSum),
  };

  useEffect(() => {
    API.getLenderDashboard(period).then((res) => {
      setStatistics(res.data?.records);
    });
  }, [period]);

  const handleChangePeriod = (e) => {
    setPeriod(e.target.value);
  };

  const isChartEmpty = !percents.missed && !percents.missed && !percents.missed;

  const data = {
    datasets: [
      {
        label: '# of Votes',
        data: isChartEmpty ? [1] : [percents.missed, percents.quoted, percents.viewed],
        backgroundColor: isChartEmpty ? ['#e0e0e0'] : ['#FF4D4F', '#FFC53D', '#73D13D'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label(tooltipItem) {
            const label = tooltipItem.label || '';
            const value = tooltipItem.raw || 0;
            return `${label}: ${value} %`;
          },
        },
      },
    },
  };

  return (
    <div className={styles.root}>
      <Row justify="space-between">
        <Col>
          <h1 className={styles.title}>Your Performance</h1>
        </Col>
        <Col>
          <Radio.Group value={period} className={styles.periodFilter} onChange={handleChangePeriod}>
            {/* <Radio.Button value="30 days">Last 30 Days</Radio.Button> */}
            {/* <Radio.Button value="full">View Full Report</Radio.Button> */}
          </Radio.Group>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={12} xl={6}>
          <Card className={styles.card}>
            <div className={`${styles.cardInner} ${styles.cardInnerWithChart}`}>
              <div className={styles.cardIcon}>
                <Doughnut data={data} options={options} />
              </div>
              <Row gutter={16}>
                <Col span={24}>
                  <div className={styles.cardText}>Total</div>
                </Col>
                <Col span={24}>
                  <div className={styles.cardPrice}>${totalSum?.toLocaleString()}</div>
                </Col>
                <Col span={24}>
                  <div className={styles.cardText}>{totalDeals} deals</div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col xs={12} xl={6}>
          <Card className={styles.card}>
            <div className={styles.cardInner}>
              <div className={`${styles.cardIcon} ${styles.cardIconDanger}`}>
                <ClockIcon fill="currentColor" />
              </div>
              <Row gutter={16}>
                <Col span={24}>
                  <div className={styles.cardText}>No Action</div>
                </Col>
                <Col span={24}>
                  <div className={styles.cardPrice}>${statistics.missed?.sum.toLocaleString()}</div>
                </Col>
                <Col span={24}>
                  <div className={styles.cardText}>{percents.missed}%</div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col xs={12} xl={6}>
          <Card className={styles.card}>
            <div className={styles.cardInner}>
              <div className={`${styles.cardIcon} ${styles.cardIconWarning}`}>
                <CheckIcon />
              </div>
              <Row gutter={16}>
                <Col span={24}>
                  <div className={styles.cardText}>Viewed</div>
                </Col>
                <Col span={24}>
                  <div className={styles.cardPrice}>${statistics.viewed?.sum.toLocaleString()}</div>
                </Col>
                <Col span={24}>
                  <div className={styles.cardText}>{percents.viewed}%</div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col xs={12} xl={6}>
          <Card className={styles.card}>
            <div className={styles.cardInner}>
              <div className={`${styles.cardIcon} ${styles.cardIconSuccess}`}>
                <ChecksIcon />
              </div>
              <Row gutter={16}>
                <Col span={24}>
                  <div className={styles.cardText}>Quoted</div>
                </Col>
                <Col span={24}>
                  <div className={styles.cardPrice}>${statistics.quoted?.sum.toLocaleString()}</div>
                </Col>
                <Col span={24}>
                  <div className={styles.cardText}>{percents.quoted}%</div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default LenderDashboardWidgets;
