import { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import tickmark from '../../../assets/images/circle-check.svg';
import './registration-sub-header.css';

function RegistrationSubHeader({ progress = [], actions = [], maxWidth = '714px' }) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  const content = (
    <>
      <div className="registration-progress">
        {progress.map((i, index) => (
          <div
            key={index}
            onClick={i.onClick && i.clickCondition ? i.onClick : undefined}
            style={i.onClick && i.clickCondition ? { cursor: 'pointer' } : {}}
            className={`progress-item ${i.active ? 'progress-item_active' : ''}`}
          >
            <span>{i.title}</span>
            <img src={tickmark} style={{ opacity: i.completed ? '1' : '0' }} alt="" />
          </div>
        ))}
      </div>
      <div className="registration-actions">{actions}</div>
    </>
  );

  return (
    <div className="registration-sub-header">
      <div style={{ maxWidth }} className="sub-header-content">
        {width < 750 ? (
          <Collapse defaultActiveKey={['1']} expandIconPosition="end" style={{ width: '100%' }}>
            <Collapse.Panel header="Navigation" key="1">
              {content}
            </Collapse.Panel>
          </Collapse>
        ) : (
          content
        )}
      </div>
    </div>
  );
}
export default RegistrationSubHeader;
