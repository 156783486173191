const formSettings = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    offset: 1,
    span: 16,
  },
  colon: false,
  labelAlign: 'left',
  layout: 'horizontal',
};

export default formSettings;
