import './styles/categories.css';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-drag';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import Button from 'components/UI/Button';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import TextInput from 'components/CustomComponents/TextInput';

export default function Categories() {
  const [data, setData] = useState({ loading: false });
  const [unsaved, setUnsaved] = useState(false);

  useEffect(() => {
    API()
      .get('/superadmin/settings/categories')
      .then((res) => {
        setData({
          loading: true,
          categories: res.data.categories,
        });
      });
  }, []);

  const onChange = (e, item) => {
    setUnsaved(true);

    const protoData = data.categories.map((i) => (i.id === item.id ? { ...i, name: e.target.value } : i));
    setData((prevState) => ({ ...prevState, categories: protoData }));
  };

  function onSubmit(e) {
    e.preventDefault();

    API()
      .post('/superadmin/settings/categories', { data: data.categories })
      .then(() => {
        setUnsaved(false);
        message.success('You successfully updated document categories');
      })
      .catch(console.error);
  }

  function addCategory() {
    setUnsaved(true);
    setData((cur) => {
      const lastId = cur.categories.reduce((a, c) => (c.id > a ? c.id : a), cur.categories[0].id);

      return {
        ...cur,
        categories: [...cur.categories, { id: lastId + 1, name: '' }],
      };
    });
  }

  function deleteCategory(id) {
    setUnsaved(true);
    setData((cur) => {
      const newCategories = cur.categories.filter((c) => c.id !== id);
      return { ...cur, categories: newCategories };
    });
  }

  function onSort(sourceId, sourceIndex, targetIndex) {
    setUnsaved(true);
    setData((cur) => ({ ...cur, categories: swap(cur.categories, sourceIndex, targetIndex) }));
  }

  return (
    <form className="wrapper_categories" onSubmit={onSubmit}>
      <div className="title">
        <SectionTitle title="Document Categories" />
        <div className="button_wrapper">
          <Button text="Add category" className="btn_darkblue" onClick={addCategory} />
          {unsaved && <Button text="Save" type="submit" className="btn_darkblue" />}
        </div>
      </div>
      <div className="categories_list">
        {data.loading ? (
          <GridContextProvider onChange={onSort}>
            <GridDropZone boxesPerRow={1} rowHeight={50} style={{ height: `${data.categories.length * 50}px` }}>
              {data.categories.map((i, index) => (
                <GridItem key={index}>
                  <div className="every" key={index}>
                    <TextInput value={i.name} onChange={(e) => onChange(e, i)} />
                    <Button text="Delete" onClick={() => deleteCategory(i.id)} />
                  </div>
                </GridItem>
              ))}
            </GridDropZone>
          </GridContextProvider>
        ) : null}
      </div>
    </form>
  );
}
