import TextInput from 'components/CustomComponents/TextInput';
import regExp from 'constants/regExp';

function FeesInputLayout({ title, current, currentName, proposed, proposedName, setFields, setNestedFields }) {
  const onChange = (event, name) => {
    let { value } = event.target;
    value = value.split('%')[0];
    if (value === '.0' || value === '.') {
      value = '';
    }
    if (regExp.two_number_two_decimal.test(value) || value === '') {
      setFields((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value,
          error: false,
        },
      }));
    }
  };

  return (
    <div className="every">
      <span>{title}</span>
      <TextInput
        value={
          current.disabled
            ? (+current.value).toFixed(2)
            : current.value === '' || current.value
            ? current.value
            : current
        }
        error={current.error}
        maxLength={7}
        disabled={current.disabled}
        onChange={(e) => {
          if (setNestedFields) {
            setNestedFields(e, currentName);
          } else {
            onChange(e, currentName);
          }
        }}
        errorMessage={current.errorMessage}
        addonAfter="%"
      />
      <TextInput
        value={
          proposed.disabled
            ? (+proposed.value).toFixed(2)
            : proposed.value === '' || proposed.value || proposed.value === 0
            ? proposed.value
            : proposed
        }
        disabled={proposed.disabled}
        error={proposed.error}
        maxLength={7}
        onChange={(e) => {
          if (setNestedFields) {
            setNestedFields(e, proposedName);
          } else {
            onChange(e, proposedName);
          }
        }}
        errorMessage={proposed.errorMessage}
        addonAfter="%"
      />
    </div>
  );
}

export default FeesInputLayout;
