import { message, Space } from 'antd';
import { useRef } from 'react';
import { acceptedMimes } from 'constants/mimes';
import { CloseIcon, FileUploadIcon } from 'assets/icons/icons';
import CustomButton from 'components/UI/Button';

function DocumentAttachField({ activeDocument, onChange, removeDocument }) {
  const uploadRef = useRef();

  return (
    <div className="logo-wrapper">
      <label className="input_title">Attach a document</label>
      <div
        className={`logo-row ${activeDocument.document.error ? 'error' : ''}`}
        onClick={({ target }) => {
          if (target.tagName !== 'svg' && target.tagName !== 'path') {
            uploadRef.current.click();
          }
        }}
      >
        {activeDocument.document.value.length ? (
          <Space size={8} direction="vertical">
            {activeDocument.document.value.map((doc, idx) => (
              <div className="document" key={doc.lastModified}>
                <span>
                  Document
                  {idx + 1}:{doc.name}
                </span>
                <CloseIcon onClick={() => removeDocument(idx)} />
              </div>
            ))}
          </Space>
        ) : (
          <CustomButton
            style={{ padding: '4px 32px' }}
            text={
              <div className="upload-logo">
                <span>Drop here or click to upload</span>
                <FileUploadIcon style={{ color: '#1F1F1F' }} />
              </div>
            }
          />
        )}
        <input
          accept={acceptedMimes.diligenceDocs.join()}
          hidden
          multiple
          onChange={(e) => {
            const files = [...activeDocument.document.value, ...e.target.files] ?? [];

            if (files.length > 5) {
              message.error('You can only select up to 5 documents');
            } else {
              onChange('document', files);
            }
          }}
          ref={uploadRef}
          type="file"
        />
      </div>
      {activeDocument.document.error && <span className="errorMessage">Select at least 1 document</span>}
    </div>
  );
}

export default DocumentAttachField;
