const colors = new Map([
  ['black', '#000'],
  ['brand-5', '#40a9ff'],
  ['brand-6', '#2951e7'],
  ['gold-2', '#fff1b8'],
  ['gold-6', '#faad14'],
  ['gold-7', '#d48806'],
  ['gold-8', '#ad6800'],
  ['green-5', '#73d13d'],
  ['green-6', '#52c41a'],
  ['green-7', '#389e0d'],
  ['grey-6', '#bfbfbf'],
  ['grey-7', '#8c8c8c'],
  ['grey-8', '#595959'],
  ['movadex-grey', '#a9b1cc'],
  ['purple-5', '#877aeb'],
  ['red-5', '#ff4d4f'],
  ['white', '#fff'],
  ['inherit', 'inherit'],
  ['currentColor', 'currentColor'],
]);

export default colors;
