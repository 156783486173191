import './styles/lar_created_team.css';
import { EMSAPI } from 'api';
import { setBreadCrumbHistorySetValue } from 'store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BreadCrumbHistory from 'components/BreadCrumbHistory';
import BrowserNotificationTemplate from './BrowserNotificationTemplate';
import EmailMessageTemplate from './EmailMessageTemplate';
import LARBannerTemplate from './LARBannerTemplate';
import MissionControlMessageTemplate from './MissionControlMessageTemplate';
import SMSTextMessageTemplate from './SMSTextMessageTemplate';

const messageTypeMap = new Map([
  ['Browser notification', { Component: BrowserNotificationTemplate }],
  ['Email message', { Component: EmailMessageTemplate }],
  ['LAR Banner', { Component: LARBannerTemplate }],
  ['Marketing api', { Component: SMSTextMessageTemplate, params: { type: 'marketing' } }],
  ['Mission Control Message', { Component: MissionControlMessageTemplate }],
  ['Regular Mission Control Message', { Component: MissionControlMessageTemplate, params: { regular: true } }],
  ['SMS Text message', { Component: SMSTextMessageTemplate }],
]);

export default function EditEMSMessage() {
  const [pageData, setPageData] = useState({});
  const { configId } = useParams();
  const breadCrumbHistory = useSelector((store) => store.user.breadCrumbHistory);
  const dispatch = useDispatch();
  const history = useHistory();

  const API = useMemo(() => new EMSAPI(), []);

  useEffect(() => {
    API.getChannel(configId).then((data) => {
      setPageData(data);
    });
  }, [API, configId]);

  const exactMessageEdit = useMemo(
    () => messageTypeMap.get(pageData.template_type) || { Component: () => null },
    [pageData],
  );

  return (
    <div className="wrapper_lar_create_team">
      <div className="edit_EMS_wrapper__bcd">
        <BreadCrumbHistory
          goBack={(key) => dispatch(setBreadCrumbHistorySetValue(key))}
          history={breadCrumbHistory}
          historyWeb={history}
        />
      </div>
      <exactMessageEdit.Component {...exactMessageEdit.params} pageData={pageData} />
    </div>
  );
}
