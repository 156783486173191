import larVariables from './larVariables';

const hideForms = (args) => {
  const { isRefinance, isPurchaseLong, use, setProgress, setLAProgress, isIndividual } = args;
  if (!((isRefinance && use !== larVariables.use.consumer) || (isPurchaseLong && use !== larVariables.use.consumer))) {
    setProgress((prevState) => ({
      ...prevState,
      property_income_and_expense: 'hidden',
    }));
    setLAProgress((prevState) => ({
      ...prevState,
      property_income_and_expense: 'hidden',
    }));
  }
  if (!isIndividual) {
    setProgress((prevState) => ({
      ...prevState,
      co_borrowers: 'hidden',
    }));
    setLAProgress((prevState) => ({
      ...prevState,
      co_borrowers: 'hidden',
    }));
  }
};

export default hideForms;
