import { Checkbox, Col, Form, Input, Row, Space, Upload } from 'antd';
import CustomButton from '../../UI/Button';

function LenderDocsCreateForm() {
  const [form] = Form.useForm();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  return (
    <div
      style={{
        border: '1px solid #BFBFBF',
        borderRadius: '8px',
        padding: '24px',
      }}
    >
      <Col span={8}>
        <Form form={form} layout="vertical" autoComplete="off">
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
          </Form.Item>
          <Form.Item label="" name="requires" valuePropName="checked">
            <Checkbox.Group>
              <Space direction="vertical">
                <Checkbox>Requires borrower authorization</Checkbox>
                <Checkbox>Requires borrower signature</Checkbox>
              </Space>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item label="">
            <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
              <Upload.Dragger
                name="files"
                action="/upload.do"
                style={{
                  padding: '34px 0',
                  border: '1px dashed #D9D9D9',
                  background: 'transparent',
                }}
              >
                <Row align="middle" justify="center" className="ant-upload-drag-icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
                      stroke="#5744E3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
                      stroke="#5744E3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M12 11V17" stroke="#5744E3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M9 14L12 11L15 14"
                      stroke="#5744E3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span
                    style={{
                      marginLeft: 8,
                      color: '#5744E3',
                      fontSize: '16px',
                    }}
                  >
                    Upload file
                  </span>
                </Row>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Row align="middle" justify="space-between">
            <CustomButton key="back" onClick={() => null} text="Cancel" style={{ width: '105px' }} />
            <CustomButton
              style={{ width: '105px' }}
              key="submit"
              onClick={() => null}
              text="Save"
              className="btn_darkblue"
            />
          </Row>
        </Form>
      </Col>
    </div>
  );
}
export default LenderDocsCreateForm;
