import '../CustomComponents/styles/custom_tabs.css';
import { Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useMemo, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import LARDBContext from 'pages/Onboarding/LARDB/LARDBContext';
import DiligenceTabs from './Diligence/DiligenceTabs';
import LARDBAuction from './Auction/LARDBAuction';
import Settings from './Settings/Settings';
import styles from './styles/LARDBTabs.module.css';
import Quote from './Quote';

function LARDBTabs({
  closeInviteDrawer,
  createInviteDrawer,
  getLARPDBData,
  openInviteDrawer,
  setDataLoading,
  setUserDrawer,
}) {
  const { tab: tabActiveKey } = useParams();
  const data = useContext(LARDBContext);
  const history = useHistory();
  const role = useSelector((state) => state.user.userData.role);

  const setTabActiveKey = useCallback(
    (newTab) => {
      history.push(`/onboarding/lar_db/${data.lar_id}/${newTab}`);
    },
    [history, data.lar_id],
  );

  const goToLoanProfile = useCallback(() => {
    history.push({
      pathname: `/onboarding/loan_profile/${data.lar_id}`,
      state: { pageBg: '#F5F5F5' },
    });
  }, [history, data.lar_id]);

  const tabsData = useMemo(
    () => [
      ...(/borrower|lender/i.test(role) ||
      /pre auction|scheduled|archive/i.test(data.lar_status) ||
      (/broker/i.test(role) && /live auction/i.test(data.lar_status))
        ? []
        : [
            {
              Component: LARDBAuction,
              key: 'auction',
              label: 'Auction',
            },
          ]),
      ...(/loan processing/i.test(data.lar_status)
        ? [
            {
              Component: DiligenceTabs,
              key: 'loan_processing',
              label: 'Loan processing',
              path: 'loan_processing/verification',
            },
          ]
        : []),
      {
        Component: () => null,
        key: 'link',
        label: (
          <span
            onClick={(e) => {
              e.preventDefault();
              goToLoanProfile();
            }}
          >
            Loan profile
          </span>
        ),
      },
      ...(/(lender)/i.test(role)
        ? [
            {
              Component: () => <Quote />,
              key: 'quote',
              label: 'Quote',
            },
          ]
        : []),
      ...(/(broker)|(pla)|(^superadmin$)/i.test(role)
        ? [
            {
              Component: Settings,
              key: 'settings',
              label: 'Settings',
            },
          ]
        : []),
    ],
    [goToLoanProfile, role, data.lar_status],
  );

  return (
    <Tabs
      activeKey={tabsData.find((t) => tabActiveKey === t.key) ? tabActiveKey : 'auction'}
      className={`${styles.root} custom-tabs`}
      onTabClick={(key) => {
        if (key === 'link') {
          return;
        }

        const currComponent = tabsData.find((t) => key === t.key);
        const activePath = currComponent.path || currComponent.key;
        sessionStorage.setItem('IharmoniDBTabName', key);
        setTabActiveKey(activePath);
      }}
    >
      {tabsData.map((tab) => (
        <Tabs.TabPane tab={tab.label} key={tab.key}>
          <tab.Component
            closeInviteDrawer={closeInviteDrawer}
            createInviteDrawer={createInviteDrawer}
            getLARPDBData={getLARPDBData}
            goToLoanProfile={goToLoanProfile}
            openInviteDrawer={openInviteDrawer}
            setDataLoading={setDataLoading}
            setLARDBTab={setTabActiveKey}
            setUserDrawer={setUserDrawer}
          />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
}

export default LARDBTabs;
