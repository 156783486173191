import { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { LARAPI } from 'api';
import { RejectIcon, TrophyIcon } from 'assets/icons';

import Actions from 'components/OnBoarding/ScenarioBids/Actions';
import Header from 'components/OnBoarding/ScenarioBids/Header';
import ScenarioNav from 'components/OnBoarding/Scenarios/ScenarioNav';
import Placeholder from 'components/OnBoarding/ScenarioBids/Placeholder';
import PostMessageDrawer from 'components/OnBoarding/PostMessageDrawer';
import PublicMessages from 'components/OnBoarding/PublicMessages/PublicMessages';
import Response from 'components/OnBoarding/ScenarioBids/Responses/Response';
import ScenarioBid from 'components/OnBoarding/ScenarioBid';
import Timeline from 'components/Timeline';
import Box from 'components/UI/Box';
import DQConfirmation from 'components/DirectQuote/DQConfirmation';
import Tooltip from 'components/UI/Tooltip';
import LenderInfo from 'components/OnBoarding/ScenarioBids/LenderInfo';

import styles from './ScenarioBids.module.css';

function ScenarioBids() {
  const history = useHistory();
  const { id: larId, bidId: activeItemId } = useParams();

  const { timestamp } = useSelector((s) => s.chat);
  const { token } = useSelector((s) => s.user.userData);
  const [activeDrawer, setActiveDrawer] = useState({ show: '', data: null });
  const [data, setData] = useState({ ready: false, items: [], scenario: {} });

  const API = useMemo(() => new LARAPI(Number(larId)), [larId]);

  const setActiveItemId = useCallback(
    (id) => {
      const currentId = Number(activeItemId);
      if (currentId === id) {
        return;
      }

      history.push(`/onboarding/scenarios/${larId}/bids/${id}`);
    },
    [activeItemId, history, larId],
  );

  const updateItemList = useCallback(() => {
    API.getBids().then(({ bids, scenario }) => {
      setData({
        items: bids,
        ready: true,
        scenario,
      });
    });
  }, [API, timestamp]);
  useEffect(updateItemList, [updateItemList]);

  const activeItem = useMemo(
    () => data.items.find(({ id }) => id === Number(activeItemId)) || {},
    [data.items, activeItemId],
  );

  useEffect(() => {
    if (data.items[0]) {
      setActiveItemId(data.items[0].id);
    }
  }, [data.ready]);

  useEffect(() => {
    activeItem.id && API.sendBidViewReport(activeItem.id);
  }, [API, activeItem]);

  /* Handle websocket events */
  // This is all untested btw

  const socket = useMemo(() => io(`${process.env.REACT_APP_API_URL}/scenario`, { query: { jwt: token } }), [token]);
  useEffect(() => {
    if (!token) {
      return;
    }

    socket.on('connect', () => {
      socket.emit('connect_to_scenario');
    });

    socket.on('scenario_updates', ({ action, bid }) => {
      switch (action) {
        case 'new_respond':
          setData((prev) => ({
            ...prev,
            items: [...prev.items, bid],
          }));
          break;

        case 'bid_deleted':
        case 'bid_updated':
        case 'new_bid':
        case 'respond_updated':
        default:
          // TODO: Handle these events
          console.log('Scenario bids ws unhandled event');
      }
    });

    socket.connect();
    return () => {
      socket.emit('disconnect_from_scenario');
      socket.disconnect();
    };
  }, [socket, larId, token]);

  function closeActiveDrawer() {
    setActiveDrawer({ show: '', data: null });
  }

  return (
    <div className={styles.root}>
      <Header
        API={API}
        openPublicMessageDrawer={() => setActiveDrawer({ show: 'post_message' })}
        scenario={data.scenario}
      />
      <Spin spinning={!data.ready}>
        <ScenarioNav
          items={data.items}
          larName={data.scenario?.name}
          activeItemId={activeItem.id}
          onSetActiveItemId={setActiveItemId}
        />
      </Spin>
      {activeItem.id && (
        <main className={styles.main}>
          <Timeline timeline={activeItem.timeline} />

          {activeItem?.bid_status?.name === 'Quote rejected' && activeItem.reject_reason && (
            <Tooltip
              size="large"
              title="Quote rejected"
              text={activeItem.reject_reason}
              icon={<RejectIcon colour="white" strokeWidth="1" />}
            />
          )}

          {activeItem.bid_status?.name === 'Borrower Interested' && (
            <Tooltip
              type="success"
              icon={<TrophyIcon className={styles.trophyIcon} />}
              size="large"
              text="Good news! The borrower accepted this Direct Quote. You must now select “Accept” to continue the process."
            />
          )}
          {activeItem.bid_status?.name === 'Quote rescinded' && <DQConfirmation />}

          <PublicMessages larId={Number(larId)} isScenario />
          <Actions
            API={API}
            activeItem={activeItem}
            bidId={activeItem.id}
            larId={larId}
            updateItemList={updateItemList}
            quoteRecieved={Boolean(activeItem.loan_amount)}
            borrowerAccepted={activeItem.bid_status?.name === 'Borrower Interested'}
            borrowerRejected={activeItem.bid_status?.name === 'Borrower Not Interested'}
          />
          {activeItem.loan_amount && (
            <Box>
              <div className={styles.quoteWrapper}>
                <Tooltip
                  icon={<InfoCircleOutlined style={{ color: '#40a9ff', fontSize: '20px' }} />}
                  text="This quote does not include title insurance, appraisal, broker fees, and other related closing costs."
                  type="accent"
                />
                <LenderInfo companyName={activeItem.company_name} companyLogo={activeItem.company_logo} />
                <ScenarioBid meta={activeItem} />
              </div>
            </Box>
          )}
          {activeItem.respond ? <Response data={activeItem.respond} /> : null}
        </main>
      )}

      {data.items.length === 0 && <Placeholder />}
      <PostMessageDrawer API={API} onClose={closeActiveDrawer} visible={activeDrawer.show === 'post_message'} />
    </div>
  );
}

export default ScenarioBids;
