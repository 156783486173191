import { Space, Row, Radio, Input, Modal, message, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { getUserFromStorage } from 'utils/storage';
import UniversalTable from '../../UniversalTable';
import { DownloadIcon, EyeIcon } from '../../../assets/icons/icons';
import Button from '../../UI/Button';
import SectionTitle from '../SectionTitle';
import SendPackageModal from './SendPackageModal';
import API from '../../../api';
import CustomButton from '../../UI/Button';
import helper from '../../../constants/helper';

const downloadIcon = (
  <div
    style={{
      background: '#FAFAFA',
      borderRadius: 24,
      padding: 20,
      display: 'flex',
    }}
  >
    <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.667 53.8333V60.1667C12.667 61.8464 13.3343 63.4573 14.522 64.645C15.7097 65.8328 17.3206 66.5 19.0003 66.5H57.0003C58.68 66.5 60.2909 65.8328 61.4787 64.645C62.6664 63.4573 63.3337 61.8464 63.3337 60.1667V53.8333"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.167 34.8333L38.0003 50.6667L53.8337 34.8333"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M38 12.6667V50.6667" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);
const moveIcon = (
  <div
    style={{
      background: '#D7D3F8',
      borderRadius: 24,
      padding: 20,
      border: '2px solid #5744E4',
      display: 'flex',
    }}
  >
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.667 32L32.0003 45.3333L58.667 18.6666"
        stroke="#6C5CE7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.9997 32L45.333 18.6666M5.33301 32L18.6663 45.3333L5.33301 32Z"
        stroke="#6C5CE7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
function DownloadPackageModal(props) {
  const { visible, onClose, onSubmit, allItemsCount, selectedItemsCount } = props;
  const [move, setMove] = useState(false);
  const moveOnchange = (e) => {
    setMove(e.target.checked);
  };
  return (
    <Modal
      className="wrapper_prospect wrapper_paste_file"
      title={
        <div className="wrapper_prospect__title">
          <span className="title">Download Zip</span>
        </div>
      }
      open={visible}
      centered
      footer={
        <div className="wrapper_remove_prospect_btns">
          <CustomButton key="back" onClick={onClose} text="Cancel" />
          <CustomButton key="submit" className="btn_darkblue" onClick={() => onSubmit(move)} text="Download" />
        </div>
      }
      onCancel={onClose}
    >
      <Row justify="center" align="middle">
        {downloadIcon}
      </Row>
      <Row style={{ marginTop: 35 }} justify="center" align="middle">
        <div>
          <Checkbox name="move" onChange={moveOnchange} checked={move}>
            Move {selectedItemsCount}/{allItemsCount} selected items to "Lender Review"
          </Checkbox>
        </div>
      </Row>
    </Modal>
  );
}
function MovePackageModal(props) {
  const { visible, onClose, allItemsCount, selectedItemsCount, onSubmit } = props;
  return (
    <Modal
      className="wrapper_prospect wrapper_paste_file"
      title={
        <div className="wrapper_prospect__title">
          <span className="title">Move to Lender Review</span>
        </div>
      }
      open={visible}
      centered
      footer={
        <div className="wrapper_remove_prospect_btns">
          <CustomButton key="back" onClick={onClose} text="Cancel" />
          <CustomButton key="submit" className="btn_darkblue" onClick={onSubmit} text="Send" />
        </div>
      }
      onCancel={onClose}
    >
      <Row justify="center" align="middle">
        {moveIcon}
      </Row>
      <Row style={{ marginTop: 35 }} justify="center" align="middle">
        <div>
          Move {selectedItemsCount}/{allItemsCount} selected items to "Lender Review"
        </div>
      </Row>
    </Modal>
  );
}

function CreateLoanPackage(props) {
  const { dd_center_id, larDBData, setViewDiligenceVisible, setViewDiligence } = props;
  const [activeModal, setActiveModal] = useState({ show: false, data: null });
  const [selectedItems, setSelectedItems] = useState([]);
  const [packageItems, setPackageItems] = useState({ loading: false, data: [] });
  const [modalAction, setModalAction] = useState(null);
  const [loanPackageName, setLoanPackageName] = useState('');
  const [responseErrorModal, setResponseErrorModal] = useState(null);
  const userData = getUserFromStorage();
  const onCloseActiveModal = () => setActiveModal({ show: '', data: null });

  const clearFields = () => {
    setSelectedItems([]);
    setModalAction(null);
    setLoanPackageName('');
  };

  const currentModalCallback = () => setActiveModal((state) => ({ ...state, show: `${modalAction}_loan_package` }));

  const onSelectPackage = (record) => {
    const { id } = record;
    const isSelected = selectedItems.findIndex((el) => el === id);
    if (isSelected === -1) {
      setSelectedItems((prevState) => [...prevState, id]);
    } else {
      const filteredItems = selectedItems.filter((el) => el !== id);
      setSelectedItems([...filteredItems]);
    }
  };
  const onSelectAllPackages = (selected) => {
    const selectedIds = packageItems.data.map((el) => el.id);
    if (selected) {
      setSelectedItems([...selectedIds]);
    } else {
      setSelectedItems([]);
    }
  };

  const downloadLoanPackage = (move) => {
    API()
      .post('/LAR/dd_center/loan_package', {
        items_ids: selectedItems,
        action: 'download',
        package_name: loanPackageName,
        move,
      })
      .then((res) => {
        onCloseActiveModal();
        window.open(res.data.zip_path, '_blank');
      })
      .then(() => {
        getItems();
        clearFields();
      });
  };
  const moveLoanPackage = () => {
    API()
      .post('/LAR/dd_center/loan_package', {
        items_ids: selectedItems,
        action: 'move',
        package_name: loanPackageName,
      })
      .then(() => {
        onCloseActiveModal();
        message.info('Loan package has been move successfully');
      })
      .then(() => {
        getItems();
        clearFields();
      });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'item_name',
      key: 'item_name',
    },
    {
      title: 'Type',
      dataIndex: 'item_type',
      key: 'item_type',
    },
    {
      title: 'Uploaded',
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
    {
      title: 'Uploaded by',
      dataIndex: 'updated_by',
      key: 'updated_by',
    },
    {
      title: null,
      dataIndex: 'actions',
      key: 'actions',
      render: (_, obj) => (
        <Space align="center">
          <a
            aria-label="downloadItem"
            href={`${process.env.REACT_APP_API_URL}/LAR/dd_center/item-download?item_id=${
              obj.id
            }&jwt=${localStorage.getItem(helper.JWT_TOKEN)}`}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            <DownloadIcon style={{ color: '#8C8C8C' }} />
          </a>

          <EyeIcon
            onClick={() => {
              setViewDiligenceVisible(true);
              setViewDiligence(obj);
            }}
            style={{ color: '#8C8C8C', verticalAlign: 'middle' }}
          />
        </Space>
      ),
    },
  ];
  const headerContent = (
    <>
      <Row justify="space-between" align="middle" style={{ padding: '0 24px', marginBottom: 7 }}>
        <Space size={4} direction="vertical">
          <SectionTitle type="subheading-16" title="Create loan package" />
          <span className="description-12">Only Lender Ready items are shown</span>
        </Space>
      </Row>
      <Row
        justify="space-between"
        align="middle"
        style={{
          padding: '17px 24px',
          minHeight: 95,
          borderTop: '1px solid #D9D9D9',
          borderBottom: '1px solid #D9D9D9',
        }}
      >
        <Space size={4} direction="vertical">
          <span>Loan Package Name</span>
          <Input value={loanPackageName} onChange={(e) => setLoanPackageName(e.target.value)} style={{ width: 300 }} />
        </Space>
        <Button
          disabled={!selectedItems.length || !loanPackageName.trim() || !modalAction}
          onClick={currentModalCallback}
          text="Continue"
          className="btn_darkblue"
        />
      </Row>
      <div style={{ padding: '16px 24px' }}>
        <Radio.Group value={modalAction} onChange={(e) => setModalAction(e.target.value)}>
          <Space size="middle" direction="vertical">
            <Radio value="sent">Send loan package as a zip file</Radio>
            <Radio value="download">Download ZIP now</Radio>
            <Radio value="move">Move to Lender Review without Sending</Radio>
          </Space>
        </Radio.Group>
      </div>
    </>
  );

  const getItems = () => {
    API()
      .get('/LAR/dd_center/item', {
        params: {
          for_loan_package: 1,
          dd_center_id: +dd_center_id,
        },
      })
      .then((res) => {
        setPackageItems((prevState) => ({
          ...prevState,
          data: [...res.data.items],
          loading: true,
        }));
      });
  };

  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <UniversalTable
        headerContent={headerContent}
        rowSelection={{
          onSelect: onSelectPackage,
          onSelectAll: onSelectAllPackages,
        }}
        scroll={{ x: 700 }}
        className="universal-table_header-no-x-padding"
        dataSource={packageItems.data.map((el, idx) => ({ ...el, key: idx }))}
        columns={columns}
      />
      <SendPackageModal
        getItems={getItems}
        preparedFields={{
          hasFields: true,
          email: {
            value: larDBData?.lender_email || '',
            error: false,
            errorMessage: 'Fill this label',
          },
          email_cc: {
            value: userData?.email,
            error: false,
            errorMessage: 'Fill this label',
          },
          template: {
            value: 'Hello ${name}, please start upload documents to the LAR...',
            error: false,
            errorMessage: 'Fill this label',
          },
        }}
        modal_title="Create & send loan package"
        responseError={responseErrorModal}
        onClose={onCloseActiveModal}
        allItemsCount={packageItems.data.length}
        selectedItemsCount={selectedItems.length}
        ccEmail
        userData={userData}
        selectedItems={selectedItems}
        loanPackageName={loanPackageName}
        clearFields={clearFields}
        showPreview
        activeModal={activeModal}
        setResponseError={(e) => setResponseErrorModal(e)}
        toBeVisible={activeModal.show === 'sent_loan_package'}
        requestUrl="/LAR/dd_center/loan_package"
        responseText="Loan package sent"
      />
      <DownloadPackageModal
        onClose={onCloseActiveModal}
        allItemsCount={packageItems.data.length}
        selectedItemsCount={selectedItems.length}
        onSubmit={downloadLoanPackage}
        visible={activeModal.show === 'download_loan_package'}
      />
      <MovePackageModal
        onSubmit={moveLoanPackage}
        allItemsCount={packageItems.data.length}
        selectedItemsCount={selectedItems.length}
        onClose={onCloseActiveModal}
        visible={activeModal.show === 'move_loan_package'}
      />
    </>
  );
}
export default CreateLoanPackage;
