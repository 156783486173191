import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo(({ variant, ...params }) => (
  <GenericIcon {...params}>
    {(variant || 1) === 1 ? (
      <>
        <path d="M4 21.5V8.5C4 7.70435 4.31607 6.94129 4.87868 6.37868C5.44129 5.81607 6.20435 5.5 7 5.5H17C17.7956 5.5 18.5587 5.81607 19.1213 6.37868C19.6839 6.94129 20 7.70435 20 8.5V14.5C20 15.2956 19.6839 16.0587 19.1213 16.6213C18.5587 17.1839 17.7956 17.5 17 17.5H8L4 21.5Z" />
        <path d="M8 13.5H14" />
        <path d="M8 9.5H16" />
      </>
    ) : (
      <>
        <path d="M12 20L9 17H7C6.20435 17 5.44129 16.6839 4.87868 16.1213C4.31607 15.5587 4 14.7956 4 14V8C4 7.20435 4.31607 6.44129 4.87868 5.87868C5.44129 5.31607 6.20435 5 7 5H17C17.7956 5 18.5587 5.31607 19.1213 5.87868C19.6839 6.44129 20 7.20435 20 8V14C20 14.7956 19.6839 15.5587 19.1213 16.1213C18.5587 16.6839 17.7956 17 17 17H15L12 20Z" />
        <path d="M8 13H14" />
        <path d="M8 9H16" />
      </>
    )}
  </GenericIcon>
));
