import { Collapse } from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { AlarmClockIcon, CloseIcon } from 'assets/icons';
import SwitchIcon from 'assets/icons/SwitchIcon';
import LoaderIcon from 'assets/icons/LoaderIcon';
import Modal from 'components/Modals/Modal/Modal';
import parseFromET from 'constants/parseFromET';
import Keys from 'constants/helper';

import styles from './AuctionPreviewPopup.module.css';

export default function AuctionPreviewPopup() {
  const [timer, setTimer] = useState('00:00:00');
  const [isOpen, setIsOpen] = useState(false);
  const { metrics, timestamp } = useSelector((store) => store.dashboard);
  const nextStart = useMemo(() => parseFromET(metrics?.timing_json?.next_event_time), [metrics]);

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem(Keys.AUCTION_PREVIEW_POPUP, 'true');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const delta = nextStart.diff(moment.now());

      const h = Math.floor(delta / 3.6e6);
      const m = Math.floor((delta - h * 3.6e6) / 6e4);
      const s = Math.floor((delta - h * 3.6e6 - m * 6e4) / 1e3);

      setTimer([h, m, s].map((n) => n.toString().padStart(2, 0)).join(':'));
    }, 1e3);

    return () => clearInterval(interval);
  }, [nextStart]);

  useEffect(() => {
    if (metrics?.timing_json?.event === 'Preview open' && !localStorage.getItem(Keys.AUCTION_PREVIEW_POPUP)) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [metrics, timestamp]);

  return (
    <Modal destroyOnClose onCancel={handleClose} open={isOpen} title="Auction Preview" footer="">
      <div className={styles.note}>
        <p className={styles.noteTitle}>Time to Auction Start</p>
        <div className={styles.noteTimeWrapper}>
          <AlarmClockIcon colour="brand-5" />
          <span className={styles.noteTime}>{timer.includes('-') ? '00:00:00' : timer}</span>
        </div>
      </div>
      <div>During the preview you can:</div>
      <ol className={styles.list}>
        <li>
          <LoaderIcon colour="brand-5" />
          <div>Review loan requests and prepare your quotes</div>
        </li>
        <li>
          <SwitchIcon colour="brand-5" />
          <div>Reassign auctions to other members</div>
        </li>
        <li>
          <CloseIcon colour="brand-5" />
          <div>Pass on an auction</div>
        </li>
      </ol>
      <Collapse>
        <Collapse.Panel header="Why don’t I see any auction previews?" key="1" className={styles.collapse}>
          <p className={styles.text}>
            If you didn’t receive any invitations, then it means none of the upcoming deals were a strong match to your
            lending criteria.
          </p>
          <p className={styles.text}>
            Lender invitations are delivered automatically by Starport’s AI Engine based on how well your loan program
            mandates match a deal’s parameters.
          </p>
        </Collapse.Panel>
      </Collapse>
    </Modal>
  );
}
