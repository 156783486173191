import './styles/email_message_template.css';
import { getLARFields } from 'constants/stringReplaceData';
import { message as toast } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMergeFields } from 'constants/useMergeFields';
import API from 'api';
import Button from 'components/UI/Button';
import CustomTable from 'components/UI/CustomTable';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import TemplateEditor from 'components/TemplateEditor';
import untagify from 'constants/untagify';
import useNestedCheckError from 'constants/useNestedCheckError';

let initialState = {
  subject: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
  template: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
};

const initialPageData = {
  action_required: '',
  delay: null,
  document_url: null,
  enabled: null,
  id: null,
  message: '',
  role_name: '',
  subject: '',
  template_type: '',
  trigger_id: null,
};

// TODO: pageData is passed as an argument here, no need to request /superadmin/trigger-config again
export default function EmailMessageTemplate() {
  const [fields, setFields] = useState(initialState);
  const [pageData, setPageData] = useState(initialPageData);
  const [disableRerender, setDisableRerender] = useState(false);
  const [configData, setConfigData] = useState({});
  const { configId, triggerId } = useParams();
  const history = useHistory();
  const { checkEmpty } = useNestedCheckError(fields, setFields);
  const [mergeValue, setMergeValue] = useMergeFields('', configData.context, configData.merge_fields);
  const [mergeSubject, setMergeSubject] = useMergeFields('', configData.context, configData.merge_fields);

  useEffect(() => {
    setMergeValue(fields?.template?.value);
    // eslint-disable-next-line
  }, [fields?.template?.value]);

  useEffect(() => {
    setMergeSubject(fields?.subject?.value);
    // eslint-disable-next-line
  }, [fields?.subject?.value]);

  useEffect(() => {
    API()
      .get('/superadmin/trigger-config', {
        params: { config_id: configId },
      })
      .then((res) => {
        const { subject, message } = res.data;
        setPageData(res.data);
        initialState = {
          subject: {
            ...initialState.subject,
            value: subject,
          },
          template: {
            ...initialState.template,
            value: message,
          },
        };
        setFields(initialState);
      });
  }, [configId]);

  useEffect(() => {
    API()
      .get('/superadmin/triggers', { params: { trigger_id: triggerId } })
      .then((res) => {
        setConfigData(res.data.trigger);
      });
  }, [triggerId]);

  const saveConfig = (e) => {
    e.preventDefault();
    const { error, obj } = checkEmpty(fields);
    setFields(obj);

    if (error) {
      return;
    }

    API()
      .post('/superadmin/email-config', {
        config_id: +configId,
        subject: untagify(fields.subject.value),
        message: fields.template.value,
      })
      .then(() => {
        toast.success('Email message saved');
      });
  };

  const previewEmail = () => {
    API()
      .post('/superadmin/send-email-preview', {
        subject: untagify(fields.subject.value),
        message: untagify(fields.template.value, '<br>'),
      })
      .then(() => {
        toast.success('Email sent successfully');
      });
  };

  return (
    <div className="lar_created_wrapper email_notification_wrapper">
      <div className="wrapper_lar_create_team" style={{ marginBottom: 24 }}>
        <SectionTitle title="Email Message" />
      </div>
      <div className="trigger-info email">
        <div>
          <span>ID:</span>
          <b>{pageData.id}</b>
        </div>
        <div>
          <span>Nickname:</span>
          <b>{configData.name}</b>
        </div>
        <div>
          <span>Recipient User:</span>
          <b>{pageData.role_name}</b>
        </div>
        <div>
          <span>Message Type:</span>
          <b>{pageData.template_type}</b>
        </div>
        <div>
          <span>Use email template:</span>
          <b>{configData.type_name === 'Automated' ? 'No' : 'Yes'}</b>
        </div>
        <div>
          <span>Action:</span>
          <b>{pageData.action_required?.name}</b>
        </div>
        <div>
          <span>Updated at:</span>
          <b>{pageData.updated_at}</b>
        </div>
      </div>
      <CustomTable onSubmit={saveConfig}>
        <TemplateEditor
          title="Subject"
          text={fields.subject.value}
          wrapperClassName="wrapper_template wrapper_template_input"
          toolbar={{ options: [] }}
          mention={{
            separator: ' ',
            trigger: '$',
            suggestions: getLARFields(configData.context, configData.merge_fields).map((i) => ({
              text: `{${i.field}}`,
              value: `{${i.field}}`,
            })),
          }}
          disableRerender={disableRerender}
          setDisableRerender={setDisableRerender}
          setText={(e) => {
            setFields((state) => ({
              ...state,
              subject: {
                ...state.subject,
                value: e,
              },
            }));
          }}
        />

        <TemplateEditor
          title="Template"
          text={fields.template.value}
          mention={{
            separator: ' ',
            trigger: '$',
            suggestions: getLARFields(configData.context, configData.merge_fields).map((i) => ({
              text: `{${i.field}}`,
              value: `{${i.field}}`,
            })),
          }}
          disableRerender={disableRerender}
          setDisableRerender={setDisableRerender}
          setText={(e) => {
            setFields((state) => ({
              ...state,
              template: {
                ...state.template,
                value: e,
              },
            }));
          }}
        />

        <div className="wrapper_template_preview">
          <div style={{ marginTop: 16, marginBottom: 6 }}>Subject</div>
          <div style={{ marginTop: 0 }} className="preview">
            <span dangerouslySetInnerHTML={{ __html: mergeSubject }} />
          </div>
          <div style={{ marginTop: 16, marginBottom: 6 }}>Template</div>
          <div style={{ marginTop: 0 }} className="preview">
            <span dangerouslySetInnerHTML={{ __html: mergeValue }} />
          </div>
        </div>
        <div className="wrapper_form_btn four_btn">
          <Button
            text="Cancel"
            style={{ marginRight: 'auto' }}
            onClick={() => history.push(`/onboarding/ems/${triggerId}`)}
          />
          <Button text="Email Preview to me" onClick={() => previewEmail()} />
          <Button
            text="Reset to previous"
            onClick={() => {
              setDisableRerender(false);
              setFields(initialState);
            }}
          />
          <Button className="btn_darkblue" type="submit" text="Save" />
        </div>

        <div className="merge-fields">
          <span>Merge Fields to Customize Your Email Templates</span>
          <div className="fields">
            <div className="field-row">
              <b>Merge Field</b>
              <b>Meaning</b>
            </div>
            {getLARFields(configData.context, configData.merge_fields).map((i) => (
              <div className="field-row">
                <span
                  onClick={() =>
                    setFields((state) => ({
                      ...state,
                      template: {
                        ...state.template,
                        value: state?.template?.value?.split('</p>').join(` \${${i.field}} </p>`),
                      },
                    }))
                  }
                >
                  {i.field}
                </span>
                <span>{i.description}</span>
              </div>
            ))}
          </div>
        </div>
      </CustomTable>
    </div>
  );
}
