import { Checkbox, Modal, Row, Space, Input } from 'antd';
import { useState } from 'react';
import CustomButton from 'components/UI/Button';

const initialFields = {
  additional_info: ' ',
  already_seen: false,
  does_not_meet_criteria: false,
  more_details: false,
  not_competitive: false,
  too_busy: false,
};

export default function PassAuctionModal({ onCloseActiveModal, visible, handlePassAuction, rescind }) {
  const [fields, setFields] = useState(initialFields);

  const checkBoxSetting = [
    {
      name: 'does_not_meet_criteria',
      value: fields.does_not_meet_criteria,
      label: 'The loan request does not meet our lending criteria',
    },
    {
      name: 'too_busy',
      value: fields.too_busy,
      label: 'Our team is too busy with other loan requests',
    },
    {
      name: 'not_competitive',
      value: fields.not_competitive,
      label: 'We cannot be competitive with the requested terms',
    },
    {
      name: 'already_seen',
      value: fields.already_seen,
      label: 'We have already seen this loan request',
    },
    {
      name: 'more_details',
      value: fields.more_details,
      label: 'We need more detailed information to place a quote',
    },
  ];

  function handleCheckboxChange({ target: { name, checked } }) {
    setFields((prev) => ({
      ...prev,
      [name]: checked,
    }));
  }

  function handleInputChange({ target: { name, value } }) {
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  return (
    <Modal
      className="wrapper_prospect"
      title={
        <div className="wrapper_prospect__title">
          <span className="title">{rescind ? 'Rescind quote' : 'Confirm Passing on this Deal'}</span>
          {!rescind && (
            <span className="subtitle">
              When you pass on a deal, then the deal will be removed from your pipeline and no longer visible. This
              decision cannot be undone.
            </span>
          )}

          <span className="description">Please tell us why you are not interested in this deal</span>
        </div>
      }
      open={visible}
      onOk={onCloseActiveModal}
      centered
      footer={
        <div className="wrapper_remove_prospect_btns">
          <CustomButton onClick={onCloseActiveModal} text="Cancel" />
          <CustomButton
            onClick={() => {
              handlePassAuction(fields);
              setFields(initialFields);
            }}
            text={rescind ? 'Rescind quote' : 'Pass'}
            primary
          />
        </div>
      }
      onCancel={onCloseActiveModal}
    >
      <Space size={20} direction="vertical" style={{ width: '100%' }}>
        <Space size={12} direction="vertical" style={{ width: '100%' }}>
          {checkBoxSetting.map(({ label, name, value }, i) => (
            <Row key={i}>
              <Checkbox checked={value} name={name} onChange={handleCheckboxChange}>
                {label}
              </Checkbox>
            </Row>
          ))}
        </Space>
        <Space size={12} direction="vertical" style={{ width: '100%' }}>
          <div>Use this space to provide additional information</div>
          <Input.TextArea name="additional_info" onChange={handleInputChange} rows={6} />
        </Space>
      </Space>
    </Modal>
  );
}
