import { CaretIcon } from 'assets/icons';
import { DashboardAPI } from 'api';
import { Row, Select, Space, Table } from 'antd';
import { useCallback, useReducer, useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CustomSearchInput from 'components/CustomComponents/CustomSearchInput';
import classes from './styles/CompanyLARs.module.css';

class Column {
  constructor(id, label, sorting, amendSorting) {
    this.dataIndex = id;
    this.title = (
      <div className={classes.columnSorter}>
        {label}
        {id === sorting.column && <CaretIcon direction={sorting.order === 'desc' ? 'down' : 'up'} />}
      </div>
    );
    this.onHeaderCell = () => ({
      onClick: () => {
        amendSorting(id === sorting.column ? { type: 'toggleOrder' } : { type: 'setColumn', column: id });
      },
    });
  }
}

function SearchBox({ API, openLAR }) {
  const [findItemData, setFindItemData] = useState([]);
  const [ready, setReady] = useState(true);

  function searchItems(name) {
    setReady(false);

    API.searchCompanyLARs(name).then(({ lars }) => {
      const resultsUI = lars.map(({ id, owner_name }) => (
        <div
          className="custom-search__result-item"
          key={id}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            openLAR(id);
          }}
        >
          {owner_name}
          <span style={{ float: 'right', marginLeft: 30 }}>
            id:
            {id}
          </span>
        </div>
      ));

      setFindItemData(resultsUI);
      setReady(true);
    });
  }

  return (
    <CustomSearchInput
      minContainerWidth={400}
      clearFunc={() => setFindItemData([])}
      enterButton
      loading={!ready}
      placeholder="Search"
      results={findItemData}
      searchFunc={searchItems}
    />
  );
}

export default function CompanyLARs({ API }) {
  const [companyMembers, setCompanyMembers] = useState([]);
  const [data, setData] = useState({ lars: [], total: 0 });
  const [limitBy, setLimitBy] = useState({ loan_owner: null });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [ready, setReady] = useState(false);
  const [sorting, amendSorting] = useReducer(
    (state, action) =>
      action.type === 'toggleOrder'
        ? { ...state, order: { desc: 'asc', asc: 'desc' }[state.order] }
        : { column: action.column, order: 'desc' },
    { column: 'id', order: 'desc' },
  );
  const history = useHistory();

  const columns = useMemo(
    () => [
      new Column('id', 'ID', sorting, amendSorting),
      new Column('type', 'Deal type', sorting, amendSorting),
      new Column('status_name', 'Status', sorting, amendSorting),
      new Column('dd_statuses', 'Diligence (O, R, A)', sorting, amendSorting),
      new Column('broker', 'MLO', sorting, amendSorting),
      new Column('borrower', 'Borrower', sorting, amendSorting),
      new Column('lender', 'LAE', sorting, amendSorting),
    ],
    [sorting],
  );

  const dashboardAPI = useMemo(() => new DashboardAPI(), []);
  const openLAR = useCallback(
    (id) => {
      history.push(`/onboarding/loan_profile/${id}`);
    },
    [history],
  );

  useEffect(() => {
    setReady(false);

    dashboardAPI
      .getItemsList(sorting.column, sorting.order, pagination.current, pagination.pageSize, {
        owner_id: limitBy.loan_owner,
      })
      .then((data) => {
        setData(data);
        setReady(true);
      });
  }, [dashboardAPI, sorting, pagination, limitBy]);

  useEffect(() => {
    API.getCompanyMembers(1, 100).then((data) => setCompanyMembers(data.members));
  }, [API]);

  function handleChangeLoanOwner(value) {
    setPagination((prev) => ({ ...prev, current: 1 }));
    setLimitBy((prev) => ({ ...prev, loan_owner: value }));
  }

  return (
    <div className={classes.root}>
      <Row align="middle" justify="space-between" style={{ margin: '16px 0' }}>
        <h1>Company loans</h1>
        <Space>
          <Select
            allowClear
            onChange={handleChangeLoanOwner}
            options={companyMembers.map((m) => ({ value: m.id, label: m.full_name }))}
            placeholder="Select company member"
            style={{ minWidth: 230 }}
          />
          <SearchBox API={API} openLAR={openLAR} />
        </Space>
      </Row>
      <Table
        columns={columns}
        dataSource={data.lars}
        loading={!ready}
        onChange={(p) => setPagination(p)}
        onRow={({ id }) => ({ onClick: () => openLAR(id) })}
        pagination={{
          ...pagination,
          total: data.total,
        }}
        rowKey="id"
      />
    </div>
  );
}
