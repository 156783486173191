import {
  ACTIVE,
  ACTIVE_AUCTIONS,
  ARCHIVE,
  DRAFT,
  INCOMPLETE,
  LIVE_AUCTION,
  LOAN_PROCESSING,
  PENDING_REVIEW,
  PRE_AUCTION,
  QUOTE_SELECTION,
  SCHEDULED,
  REPORT_FOR_PLA,
  PLA_REVIEW,
  LAUNCH_APPROVED,
} from 'constants/larApp';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export function useGetLARLink() {
  const role = useSelector((s) => s.user.userData?.role);

  return {
    getLARLink: useCallback(
      (id, type, status, { cognito_token } = {}) => {
        console.log(cognito_token, 'cognito token');
        const isScenario = /scenario/i.test(type);

        if (isScenario && /borrower/i.test(role) && status === ACTIVE) {
          return `/onboarding/loan_profile/${id}`;
        }

        if (/broker/i.test(role) && status === PENDING_REVIEW) {
          return `/onboarding/loan_profile/${id}`;
        }

        if (
          isScenario &&
          /pla|superadmin/i.test(role) &&
          (status === ACTIVE || status === PLA_REVIEW || status === PENDING_REVIEW)
        ) {
          return `/onboarding/scenarios/${id}/report`;
        }

        if (isScenario && status === ACTIVE) {
          return `/onboarding/scenarios/${id}`;
        }

        if (
          !isScenario &&
          /pla|superadmin/i.test(role) &&
          [...ACTIVE_AUCTIONS, PRE_AUCTION, SCHEDULED].includes(status)
        ) {
          return `/onboarding/auction/${id}/report`;
        }

        if (/pla|superadmin/i.test(role) && REPORT_FOR_PLA.includes(status)) {
          return `/onboarding/auction/${id}/report`;
        }

        if (/pla|superadmin|broker/i.test(role) && status === ARCHIVE) {
          return `/onboarding/lar_db/${id}/settings`;
        }

        if (/broker/i.test(role) && status === SCHEDULED) {
          return `/onboarding/auction/${id}/schedule`;
        }

        if (
          /borrower/i.test(role) &&
          [LIVE_AUCTION, ARCHIVE, SCHEDULED, PENDING_REVIEW, QUOTE_SELECTION].includes(status)
        ) {
          return `/onboarding/loan_profile/${id}`;
        }

        if (/lender/i.test(role) && status === QUOTE_SELECTION) {
          return '/onboarding/finished-auctions';
        }

        if (/lender/i.test(role) || type === 'active') {
          return `/onboarding/lar_db/${id}/loan_processing`;
        }

        if (/lender/i.test(role) && ![LIVE_AUCTION, LOAN_PROCESSING].includes(status)) {
          return `/onboarding/loan_profile/${id}`;
        }

        if (status === LOAN_PROCESSING) {
          return `/onboarding/lar_db/${id}/loan_processing`;
        }

        if ([SCHEDULED, PRE_AUCTION, PLA_REVIEW, LAUNCH_APPROVED].includes(status)) {
          return `/onboarding/loan_profile/${id}`;
        }

        if (status === LIVE_AUCTION) {
          return `/onboarding/live_auctions/${id}`;
        }

        if ([INCOMPLETE].includes(status)) {
          return `/lar_app/create${cognito_token}`;
        }

        if (status === QUOTE_SELECTION) {
          return `/onboarding/lar_db/${id}/activity`;
        }

        if (![INCOMPLETE, DRAFT].includes(status)) {
          return `/onboarding/lar_db/${id}`;
        }

        return `/lar_app/create?lar_id=${id}`;
      },
      [role],
    ),
  };
}
