import { SwiperSlide, Swiper } from 'swiper/react/swiper-react';
import styles from './QuoteVisualizer.module.css';
import { breakpoints } from '../../../../constants/slider';
import BarChart from '../../../UI/BarChart';
import { useSelector } from 'react-redux';
import { getClosingCost, getDownPayment } from 'utils/formula';

function QuoteVisualizer({ quotes, setSwiper, larId }) {
  const { feesWorksheet } = useSelector((state) => state);

  const getTheMostLoanAmount = () => {
    let sum = 0;
    quotes?.forEach((quote) => {
      const downPayment = getDownPayment(feesWorksheet, quote) || 0;
      const closingCost = getClosingCost(feesWorksheet, quote) || 0;

      const sumOfDPandCC = downPayment + closingCost;

      if (sumOfDPandCC > sum) {
        sum = sumOfDPandCC;
      }
    });

    return sum;
  };

  const theMostLoanAmount = getTheMostLoanAmount();

  return (
    <div className={styles.section}>
      <div className={styles.plug} />
      <Swiper
        breakpoints={breakpoints}
        onBreakpoint={(swiper) => setSwiper(swiper)}
        onSwiper={(swiper) => setSwiper(swiper)}
        slidesPerView="auto"
        style={{ marginLeft: 0, width: '100%' }}
      >
        {quotes?.map((quote) => (
          <SwiperSlide key={quote.id}>
            <BarChart quote={quote} theMostLoanAmount={theMostLoanAmount} larId={larId} barTitle="Cash to Close" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default QuoteVisualizer;
