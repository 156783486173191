import {
  FETCH_BORROWERS_ACTIVE_ERROR,
  FETCH_BORROWERS_ACTIVE_LOADING,
  FETCH_BORROWERS_ACTIVE_SUCCESS,
  FETCH_BORROWERS_PROSPECTS_ERROR,
  FETCH_BORROWERS_PROSPECTS_LOADING,
  FETCH_BORROWERS_PROSPECTS_SUCCESS,
  SELECT_BORROWER,
} from '../types/users';

export const fetchBorrowersProspectsLoading = () => ({
  type: FETCH_BORROWERS_PROSPECTS_LOADING,
});

export const fetchBorrowersProspectsSuccess = (payload) => ({
  type: FETCH_BORROWERS_PROSPECTS_SUCCESS,
  payload,
});

export const fetchBorrowersProspectsError = (payload) => ({
  type: FETCH_BORROWERS_PROSPECTS_ERROR,
  payload,
});

export const fetchBorrowersActiveLoading = () => ({
  type: FETCH_BORROWERS_ACTIVE_LOADING,
});

export const fetchBorrowersActiveSuccess = (payload) => ({
  type: FETCH_BORROWERS_ACTIVE_SUCCESS,
  payload,
});

export const fetchBorrowersActiveError = (payload) => ({
  type: FETCH_BORROWERS_ACTIVE_ERROR,
  payload,
});

export const selectedBorrower = (payload) => ({
  type: SELECT_BORROWER,
  payload,
});

export const removeSelectedBorrower = () => ({
  type: SELECT_BORROWER,
});
