import React, { useState } from 'react';
import API from 'api';
import { Button, message, Modal } from 'antd';

import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter';
import useNestedCheckError from 'constants/useNestedCheckError';
import Keys from 'constants/helper';
import TextInput from 'components/CustomComponents/TextInput';
import styles from './styles.module.css';

import '../../../Auth/styles/auth_main.css';

const initState = {
  code: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
  password: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
  repeatPassword: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
};

function VerificationCodeSentPopUp({ isOpen, onCancel }) {
  const [field, setFields] = useState(initState);
  const [loading, setLoading] = useState(false);
  const { checkEmpty } = useNestedCheckError(field, setFields);
  const [responseError, setResponseError] = useState(null);

  const onSubmit = (event) => {
    event.preventDefault();
    const { error, obj } = checkEmpty(field);
    setFields(obj);

    if (error) {
      return;
    }

    if (field.password.value.length <= 7) {
      setFields((prevState) => ({
        ...prevState,
        password: {
          ...prevState.password,
          error: true,
          errorMessage: 'Enter at least 8 characters',
        },
      }));
      return;
    }
    if (field.repeatPassword.value !== field.password.value) {
      setFields((prevState) => ({
        ...prevState,
        repeatPassword: {
          ...prevState.repeatPassword,
          error: true,
          errorMessage: 'Passwords is not similar',
        },
      }));
      return;
    }

    setLoading(true);
    API()
      .post('/sign_in/reset_password', {
        domain: window.location.href,
        password: field.password.value,
        code: field.code.value,
        email: sessionStorage.getItem(Keys.VERIFICATION_CODE_EMAIL),
      })
      .then(() => {
        message.success('You successfully changed password');
        onCancel();
      })
      .catch((e) => {
        if (e.response?.data?.error) {
          message.error(e.response?.data?.error);
          setResponseError(e.response.data.error);
        }
      })
      .finally(() => setLoading(false));
  };

  const onChange = (event) => {
    const { value, name } = event.target;
    setFields((prevState) => ({
      ...prevState,
      [name]: {
        value,
        error: null,
      },
    }));
    setResponseError(null);
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      width={370}
      style={innerWidth > 635 ? { top: 84, margin: '0 calc(3% + 270px) 0 auto' } : null}
      onCancel={onCancel}
    >
      <p className="auth_describe">Please enter the code sent to your email</p>
      <form className={styles.container} onSubmit={onSubmit}>
        <TextInput
          value={field.code.value}
          title="Verification code"
          htmlFor="code"
          onChange={onChange}
          errorMessage={field.code.errorMessage}
          error={field.code.error}
        />
        <TextInput
          value={field.password.value}
          title="New password"
          error={field.password.error}
          showPassIcon
          htmlFor="password"
          errorMessage={field.password.errorMessage}
          onChange={onChange}
          autoComplete="on"
          type="password"
        />
        <TextInput
          value={field.repeatPassword.value}
          title="New password confirmation"
          showPassIcon
          htmlFor="repeatPassword"
          errorMessage={field.repeatPassword.errorMessage}
          type="password"
          autoComplete="on"
          error={field.repeatPassword.error}
          onChange={onChange}
        />
        {responseError ? (
          <div className="wrapper_form_error">
            <span>{capitalizeFirstLetter(responseError)}</span>
          </div>
        ) : null}
        <Button size="large" type="dark" htmlType="submit" loading={loading}>
          Change password
        </Button>
      </form>
    </Modal>
  );
}

export default VerificationCodeSentPopUp;
