import { CLOSE_POPUP, SHOW_POPUP } from './types';

export function openPopup(payload) {
  return {
    type: SHOW_POPUP,
    payload,
  };
}

export function closePopup() {
  return {
    type: CLOSE_POPUP,
  };
}
