import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <path d="M13.5 12.5C13.5 11.9477 13.0523 11.5 12.5 11.5C11.9477 11.5 11.5 11.9477 11.5 12.5C11.5 13.0523 11.9477 13.5 12.5 13.5C13.0523 13.5 13.5 13.0523 13.5 12.5Z" />
    <path d="M20.5 12.5C20.5 11.9477 20.0523 11.5 19.5 11.5C18.9477 11.5 18.5 11.9477 18.5 12.5C18.5 13.0523 18.9477 13.5 19.5 13.5C20.0523 13.5 20.5 13.0523 20.5 12.5Z" />
    <path d="M6.5 12.5C6.5 11.9477 6.05228 11.5 5.5 11.5C4.94772 11.5 4.5 11.9477 4.5 12.5C4.5 13.0523 4.94772 13.5 5.5 13.5C6.05228 13.5 6.5 13.0523 6.5 12.5Z" />
  </GenericIcon>
));
