import './styles/iharmoni_documents.css';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import API from 'api';
import Navigations from 'routers/Navigations/index';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import VerticalTabs from 'components/OnBoarding/VerticalTabs';

export default function IHarmoniDocuments() {
  const [activeTab, setActiveTab] = useState(null);
  const [documents, setDocuments] = useState({
    documents: null,
    loading: false,
  });
  const location = useLocation();

  useEffect(() => {
    const activeUrl = location.pathname.split('/')[3];
    if (!activeUrl) {
      return null;
    }
    const activeLabel = Navigations.mainNavigationOnboardingDocuments.find((i) => i.pathname === activeUrl);

    setActiveTab({ activeUrl, activeLabel });
  }, [location.pathname]);

  useEffect(() => {
    API()
      .get('/superadmin/documents')
      .then((res) => {
        setDocuments({ loading: true, documents: res.data.documents });
      });
  }, []);

  const uploadFile = (file, activeDocument) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', activeDocument.id);
    API()
      .post('/superadmin/documents', formData)
      .then((res) => {
        setDocuments((prevState) => ({
          ...prevState,
          documents: prevState.documents.map((d) => {
            if (d.id === activeDocument.id) {
              return {
                ...d,
                updated_at: res.data.updated_at,
                path: res.data.path,
                name: res.data.name,
              };
            }
            return d;
          }),
        }));

        message.success(`You successfully ${activeDocument.name ? 'changed' : 'added'} document`);
      })
      .catch(console.error);
  };

  return (
    <div className="wrapper_iharmoni_documents">
      <SectionTitle title="Starport Documents" />
      {documents.loading && <VerticalTabs activeTab={activeTab} {...documents} uploadFile={uploadFile} />}
    </div>
  );
}
