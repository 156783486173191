import { Tabs } from 'antd';
import { useMemo, useState } from 'react';
import classes from '../../LARDB/styles/LARDBTabs.module.css';
import ProspectsTable from './ProspectsTable';

export default function BorrowersTabs({ onCloseDrawer, setFields, updateTabs, showProfile, ...restProps }) {
  const [activeTab, setActiveTab] = useState('prospect');

  const tabsData = useMemo(
    () =>
      [
        {
          Component: ProspectsTable,
          hidden: false,
          key: 'prospect',
          label: 'Prospects',
        },
        {
          Component: ProspectsTable,
          key: 'user',
          label: 'Active users',
        },
      ].filter((tab) => !tab.hidden),
    [],
  );

  return (
    <Tabs
      activeKey={tabsData.find((t) => activeTab === t.key) ? activeTab : 'prospect'}
      style={{ paddingLeft: 0 }}
      className={`${classes.root} custom-tabs custom-tabs_left`}
      onTabClick={(key) => {
        setActiveTab(key);
      }}
    >
      {tabsData.map((tab) => (
        <Tabs.TabPane tab={tab.label} key={tab.key}>
          <tab.Component
            {...restProps}
            updateTabs={updateTabs}
            onCloseDrawer={onCloseDrawer}
            setFields={setFields}
            activeTab={activeTab}
            showProfile={showProfile}
          />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
}
