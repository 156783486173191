import { Select, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import API from 'api';
import Modal from 'components/Modals/Modal';
import { replacedTextData } from 'constants/common';
import styles from './UpdateRoleDropdown.module.css';

export default function UpdateRoleDropdown({ userData }) {
  const [roles, setRoles] = useState([]);
  const [value, setValue] = useState(userData.role);
  const [isSending, setIsSending] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    setValue(userData.role);
    API()
      .get('/user_settings/company/members')
      .then((res) => {
        const data = res.data.members.find((member) => member.id === userData.id)?.available_roles || [];
        const options = data
          .filter((i) => !/admin/i.test(i))
          .map((i) => ({
            label: replacedTextData[i] || i,
            value: i,
          }));
        setRoles(options);
      });
  }, [userData]);

  const handleUpdate = (v) => {
    setValue(v);
    setIsOpenModal(true);
  };

  const handleChangeRole = useCallback(() => {
    setIsSending(true);
    API()
      .post('/user_settings/company/members', {
        id: userData.id,
        role_name: value,
      })
      .catch(() => setValue(userData.role))
      .finally(() => {
        setIsOpenModal(false);
        setIsSending(false);
      });
  }, [value]);

  const handleCloseModal = () => {
    setValue(userData.role);
    setIsOpenModal(!isOpenModal);
  };

  return (
    <>
      <Select disabled={isSending} className={styles.root} options={roles} value={value} onChange={handleUpdate} />
      <Modal
        open={isOpenModal}
        onCancel={handleCloseModal}
        onOk={handleChangeRole}
        okText="Confirm"
        okButtonProps={{ loading: isSending }}
      >
        <Typography.Title level={5}>Do you want to change this user role?</Typography.Title>
        <Typography.Text>
          If this user is a Mortgage Loan Officer, then all his deals will be transferred to company admin
        </Typography.Text>
      </Modal>
    </>
  );
}
