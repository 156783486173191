import { getLARFields } from 'constants/stringReplaceData';
import { message, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import API from 'api';
import Button from 'components/UI/Button';
import CustomTable from 'components/UI/CustomTable';
import notificationBannerOptions from 'constants/notificationBannerOptions';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import TemplateEditor from 'components/TemplateEditor';
import untagify from 'constants/untagify';
import EMSMeta from './EMSMeta';
import styles from './styles/LARBannerTemplate.module.css';
import BannerIconSelect from './BannerIconSelect';
import BannerColourSchemeSelect from './BannerColourSchemeSelect';
import NotificationBanner from '../../../../components/NotificationBanners/NotificationBanner';

function LARBannerTemplate({ pageData }) {
  const [action, setAction] = useState('none');
  const [colourScheme, setColourScheme] = useState('blue');
  const [configData, setConfigData] = useState({});
  const [disableRerender, setDisableRerender] = useState(false);
  const [icon, setIcon] = useState('trophy');
  const [template, setTemplate] = useState('');
  const { configId, triggerId } = useParams();
  const history = useHistory();
  useEffect(() => {
    pageData.action_required?.name && setAction(pageData.action_name);
    pageData.banner_color && setColourScheme(pageData.banner_color);
    pageData.banner_pic && setIcon(pageData.banner_pic);
    pageData.message && setTemplate(pageData.message);
  }, [pageData]);

  useEffect(() => {
    API()
      .get('/superadmin/triggers', { params: { trigger_id: triggerId } })
      .then((res) => {
        setConfigData(res.data.trigger);
      });
  }, [triggerId]);

  function saveConfig(e) {
    e.preventDefault();

    API()
      .post('/superadmin/lar-banner-config', {
        banner_action: action,
        banner_color: colourScheme,
        banner_pic: icon,
        id: Number(configId),
        message: untagify(template),
      })
      .then(() => message.success('Banner saved successfully'));
  }

  function reset() {
    setTemplate(pageData.message);
  }

  function parseTemplate(template) {
    const stplitedStr1 = template.split('${').join('<strong>${');
    return stplitedStr1.split('}').join('}</strong>');
  }

  return (
    <div className="lar_created_wrapper email_notification_wrapper">
      <div className="wrapper_lar_create_team" style={{ marginBottom: 24 }}>
        <SectionTitle title="LAR Banner" />
      </div>
      <EMSMeta triggerData={configData} channelData={pageData} />
      <CustomTable onSubmit={saveConfig}>
        <div>Banner colour</div>
        <BannerColourSchemeSelect
          options={notificationBannerOptions.colourSchemes.map((c) => ({
            colourA: c.colourA,
            colourB: c.colourB,
            value: c.id,
          }))}
          setValue={setColourScheme}
          value={colourScheme}
        />
        <div>Banner icon</div>
        <BannerIconSelect
          options={notificationBannerOptions.icons.map((i) => ({
            Icon: i.Component,
            value: i.id,
          }))}
          setValue={setIcon}
          value={icon}
        />
        <TemplateEditor
          disableRerender={disableRerender}
          setDisableRerender={setDisableRerender}
          setText={(t) => setTemplate(t)}
          mention={{
            separator: ' ',
            suggestions: getLARFields(configData.context, configData.merge_fields).map(({ field }) => ({
              text: `{${field}}`,
              value: `{${field}}`,
            })),
            trigger: '$',
          }}
          text={template}
          title="Message"
          wrapperClassName="wrapper_template wrapper_template_no-toolbar"
        />
        <div>Action button</div>
        <Select
          className={styles.actionSelect}
          onChange={(a) => setAction(a)}
          options={notificationBannerOptions.actions.map((a) => ({
            label: a.label,
            value: a.id,
          }))}
          value={action}
        />
        <div>
          Preview
          <NotificationBanner
            meta={{
              action,
              banner_color: colourScheme,
              banner_pic: icon,
              message: <span dangerouslySetInnerHTML={{ __html: parseTemplate(template) }} />,
            }}
          />
        </div>
        <div className="wrapper_form_btn four_btn">
          <Button
            text="Cancel"
            style={{ marginRight: 'auto' }}
            onClick={() => history.push(`/onboarding/ems/${triggerId}`)}
          />
          <Button
            text="Reset to default"
            onClick={() => {
              setDisableRerender(false);
              reset();
            }}
          />
          <Button primary text="Save" type="submit" />
        </div>
        <div className="merge-fields">
          <span>Merge fields to customize your banner template</span>
          <div className="fields">
            <div className="field-row">
              <b>Merge field</b>
              <b>Meaning</b>
            </div>
            {getLARFields(configData.context, configData.merge_fields).map(({ field, description }) => (
              <div className="field-row" key={field}>
                <div onClick={() => setTemplate((prev) => prev.split('</p>').join(` \${${field}} </p>`))}>{field}</div>
                <div>{description}</div>
              </div>
            ))}
          </div>
        </div>
      </CustomTable>
    </div>
  );
}

export default LARBannerTemplate;
