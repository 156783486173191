import '../BDR/styles/prospects.css';
import { acceptedMimes } from 'constants/mimes';
import { BinIcon, EditIcon, FileUploadIcon, PlusIcon } from 'assets/icons';
import { ChevronIcon } from 'assets/icons/icons';
import { Table, message, Space, Select, Tooltip } from 'antd';
import { useEffect, useState, useRef, useCallback, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import API from 'api';
import copyIcon from 'assets/images/copy-light-grey.svg';
import CustomButton from 'components/UI/Button';
import DeleteMasterlistModal from 'components/OnBoarding/Masterlist/DeleteMasterlistModal';
import PaginationItemRender from 'constants/PaginationItemRender';
import Papa from 'papaparse';
import SearchBox from 'components/OnBoarding/Masterlist/SearchBox';
import classes from './styles/Masterlists.module.css';

const assignmentTypes = new Set([
  'All',
  'Catchall',
  'Long Term Purchase Multifamily',
  'Long Term Purchase',
  'Long Term Refi Multifamily',
  'Long Term Refi',
  'Short Term',
  'Unassigned',
]);

class Column {
  constructor(label, id, sorting, setSorting, width = 100) {
    this.dataIndex = id;
    this.key = id;
    this.onHeaderCell = () => ({
      onClick: () => {
        setSorting((prev) => ({
          orderBy: id,
          orderType: prev.orderBy === id ? (prev.orderType === 'desc' ? 'asc' : 'desc') : 'desc',
        }));
      },
    });
    this.title = (
      <div className="inline">
        <span>{label}</span>
        {sorting.orderBy === id && (
          <ChevronIcon
            style={{
              color: '#8c8c8c',
              display: 'flex',
              transform: sorting.orderType === 'asc' ? 'rotate(180deg)' : 'rotate(0)',
              transition: '.3s',
            }}
          />
        )}
      </div>
    );
    this.width = width;
  }
}

export default function Masterlists() {
  const [activeModal, setActiveModal] = useState({ show: false, data: null });
  const [companies, setCompanies] = useState(new Set());
  const [filtering, updateFiltering] = useReducer((state, update) => ({ ...state, ...update }), {
    companyId: null,
    assignmentType: null,
  });
  const [masterlistsData, updateMasterlistsData] = useReducer((state, update) => ({ ...state, ...update }), {
    masterlists: [],
    ready: false,
  });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [sorting, setSorting] = useState({ orderBy: 'name', orderType: 'desc' });
  const history = useHistory();
  const importFileInput = useRef(null);
  const role = useSelector((store) => store.user.userData?.role);

  const getMasterlists = useCallback(() => {
    updateMasterlistsData({ ready: false });

    API()
      .get('/user_settings/masterlists', {
        params: {
          company_id: filtering.companyId,
          masterlist_type: filtering.assignmentType,
          order_by: sorting.orderBy,
          order_type: sorting.orderType,
          page: pagination.current,
          per_page: pagination.pageSize,
        },
      })
      .then(({ data }) => updateMasterlistsData({ ...data }))
      .catch((reason) => message.error(reason.response?.data.error))
      .finally(() => updateMasterlistsData({ ready: true }));
  }, [pagination, sorting, filtering]);

  useEffect(getMasterlists, [getMasterlists]);

  const goToMasterlistEdit = useCallback(
    (e, item) => {
      e.stopPropagation();
      e.preventDefault();

      history.push(`/onboarding/masterlists/${item.id}/edit`);
    },
    [history],
  );

  const handleCopyMasterlist = useCallback(
    (masterlistId) => {
      updateMasterlistsData({ ready: false });

      API()
        .post('/user_settings/masterlist/copy', { masterlist_id: masterlistId })
        .then(({ data }) => {
          if (Number(data.masterlist_id) >= 0) {
            message.info('Masterlist successfully copied!');
          }

          getMasterlists();
        });
    },
    [getMasterlists],
  );

  const columns = useMemo(
    () => [
      new Column('Company name', 'company_name', sorting, setSorting, 150),
      new Column('Masterlist Name', 'name', sorting, setSorting, 150),
      new Column('Date Created', 'created_at', sorting, setSorting, 150),
      new Column('# of items', '#_of_items', sorting, setSorting),
      new Column('Assignment Type', 'type', sorting, setSorting),
      {
        title: '',
        dataIndex: 'user_actions',
        key: 'user_actions',
        width: 50,
        render: (_, item) => (
          <Space size={20} align="center" style={{ justifyContent: 'flex-end' }}>
            <Tooltip title="Edit">
              <EditIcon onClick={(e) => goToMasterlistEdit(e, item)} colour="grey-6" />
            </Tooltip>
            <Tooltip title="Copy">
              <img onClick={() => handleCopyMasterlist(item.id)} src={copyIcon} alt="Copy" />
            </Tooltip>
            <Tooltip title="Delete">
              <BinIcon onClick={(e) => openDeleteModal(e, item.id)} colour="grey-6" />
            </Tooltip>
          </Space>
        ),
      },
    ],
    [goToMasterlistEdit, handleCopyMasterlist, sorting],
  );

  function openDeleteModal(e, id) {
    e.stopPropagation();
    e.preventDefault();

    setActiveModal({
      show: 'delete_masterlist',
      data: { id },
    });
  }

  function handleDeleteMasterlist(masterlistId) {
    API()
      .delete('/user_settings/masterlist', {
        params: {
          masterlist_id: masterlistId,
        },
      })
      .then(({ data }) => {
        if (data.msg === 'ok') {
          onCloseActiveModal();
          updateMasterlistsData({ ready: false });

          message.success('Masterlist deleted successfully');
        }
      })
      .then(getMasterlists)
      .catch(({ response }) => {
        message.error(response.data.error);
      });
  }

  function goToMasterlistAdd() {
    history.push({ pathname: '/onboarding/masterlists/add' });
  }

  function onCloseActiveModal() {
    setActiveModal({ show: null, data: null });
  }

  function startImport({ target }) {
    const reader = new FileReader();

    reader.onload = () => {
      const parsed = Papa.parse(reader.result);

      if (parsed.errors.length) {
        message.error('There was a problem importing the file. Please try again or contact support');
        console.error(parsed.errors);
        return;
      }

      const processed = parsed.data.filter((line) => line.filter(Boolean).length);

      if (!processed.length) {
        message.error("You can't import an empty file");
        return;
      }

      history.push({
        pathname: '/onboarding/masterlists/import',
        state: { CSVData: processed },
      });
    };

    reader.readAsText(target.files[0]);
  }

  useEffect(() => {
    if (!/^superadmin$/i.test(role)) {
      return;
    }

    API()
      .get('/user_settings/masterlist/superadmin/companies')
      .then(({ data }) => setCompanies(new Set(data.companies)));
  }, [role]);

  return (
    <div className={`${classes.root} prospects_page_wrapper table_with_header_wrapper table_with_pagination_wrapper`}>
      <input
        accept={acceptedMimes.masterlist.join(',')}
        hidden
        onChange={startImport}
        ref={importFileInput}
        type="file"
      />
      <h1>Masterlists</h1>
      <SearchBox />
      {/^superadmin$/i.test(role) && (
        <Select
          allowClear
          onChange={(companyId) => updateFiltering({ companyId })}
          options={Array.from(companies).map((c) => ({ label: c.name, value: c.id }))}
          placeholder="Any company"
          value={filtering.company}
        />
      )}
      <Select
        allowClear
        onChange={(assignmentType) => updateFiltering({ assignmentType })}
        options={Array.from(assignmentTypes).map((t) => ({ label: t, value: t }))}
        placeholder="Any assignment type"
        value={filtering.assignment_type}
      />
      <CustomButton
        onClick={() => importFileInput.current.click()}
        primary
        svg={<FileUploadIcon colour="white" />}
        text="Import masterlist"
      />
      <CustomButton onClick={goToMasterlistAdd} primary svg={<PlusIcon colour="white" />} text="Add masterlist" />
      <Table
        border
        bordered={false}
        columns={columns}
        dataSource={masterlistsData.masterlists}
        loading={!masterlistsData.ready}
        onChange={(e) => setPagination(e)}
        pagination={{
          ...pagination,
          itemRender: PaginationItemRender,
          showSizeChanger: true,
          total: masterlistsData.total,
        }}
        rowKey="id"
        scroll={{ x: 1200 }}
      />
      <DeleteMasterlistModal
        handleDeleteMasterlist={() => handleDeleteMasterlist(activeModal.data?.id)}
        onClose={onCloseActiveModal}
        visible={activeModal.show === 'delete_masterlist'}
      />
    </div>
  );
}
