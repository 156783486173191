import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <path d="M3.5 20.8519L4.8 16.9519C3.67644 15.2902 3.26999 13.3224 3.65622 11.4142C4.04244 9.50614 5.19506 7.78762 6.89977 6.57822C8.60447 5.36882 10.7453 4.75084 12.9241 4.83919C15.1029 4.92753 17.1715 5.71618 18.7453 7.05851C20.319 8.40083 21.2909 10.2055 21.4801 12.1369C21.6693 14.0684 21.063 15.9952 19.7739 17.5591C18.4848 19.123 16.6007 20.2176 14.4718 20.6393C12.3429 21.061 10.1142 20.7813 8.2 19.8519L3.5 20.8519" />
    <path d="M12.5 12.8519V12.8619" />
    <path d="M8.5 12.8519V12.8619" />
    <path d="M16.5 12.8519V12.8619" />
  </GenericIcon>
));
