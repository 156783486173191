import { DashboardAPI, LARAPI } from 'api';
import { io } from 'socket.io-client';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useReducer, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { updateDashboardMetricsTimestamp } from 'store/actions/dashboard';

import ActionsBar from 'components/OnBoarding/LiveAuctions/ActionsBar';
import AuctionPreviewPopup from 'components/OnBoarding/LiveAuctions/Popups/AuctionPreviewPopup';
import LiveAuctionsHeader from 'components/OnBoarding/LiveAuctions/LiveAuctionsHeader';
import LoanProfile from 'pages/Onboarding/LoanProfile';
import LiveAuctionsAside from 'components/OnBoarding/LiveAuctions/LiveAuctionsAside';
import PassAuctionModal from 'components/LoanProfile/PassAuctionModal';
import LiveAuctionsPlaceholder from 'components/OnBoarding/LiveAuctions/LiveAuctionsPlaceholder';
import PostMessageDrawer from 'components/OnBoarding/PostMessageDrawer';
import PublicMessages from 'components/OnBoarding/PublicMessages';
import ShareLoanProfileModal from 'components/LoanProfile/ShareLoanProfileModal';
import AuctionEndPopup from 'components/OnBoarding/LiveAuctions/Popups/AuctionEndPopup';
import AuctionTutorial from 'components/OnBoarding/LiveAuctions/Popups/AuctionTutorial';
import MainLayout from 'components/Layouts/MainLayout';
import PlaceBidDrawer from '../BidApplication/PlaceBidDrawer';
import ChooseBidDrawer from '../BidApplication/ChooseBidDrawer';

import styles from './LiveAuctions.module.css';
import { ClockIcon, DollarIcon, SharpIcon } from 'assets/icons';

const sortOptions = [
  { name: 'id', Icon: SharpIcon },
  { name: 'property_value', Icon: DollarIcon },
  { name: 'last_update', Icon: ClockIcon },
];

function LiveAuctions() {
  const location = useLocation();
  const { id: activeItemId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  const [team, setTeam] = useState([]);
  const [activeDrawer, setActiveDrawer] = useState({ show: '', data: null });
  const [activeModal, setActiveModal] = useState({ show: '', data: null });
  const [data, setData] = useState({ ready: false, items: [], auction: {} });
  const [actionType, setActionType] = useState(queryParams.get('tab') || 'today');
  const [showNav, toggleNav] = useReducer((s) => !s, true);
  const [forceProfileUpdate, updateLoanProfile] = useReducer((s) => ++s, 0);
  const [showPassModal, togglePassModal] = useReducer((s) => !s, false);
  const [activeSortingOption, setActiveSortingOption] = useState(sortOptions[0]?.name);
  const { metrics, timestamp } = useSelector((store) => store.dashboard);
  const { role, token } = useSelector((state) => state.user.userData);

  const asideWithTabs = /lender|broker/i.test(role);
  const isLender = /lender/i.test(role);
  const isPreviewClosed =
    metrics.timing_json?.event !== 'Preview open' && metrics.timing_json?.event !== 'Auction Live';

  const API = useMemo(() => new DashboardAPI(), []);
  const larAPI = useMemo(() => new LARAPI(Number(activeItemId)), [activeItemId]);

  function openChooseBidDrawer(isShowIfChosen = false, editMode = false) {
    setActiveDrawer({
      show: 'choose_bid',
      data: {
        showChoosePageIfChosenBid: isShowIfChosen,
        editMode,
      },
    });
  }

  const openPlaceBidDrawer = useCallback((editMode = false) => {
    setActiveDrawer({
      show: 'place_bid',
      data: {
        editMode,
      },
    });
  }, []);

  function closeActiveDrawer() {
    setActiveDrawer({ show: '', data: null });
  }

  const updateItemList = useCallback(
    (isRerender = false) => {
      API.getItemsList(
        null,
        null,
        null,
        null,
        { status_name: 'Live Auction' },
        { ...(asideWithTabs && { type: actionType }) },
      ).then(({ auction_data, lars }) => {
        // lars?.sort((a, b) => b.id - a.id);
        lars?.sort((a, b) =>
          activeSortingOption === 'last_update'
            ? new Date(a.last_update) - new Date(b.last_update)
            : b[activeSortingOption] - a[activeSortingOption],
        );
        setData({
          auction: auction_data,
          items: lars,
          ready: true,
        });

        if ((isRerender || !+activeItemId) && !isEmpty(lars) && !isPreviewClosed) {
          setActiveItemId(lars[0].id);
        }

        if (!asideWithTabs && isRerender && isEmpty(lars)) {
          history.push('/onboarding/live_auctions');
        }
      });
    },
    [API, history, actionType, activeItemId, activeSortingOption],
  );

  useEffect(updateItemList, [updateItemList]);
  useEffect(() => {
    const interval = setTimeout(() => {
      updateItemList();
    }, 2000);

    return () => clearInterval(interval);
  }, [updateItemList, timestamp]);

  /* Watch for new events */
  // TODO: The whole sub/unsub system is super wonky, I guarantee it has bugs
  const socket = useMemo(() => io(`${process.env.REACT_APP_API_URL}/auction`, { query: { jwt: token } }), [token]);

  useEffect(() => {
    if (!token) {
      return;
    }

    socket.on('connect', () => {
      socket.emit('connect_to_auction');
    });

    socket.on('auction_updates', ({ action, lar_data }) => {
      switch (action) {
        case 'auction_started':
          // TODO: Insert new item in the correct place
          // depending on the selected sorting method
          setData((prev) => ({
            ...prev,
            items: [...prev.items, lar_data],
          }));
          break;

        // TODO: Need an event for auction cancel/finish
        case 'auction_updated':
        case 'new_bid':
        case 'bid_updated':
        case 'bid_deleted':
        default:
          // TODO: Handle these events
          console.log('Live auctions ws unhandled event');
      }
    });

    socket.connect();
    return () => {
      socket.emit('disconnect_from_auction');
      socket.disconnect();
    };
  }, [socket, activeItemId, token]);

  function setActiveItemId(id) {
    if (Number(activeItemId) === id) {
      return;
    }

    history.push(`/onboarding/live_auctions/${id}`);
  }

  function placeBidHandler() {
    setActiveDrawer({ show: '', data: null });
    updateItemList();
    updateLoanProfile();
  }

  function handleOpenBidDrawer() {
    if (activeDrawer.show) {
      return;
    }

    if (activeItem.bid_exist) {
      openPlaceBidDrawer(true);
    } else {
      openChooseBidDrawer();
    }

    toggleNav();
  }

  const handlePass = useCallback(
    ({ additional_info, already_seen, does_not_meet_criteria, more_details, not_competitive, too_busy }) => {
      larAPI
        .pass(null, additional_info, already_seen, does_not_meet_criteria, more_details, not_competitive, too_busy)
        .then(() => {
          togglePassModal();
          updateItemList();
          dispatch(updateDashboardMetricsTimestamp());
        });
    },
    [larAPI, updateItemList],
  );

  useEffect(() => {
    if (!isFinite(Number(activeItemId))) {
      return;
    }

    larAPI.getTeam().then((data) => setTeam(data));
  }, [larAPI, activeItemId]);

  function submitShare({ cc, message, send_copy_to_self, to }) {
    larAPI.share(cc, message, send_copy_to_self, to).then(() => setActiveModal({ show: '', data: null }));
  }

  const activeItem = useMemo(() => data.items.find((i) => i.id === Number(activeItemId)), [data.items, activeItemId]);

  useEffect(() => {
    if (activeItem && queryParams.get('quote') === 'open') {
      handleOpenBidDrawer();
    }
  }, [activeItem]);

  const handleSort = (type) => {
    const items = [...data.items];
    items.sort((a, b) =>
      type === 'last_update' ? new Date(a.last_update) - new Date(b.last_update) : b[type] - a[type],
    );
    setData((prev) => ({
      ...prev,
      items,
    }));
  };

  return (
    <MainLayout title="Active Auctions">
      <div
        className={classNames(styles.root, {
          [styles.noNavRoot]: !showNav,
          [styles.smallContentWidth]: activeDrawer.show,
        })}
      >
        <LiveAuctionsHeader
          isShowNav={showNav}
          onSort={handleSort}
          onToggleNav={toggleNav}
          activeSortingOption={activeSortingOption}
          setActiveSortingOption={setActiveSortingOption}
          sortOptions={sortOptions}
        />
        {showNav && (
          <LiveAuctionsAside
            role={role}
            items={data.items}
            isSpinning={!data.ready}
            activeItemId={activeItem?.id}
            isPreviewClosed={isPreviewClosed}
            actionType={actionType}
            onSetActionType={setActionType}
            onSetActiveItemId={setActiveItemId}
          />
        )}
        {activeItem ? (
          <>
            <ActionsBar
              id={activeItem.id}
              auction={data.auction}
              bidPlaced={activeItem.bid_exist}
              canSendMessage={activeItem.can_send_message}
              immediateAuction={activeItem?.started_now}
              onSetActiveDrawer={setActiveDrawer}
              onSetActiveModal={setActiveModal}
              onTogglePassModal={togglePassModal}
              onOpenBidDrawer={handleOpenBidDrawer}
            />
            <main>
              <PublicMessages larId={activeItem.id} />
              <LoanProfile showBids forceProfileUpdate={forceProfileUpdate} />
            </main>
          </>
        ) : (
          <LiveAuctionsPlaceholder isUpcoming={actionType === 'upcoming'} auction={data.auction} />
        )}
        <ChooseBidDrawer
          activeDrawer={activeDrawer}
          onClose={closeActiveDrawer}
          openPlaceBidDrawer={openPlaceBidDrawer}
          visible={activeDrawer.show === 'choose_bid'}
        />
        <PlaceBidDrawer
          activeDrawer={activeDrawer}
          edit={activeDrawer.show === 'edit_bid'}
          onClose={closeActiveDrawer}
          onPlaceBid={placeBidHandler}
          openChooseBidDrawer={openChooseBidDrawer}
          visible={['place_bid', 'edit_bid'].includes(activeDrawer.show)}
        />
        <PostMessageDrawer API={larAPI} onClose={closeActiveDrawer} visible={activeDrawer.show === 'post_message'} />
        <ShareLoanProfileModal
          larId={activeItem?.id}
          onCloseActiveModal={() => setActiveModal({ show: '', data: null })}
          submitShare={submitShare}
          team={team}
          visible={activeModal.show === 'share_profile'}
        />
        <PassAuctionModal
          handlePassAuction={handlePass}
          onCloseActiveModal={togglePassModal}
          rescind={activeItem?.bid_exist}
          visible={showPassModal}
        />
        {isLender && data.ready && (
          <>
            <AuctionEndPopup />
            <AuctionTutorial />
            <AuctionPreviewPopup />
          </>
        )}
      </div>
    </MainLayout>
  );
}

export default LiveAuctions;
