import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { ReplyBigIcon } from 'assets/icons/icons';
import ChatAttachFile from '../../ChatAttachFile';

import styles from './MessageItem.module.css';

export default function MessageItem({ message, sender, time, hasNestedMessage }) {
  const { chatState } = useSelector((s) => s.user);
  const isViewOnly = chatState?.mode === 'viewOnly';

  return (
    <>
      {message.type === 'text' && (
        <div className="message-text">
          <Row gutter={20} wrap={false} className={styles.row} justify="space-between">
            <Col>{isViewOnly && sender && <h4 className="messages-author">{sender}</h4>}</Col>
            <Col>
              <span className="message-time">{time}</span>
            </Col>
          </Row>
          {hasNestedMessage && <ReplyBigIcon className="reply-icon" />}
          <span>{message.body}</span>
        </div>
      )}
      {message.type === 'audio' && (
        <>
          <Row gutter={20} wrap={false} className={styles.row} justify="space-between">
            <Col>{isViewOnly && sender && <h4 className="messages-author">{sender}</h4>}</Col>
            <Col>
              <span className="message-time">{time}</span>
            </Col>
          </Row>
          <audio
            className="chat-audio"
            controls
            onLoadedData={({ target }) => {
              target.currentTime = 999;
              setTimeout(() => (target.currentTime = 0), 150);
            }}
            src={message.body}
          />
        </>
      )}
      {message.type === 'document' && message?.documents?.length > 0 && (
        <div className={classNames(styles.filesWrap, styles.filesWrapColumn)}>
          <Row gutter={20} wrap={false} className={styles.row} justify="space-between">
            <Col>{isViewOnly && sender && <h4 className="messages-author">{sender}</h4>}</Col>
            <Col>
              <span className="message-time">{time}</span>
            </Col>
          </Row>
          {message?.body}
          {message.documents.map((document) => (
            <ChatAttachFile key={document.path} name={document.name} size={document.size} preview={document.path} />
          ))}
        </div>
      )}
    </>
  );
}
