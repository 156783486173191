const initialLarProgress = {
  project: false,
  property: false,
  address: false,
  property_income_and_expense: false,
  business: false,
  product: false,
  borrower_funds: false,
  borrower: false,
  credit_history: false,
  co_borrowers: false,
};
export default initialLarProgress;
