import { Button, Col, Modal, Row } from 'antd';
import { useReducer, useMemo } from 'react';
import getBidValuesData from 'constants/getBidValuesData';
import styles from './styles/ScenarioBid.module.css';
import QuoteTermsRowItem from 'components/Quotes/QuoteTermsRowItem';
import Box from 'components/UI/Box';
import QuoteRowItem from 'components/Quotes/QuotesItem/QuoteRowItem';

function ScenarioBid({ meta = {} }) {
  const [termsPopupVisible, toggleTermsPopupVisible] = useReducer((s) => !s, false);

  const bidValuesData = useMemo(() => getBidValuesData(meta.loan_purpose, meta.loan_term_name, meta.rate_type), [meta]);

  return (
    <>
      <Row>
        <Col flex="209px">
          <ul className={styles.list}>
            <QuoteTermsRowItem key={name}>
              <strong>Quote terms:</strong>
            </QuoteTermsRowItem>
            {bidValuesData.map(({ name, value }) =>
              meta[value] ? <QuoteTermsRowItem key={name}>{name}</QuoteTermsRowItem> : null,
            )}
          </ul>
        </Col>
        <Col flex="238px">
          <ul className={styles.list}>
            <QuoteTermsRowItem />
            <Box hor="sm" ver="xs">
              {bidValuesData.map(({ format, value }) =>
                meta[value] ? (
                  <QuoteRowItem key={value}>{(format ? format(meta[value]) : meta[value]) || 'N/A'}</QuoteRowItem>
                ) : null,
              )}
              {meta.additional_terms_and_comment && (
                <QuoteRowItem>
                  <Button type="link" onClick={toggleTermsPopupVisible} className={styles.button}>
                    View Additional Terms
                  </Button>
                </QuoteRowItem>
              )}
            </Box>
          </ul>
        </Col>
      </Row>
      <Modal
        centered
        footer={null}
        onCancel={toggleTermsPopupVisible}
        title="Additional terms"
        open={termsPopupVisible}
      >
        <div dangerouslySetInnerHTML={{ __html: meta?.additional_terms_and_comment }} />
      </Modal>
    </>
  );
}

export default ScenarioBid;
