import { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ActionListItem from './ActionListItem';
import styles from './AuctionsList.module.css';

export default function AuctionsList({ isUpcoming, isPreviewClosed, items, activeItemId, onSetActiveItemId }) {
  const sortedItems = isUpcoming
    ? items
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .reduce((acc, cur) => {
          const k = cur.date;
          if (!acc[k]) {
            acc[k] = [cur];
          } else {
            acc[k].push(cur);
          }

          return acc;
        }, {})
    : items;

  return items.length > 0 ? (
    <ul className={styles.root}>
      {Array.isArray(sortedItems) ? (
        <>
          {sortedItems.map((item) => (
            <ActionListItem
              hasTooltip
              data={item}
              key={item.id}
              isPreviewClosed={isPreviewClosed}
              viewOnly={isUpcoming || isPreviewClosed}
              isActive={item.id === activeItemId}
              onClick={onSetActiveItemId}
            />
          ))}
        </>
      ) : (
        <>
          {Object.keys(sortedItems).map((k) => (
            <Fragment key={k}>
              {k && (
                <>
                  <li className={styles.date}>
                    <time>{moment(k).format('dddd DD MMM')}</time>
                  </li>
                  {sortedItems[k].map((item) => (
                    <ActionListItem
                      key={item.id}
                      data={item}
                      isPreviewClosed={isPreviewClosed}
                      viewOnly={isUpcoming || isPreviewClosed}
                      isActive={item.id === activeItemId}
                      onClick={onSetActiveItemId}
                    />
                  ))}
                </>
              )}
            </Fragment>
          ))}
        </>
      )}
    </ul>
  ) : (
    <div className={styles.empty}>New active auctions will appear here.</div>
  );
}

AuctionsList.propTypes = {
  activeItemId: PropTypes.number,
  onSetActiveItemId: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
};
