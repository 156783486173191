import { Spin, Tabs } from 'antd';
import PropTypes from 'prop-types';
import AuctionsList from './AuctionsList';
import styles from './LiveAuctionsAside.module.css';

function LiveAuctionsAside({
  isSpinning,
  actionType,
  activeItemId,
  items,
  isPreviewClosed,
  role,
  onSetActiveItemId,
  onSetActionType,
}) {
  const asideWithTabs = /lender|broker/i.test(role);

  const tabItems = [
    {
      key: 'today',
      label: 'Today',
      children: (
        <AuctionsList
          isPreviewClosed={isPreviewClosed}
          items={items}
          activeItemId={activeItemId}
          onSetActiveItemId={onSetActiveItemId}
        />
      ),
    },
    {
      key: 'upcoming',
      label: 'Upcoming',
      children: (
        <AuctionsList
          isUpcoming
          isPreviewClosed={isPreviewClosed}
          items={items}
          activeItemId={activeItemId}
          onSetActiveItemId={onSetActiveItemId}
        />
      ),
    },
  ];

  const handleClickTab = (v) => {
    onSetActionType(v);
  };

  return (
    <Spin spinning={isSpinning}>
      <nav className={styles.root}>
        {asideWithTabs ? (
          <Tabs className={styles.tabs} defaultActiveKey={actionType} items={tabItems} onChange={handleClickTab} />
        ) : (
          <AuctionsList
            items={items}
            isPreviewClosed={isPreviewClosed}
            activeItemId={activeItemId}
            onSetActiveItemId={onSetActiveItemId}
          />
        )}
      </nav>
    </Spin>
  );
}

LiveAuctionsAside.propTypes = {
  role: PropTypes.string,
  activeItemId: PropTypes.number,
  onSetActionType: PropTypes.func,
  onSetActiveItemId: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default LiveAuctionsAside;
