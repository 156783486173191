import { Form, InputNumber } from 'antd';

function Units({ rentalUnits, totalUnitsRange: [min, max] }) {
  return (
    <>
      <Form.Item label="Total Units" name="number_of_rental_units">
        <InputNumber min={min} max={max} style={{ width: 90 }} />
      </Form.Item>
      <Form.Item label="Units currently occupied" name="number_of_rental_units_occupied">
        <InputNumber min={0} defaultValue={0} max={rentalUnits} style={{ width: 90 }} />
      </Form.Item>
    </>
  );
}

export default Units;
