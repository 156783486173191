import { Button, Checkbox, Table, Select, message } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useMemo, useReducer, useState } from 'react';
import API from 'api';
import CustomButton from 'components/UI/Button';
import classes from './styles/ImportMasterlist.module.css';

class ColumnBueprint {
  constructor(i, title, onChange) {
    function handleChange(newValue = 'Select') {
      onChange({ i, newValue });
    }

    const options = title !== 'Name' ? ['Description', 'Name', 'Select'] : ['Name'];

    this.className = title === 'Select' ? 'disabled-column' : '';
    this.key = i;
    this.title = (
      <Select value={title} onChange={handleChange}>
        {options.map((option) => (
          <Select.Option value={option} key={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

const lengthLimits = {
  description: 400,
  name: 255,
};

const maxColumns = 9;

function cropString(str, maxLength) {
  return str.length <= maxLength ? str : `${str.substring(0, maxLength - 1)}…`;
}

export default function ImportMasterlist() {
  const [hasHeaders, setHasHeaders] = useState(false);
  const location = useLocation();
  const [selectedColumns, selectColumn] = useReducer(
    (state, { i, newValue }) =>
      state.map((val, j) => {
        if (i === j) {
          return newValue;
        }

        if (val !== newValue) {
          return val;
        }

        return 'Select';
      }),
    location.state.CSVData[0].map((_, i) => (i ? 'Select' : 'Name')),
  );
  const [viewportOffsetX, amendViewportOffsetX] = useReducer((state, mod) => state + mod, 0);
  const history = useHistory();

  const columns = useMemo(
    () =>
      selectedColumns.slice(viewportOffsetX, viewportOffsetX + maxColumns).map((title, i) => ({
        ...new ColumnBueprint(viewportOffsetX + i, title, selectColumn),
        dataIndex: i,
      })),
    [selectedColumns, viewportOffsetX],
  );

  const dataSource = useMemo(
    () =>
      // Get first 5 rows and add keys to remove the warning
      location.state.CSVData.slice(...(hasHeaders ? [1, 6] : [0, 5]))
        .map((row) => row.slice(viewportOffsetX, viewportOffsetX + maxColumns))
        .map((row, i) => ({ ...row, key: i })),
    [location.state.CSVData, hasHeaders, viewportOffsetX],
  );

  function submitData() {
    const documents = location.state.CSVData.map((row, i) => {
      if (hasHeaders && i === 0) {
        return null;
      }

      const obj = {};

      for (const [i, value] of row.entries()) {
        if (selectedColumns[i] !== 'Select') {
          const maxLength = lengthLimits[selectedColumns[i].toLowerCase()];
          obj[selectedColumns[i].toLowerCase()] = maxLength ? cropString(value, maxLength) : value;
        }
      }

      return obj;
    }).filter(Boolean);

    API()
      .post('/user_settings/masterlist/import', { documents })
      .then(({ data }) => {
        message.success(`File was imported successfully. ${documents.length} items were added to your masterlist.`);
        history.push(`/onboarding/masterlists/${data?.masterlist_id}/edit`);
      })
      .catch((err) => {
        message.error('There was a problem importing the file. Please try again or contact support');
        console.error(err);
      });
  }

  return (
    <div className={classes.root}>
      <h1>Map Masterlist CSV</h1>
      {/* TODO: A wild lorem ipsum appeared */}
      <p>
        Use the pulldown menu on the header row to identify which columns in your imported file correspond to the item’s
        name and to the description. Only these fields will be imported to Starport.
      </p>
      <Checkbox
        checked={hasHeaders}
        disabled={location.state.CSVData.length < 2}
        onChange={({ target: t }) => setHasHeaders(t.checked)}
      >
        Ignore first line
      </Checkbox>
      <CustomButton text="Cancel" onClick={() => history.push('/onboarding/masterlists')} />
      <CustomButton primary text="Next" onClick={submitData} />
      <Table dataSource={dataSource} columns={columns} pagination={false} />
      <div className={classes.leftRightButtons}>
        <Button
          disabled={viewportOffsetX < 1}
          icon={<LeftOutlined />}
          onClick={() => amendViewportOffsetX(-1)}
          type="primary"
        />
        <Button
          disabled={location.state.CSVData[0].length <= viewportOffsetX + maxColumns}
          icon={<RightOutlined />}
          onClick={() => amendViewportOffsetX(1)}
          type="primary"
        />
      </div>
    </div>
  );
}
