import { BinIcon } from 'assets/icons';
import { Form, Select, InputNumber, Input } from 'antd';
import styles from '../LARDBFeesWorksheet.module.css';
import readableFields from '../readableFields';
import getTotal from '../getTotal';

function ExtendedRow({ id, form, currencyInputParams = {}, additional = false, onChange = () => {} }) {
  const names = {
    field: additional ? `${id}.additional_field.${additional.i}` : id,
    period: additional ? `${id}.additional_field.${additional.i}.period` : `${id}.period`,
    periodQuantity: additional ? `${id}.additional_field.${additional.i}.quantity` : `${id}.quantity`,
  };
  const fieldValue = Form.useWatch(names.field, form);
  const periodValue = Form.useWatch(names.period, form);
  const periodQuantityValue = Form.useWatch(names.periodQuantity, form);

  return (
    <>
      {additional ? (
        <div className={styles.rowWithBin}>
          <Form.Item className={styles.bigFormGridHHeader} name={`${id}.additional_field_name.${additional.i}`}>
            <Input className={styles.customOtherInput} defaultValue="Other" />
          </Form.Item>
          {currencyInputParams.disabled || <BinIcon onClick={() => additional.delete(id, additional.i)} />}
        </div>
      ) : (
        <div className={styles.bigFormGridHHeader}>{readableFields.get(id)}</div>
      )}
      <Form.Item name={names.field}>
        <InputNumber {...currencyInputParams} />
      </Form.Item>
      <Form.Item name={names.period} initialValue="months">
        <Select
          onChange={onChange}
          value={periodValue}
          defaultValue="months"
          options={[
            { value: 'months', label: 'Months' },
            { value: 'days', label: 'Days' },
          ]}
        />
      </Form.Item>
      <Form.Item name={names.periodQuantity}>
        <InputNumber />
      </Form.Item>
      <div className={styles.notAnInput}>{getTotal(fieldValue, periodValue, periodQuantityValue)}</div>
    </>
  );
}

export default ExtendedRow;
