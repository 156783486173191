import { message } from 'antd';
import { fieldValidator, scrollToField } from './larHelpers';

const showFormErrors = (forms, { isRefinance, isPurchaseLong, isPurchaseShort }) => {
  let scrollCount = 0;
  Object.entries(forms).forEach(([formName, formInstance]) => {
    formInstance.validateFields().then((values) => {
      formInstance.setFields(
        Object.entries(values).map(([fieldName, fieldValue]) => {
          const validatedObj = fieldValidator(
            formName,
            {
              name: fieldName,
              value: fieldValue,
              errors: [],
            },
            { isRefinance, isPurchaseLong, isPurchaseShort },
            values,
            true,
          );
          if (scrollCount === 0 && validatedObj.errors.length) {
            scrollToField(formInstance, fieldName);
            scrollCount++;
          }
          return validatedObj;
        }),
      );
    });
  });
  message.error('Please complete all required fields');
};

export default showFormErrors;
