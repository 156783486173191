export const acceptedMimes = {
  diligenceDocs: [
    'application/pdf', // PDF
    'application/vnd.ms-excel', // Excel
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel
    'application/wps-office.xlsx', // Excel
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word
    'application/msword', // Word
    'application/vnd.oasis.opendocument.text', // Word
    'image/png', // PNG
    'images/vnd.mozilla.apng', // PNG
    'image/jpeg', // JPEG
    'text/csv', // CSV
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX
    'application/vnd.ms-powerpoint', // PPTX
  ],

  avatar: ['image/jpeg', 'image/png'],

  larMedia: ['image/jpeg', 'image/png', 'video/mp4'],

  larDocs: [
    'application/pdf', // PDF
    'application/vnd.ms-excel', // Excel
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel
    'application/wps-office.xlsx', // Excel
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word
    'application/msword', // Word
    'application/vnd.oasis.opendocument.text', // Word
    'text/csv', // CSV
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX
    'application/vnd.ms-powerpoint', // PPTX
  ],

  masterlist: ['text/csv', 'application/csv'],
};
