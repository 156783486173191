import AuthPoster from '../../AuthPoster';
import styles from './InviteCanceled.module.css';

function InviteCanceled() {
  return (
    <div className={styles.section}>
      <AuthPoster title="This link is no longer active. Please contact your originator" />
    </div>
  );
}

export default InviteCanceled;
