import { useEffect } from 'react';
import { Form, InputNumber } from 'antd';

function FormItem({ category_name, id, type, value, required, form, setRequiredFields, onFieldsChange }) {
  const currValue = Form.useWatch([category_name, 'value', type], form);

  useEffect(() => {
    if (required) {
      setRequiredFields((prevState) => [...prevState, [category_name, type].join('_')]);
    }
    // eslint-disable-next-line
  }, []);

  const setField = async (category_name, type, i) => {
    await form.setFields([
      {
        name: [category_name, 'value', type],
        value: i / 12,
      },
    ]);
  };

  return (
    <Form.Item label={`${category_name} ${required ? '(required)' : ''}`} id={id}>
      <Form.Item
        initialValue={value === 0 ? null : value}
        name={[category_name, 'value', type]}
        style={{
          display: 'inline-block',
          marginBottom: 0,
        }}
      >
        <InputNumber
          formatter={(value) => `${Math.floor(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          min={0}
          max={999999}
          style={{ width: 215 }}
          addonBefore="$"
        />
      </Form.Item>
      <Form.Item
        style={{
          display: 'inline-block',
          marginBottom: 0,
          marginLeft: 30,
        }}
      >
        <InputNumber
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          value={currValue ? currValue * 12 : null}
          onChange={(i) => {
            setField(category_name, type, i).then(() => {
              const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
                name: [el[0], 'value', el[1].type],
                value: el[1].value[el[1].type],
              }));
              onFieldsChange([1], valuesArr);
            });
          }}
          max={999999 * 12}
          style={{ width: 215 }}
          addonBefore="$"
        />
      </Form.Item>
      <Form.Item
        initialValue={id}
        name={[`${category_name}`, 'id']}
        style={{
          display: 'none',
        }}
      >
        <InputNumber style={{ width: 215 }} addonBefore="$" />
      </Form.Item>
      <Form.Item
        initialValue={required}
        name={[`${category_name}`, 'required']}
        style={{
          display: 'none',
        }}
      />
      <Form.Item
        initialValue={type}
        name={[`${category_name}`, 'type']}
        style={{
          display: 'none',
        }}
      />
    </Form.Item>
  );
}

export default FormItem;
