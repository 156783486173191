import React from 'react';
import { Route } from 'react-router-dom';
import Navigations from './Navigations/index';

const MainOnboardingNavigation = (props) =>
  Navigations.mainNavigation.map((route, index) => (
    <Route path={route.path} exact {...props} component={route.main} key={index} />
  ));

export default MainOnboardingNavigation;
