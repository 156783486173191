import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Navigations from 'routers/Navigations/index';
import PageHeader from 'components/OnBoarding/PageHeader/PageHeader';
import starportLogo from 'assets/images/starportLogo.png';
import Logo from '../../../components/OnBoarding/Logo';
import { openPopup } from '../../../store/actions/popupsActions';
import { isRole } from '../../../constants/isRole';

export default function MainRouter() {
  const isConfirmPage = window.location.href.includes('/confirm');
  const dispatch = useDispatch();
  const isBDR = isRole('BDR');
  const history = useHistory();

  const handleClick = () => {
    if (isBDR) {
      dispatch(openPopup({ show: 'exit', data: {} }));
    } else {
      history.push('/');
    }
  };

  return (
    <>
      {isConfirmPage ? (
        <img src={starportLogo} alt="" style={{ width: '100%', alignSelf: 'center', padding: '0 8px' }} />
      ) : (
        <Logo onClick={handleClick} />
      )}
      <PageHeader registration />
      <div className="main-registration-wrapper-page">
        {Navigations.mainNavigationRegisterBroker.map((route) => (
          <Route path={route.path} exact component={route.main} key={route.path} />
        ))}
      </div>
    </>
  );
}
