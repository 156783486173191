import styles from './QuoteTermsArrows.module.css';
import IconButton from '../../../../UI/IconButton';
import leftGreyIcon from '../../../../../assets/icons/arrow-grey-left.svg';
import rightGreyIcon from '../../../../../assets/icons/arrow-grey-right.svg';

function QuoteTermsArrows({ prevSlide, nextSlide }) {
  return (
    <div className={styles.arrows}>
      <IconButton icon={leftGreyIcon} alt="left" classes="slider-arrow" handleClick={prevSlide} />
      <IconButton icon={rightGreyIcon} alt="left" classes="slider-arrow" handleClick={nextSlide} />
    </div>
  );
}

export default QuoteTermsArrows;
