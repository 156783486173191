import React, { useEffect, useState } from 'react';
import './styles/bank_fee_info.css';
import { useHistory } from 'react-router-dom';
import API from '../../../../api';
import siteName from '../../../../constants/siteName';

function BankFeeInfo() {
  const [data, setData] = useState({ loading: false });
  const history = useHistory();

  useEffect(() => {
    API()
      .get('/superadmin/settings/bank_and_fee_info')
      .then((res) => {
        setData({
          loading: true,
          ...res.data,
        });
      });
  }, []);

  function UniqueSection({ preview, value }) {
    return (
      <div className="every">
        <span className="preview">{preview}</span>
        <span className="value">{value}</span>
      </div>
    );
  }

  return (
    <div className="wrapper_bank_fee_info">
      <div className="wrapper_bank_fee_info__form">
        <div className="title">
          <span>{data.loading && data.bank_name}</span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => history.push('/onboarding/admin-settings/bank_fee_info/edit')}
          >
            <path
              d="M2.66666 13.3333H5.33333L12.3333 6.33333C12.687 5.97971 12.8856 5.50009 12.8856 5C12.8856 4.4999 12.687 4.02029 12.3333 3.66666C11.9797 3.31304 11.5001 3.11438 11 3.11438C10.4999 3.11438 10.0203 3.31304 9.66666 3.66666L2.66666 10.6667V13.3333Z"
              stroke="#595959"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M9 4.33337L11.6667 7.00004" stroke="#595959" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="info">
          <UniqueSection preview="FULL ADDRESS" value={data.loading ? data.bank_address : null} />
          <UniqueSection preview="PHONE NUMBER" value={data.loading ? data.phone_number : null} />
          <UniqueSection preview="BANK CONTACT NAME" value={data.loading ? data.bank_contact_name : null} />
          <UniqueSection preview="ACCOUNT NUMBER" value={data.loading ? data.account_number : null} />
          <UniqueSection preview="ABA ROUTING NUMBER" value={data.loading ? data.aba_routing_number : null} />
          <UniqueSection preview="SWIFT" value={data.loading ? data.swift : null} />
          <UniqueSection
            preview={`${siteName.toLocaleUpperCase()} FEE`}
            value={data.loading ? data.iharmoni_default_success_fee : null}
          />
          <UniqueSection
            preview={`${siteName.toLocaleUpperCase()} MINIMUM FEE`}
            value={data.loading ? data.iharmoni_minimum_fee : null}
          />
          <UniqueSection
            preview={`${siteName.toLocaleUpperCase()} PROCESSING FEE`}
            value={data.loading ? data.iharmoni_processing_fee : null}
          />
        </div>
      </div>
    </div>
  );
}

export default BankFeeInfo;
