import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import API from 'api';
import { LOAN_PROCESSING, QUOTE_SELECTION } from 'constants/larApp';

import Loader from '../../../components/Loader';
import LoanProfileContent from './LoanProfileContent/LoanProfileContent';
import BidTable from '../../../components/LoanProfile/BidTable';

import styles from './LoanProfile.module.css';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import Keys from '../../../constants/helper';

export default function LoanProfile({ showBids }) {
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  const [bids, setBids] = useState(null);
  const [order, setOrder] = useState({ by: 'largest_loan_amount' });
  const userAddedBid = useMemo(() => bids?.bids?.find((bid) => bid.is_owner), [bids]);
  const history = useHistory();
  const role = useSelector((s) => s.user.userData?.role);
  const isBroker = /broker/i.test(role);
  const token = localStorage.getItem(Keys.JWT_TOKEN);
  const socket = useMemo(() => io(`${process.env.REACT_APP_API_URL}/auction`, { query: { jwt: token } }), [token]);

  function handleChangeBidsOrder(value) {
    setBids((prev) => ({
      ...prev,
      loading: false,
    }));

    setOrder({
      by: value,
    });
  }

  const getBids = useCallback(
    () =>
      API()
        .get(`/LAR/${/broker/i.test(role) ? 'quotes/broker' : 'bid_process/lender/bids'}`, {
          params: {
            lar_id: +id,
            order_by: !/broker/i.test(role) ? order.by : undefined,
          },
        })
        .then(({ data }) => {
          setBids(() => ({
            ...data,
            loading: true,
          }));
        })
        .catch(({ response }) => {
          if (response?.data?.error === 'need bid') {
            setBids({
              loading: false,
            });
          }
        }),
    [id, order, role],
  );

  function goToEditBid() {
    history.push(`/bid_application/lar_id_${id}/edit_bid`);
  }

  function handleNewBids() {
    setBids((prev) => {
      if (Array.isArray(prev.bids)) {
        return {
          ...prev,
          bids: prev.bids.map((bid) => ({
            ...bid,
            newBid: false,
          })),
        };
      }
    });
  }

  const getLARProfileData = useCallback(() => {
    setIsFetching(true);

    API()
      .get('/LAR/loan_profile/lender', { params: { id } })
      .then((res) => setData(res.data))
      .catch((e) => setError(e?.response?.data?.error))
      .finally(() => setIsFetching(false));
  }, [id]);

  useEffect(() => {
    getLARProfileData();
  }, [id]);

  useEffect(() => {
    getBids();
  }, [getBids, getLARProfileData]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL, { query: { jwt: token } });

    // eslint-disable-next-line
    socket.connect();
    // eslint-disable-next-line
    socket.on('new_mcm_message', () => console.log('connected'));

    return () => socket.disconnect();
  }, [token]);

  useEffect(() => {
    if (!token) {
      return;
    }

    socket.on('connect', () => {
      socket.emit('connect_to_auction');
    });

    socket.on('auction_updates', ({ action, bid }) => {
      switch (action) {
        case 'new_bid':
          setBids((prev) => {
            if (Array.isArray(prev.bids)) {
              return {
                ...prev,
                bids: [...prev.bids, { ...bid, newBid: true }],
              };
            }

            return {
              ...prev,
              bids: [{ ...bid, newBid: true }],
            };
          });
          break;
        default:
          return null;
      }
    });

    socket.connect();
    return () => {
      socket.emit('disconnect_from_auction');
      socket.disconnect();
    };
  }, [socket, token]);

  useEffect(() => {
    API().get('/LAR/loan_profile/view', {
      params: {
        id,
      },
    });
  }, []);

  const sortedBids = useMemo(() => {
    const quotes = bids?.bids || [];

    switch (order.by) {
      case isBroker && 'largest_loan_amount': {
        return quotes.sort((quoteA, quoteB) => quoteB.loan_amount - quoteA.loan_amount);
      }
      case isBroker && 'last_update': {
        return quotes.sort((quoteA, quoteB) => Date.parse(quoteB.updated_at) - Date.parse(quoteA.updated_at));
      }
      case isBroker && 'lowest_interest_rate': {
        return quotes.sort((quoteA, quoteB) => quoteA.interest_rate - quoteB.interest_rate);
      }
      default:
        return quotes;
    }
  }, [bids?.bids, order]);

  return (
    <div className={styles.wrapper}>
      {showBids && (
        <>
          {data?.statuses?.bids !== 0 &&
            bids?.bids &&
            ((/broker/i.test(role) && ![LOAN_PROCESSING, QUOTE_SELECTION].includes(data?.statuses?.status)) ||
              userAddedBid) && (
              <BidTable
                bids={sortedBids}
                goToEditBid={goToEditBid}
                handleChangeBidsOrder={handleChangeBidsOrder}
                loading={bids?.loading}
                loan_purpose={data?.statuses?.loan_purpose}
                loan_term={data?.statuses?.loan_term}
                orderBy={order}
                handleNewBids={handleNewBids}
              />
            )}
        </>
      )}
      {isFetching ? <Loader active={isFetching} /> : error || <LoanProfileContent data={data} />}
    </div>
  );
}
