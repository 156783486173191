import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../../components/OnBoarding/SectionTitle';
import '../../BDR/styles/prospects.css';
import { Table, Tabs } from 'antd';
import API from '../../../../api';
import PaginationItemRender from '../../../../constants/PaginationItemRender';
import { useHistory } from 'react-router-dom';

const { TabPane } = Tabs;

function Companies() {
  const history = useHistory();
  const activeCompaniesTab = history?.location?.state?.companiesTab;
  const [companies, setCompanies] = useState({ loading: false });
  const [activeTab, setActiveTab] = useState(activeCompaniesTab || 'Brokers');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const columns = [
    {
      title: 'Company name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '# of users',
      dataIndex: '#_of_users',
      key: '#_of_users',
    },
    {
      title: 'Admin',
      dataIndex: 'admins',
      key: 'admins',
      render: (data) =>
        data.length === 0 ? null : (
          <>
            {data.map((el, idx) => (
              <div key={idx}>{el}</div>
            ))}
          </>
        ),
    },
    {
      title: 'Account Created',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Referred by',
      dataIndex: 'referred_by',
      key: 'referred_by',
    },
    {
      title: 'Starport Account Owner',
      dataIndex: 'owner',
      key: 'owner',
    },
  ];

  const tabCallback = (val) => {
    setActiveTab(val);
    setPagination((state) => ({
      ...state,
      current: 1,
    }));
    history.push({
      state: { companiesTab: val },
    });
  };

  useEffect(() => {
    API()
      .get('/superadmin/companies', {
        params: {
          page: pagination.current,
          per_page: pagination.pageSize,
          user_type: activeTab,
        },
      })
      .then((res) => {
        setPagination((prevState) => ({ ...prevState, total: res.data.total }));
        setCompanies({
          loading: true,
          total: res.data.total,
          companies: res.data.companies.map((i, index) => ({ ...i, index })),
        });
      });
    // eslint-disable-next-line
  }, [activeTab, pagination.current, pagination.pageSize]);

  return (
    <div className="prospects_page_wrapper table_with_header_wrapper table_with_pagination_wrapper">
      <div className="section_title_wrapper">
        <SectionTitle title="Companies" />
      </div>
      <div className="section_table_wrapper table_with_header table_with_pagination">
        <Table
          columns={columns}
          pagination={{
            showSizeChanger: true,
            defaultCurrent: pagination.current,
            total: pagination.total ?? null,
            itemRender: PaginationItemRender,
          }}
          onChange={(e) => setPagination(e)}
          scroll={{ x: 1500 }}
          rowKey="id"
          title={() => (
            <Tabs defaultActiveKey={activeTab} onChange={tabCallback}>
              <TabPane tab="Brokers" key="Brokers" />
              <TabPane tab="Lenders" key="Lenders" />
            </Tabs>
          )}
          onRow={(record) => ({
            onClick: () => {
              history.push({
                pathname: `/onboarding/companies/${record.id}`,
                state: { companiesTab: activeTab },
              });
            },
          })}
          border
          bordered={false}
          dataSource={companies.companies}
        />
      </div>
    </div>
  );
}

export default Companies;
