const diligenceStatuses = [
  {
    title: 'Owed',
    value: 'owed',
    colour: 'purple',
    group: 0,
  },
  {
    title: 'MLO Review',
    value: 'pending_verification',
    colour: 'yellow',
    group: 0,
  },
  {
    title: 'Lender Ready',
    value: 'lender_ready',
    colour: 'green',
    group: 0,
  },
  {
    title: 'Lender Review',
    value: 'pending_lender_review',
    colour: 'blue',
    group: 1,
  },
  {
    title: 'Lender Accepted',
    value: 'lender_approve',
    colour: 'green',
    group: 1,
  },
  {
    title: 'Archive',
    value: 'not_applicable',
    colour: 'red',
    group: 2,
  },
];

export function getPrettyStatusName(status) {
  return diligenceStatuses.find((i) => i.value === status)?.title ?? status;
}

export function getStatusColour(status) {
  return diligenceStatuses.find((i) => i.value === status)?.colour ?? 'red';
}

export default diligenceStatuses;
