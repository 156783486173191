import styles from './DashboardWidget.module.css';

function DashboardWidget(props) {
  const { title = '1', desc = 'Active scenarios', extra = null, titleFontSize = null } = props;
  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <div className={styles.title} style={titleFontSize ? { fontSize: titleFontSize } : null}>
          {title}
        </div>
        <div className={styles.desc}>{desc}</div>
        <div className={styles.extra}>{extra}</div>
      </div>
    </div>
  );
}
export default DashboardWidget;
