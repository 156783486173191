import API from '../API';

export default class BannersAPI {
  async getBanners() {
    return API()
      .get('/LAR/dashboard/LAR_banner')
      .then((res) => res?.data?.lar_banner || []);
  }

  async closeBanner(id) {
    return API().delete('/LAR/dashboard/LAR_banner', {
      params: {
        banner_id: id,
      },
    });
  }

  async createTestBanner(larId) {
    return API().post('/dev/lar_banner', { lar_id: larId });
  }
}
