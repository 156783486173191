import { capitalizeAll } from 'constants/capitalizeFirstLetter';
import { CloseIcon } from 'assets/icons';
import { isNumber } from 'lodash';
import { Button, Space } from 'antd';
import ChatAttachFile from '../ChatAttachFile';
import styles from './MessageReply.module.css';

export default function MessageReply({ activeForward, activeReply, closeActiveReply }) {
  const forward_to = activeForward?.forward_to;
  const activeCondition = activeReply || (forward_to && activeForward);

  if (!activeCondition) {
    return null;
  }

  function getName(name) {
    if (name) {
      if (isNumber(name)) {
        return `Lender #${name}`;
      }

      return capitalizeAll(name);
    }

    return 'Anonymous';
  }

  return (
    <div className={styles.root}>
      <Button type="text" className={styles.close} icon={<CloseIcon />} onClick={closeActiveReply} />
      {activeCondition ? (
        <div className={styles.inner}>
          <b>{getName(activeForward?.sender?.full_name || activeReply?.sender?.full_name)}</b>
          {activeCondition.message_data.type === 'text' ? (
            <span>{activeCondition.message_data.body}</span>
          ) : activeCondition.message_data.type === 'document' ? (
            <>
              <div>{activeCondition.message_data?.body}</div>
              <Space>
                {activeCondition.message_data.documents.map((document) => (
                  <ChatAttachFile
                    key={document?.path}
                    name={document?.name}
                    size={document?.size}
                    preview={document?.path}
                  />
                ))}
              </Space>
            </>
          ) : (
            <audio
              controls
              className="chat-audio"
              onLoadedData={({ target }) => {
                target.currentTime = 999;
                setTimeout(() => (target.currentTime = 0), 150);
              }}
              src={activeCondition.message_data.body}
            />
          )}
        </div>
      ) : null}
      <div className={styles.replyIcon}>
        <span>{forward_to ? 'Forward' : 'Reply'}</span>
      </div>
    </div>
  );
}
