import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Modal, Select, message as toast } from 'antd';
import classNames from 'classnames';

import ManualEMSModal from 'components/Modals/ManualEMSModal';
import ChatIndicator from 'components/UI/ChatIndicator';
import { rejectReasons } from 'constants/rejectReasons';
import { setChatState } from 'store/actions/userActions';

import styles from './Actions.module.css';

function Actions({
  API,
  activeItem,
  bidId,
  larId,
  updateItemList,
  borrowerAccepted,
  borrowerRejected,
  quoteRecieved = false,
}) {
  const [activeDrawer, setActiveDrawer] = useState({ show: '', data: null });
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const [reasonValidation, setReasonValidation] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  function openChat() {
    dispatch(setChatState({ bid_id: bidId, lar_id: larId, type: 'sidebar' }));
  }

  function submitDirectQuoteRequest({ message, send_copy_to_self }) {
    API.requestDirectQuote(bidId, message, send_copy_to_self).then(() => {
      closeActiveDrawer();
      updateItemList();
    });
  }

  function sendQuoteToBorrower({ message, send_copy_to_self }) {
    API.acceptQuote(bidId, message, send_copy_to_self).then(() => {
      closeActiveDrawer();
      toast.success('Selected quoted sent to borrower');
      history.push('/onboarding/dashboard');
    });
  }

  async function acceptDirectQuote() {
    setLoading(true);

    try {
      await API.acceptQuoteWithoutBorrower(bidId);
      toast.success('You have successfully accepted the quote.');
      closeActiveDrawer();
      history.push(`/onboarding/winner/${larId}`);
    } catch (error) {
      toast.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  }

  async function rejectQuotes() {
    if (!reason) {
      setReasonValidation('error');
      return;
    }

    setLoading(true);

    try {
      await API.rejectQuote(bidId, reason);
      toast.success('You have successfully rejected the quote.');
      updateItemList();
      closeActiveDrawer();
    } catch (error) {
      toast.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  }

  function handleOpenAcceptQuoteModal() {
    setActiveDrawer({ show: 'accept-quote-modal', data: null });
  }

  function handleOpenRejectQuoteModal() {
    setActiveDrawer({ show: 'reject-quote-modal', data: null });
  }

  function handleOpenSendToBorrowerModal() {
    setActiveDrawer({ show: 'send-to-borrower-modal', data: null });
  }

  function handleOpenDirectQuoteModal() {
    setActiveDrawer({ show: 'direct-quote-modal', data: null });
  }

  function closeActiveDrawer() {
    setActiveDrawer({ show: '', data: null });
  }

  return (
    <>
      <ul className={styles.actionsList}>
        {!['Quote requested'].includes(activeItem.bid_status?.name) && (
          <li>
            <Button
              size="large"
              type="primary"
              onClick={quoteRecieved || borrowerAccepted ? handleOpenAcceptQuoteModal : handleOpenDirectQuoteModal}
            >
              {quoteRecieved || borrowerAccepted ? 'Accept quote' : 'Request direct quote'}
            </Button>
          </li>
        )}
        {[
          'Quote received',
          'Quote rejected',
          'Quote sent to borrower',
          'Borrower Interested',
          'Borrower Not Interested',
        ].includes(activeItem.bid_status?.name) && (
          <li>
            <Button size="large" type="primary" onClick={handleOpenSendToBorrowerModal}>
              {borrowerRejected ? 'Resend quote to borrower' : 'Send quote to borrower'}
            </Button>
          </li>
        )}
        {['Quote received', 'Quote sent to borrower', 'Borrower Interested', 'Borrower Not Interested'].includes(
          activeItem.bid_status?.name,
        ) && (
          <li>
            <Button size="large" danger onClick={handleOpenRejectQuoteModal}>
              Reject quote
            </Button>
          </li>
        )}
        {activeItem.can_send_message && (
          <li>
            <Button
              onClick={openChat}
              icon={
                <ChatIndicator
                  isVisible
                  className={classNames(styles.chatIndicator, activeItem?.new_messages && styles.chatIndicatorActive)}
                  hasIndicator={activeItem?.new_messages}
                />
              }
              type={activeItem?.new_messages ? 'primary' : 'default'}
              size="large"
            >
              Lender Conversation
            </Button>
          </li>
        )}
      </ul>
      <ManualEMSModal
        onCancel={closeActiveDrawer}
        onSubmit={sendQuoteToBorrower}
        templateRoute={`/LAR/scenario/broker_accept_quote?bid_id=${bidId}&lar_id=${larId}`}
        visible={activeDrawer.show === 'send-to-borrower-modal'}
      />
      <ManualEMSModal
        onCancel={closeActiveDrawer}
        onSubmit={submitDirectQuoteRequest}
        templateRoute={`/LAR/scenario/provide_quote?bid_id=${bidId}&lar_id=${larId}`}
        visible={activeDrawer.show === 'direct-quote-modal'}
      />
      <Modal
        title="Are you sure you want to accept this direct quote?"
        okText="Yes"
        open={activeDrawer.show === 'accept-quote-modal'}
        onCancel={closeActiveDrawer}
        footer={[
          <Button size="large" onClick={closeActiveDrawer}>
            Cancel
          </Button>,
          <Button size="large" type="dark" onClick={acceptDirectQuote} loading={loading}>
            Yes
          </Button>,
        ]}
      />
      <Modal
        allowClear
        title="Are you sure you want to reject this direct quote?"
        okText="Yes"
        open={activeDrawer.show === 'reject-quote-modal'}
        onCancel={closeActiveDrawer}
        footer={[
          <Button size="large" onClick={closeActiveDrawer}>
            Cancel
          </Button>,
          <Button size="large" type="dark" onClick={rejectQuotes} loading={loading}>
            Yes
          </Button>,
        ]}
      >
        <Select
          showSearch
          status={reasonValidation}
          className={styles.select}
          disabled={loading}
          placeholder="Select a reason"
          optionFilterProp="children"
          onChange={(value) => setReason(value)}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          options={rejectReasons.map((reason) => ({
            label: reason,
            value: reason,
          }))}
        />
      </Modal>
    </>
  );
}

export default Actions;
