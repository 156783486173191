import React from 'react';
import './styles/preferences.css';
import { Route } from 'react-router-dom';
import Navigations from '../../../../routers/Navigations/index';

function OnboardingPreferencesBroker() {
  return (
    <div className="wrapper_preferences">
      <div className="wrapper_preferences__route">
        {Navigations.mainNavigationOnboardingPreferencesBroker.map((route, index) => (
          <Route path={route.path} exact isAuthed component={route.main} key={index} />
        ))}
      </div>
    </div>
  );
}

export default OnboardingPreferencesBroker;
