import classNames from 'classnames';
import { isNumber } from 'lodash';

import { makeAbbreviation } from 'utils/role';
import { replacedTextData } from 'constants/common';
import { capitalizeAll } from 'constants/capitalizeFirstLetter';
import { useDispatch, useSelector } from 'react-redux';
import { setChatState } from 'store/actions/userActions';

import styles from './ChatUserTab.module.css';

function ChatUserTab({ type, activeChat, user }) {
  const role = makeAbbreviation(user.user_role);
  const dispatch = useDispatch();
  const { chatState } = useSelector((state) => state.user);

  const handleActiveChat = (chat_id) => () => {
    dispatch(
      setChatState({
        chat_id,
        type: type === 'sidebar' && type,
      }),
    );
  };

  function getName(name) {
    if (name) {
      if (isNumber(name)) {
        return `Lender #${name}`;
      }
      return capitalizeAll(name);
    }
    return 'Anonymous';
  }

  const isActiveTab = activeChat.chat.chat_id === user.chat_id || chatState?.chat_id === user.chat_id;

  return (
    <div
      className={classNames(styles.larChat, isActiveTab && styles.activeLARChat)}
      onClick={handleActiveChat(user.chat_id)}
    >
      {user.user_role && <span className={styles.larChatRole}>{replacedTextData[role] || role}</span>}
      {getName(user.user_full_name)}
    </div>
  );
}

export default ChatUserTab;
