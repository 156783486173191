import { Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useMemo } from 'react';
import Modal from 'components/Modals/Modal/Modal';
import { getPrettyStatusName } from './constants/diligenceStatuses';

export default function MoveToStatusModal({ newStatus, onCancel, onSubmit, ...rest }) {
  const [form] = useForm();

  const statusLabel = useMemo(() => getPrettyStatusName(newStatus), [newStatus]);

  function handleSubmit({ reason }) {
    onSubmit(newStatus, reason) || onCancel();
  }

  return (
    <Modal {...rest} onCancel={onCancel} onOk={() => form.submit()}>
      <h2>
        Move item to
        {statusLabel}
      </h2>
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={`Why do you want to move this item to ${statusLabel}?`}
          name="reason"
          rules={[{ required: true, message: 'Please enter the reason for this action' }]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
