import { GET_FINISHED_AUCTIONS } from '../types/auctions';

const initialState = {
  quoteResults: {
    isReceived: false,
    total: 0,
  },
};

const feesWorksheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FINISHED_AUCTIONS:
      return {
        ...state,
        quoteResults: {
          ...state.quoteResults,
          isReceived: true,
          total: action.payload?.total,
        },
      };
    default:
      return state;
  }
};

export default feesWorksheetReducer;
