import LoanProfileHero from './LoanProfileHero/LoanProfileHero';
import LoanProfileRefinance from './LoanProfileRefinance';
import LoanProfilePropertyDetails from './LoanProfilePropertyDetails';
import LoanProfilePropertyIncome from './LoanProfilePropertyIncome';
import LoanProfileBorrowerInformation from './LoanProfileBorrowerInformation';
import LoanProfileConstructionDetails from './LoanProfileConstructionDetails';
import LoanProfileDocuments from './LoanProfileDocuments';
import LoanProfileSourcesAndUses from './LoanProfileSourcesAndUses';

import styles from './LoanProfileContent.module.css';

export default function LoanProfileContent({ data = {} }) {
  const isRefinance = /refinance/i.test(data.loan_purpose);
  const isPurchase = /purchase/i.test(data.loan_purpose);
  const isRenovationOrConstruction = /renovation|construction/i.test(data.loan_purpose);

  const isPurchaseAndRenovationExists =
    data?.construction_details?.construction_business_plan === 'Purchase and renovation of a an existing property';

  return (
    <div className={styles.wrapper}>
      <LoanProfileHero data={data} />
      <div className={styles.content}>
        {(isRefinance || isPurchase) && <LoanProfilePropertyDetails data={data.purchase_details} />}
        {isRefinance && <LoanProfileRefinance data={data.refinance} />}
        {isRenovationOrConstruction && <LoanProfileConstructionDetails data={data.construction_details} />}
        <LoanProfilePropertyIncome data={data.property_income} />
        {(isRefinance || isPurchase || isRenovationOrConstruction) && (
          <LoanProfileSourcesAndUses
            data={data.sources_and_uses}
            refinance={data.refinance}
            isPurchase={isPurchase}
            isRefinance={isRefinance}
            isRenovationOrConstruction={isRenovationOrConstruction}
            isPurchaseAndRenovationExists={isPurchaseAndRenovationExists}
          />
        )}
        <LoanProfileBorrowerInformation data={data.borrower_information} />
        <LoanProfileDocuments data={data.documents} />
      </div>
    </div>
  );
}
