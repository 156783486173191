import { Space, Tooltip } from 'antd';
import './BorrowerLink.css';
import CopyButton from 'components/UI/CopyButton/CopyButton';

function BorrowersLandingLink({ link }) {
  return (
    <div className="borrower_link_wrap">
      <Space align="center" size="middle">
        {link !== null ? (
          <>
            <span className="borrower_link-title">Your personalized loan portal link:</span>
            <Tooltip placement="top" title={link}>
              <div className="borrower_link-item">{link}</div>
            </Tooltip>
            <CopyButton link={link} />
          </>
        ) : (
          <span>Contact your admin to setup your landing page.</span>
        )}
      </Space>
    </div>
  );
}

export default BorrowersLandingLink;
