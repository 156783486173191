const fillError = (updateFields, condition, fields, setFields) => {
  const tempFields = { ...fields };

  Object.entries(fields).forEach((i) => {
    const field = i[1];
    if (i[0].includes('dividedInputs')) {
      Object.entries(field.inputs).forEach((item) => {
        if (updateFields[item[0]] && updateFields[item[0]].length) {
          tempFields[i[0]].inputs[item[0]].error = true;
        }
      });
    } else if (i[0].includes('upload') || i[0].includes('radioButtons') || i[0].includes('selectTags')) {
      const fieldName = i[0].split('_').slice(1).join('_');
      if (updateFields[fieldName] && updateFields[fieldName].length) {
        tempFields[i[0]].error = true;
      }
    } else if (i[0].includes('switchButtons')) {
      // let fieldName = i[0].split('_').slice(1).join('_')
      Object.entries(field.switches).forEach((item) => {
        if (updateFields[item[0]] && updateFields[item[0]].length) {
          tempFields[i[0]].switches[item[0]].error = true;
        }
      });
    } else if (i[0].includes('checkbox')) {
      Object.entries(field.boxes).forEach((item) => {
        if (updateFields[item[0]] && updateFields[item[0]].length) {
          tempFields[i[0]].boxes[item[0]].error = true;
        }
      });
    } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle')) {
      //eslint-disable-line
    } else if (updateFields[i[0]] && updateFields[i[0]].length) {
      if (i[0] === 'year_established') {
        tempFields[i[0]].errorMessage = 'Year should be valid';
      }
      if (i[0] === 'business_email' && field.value) {
        tempFields[i[0]].errorMessage = 'Incorrect email format';
      }
      tempFields[i[0]].error = true;
    }
  });

  setFields(tempFields);
};

export default fillError;
