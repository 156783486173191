import { useContext, useEffect } from 'react';
import { DatePicker, Divider, Form, InputNumber, Radio, Space } from 'antd';
import moment from 'moment';
import { getDefaultSuccess, onChangeValidator, useFieldsChangeNew } from '../../helpers/larHelpers';
import formSettings from '../../helpers/formSettings';
import LarAppContext from '../../context/LarAppContext';

function CreditHistoryForm({
  form,
  isPurchaseLong,
  isPurchaseShort,
  isRefinance,
  setLAProgress,
  setProgress,
  setSuccess,
  setSuccessLA,
}) {
  const {
    bankruptcy_chapter,
    discharge_from_bankruptcy_date,
    FICO,
    filling_from_bankruptcy_date,
    foreclosure_completion_date,
    foreclosure_property_type,
    mortgage_payment_history_120_days_late,
    mortgage_payment_history_30_days_late,
    mortgage_payment_history_60_days_late,
    mortgage_payment_history_90_days_late,
    primary_home,
    signer_has_completed_foreclosure,
    bankruptcy,
  } = useContext(LarAppContext);
  const formName = 'credit_history';
  const primaryHome = Form.useWatch('primary_home', form);
  const bankruptcyChapter = Form.useWatch('bankruptcy_chapter', form);
  const signerHasCompletedForeclosure = Form.useWatch('signer_has_completed_foreclosure', form);
  const signerHasFiledBankruptcy = Form.useWatch('bankruptcy', form);

  const getSuccessStatus = (fieldsArr) => {
    onChangeValidator(form, fieldsArr, formName, {
      isRefinance,
      isPurchaseLong,
      isPurchaseShort,
    });
    const valuesArr = Object.entries(form.getFieldsValue()).map((el) => el[1]);
    return getDefaultSuccess(valuesArr);
  };

  const setSuccessStatus = (status = false) => {
    setSuccess(status);
    setProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const setSuccessStatusLA = (status = false) => {
    setSuccessLA(status);
    setLAProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const onSuccess = () => {
    form.submit();
  };

  const onReject = () => {};

  const onFieldsChange = useFieldsChangeNew(
    getSuccessStatus,
    setSuccessStatus,
    onSuccess,
    onReject,
    getSuccessStatus,
    setSuccessStatusLA,
  );

  useEffect(() => {
    form.setFieldsValue({
      FICO,
      primary_home,
      mortgage_payment_history_30_days_late: mortgage_payment_history_30_days_late || 0,
      mortgage_payment_history_60_days_late: mortgage_payment_history_60_days_late || 0,
      mortgage_payment_history_90_days_late: mortgage_payment_history_90_days_late || 0,
      mortgage_payment_history_120_days_late: mortgage_payment_history_120_days_late || 0,
      bankruptcy_chapter,
      discharge_from_bankruptcy_date: discharge_from_bankruptcy_date
        ? moment(discharge_from_bankruptcy_date, 'YYYY-DD-MM')
        : moment(new Date(), 'YYYY-DD-MM'),
      filling_from_bankruptcy_date: filling_from_bankruptcy_date
        ? moment(filling_from_bankruptcy_date, 'YYYY-DD-MM')
        : moment(new Date(), 'YYYY-DD-MM'),
      foreclosure_completion_date: foreclosure_completion_date
        ? moment(foreclosure_completion_date, 'YYYY-DD-MM')
        : moment(new Date(), 'YYYY-DD-MM'),
      signer_has_completed_foreclosure: signer_has_completed_foreclosure || false,
      bankruptcy: bankruptcy || false,
      foreclosure_property_type,
    });
    const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
      name: el[0],
      value: el[1],
    }));
    onFieldsChange([1], valuesArr);
    // eslint-disable-next-line
  }, []);

  return (
    <Form {...formSettings} name={formName} form={form} onFieldsChange={onFieldsChange}>
      <div style={{ marginBottom: 12, color: '#8C8C8C' }}>
        Please complete this section using the Signer with the highest FICO score.
      </div>
      <Form.Item
        tooltip="Enter the actual score, if known, or make your best estimate. FICO scores range between 350-850."
        label="FICO"
        name="FICO"
        className="scenario-required-field auction-required-field"
      >
        <InputNumber style={{ width: 90 }} min={350} max={850} />
      </Form.Item>
      <Form.Item name="primary_home" label="Primary home" className="scenario-required-field auction-required-field">
        <Radio.Group>
          <Space direction="vertical" size="large">
            <Radio value="Own">Own</Radio>
            <Radio value="Rent">Rent</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {(isRefinance || isPurchaseLong) && primaryHome === 'Own' && !isPurchaseShort && (
        <>
          <h3>
            <b>Mortgage Payment History (# Times Late in Past 12 Months)</b>
          </h3>
          <Form.Item initialValue={0} label="30 days late" name="mortgage_payment_history_30_days_late">
            <InputNumber min={0} max={12} style={{ width: 120 }} />
          </Form.Item>
          <Form.Item initialValue={0} label="60 days late" name="mortgage_payment_history_60_days_late">
            <InputNumber min={0} max={12} style={{ width: 120 }} />
          </Form.Item>
          <Form.Item initialValue={0} label="90 days late" name="mortgage_payment_history_90_days_late">
            <InputNumber min={0} max={12} style={{ width: 120 }} />
          </Form.Item>
          <Form.Item initialValue={0} label="120 days late" name="mortgage_payment_history_120_days_late">
            <InputNumber min={0} max={12} style={{ width: 120 }} />
          </Form.Item>
        </>
      )}
      <Divider />
      <h3>
        <b>Bankrupcies</b>
      </h3>
      <Form.Item label="Filed bankruptcy within past 7 years" name="bankruptcy">
        <Radio.Group>
          <Space direction="vertical" size="large">
            <Radio value>Yes</Radio>
            <Radio value={false}>Not applicable</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {signerHasFiledBankruptcy && (
        <Form.Item
          name="bankruptcy_chapter"
          label="Bankruptcy chapter"
          className="scenario-required-field auction-required-field"
        >
          <Radio.Group>
            <Space direction="vertical" size="large">
              <Radio value={7}>7</Radio>
              <Radio value={11}>11</Radio>
              <Radio value={13}>13</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      )}
      {(bankruptcyChapter === 7 || bankruptcyChapter === 11) && (
        <Form.Item
          name="discharge_from_bankruptcy_date"
          label="Discharge date"
          initialValue={moment(new Date(), 'YYYY-DD-MM')}
          className="scenario-required-field auction-required-field"
        >
          <DatePicker
            style={{ width: 215 }}
            disabledDate={(currentDate) => currentDate.isAfter(moment(new Date(), 'YYYY-DD-MM'))}
          />
        </Form.Item>
      )}
      {bankruptcyChapter === 13 && (
        <Form.Item
          name="filling_from_bankruptcy_date"
          label="Filling date"
          initialValue={moment(new Date(), 'YYYY-DD-MM')}
          className="scenario-required-field auction-required-field"
        >
          <DatePicker
            style={{ width: 215 }}
            disabledDate={(currentDate) => currentDate.isAfter(moment(new Date(), 'YYYY-DD-MM'))}
          />
        </Form.Item>
      )}
      <Divider />
      <h3>
        <b>Foreclosures</b>
      </h3>
      <Form.Item name="signer_has_completed_foreclosure" label="The signer has completed a foreclosure">
        <Radio.Group>
          <Space direction="vertical" size="large">
            <Radio value>Yes</Radio>
            <Radio value={false}>Not applicable</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {signerHasCompletedForeclosure && (
        <>
          <Form.Item
            label="Foreclosure completion date"
            name="foreclosure_completion_date"
            initialValue={moment(new Date(), 'YYYY-DD-MM')}
            className="scenario-required-field auction-required-field"
          >
            <DatePicker
              style={{ width: 215 }}
              disabledDate={(currentDate) => currentDate.isAfter(moment(new Date(), 'YYYY-DD-MM'))}
            />
          </Form.Item>
          <Form.Item
            name="foreclosure_property_type"
            label="Property type"
            className="scenario-required-field auction-required-field"
          >
            <Radio.Group>
              <Space direction="vertical" size="large">
                <Radio value="Investment">Investment</Radio>
                <Radio value="Primary home">Primary home</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </>
      )}
    </Form>
  );
}

export default CreditHistoryForm;
