export const passwordRules = [
  {
    required: true,
    message: 'Please input your password!',
  },
  {
    min: 8,
    message: 'Enter at least 8 characters',
  },
  {
    max: 32,
    message: 'Max length 32 characters',
  },
];
