export default function ListItemExpand({
  // diligence_description,
  item_description,
  // notes,
  // reason,
  // rejected_by_lender,
  // remedy,
}) {
  // TODO: A lot of commented out stuff here
  // Do we really need it?
  return (
    <div className="diligence-expand">
      <div className="expand-col">
        <b>Description</b>
        <span>{item_description}</span>
      </div>
      {/* {rejected_by_lender &&
				<div styles={{ margin: "24px 0 16px" }} className="expand-row">
					<b>Rejected by Lender:</b>
					<span>{rejected_by_lender}</span>
				</div>
			}
			{reason &&
				<div className="expand-row">
					<b>Reason:</b>
					<span>{reason}</span>
				</div>
			}
			{diligence_description &&
				<div className="expand-row">
					<b>Description:</b>
					<span>{diligence_description}</span>
				</div>
			}
			{remedy &&
				<div styles={{ marginTop: 24 }} className="expand-row">
					<b>Remedy:</b>
					<span>{remedy}</span>
				</div>
			}
			{notes &&
				<div className="expand-row">
					<b>Notes:</b>
					<span>{notes}</span>
				</div>
			} */}
    </div>
  );
}
