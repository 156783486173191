import { useRef } from 'react';
import { Carousel, Image } from 'antd';
import ReactYoutubePlayer from 'react-player/youtube';
import ReactPlayer from 'react-player';
import 'components/UniversalSlider/universal_carousel.css';
import larPlaceholderImg from 'assets/images/LAR_placeholder.svg';
import { ChevronLeftIcon, ChevronRightIcon } from 'assets/icons/icons';

function UniversalSlider({ media, height }) {
  const carouselRef = useRef();
  const youtubePlayerRef = useRef(null);
  const reactPlayerRef = useRef(null);

  const checkPlayerPlay = (playerRef) => {
    if (playerRef.current.player.isPlaying) {
      playerRef.current.player.player.player.pauseVideo();
    }
  };
  const handleChangeSlider = (direction) => {
    if (direction !== 'prev' && direction !== 'next') {
      return 0;
    }
    carouselRef.current[direction]();
    if (youtubePlayerRef.current) {
      checkPlayerPlay(youtubePlayerRef);
    }
    if (reactPlayerRef.current) {
      checkPlayerPlay(reactPlayerRef);
    }
  };

  return (
    <div className="universal-carousel-container" style={{ maxHeight: height }}>
      {media.length === 0 || !media ? (
        <Image style={{ objectFit: 'cover' }} height={height} width="100%" src={larPlaceholderImg} alt="building" />
      ) : (
        <Carousel effect="fade" className="universal-carousel" ref={carouselRef}>
          {media.map((el, idx) => {
            if (el.type === 'image') {
              return (
                <div
                  className="universal-carousel__media-container universal-carousel__media-container_image"
                  key={idx}
                >
                  <Image
                    style={{ objectFit: 'cover' }}
                    fallback={larPlaceholderImg}
                    height={height}
                    width="100%"
                    className=""
                    src={el?.elem}
                    alt="building"
                  />
                </div>
              );
            }
            if (el.type === 'youtube') {
              return (
                <div
                  className="universal-carousel__media-container universal-carousel__media-container_youtube-video"
                  key={idx}
                >
                  <ReactYoutubePlayer height={height} style={{ borderRadius: '8px' }} width="auto" url={el?.elem} />
                </div>
              );
            }
            if (el.type === 'video') {
              return (
                <div
                  className="universal-carousel__media-container universal-carousel__media-container_video"
                  key={idx}
                >
                  <ReactPlayer
                    height={height}
                    className=""
                    style={{ borderRadius: '8px' }}
                    width="100%"
                    controls
                    url={el?.elem}
                  />
                </div>
              );
            }
            return null;
          })}
        </Carousel>
      )}
      {media?.length > 1 ? (
        <>
          <div
            className="universal-carousel__btn universal-carousel__btn_prev"
            onClick={() => {
              handleChangeSlider('prev');
            }}
          >
            <ChevronLeftIcon style={{ color: '#FDFDFF' }} />
          </div>
          <div
            className="universal-carousel__btn universal-carousel__btn_next"
            onClick={() => {
              handleChangeSlider('next');
            }}
          >
            <ChevronRightIcon style={{ color: '#FDFDFF' }} />
          </div>
        </>
      ) : null}
    </div>
  );
}
export default UniversalSlider;
