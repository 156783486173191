import { useState } from 'react';
import CustomSearchInput from 'components/CustomComponents/CustomSearchInput';

function SearchBox({ API, navigateToItem }) {
  const [findItemData, setFindItemData] = useState([]);
  const [ready, setReady] = useState(true);

  function searchItems(query) {
    setReady(false);

    API.search(query).then((data) => {
      setFindItemData(
        data.map(({ id, property_address, status, type }) => (
          <div className="custom-search__result-item" key={id} onClick={() => navigateToItem(id, type, status)}>
            {property_address}
            <span style={{ float: 'right', marginLeft: 30 }}>{`id: ${id}`}</span>
          </div>
        )),
      );
      setReady(true);
    });
  }

  return (
    <CustomSearchInput
      clearFunc={() => setFindItemData([])}
      enterButton
      loading={!ready}
      placeholder="Search"
      results={findItemData}
      searchFunc={searchItems}
    />
  );
}

export default SearchBox;
