import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Tooltip.module.css';

function Tooltip({ title, icon, text, type, size }) {
  return (
    <div className={classNames([styles.tooltip, styles[type], styles[size]])}>
      {icon && <div className={styles.imageWrapper}>{icon}</div>}
      <div>
        {title && <h3>{title}</h3>}
        <span>{text}</span>
      </div>
    </div>
  );
}

export default Tooltip;

Tooltip.defaultProps = {
  title: '',
  type: 'info',
  size: 'medium',
};

Tooltip.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  type: PropTypes.oneOf(['info', 'success', 'accent', 'warn', 'error']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};
