import { useMemo } from 'react';
import { larVariables } from '../../helpers';

const LoanFormWrapper = ({
  loan_purpose = '',
  loan_opportunity = '',
  PurchaseLongForm = null,
  PurchaseShortForm = null,
  RefinanceForm = null,
}) => {
  const isRefinance = loan_purpose === larVariables.loan_purpose_name.refinance;
  const isPurchaseLong =
    loan_purpose === larVariables.loan_purpose_name.purchase && loan_opportunity === larVariables.loan_term_name.long;
  const isPurchaseShort =
    loan_purpose === larVariables.loan_purpose_name.purchase && loan_opportunity === larVariables.loan_term_name.short;

  const Component = useMemo(() => {
    if (isPurchaseLong) {
      return PurchaseLongForm;
    }
    if (isPurchaseShort) {
      return PurchaseShortForm;
    }
    if (isRefinance) {
      return RefinanceForm;
    }
    return null;
  }, [isPurchaseLong, isRefinance, isPurchaseShort, PurchaseLongForm, PurchaseShortForm, RefinanceForm]);

  return Component;
};
export default LoanFormWrapper;
