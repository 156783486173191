import Modal from '../../../Modals/Modal';
import ReadyLaunchDescription from '../ReadyLaunchDescription';
import styles from './styles.module.css';

export default function ReadyLaunchDealModal({ isOpen, onCancel, onOk }) {
  function handleContinue() {
    onCancel();
    onOk();
  }

  const array = [
    { title: 'Starport Quoting Process', items: ['Lenders Invited', 'Duration', 'Extensions', 'Launch Begin'] },
    { title: 'Scenario', items: ['Up to 20', 'Up to 7 business days', '3 days', 'Immediately*'] },
  ];

  return (
    <Modal
      okText="Launch Now"
      width={700}
      title={<p style={{ fontSize: 18 }}>We’re ready for Launch</p>}
      bodyStyle={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 24, paddingTop: 0 }}
      onOk={handleContinue}
      open={isOpen}
      onCancel={onCancel}
    >
      <ReadyLaunchDescription />
      <div className={styles.containerColumns}>
        {array.map((el, elIndex) => (
          <div key={elIndex} className={styles.column}>
            <p className={styles.columnTitle} style={{ fontWeight: 'bolder' }}>
              {el.title}
            </p>
            {el.items.map((item, index) => (
              <p className={styles.columnTitle} key={elIndex.toString() + index}>
                {item}
              </p>
            ))}
          </div>
        ))}
      </div>
    </Modal>
  );
}
