import QuoteTermsFilters from '../QuoteTermsFilters/QuoteTermsFilters';
import QuoteTermsArrows from '../QuoteTermsArrows/QuoteTermsArrows';

function QuoteTermsExtra({ filter, setFilter, data, nextSlide, prevSlide, swiper }) {
  return (
    <>
      <QuoteTermsFilters filter={filter} setFilter={setFilter} />
      {swiper?.params?.slidesPerView < data?.bids?.length && (
        <QuoteTermsArrows nextSlide={nextSlide} prevSlide={prevSlide} />
      )}
    </>
  );
}

export default QuoteTermsExtra;
