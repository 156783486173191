import * as types from './types';

export const setUserData = (payload) => ({
  type: types.USER_DATA,
  payload,
});

export const setOnboardingSize = (payload) => ({
  type: types.LEFT_PANEL_SIZE,
  payload,
});

export const setActiveRoute = (payload) => ({
  type: types.ACTIVE_ROUTE,
  payload,
});

export const setBreadCrumbHistory = (payload) => ({
  type: types.BREAD_CRUMB_HISTORY,
  payload,
});

export const setBreadCrumbHistorySetValue = (payload) => ({
  type: types.BREAD_CRUMB_HISTORY_SET_VALUE,
  payload,
});

export const setChatState = (payload) => ({
  type: types.CHAT_STATE,
  payload,
});

export const updateChatConnection = (payload) => ({
  type: types.UPDATE_CHAT_CONNECTION,
  payload,
});

export const setUnreadChatMessages = (payload) => ({
  type: types.UNREAD_CHAT_MESSAGES,
  payload,
});
