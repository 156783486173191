import { message, Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import LegacyAPI from 'api';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import classes from './FinishedAuctions.module.css';
import columns from './FinishedAuctionsTable/FinishedAuctionsTableColumnsData';
import FinishedAuctionsFilter from './FinishedAuctionsFilter/FinishedAuctionsFilter';
import selectData from './FinishedAuctionsFilter/FinishedAuctionsFilterData';

export default function FinishedAuctions() {
  const [data, setData] = useState([]);
  const [selectedSort, setSelectedSort] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleSelectChange = (value) => {
    setSelectedSort(value);
  };

  const onSearch = (value) => {
    setSearchQuery(value);
  };

  const parsedData = useMemo(
    () =>
      data.map((el) => ({
        dealType: {
          id: el.id,
          type: el.type,
          style: el.style,
          amount: el.requesting_amount,
          address: el.address,
        },
        auctionStats: {
          views: el.auction_views,
          quotes: el.bid_count,
        },
        event: el.event,
        selectionProcess: {
          id: el.id,
          reviews: el.section_process.reviewed,
          sentToBorrower: el.section_process.send_to_borrower,
          decision: el.section_process.decision,
          winnerBid: el.winner_bid,
        },
      })),
    [data],
  );

  useEffect(() => {
    const params = {
      page: tableParams.pagination.current,
      per_page: tableParams.pagination.pageSize,
    };

    if (selectedSort) {
      params.order_by = selectedSort;
      params.order_type = 'asc';
    }

    if (searchQuery) {
      params.search = searchQuery;
    }

    const fetchFinishedAuctions = () => {
      setLoading(true);
      LegacyAPI()
        .get('/LAR/dashboard/lender/finished_auctions', { params })
        .then(({ data }) => {
          setData(data.lars);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: data.total,
            },
          });
        })
        .catch((err) => {
          console.error(err);
          message.error('Something was wrong, please try again');
        })
        .finally(() => setLoading(false));
    };

    fetchFinishedAuctions();
    // eslint-disable-next-line
  }, [JSON.stringify(tableParams), selectedSort, searchQuery]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <section className={classes.section}>
      <SectionTitle title="Quote results" />
      <div className={classes.finishedAuctionsWrapper}>
        <FinishedAuctionsFilter
          onSearch={onSearch}
          handleChange={handleSelectChange}
          options={selectData}
          searchQuery={searchQuery}
        />
        <div className={classes.tableWrapper}>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={parsedData}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </section>
  );
}
