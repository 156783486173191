import { io } from 'socket.io-client';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import API from 'api';

export function useMCMNotificationCount(disabled) {
  const [count, setCount] = useState(0);
  const { token, role } = useSelector((store) => store.user.userData || {});

  // Get the initial number of messages
  useEffect(() => {
    if (disabled) {
      return;
    }

    API()
      .get('/profile/mcm-notifications', {
        params: {
          important_page: 1,
          important_per_page: 1, // TODO: Ideally this should be 0, but backend returns code 500
          regular_page: 1,
          regular_per_page: 1, // TODO: Ideally this should be 0, but backend returns code 500
        },
      })
      .then(({ data }) => {
        setCount(Object.values(data).reduce((acc, cur) => acc + (cur.total_count || 0), 0));
      });
  }, [role, disabled]);

  // Update the counter when there are new messages
  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL, { query: { jwt: token } });

    socket.connect();
    socket.on('new_mcm_message', () => setCount((prev) => prev + 1));

    return () => socket.disconnect();
  }, [token]);

  return count;
}
