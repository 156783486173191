import { Tag } from 'antd';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import LARDBContext from 'pages/Onboarding/LARDB/LARDBContext';
import diligenceStatuses from '../constants/diligenceStatuses';

import classes from './styles/TableHeader.module.css';

const statusGroups = Array.from(new Set(diligenceStatuses.map((s) => s.group))).map((statusGroup) =>
  diligenceStatuses.filter((i) => i.group === statusGroup),
);

function Status({ meta, currentStatus, setStatus }) {
  const { dd } = useContext(LARDBContext);
  const { role } = useSelector((store) => store.user.userData);

  // Lender can't view items inside MLO Review and Lender Ready tabs
  const clickable = !/lender/i.test(role) || meta.group !== 0 || /owed/i.test(meta.title);

  return (
    <div
      className={classNames(
        {
          [classes.activeStatus]: currentStatus === meta.value,
          [classes.clickableStatus]: clickable,
        },
        classes.status,
        classes[`${meta.colour}Status`],
      )}
      onClick={() => clickable && setStatus(meta.value)}
    >
      <span>{meta.title}</span>
      <Tag>{dd.statistic[meta.value] ?? 0}</Tag>
    </div>
  );
}

function StatusGroup({ meta, currentStatus, setStatus }) {
  const role = useSelector((store) => store.user.userData?.role);

  // Borrower can't see lender stages and archive
  if (/borrower/i.test(role) && (meta[0].group === 1 || meta[0].group === 2)) {
    return null;
  }

  return (
    <div className={classes.statusGroup}>
      {meta.map((status) => (
        <Status meta={status} key={status.title} currentStatus={currentStatus} setStatus={setStatus} />
      ))}
    </div>
  );
}

export default function TableHeader({ setStatus, status }) {
  return (
    <div className={classes.root}>
      {statusGroups.map((group, i) => (
        <StatusGroup key={i} meta={group} currentStatus={status} setStatus={setStatus} />
      ))}
    </div>
  );
}
