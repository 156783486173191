import React from 'react';
import ChooseBid from '../../pages/Onboarding/BidApplication/ChooseBid';
import PlaceBid from '../../pages/Onboarding/BidApplication/PlaceBid';

export const mainNavigationBidApplication = [
  {
    exact: true,
    title: 'Bid Application',
    path: '/bid_application/lar_id_:id/choose_bid',
    main: (props) => <ChooseBid {...props} />,
  },
  {
    exact: true,
    title: 'Place bid',
    path: '/bid_application/lar_id_:id/place_bid',
    main: (props) => <PlaceBid {...props} />,
  },
  {
    exact: true,
    title: 'Edit bid',
    path: '/bid_application/lar_id_:id/edit_bid',
    main: (props) => <PlaceBid {...props} edit />,
  },
];
