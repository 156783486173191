import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Checkbox, Form, Input, message, Spin, Result } from 'antd';
import API from 'api';
import Keys from 'constants/helper';
import { setUserData } from 'store/actions/userActions';
import { linksList } from 'utils/parse';
import { passwordRules } from 'constants/rules';
import styles from './Confirm.module.css';

function Confirm({ type }) {
  const [form] = Form.useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [agreements, setAgreements] = useState({});
  const [status, setStatus] = useState(null);

  const role = location.pathname?.split('/')[2];

  useEffect(() => {
    if (type !== 'company') {
      const token = getToken();
      localStorage.setItem(Keys.JWT_TOKEN, token);
      API()
        .get('/prospects/BDR/check_status')
        .then(({ data }) => setStatus(data))
        .catch(() => message.error('Something was wrong'));
    }
  }, [type]);

  useEffect(() => {
    API()
      .get('/common/iharmoni-terms')
      .then(({ data }) => data?.documents)
      .then((docs) =>
        Object.fromEntries(
          Object.entries(docs).filter(([name]) => ['Privacy Policy', 'Terms of Service'].includes(name)),
        ),
      )
      .then(setAgreements);
  }, [role]);

  function getToken() {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get('token');
  }

  function getRequestUrl() {
    switch (type) {
      case 'company':
        return '/user_settings/company/members/accept-invite';
      default:
        return '/prospects/BDR/set_password';
    }
  }

  function redirect(data) {
    localStorage.setItem(Keys.USER_DATA, JSON.stringify(data));
    history.push('/login');
  }

  function handleFinish(values) {
    const token = getToken();
    const requestUrl = getRequestUrl();

    API(token)
      .post(requestUrl, {
        password: values.password,
      })
      .then(({ data }) => {
        if (data.access_token) {
          localStorage.setItem(Keys.JWT_TOKEN, data.access_token);
        } else {
          localStorage.setItem(Keys.JWT_TOKEN, token);
        }

        dispatch(
          setUserData({
            role: data.role,
            token: data.access_token,
          }),
        );

        redirect(data);
      })
      .catch(() => {
        message.error('Something was wrong');
      });
  }

  if (status?.password_exist) {
    const token = getToken();

    if (status.access_token) {
      localStorage.setItem(Keys.JWT_TOKEN, status.access_token);
    } else {
      localStorage.setItem(Keys.JWT_TOKEN, token);
    }
    history.push('/login');
  }

  if (status?.msg === 'invitation expired') {
    return (
      <div className={styles.wrapper}>
        <Result title="Your invitation expired" />
      </div>
    );
  }

  if (!status && type !== 'company') {
    return (
      <div className={styles.wrapper}>
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <h1 className={styles.authHead}>We're glad you're here. Now, let's get started.</h1>
        <h2 className={styles.authTitle}>Select a Password</h2>
        <Form form={form} name="register" onFinish={handleFinish} style={{ width: 360, maxWidth: '100%' }}>
          <Form.Item className={styles.formItem} name="password" label="Password" rules={passwordRules} hasFeedback>
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              ...passwordRules,
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
              },
            ]}
          >
            <Checkbox>I have read and agree to {linksList(agreements)}</Checkbox>
          </Form.Item>
          <Form.Item shouldUpdate className={styles.formItem}>
            {() => (
              <Button
                type="dark"
                size="large"
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                }
              >
                Create account
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Confirm;
