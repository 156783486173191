import { Button, message, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';

import API from 'api';
import regExp from 'constants/regExp';
import Keys from 'constants/helper';
import useResize from 'constants/useResize';

import TextInput from 'components/CustomComponents/TextInput';

export default function ResetPasswordPopup({ color, isOpen, onCancel, onConfirm }) {
  const { innerWidth } = useResize();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState({ value: '', error: null });

  const handleSubmit = () => {
    if (!email.value.length) {
      setEmail((prevState) => ({
        ...prevState,
        error: 'Fill this label',
      }));
      return null;
    }
    if (!regExp.email.test(email.value)) {
      setEmail((prevState) => ({
        ...prevState,
        error: 'Incorrect email format',
      }));
      return null;
    }

    setLoading(true);
    API()
      .post('/sign_in/reset_password/code', { domain: window.location.href, email: email.value })
      .then(() => {
        message.info('Please check your email');

        sessionStorage.setItem(Keys.VERIFICATION_CODE_EMAIL, email.value);
        setEmail({ value: '', error: null });
        onConfirm && onConfirm();
      })
      .catch((e) => {
        if (e.response?.data?.error) {
          setEmail((prevState) => ({
            ...prevState,
            error: e.response.data.error,
          }));
        }
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail({
      value,
      error: null,
    });
  };

  return (
    <Modal
      open={isOpen}
      footer={
        <Button
          block
          style={{
            borderColor: color,
            backgroundColor: color,
          }}
          type="dark"
          size="large"
          loading={loading}
          onClick={handleSubmit}
        >
          Send verification code
        </Button>
      }
      width={370}
      style={innerWidth > 635 ? { top: 84, margin: '0 calc(3% + 270px) 0 auto' } : null}
      onCancel={onCancel}
    >
      <form className="wrapper_auth__inputs" onSubmit={handleSubmit}>
        <TextInput
          value={email.value}
          title="Email"
          htmlFor="email"
          error={email.error}
          errorMessage={email.error}
          onChange={handleChange}
        />
      </form>
    </Modal>
  );
}

ResetPasswordPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};
