import { useMemo } from 'react';
import { isEmpty, isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { capitalizeAll } from 'constants/capitalizeFirstLetter';
import { formatAMPM } from 'utils/date';
import styles from './styles/ChatRow.module.css';

function ChatRow({ chat, onClick }) {
  const { chatState } = useSelector((state) => state.user);

  const isActiveChat = chatState?.chat_id === chat.chat_id;

  const getLastSentMessage = () => {
    const isToday = new Date().toDateString() === new Date(chat.last_message.message_data.sent_at).toDateString();
    if (isToday) {
      return formatAMPM(chat.last_message.message_data.sent_at);
    }

    return chat.last_message.message_data.sent_at;
  };

  const lastMessage = useMemo(() => {
    const type = chat.last_message.message_data.type;
    return type === 'text'
      ? chat.last_message.message_data.body
      : type === 'document'
      ? 'File attached'
      : 'Audio message';
  }, [chat?.last_message]);

  const handleClick = () => {
    onClick({ ...chat });
  };

  return (
    <div key={chat.chat_id} className={classNames('chat-row', isActiveChat && 'active')} onClick={handleClick}>
      <div className="chat-row-info">
        <div className={styles.chatRowTop}>
          <span className="name">
            <h4 className={styles.chatRowName}>
              {(() => {
                const fullName = chat.users[0]?.full_name || chat.users[0]?.user_json?.full_name || 'Anonymous';

                return isNumber(fullName) ? `Lender #${fullName}` : capitalizeAll(fullName);
              })()}
            </h4>
          </span>
          <div className={styles.chatRowMid}>
            <div className={styles.auctionInfo}>
              {!isEmpty(chat.lar_data) && (
                <>
                  <p>#{chat.lar_data.id}</p>
                  <p>{chat.lar_data.type}</p>
                </>
              )}
            </div>
            {chat.new_messages > 0 && !isActiveChat && (
              <div className={styles.newMessagesCount}>{chat.new_messages}</div>
            )}
          </div>
          <span className={styles.date}>{getLastSentMessage()}</span>
        </div>
        <div className={styles.chatRowBottom}>{lastMessage}</div>
      </div>
    </div>
  );
}

export default ChatRow;
