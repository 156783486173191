import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SettingsAPI } from 'api';
import { Switch, Modal, Button } from 'antd';
import { useLARDBContext } from 'pages/Onboarding/LARDB/hooks/LARDBContext';
import { ACTIVE_AUCTIONS } from 'constants/larApp';

function Scheduling() {
  const role = useSelector((state) => state.user.userData?.role);
  const { lar_status: larStatus } = useLARDBContext();
  const { id } = useParams();
  const [isToggleChecked, setIsToggleChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const API = new SettingsAPI();

    API.getLarScheduleStatus(id).then((res) => {
      setIsToggleChecked(res.data.option);
    });
  }, [id]);

  const onChange = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    const API = new SettingsAPI();

    setLoading(true);
    API.postLarScheduleStatus(id)
      .then(() => {
        setIsToggleChecked((prev) => !prev);
      })
      .finally(() => {
        setIsModalOpen(false);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setIsToggleChecked(false);
    setIsModalOpen(false);
  };

  if (role !== 'PLA' && role !== 'superadmin') {
    return null;
  }

  return (
    <>
      <b>Enable scheduling</b>
      <div>
        <Switch
          defaultChecked={false}
          disabled={ACTIVE_AUCTIONS.includes(larStatus)}
          checked={isToggleChecked}
          onChange={onChange}
        />
      </div>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button size="large" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button size="large" onClick={handleOk} type="dark" loading={loading}>
            OK
          </Button>,
        ]}
      >
        <p>Are you sure you want to enable scheduling?</p>
      </Modal>
    </>
  );
}

export default Scheduling;
