import { BinIcon, EyeIcon, PencilIcon } from 'assets/icons';
import { Input, Spin, Modal } from 'antd';
import { useContext, useReducer, useState } from 'react';
import CustomButton from 'components/UI/Button';
import LARDBContext from 'pages/Onboarding/LARDB/LARDBContext';

export default function RegularDocumentEntry({
  actions = [],
  deleteFile,
  document,
  itemId,
  setViewDiligence,
  viewFullscreen,
  withDivider,
}) {
  const [editMode, toggleEditMode] = useReducer((state) => !state, false);
  const [fileName, setFileName] = useState(document.document_name || 'Unnamed document');
  const [loading, setLoading] = useState(false);
  const [visibleDeletePopup, toggleVisibleDeletePopup] = useReducer((state) => !state, false);
  const { dd } = useContext(LARDBContext);

  function submitNewFileName() {
    setLoading(true);

    dd.API.renameFile(itemId, document.id, fileName).then(({ data }) => {
      setViewDiligence(data);
      setLoading(false);
      toggleEditMode();
    });
  }

  function cancelRenaming() {
    setFileName(document.document_name || 'Unnamed document');
    toggleEditMode();
  }

  function handleDeleteModal(ok) {
    toggleVisibleDeletePopup();
    ok && deleteFile(document.id);
  }

  if (editMode) {
    return (
      <div className="document-entry-block edit-mode">
        <Input value={fileName} onChange={(e) => setFileName(e.target.value)} />
        <Spin spinning={loading}>
          <CustomButton onClick={cancelRenaming} text="Cancel" disabled={loading} />
          <CustomButton onClick={submitNewFileName} primary text="Save" disabled={loading} />
        </Spin>
      </div>
    );
  }

  return (
    <div className={`document-entry-block${withDivider ? ' with-divider' : ''}`}>
      <b>{document.is_sample ? 'Sample File' : fileName}</b>
      <div className="button-block">
        <button
          aria-label="fullScreen"
          onClick={() => viewFullscreen(document.document_path, document.content_type)}
          type="button"
        >
          <EyeIcon />
        </button>
        {/* TODO: This permission will come from backend in available_actions as rename_document */}
        {actions.includes('upload') && (
          <button aria-label="editDocument" type="button" onClick={toggleEditMode}>
            <PencilIcon />
          </button>
        )}
        {actions.includes('document_delete') && (
          <button aria-label="deleteDocument" type="button" onClick={toggleVisibleDeletePopup}>
            <BinIcon />
          </button>
        )}
      </div>
      <div className="document-desc">
        Uploaded by {document.uploaded_by},{document.uploaded_at}
      </div>
      <Modal onCancel={() => handleDeleteModal()} onOk={() => handleDeleteModal(true)} visible={visibleDeletePopup}>
        Are you sure you want to delete this file?
      </Modal>
    </div>
  );
}
