import { Form, Radio, Input, Spin, message } from 'antd';
import { useEffect, useReducer, useState } from 'react';
import CustomButton from 'components/UI/Button';
import classes from './styles/Security.module.css';

export default function Security({ API }) {
  const [editMode, toggleEditMode] = useReducer((state, type) => ({ ...state, [type]: !state[type] }), {
    password: false,
    question: false,
  });
  const [data, setData] = useState({ two_factor_method: 'email', question: '' });
  const [loading, setLoading] = useState(false);
  const [passwordForm] = Form.useForm();
  const [questionForm] = Form.useForm();

  function submitPassword({ password, newPassword }) {
    setLoading(true);

    API.updatePassword(password, newPassword)
      .then(() => {
        toggleEditMode('password');
      })
      .catch((reason) => {
        message.error(
          {
            'wrong password': 'The current password is incorrect',
          }[reason?.response?.data?.error] || "Couldn't update password",
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (editMode.password) {
      return;
    }

    passwordForm.resetFields();
  }, [editMode.password, passwordForm]);

  function submitQuestion({ password, question, answer }) {
    setLoading(true);

    API.updateSecurityQuestion(password, question, answer)
      .then(() => {
        toggleEditMode('question');
      })
      .catch((reason) => {
        message.error(reason?.response?.data?.error || "Couldn't update security question");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (editMode.question) {
      return;
    }

    questionForm.resetFields();
  }, [editMode.question, questionForm]);

  useEffect(() => {
    setLoading(true);

    API.getSecuritySettings().then((data) => {
      setData(data);
      setLoading(false);
    });
  }, [API]);

  function updateTwoFactorMethod(method) {
    API.updateTwoStepMode(method);
    setData((prev) => ({ ...prev, two_factor_method: method }));
  }

  function revokeAllOtherSessions() {
    API.revokeAllOtherSessions()
      .then(() => {
        message.success('All other sessions revoked!');
      })
      .catch(() => {
        message.failure("Couldn't revoke other sessions!");
      });
  }

  return (
    <div className={classes.root}>
      <h1>Password & Security</h1>
      <Spin spinning={loading}>
        <div className={classes.main}>
          <div>
            <h2>
              {editMode.password ? 'Change p' : 'P'}
              assword
            </h2>
            {editMode.password ? (
              <Form form={passwordForm} layout="vertical" onFinish={submitPassword} requiredMark={false}>
                <Form.Item
                  label="Current password"
                  name="password"
                  rules={[
                    {
                      message: 'Please enter your current password!',
                      required: true,
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="New password"
                  name="newPassword"
                  rules={[
                    {
                      message: 'The minimum length is 6 symbols!',
                      min: 6,
                      required: true,
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="Confirm new password"
                  name="confirmNewPassword"
                  dependencies={['newPassword']}
                  hasFeedback
                  rules={[
                    {
                      message: 'Please confirm your new password!',
                      required: true,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <CustomButton text="Cancel" onClick={() => toggleEditMode('password')} />
                <CustomButton primary text="Update password" onClick={() => passwordForm.submit()} />
              </Form>
            ) : (
              <div className={classes.rowWithButton}>
                Last updated: {data.last_password_update || 'never'}
                <button onClick={() => toggleEditMode('password')}>Update password</button>
              </div>
            )}
          </div>
          {false && (
            <div>
              <h2>
                Two step verification
                <span>(Saves automatically)</span>
              </h2>
              <Radio.Group
                value={data.two_factor_method}
                onChange={({ target }) => updateTwoFactorMethod(target.value)}
                options={[
                  {
                    label: 'Email one-time code',
                    value: 'email',
                  },
                  {
                    label: 'Security question',
                    value: 'question',
                  },
                ]}
              />
              {data.two_factor_method === 'question' ? (
                editMode.question ? (
                  <Form
                    form={questionForm}
                    initialValues={{ question: data.question }}
                    layout="vertical"
                    onFinish={submitQuestion}
                    requiredMark={false}
                  >
                    <Form.Item
                      label="Question"
                      name="question"
                      rules={[{ required: true, message: 'Please enter your question!' }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Answer"
                      name="answer"
                      rules={[{ required: true, message: 'Please enter your answer!' }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Your account password"
                      name="password"
                      rules={[{ required: true, message: 'Please enter your password!' }]}
                    >
                      <Input type="password" />
                    </Form.Item>
                    <CustomButton text="Cancel" onClick={() => toggleEditMode('question')} />
                    <CustomButton primary text="Set question" onClick={() => questionForm.submit()} />
                  </Form>
                ) : (
                  <div className={classes.rowWithButton}>
                    Question: {data.question}
                    <button onClick={() => toggleEditMode('question')}>Update</button>
                  </div>
                )
              ) : null}
            </div>
          )}
          <div>
            <div className={classes.rowWithButton}>
              Revoke all other sessions and log off
              <button onClick={revokeAllOtherSessions}>Revoke</button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}
