import 'components/UI/CustomTable/custom_table.css';

function CustomTable({ children, onSubmit }) {
  return (
    <form className="custom_table" onSubmit={onSubmit}>
      {children}
    </form>
  );
}

export default CustomTable;
