import { BinIcon } from 'assets/icons';
import { Form, Select, InputNumber, Input } from 'antd';
import styles from '../LARDBFeesWorksheet.module.css';
import readableFields from '../readableFields';

function Row({ id, currencyInputParams = {}, additional = false }) {
  return (
    <>
      {additional ? (
        <div className={styles.rowWithBin}>
          <Form.Item name={`${id}.additional_field_name.${additional.i}`}>
            {additional.allowedNames ? (
              <Select
                className={styles.rowNameSelect}
                disabled={currencyInputParams.disabled}
                options={additional.allowedNames}
                placeholder="Choose type"
              />
            ) : (
              <Input className={styles.customOtherInput} defaultValue="Other" />
            )}
          </Form.Item>
          {currencyInputParams.disabled || <BinIcon onClick={() => additional.delete(id, additional.i)} />}
        </div>
      ) : (
        <div>{readableFields.get(id)}</div>
      )}
      <Form.Item name={additional ? `${id}.additional_field.${additional.i}` : id}>
        <InputNumber {...currencyInputParams} />
      </Form.Item>
    </>
  );
}

export default Row;
