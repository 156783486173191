import { BinIcon, EditIcon, PlusIcon } from 'assets/icons';
import { message, Modal, Space, Tooltip } from 'antd';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import API from 'api';
import copyIcon from 'assets/images/copy-light-grey.svg';
import CustomButton from 'components/UI/Button';
import MainLayout from 'components/Layouts/MainLayout';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import UniversalTable from 'components/UniversalTable';

function DeleteMandateModal({ open, deleteMandate, onCloseActiveModal }) {
  return (
    <Modal
      className="wrapper_prospect wrapper_prospect_delete"
      title={
        <div className="wrapper_prospect__title">
          <span className="title">Delete mandate</span>
          <span className="description">Are you sure you want to delete a mandate?</span>
        </div>
      }
      centered
      onOk={onCloseActiveModal}
      open={open}
      footer={
        <div className="wrapper_remove_prospect_btns">
          <CustomButton key="back" onClick={onCloseActiveModal} text="Cancel" />
          <CustomButton primary key="submit" onClick={deleteMandate} text="Confirm" />
        </div>
      }
      onCancel={onCloseActiveModal}
    />
  );
}

export default function Mandates() {
  const [activeModal, setActiveModal] = useState({ show: '', id: null });
  const [mandatesData, setMandatesData] = useState({ mandates: [], total: 0, ready: false });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const history = useHistory();

  const getMandates = useCallback(() => {
    setMandatesData((prev) => ({
      ...prev,
      ready: false,
    }));

    API()
      .get('/user_settings/mandates/lender', {
        params: {
          page: pagination.current,
          per_page: pagination.pageSize,
        },
      })
      .then(({ data }) => {
        setMandatesData({
          ...data,
          ready: true,
        });
      })
      .catch((reason) => message.error(reason.response?.data.error))
      .finally(() =>
        setMandatesData((prev) => ({
          ...prev,
          ready: true,
        })),
      );
  }, [pagination]);

  const deleteMandate = useCallback(() => {
    const params = { id: activeModal.id };

    API()
      .delete('/user_settings/mandates/lender/creation', { params })
      .then(() => {
        setMandatesData((prev) => ({
          ...prev,
          mandates: prev.mandates.filter((i) => i.id !== activeModal.id),
        }));
        setActiveModal({ show: '', data: null });

        message.success('Mandate deleted successfully');
      });
  }, [activeModal.id]);

  const handleCopyMandate = useCallback(
    (mandateId) => {
      setMandatesData((prev) => ({
        ...prev,
        ready: false,
      }));

      API()
        .post('/user_settings/mandates/lender/copy', { id: mandateId })
        .then(({ data }) => {
          if (Number(data.mandate_id) >= 0) {
            message.info('Mandate successfully copied!');
          }
          getMandates();
        });
    },
    [getMandates],
  );

  const columns = useMemo(
    () => [
      {
        dataIndex: 'name',
        key: 'name',
        title: 'Mandate name',
        width: 194,
      },
      {
        dataIndex: 'is_active',
        key: 'is_active',
        title: 'Active/Not active',
        render: (bool) => {
          const color = bool ? '#73D13D' : '#FF7875';

          return (
            <strong>
              <span style={{ color }} className="status-tag">
                <div style={{ backgroundColor: color }} className="color-indicator" />
                {bool ? 'Active' : 'Not active'}
              </span>
            </strong>
          );
        },
      },
      {
        dataIndex: 'LAE_assigned',
        key: 'LAE_assigned',
        title: 'Assigned to',
      },
      {
        dataIndex: 'updated_at',
        key: 'updated_at',
        title: 'Recent Update',
      },
      {
        dataIndex: 'actions',
        key: 'actions',
        title: '',
        render: (_, obj) => (
          <Space size="large">
            <CustomButton
              primary
              onClick={() => {
                history.push(`/onboarding/mandates/view_mandate/${obj.id}`);
              }}
              text="View"
            />
            <EditIcon onClick={() => history.push(`/onboarding/mandates/edit_mandate/${obj.id}`)} />
            <Tooltip title="Copy">
              <img onClick={() => handleCopyMandate(obj.id)} src={copyIcon} alt="Copy" />
            </Tooltip>
            <BinIcon onClick={() => setActiveModal({ show: 'delete_mandate', id: obj.id })} />
          </Space>
        ),
      },
    ],
    [history, handleCopyMandate],
  );

  useEffect(() => {
    getMandates();
  }, [pagination, getMandates]);

  const goToCreateEditMandate = useCallback(() => {
    history.push({
      pathname: '/onboarding/mandates/create_mandate',
      state: { pageBg: '#FFFFFF' },
    });
  }, [history]);

  return (
    <MainLayout title="Mandates">
      <div className="mandates_wrapper table_with_header_wrapper table_with_pagination_wrapper">
        <div className="section_title_wrapper">
          <SectionTitle title="Mandates" />
          <CustomButton onClick={goToCreateEditMandate} primary svg={<PlusIcon />} text="Create" />
        </div>

        <div className="section_table_wrapper table_with_header table_with_pagination">
          <UniversalTable
            columns={columns}
            dataSource={mandatesData.mandates}
            loading={!mandatesData.ready}
            pagination={{
              ...pagination,
              total: mandatesData.total,
            }}
            rowKey="id"
            scroll={{ x: 'max-content' }}
            setPagination={setPagination}
            withHeader={false}
          />
        </div>
        <DeleteMandateModal
          deleteMandate={deleteMandate}
          onCloseActiveModal={() => setActiveModal({ show: '', data: null })}
          open={activeModal.show === 'delete_mandate'}
        />
      </div>
    </MainLayout>
  );
}
