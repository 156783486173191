import { useContext } from 'react';
import { Col, Form, Row, Space } from 'antd';
import LarAppContext from 'components/LarAppNew/context/LarAppContext';
import numberToComma from 'constants/numberToComma';
import FormItem from './FormItem';

function PropertyIncomeExpenseMain({ form, total, setRequiredFields, onFieldsChange, exp }) {
  const { incomes } = useContext(LarAppContext);

  return (
    <>
      <Row style={{ marginBottom: 8 }}>
        <Col span={6} />
        <Col span={16} offset={1}>
          <Space size={190}>
            <div>Monthly</div>
            <div>Annual</div>
          </Space>
        </Col>
      </Row>
      <Form.Item label={<b>Income</b>} />
      {incomes
        ?.filter((el) => el?.type === 'income')
        ?.map((el) => (
          <FormItem
            setRequiredFields={setRequiredFields}
            {...el}
            key={el.id}
            onFieldsChange={onFieldsChange}
            form={form}
          />
        ))}
      <Form.Item label={<b>Expenses</b>} />
      {incomes
        ?.filter((el) => el?.type === 'expenses')
        ?.map((el) => (
          <FormItem
            {...el}
            key={el.id}
            form={form}
            onFieldsChange={onFieldsChange}
            setRequiredFields={setRequiredFields}
          />
        ))}
      <Row align="middle" style={{ marginTop: 24 }}>
        <Col span={6}>
          <span>
            <b>Total expenses</b>
          </span>
        </Col>
        <Col span={16} offset={1}>
          <Space size={30} style={{ background: '#F5F5F5', width: '100%', maxWidth: '460px' }}>
            <div style={{ padding: 8, width: 215 }}>
              <b>${numberToComma(Math.round(exp))}</b>
            </div>
            <div style={{ padding: 8, width: 215 }}>
              <b>${numberToComma(Math.round(exp * 12))}</b>
            </div>
          </Space>
        </Col>
      </Row>
      <Row align="middle" style={{ marginTop: 8 }}>
        <Col span={6}>
          <span>
            <b>NOI</b>
          </span>
        </Col>
        <Col span={16} offset={1}>
          <Space size={30} style={{ background: '#F5F5F5', width: '100%', maxWidth: '460px' }}>
            <div style={{ padding: 8, width: 215 }}>
              <b>${numberToComma(Math.round(total))}</b>
            </div>
            <div style={{ padding: 8, width: 215 }}>
              <b>${numberToComma(Math.round(total * 12))}</b>
            </div>
          </Space>
        </Col>
      </Row>
    </>
  );
}

export default PropertyIncomeExpenseMain;
