export default class regExp {
  static email = /^[a-z0-9_.+-]+@[a-z0-9-]+\.[a-z0-9-.]+$/i;

  static number_optional_decimal = /^\d{0,2}(?:\.)?(?:\.\d)?$/;

  static number_percent_format = /^$|((100)|(\d{1,2}(\.\d*)?))%?$/;

  static number_percent_three_decimal = /^$|((100)|(\d{1,2}(\.\d{0,3})?))%?$/;

  static one_number_one_decimal = /^[0-9]\d{0}(\.\d{0,1})?%?$/;

  static one_number_two_decimal = /^[0-9]\d{0}(\.\d{0,2})?%?$/;

  static only_letters = /^[a-zA-Z]+$/;

  static only_number = /^\d*$/;

  static phone_number = /^([0-9+]*)$/;

  static two_number_one_decimal = /^[0-9]\d{0,1}(\.\d{0,1})?%?$/;

  static two_number_two_decimal = /^[0-9]\d{0,1}(\.\d{0,2})?%?$/;

  static two_number_three_decimal = /^[0-9]\d{0,1}(\.\d{0,3})?%?$/;

  static youtube_link = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp)?[\w?=]*)?/;
}
