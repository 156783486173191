import './styles/popup_tabs.css';
import { Tabs } from 'antd';
import { useContext, useState } from 'react';
import LARDBContext from 'pages/Onboarding/LARDB/LARDBContext';
import PlaceBid from 'pages/Onboarding/BidApplication/PlaceBid';
import Description from './Description';
import DiligenceItemNotes from './DiligenceItemNotes';
import FinalUnderwritingPreview from '../Underwriting/FinalUnderwritingPreview';
import KeyFields from './KeyFields';

export default function PopupTabs({
  changeEditField,
  closeDiligenceItem,
  editFields,
  jointInfo = false,
  larData,
  placeBidEdit,
  role,
  setEditFields,
  setPlaceBidEdit,
  setViewDiligence,
  submitEditField,
  updateDiligenceList,
  viewDiligence,
}) {
  const { dd } = useContext(LARDBContext);
  const [activeModal, setActiveModal] = useState({ show: false, data: null });

  const isPLA = /^superadmin$|^pla$|^lom$/i.test(role);

  function handlePreviewBid(data) {
    setActiveModal({ show: 'final_underwriting_preview', data });
  }

  function closeActiveModal() {
    setActiveModal({ show: false, data: null });
  }

  return (
    <div className="diligence-content">
      <Tabs defaultActiveKey={viewDiligence.defaultTab || 'activity'}>
        {viewDiligence?.item_type === 'final_approval' || (
          <Tabs.TabPane tab="Activity" key="activity">
            {jointInfo && (
              <Description
                changeEditField={changeEditField}
                editFields={editFields}
                isPLA={isPLA}
                setViewDiligence={setViewDiligence}
                submitEditField={submitEditField}
                viewDiligence={viewDiligence}
              />
            )}
            <DiligenceItemNotes viewDiligenceId={viewDiligence.id} type="activity" />
          </Tabs.TabPane>
        )}
        {viewDiligence?.item_type === 'final_approval' && (
          <>
            {(placeBidEdit && viewDiligence?.bids?.length === 2) ||
            (!placeBidEdit && viewDiligence?.bids?.length < 2) ? (
              <Tabs.TabPane tab="Info" key="info">
                <PlaceBid
                  broker_fee={dd?.lar?.broker_fee}
                  diligenceItemId={viewDiligence.id}
                  edit={viewDiligence?.bids?.length === 2}
                  bids={viewDiligence?.bids}
                  diligenceMode
                  setPlaceBidEdit={setPlaceBidEdit}
                  drawerType={false}
                  larData={larData}
                  handlePreviewBid={handlePreviewBid}
                  getDataOnRender={false}
                  onDiligenceCancel={closeDiligenceItem}
                  onPlaceBid={() => {
                    setPlaceBidEdit(false);
                    closeActiveModal();
                    updateDiligenceList();
                  }}
                  placeBidUrl="/LAR/dd_center/item/bid"
                  title={false}
                />
              </Tabs.TabPane>
            ) : null}
            <FinalUnderwritingPreview
              setPlaceBidEdit={setPlaceBidEdit}
              role={role}
              viewDiligence={{
                ...viewDiligence,
                bids: placeBidEdit
                  ? [activeModal?.data?.bid_preview, ...viewDiligence?.bids.slice(1)]
                  : [activeModal?.data?.bid_preview, ...viewDiligence?.bids],
              }}
              closeActiveModal={closeActiveModal}
              closeDiligenceItem={closeDiligenceItem}
              setViewDiligence={setViewDiligence}
              submit={activeModal?.data?.onSubmit}
              visible={activeModal.show === 'final_underwriting_preview'}
            />
          </>
        )}
        <Tabs.TabPane
          key="discussion"
          tab={viewDiligence?.item_type === 'final_approval' ? 'History' : 'Discussion Board'}
        >
          <DiligenceItemNotes viewDiligenceId={viewDiligence.id} type="discussion" />
        </Tabs.TabPane>
        {jointInfo || (
          <Tabs.TabPane tab="Info" key="info">
            {viewDiligence?.item_type === 'bid_terms_updates' ? (
              <PlaceBid
                broker_fee={dd?.lar?.broker_fee}
                diligenceItemId={viewDiligence.id}
                diligenceMode
                drawerType={false}
                getDataOnRender={false}
                onDiligenceCancel={closeDiligenceItem}
                onPlaceBid={() => {
                  // TODO: This should refresh current data
                }}
                placeBidUrl="/LAR/dd_center/item/bid"
                title={false}
              />
            ) : (
              <>
                <Description
                  changeEditField={changeEditField}
                  editFields={editFields}
                  isPLA={isPLA}
                  setViewDiligence={setViewDiligence}
                  submitEditField={submitEditField}
                  viewDiligence={viewDiligence}
                />
                <KeyFields
                  data={viewDiligence.key_fields}
                  editFields={editFields}
                  isPLA={isPLA}
                  setEditFields={setEditFields}
                  setViewDiligence={setViewDiligence}
                  submitEditField={submitEditField}
                />
              </>
            )}
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  );
}
