import { Modal } from 'antd';
import styles from './styles.module.css';

export default function ConfirmTokenResetModal({ isOpen, onCancel }) {
  return (
    <Modal
      okText="Confirm"
      width={660}
      okButtonProps={{ type: 'dark' }}
      title={<p style={{ fontSize: 18 }}>Confirm Token Reset</p>}
      bodyStyle={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 24, paddingTop: 0 }}
      // onOk={handleContinue}
      open={isOpen}
      onCancel={onCancel}
    >
      <div className={styles.container}>
        You are about to reset the API token for the selected account(s).
        <br /> This action will invalidate the current token and generate a new one.
        <br /> Are you sure you want to proceed?
      </div>
    </Modal>
  );
}
