import { Button, Col, Row, Space, Typography } from 'antd';
import { CloseIcon, FileInvoiceIcon, NotificationIcon } from 'assets/icons';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { onDownloadFile } from 'utils/files';
import PropTypes from 'prop-types';
import parseFromET from 'constants/parseFromET';
import styles from './PublicMessage.module.css';

function getMinsAgo(dateString) {
  return parseFromET(dateString).fromNow();
}

export default function PublicMessage({ created_at, file, id, message, onClose }) {
  const role = useSelector((state) => state.user.userData?.role);
  const [delta, setDelta] = useState(getMinsAgo(created_at));
  const isFileIncluded = file.length > 0;
  const hasClose = [
    'Lender Admin',
    'Lender Account Executive',
    'superadmin',
    'PLA',
    'Broker Admin',
    'Broker Loan Manager',
  ].includes(role);

  /* Update the time each 10 seconds */
  useEffect(() => {
    const interval = setInterval(() => setDelta(getMinsAgo(created_at)), 1e4);

    return () => clearInterval(interval);
  }, [created_at]);

  function handleDownloadFile(url, name) {
    return () => {
      if (url) {
        onDownloadFile(url, name);
      }
    };
  }

  const Icon = isFileIncluded ? FileInvoiceIcon : NotificationIcon;

  return (
    <div className={styles.root}>
      <Row gutter={[10, 10]} wrap={false} justify="space-between">
        <Col>
          <div className={styles.header}>
            <Icon />
            <Typography.Title level={5} className={styles.title}>
              {isFileIncluded ? 'The broker added new files to the deal profile' : 'Public message'}
            </Typography.Title>
          </div>
        </Col>
        <Col>{hasClose && <CloseIcon className={styles.close} onClick={() => onClose(id)} />}</Col>
      </Row>
      <Row gutter={[10, 10]} justify="space-between">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <div className={styles.messageText}>{message}</div>
            </Col>
            <Col>
              <time>{delta}</time>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {isFileIncluded && (
            <Space className={styles.viewButtons}>
              {file.map((f) => (
                <Button key={f.id} onClick={handleDownloadFile(f.elem, f.file_name)}>
                  View Document
                </Button>
              ))}
            </Space>
          )}
        </Col>
      </Row>
    </div>
  );
}

PublicMessage.propTypes = {
  created_at: PropTypes.string,
  file: PropTypes.array,
  id: PropTypes.number,
  message: PropTypes.string,
  onClose: PropTypes.func,
};
