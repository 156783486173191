import { useRef, useState } from 'react';
import classNames from 'classnames';
import { acceptedMimes } from 'constants/mimes';
import { UploadIcon } from 'assets/icons/icons';
import Button from '../UI/Button';
import CustomAvatar from '../UI/Avatar';
import ImageCropModal from '../ImageCropModal';
import './upload_avatar.css';

function AvatarUpload({
  avatarPlaceholderName,
  btn_text,
  companyLogoFormat = false,
  description,
  disabled,
  error,
  errorMessage,
  preview,
  propsUpload,
}) {
  const [logoCropModal, setLogoCropModal] = useState({ visible: false, file: null });
  const uploadRef = useRef();

  function handleSelectPic() {
    setLogoCropModal({ visible: true, file: uploadRef.current.files[0] });
  }

  function handleCroppedPhoto(blob) {
    setLogoCropModal((prev) => ({ ...prev, visible: false }));
    propsUpload(blob);
  }

  function resetFileInput() {
    uploadRef.current.value = null;
  }

  return (
    <div className="wrapper__avatar">
      <div className={classNames('avatar', { avatar_error: error })}>
        <input
          accept={acceptedMimes.avatar.join()}
          hidden
          onChange={handleSelectPic}
          onClick={resetFileInput}
          ref={uploadRef}
          type="file"
        />
        <CustomAvatar
          companyLogoFormat={companyLogoFormat}
          fullName={avatarPlaceholderName}
          onClick={() => !disabled && uploadRef.current.click()}
          size={80}
          src={preview}
        />
      </div>
      <Button
        className="avatar_upload"
        disabled={disabled}
        onClick={() => uploadRef.current.click()}
        primary
        svg={UploadIcon({ fill: '#fff' })}
        text={btn_text ?? 'Upload photo'}
      />
      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
      {description && <p className="description">{description}</p>}
      <ImageCropModal
        file={logoCropModal.file}
        format={companyLogoFormat ? 'logo' : 'avatar'}
        onCancel={() => setLogoCropModal((prev) => ({ ...prev, visible: false }))}
        onOk={handleCroppedPhoto}
        visible={logoCropModal.visible}
      />
    </div>
  );
}

export default AvatarUpload;
