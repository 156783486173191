import './styles/invites_auction.css';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import Button from 'components/UI/Button';
import DropdownMenu from 'components/CustomComponents/DropdownMenu';
import InvitesAuctionComponent from 'components/OnBoarding/SuperAdmin/InvitesAuction';
import moment from 'moment';
import {
  bidDeadlineHurdleOptions,
  maxNumInvitesOptions,
  maxNumOfLendersOptions,
  minNumInvitesOptions,
  minNumOfLendersOptions,
  minSmartMatchScoreOptions,
} from './constants/dropdownOptions';

export default function InvitesAuction() {
  const [advanced, setAdvanced] = useState({ loading: false });
  const [general, setGeneral] = useState({ loading: false });
  const [disabledHours, setDisabledHours] = useState([]);
  const [isEdited, setIsEdited] = useState({ invites: false, auction: false });

  const onSubmitGeneral = (event) => {
    event.preventDefault();
    let error = false;

    Object.entries(general).forEach((i) => {
      if (!(i[1] instanceof Object)) {
        return;
      }

      if (i[1].type === 'select') {
        if (typeof i[1].value.value === 'undefined') {
          setGeneral((prevState) => ({
            ...prevState,
            [i[0]]: {
              ...prevState[i[0]],
              error: true,
            },
          }));
          error = true;
        }
      }
    });

    if (error) {
      return;
    }

    const reqObj = {};

    Object.entries(general).forEach((i) => {
      if (!(i[1] instanceof Object)) {
        return;
      }

      if (i[1].type === 'select') {
        reqObj[i[0]] = i[1].value.value;
      }
      if (i[1].type === 'radio') {
        reqObj[i[0]] = i[1].value;
      }
    });

    API()
      .post('/superadmin/settings/auction/general', reqObj)
      .then(() => {
        message.success('You successfully updated general settings');
      });
  };

  const onSubmitAuction = (event) => {
    event.preventDefault();
    let error = false;
    Object.entries(advanced).forEach((i) => {
      if (i[1] instanceof Object) {
        Object.entries(i[1]).forEach((item) => {
          if (!item[1].value && item[0] !== 'day_time') {
            setAdvanced((prevState) => ({
              ...prevState,
              [i[0]]: {
                ...prevState[i[0]],
                [item[0]]: {
                  ...prevState[i[0]][item[0]],
                  error: true,
                },
              },
            }));
            error = true;
          }
        });
      }
    });

    if (error) {
      return;
    }

    const reqObj = {};

    Object.entries(advanced).forEach((i) => {
      if (i[1] instanceof Object) {
        Object.entries(i[1]).forEach((item) => {
          if (item[1].value) {
            if (item[1].value instanceof Object) {
              reqObj[item[0]] = item[1].value.value;
            } else {
              reqObj[item[0]] = item[1].value;
            }
          }
        });
      }
    });

    delete reqObj.day_time;

    API()
      .post('/superadmin/settings/auction/advanced', reqObj)
      .then(() => {
        message.success('You successfully updated general settings');
      });
  };

  useEffect(() => {
    Promise.all([
      API().get('/superadmin/settings/auction/general'),
      API().get('/superadmin/settings/auction/advanced'),
      API().get('/superadmin/settings/general'),
    ])
      .then((res) => {
        const { general_settings } = res[0].data;
        const { advanced_settings } = res[1].data;
        const { business_day_end, business_day_start } = res[2].data.general_settings;
        if (business_day_start && business_day_end) {
          const start_time = moment(business_day_start.split(' ').slice(0, 2).join(' '), ['h:mm A']).format('H');
          const end_time = moment(business_day_end.split(' ').slice(0, 2).join(' '), ['h:mm A']).format('H');
          const dis_hours = [];
          for (let i = 0; i < +start_time; i++) {
            dis_hours.push(i);
          }
          for (let i = 24; i >= +end_time; i--) {
            dis_hours.push(i);
          }
          setDisabledHours(dis_hours);
        }

        setGeneral({
          loading: true,
          lender_bid_update_notifications: {
            value: general_settings.lender_bid_update_notifications,
            type: 'radio',
            options: [
              { text: 'Immediately', value: 'immediately' },
              { text: 'Hourly', value: 'every_hour' },
              { text: 'None', value: 'never' },
            ],
          },
          max_lenders_for_auction_participation: {
            value: {
              value: general_settings.max_lenders_for_auction_participation,
              preview: general_settings.max_lenders_for_auction_participation,
            },
            error: false,
            type: 'select',
            errorMessage: 'Select the option',
          },
          min_lenders_for_auto_invite: {
            value: {
              value: general_settings.min_lenders_for_auto_invite,
              preview: general_settings.min_lenders_for_auto_invite,
            },
            error: false,
            type: 'select',
            errorMessage: 'Select the option',
          },
          max_number_of_invites: {
            value: {
              value: general_settings.max_number_of_invites,
              preview: general_settings.max_number_of_invites,
            },
            error: false,
            type: 'select',
            errorMessage: 'Select the option',
          },
          min_number_of_invites: {
            value: {
              value: general_settings.min_number_of_invites,
              preview: general_settings.min_number_of_invites,
            },
            error: false,
            type: 'select',
            errorMessage: 'Select the option',
          },
          min_smartmatch_score_for_lenders: {
            value: {
              value: general_settings.min_smartmatch_score_for_lenders,
              preview: general_settings.min_smartmatch_score_for_lenders,
            },
            error: false,
            type: 'select',
            errorMessage: 'Select the option',
          },
          bid_deadline_hurdle: {
            value: {
              value: general_settings.bid_deadline_hurdle,
              preview: general_settings.bid_deadline_hurdle,
            },
            error: false,
            type: 'select',
            errorMessage: 'Select the option',
          },
        });

        const parsedData = Object.entries(advanced_settings).map(([key, value]) => [
          key,
          {
            value,
            erro: null,
          },
        ]);

        setAdvanced({
          loading: true,
          afternoon: Object.fromEntries(parsedData),
        });
      })
      .catch(console.error);
  }, []);

  const onSelectGeneral = (value, name) => {
    if (!isEdited.invites) {
      setIsEdited((state) => ({ ...state, invites: true }));
    }
    setGeneral((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
        error: false,
      },
    }));
  };

  const onChangeAuctionDate = (dateString, key, name) => {
    if (!isEdited.auction) {
      setIsEdited((state) => ({ ...state, auction: true }));
    }

    setAdvanced((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        [name]: {
          value: dateString,
          error: false,
        },
      },
    }));
  };

  return (
    <div className="wrapper_invites_auction">
      <form className="invites_wrapper" onSubmit={onSubmitGeneral}>
        <p className="section_title">Invites</p>
        <div className="two_col invites">
          <DropdownMenu
            options={minNumInvitesOptions}
            className="bordered"
            onSelect={(val) => onSelectGeneral(val, 'min_number_of_invites')}
            title="Min number of invites for manual scheduling"
            error={general.min_number_of_invites?.error}
            errorMessage={general.min_number_of_invites?.errorMessage}
            value={general.loading ? general.min_number_of_invites.value.preview : ''}
          />
          <DropdownMenu
            options={maxNumInvitesOptions}
            className="bordered"
            onSelect={(val) => onSelectGeneral(val, 'max_number_of_invites')}
            title="Max number of invites"
            error={general.max_number_of_invites?.error}
            errorMessage={general.max_number_of_invites?.errorMessage}
            value={general.loading ? general.max_number_of_invites.value.preview : ''}
          />
        </div>
        <div className="two_col smart_match_score">
          <DropdownMenu
            options={minSmartMatchScoreOptions}
            className="bordered"
            onSelect={(val) => onSelectGeneral(val, 'min_smartmatch_score_for_lenders')}
            title="Minimum smartmatch score required for a lenders"
            error={general.min_smartmatch_score_for_lenders?.error}
            errorMessage={general.min_smartmatch_score_for_lenders?.errorMessage}
            value={general.loading ? general.min_smartmatch_score_for_lenders.value.preview : ''}
          />
          <DropdownMenu
            options={bidDeadlineHurdleOptions}
            className="bordered"
            onSelect={(val) => onSelectGeneral(val, 'bid_deadline_hurdle')}
            title="Bid deadline hurdle"
            error={general.bid_deadline_hurdle?.error}
            errorMessage={general.bid_deadline_hurdle?.errorMessage}
            value={general.loading ? general.bid_deadline_hurdle.value.preview : ''}
          />
        </div>
        <div className="two_col auctions">
          <DropdownMenu
            options={minNumOfLendersOptions}
            className="bordered"
            onSelect={(val) => onSelectGeneral(val, 'min_lenders_for_auto_invite')}
            title="Minimum number of lenders for auto invite scheduling"
            error={general.min_lenders_for_auto_invite?.error}
            errorMessage={general.min_lenders_for_auto_invite?.errorMessage}
            value={general.loading ? general.min_lenders_for_auto_invite.value.preview : ''}
          />
          <DropdownMenu
            options={maxNumOfLendersOptions}
            className="bordered"
            onSelect={(val) => onSelectGeneral(val, 'max_lenders_for_auction_participation')}
            title="Maximum number of lenders that can participate in auction "
            error={general.max_lenders_for_auction_participation?.error}
            errorMessage={general.max_lenders_for_auction_participation?.errorMessage}
            value={general.loading ? general.max_lenders_for_auction_participation.value.preview : ''}
          />
        </div>
        <Button text="Save" type="submit" disabled={!isEdited.invites} className="btn_darkblue submit" />
      </form>
      <form className="auctions" onSubmit={onSubmitAuction}>
        <InvitesAuctionComponent
          onChangeDate={onChangeAuctionDate}
          disabledHours={disabledHours}
          data={advanced.loading ? advanced.afternoon : null}
          title="Afternoon"
        />
        <Button text="Save" type="submit" disabled={!isEdited.auction} className="btn_darkblue submit" />
      </form>
    </div>
  );
}
