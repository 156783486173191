import { Button } from 'antd';
import classNames from 'classnames';

function SimpleButton({ text, handleClick, classes = [], disabled = false }) {
  return (
    <Button
      type="text"
      className={classNames(['simple-button', classes].flat())}
      onClick={handleClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}

export default SimpleButton;
