import { ArrowIcon, FileInvoiceIcon, HomeIcon } from 'assets/icons';
import Modal from 'components/Modals/Modal/Modal';
import classes from './styles/ConvertToLARModal.module.css';

export default function ConvertToLARModal({ larId = 0, ...rest }) {
  return (
    <Modal {...rest} okText="Confirm" title="Convert scenario to an auction">
      <div className={classes.iconsBox}>
        <div className={classes.blueIconCircle}>
          <FileInvoiceIcon colour="white" size="2.5rem" strokeWidth={1.5} />
        </div>
        <ArrowIcon colour="brand-5" direction="right" size="2.5rem" strokeWidth={1.5} />
        <div className={classes.greenIconCircle}>
          <HomeIcon colour="white" size="2.5rem" strokeWidth={1.5} />
        </div>
      </div>
      <p>When you select confirm the following will happen:</p>
      <ol>
        <li>
          The current scenario will be closed. You will no longer be able to access the lender responses or related
          information.
        </li>
        <li>
          The loan request and all completed fields will be cloned and available for you to edit as a new loan request.
        </li>
        <li>{`The loan request will be assigned Deal ID: #${larId}`}</li>
        <li>The loan request will be listed in your “Pending” pipeline table with status “Incomplete”</li>
        <li>You must complete any additional fields in the loan request that are required for an auction.</li>
      </ol>
    </Modal>
  );
}
