import { Route } from 'react-router-dom';
import Navigations from 'routers/Navigations/index';
import PageHeader from 'components/OnBoarding/PageHeader/PageHeader';
import Logo from '../../../components/OnBoarding/Logo';

export default function MainRouter() {
  return (
    <>
      <Logo />
      <PageHeader registration />
      <div className="main-registration-wrapper-page">
        {Navigations.mainNavigationRegisterCompany.map((route) => (
          <Route path={route.path} exact component={route.main} key={route.path} />
        ))}
      </div>
    </>
  );
}
