import { Drawer, Input, Typography, Upload, Space } from 'antd';
import { FileAddFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import moment from 'moment';
import { updatePublicMessagesSuccess } from 'store/actions/lar';
import CustomButton from 'components/UI/Button';
import styles from './PostMessageDrawer.module.css';

export default function PostMessageDrawer({ API, onClose, visible }) {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.userData.role);
  const [attachment, setAttachment] = useState(null);
  const [text, setText] = useState('');
  const isBroker = /broker/i.test(role);

  function handleSubmit() {
    API.postPublicMessage(text, attachment).then(() => {
      dispatch(
        updatePublicMessagesSuccess({
          created_at: moment().format('DD mmm yyyy, HH:MM'),
          message: text,
          file: attachment,
        }),
      );
      setText('');
      setAttachment(null);
      onClose();
    });
  }

  return (
    <Drawer
      destroyOnClose
      mask
      onClose={onClose}
      placement="right"
      title="Post a public message"
      visible={visible}
      width={500}
      className={styles.root}
    >
      <Space direction="vertical">
        <Input.TextArea
          value={text}
          onChange={({ target: t }) => setText(t.value)}
          autoSize={{ minRows: 6, maxRows: 12 }}
        />
        {isBroker && (
          <Typography.Text type="secondary">
            Documents will be added to the deal profile and available to all lenders invited to this deal
          </Typography.Text>
        )}
        <Upload.Dragger
          multiple
          maxCount={3}
          onChange={({ fileList }) => {
            setAttachment(fileList.map((file) => file.originFileObj));
          }}
          customRequest={() => {}}
        >
          <FileAddFilled style={{ color: '#1f2c5f', fontSize: 20 }} />
          <span className="ant-upload-text" style={{ color: '#1f2c5f', marginLeft: 10 }}>
            Add document (Optional)
          </span>
        </Upload.Dragger>
      </Space>
      <div
        style={{
          alignItems: 'center',
          columnGap: 22,
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 15,
        }}
      >
        <CustomButton key="back" onClick={onClose} style={{ width: 200 }} text="Cancel" />
        <CustomButton key="submit" onClick={handleSubmit} primary style={{ width: 200 }} text="Post" />
      </div>
    </Drawer>
  );
}
