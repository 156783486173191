import { Modal, Select, Spin } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { LARAPI } from 'api';

import CustomButton from '../UI/Button';
import ManualEMSModal from '../Modals/ManualEMSModal';

export default function ShareLoanProfileModal({ onCloseActiveModal, visible, team, larId, submitShare }) {
  const [mainRecipientAccepted, setMainRecipientAccepted] = useState(false);
  const [mainRecipientId, setMainRecipientId] = useState(null);
  const [teamState, setTeamState] = useState([]);
  const currentUserId = useSelector((store) => store.user.userData?.id);

  const mainRecipientOptions = teamState?.filter((item) => item.user_id !== currentUserId) || [];
  const larAPI = useMemo(() => new LARAPI(Number(larId)), [larId]);

  useEffect(() => {
    if (visible) {
      if (!team) {
        larAPI.getTeam().then((data) => setTeamState(data));
      } else {
        setTeamState(team);
      }
    }
  }, [larAPI, visible, larId, team]);

  // Clean up after closing modal
  useEffect(() => visible || setMainRecipientAccepted(false), [visible]);

  return (
    <>
      <Modal
        className="wrapper_prospect"
        centered
        footer={
          <div className="wrapper_remove_prospect_btns">
            <CustomButton onClick={onCloseActiveModal} text="Cancel" />
            <CustomButton
              disabled={!mainRecipientId}
              onClick={() => setMainRecipientAccepted(true)}
              primary
              text="Confirm"
            />
          </div>
        }
        onCancel={onCloseActiveModal}
        onOk={onCloseActiveModal}
        title={
          <div className="wrapper_prospect__title">
            <span className="title">Share loan profile</span>
            <span className="description">Please select the main receiver</span>
          </div>
        }
        visible={visible && !mainRecipientAccepted}
      >
        <Spin spinning={!teamState}>
          <div>
            <label>Company member</label>
            <Select
              onChange={setMainRecipientId}
              value={mainRecipientId}
              style={{ width: '100%', marginTop: 10 }}
              options={mainRecipientOptions.map((u) => ({
                label: u.user_name,
                value: u.user_id,
              }))}
            />
          </div>
        </Spin>
      </Modal>
      {teamState && (
        <ManualEMSModal
          mainRecipient={{
            full_name: mainRecipientOptions.find((u) => mainRecipientId === u.user_id)?.user_name,
            user_id: mainRecipientId,
          }}
          onCancel={onCloseActiveModal}
          onSubmit={(form) => submitShare({ ...form, to: mainRecipientId })}
          optionalRecipients={[
            {
              label: 'Lenders',
              items: teamState.map((u) => ({
                full_name: u.user_name,
                user_id: u.user_id,
              })),
            },
          ]}
          templateRoute={
            mainRecipientId ? `/LAR/loan_profile/lender/share?lar_id=${larId}&to=${mainRecipientId}` : undefined
          }
          title="Share loan profile"
          visible={visible && mainRecipientAccepted}
        />
      )}
    </>
  );
}
