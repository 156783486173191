import { BriefcaseIcon, BuildingIcon, NotificationIcon, PadlockIcon, PencilIcon } from 'assets/icons';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CompanyInfo from '../CompanyInfo';
import NMLS from '../NMLS';
import Notifications from '../Notifications';
import Profile from '../Profile';
import Security from '../Security';

export function useUserCategories() {
  const role = useSelector((store) => store.user.userData?.role);

  return useMemo(
    () => [
      {
        Component: Profile,
        description: 'Edit my profile information & profile picture',
        Icon: PencilIcon,
        id: 'profile',
        title: 'My Info',
      },
      {
        Component: Security,
        description: 'Update email & password & Setup security',
        Icon: PadlockIcon,
        id: 'security',
        title: 'Password & Security',
      },
      {
        Component: NMLS,
        description: 'Personal NMLS settings information',
        hidden: /lender/i.test(role) || /borrower/i.test(role),
        Icon: BriefcaseIcon,
        id: 'nmls',
        title: 'Personal NMLS',
      },
      {
        Component: Notifications,
        description: 'Choose which notifications to receive',
        hidden: true, // Not ready yet
        Icon: NotificationIcon,
        id: 'notifications',
        title: 'Notifications',
      },
      {
        Component: CompanyInfo,
        description: 'Set company branding & edit information',
        hidden: !/lender|broker/i.test(role) || /without company/i.test(role),
        Icon: BuildingIcon,
        id: 'company',
        title: 'Company Info',
      },
    ],
    [role],
  );
}
