import {
  GET_ACTIVITY_LOG_LOADING,
  GET_ACTIVITY_LOG_ERROR,
  GET_ACTIVITY_LOG_SUCCESS,
  GET_CONGRATULATIONS_ERROR,
  GET_CONGRATULATIONS_LOADING,
  GET_CONGRATULATIONS_SUCCESS,
  GET_ACTIVITY_LOG_FILTER_SUCCESS,
  GET_ACTIVITY_LOG_FILTER_LOADING,
  GET_ACTIVITY_LOG_FILTER_ERROR,
  GET_CONVERSATION_LOG_SUCCESS,
  GET_CONVERSATION_LOG_LOADING,
  GET_CONVERSATION_LOG_ERROR,
  GET_CONVERSATION_LOG_FILTER_SUCCESS,
  GET_CONVERSATION_LOG_FILTER_LOADING,
  GET_CONVERSATION_LOG_FILTER_ERROR,
  UPDATE_PUBLIC_MESSAGES,
  GET_RATES_TRACKER,
} from 'store/types/lar';

// CONGRATULATIONS
export const fetchCongratulationsSuccess = (payload) => ({
  type: GET_CONGRATULATIONS_SUCCESS,
  payload,
});

export const fetchCongratulationsLoading = () => ({
  type: GET_CONGRATULATIONS_LOADING,
});

export const fetchCongratulationsError = (payload) => ({
  type: GET_CONGRATULATIONS_ERROR,
  payload,
});

// ACTIVITY LOG
export const fetchActivityLogSuccess = (payload) => ({
  type: GET_ACTIVITY_LOG_SUCCESS,
  payload,
});

export const fetchActivityLogLoading = () => ({
  type: GET_ACTIVITY_LOG_LOADING,
});

export const fetchActivityLogError = (payload) => ({
  type: GET_ACTIVITY_LOG_ERROR,
  payload,
});

// ACTIVITY LOG FILTER
export const fetchActivityLogFilterSuccess = (payload) => ({
  type: GET_ACTIVITY_LOG_FILTER_SUCCESS,
  payload,
});

export const fetchActivityLogFilterLoading = () => ({
  type: GET_ACTIVITY_LOG_FILTER_LOADING,
});

export const fetchActivityLogFilterError = (payload) => ({
  type: GET_ACTIVITY_LOG_FILTER_ERROR,
  payload,
});

// CONVERSATION LOG
export const fetchConversationLogSuccess = (payload) => ({
  type: GET_CONVERSATION_LOG_SUCCESS,
  payload,
});

export const fetchConversationLogLoading = () => ({
  type: GET_CONVERSATION_LOG_LOADING,
});

export const fetchConversationLogError = (payload) => ({
  type: GET_CONVERSATION_LOG_ERROR,
  payload,
});

// CONVERSATION LOG FILTER
export const fetchConversationLogFilterSuccess = (payload) => ({
  type: GET_CONVERSATION_LOG_FILTER_SUCCESS,
  payload,
});

export const fetchConversationLogFilterLoading = () => ({
  type: GET_CONVERSATION_LOG_FILTER_LOADING,
});

export const fetchConversationLogFilterError = (payload) => ({
  type: GET_CONVERSATION_LOG_FILTER_ERROR,
  payload,
});

// PUBLIC MESSAGES
export const updatePublicMessagesSuccess = (payload) => ({
  type: UPDATE_PUBLIC_MESSAGES,
  payload,
});

// RATES TRACKER
export const fetchRatesTracker = (payload) => ({
  type: GET_RATES_TRACKER,
  payload,
});
