import { useContext, useEffect, useState } from 'react';
import { Form } from 'antd';
import { numberToCurrency } from 'constants/numberToComma';
import { getDefaultSuccess, getSuccess, onChangeValidator, useFieldsChangeNew } from '../../helpers/larHelpers';
import formSettings from '../../helpers/formSettings';
import LarAppContext from '../../context/LarAppContext';
import LoanFormWrapper from '../../FormWrappers/LoanFormWrapper';
import ProjectFormPurchaseLong from './ProjectFormPurchaseLong';
import ProjectFormPurchaseShort from './ProjectFormPurchaseShort';
import ProjectFormRefi from './ProjectFormRefi';

const getLTVLong = (requestingAmount, propertyValue, maxLtv = 80) => {
  const LTV = Number(((requestingAmount / propertyValue) * 100).toFixed(2));
  const isBadLTV = LTV > maxLtv;
  return {
    LTV,
    isBadLTV,
  };
};

const formName = 'project';

export default function ProjectForm({
  loan_purpose,
  loan_opportunity,
  setSuccess,
  setSuccessLA,
  setProgress,
  setLAProgress,
  isRefinance,
  isPurchaseLong,
  isPurchaseShort,
  form,
  SA_values,
}) {
  const { refi_cash_out, custom_amount_maximum_possible } = useContext(LarAppContext);
  const [cashOut, setCashOut] = useState(!!refi_cash_out);
  const [maximumPossible, setMaximumPossible] = useState(custom_amount_maximum_possible);

  const getSuccessStatus = (fieldsArr) => {
    onChangeValidator(
      form,
      fieldsArr,
      formName,
      {
        isRefinance,
        isPurchaseLong,
        isPurchaseShort,
      },
      SA_values,
    );

    const {
      ARV,
      LTV,
      property_value,
      custom_amount_maximum_possible,
      loan_style_name,
      requesting_amount,
      refi_cash_out,
      renovation_costs,
      remaining_loan_balance,
      current_payment,
    } = form.getFieldsValue();

    if (isPurchaseLong) {
      const isSuccess = getSuccess(fieldsArr, ['closing_date']);
      const { isBadLTV } = getLTVLong(requesting_amount, property_value, SA_values?.long_purchase_LTV);
      const isBadLoanAmount = requesting_amount < SA_values?.minimum_loan_amount;
      const isTooBigLoanAmount = requesting_amount > SA_values?.maximum_loan_amount;
      const isBadPropertyValue = property_value >= 100000000;

      if (isBadLTV && typeof requesting_amount === 'number') {
        form.setFields([
          {
            name: 'property_value',
            errors: ['bad ltv'],
          },
          {
            name: 'requesting_amount',
            errors: ['bad ltv'],
          },
        ]);
      }

      if (isBadLoanAmount && typeof requesting_amount === 'number') {
        form.setFields([
          {
            name: 'requesting_amount',
            errors: [`The minimum loan amount is ${numberToCurrency(SA_values?.minimum_loan_amount)}.`],
          },
        ]);
      }

      if (isTooBigLoanAmount && typeof requesting_amount === 'number') {
        form.setFields([
          {
            name: 'requesting_amount',
            errors: [`The maximum loan amount is ${numberToCurrency(SA_values?.maximum_loan_amount)}.`],
          },
        ]);
      }

      if (isBadPropertyValue) {
        form.setFields([
          {
            name: 'property_value',
            errors: ['Maximum purchase price is $99,999,999'],
          },
        ]);
      }

      return (
        isSuccess && !isBadLTV && !isBadLoanAmount && !isTooBigLoanAmount && !isBadPropertyValue && !!loan_style_name
      );
    }

    if (isPurchaseShort) {
      const isSuccess = getSuccess(fieldsArr, ['closing_date']);
      const MAX_VALUE = 99999999;
      const isBadARV = ARV < property_value + renovation_costs;
      const isBadLoanAmount = requesting_amount < 100000;

      if (isBadARV) {
        form.setFields([
          {
            name: 'ARV',
            errors: ['ARV must be greater than the sum of the purchase price and renovation budget'],
          },
        ]);
      }

      if (ARV > MAX_VALUE) {
        form.setFields([
          {
            name: 'ARV',
            errors: ['Please enter a value less than “99,999,999“'],
          },
        ]);
      }

      if (renovation_costs > MAX_VALUE) {
        form.setFields([
          {
            name: 'renovation_costs',
            errors: ['Please enter a value less than “99,999,999“'],
          },
        ]);
      }

      if (isBadLoanAmount && typeof requesting_amount === 'number') {
        form.setFields([
          {
            name: 'requesting_amount',
            errors: ['The minimum loan amount is $100,000.'],
          },
        ]);
      }

      return isSuccess && !isBadARV && !isBadLoanAmount;
    }

    if (isRefinance) {
      const isSuccess = getSuccess(fieldsArr, [
        'closing_date',
        'interest_rate',
        'current_payment',
        'remaining_loan_balance',
        ...(custom_amount_maximum_possible ? ['refi_cash_out'] : []),
      ]);
      const calculatedLoanAmount = property_value * (LTV / 100);
      const loanPayoffOverPropValue = remaining_loan_balance >= property_value;
      const currentMortgagePaymentOver50k = current_payment >= 5e4;

      const isBadLTA = calculatedLoanAmount < remaining_loan_balance;

      const isBadLoanAmount =
        calculatedLoanAmount < SA_values?.minimum_loan_amount || calculatedLoanAmount > SA_values?.maximum_loan_amount;

      if (loanPayoffOverPropValue) {
        form.setFields([
          {
            name: 'remaining_loan_balance',
            errors: ['The loan payoff must be less than the property value'],
          },
        ]);
      }

      if (currentMortgagePaymentOver50k) {
        form.setFields([
          {
            name: 'current_payment',
            errors: ['The monthly mortgage payment must be less than $50,000'],
          },
        ]);
      }

      if (!cashOut) {
        return (
          isSuccess &&
          !!loan_style_name &&
          !isBadLoanAmount &&
          !!property_value &&
          !!LTV &&
          !loanPayoffOverPropValue &&
          !isBadLTA
        );
      }

      if (cashOut && custom_amount_maximum_possible) {
        return isSuccess && !!loan_style_name && !!property_value && !!LTV && !loanPayoffOverPropValue && !isBadLTA;
      }

      if (cashOut && !custom_amount_maximum_possible) {
        return (
          isSuccess &&
          !!loan_style_name &&
          !!property_value &&
          !!LTV &&
          !!refi_cash_out &&
          !loanPayoffOverPropValue &&
          !isBadLTA
        );
      }

      return isSuccess;
    }
  };

  const getSuccessStatusLA = (fieldsArr) => {
    onChangeValidator(
      form,
      fieldsArr,
      formName,
      {
        isRefinance,
        isPurchaseLong,
        isPurchaseShort,
      },
      SA_values,
    );

    const valuesArr = fieldsArr.map((fieldObj) => fieldObj.value);
    const defaultSuccess = getDefaultSuccess(valuesArr);
    const {
      ARV,
      custom_amount_maximum_possible,
      loan_style_name,
      refi_cash_out,
      property_value,
      renovation_costs,
      requesting_amount,
      remaining_loan_balance,
    } = form.getFieldsValue();

    if (isPurchaseLong) {
      const isSuccess = getSuccess(fieldsArr);
      const { isBadLTV } = getLTVLong(requesting_amount, property_value, SA_values?.long_purchase_LTV);
      const isBadLoanAmount = requesting_amount < SA_values?.minimum_loan_amount;
      const isTooBigLoanAmount = requesting_amount > SA_values?.maximum_loan_amount;
      const isBadPropertyValue = property_value >= 100000000;

      if (isBadLTV && typeof requesting_amount === 'number') {
        form.setFields([
          {
            name: 'property_value',
            errors: ['bad ltv'],
          },
          {
            name: 'requesting_amount',
            errors: ['bad ltv'],
          },
        ]);
      }

      if (isBadLoanAmount && typeof requesting_amount === 'number') {
        form.setFields([
          {
            name: 'requesting_amount',
            errors: [`The minimum loan amount is ${numberToCurrency(SA_values?.minimum_loan_amount)}.`],
          },
        ]);
      }

      if (isTooBigLoanAmount && typeof requesting_amount === 'number') {
        form.setFields([
          {
            name: 'requesting_amount',
            errors: [`The maximum loan amount is ${numberToCurrency(SA_values?.maximum_loan_amount)}.`],
          },
        ]);
      }

      if (isBadPropertyValue) {
        form.setFields([
          {
            name: 'property_value',
            errors: ['Maximum purchase price is $99,999,999'],
          },
        ]);
      }

      return (
        isSuccess && !isBadLTV && !isBadLoanAmount && !isTooBigLoanAmount && !isBadPropertyValue && !!loan_style_name
      );
    }

    if (isPurchaseShort) {
      const MAX_VALUE = 99999999;
      const isBadARV = ARV < property_value + renovation_costs;
      const isBadLoanAmount = requesting_amount < 100000;

      if (isBadARV) {
        form.setFields([
          {
            name: 'ARV',
            errors: ['ARV must be greater than the sum of the purchase price and renovation budget'],
          },
        ]);
      }

      if (ARV > MAX_VALUE) {
        form.setFields([
          {
            name: 'ARV',
            errors: ['Please enter a value less than “100,000,000“'],
          },
        ]);
      }

      if (renovation_costs > MAX_VALUE) {
        form.setFields([
          {
            name: 'renovation_costs',
            errors: ['Please enter a value less than “10,000,000“'],
          },
        ]);
      }

      if (isBadLoanAmount && typeof requesting_amount === 'number') {
        form.setFields([
          {
            name: 'requesting_amount',
            errors: ['The minimum loan amount is $100,000.'],
          },
        ]);
      }

      return defaultSuccess && !isBadARV && !isBadLoanAmount;
    }

    if (isRefinance) {
      const isSuccess = getSuccess(fieldsArr, [
        'closing_date',
        'remaining_loan_balance',
        ...(custom_amount_maximum_possible ? ['refi_cash_out'] : []),
      ]);
      const loanPayoffOverPropValue = remaining_loan_balance >= property_value;
      const LTV = fieldsArr.find((el) => el?.name?.includes('LTV'))?.value;
      const calculatedLoanAmount = property_value * (LTV / 100);
      const isBadLTA = calculatedLoanAmount < remaining_loan_balance;
      const isBadLoanAmount =
        calculatedLoanAmount < SA_values?.minimum_loan_amount || calculatedLoanAmount > SA_values?.maximum_loan_amount;

      if (custom_amount_maximum_possible !== undefined && !custom_amount_maximum_possible && !refi_cash_out) {
        return false;
      }

      return isSuccess && !loanPayoffOverPropValue && !isBadLTA && !isBadLoanAmount;
    }
  };

  const setSuccessStatus = (status = false) => {
    setSuccess(status);
    setProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const setSuccessStatusLA = (status = false) => {
    setSuccessLA(status);
    setLAProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const onSuccess = () => {
    form.submit();
  };

  const onReject = () => {};

  const onFieldsChange = useFieldsChangeNew(
    getSuccessStatus,
    setSuccessStatus,
    onSuccess,
    onReject,
    getSuccessStatusLA,
    setSuccessStatusLA,
  );

  // TODO: TEST SAVE DATA
  useEffect(() => {
    const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
      name: el[0],
      value: el[1],
    }));
    onFieldsChange([1], valuesArr);
    // eslint-disable-next-line
  }, []);

  return (
    <Form {...formSettings} form={form} name={formName} onFieldsChange={onFieldsChange}>
      <LoanFormWrapper
        form={form}
        loan_purpose={loan_purpose}
        loan_opportunity={loan_opportunity}
        PurchaseLongForm={<ProjectFormPurchaseLong onFieldsChange={onFieldsChange} form={form} />}
        PurchaseShortForm={<ProjectFormPurchaseShort form={form} onFieldsChange={onFieldsChange} />}
        RefinanceForm={
          <ProjectFormRefi
            onFieldsChange={onFieldsChange}
            setCashOut={setCashOut}
            cashOut={cashOut}
            maximumPossible={maximumPossible}
            setMaximumPossible={setMaximumPossible}
            form={form}
          />
        }
      />
    </Form>
  );
}
