import { Link, useHistory, useParams } from 'react-router-dom';
import { setBreadCrumbHistory, setBreadCrumbHistorySetValue } from 'store/actions/userActions';
import { Spin, Table, message, Col, Row, Switch, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter';
import API from 'api';
import BreadCrumbHistory from 'components/BreadCrumbHistory';
import DropdownMenu from 'components/CustomComponents/DropdownMenu';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import StatusIndicator from 'components/OnBoarding/EMS/StatusIndicator';
import TextArea from 'components/CustomComponents/TextArea';
import TextInput from 'components/CustomComponents/TextInput';
import useNestedCheckError from 'constants/useNestedCheckError';
import columns from './constants/EditEMSListColumns';
import styles from './styles/EditEMS.module.css';
import 'components/OnBoarding/EMS/styles/edit-ems.css';
import { LENDER_ADMIN } from 'constants/roles';

const initialState = {
  name: {
    error: null,
    errorMessage: 'Fill this label',
    htmlFor: 'name',
    label: 'Nickname',
    required: true,
    value: '',
  },
  description: {
    error: null,
    errorMessage: 'Fill this label',
    htmlFor: 'description',
    label: 'Description',
    placeholder: 'Autosize height based on content lines',
    required: true,
    value: '',
  },
};

const delayOptions = Array.from({ length: 7 }, (_, i) => ({ value: i * 10, preview: `${i * 10}m` }));

function EditEMS() {
  const [fields, setFields] = useState(initialState);
  const [triggerData, setTriggerData] = useState({ ready: false });
  const [loading, setLoading] = useState({
    action: [],
    messageType: [],
    submit: false,
  });
  const { checkEmpty } = useNestedCheckError(fields, setFields);
  const { triggerId } = useParams();
  const breadCrumbHistory = useSelector((store) => store.user.breadCrumbHistory);
  const dispatch = useDispatch();
  const history = useHistory();

  function handleActionChange(checked, item) {
    setLoading((prev) => ({
      ...prev,
      action: [...prev.action, item.id],
    }));

    API()
      .put('/superadmin/triggers', {
        config: [
          {
            role_name: item.role_name,
            template_config: [
              {
                template_type: item.template_type,
                action: checked,
              },
            ],
          },
        ],
        trigger_id: Number(triggerId),
      })
      .then(({ data }) => {
        message.success('Information successfully changed');

        setTriggerData((prev) => ({
          ...prev,
          trigger: data,
        }));
      })
      .finally(() =>
        setLoading((prev) => ({
          ...prev,
          action: prev.action.filter((el) => el !== item.id),
        })),
      );
  }

  function onSubmit(e) {
    e.preventDefault();

    const { error, obj } = checkEmpty(fields);
    setFields(obj);

    if (error) {
      return;
    }

    setLoading((prev) => ({
      ...prev,
      submit: true,
    }));

    API()
      .put('/superadmin/triggers', {
        description: fields.description.value,
        name: fields.name.value,
        trigger_id: Number(triggerId),
      })
      .then(({ data }) => {
        message.success('Information successfully changed');

        setTriggerData((prev) => ({
          ...prev,
          trigger: data,
        }));
      })
      .finally(() =>
        setLoading((prev) => ({
          ...prev,
          submit: false,
        })),
      );
  }

  useEffect(() => goBack(1), []);

  useEffect(() => {
    API()
      .get('/superadmin/triggers', { params: { trigger_id: triggerId } })
      .then(({ data }) => {
        setTriggerData({
          ready: true,
          ...data,
        });

        setFields((prev) => ({
          name: {
            ...prev.name,
            error: false,
            value: data.trigger.name ?? '',
          },
          description: {
            ...prev.description,
            error: false,
            value: data.trigger.description ?? '',
          },
        }));
      });
  }, [triggerId]);

  function goBack(key) {
    dispatch(setBreadCrumbHistorySetValue(key));
  }

  function delaySelect(val, item) {
    API()
      .put('/superadmin/triggers', {
        config: [
          {
            role_name: item.role_name,
            template_config: [
              {
                delay: Number(val.value),
                template_type: item.template_type,
              },
            ],
          },
        ],
        trigger_id: Number(triggerId),
      })
      .then(({ data }) => {
        message.success('Information successfully changed');

        setTriggerData((prev) => ({
          ...prev,
          trigger: data,
        }));
      });
  }

  function switchBoxChange(item) {
    setLoading((prev) => ({
      ...prev,
      messageType: [...prev.messageType, item.id],
    }));
    API()
      .put('/superadmin/triggers', {
        config: [
          {
            role_name: item.role_name,
            template_config: [
              {
                enabled: !item.enabled,
                template_type: item.template_type,
              },
            ],
          },
        ],
        trigger_id: Number(triggerId),
      })
      .then(({ data }) => {
        message.success('Information successfully changed');

        setTriggerData((prev) => ({
          ...prev,
          trigger: data,
        }));
      })
      .finally(() =>
        setLoading((prev) => ({
          ...prev,
          messageType: prev.messageType.filter((el) => el !== item.id),
        })),
      );
  }

  function onChange(event, name) {
    setFields((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        error: false,
        value: event.target.value,
      },
    }));
  }

  function actionSelect(val, item) {
    API()
      .put('/superadmin/triggers', {
        config: [
          {
            role_name: item.role_name,
            template_config: [
              {
                template_type: item.template_type,
                action_id: val.value,
              },
            ],
          },
        ],
        trigger_id: Number(triggerId),
      })
      .then(({ data }) => {
        message.success('Information successfully changed');

        setTriggerData((prev) => ({
          ...prev,
          trigger: data,
        }));
      });
  }

  function handleActionClick(item, linkURI) {
    dispatch(setBreadCrumbHistory([...breadCrumbHistory, { preview: item.id, to: linkURI }]));
  }

  const dataList = useMemo(() => {
    if (!triggerData.ready || !triggerData.trigger?.config || !Object.keys(triggerData.trigger?.config).length) {
      return [];
    }

    const triggerArr = [];

    Object.entries(triggerData.trigger?.config).forEach((config, index) => {
      let valueAmount = 0;

      Object.entries(config[1]).forEach((trigger) => {
        valueAmount += Object.keys(trigger[1]).length;
      });

      if (!valueAmount) {
        return;
      }

      triggerArr.push({
        action: '',
        delay: '',
        id: index,
        messageType: config[0],
      });

      Object.entries(config[1]).forEach((trigger) => {
        if (Object.keys(trigger[1]).length) {
          const i = {
            ...trigger[1],
            role_name: config[0],
            template_type: trigger[0],
          };

          const linkURI = `/onboarding/ems/${triggerId}/channel/${i.id}`;

          triggerArr.push({
            ...i,
            messageType: (
              <Row>
                <Col span={3}>
                  <Switch
                    checked={i.enabled}
                    loading={loading.messageType.includes(i.id)}
                    onChange={() => switchBoxChange(i)}
                    disabled={
                      trigger[0] === 'Email message' &&
                      triggerData?.trigger?.type_name === 'Manual' &&
                      i.role_name === LENDER_ADMIN
                    }
                  />
                </Col>
                <Col>{trigger[0]}</Col>
              </Row>
            ),
            delay: (
              <DropdownMenu onSelect={(val) => delaySelect(val, i)} options={delayOptions} suffix="m" value={i.delay} />
            ),
            action: (
              <Row>
                <Col span={3}>
                  <Switch
                    checked={i.action}
                    loading={loading.action.includes(i.id)}
                    onChange={(checked) => handleActionChange(checked, i)}
                  />
                </Col>
                <Col flex="auto">
                  {trigger[1]?.action_text?.includes('_')
                    ? trigger[1]?.action_text
                        .split('_')
                        .map((i) => capitalizeFirstLetter(i))
                        .join(' ')
                    : trigger[1]?.action_text}
                </Col>
                <Col>
                  <Link onClick={() => handleActionClick(i, linkURI)} to={linkURI}>
                    Edit template
                  </Link>
                </Col>
              </Row>
            ),
          });
        }
      });
    });

    return triggerArr;
  }, [triggerData, triggerId, actionSelect, breadCrumbHistory, delaySelect, dispatch, switchBoxChange, loading]);

  return (
    <Spin spinning={!triggerData.ready}>
      <div className="edit-EMS-wrapper">
        <div className="edit_EMS_wrapper__bcd">
          <BreadCrumbHistory goBack={goBack} history={breadCrumbHistory} historyWeb={history} />
        </div>
        <div className="section-title-wrapper">
          <SectionTitle title={triggerData?.trigger?.category_name} />
          {triggerData.ready && (
            <StatusIndicator
              active={triggerData?.trigger?.type_name?.toLowerCase() !== 'automated'}
              status={`${triggerData?.trigger?.type_name} message`}
            />
          )}
        </div>
        <div className={styles.triggerIdWrapper}>
          <Row>
            <Col span={3}>Trigger ID:</Col>
            <Col>
              <b>{triggerData.trigger?.id}</b>
            </Col>
          </Row>
          {triggerData.trigger?.context && (
            <Row>
              <Col span={3}>Context:</Col>
              <Col>
                <b>{triggerData.trigger.context}</b>
              </Col>
            </Row>
          )}
          {triggerData.trigger?.target && (
            <Row>
              <Col span={3}>Target:</Col>
              <Col>
                <b>{triggerData.trigger.target}</b>
              </Col>
            </Row>
          )}
          {triggerData.trigger?.dev_name && (
            <Row>
              <Col span={3}>Dev name:</Col>
              <Col>
                <b>{triggerData.trigger.dev_name}</b>
              </Col>
            </Row>
          )}
        </div>
        <form onSubmit={onSubmit} className="edit_EMS_wrapper_form">
          <TextInput {...fields.name} onChange={(e) => onChange(e, 'name')} />
          <TextArea {...fields.description} onChange={(e) => onChange(e, 'description')} rows={2} />
          <Button loading={loading.submit} htmlType="submit" type="dark" size="large">
            Save
          </Button>
        </form>
        <h2>Messages</h2>
        <Table
          loading={!triggerData.ready}
          columns={columns}
          dataSource={dataList}
          pagination={false}
          rowKey={({ messageType, id }) => (typeof messageType === 'string' ? messageType : id)}
          scroll={{ x: 1100 }}
        />
      </div>
    </Spin>
  );
}

export default EditEMS;
