import { useContext, useEffect } from 'react';
import { Checkbox, Form, InputNumber } from 'antd';

import LarAppContext from 'components/LarAppNew/context/LarAppContext';
import Type from '../Type';
import Units from '../Units';

function PropertyFormPurchaseShort({ form, totalUnitsRange, setTotalUnitsRange, onFieldsChange }) {
  const {
    property_type,
    owners_occupied,
    property_type_description,
    number_of_rental_units,
    number_of_rental_units_occupied,
    annual_property_insurance,
    annual_property_tax,
  } = useContext(LarAppContext);

  const propertyType = Form.useWatch('property_type', form);
  const rentalUnits = Form.useWatch('number_of_rental_units', form);

  useEffect(() => {
    form.setFieldsValue({
      property_type,
      owners_occupied,
      property_type_description,
      number_of_rental_units,
      number_of_rental_units_occupied,
      annual_property_tax,
      annual_property_insurance,
    });
  }, []);

  // TODO: Refactor this
  useEffect(() => {
    if (propertyType === '2-4 Unit' && (rentalUnits < 2 || rentalUnits > 4)) {
      form.setFieldsValue({
        number_of_rental_units: 2,
      });
    }

    if (propertyType === 'MultiFamily 5-20' && (rentalUnits < 5 || rentalUnits > 1000)) {
      form.setFieldsValue({
        number_of_rental_units: 5,
      });
    }
  }, [totalUnitsRange, rentalUnits, propertyType, form]);

  return (
    <>
      <Type
        number_of_rental_units={number_of_rental_units}
        form={form}
        setTotalUnitsRange={setTotalUnitsRange}
        onFieldsChange={onFieldsChange}
      />
      {propertyType === '2-4 Unit' || propertyType === 'MultiFamily 5-20' ? (
        <Units rentalUnits={rentalUnits} totalUnitsRange={totalUnitsRange} />
      ) : null}
      <Form.Item
        label="Annual Property Tax"
        name="annual_property_tax"
        rules={[
          {
            max: 99999,
            message: 'Please enter a value less than 100,000',
            min: 0,
            type: 'number',
          },
        ]}
        className="auction-required-field"
      >
        <InputNumber
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          style={{ width: 215 }}
          addonBefore="$"
        />
      </Form.Item>
      <Form.Item
        label="Annual Property Insurance"
        name="annual_property_insurance"
        rules={[
          {
            max: 99999,
            message: 'Please enter a value less than 100,000',
            min: 0,
            type: 'number',
          },
        ]}
        className="auction-required-field"
      >
        <InputNumber
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          style={{ width: 215 }}
          addonBefore="$"
        />
      </Form.Item>
      <Form.Item label="Owner occupied" name="owners_occupied" valuePropName="checked">
        <Checkbox />
      </Form.Item>
    </>
  );
}

export default PropertyFormPurchaseShort;
