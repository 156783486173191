import { useHistory } from 'react-router-dom';
import './auth_header.css';
import starportLogo from '../../assets/images/Starport-Menu-Logo.svg';

function AuthHeader() {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <header id="auth_header">
      <div onClick={goBack} className="go-back">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 12H19" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5 12L11 18" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5 12L11 6" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      <img src={starportLogo} alt="Starport logo" />
    </header>
  );
}

export default AuthHeader;
