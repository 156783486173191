import SectionTitle from '../../../components/OnBoarding/SectionTitle';
import LenderDocsTable from '../../../components/OnBoarding/LenderAdmin/LenderDocsTable';

function LenderDocs() {
  return (
    <div>
      <div className="section_title_wrapper">
        <SectionTitle title="Documents" />
      </div>
      <div style={{ marginTop: '24px' }}>
        <LenderDocsTable />
      </div>
    </div>
  );
}
export default LenderDocs;
