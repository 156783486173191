import { useContext, useEffect, useState } from 'react';
import { Checkbox, Form, Input, message } from 'antd';
import { usePlacesWidget } from 'react-google-autocomplete';
import Geocode from 'react-geocode';
import AddressMap from './AddressMap';
import formSettings from '../../helpers/formSettings';
import LarAppContext from '../../context/LarAppContext';
import getAddressFromLatLng from './getAddressFromLatLng';
import { useFieldsChange } from '../../helpers/larHelpers';

Geocode.setApiKey(process.env.REACT_APP_MAPS_API_KEY);
Geocode.setLocationType('ROOFTOP');
process.env.REACT_APP_NODE_ENV === 'development' && Geocode.enableDebug();

function AddressForm({ setSuccess, setSuccessLA, setProgress, setLAProgress, form }) {
  const { property_address, property_zip, property_city, property_state, is_rural } = useContext(LarAppContext);
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const onFieldsChange = useFieldsChange(setSuccess, setProgress, 'address');
  const { ref: addressInputRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      if (!place.geometry) {
        return;
      }
      const { location } = place.geometry;
      getAddressFromLatLng(location.lat(), location.lng()).then((res) => {
        if (!res) {
          message.error(
            'If you encounter problems with Google’s address selector, save your work and then refresh the page.',
          );
          return;
        }
        const { city, state, zip, address } = res;
        const isUnknownData = [state, zip, address].find((el) => el === 'unknown');
        if (isUnknownData) {
          message.error('Please, select another address');
        } else {
          form.setFieldsValue({
            address_all: place.formatted_address,
            property_zip: zip,
            property_address: address,
            property_city: city,
            property_state: state,
          });
          setSuccess(true);
          setProgress((prevState) => ({
            ...prevState,
            address: true,
          }));
          form.submit();
          setSuccessLA(true);
          setLAProgress((prevState) => ({
            ...prevState,
            address: true,
          }));
        }
      });
      setLocation({ lat: location.lat(), lng: location.lng() });
    },
    options: {
      types: ['geocode', 'establishment'],
      language: 'en',
      componentRestrictions: { country: 'us' },
    },
  });

  useEffect(() => {
    Geocode.fromAddress(`${property_zip} ${property_city} ${property_address} ${property_state}`).then(
      ({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        setLocation({ lat, lng });
        form.setFieldsValue({
          address_all: results[0].formatted_address,
          property_address,
          property_zip,
          property_city,
          property_state,
          is_rural,
        });
        setSuccess(true);
        setSuccessLA(true);
        setProgress((prevState) => ({
          ...prevState,
          address: true,
        }));
        setLAProgress((prevState) => ({
          ...prevState,
          address: true,
        }));
        form.submit();
      },
    );
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      form={form}
      {...formSettings}
      name="address"
      onFieldsChange={onFieldsChange}
      initialValues={{
        address_all: null,
        property_address: null,
        property_zip: null,
        property_city: null,
        property_state: null,
        is_rural: false,
      }}
    >
      <Form.Item label="Address" name="address_all" className="scenario-required-field auction-required-field">
        <Input
          ref={(c) => {
            c && addressInputRef && (addressInputRef.current = c.input);
          }}
          style={{ maxWidth: 500 }}
          placeholder="123 Sunset Streeet"
        />
      </Form.Item>
      <Form.Item label=" ">
        <div style={{ minHeight: '300px', maxWidth: 500, position: 'relative' }}>
          <AddressMap coordinates={location} />
        </div>
      </Form.Item>
      <Form.Item label="Is Rural?" valuePropName="checked" name="is_rural">
        <Checkbox />
      </Form.Item>
      <div style={{ display: 'none' }}>
        <Form.Item label="" name="property_address">
          <Input style={{ maxWidth: 445 }} placeholder="What’s your question?" />
        </Form.Item>
        <Form.Item label="" name="property_zip">
          <Input style={{ maxWidth: 445 }} placeholder="What’s your question?" />
        </Form.Item>
        <Form.Item label="" name="property_city">
          <Input style={{ maxWidth: 445 }} placeholder="What’s your question?" />
        </Form.Item>
        <Form.Item label="" name="property_state">
          <Input style={{ maxWidth: 445 }} placeholder="What’s your question?" />
        </Form.Item>
      </div>
    </Form>
  );
}
export default AddressForm;
