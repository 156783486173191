import { useEffect, useState } from 'react';
import { Form, Radio, Space, Table } from 'antd';
import formSettings from '../../helpers/formSettings';
import { coBorrowersData } from '../../helpers/larHelpers';
import EditableRow from './EditableRow';
import EditableCell from './EditableCell';

function CoBorrowersForm(props) {
  const { dataSource, setDataSource } = props;
  const [form] = Form.useForm();
  const [count, setCount] = useState('none');
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const defaultColumns = [
    {
      title: 'First name',
      type: 'text',
      dataIndex: 'first_name',
      key: 'first_name',
      editable: true,
    },
    {
      title: 'Last name',
      dataIndex: 'last_name',
      type: 'text',
      key: 'last_name',
      editable: true,
    },
    {
      title: 'Relationships',
      type: 'select',
      dataIndex: 'relationships',
      key: 'relationships',
      editable: true,
    },
    {
      title: 'FICO',
      key: 'fico',
      type: 'number',
      dataIndex: 'fico',
      editable: true,
    },
  ];

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        type: col.type,
        title: col.title,
        handleSave,
      }),
    };
  });

  useEffect(() => {
    form.setFieldsValue({
      h: dataSource?.length === 0 ? 'none' : dataSource?.length,
    });
    setCount(dataSource?.length === 0 ? 'none' : dataSource?.length);
    setDataSource((prevState) => prevState?.map((el, idx) => ({ ...el, key: idx + 1 })));
    // eslint-disable-next-line
  }, []);

  return (
    <Form {...formSettings} form={form} name="co_borrowers">
      <Form.Item name="h" label="Number of co-borrowers">
        <Radio.Group
          onChange={(e) => {
            setCount(e.target.value);
            if (e.target.value !== 'none' && e.target.value > dataSource.length) {
              setDataSource((prevState) => {
                const newData = [...prevState];
                for (let i = 0; i < e.target.value - dataSource.length; i++) {
                  newData.push({
                    ...coBorrowersData[0],
                    key: dataSource.length + i,
                  });
                }
                return newData;
              });
            } else if (e.target.value !== 'none' && e.target.value < dataSource.length) {
              setDataSource((prevState) => {
                const newData = [...prevState];
                newData.length = e.target.value;
                return newData;
              });
            }
            if (e.target.value === 'none') {
              setDataSource([]);
            }
          }}
        >
          <Space direction="vertical" size="large">
            <Radio value="none">None</Radio>
            <Radio value={1}>1</Radio>
            <Radio value={2}>2</Radio>
            <Radio value={3}>3</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {count === 'none' ? null : (
        <Table
          pagination={false}
          components={components}
          rowClassName={() => 'editable-row'}
          columns={columns}
          dataSource={dataSource}
          size="middle"
        />
      )}
    </Form>
  );
}
export default CoBorrowersForm;
