import './styles/registration.css';
import { message } from 'antd';
import { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import API from 'api';
import Button from 'components/UI/Button';
import CustomButton from 'components/UI/Button';
import fillError from 'constants/fillRegistrationErrors';
import fillFields from 'constants/fillRegistrationFields';
import Modal from 'components/Modals/Modal/Modal';
import otherConst from 'constants/otherConst';
import RegistrationSubHeader from 'components/Registration/RegistrationSubHeader';
import RenderInputs from 'components/RenderInputs';
import { closePopup } from '../../../store/actions/popupsActions';

const initState = {
  sectionTitle_0: {
    title: 'Create Lender Company Profile',
    style: {
      fontWeight: 400,
    },
  },
  name: {
    value: '',
    error: null,
    errorMessage: 'Fill this label',
    title: 'Company name',
  },
  upload_logo: {
    value: null,
    file: null,
    error: null,
    errorMessage: 'Fill this label',
    optional: true,
    title: 'Company logo',
    buttonText: 'Upload a logo',
  },
  sectionTitle_1: {
    title: 'Company address',
    type: 'heading-20',
    style: {
      margin: '38px 0 24px',
    },
  },
  dividedInputs_2: {
    inputs: {
      city: {
        error: null,
        errorMessage: 'Fill this label',
        optional: true,
        title: 'City',
        value: '',
      },
      state: {
        error: null,
        errorMessage: 'Fill this label',
        optional: true,
        options: otherConst.states,
        title: 'State',
        type: 'select',
        value: '',
      },
    },
  },
  dividedInputs_3: {
    inputs: {
      zip: {
        error: null,
        errorMessage: 'Please enter 5 symbols',
        maxLength: 5,
        optional: true,
        regex: '^[0-9]*$',
        title: 'Zip',
        value: '',
      },
    },
  },
};

export default function Company() {
  const [fields, setFields] = useState(initState);
  const [disabledAction, setDisabledAction] = useState(true);
  const [edit, setEdit] = useState(null);
  const [doneTabs, setDoneTabs] = useState({ company: false, profile: false });
  const history = useHistory();
  const role = useSelector((store) => store.user.userData?.role);
  const location = useLocation();
  const activeExitModal = useSelector((state) => state.popups.show);
  const dispatch = useDispatch();

  const prospectId = useMemo(() => Number(new URLSearchParams(location.search).get('id')), [location]);

  const progress = [
    {
      title: 'Company Profile',
      completed: !disabledAction,
    },
    {
      title: 'Personal Profile',
      completed: doneTabs.profile,
      onClick: () => onSave('link', `/registration/lender/user${location.search}` ?? ''),
      clickCondition: !disabledAction,
    },
  ];

  const autocompleteAddressFields = (address, city, state, zip) => {
    onChange({ target: { value: address } }, 'street_address');
    onChange({ target: { value: city } }, 'dividedInputs_2.inputs.city');
    onChange({ target: { value: state.value ?? state } }, 'dividedInputs_2.inputs.state');
    onChange({ target: { value: zip } }, 'dividedInputs_3.inputs.zip');
  };

  const handleExit = () => {
    history.push('/');
    dispatch(closePopup());
  };

  const handleSaveAndExit = () => {
    onSave('link', '/');
    dispatch(closePopup());
  };

  const onCloseActiveModal = () => {
    dispatch(closePopup());
  };

  const onChange = (event, route) => {
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const { value } = event.target;

    setFields((state) => {
      const tempFields = { ...state };

      if (routeLength === 3) {
        tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
          ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
          value,
          error: false,
        };
      } else if (routeLength === 2) {
        tempFields[routeArr[0]][routeArr[1]] = {
          ...tempFields[routeArr[0]][routeArr[1]],
          value,
          error: false,
        };
      } else if (routeLength === 1) {
        tempFields[routeArr[0]] = { ...tempFields[routeArr[0]], value, error: false };
      }

      return tempFields;
    });
  };

  const onUpload = (event, route) => {
    event.preventDefault();
    const file = event.target.files[0];
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const tempFields = { ...fields };

    if (!file) {
      return;
    }

    if (routeLength === 3) {
      tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
        ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
        value: URL.createObjectURL(file),
        file,
      };
    } else if (routeLength === 2) {
      tempFields[routeArr[0]][routeArr[1]] = {
        ...tempFields[routeArr[0]][routeArr[1]],
        value: URL.createObjectURL(file),
        file,
      };
    } else if (routeLength === 1) {
      tempFields[routeArr[0]] = {
        ...tempFields[routeArr[0]],
        value: URL.createObjectURL(file),
        file,
      };
    }

    setFields(tempFields);
  };

  useEffect(() => {
    API()
      .get('/prospects/BDR/check_status', { params: { id: prospectId } })
      .then(({ data: { company_is_full, profile_is_full, agreement } }) => {
        setDoneTabs({
          company: company_is_full,
          profile: profile_is_full,
          agreement,
        });
      });

    API()
      .get('/prospects/BDR/company', { params: prospectId ? { id: prospectId } : {} })
      .then(({ data }) => {
        setEdit(data.actions.includes('Edit'));
        fillFields(data, true, fields, setFields);
        window.scrollTo(0, 0);
      });

    // eslint-disable-next-line
  }, []);

  const onError = (routes) => {
    const tempFields = { ...fields };

    routes.forEach((route) => {
      const routeArr = route.split('.');
      const routeLength = routeArr.length;

      if (routeLength === 3) {
        if (route === 'dividedInputs_1.inputs.foundation_year') {
          tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
            ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
            errorMessage:
              +tempFields[routeArr[0]][routeArr[1]][routeArr[2]].value < 1980
                ? 'Minimal year is 1980'
                : tempFields[routeArr[0]][routeArr[1]][routeArr[2]].errorMessage,
            error: true,
          };
        } else {
          tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
            ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
            error: true,
          };
        }
      } else if (routeLength === 2) {
        tempFields[routeArr[0]][routeArr[1]] = {
          ...tempFields[routeArr[0]][routeArr[1]],
          error: true,
        };
      } else if (routeLength === 1) {
        tempFields[routeArr[0]] = { ...tempFields[routeArr[0]], error: true };
      }
    });

    setFields(tempFields);
  };

  useEffect(() => {
    let unfilledFieldsCount = 0;
    Object.entries(fields).forEach((i) => {
      const field = i[1];
      if (i[0].includes('dividedInputs')) {
        Object.entries(field.inputs).forEach((item) => {
          const input = item[1];
          if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
            unfilledFieldsCount += 1;
          }
        });
      } else if (i[0].includes('radioButtons')) {
        // eslint-disable-line
      } else if (i[0].includes('switchButtons')) {
        // eslint-disable-line
      } else if (i[0].includes('selectTags')) {
        if (!i[1].optional && !i[1].tags.length) {
          unfilledFieldsCount += 1;
        }
      } else if (i[0].includes('upload')) {
        const upload = i[1];
        if (!upload.optional && !upload.file && !upload.value) {
          unfilledFieldsCount += 1;
        }
      } else if (i[0].includes('checkbox')) {
        // eslint-disable-line
      } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle')) {
        // eslint-disable-line
      } else {
        const input = i[1];
        if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
          unfilledFieldsCount += 1;
        }
      }
    });

    setDisabledAction(!!unfilledFieldsCount);
  }, [fields]);

  function onSave(type, link) {
    const request = {};
    const temp = { ...fields };

    if (disabledAction && type === 'submit') {
      const routes = [];
      Object.entries(temp).forEach((i) => {
        const field = i[1];
        if (i[0].includes('dividedInputs')) {
          Object.entries(field.inputs).forEach((item) => {
            const input = item[1];
            if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
              routes.push(`${i[0]}.inputs.${item[0]}`);
            } else if (
              (item[0] === 'foundation_year' && input.value.length < 4 && type !== 'save') ||
              (type === 'save' && input.value.length > 0 && input.value.length < 4)
            ) {
              routes.push(`${i[0]}.inputs.${item[0]}`);
            } else if (
              (item[0] === 'zip' && input.value.length < 5 && type !== 'save') ||
              (type === 'save' && input.value.length > 0 && input.value.length < 5)
            ) {
              routes.push(`${i[0]}.inputs.${item[0]}`);
            }
          });
        } else if (i[0].includes('radioButtons')) {
          // eslint-disable-line
        } else if (i[0].includes('switchButtons')) {
          // eslint-disable-line
        } else if (i[0].includes('selectTags')) {
          if (!i[1].optional && !i[1].tags.length) {
            routes.push(`${i[0]}`);
          }
        } else if (i[0].includes('upload')) {
          // eslint-disable-line
        } else if (i[0].includes('checkbox')) {
          // eslint-disable-line
        } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle')) {
          // eslint-disable-line
        } else {
          const input = i[1];
          if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
            routes.push(`${i[0]}`);
          } else if (
            (i[0] === 'foundation_year' && input.value.length < 4 && type !== 'save') ||
            (type === 'save' && input.value.length > 0 && input.value.length < 4)
          ) {
            routes.push(`${i[0]}`);
          }
        }
      });

      onError(routes);
      window.scrollTo(0, 0);
      return;
    }

    Object.entries(temp).forEach((i) => {
      const field = i[1];
      if (i[0].includes('dividedInputs')) {
        Object.entries(field.inputs).forEach((item) => {
          if (
            !item[1].skip &&
            ((item[1].type === 'number' && (item[1].value || item[1].value === 0)) || item[1].type !== 'number')
          ) {
            request[item[0]] = item[1].value;
          }
        });
      } else if (i[0].includes('radioButtons')) {
        if (!i[1].skip) {
          const fieldName = i[0].split('_').slice(1).join('_');

          request[fieldName] = field.value;
        }
      } else if (i[0].includes('switchButtons')) {
        Object.entries(field.switches).forEach((item) => {
          if (!item[1].skip) {
            request[item[0]] = item[1].value;
          }
        });
      } else if (i[0].includes('selectTags')) {
        if (!i[1].skip) {
          const fieldName = i[0].split('_').slice(1).join('_');
          request[fieldName] = i[1].tags.join(', ');
        }
      } else if (i[0].includes('upload')) {
        if (!i[1].skip && i[1].file) {
          const fieldName = i[0].split('_').slice(1).join('_');
          request[fieldName] = i[1].file;
        }
      } else if (i[0].includes('checkbox')) {
        Object.entries(field.boxes).forEach((item) => {
          if (!item[1].skip) {
            request[item[0]] = item[1].value;
          }
        });
      } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle')) {
        // eslint-disable-line
      } else if (
        !i[1].skip &&
        ((i[1].type === 'number' && (i[1].value || i[1].value === 0)) || i[1].type !== 'number')
      ) {
        request[i[0]] = field.value;
      }
    });

    const FD = new FormData();

    Object.entries(request).forEach((i) => {
      if (i[0] !== 'foundation_year' || (i[0] === 'foundation_year' && i[1])) {
        // Do not send empty values unless they are bools
        if (typeof i[1] === 'boolean') {
          FD.append(i[0], i[1] ? 1 : 0);
        } else if (i[1]) {
          FD.append(i[0], i[1]);
        }
      }
    });

    prospectId && FD.append('id', prospectId);

    API()
      .post('/prospects/BDR/company', FD)
      .then(({ data }) => {
        if (data.error) {
          return;
        }

        if (type === 'submit') {
          history.push(prospectId ? `/registration/lender/user?id=${prospectId}` : '/registration/lender/user');
        } else if (type === 'link') {
          history.push(link);
        } else {
          message.success('Lender Company Profile data successfully saved!');
        }
      })
      .catch((reason) => {
        message.error(reason.response?.data?.error || 'respond LAR error!');
        const errors = reason.response?.data['wrong columns'] || [];
        const errorObj = {};

        errors.forEach((i) => {
          Object.entries(i).forEach((i) => {
            errorObj[i[0]] = i[1];
          });
        });

        window.scrollTo(0, 0);
        fillError(errorObj, true, fields, setFields);
      });
  }

  const nextStep = () => {
    history.push(prospectId ? `/registration/lender/user?id=${prospectId}` : '/registration/lender/user');
  };

  return (
    <div className="registration lender-register">
      <RegistrationSubHeader
        maxWidth="714px"
        progress={progress}
        actions={
          edit
            ? [
                <Button key={1} onClick={() => onSave('save')} primary text="Save" />,
                <Button key={2} onClick={() => onSave('submit')} primary text="Next" />,
              ]
            : edit === false
            ? [<Button key={1} onClick={nextStep} primary text="Next" />]
            : []
        }
      />
      <div className="registration-content">
        <RenderInputs
          autocompleteAddressFields={autocompleteAddressFields}
          data={fields}
          edit={edit}
          onChange={onChange}
          onUpload={onUpload}
        />
      </div>
      {/^bdr$/i.test(role) && (
        <Modal
          footer={
            <>
              <CustomButton onClick={handleExit} text="Exit" />
              <CustomButton onClick={handleSaveAndExit} primary text="Save progress & exit" />
            </>
          }
          onCancel={onCloseActiveModal}
          visible={activeExitModal === 'exit'}
        >
          Exit without saving progress?
        </Modal>
      )}
    </div>
  );
}
