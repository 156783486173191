import { Dropdown } from 'antd';
import { useCallback, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DashboardAPI, LARAPI } from 'api';
import { setChatState } from 'store/actions/userActions';
import { updateDashboardMetricsTimestamp } from 'store/actions/dashboard';
import { getDaysDiff } from 'utils/date';

import NotesDrawer from '../../../NotesDrawer';
import ReassignButton from '../../../../ReassignButton';
import PassAuctionModal from '../../../../LoanProfile/PassAuctionModal';
import ShareLoanProfileModal from '../../../../LoanProfile/ShareLoanProfileModal';

import { HorizontalDotsIcon, NoteIcon, ShareIcon, MessageIcon } from 'assets/icons';
import { ReactComponent as FavoriteStarIcon } from 'assets/icons/favorite-star.svg';
import { ReactComponent as FileIcon } from 'assets/icons/file.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import styles from './LenderActions.module.css';

export default function LenderActions({ data: { status, id, type, is_favorite_sub } }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    metrics: { workday, timing_json },
  } = useSelector((store) => store.dashboard);
  const [showPassModal, togglePassModal] = useReducer((s) => !s, false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isFavoriteSub, setIsFavoriteSub] = useState(is_favorite_sub);
  const [notes, setNotes] = useState({ visible: false, data: null });
  const [createNoteDrawer, setCreateNoteDrawer] = useState({ visible: false, text: '' });

  const APIDashboard = useMemo(() => new DashboardAPI(id), [id]);
  const larAPI = useMemo(() => new LARAPI(Number(id)), [id]);

  const handleToggleNotes = useCallback(() => {
    const API = new LARAPI(id);

    API.getNotes().then((data) => {
      setNotes((prev) => ({
        ...prev,
        data: {
          ...data,
          notes: Object.entries(data.notes).reverse(),
        },
        visible: true,
      }));
    });
  }, [id]);

  const handleFavorite = () => {
    APIDashboard.postLenderFavoriteLat(id).then(() => {
      setIsFavoriteSub((prev) => !prev);
    });
  };

  const handleMessage = () => {
    dispatch(setChatState({ lar_id: id, recipient_role: 'Broker Loan Manager', type: 'sidebar' }));
  };

  function viewQuote() {
    history.push(`/onboarding${type === 'scenario' ? '/scenarios/' : '/live_auctions/'}${id}`);
  }

  function handleShare({ cc, message, send_copy_to_self, to }) {
    larAPI.share(cc, message, send_copy_to_self, to).finally(() => setIsShareOpen(false));
  }

  const handleQuote = useCallback(() => {
    history.push(`/onboarding${type === 'scenario' ? '/scenarios/' : '/live_auctions/'}${id}?quote=open`);
  }, [type]);

  const handlePass = useCallback(
    ({ additional_info, already_seen, does_not_meet_criteria, more_details, not_competitive, too_busy }) => {
      larAPI
        .pass(null, additional_info, already_seen, does_not_meet_criteria, more_details, not_competitive, too_busy)
        .then(() => {
          togglePassModal();
          dispatch(updateDashboardMetricsTimestamp());
        });
    },
    [larAPI],
  );

  const items = useMemo(
    () => [
      {
        key: 'view-deal',
        label: <div onClick={viewQuote}>View Deal</div>,
        icon: <SearchIcon width={24} height={24} />,
      },
      {
        key: 'mark-as-important',
        label: <div onClick={handleFavorite}>Mark As Important</div>,
        icon: <StarIcon width={24} height={24} />,
      },
      workday && getDaysDiff(timing_json?.next_event_time) === 0
        ? {
            key: 'quote',
            label: <div onClick={handleQuote}>Quote</div>,
            icon: <FileIcon width={24} height={24} />,
          }
        : null,
      {
        key: 'message',
        label: <div onClick={handleMessage}>Message</div>,
        icon: <MessageIcon width={24} height={24} />,
      },
      {
        key: 'note',
        label: <div onClick={handleToggleNotes}>Note</div>,
        icon: <NoteIcon width={24} height={24} />,
      },
      {
        key: 'reassign',
        label: <ReassignButton className={styles.reassign} larId={id} isLender />,
      },
      {
        key: 'share',
        label: <div onClick={() => setIsShareOpen(true)}>Share</div>,
        icon: <ShareIcon width={24} height={24} />,
      },
      {
        key: 'pass',
        label: <div onClick={togglePassModal}>Pass</div>,
        icon: <CloseIcon width={24} height={24} />,
      },
    ],
    [status, history, id, type, dispatch],
  );

  return (
    <div className={styles.actions} onClick={(e) => e.stopPropagation()}>
      {isFavoriteSub && <FavoriteStarIcon />}
      <Dropdown overlayClassName={styles.menu} trigger="click" menu={{ items }}>
        <HorizontalDotsIcon />
      </Dropdown>
      <NotesDrawer
        notes={notes}
        createNoteDrawer={createNoteDrawer}
        setCreateNoteDrawer={setCreateNoteDrawer}
        setNotes={setNotes}
      />
      <ShareLoanProfileModal
        larId={id}
        visible={isShareOpen}
        onCloseActiveModal={() => setIsShareOpen(false)}
        submitShare={handleShare}
      />
      <PassAuctionModal handlePassAuction={handlePass} onCloseActiveModal={togglePassModal} visible={showPassModal} />
    </div>
  );
}
