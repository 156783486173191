import classNames from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { setChatState } from 'store/actions/userActions';
import IconButton from 'components/UI/IconButton';
import ChatUserTab from './ChatUserTab';
import backIcon from 'assets/icons/arrow-bar-left.svg';
import moreIcon from 'assets/icons/dots.svg';

import styles from './ChatMessagingHeader.module.css';

function ChatMessagingHeader({ activeChat, type, isOpenMessagesList, onSetActiveChat, onArchive }) {
  const dispatch = useDispatch();
  const { chatState } = useSelector((s) => s.user);
  const larData = activeChat.chat?.lar_data;
  const isViewOnly = chatState?.mode === 'viewOnly';
  const chat_with = activeChat.chat?.users.length <= 2 && activeChat.chat.users.find((i) => !i.is_current);

  const handleCloseChat = () => {
    onSetActiveChat(null);
    dispatch(setChatState({}));
  };

  if (!larData) {
    return null;
  }

  return (
    <div className={styles.header}>
      <div className={styles.headerTop}>
        {type === 'sidebar' && (
          <div className={styles.closeBtn}>
            <IconButton icon={backIcon} handleClick={handleCloseChat} alt="close" />
          </div>
        )}
        <h3 className={classNames([styles.headerTopTitle, !isOpenMessagesList && styles.headerTopTitleWithBtn])}>
          {larData.type}
          <span className={styles.headerTopId}>#{larData.id}</span>
        </h3>
        <p className={styles.headerAddress}>
          {larData.address},{larData.city},{larData.state}
        </p>
      </div>
      <div className={styles.headerMid}>
        {activeChat.chat.lar_users.map(
          (user) =>
            user && (
              <ChatUserTab
                key={`${user.user_id}-${user.user_full_name}`}
                type={type}
                user={user}
                activeChat={activeChat}
              />
            ),
        )}
      </div>
      <div className={styles.headerBottom}>
        {!isViewOnly && (
          <>
            {chat_with?.last_online && (
              <div className={styles.lastVisit}>
                Online
                {moment(chat_with?.last_online).fromNow()}
              </div>
            )}
            <div className={styles.addInfo}>
              {activeChat.chat.in_archive && <div className={styles.archived}>Archived</div>}
              <div className={styles.more}>
                <img src={moreIcon} alt="more" />
                <div className={styles.moreList}>
                  <div className={styles.moreListItem} onClick={onArchive}>
                    {activeChat.chat.in_archive ? 'Unarchive' : 'Archive'}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ChatMessagingHeader;
