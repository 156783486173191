export const roleOptions = [
  'PLA',
  'Broker Admin',
  'Broker Transaction Manager',
  'Broker Loan Manager',
  'Borrower',
  'Lender Admin',
  'Lender Transaction Manager',
  'Lender Account Executive',
  'Prospect lender',
  'Prospect broker',
  'Prospect borrower',
];

export const userTypes = ['Starport', 'Lender', 'Originator', 'Borrower'];

export const companyType = [
  'NonQM Lender',
  'Commercial Lender',
  'Alternative Lender',
  'Insurance Company',
  'Hard Money/Private Lender',
  'Residential Mortgage Broker',
  'Commercial Mortgage Broker',
  'Agency Lender',
  'Correspondent Lender',
  'Credit Union',
  'Regional Bank',
  'Commercial Bank',
];
