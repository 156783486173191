import { useEffect, useState } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Keys from 'constants/helper';
import parseFromET from 'constants/parseFromET';
import { ClockIcon, InfoCircleIcon, PadlockIcon } from 'assets/icons';
import Modal from 'components/Modals/Modal';

import styles from './AuctionEndPopup.module.css';

function AuctionEndPopup() {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { auctions, timing_json } = useSelector((store) => store.dashboard.metrics);
  const auctionEndTime = localStorage.getItem(Keys.AUCTION_END_TIME);
  const timeSinceAuction = auctionEndTime ? moment().diff(moment(parseFromET(auctionEndTime)), 'minute') : 31;
  const todayAuctions = auctions?.lars.length;
  const nextEventDay = moment(parseFromET(timing_json?.next_event_time)).diff(moment().startOf('day'), 'days');

  const handleOk = () => {
    if (timeSinceAuction < 30) {
      history.push('/onboarding/finished-auctions');
    } else {
      setIsOpen(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (timing_json && !['Auction Live', 'Preview open'].includes(timing_json?.event)) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [timing_json, auctionEndTime]);

  return (
    <Modal
      destroyOnClose
      open={isOpen}
      onCancel={handleClose}
      className={styles.root}
      title={timeSinceAuction < 30 ? 'Live auction completed' : 'Upcoming auction'}
      footer={[
        <Button key={1} type="dark" size="large" onClick={handleOk}>
          {timeSinceAuction < 30 ? 'View Quote results' : 'Close'}
        </Button>,
      ]}
    >
      {timeSinceAuction < 30 ? (
        <>
          <div className={classNames(styles.block, styles.greenBlock)}>
            <ClockIcon colour="green-5" />
            <span>
              <b>Thank you for participation in this auction.</b>
              <br />
              The MLO is reviewing the quotes with their client.
              <br />
              We’ll notify you if your quote is selected as the winner.
            </span>
          </div>
          <div className={styles.block}>
            <PadlockIcon />
            The deals have been locked and moved to the Quote Results page.
          </div>
        </>
      ) : (
        <>
          <div className={classNames(styles.block, styles.blueBlock)}>
            <ClockIcon colour="brand-5" />
            {todayAuctions > 0 && nextEventDay === 0 ? (
              <span>
                There are <b>{todayAuctions} auctions</b> scheduled for {moment().format('MMM DD')}.
              </span>
            ) : (
              <span>
                There are <b>{auctions?.next_auctions[0]?.count || auctions?.next_auctions[1]?.count} auctions</b>{' '}
                scheduled for{' '}
                {moment(auctions?.next_auctions[0]?.date || auctions?.next_auctions[1]?.date).format('MMM DD')}.
              </span>
            )}
          </div>
          <div className={styles.block}>
            <InfoCircleIcon />
            You will be able to preview the deal profile at {moment(timing_json?.next_event_time).format('hh:mm A')} ET
          </div>
        </>
      )}
    </Modal>
  );
}

export default AuctionEndPopup;
