import { acceptedMimes } from 'constants/mimes';
import { FileUploadIcon, PlusIcon } from 'assets/icons';
import { message } from 'antd';
import { useRef, useEffect } from 'react';
import API from 'api';
import CustomButton from 'components/UI/Button';
import ActionsFooter from '../ActionsFooter';
import RegularDocumentEntry from './RegularDocumentEntry';
import UnderwritingFooter from '../UnderwritingFooter';

export default function DocumentView({
  closeDiligenceItem,
  isUnderwriting = false,
  openManualEmsModal,
  role,
  setViewDiligence,
  viewDiligence,
  viewFullscreen,
}) {
  const uploadInput = useRef();

  function uploadFile(file) {
    if (!acceptedMimes.diligenceDocs.includes(file.type)) {
      message.error(`Selected file format "${file.type}" is unsupported`);
      return;
    }

    if (file.size > 10485760) {
      message.error('Selected file size is over 10MiB');
      return;
    }

    const formData = new FormData();
    formData.append('document_name', file.name.split('.')[0]);
    formData.append('document', file);
    formData.append('item_id', viewDiligence.id);

    API()
      .post('/LAR/dd_center/item/document', formData)
      .then(({ data }) => setViewDiligence(data));
  }

  function deleteFile(id) {
    API()
      .delete('/LAR/dd_center/item/document', {
        params: {
          document_id: id,
          item_id: viewDiligence.id,
        },
      })
      .then(() => {
        setViewDiligence((prev) => ({
          ...prev,
          documents: prev.documents.filter((doc) => id !== doc.id),
        }));
      });
  }

  useEffect(() => {
    viewDiligence.uploadAction && uploadInput.current?.click();
  }, [viewDiligence.uploadAction]);

  return (
    <div className={['document-view', !viewDiligence.documents?.length ? 'empty' : ''].join(' ')}>
      <input
        accept={acceptedMimes.diligenceDocs.join()}
        hidden
        onChange={(e) => uploadFile(e.target.files[0])}
        ref={uploadInput}
        type="file"
      />
      <div className="document-view-content">
        {viewDiligence.documents?.length ? (
          <>
            {viewDiligence.documents
              .sort((a, b) => b.is_sample - a.is_sample)
              .map((doc) => (
                <RegularDocumentEntry
                  actions={viewDiligence.available_actions}
                  deleteFile={deleteFile}
                  document={doc}
                  itemId={viewDiligence.id}
                  key={doc.id}
                  setViewDiligence={setViewDiligence}
                  viewFullscreen={viewFullscreen}
                  withDivider={doc.is_sample && viewDiligence.documents.filter((d) => !d.is_sample).length > 0}
                />
              ))}
            {viewDiligence.available_actions.includes('upload') && (
              <div className="add-document-entry-block">
                Add new file
                <CustomButton
                  onClick={() => uploadInput.current.click()}
                  primary
                  svg={<PlusIcon colour="white" />}
                  text="Browse"
                />
              </div>
            )}
          </>
        ) : (
          <>
            <FileUploadIcon colour="grey-6" size="15em" strokeWidth="0.2" />
            <div>Please upload first item.</div>
            <CustomButton onClick={() => uploadInput.current.click()} primary text="Upload document" />
          </>
        )}
      </div>
      {isUnderwriting ? (
        <UnderwritingFooter
          closeDiligenceItem={closeDiligenceItem}
          itemId={viewDiligence.id}
          onSubmitApprove={() => {
            openManualEmsModal({ item_id: viewDiligence.id });
          }}
          documentType={viewDiligence.item_type}
        />
      ) : (
        <ActionsFooter
          closeDiligenceItem={closeDiligenceItem}
          documentsN={viewDiligence.documents.filter((d) => !d.is_sample).length}
          documentType="regular"
          instantAccept={viewDiligence.acceptAction}
          instantReject={viewDiligence.rejectAction}
          itemId={viewDiligence.id}
          role={role}
          stage={viewDiligence.item_stage}
          updated_at={viewDiligence.updated_at}
          updated_by={viewDiligence.updated_by}
        />
      )}
    </div>
  );
}
