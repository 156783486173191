import './custom-checkbox-block.css';
import { Form } from 'antd';
import { includes, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { onChangeValidator, useFieldsChangeNew } from '../../helpers/larHelpers';
import formSettings from '../../helpers/formSettings';
import ProductFormMain from './ProductFormMain/ProductFormMain';
import ProductFormPurchaseShort from './ProductFormPurchaseShort/ProductFormPurchaseShort';
import LoanFormWrapper from 'components/LarAppNew/FormWrappers/LoanFormWrapper/LoanFormWrapper';

function ProductForm({
  form,
  isPurchaseLong,
  isPurchaseShort,
  isRefinance,
  loan_opportunity,
  loan_purpose,
  setLAProgress,
  setProgress,
  setSuccess,
  setSuccessLA,
}) {
  const formName = 'product';
  const role = useSelector((store) => store.user.userData?.role);

  function isSomeCheckboxChecked() {
    const {
      income_doc_type_DSCR,
      income_doc_type_stated,
      income_doc_type_full_doc,
      income_doc_type_1_3_bank_statements,
      income_doc_type_6_12_bank_statements,
      income_doc_type_24_bank_statements,
      income_doc_type_k_1_only,
      income_doc_type_VOE_only,
    } = form.getFieldsValue();

    return (
      /borrower/i.test(role) ||
      includes(
        [
          income_doc_type_DSCR,
          income_doc_type_stated,
          income_doc_type_full_doc,
          income_doc_type_1_3_bank_statements,
          income_doc_type_6_12_bank_statements,
          income_doc_type_24_bank_statements,
          income_doc_type_k_1_only,
          income_doc_type_VOE_only,
        ],
        true,
      )
    );
  }

  const getSuccessStatus = (fieldsArr) => {
    onChangeValidator(form, fieldsArr, formName, {
      isRefinance,
      isPurchaseLong,
      isPurchaseShort,
    });

    if (isPurchaseLong) {
      return isSomeCheckboxChecked();
    }

    return true;
  };

  const getSuccessStatusLA = (fieldsArr) => {
    onChangeValidator(form, fieldsArr, formName, {
      isRefinance,
      isPurchaseLong,
      isPurchaseShort,
    });

    const {
      bank_account_type: bankAccountType,
      income_doc_type_1_3_bank_statements: incomeDocType3BankStatements,
      income_doc_type_6_12_bank_statements: incomeDocType12BankStatements,
      income_doc_type_24_bank_statements: incomeDocType24BankStatements,
      interest_rate_type: rateType,
      loan_term: loanTerm,
    } = form.getFieldsValue();

    const isBadRateType = isEmpty(rateType) || !rateType;
    const isBadLoanTerm = isEmpty(loanTerm) || !loanTerm;
    const bankStatementsSelected =
      incomeDocType3BankStatements || incomeDocType12BankStatements || incomeDocType24BankStatements;

    if (isPurchaseShort) {
      return isPurchaseShort;
    }

    if (isPurchaseLong || isRefinance) {
      return (
        isSomeCheckboxChecked() && !isBadRateType && !isBadLoanTerm && (!!bankAccountType || !bankStatementsSelected)
      );
    }

    return isSomeCheckboxChecked() && !isBadRateType && !isBadLoanTerm;
  };

  const setSuccessStatus = (status = false) => {
    setSuccess(status);
    setProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const setSuccessStatusLA = (status = false) => {
    setSuccessLA(status);
    setLAProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const onSuccess = () => {
    form.submit();
  };

  const onReject = () => {};

  const onFieldsChange = useFieldsChangeNew(
    getSuccessStatus,
    setSuccessStatus,
    onSuccess,
    onReject,
    getSuccessStatusLA,
    setSuccessStatusLA,
  );

  // TODO: TEST SAVE DATA
  useEffect(() => {
    const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
      name: el[0],
      value: el[1],
    }));
    onFieldsChange([1], valuesArr);
    // eslint-disable-next-line
  }, []);

  return (
    <Form {...formSettings} form={form} name={formName} onFieldsChange={onFieldsChange}>
      <LoanFormWrapper
        loan_purpose={loan_purpose}
        loan_opportunity={loan_opportunity}
        PurchaseLongForm={<ProductFormMain form={form} isPurchaseLong={isPurchaseLong} />}
        PurchaseShortForm={<ProductFormPurchaseShort form={form} />}
        RefinanceForm={<ProductFormMain form={form} isPurchaseLong={isPurchaseLong} />}
      />
    </Form>
  );
}

export default ProductForm;
