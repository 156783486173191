import API from '../API';

export default class ChatAPI {
  async getList(showArchive, query) {
    return API()
      .get('/chats', {
        params: {
          in_archive: +showArchive,
          search: query || undefined,
        },
      })
      .then((res) => res.data?.chats || {});
  }

  async getMessages({ lar_id, recipient_role, chat_id, user_id, bid_id }) {
    return API()
      .get('/chat/message', {
        params: {
          lar_id,
          recipient_role,
          chat_id,
          user_id,
          bid_id,
        },
      })
      .then((res) => res.data);
  }

  async postMessage(message, chatId, onMessageId, audio, documents) {
    const formData = new FormData();

    formData.set('message', message);
    formData.set('chat_id', chatId);
    onMessageId && formData.set('on_message_id', onMessageId);
    audio &&
      formData.set(
        'audio_file',
        await fetch(audio)
          .then((res) => res.blob())
          .then((blob) => new File([blob], 'audio.ogg', { type: 'audio/ogg codecs=opus' })),
      );
    if (documents?.length > 0) {
      documents.forEach((document, i) => {
        formData.set(`document_${i + 1}`, document);
      });
    }

    return API()
      .post('/chat/message', formData)
      .then((res) => res.data);
  }

  async deleteMessage(id) {
    return API()
      .delete('/chat/message', { params: { message_id: id } })
      .then((res) => res.data);
  }

  async archive(id) {
    return API()
      .post('/chat/archivate', { chat_id: id })
      .then((res) => res.data);
  }
}
