import 'components/TemplateEditor/template_editor.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import styles from './styles/TemplateEditor.module.css';

function TemplateEditor({
  title,
  text,
  setText,
  editorWrapClassName,
  disableRerender,
  setDisableRerender,
  mention,
  toolbar,
  wrapperClassName,
}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (!disableRerender && text) {
      const contentBlock = htmlToDraft(text);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorStateTemp = EditorState.createWithContent(contentState);
        setEditorState(editorStateTemp);
      }
    }

    // eslint-disable-next-line
  }, [text]);

  return (
    <div className={classNames(wrapperClassName || 'wrapper_template', styles.root)}>
      {title && <label className="input_title">{title}</label>}
      <Editor
        style={{ height: 50 }}
        wrapperClassName={editorWrapClassName || 'text_area'}
        toolbar={
          toolbar || {
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'emoji',
              'remove',
              'history',
            ],
          }
        }
        editorState={editorState}
        mention={mention}
        onEditorStateChange={(state) => {
          setEditorState(state);
          const rawContentState = convertToRaw(state.getCurrentContent());

          // This thing removes links
          // I dunno why someone made it to, so I won't touch it
          // But if you need to allow links, remove the last parameter from this function
          const markup = draftToHtml(rawContentState, undefined, true, (entity, text) => text);

          setText(markup);

          setDisableRerender(true);
          setTimeout(() => {
            setDisableRerender(false);
          }, 100);
        }}
      />
    </div>
  );
}

export default TemplateEditor;
