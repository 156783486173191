import './styles/bid_app.css';
import { Route } from 'react-router-dom';
import Navigations from 'routers/Navigations/index';
import PageHeader from 'components/OnBoarding/PageHeader/PageHeader';

export default function MainRouter() {
  return (
    <div className="loan-bid-app">
      <div />
      <PageHeader registration />
      <div className="loan-bid-app__inner">
        {Navigations.mainNavigationBidApplication.map((route) => (
          <Route path={route.path} exact={route.exact} component={route.main} key={route.path} />
        ))}
      </div>
    </div>
  );
}
