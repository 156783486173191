import moment from 'moment';

/**
 * Takes in a Date object and returns a formatted time string in 12-hour clock format with AM/PM indicator
 * If no argument is provided, the current date and time is used
 * @param {Date} date - Optional parameter for a Date object to format
 * @returns {string} - Formatted time string in the format "HH:MM AM/PM"
 */
export function formatAMPM(date = new Date()) {
  const formattedDate = new Date(date);
  const hours = formattedDate.getHours() % 12 || 12;
  const minutes = formattedDate.getMinutes().toString().padStart(2, '0');
  const ampm = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

  return `${hours}:${minutes}${ampm}`;
}

/**
 * Returns the time difference between the given date and the current time in the format "HH:MM:SS".
 * If no date is provided, returns "00:00:00".
 *
 * @param {string} date - A string representing the date to calculate the time difference from.
 * @returns {string} A string representing the time difference between the given date and the current time.
 */
export function timeLeft(date) {
  if (!date) {
    return '00:00:00';
  }

  const now = Date.parse(new Date(Date.now()).toLocaleString('en-US', { timeZone: 'America/New_York' }));
  const parseDate = new Date(date).getTime();

  const diff = new Date(parseDate - now);
  const hours = diff.getUTCHours().toString().padStart(2, '0');
  const minutes = diff.getUTCMinutes().toString().padStart(2, '0');
  const seconds = diff.getUTCSeconds().toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}

/**
 * Returns a new Date object with the specified number of minutes added to the input date.
 * @param {Date|string} date - The input date. Defaults to the current date and time if not provided.
 * @param {number} minutes - The number of minutes to add to the input date. Defaults to 30 if not provided.
 * @returns {number} - The timestamp of the new Date object.
 */
export function addMinutes(date = new Date(), minutes = 30) {
  return new Date(date).getTime() + minutes * 60000;
}

export function getDaysDiff(date) {
  return date ? moment(date).startOf('day').diff(moment().startOf('day')) : null;
}
