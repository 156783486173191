import { ArrowIcon } from 'assets/icons';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classes from './styles/Dashboard.module.css';

export default function Dashboard({ categories, company = false }) {
  const { name, surname, email } = useSelector((store) => store.user.userData);
  const history = useHistory();

  return (
    <div className={classes.root}>
      <h1>{company ? 'Company information' : 'Account'}</h1>
      {company || (
        <>
          <div>
            {name} {surname} — {email}
          </div>
          <Link to="/onboarding/settings/profile">
            Open profile
            <ArrowIcon colour="brand-5" direction="right" style={{ verticalAlign: 'middle' }} />
          </Link>
        </>
      )}
      <div className={classes.cardsWrapper}>
        {categories
          .filter((c) => !c.hidden)
          .map(({ description, Icon, title, id, redirect }) => (
            <div
              key={id}
              className={classes.card}
              onClick={() => {
                history.push(redirect || `/onboarding/${company ? 'company-' : ''}settings/${id}`);
              }}
            >
              <Icon colour="black" />
              <h2>{title}</h2>
              <div>{description}</div>
            </div>
          ))}
      </div>
    </div>
  );
}
