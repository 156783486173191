import { Checkbox, Input, Radio, Select, Space, Typography } from 'antd';

import otherConst from '../../../../../../constants/otherConst';

import styles from './ScenarioTableFilters.module.css';

export default function ScenarioTableFilters({ data, onChange }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <Typography.Title level={5} className={styles.title}>
          Loan Purpose
        </Typography.Title>
        <Radio.Group name="loan_purpose" onChange={onChange} value={data.loan_purpose}>
          <Space direction="vertical">
            {[
              'Purchase',
              'Refinance',
              'Bridge without Renovation',
              'Bridge with Renovation/Fix to Flip',
              'Construction',
            ].map((i) => (
              <Radio key={i} value={i}>
                {i}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
      <div className={styles.inner}>
        <Typography.Title level={5} className={styles.title}>
          Property Type
        </Typography.Title>
        <Radio.Group name="property_type" onChange={onChange} value={data.property_type}>
          <Space direction="vertical">
            {[
              'Long Term Rental 1-4 units',
              'Short Term Rental 1-4 units (AirBnB)',
              'Condominium (Warrantable)',
              'Condo (Non-Warrantable)',
              'Multifamily 5+ units',
              'Office',
              'Retail',
              'Industrial/NNN',
              'Hospitality',
              'Student',
              'Storage',
              'Senior/Assisted Living',
              'Mixed-use',
              'Mobile Home Park',
              'Subdivision/Spec Home',
              'Portfolio of Rental Homes',
              'Land',
              'Other',
            ].map((i) => (
              <Radio key={i} value={i}>
                {i}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
      <div className={styles.inner}>
        <Typography.Title level={5} className={styles.title}>
          Recourse
        </Typography.Title>
        <Checkbox.Group name="recourse" onChange={(v) => onChange(v, 'recourse')}>
          <Checkbox value="Partial-Recourse">Partial Recourse</Checkbox>
          <Checkbox value="Recourse">Recourse</Checkbox>
          <Checkbox value="Non-Recourse">Non-Recourse</Checkbox>
        </Checkbox.Group>
      </div>
      <div className={styles.inner}>
        <Typography.Title level={5} className={styles.title}>
          Payment type
        </Typography.Title>
        <Checkbox.Group name="payment_type" onChange={(v) => onChange(v, 'payment_type')}>
          <Checkbox value="(P&I)">Principal & Interest</Checkbox>
          <Checkbox value="I/O">Interest Only</Checkbox>
        </Checkbox.Group>
      </div>
      <div className={styles.inner}>
        <Space direction="vertical" size={8}>
          <Typography.Text>Loan Amount</Typography.Text>
          <Input name="loan_amount" onChange={onChange} value={data.loan_amount} />
        </Space>
      </div>
      <div className={styles.inner}>
        <Space direction="vertical" size={8}>
          <Typography.Text>LTV</Typography.Text>
          <Input name="ltv" onChange={onChange} value={data.ltv} />
        </Space>
      </div>
      <div className={styles.inner}>
        <Space direction="vertical" size={8}>
          <Typography.Text>LTC</Typography.Text>
          <Input name="ltc" onChange={onChange} value={data.ltc} />
        </Space>
      </div>
      <div className={styles.inner}>
        <Checkbox name="owners_occupied" onChange={onChange}>
          Owner Occupied
        </Checkbox>
      </div>
      <div className={styles.inner}>
        <Checkbox name="is_deal_distressed" onChange={onChange}>
          Distressed Situation
        </Checkbox>
      </div>
      <div className={styles.inner}>
        <Typography.Title level={5} className={styles.title}>
          Borrower Citizenship
        </Typography.Title>
        <Radio.Group name="borrower_citizenship" onChange={onChange} value={data.borrower_citizenship}>
          <Space direction="vertical">
            {[
              'US Citizen',
              'Foreign National (ITIN)',
              'Foreign National (No ITIN)',
              'Perm. Resident Alien',
              'Non-Permanent Resident',
              'Foreign Investor',
              'Other',
            ].map((i) => (
              <Radio key={i} value={i}>
                {i}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
      <div className={styles.inner}>
        <Typography.Title level={5} className={styles.title}>
          FICO
        </Typography.Title>
        <Input name="fico" onChange={onChange} value={data.fico} />
      </div>
      <div className={styles.inner}>
        <Typography.Title level={5} className={styles.title}>
          Deal Source
        </Typography.Title>
        <Radio.Group name="dial_source" onChange={onChange} value={data.dial_source}>
          <Space direction="vertical">
            {['Broker', 'Borrower'].map((i) => (
              <Radio key={i} value={i}>
                {i}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
      <div className={styles.inner}>
        <Checkbox name="is_rural" onChange={onChange}>
          Is rural
        </Checkbox>
      </div>
      <div className={styles.inner}>
        <Typography.Title level={5} className={styles.title}>
          Location
        </Typography.Title>
        <Select
          options={otherConst.states.map((i) => ({
            value: i.preview,
            label: i.preview,
          }))}
          onChange={(v) => onChange(v, 'location')}
          value={data.locations}
        />
      </div>
    </div>
  );
}
