const entityOwnersHelper = {
  getInitState: (entity_owners) =>
    entity_owners?.map((el) => ({
      key: el.id,
      first_name: el.first_name,
      fico: el.credit_rating,
      last_name: el.last_name,
      signer: !!el.signer,
      percent_ownership: el.percent_ownership,
      relationships: el.relationship,
    })),
};
export default entityOwnersHelper;
