import './styles/mandates.css';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import Button from 'components/UI/Button';
import ReminderForm from 'components/OnBoarding/SuperAdmin/ReminderForm';

const initState = {
  date: {
    value: '',
    error: false,
    errorMessage: 'Select date',
  },
  time: {
    value: '',
    error: false,
    errorMessage: 'Select time',
  },
};

export default function Mandates() {
  const [addReminder, setAddReminder] = useState(initState);
  const [reminderList, setReminderList] = useState([]);

  const onChangeDate = (d, name) => {
    setAddReminder((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: d,
        error: false,
      },
    }));
  };

  useEffect(() => {
    API()
      .get('/superadmin/settings/mandate_reminders')
      .then((res) => {
        const protoData = res.data.data.map((i) => ({
          date: {
            value: i.date,
          },
          time: {
            value: i.time,
          },
          id: i.id,
        }));
        setReminderList(protoData);
      });
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    let error = false;

    if (!addReminder.date.value) {
      setAddReminder((prevState) => ({
        ...prevState,
        date: {
          ...prevState.date,
          error: true,
        },
      }));
      error = true;
    }
    if (!addReminder.time.value) {
      setAddReminder((prevState) => ({
        ...prevState,
        time: {
          ...prevState.time,
          error: true,
        },
      }));
      error = true;
    }

    if (error) {
      return;
    }

    const reqObj = {
      datetime: `${addReminder.date.value} ${addReminder.time.value}`,
    };

    API()
      .post('/superadmin/settings/mandate_reminders', reqObj)
      .then((res) => {
        setReminderList((prevState) => [
          ...prevState,
          {
            date: {
              value: addReminder.date.value,
            },
            time: {
              value: addReminder.time.value,
            },
            id: res.data.id,
          },
        ]);
        setAddReminder(initState);

        message.success('You successfully added reminder');
      });
  };

  const onDeleteReminder = (item) => {
    API()
      .delete(`/superadmin/settings/mandate_reminders?id=${item.id}`)
      .then(() => {
        const filterData = reminderList.filter((i) => i.id !== item.id);
        setReminderList(filterData);

        message.success('You successfully deleted reminder');
      });
  };

  return (
    <div className="wrapper_mandates">
      <div className="active_list">
        <p className="section_title">Lender Mandate and Loan Document Update Reminders</p>

        <div className="reminders_list">
          <div className="every">
            {reminderList?.map((item, index) => (
              <ReminderForm item={item} key={index} disabled onDelete={onDeleteReminder} onChangeDate={onChangeDate} />
            ))}
          </div>
        </div>
      </div>

      <form className="add_new_reminder" onSubmit={onSubmit}>
        <p className="section_title">Add a new reminder</p>
        <ReminderForm item={addReminder} onChangeDate={onChangeDate} />
        <Button text="Add mandate reminder" className="btn_darkblue submit" type="submit" />
      </form>
    </div>
  );
}
