export const bidDeadlineHurdleOptions = Array.from({ length: 6 }, (_, i) => ({ value: i, preview: i }));
export const maxNumInvitesOptions = Array.from({ length: 91 }, (_, i) => ({ value: i + 10, preview: i + 10 }));
export const maxNumOfLendersOptions = Array.from({ length: 46 }, (_, i) => ({ value: i + 5, preview: i + 5 }));
export const minNumInvitesOptions = Array.from({ length: 10 }, (_, i) => ({ value: i + 1, preview: i + 1 }));
export const minNumOfLendersOptions = Array.from({ length: 31 }, (_, i) => ({ value: i, preview: i }));
export const minSmartMatchScoreOptions = Array.from({ length: 100 }, (_, i) => ({ value: i + 1, preview: i + 1 }));
// const quoteDeadlineOptions = Array.from({length: 10}, (_, i) => ({value: i + 1, preview: `${i + 1} hours`}))
// const deadLineOptions = [
//     {preview: 'End of 1st week', value: 'End of 1st week'},
//     {preview: 'End of 2nd week', value: 'End of 2nd week'},
//     {preview: 'End of 4th', value: 'End of 4th'},
//     {preview: 'End of 5th', value: 'End of 5th'},
//     {preview: 'End of 6th', value: 'End of 6th'},
// ]
