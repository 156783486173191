import { Breadcrumb, Divider, Form, Input, Space, Row, Col, Select, message, Spin, Radio } from 'antd';
import { CloseIcon, FileUploadIcon } from 'assets/icons';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import API from 'api';
import CustomButton from 'components/UI/Button';
import FieldModal from 'components/OnBoarding/Masterlist/FieldModal';
import SectionTitle from 'components/OnBoarding/SectionTitle';

const initialModalState = { show: '', data: null };
const questionTypes = [
  {
    name: 'Currency',
    value: 'currency',
  },
  {
    name: 'Number',
    value: 'number',
  },
  {
    name: 'Short Text',
    value: 'short_text',
  },
  {
    name: 'Long Text',
    value: 'long_text',
  },
  {
    name: 'Email',
    value: 'email',
  },
  {
    name: 'Address',
    value: 'address',
  },
  {
    name: 'State',
    value: 'state',
  },
  {
    name: 'Zip',
    value: 'zip',
  },
  {
    name: 'Phone',
    value: 'phone',
  },
];

const docTypes = [
  {
    name: 'Regular',
    value: 'regular',
  },
  {
    name: 'Question/Form',
    value: 'question_or_form',
  },
  {
    name: 'Completed',
    value: 'completed',
  },
];
const itemActions = [
  {
    text: 'Acknowledge/Approve',
    value: 'acknowledge',
  },
  {
    text: 'No response needed',
    value: 'nothing',
  },
];

function MasterlistDocument({ edit = false }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const uploadRef = useRef();
  const [activeModal, setActiveModal] = useState(initialModalState);
  const [disableSubmitBtns, setDisableSubmitBtns] = useState(true);
  const [masterlistDocData, setMasterlistDocData] = useState({
    loading: false,
  });
  const [fiedlsData, setFieldsData] = useState([]);
  const [documentsData, setDocumentsData] = useState({
    document: {
      value: [],
      error: false,
      errorMessage: 'Upload a document',
    },
  });
  const { masterlistDocId, masterlistId } = useParams();
  const [goBack, setGoBack] = useState(false);
  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);
  const [hiddenQuestion, setHiddenQuestion] = useState(form.getFieldValue('type') !== 'question_or_form');
  const [hiddenAction, setHiddenAction] = useState(form.getFieldValue('type') !== 'completed');
  const [typeCompletedAction, setTypeCompletedAction] = useState(itemActions[0].value);

  const formData = [
    {
      name: 'name',
      label: 'Item name',
      rules: [
        {
          required: true,
          whitespace: true,
          message: 'Please input Item name!',
        },
      ],
      children: <Input size="large" placeholder="" />,
    },
    {
      name: 'description',
      label: 'Item description',
      rules: [
        {
          required: true,
          whitespace: true,
          message: 'Please Item description!',
        },
      ],
      children: <Input.TextArea autoSize style={{ minHeight: 70 }} size="large" placeholder="" />,
    },
    {
      name: 'type',
      label: 'Item type',
      rules: [
        {
          required: true,
          message: 'Please select Item type!',
        },
      ],
      children: (
        <Select>
          {docTypes.map((el) => (
            <Select.Option key={`doctype_${el.value}`} value={el.value}>
              {el.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: 'category_id',
      label: 'Item category',
      rules: [{ required: true, message: 'Please select item category!' }],
      children: (
        <Select>
          {categories.map((el) => (
            <Select.Option key={`category${el.id}`} value={el.id}>
              {el.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: 'type_completed_action',
      label: 'Item action',
      hidden: hiddenAction,
      children: (
        <>
          <Radio.Group value={typeCompletedAction} onChange={handleChangeActionItem}>
            <Space size={18} direction="vertical">
              {itemActions.map((i, index) => (
                <Radio key={index} value={i.value}>
                  {i.text}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          {errors.type_completed_action && (
            <div className="ant-form-item-explain-error">{errors.type_completed_action}</div>
          )}
        </>
      ),
    },
    {
      hidden: hiddenQuestion,
      name: 'question_1',
      label: 'Question',
      rules: [{ required: true, message: 'Please input question text!' }],
      children: <Input.TextArea style={{ minHeight: 80 }} rows={4} autoSize />,
    },
    {
      hidden: hiddenQuestion,
      name: 'question_type_1',
      label: 'Question type',
      rules: [{ required: true, message: 'Please select question type!' }],
      children: (
        <Select>
          {questionTypes.map((el) => (
            <Select.Option key={el.value} value={el.value}>
              {el.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
  ];

  const onFormChange = () => {
    setDisableSubmitBtns(false);
  };

  function handleChangeActionItem(e) {
    setTypeCompletedAction(e.target.value);
  }

  const generateForm = (arr) =>
    arr.map((el, id) =>
      !el.hidden ? (
        <Col span={10} key={el.name + id}>
          <Form.Item {...el} />
        </Col>
      ) : null,
    );

  const onCloseActiveModal = () => setActiveModal((state) => ({ ...state, show: false }));

  const goToAddMasterlist = () => {
    history.push('/onboarding/masterlists');
  };

  const onGoBack = (e) => {
    e.preventDefault();
    goToAddMasterlist();
  };

  const handleAddField = (values) => {
    setFieldsData((prevState) => [...prevState, { ...values, key: prevState.length + 1 }]);
    setDisableSubmitBtns(false);
    onCloseActiveModal();
  };

  const handleEditField = (editedItem) => {
    setFieldsData((prevState) => {
      const newState = [...prevState];
      const itemIdx = newState.findIndex((el) => el.key === editedItem.key);
      newState[itemIdx] = editedItem;
      return [...newState];
    });
    setDisableSubmitBtns(false);
    onCloseActiveModal();
  };

  const handleAddEditMasterlistDoc = (values) => {
    const formData = new FormData();
    const { questions, ...restValues } = values;
    let data = {};

    if (values.type === 'question_or_form') {
      if (questions) {
        const questionsData =
          questions.length === 1
            ? { ...questions[0], question_type_3: '', question_3: '' }
            : questions.length === 2
            ? { ...questions[0], ...questions[1] }
            : {
                question_type_2: '',
                question_2: '',
                question_type_3: '',
                question_3: '',
              };

        data = {
          masterlist_id: +masterlistId,
          ...restValues,
          ...questionsData,
          fields: [...fiedlsData.map(({ key, id, ...keepAttrs }) => keepAttrs)],
        };
      } else {
        data = {
          masterlist_id: +masterlistId,
          ...restValues,
          question_type_1: values.question_type_1 || '',
          question_1: values.question_1 || '',
          fields: [...fiedlsData.map(({ key, id, ...keepAttrs }) => keepAttrs)],
        };
      }
    } else {
      data = {
        masterlist_id: +masterlistId,
        ...restValues,
        question_type_1: values.question_type_1 || '',
        question_1: values.question_1 || '',
        fields: [...fiedlsData.map(({ key, id, ...keepAttrs }) => keepAttrs)],
      };
    }

    const tempData = { ...documentsData };
    Object.entries(tempData).forEach((i) => {
      if (i[0] === 'document') {
        if (i[1].value.length) {
          i[1].value
            .filter((i) => !i.id)
            .forEach((j, idx) => {
              formData.append(`file_${idx + 1}`, j);
            });
        }
      }
    });

    if (values.type !== 'question_or_form') {
      data.question_type_1 = '';
      data.question_1 = '';
      data.question_type_2 = '';
      data.question_2 = '';
      data.question_type_3 = '';
      data.question_3 = '';

      delete data.question_type_1;
      delete data.question_1;
      delete data.question_type_2;
      delete data.question_2;
      delete data.question_type_3;
      delete data.question_3;
    }

    if (edit) {
      data.document_id = +masterlistDocId;
    } else {
      setMasterlistDocData((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }

    Object.entries(data).forEach(([key, value]) => {
      if (key !== 'fields' || value.length !== 0) {
        if (key === 'fields') {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
    });

    if (data.type === 'completed' && !typeCompletedAction) {
      setMasterlistDocData((prev) => ({
        ...prev,
        loading: true,
      }));
      setErrors((prev) => ({
        ...prev,
        type_completed_action: 'Please chose item action',
      }));
      return;
    }

    if (data.type === 'completed') {
      formData.set('type_completed_action', typeCompletedAction);
    }

    API()
      .post('/user_settings/masterlist/document', formData)
      .then((res) => {
        if (+res.data.id >= 0) {
          if (goBack) {
            history.push({
              pathname: `/onboarding/masterlists/${masterlistId}/edit`,
            });
          } else {
            setMasterlistDocData((prevState) => ({
              ...prevState,
              loading: true,
            }));
          }
          setDocumentsData({
            document: {
              value: [],
              error: false,
              errorMessage: 'Upload a document',
            },
          });
          form.resetFields();
          setFieldsData([]);
          message.info(edit ? 'Masterlist item successful edited' : 'Masterlist item successful added');
          setGoBack(false);
        }
      })
      .catch((err) => {
        message.error(err.response.data.error);
      });
  };
  const onCancel = () => {
    history.push({
      pathname: `/onboarding/masterlists/${masterlistId}/edit`,
    });
  };

  const onFormValuesChange = (values) => {
    if ('type' in values) {
      if (values.type === 'question_or_form') {
        setHiddenQuestion(false);
      } else if (values.type !== 'question_or_form') {
        setHiddenQuestion(true);
      }

      if (values.type === 'completed') {
        setHiddenAction(false);
      } else if (values.type !== 'completed') {
        setHiddenAction(true);
      }
    }
  };

  function getMasterlistDocData(categories) {
    API()
      .get('/user_settings/masterlist/document', {
        params: {
          document_id: masterlistDocId,
        },
      })
      .then((res) => {
        if (res.data.type === 'question_or_form') {
          setHiddenQuestion(false);
          if ('question_2' in res.data && 'question_3' in res.data) {
            form.setFields([
              {
                name: 'questions',
                value: [
                  {
                    question_type_2: res.data.question_type_2,
                    question_2: res.data.question_2,
                  },
                  {
                    question_type_3: res.data.question_type_3,
                    question_3: res.data.question_3,
                  },
                ],
              },
            ]);
          } else if ('question_2' in res.data) {
            form.setFields([
              {
                name: 'questions',
                value: [
                  {
                    question_type_2: res.data.question_type_2,
                    question_2: res.data.question_2,
                  },
                ],
              },
            ]);
          }
          form.setFields([
            {
              name: 'question_type_1',
              value: res.data.question_type_1,
            },
            {
              name: 'question_1',
              value: res.data.question_1,
            },
          ]);
        } else if (res.data.type === 'completed') {
          setHiddenAction(false);
        }
        form.setFields([
          {
            name: 'name',
            value: res.data.name,
          },
          {
            name: 'description',
            value: res.data.description,
          },
          {
            name: 'type',
            value: res.data.type,
          },
          {
            name: 'category_id',
            value: categories.find((el) => el.name === res.data.category)?.id,
          },
        ]);
        if (res.data.type_completed_action) {
          setTypeCompletedAction(res.data.type_completed_action);
          form.setFields([
            {
              name: 'type_completed_action',
              value: res.data.type_completed_action,
            },
          ]);
        }
        setFieldsData(res.data.fields.map((el, idx) => ({ ...el, key: idx })));
        setDocumentsData((state) => ({
          ...state,
          document: {
            ...state.document,
            value: [...(res.data.files || [])],
          },
        }));
        setMasterlistDocData(() => ({
          loading: true,
          ...res.data,
        }));
      });
    // .catch(err => {
    //     message.error(err?.response?.data?.error);
    // })
  }

  const removeDocument = (idx) => {
    setDocumentsData((state) => ({
      ...state,
      document: {
        ...state.document,
        value: state.document.value.filter((_, index) => idx !== index),
      },
    }));
  };

  useEffect(() => {
    API()
      .get('/superadmin/settings/categories')
      .then((res) => {
        const { categories } = res.data;
        setCategories(categories);

        if (edit) {
          getMasterlistDocData(categories);
        } else {
          form.setFields([
            {
              name: 'type',
              value: 'regular',
            },
          ]);
          setMasterlistDocData((prevState) => ({
            ...prevState,
            loading: true,
          }));
        }
      });

    // eslint-disable-next-line
  }, []);

  function handleAddFile() {
    const limit = 5;

    if (documentsData?.document?.value.length + uploadRef.current.files.length > limit) {
      message.error(`You can only select up to ${limit} documents`);
      return;
    }

    setDocumentsData((prev) => ({
      ...prev,
      document: {
        ...prev.document,
        error: false,
        value: [...prev.document.value, ...uploadRef.current.files],
      },
    }));

    message.success('Document uploaded!');
  }

  useEffect(() => {
    if (!documentsData?.document?.value || !uploadRef.current) {
      return;
    }

    uploadRef.current.value = null;
  }, [documentsData?.document?.value]);

  return (
    <div className="prospects_page_wrapper table_with_header_wrapper table_with_pagination_wrapper">
      <div>
        <Breadcrumb style={{ marginBottom: 15 }}>
          <Breadcrumb.Item href="" onClick={onGoBack}>
            Masterlists
          </Breadcrumb.Item>
          <Breadcrumb.Item>Add new Item</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="section_title_wrapper">
        <SectionTitle title="Add an item to the masterlist" />
      </div>
      <Spin spinning={!masterlistDocData.loading} size="large">
        <div style={{ maxWidth: 1000 }}>
          <Form
            hideRequiredMark
            form={form}
            layout="vertical"
            style={{ maxWidth: 979 }}
            onChange={onFormChange}
            onValuesChange={onFormValuesChange}
            onFinish={handleAddEditMasterlistDoc}
          >
            <Row gutter={[25, 10]}>
              {generateForm(formData)}
              {!hiddenQuestion ? (
                <Form.List name="questions">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <>
                          <Col span={10}>
                            <Form.Item
                              {...restField}
                              label={`Question ${+name + 2}`}
                              name={[name, `question_${+name + 2}`]}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input the question!',
                                },
                              ]}
                            >
                              <Input.TextArea style={{ minHeight: 80 }} rows={4} autoSize />
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              {...restField}
                              label={`Question type ${+name + 2}`}
                              name={[name, `question_type_${+name + 2}`]}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select question type!',
                                },
                              ]}
                            >
                              <Select>
                                {questionTypes.map((el) => (
                                  <Select.Option key={el.value} value={el.value}>
                                    {el.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <CustomButton
                              onClick={() => {
                                remove(name);
                                setDisableSubmitBtns(false);
                              }}
                              className="btn_darkblue"
                              text="Remove"
                            />
                          </Col>
                        </>
                      ))}
                      <Col span={12}>
                        <Form.Item>
                          <CustomButton
                            disabled={fields.length === 2}
                            onClick={() => {
                              add();
                              setDisableSubmitBtns(false);
                            }}
                            text="Add question"
                            className="btn_darkblue"
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Form.List>
              ) : (
                <Col span={10}>
                  <div className="logo-wrapper">
                    <label className="input_title">Attach a document</label>
                    <div
                      className={`logo-row ${documentsData?.document?.error ? 'error' : ''}`}
                      style={{ marginTop: 8 }}
                    >
                      <Space
                        size={8}
                        direction="vertical"
                        style={{
                          width: '100%',
                        }}
                      >
                        {documentsData.document.value.map((item, i) => (
                          <div
                            className="document"
                            key={i}
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <span>
                              Document {i + 1}: {item.name}
                            </span>
                            <CloseIcon
                              colour="#1c1c1c"
                              style={{ cursor: 'pointer' }}
                              onClick={() => removeDocument(i)}
                            />
                          </div>
                        ))}
                        <CustomButton
                          onClick={() => uploadRef.current.click()}
                          style={{
                            padding: '4px 32px',
                            width: '100%',
                          }}
                          text={
                            <div
                              className="upload-logo"
                              style={{
                                alignItems: 'center',
                                display: 'flex',
                                gap: 10,
                              }}
                            >
                              <span>Drop here or click to upload</span>
                              <FileUploadIcon colour="#1f1f1f" size="1.5em" />
                            </div>
                          }
                        />
                      </Space>
                      <input
                        accept=".xlsx, .pdf"
                        hidden
                        multiple
                        onChange={handleAddFile}
                        ref={uploadRef}
                        type="file"
                      />
                    </div>
                    {documentsData?.document?.error && <span className="errorMessage">Select at least 1 document</span>}
                  </div>
                </Col>
              )}
            </Row>
          </Form>
          <Divider style={{ borderTop: '1px solid #D9D9D9' }} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 45,
            }}
          >
            <CustomButton style={{ minWidth: 110 }} onClick={onCancel} text="Cancel" />
            <Space size="large">
              <CustomButton
                disabled={disableSubmitBtns && edit}
                onClick={() => {
                  setGoBack(true);
                  form.submit();
                }}
                style={{ minWidth: 110 }}
                className="btn_darkblue"
                text="Save"
              />
              <CustomButton
                disabled={disableSubmitBtns && edit}
                onClick={form.submit}
                style={{ minWidth: 110 }}
                type="submit"
                className="btn_darkblue"
                text="Save & Add another"
              />
            </Space>
          </div>
        </div>
      </Spin>
      <FieldModal
        activeModal={activeModal}
        editMode={activeModal?.data?.edit}
        handleAddField={handleAddField}
        handleEditField={handleEditField}
        onClose={onCloseActiveModal}
        onOk={() => {}}
        visible={activeModal.show === 'field_modal'}
      />
    </div>
  );
}

export default MasterlistDocument;
