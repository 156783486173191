import { Button, Tooltip } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LARAPI } from 'api';
import ManualEMSModal from 'components/Modals/ManualEMSModal';
import styles from './InviteBorrowerButton.module.css';

export default function InviteBorrowerButton() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const API = useMemo(() => new LARAPI(id), [id]);

  useEffect(() => {
    API.getPointsOfContact().then((res) => {
      setData(res[0]);
    });
  }, [API]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSend = (data) => {
    setIsSending(true);
    API.postDDCenterInviteBorrower({ lar_id: id, message: data.message }).finally(() => {
      setIsOpen(false);
      setIsSending(false);
    });
  };

  return (
    <>
      <Tooltip title="Invite borrower to Diligence Center">
        <Button className={styles.inviteButton} type="primary" onClick={handleOpen}>
          Invite Borrower
        </Button>
      </Tooltip>
      <ManualEMSModal
        mainRecipient={data}
        templateRoute={`/LAR/dd_center_invite_borrower?lar_id=${id}`}
        visible={isOpen}
        isSending={isSending}
        onCancel={handleClose}
        onSubmit={handleSend}
      />
    </>
  );
}
