import React from 'react';
import './styles/checkbox.css';
import { Tooltip } from 'antd';

function SwitchBox({ value, id, text, onClick, disabled }) {
  return (
    <Tooltip title={disabled}>
      <div onClick={disabled || onClick} className={`wrapper_checkbox ${disabled ? 'disabled' : ''}`} id={id}>
        <div className={`wrapper_switcher switcher_${value}`}>
          <div className="checkbox" />
        </div>

        {text && <span className="switcher_text">{text}</span>}
      </div>
    </Tooltip>
  );
}

export default SwitchBox;
