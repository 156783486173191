import API from 'api';
import { useHistory } from 'react-router-dom';
import Keys from 'constants/helper';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserData } from 'store/actions/userActions';

export function PlugLar({ token }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const getUserData = () => {
    API(token)
      .get('/profile')
      .then((res) => {
        const data = res.data.user;
        if (data.access_token) {
          localStorage.setItem(Keys.JWT_TOKEN, data.access_token);
        } else {
          localStorage.setItem(Keys.JWT_TOKEN, token);
        }

        dispatch(
          setUserData({
            role: data.role,
            token: data.access_token,
          }),
        );

        localStorage.setItem(Keys.USER_DATA, JSON.stringify(data));
        API()
          .get('/LAR/creation/data_for_form', {
            params: {
              borrower_id: data.id,
              creator: data.role,
              // type,
            },
          })
          .then((res) => {
            history.push(`/lar_app/create${res.data.token}`);
          });
      })
      .catch(({ response }) => {
        console.log('error');
        if (response?.data?.error) {
          console.log('error');
          // setResponseError(response.data.error);
        }
      });
  };

  useEffect(() => {
    getUserData();
  }, []);
  return <div>LOADING</div>;
}
