import './styles/custom_search_input.css';
import { Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import regExp from '../../constants/regExp';

function CustomSearchInput({
  clearFunc = () => null,
  enterButton = true,
  hideDropdown,
  loading,
  minContainerWidth = null,
  onlyNumbers,
  placeholder = '',
  results = [],
  searchFunc = () => null,
  style = {},
}) {
  const [show, setShow] = useState(false);
  const [overResult, setOverResult] = useState(false);
  const resultBlockRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const ref = useRef();

  const handleChange = (e) => {
    const { value } = e.target;

    const submitChange = () => {
      setShow(true);
      if (hideDropdown ? false : !value) {
        clearFunc();
        setShow(false);
      } else {
        searchFunc(value);
      }
      setSearchValue(value);
    };

    if (onlyNumbers) {
      if (regExp.only_number.test(value)) {
        submitChange();
      }
    } else {
      submitChange();
    }
  };

  const noDataItem = !loading ? <div className="custom-search__no-data-item">No results</div> : null;

  const isEmptyResults = results.length === 0;

  const onMouseOverResult = () => {
    setOverResult(true);
  };
  const onMouseOutResult = () => {
    setOverResult(false);
  };

  const onInputSearchBlur = () => {
    if (!overResult) {
      setTimeout(() => {
        setShow(false);
        clearFunc();
      }, 100);
    }
  };

  useEffect(() => {
    const resultBlock = resultBlockRef.current;
    resultBlock.addEventListener('mouseover', onMouseOverResult);
    resultBlock.addEventListener('mouseout', onMouseOutResult);

    return () => {
      resultBlock.removeEventListener('mouseover', onMouseOverResult);
      resultBlock.removeEventListener('mouseout', onMouseOutResult);
    };
  }, []);

  return (
    <div className="custom-search custom-search_default" style={{ minWidth: minContainerWidth, ...style }}>
      <Input.Search
        onBlur={onInputSearchBlur}
        ref={ref}
        value={searchValue}
        className="custom-search__input"
        onChange={handleChange}
        placeholder={placeholder}
        loading={loading}
        enterButton={enterButton}
        onFocus={(e) => {
          setShow(true);
          handleChange(e);
        }}
      />
      <div
        ref={resultBlockRef}
        className={`custom-search__result-block ${!show || hideDropdown ? 'custom-search__result-block_disabled' : ''}`}
      >
        {isEmptyResults ? noDataItem : results}
      </div>
    </div>
  );
}
export default CustomSearchInput;
