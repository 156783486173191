import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomButton from 'components/UI/Button';
import mortgageLoanSVG from 'assets/images/MortgageLoan.svg';
import classes from './styles/Placeholder.module.css';

export default function Placeholder() {
  const role = useSelector((s) => s.user?.userData?.role);
  const isAdmin = /admin/i.test(role);

  return (
    <div className={classes.root}>
      <img src={mortgageLoanSVG} alt="" />
      <b>There are no scenarios yet</b>
      {isAdmin ? (
        <>
          <div>You can review your mandates to get more invites.</div>
          <Link to="/onboarding/mandates">
            <CustomButton text="Review Mandates" primary />
          </Link>
        </>
      ) : (
        <div>To get more invites, ask your company manager to review mandates.</div>
      )}
    </div>
  );
}
