import React from 'react';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import CustomPicker from '../../CustomComponents/CustomPicker';

function ReminderForm({ disabled, item, onChangeDate, onDelete }) {
  return (
    <div className="add_new_reminder__form">
      <CustomPicker error={item.date.error} title="Date" errorMessage={item.date.errorMessage}>
        <DatePicker
          onChange={(_, d) => onChangeDate(d, 'date')}
          value={item.date.value ? moment(item.date.value, 'DD/MM/YYYY') : ''}
          format="DD/MM/YYYY"
          disabled={disabled}
          className={disabled ? 'disabled_text_black' : ''}
          disabledDate={(current) => current && current < moment().startOf('day')}
        />
      </CustomPicker>
      <div className="split" style={Object.entries(item).some((e) => e[1].error) ? { paddingBottom: '26px' } : null}>
        <span>at</span>
      </div>
      <CustomPicker error={item.time.error} title="Scheduled emails" errorMessage={item.time.errorMessage}>
        <TimePicker
          onChange={(_, d) => onChangeDate(d, 'time')}
          use12Hours
          allowClear={false}
          className={disabled ? 'disabled_text_black' : ''}
          disabled={disabled}
          hideDisabledOptions
          format="hh:mm A"
          value={item.time.value ? moment(item.time.value, 'h:mm A') : ''}
        />
      </CustomPicker>
      {disabled ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => onDelete(item)}
        >
          <path
            d="M13.5909 12L18.0441 7.54687C18.2554 7.3359 18.3743 7.04962 18.3745 6.75099C18.3748 6.45237 18.2564 6.16587 18.0455 5.95453C17.8345 5.74319 17.5482 5.62431 17.2496 5.62404C16.951 5.62378 16.6645 5.74215 16.4531 5.95312L12 10.4062L7.54687 5.95312C7.33552 5.74178 7.04888 5.62305 6.74999 5.62305C6.4511 5.62305 6.16446 5.74178 5.95312 5.95312C5.74177 6.16447 5.62304 6.45111 5.62304 6.75C5.62304 7.04888 5.74177 7.33553 5.95312 7.54687L10.4062 12L5.95312 16.4531C5.74177 16.6645 5.62304 16.9511 5.62304 17.25C5.62304 17.5489 5.74177 17.8355 5.95312 18.0469C6.16446 18.2582 6.4511 18.3769 6.74999 18.3769C7.04888 18.3769 7.33552 18.2582 7.54687 18.0469L12 13.5937L16.4531 18.0469C16.6645 18.2582 16.9511 18.3769 17.25 18.3769C17.5489 18.3769 17.8355 18.2582 18.0469 18.0469C18.2582 17.8355 18.3769 17.5489 18.3769 17.25C18.3769 16.9511 18.2582 16.6645 18.0469 16.4531L13.5909 12Z"
            fill="#8C8C8C"
          />
        </svg>
      ) : null}
    </div>
  );
}

export default ReminderForm;
