import socket from 'constants/socket';
import Keys from './helper';

export default function forceLogOff(redirectURI = '/') {
  localStorage.removeItem(Keys.JWT_TOKEN);
  localStorage.removeItem(Keys.KEEP_ME);
  sessionStorage.clear();
  window.location = redirectURI;
  socket.disconnect();
}
