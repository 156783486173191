import arrowRight from 'assets/images/arrow-right.svg';

import styles from './styles.module.css';
import { Button, Drawer, Form, message } from 'antd';
import { useParams } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import { formatPhone } from 'constants/formatPhone';
import LegacyAPI from 'api';

function SendSMSDrawer({ onClose, activeModal }) {
  const { data, show } = activeModal;

  const { id } = useParams();
  console.log(id);
  const [form] = Form.useForm();

  function handleSubmitForm() {
    form.submit();
  }

  async function handleFinish(e) {
    const newData = { lar_id: Number(id), message: e.text, user_id: data.id };
    try {
      await LegacyAPI().post('/LAR/dashboard/PLA/send_sms_to_mandate', newData);
      onClose();
      form.resetFields();
      message.success('Message has been sent!');
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <Drawer
      placement="right"
      onClose={onClose}
      closeIcon={false}
      bodyStyle={{ padding: 0 }}
      className={styles.drawer}
      width={360}
      visible={show}
      headerStyle={{ display: 'none' }}
    >
      {data && (
        <div>
          <div className={styles.drawerHeader}>
            <img onClick={onClose} src={arrowRight} alt="" />
            <p className={styles.headerTitle}>Send SMS</p>
          </div>
          <Form
            form={form}
            // name="senDSMS"
            initialValues={{
              text: '',
            }}
            onFinish={handleFinish}
            layout="vertical"
          >
            <div className={styles.section} style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <p className={styles.title}>{data.full_name}</p>
              <p className={styles.subTitle}>{data.company_name}</p>
              <p className={styles.subTitle}>Phone: {formatPhone(`+1${data.phone}`) || 'Unknown'}</p>
              <p className={styles.subTitle}>Email: {data.email}</p>
            </div>
            <div className={styles.section}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Messsage is required.',
                    min: 10,
                    max: 160,
                  },
                ]}
                label="SMS Text (160 Characters Max)"
                name="text"
              >
                <TextArea style={{ minHeight: 80 }} />
              </Form.Item>
              <Button onClick={handleSubmitForm} style={{ width: '100%' }} type="dark">
                Send SMS
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Drawer>
  );
}

export default SendSMSDrawer;
