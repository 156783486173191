import { Modal } from 'antd';
import CustomButton from '../../UI/Button';

export default function DeleteMasterlistModal({ visible, onClose, handleDeleteMasterlist }) {
  return (
    <Modal
      centered
      className="wrapper_prospect wrapper_prospect_delete"
      footer={
        <div className="wrapper_remove_prospect_btns">
          <CustomButton key="back" onClick={onClose} text="Cancel" />
          <CustomButton className="btn_darkblue" key="submit" onClick={handleDeleteMasterlist} text="Confirm" />
        </div>
      }
      onCancel={onClose}
      title={
        <div className="wrapper_prospect__title">
          <span className="title">Delete masterlist</span>
          <span className="description">Are you sure you want to delete this masterlist?</span>
        </div>
      }
      open={visible}
    />
  );
}
