import { Button } from 'antd';
import styles from './QuoteTermsFilters.module.css';

const filterList = [
  { value: 'all', label: 'Show All' },
  { value: 'selected', label: 'Show selected' },
];

function QuoteTermsFilters({ filter, setFilter }) {
  function handleFilter(value) {
    setFilter(value);
  }

  return (
    <div className={styles.filter}>
      {filterList.map((filterItem) => (
        <Button onClick={() => handleFilter(filterItem.value)} type={filter === filterItem.value && 'primary'}>
          {filterItem.label}
        </Button>
      ))}
    </div>
  );
}

export default QuoteTermsFilters;
