import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <path d="M9 7H6C5.46957 7 4.96086 7.21071 4.58579 7.58579C4.21071 7.96086 4 8.46957 4 9V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H15C15.5304 20 16.0391 19.7893 16.4142 19.4142C16.7893 19.0391 17 18.5304 17 18V15" />
    <path d="M9 15.0002H12L20.5 6.50023C20.8978 6.1024 21.1213 5.56284 21.1213 5.00023C21.1213 4.43762 20.8978 3.89805 20.5 3.50023C20.1022 3.1024 19.5626 2.87891 19 2.87891C18.4374 2.87891 17.8978 3.1024 17.5 3.50023L9 12.0002V15.0002Z" />
    <path d="M16 5L19 8" />
  </GenericIcon>
));
