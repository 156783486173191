import { mainNavigation } from './mainNavigation';
import { mainNavigationAuction } from './mainNavigationAuction';
import { mainNavigationAuth } from './mainNavigationAuth';
import { mainNavigationBidApplication } from './mainNavigationBidApplication';
import { mainNavigationOnboarding } from './mainNavigationOnboarding';
import { mainNavigationOnboardingDocuments } from './mainNavigationOnboardingDocuments';
import { mainNavigationOnboardingPreferencesBroker } from './mainNavigationOnboardingPreferencesBroker';
import { mainNavigationRegisterBorrower } from './mainNavigationRegisterBorrower';
import { mainNavigationRegisterBroker } from './mainNavigationRegisterBroker';
import { mainNavigationRegisterCompany } from './mainNavigationRegisterCompany';
import { mainNavigationRegisterLender } from './mainNavigationRegisterLender';
import { mainNavigationSuperSettings } from './mainNavigationSuperSettings';
import mainNavLarAppNew from './mainNavLarAppNew';

export const Navigations = {
  mainNavigation,
  mainNavigationAuction,
  mainNavigationAuth,
  mainNavigationBidApplication,
  mainNavigationOnboarding,
  mainNavigationOnboardingDocuments,
  mainNavigationOnboardingPreferencesBroker,
  mainNavigationRegisterBroker,
  mainNavigationRegisterBorrower,
  mainNavigationRegisterCompany,
  mainNavigationRegisterLender,
  mainNavigationSuperSettings,
  mainNavLarAppNew,
};
