import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import PropTypes from 'prop-types';

function PlaceQuoteAction({ auction, isBidPlaced, isImmediateAuction = false, onOpenBidDrawer }) {
  const [isVisible, setIsVisible] = useState(true);
  const [bidDisabled, setBidDisabled] = useState(false);

  const role = useSelector((store) => store.user.userData?.role);
  const { metrics } = useSelector((store) => store.dashboard);

  useEffect(() => {
    if (metrics?.timing_json?.event === 'Preview open') {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [metrics]);

  useEffect(() => {
    if (!/lender/i.test(role)) {
      return;
    }

    if (isImmediateAuction) {
      setBidDisabled(false);
      return;
    }

    const t = setInterval(() => {
      const date = new Date();
      const estDate = new Date(
        date.toLocaleString('en-US', {
          timeZone: 'America/New_York',
        }),
      ).getTime();

      const bidDeadlineTime = new Date(auction.bid_deadline).getTime();
      const startAuctionTime = new Date(auction.start_time).getTime();
      const endAuctionTime = new Date(auction.end_time_p).getTime();

      // Auction hasn't started yet
      if (startAuctionTime >= estDate) {
        return setBidDisabled(true);
      }

      if (metrics?.timing_json?.event === 'Auction Live' && !auction.bid_deadline) {
        return setBidDisabled(false);
      }

      if (auction.bid_deadline) {
        if (estDate > startAuctionTime && bidDeadlineTime > estDate) {
          return setBidDisabled(false);
        }
      }

      // Bid deadline and no bids
      if (bidDeadlineTime < estDate && !isBidPlaced) {
        return setBidDisabled(true);
      }

      // Auction initial bid deadline hasn't passed
      if (startAuctionTime <= estDate && bidDeadlineTime >= estDate) {
        return setBidDisabled(false);
      }

      // Auction initial bid deadline has passed, but lender can revise it
      if (isBidPlaced && endAuctionTime >= estDate && bidDeadlineTime <= estDate) {
        return setBidDisabled(false);
      }
    }, 1e3);

    return () => clearInterval(t);
  }, [auction, isBidPlaced, role, isImmediateAuction]);

  return (
    isVisible && (
      <Button type="primary" disabled={bidDisabled} onClick={onOpenBidDrawer}>
        {isBidPlaced ? 'Revise Quote' : 'Submit a quote'}
      </Button>
    )
  );
}

PlaceQuoteAction.propTypes = {
  auction: PropTypes.object,
  isBidPlaced: PropTypes.bool,
  isImmediateAuction: PropTypes.bool,
  onOpenBidDrawer: PropTypes.func,
};

export default PlaceQuoteAction;
