const larVariables = {
  loan_purpose_name: {
    purchase: 'Purchase',
    refinance: 'Refinance',
  },
  loan_term_name: {
    long: 'Long Term',
    short: 'Short Term',
  },
  use: {
    consumer: 'Consumer',
    business: 'Business',
  },
  refi_loan_type_name: {
    cash_out: 'Cash out',
    rate_and_term: 'Rate & Term',
  },
};
export default larVariables;

// need yet one field for file form "filename"
