import { AlarmClockIcon, FileInvoiceIcon, TickCircleIcon, TrophyIcon, WarningTriangleIcon } from 'assets/icons';

const notificationBannerOptions = {
  icons: [
    {
      Component: AlarmClockIcon,
      id: 'alarm',
    },
    {
      Component: FileInvoiceIcon,
      id: 'file',
    },
    {
      Component: TickCircleIcon,
      id: 'tick',
    },
    {
      Component: TrophyIcon,
      id: 'trophy',
    },
    {
      Component: WarningTriangleIcon,
      id: 'warning',
    },
  ],
  colourSchemes: [
    {
      colourA: '#40a9ff',
      colourB: '#e6f7ff',
      id: 'blue',
    },
    {
      colourA: '#73d13d',
      colourB: '#f6ffed',
      id: 'green',
    },
    {
      colourA: '#1f1f1f',
      colourB: '#f0f0f0',
      id: 'grey',
    },
    {
      colourA: '#ff4d4f',
      colourB: '#fff1f0',
      id: 'red',
    },
    {
      colourA: '#ffc53d',
      colourB: '#fffbe6',
      id: 'yellow',
    },
  ],
  actions: [
    {
      id: 'view_archive',
      label: 'View archive',
      link: () => '', // TODO
    },
    {
      id: 'view_auction',
      label: 'View Auction',
      link: (id) => `/onboarding/lar_db/${id}/activity`,
    },
    {
      id: 'view_quotes',
      label: 'View Quotes',
      link: (id) => `/onboarding/lar_db/${id}/activity`,
    },
    {
      id: 'view_diligence',
      label: 'View diligence',
      link: (id) => `/onboarding/lar_db/${id}/loan_processing/verification`,
    },
    {
      id: 'view_scenario',
      label: 'View scenario',
      link: (id) => `/onboarding/scenarios/${id}`,
    },
    {
      id: 'none',
      label: '',
      link: () => '',
    },
  ],
};

export default notificationBannerOptions;
