export const BROKER_ADMIN = 'Broker Admin';
export const BROKER_LOAN_MANAGER = 'Broker Loan Manager';
export const BROKER_TRANSACTION_MANAGER = 'Broker Transaction Manager';
export const LENDER_ADMIN = 'Lender Admin';
export const LENDER_ACCOUNT_EXECUTIVE = 'Lender Account Executive';
export const LENDER_TRANSACTION_MANAGER = 'Lender Transaction Manager';
export const PLA = 'PLA';
export const LOM = 'LOM';
export const BORROWER = 'Borrower';

export const BROKER_ADMIN_ALLOWED_ROLES = [BROKER_LOAN_MANAGER, BROKER_TRANSACTION_MANAGER];
export const BROKER_LOAN_MANAGER_ALLOWED_ROLES = [BROKER_LOAN_MANAGER];
export const BROKER_TRANSACTION_MANAGER_ALLOWED_ROLES = [BROKER_TRANSACTION_MANAGER];
export const LENDER_ADMIN_ALLOWED_ROLES = [LENDER_ACCOUNT_EXECUTIVE, LENDER_TRANSACTION_MANAGER];
export const LENDER_ACCOUNT_EXECUTIVE_ALLOWED_ROLES = [LENDER_ACCOUNT_EXECUTIVE];
export const LENDER_TRANSACTION_MANAGER_ALLOWED_ROLES = [LENDER_TRANSACTION_MANAGER];
export const PLA_ALLOWED_ROLES = [PLA];
export const LOM_ALLOWED_ROLES = [LOM];
export const BROKERS = [BROKER_ADMIN, BROKER_LOAN_MANAGER, BROKER_TRANSACTION_MANAGER];
export const LENDERS = [LENDER_ADMIN, LENDER_ACCOUNT_EXECUTIVE, LENDER_TRANSACTION_MANAGER];
