import { useEffect, useMemo, useState } from 'react';
import { Divider, Form, Row, Button, message, Tooltip } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  entityOwnersHelper,
  getLarType,
  hideForms,
  initialLarProgress,
  larVariables,
  showFormErrors,
} from 'components/LarAppNew/helpers';
import popupInitialState from 'constants/popupState';
import { getSaveData } from 'components/LarAppNew/helpers/getSaveData';
import AddressForm from 'components/LarAppNew/Forms/AddressForm';
import BorrowerForm from 'components/LarAppNew/Forms/BorrowerForm';
import BorrowerFundsForm from 'components/LarAppNew/Forms/BorrowerFundsForm';
import BusinessForm from 'components/LarAppNew/Forms/BusinessForm';
import CoBorrowersForm from 'components/LarAppNew/Forms/CoBorrowersForm';
import CreditHistoryForm from 'components/LarAppNew/Forms/CreditHistoryForm';
import Header from 'components/LarAppNew/Header';
import LarAppContext from 'components/LarAppNew/context/LarAppContext';
import MainLayout from 'components/Layouts/MainLayout/MainLayout';
import ProductForm from 'components/LarAppNew/Forms/ProductForm';
import ProjectForm from 'components/LarAppNew/Forms/ProjectForm';
import PropertyForm from 'components/LarAppNew/Forms/PropertyForm';
import PropertyIncomeExpenseForm from 'components/LarAppNew/Forms/PropertyIncomeExpenseForm';
import Section from 'components/LarAppNew/Section';
import UploadForm from 'components/LarAppNew/Forms/UploadForm';
import useCreateLarForm from 'components/LarAppNew/hooks/useCreateLarForm';
import useLarApi from 'components/LarAppNew/hooks/useLarApi';
import styles from './LarApp.module.css';
import SubmitPopup from './SubmitPopup/SubmitPopup';
import BorrowerSubmitPopup from './BorrowerSubmitPopup/BorrowerSubmitPopup';
import API from 'api';

const { getInitState: getInitOwnersState } = entityOwnersHelper;

function LarApp({ lar_id, createLarForm }) {
  const { saveLar, endLarCreation } = useLarApi();
  const forms = useCreateLarForm();
  const history = useHistory();
  const { business_plan, loan_purpose_name, loan_term_name, SA_values, status } = createLarForm;
  const initialFormsKeys = Object.keys(forms);

  const [progress, setProgress] = useState(initialLarProgress);
  const [progressLA, setLAProgress] = useState(initialLarProgress);
  const [text, setText] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [popup, setPopup] = useState(popupInitialState);

  const [activeFormsKeys, setActiveFormsKeys] = useState(initialFormsKeys);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const role = useSelector((s) => s.user.userData?.role);
  const brokerName = createLarForm.team.broker.full_name;
  const { isRefinance, isPurchaseLong, isPurchaseShort } = getLarType({
    loan_term_name,
    loan_purpose_name,
  });

  const changeText = (text) => {
    setText(text);
  };

  const initOwnersState = getInitOwnersState(createLarForm?.entity_owners);
  const [entityOwnersDataSource, setEntityOwnersDataSource] = useState(initOwnersState);

  const initCoBorrowersState = createLarForm?.co_borrowers?.map((el) => ({
    first_name: el.first_name,
    fico: el.credit_rating,
    last_name: el.last_name,
    relationships: el.relationship,
  }));

  const [coBorrDataSource, setCoBorrDataSource] = useState(initCoBorrowersState);

  const {
    project,
    property,
    address,
    borrower,
    product,
    borrowerFunds,
    credit_history,
    business,
    property_income_and_expense,
  } = forms;

  const borrowerType = Form.useWatch('borrower_entity_type_name', borrower);
  const isIndividual = borrowerType === 'Individual';
  const { use } = createLarForm;
  const title = isRefinance ? 'Refinance' : `${loan_purpose_name} - ${loan_term_name}`;
  const commonProps = {
    isPurchaseLong,
    isPurchaseShort,
    isRefinance,
    SA_values,
    setLAProgress,
    setProgress,
  };

  const progressPercent = useMemo(() => {
    const progressArr = Object.values(progress).filter((el) => el !== 'hidden');
    const singleFormPercent = 100 / progressArr.length;

    return (singleFormPercent * progressArr.filter(Boolean).length).toString().replace(/\.[^.]*$/, '');
  }, [progress]);

  const progressLAPercent = useMemo(() => {
    const progressArr = Object.values(progressLA).filter((el) => el !== 'hidden');
    const singleFormPercent = 100 / progressArr.length;

    return (singleFormPercent * progressArr.filter(Boolean).length).toString().replace(/\.[^.]*$/, '');
  }, [progressLA]);

  const onFormSave = (callback = () => message.success('Form saved!'), isSubmit = false, isSentEmail = false) => {
    const data = getSaveData(forms, entityOwnersDataSource, coBorrDataSource, isIndividual, {
      isPurchaseShort,
    });

    if (typeof data.business_plan !== 'undefined') {
      data.business_plan = text;
    }

    const getType = () => {
      if (isRefinance) {
        return 'refi';
      }
      if (isPurchaseShort) {
        return 'purchase_short';
      }
      if (isPurchaseLong) {
        return 'purchase_long';
      }
      return 'unknown';
    };

    const larType = getType();

    saveLar(
      {
        lar_id: +lar_id,
        percent_completed: +progressPercent,
        percent_completed_la: +progressLAPercent,
        ...data,
        save_and_exit: isSentEmail,
      },
      larType,
    )
      .then((res) => {
        if (res?.data?.msg === 'ok') {
          callback();
        }
      })
      .then(() => {
        if (isSubmit && /borrower/i.test(role)) {
          endLarCreation({
            id: +lar_id,
          });
        }
      });
  };

  function redirectAfterSubmit() {
    if (role.includes('Broker')) {
      history.push(`/lar_app/confirm/${lar_id}`);
    }
    if (role.includes('Borrower')) {
      showSubmitBorrowerPopup();
    }
  }

  function showSubmitPopup() {
    setPopup(() => ({
      show: 'lar-app-submit-popup',
      data: null,
    }));
  }

  async function showSubmitBorrowerPopup() {
    setSubmitLoading(true);
    const response = await getLink();
    setPopup(() => ({
      show: 'borrower-submit-popup',
      data: response.data?.login,
    }));
    setSubmitLoading(false);
  }

  function handleCancel() {
    setPopup(popupInitialState);
  }

  function handleSubmit() {
    if (Number(progressPercent) !== 100) {
      showSubmitPopup(true);
      return;
    }

    onFormSubmit();
  }

  function onFormSubmit() {
    const isAllFormsSuccess = Object.values(progress)
      .filter((el) => el !== 'hidden')
      .every((el) => el === true);

    if (isAllFormsSuccess) {
      onFormSave(redirectAfterSubmit, true);
    } else {
      showFormErrors(forms, { isRefinance, isPurchaseLong, isPurchaseShort });
    }
  }

  async function getLink() {
    return await API().get('/LAR/landing/broker/company-domains');
  }

  useEffect(() => {
    if (business_plan) {
      setText(business_plan);
    }
  }, [business_plan]);

  useEffect(() => {
    hideForms({
      isRefinance,
      isPurchaseLong,
      use,
      setProgress,
      setLAProgress,
      isIndividual,
    });
  }, [isPurchaseLong, use, isRefinance, isIndividual, isPurchaseShort]);

  function handleActiveFormsKeys() {
    if (isCollapsed) {
      setActiveFormsKeys(initialFormsKeys);
    } else {
      setActiveFormsKeys([]);
    }

    setIsCollapsed((prev) => !prev);
  }

  function handleActiveFormItem(formKey) {
    if (activeFormsKeys.includes(formKey)) {
      setActiveFormsKeys((prev) => prev.filter((key) => key !== formKey));
    } else {
      setActiveFormsKeys((prev) => [...prev, formKey]);
    }
  }

  return (
    <LarAppContext.Provider value={createLarForm}>
      <MainLayout title="Loan Application">
        <div className={styles.formWrapper}>
          <div className={styles.headerContainer}>
            <div className={`${styles.wrap} ${styles.headerWrap}`}>
              <Header
                onFormSave={(cb, isSubmit, isSentEmail) => onFormSave(cb, isSubmit, isSentEmail)}
                progressPercent={progressPercent}
                progressLAPercent={progressLAPercent}
                title={title}
                status={status}
                handleSubmit={handleSubmit}
                submitLoading={submitLoading}
              />
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <Tooltip title="Collapse all sections to quickly see where the Big Blue Dot is missing.">
              <Button
                size="large"
                icon={isCollapsed ? <PlusOutlined /> : <MinusOutlined />}
                onClick={handleActiveFormsKeys}
              >
                {isCollapsed ? 'Uncollapse all' : 'Collapse all'}
              </Button>
            </Tooltip>
          </div>
          <div className={`${styles.formContainer} ${styles.wrap}`}>
            <Section
              title="Project"
              activeKey={activeFormsKeys}
              formKey="project"
              handleActiveFormItem={handleActiveFormItem}
            >
              <ProjectForm
                form={project}
                loan_purpose={loan_purpose_name}
                loan_opportunity={loan_term_name}
                {...commonProps}
              />
            </Section>
            <Divider className={styles.divider} />
            <Section
              title="Property"
              activeKey={activeFormsKeys}
              formKey="property"
              handleActiveFormItem={handleActiveFormItem}
            >
              <PropertyForm
                form={property}
                loan_purpose={loan_purpose_name}
                loan_opportunity={loan_term_name}
                {...commonProps}
              />
            </Section>
            <Divider className={styles.divider} />
            <Section
              title="Address"
              activeKey={activeFormsKeys}
              formKey="address"
              handleActiveFormItem={handleActiveFormItem}
            >
              <AddressForm form={address} {...commonProps} />
            </Section>
            <Divider className={styles.divider} />
            {((isRefinance && use !== larVariables.use.consumer) ||
              (isPurchaseLong && use !== larVariables.use.consumer)) && (
              <>
                <Section
                  title="Property Income & Expense"
                  activeKey={activeFormsKeys}
                  formKey="property_income_and_expense"
                  handleActiveFormItem={handleActiveFormItem}
                >
                  <PropertyIncomeExpenseForm form={property_income_and_expense} {...commonProps} />
                </Section>
                <Divider className={styles.divider} />
              </>
            )}
            <Section
              title="Product"
              activeKey={activeFormsKeys}
              formKey="product"
              handleActiveFormItem={handleActiveFormItem}
            >
              <ProductForm
                form={product}
                loan_purpose={loan_purpose_name}
                loan_opportunity={loan_term_name}
                {...commonProps}
              />
            </Section>
            <Divider className={styles.divider} />
            <Section
              title="Borrower funds available"
              initSuccess
              initSuccessLA
              activeKey={activeFormsKeys}
              formKey="borrowerFunds"
              handleActiveFormItem={handleActiveFormItem}
            >
              <BorrowerFundsForm
                form={borrowerFunds}
                loan_purpose={loan_purpose_name}
                loan_opportunity={loan_term_name}
                {...commonProps}
              />
            </Section>
            <Divider className={styles.divider} />
            <Section
              title="Borrower"
              activeKey={activeFormsKeys}
              formKey="borrower"
              handleActiveFormItem={handleActiveFormItem}
            >
              <BorrowerForm
                dataSource={entityOwnersDataSource}
                setDataSource={setEntityOwnersDataSource}
                form={borrower}
                {...commonProps}
              />
            </Section>
            <Divider className={styles.divider} />
            <Section
              title="Signers credit history (Individual)"
              activeKey={activeFormsKeys}
              formKey="credit_history"
              handleActiveFormItem={handleActiveFormItem}
            >
              <CreditHistoryForm form={credit_history} {...commonProps} />
            </Section>
            <Divider className={styles.divider} />
            {isIndividual && (
              <>
                <Section
                  title="Co-borrowers"
                  initSuccess
                  initSuccessLA
                  activeKey={activeFormsKeys}
                  formKey="co_borrowers"
                  handleActiveFormItem={handleActiveFormItem}
                >
                  <CoBorrowersForm dataSource={coBorrDataSource} setDataSource={setCoBorrDataSource} {...commonProps} />
                </Section>
                <Divider className={styles.divider} />
              </>
            )}
            <Section
              title="Business plan"
              activeKey={activeFormsKeys}
              formKey="business"
              handleActiveFormItem={handleActiveFormItem}
            >
              <BusinessForm form={business} {...commonProps} text={text} changeText={changeText} />
            </Section>
            <Divider className={styles.divider} />
            <UploadForm />
            <Divider className={styles.divider} />
            {status !== 'Active' && (
              <Row align="middle" justify="end" style={{ marginBottom: 16 }}>
                <Button onClick={handleSubmit} size="large" type="dark">
                  Submit
                </Button>
              </Row>
            )}
          </div>
        </div>
        <SubmitPopup
          open={popup.show === 'lar-app-submit-popup'}
          handleCancel={handleCancel}
          isCollapsed={isCollapsed}
          handleActiveFormsKeys={handleActiveFormsKeys}
        />
        <BorrowerSubmitPopup
          open={popup.show === 'borrower-submit-popup'}
          onCancel={handleCancel}
          larId={lar_id}
          brokerName={brokerName}
          link={popup.data}
        />
      </MainLayout>
    </LarAppContext.Provider>
  );
}

export default LarApp;
