import './style/chat.css';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import io from 'socket.io-client';

import { ChatAPI } from 'api';
import Keys from 'constants/helper';
import { updateChatTimestamp } from 'store/actions/chat';

import ChatMessaging from 'components/OnBoarding/Chat/ChatMessaging';
import ChatUsers from 'components/OnBoarding/Chat/ChatUsers';
import UserDrawer from 'components/UserDrawer';

function Chat({ type }) {
  const dispatch = useDispatch();
  const [activeChat, setActiveChat] = useState(null);
  const [isOpenMessagesList, setIsOpenMessagesList] = useState(true);
  const [activeForward, setActiveForward] = useState(null);
  const [activeReply, setActiveReply] = useState(null);
  const [activeUserModal, setActiveUserModal] = useState({ show: false, data: null });
  const [chats, setChats] = useState({});
  const [message, setMessage] = useState('');
  const [newMessage, setNewMessage] = useState(null);
  const [search, setSearch] = useState('');
  const [showArchive, setShowArchive] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [socket, setSocket] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const { chatState, chatConnection } = useSelector((s) => s.user);
  const { timestamp } = useSelector((s) => s.chat);
  const token = localStorage.getItem(Keys.JWT_TOKEN);
  const API = useMemo(() => new ChatAPI(), []);

  const getChats = useCallback(() => {
    API.getList(showArchive, search).then(setChats);
  }, [API, search, showArchive, timestamp]);

  useEffect(() => {
    if (!token) {
      return;
    }

    getChats();
  }, [showArchive, search, chatConnection, getChats, token]);

  useEffect(() => {
    if (typeof chatState !== 'object') {
      return;
    }

    if (chatState.request === 'all') {
      getChats();
      return;
    }

    API.getMessages({
      chat_id: chatState.chat_id,
      user_id: chatState.user_id,
      lar_id: chatState.larId ?? chatState.lar_id,
      recipient_role: chatState.recipient_role,
      bid_id: chatState.bidId ?? chatState.bid_id,
    }).then((res) => {
      setActiveChat(res);
      dispatch(updateChatTimestamp());
    });
  }, [chatState, API, type]);

  useEffect(() => {
    if (token) {
      setSocket(
        io(process.env.REACT_APP_API_URL, {
          query: { jwt: token },
        }),
      );
    }
  }, [chatConnection, token]);

  useEffect(() => {
    if (socket) {
      socket.connect();

      socket.on('new_chat_message', ({ message }) => {
        setNewMessage(message);
        dispatch(updateChatTimestamp());
      });

      return () => socket.off('new_chat_message');
    }
  }, [socket]);

  useEffect(() => {
    if (!newMessage) {
      return;
    }

    setActiveChat((state) => {
      if (state?.chat?.chat_id === newMessage.chat_id) {
        // TODO: Event emits twice for some reason
        socket.emit('message_read', { chat_id: newMessage.chat_id });
        return {
          ...state,
          messages: [...state.messages, newMessage],
        };
      }

      return state;
    });
    setMessage('');

    setChats((state) => {
      const newChats = {};
      let chatFound = false;

      Object.entries(state).forEach((i) => {
        const chatIndex = i[1].findIndex((i) => i.chat_id === message.chat_id);

        if (chatIndex >= 0) {
          const chat = i[1][chatIndex];

          newChats[i[0]] = [
            {
              ...chat,
              last_message: newMessage,
            },
            ...i[1].slice(0, chatIndex),
            ...i[1].slice(chatIndex + 1),
          ];
          chatFound = true;
        } else {
          newChats[i[0]] = i[1];
        }
      });

      if (!chatFound && !search) {
        getChats();
      }

      return newChats;
    });
  }, [newMessage, message.chat_id, search, socket]);

  async function submitMessage(e, recordings) {
    e?.preventDefault();
    setIsSending(true);

    return API.postMessage(
      message,
      activeChat.chat.chat_id,
      activeReply ? activeReply.message_data.id : activeForward ? activeForward.message_data.id : null,
      recordings && recordings.length ? recordings[0].audio : null,
      documents,
    )
      .then(() => {
        activeReply && setActiveReply(null);
        activeForward && setActiveForward(null);
        setDocuments([]);
      })
      .finally(() => setIsSending(false));
  }

  function onCloseActiveUserModal() {
    setActiveUserModal((prev) => ({ ...prev, show: false }));
  }

  const className = classNames({
    'active-forward': activeForward && !activeForward.forward_to,
    'active-sidechat': type === 'sidebar' && chatState?.type === 'sidebar',
    'chat-sidebar-wrapper': type === 'sidebar',
    'sidebar-messages': chatState?.variant === 'messagesList',
    'chat-wrapper': true,
    'show-archive': showArchive,
    hidden: !isOpenMessagesList,
  });

  return (
    <div className={className}>
      <ChatUsers
        activeForward={activeForward}
        chats={chats}
        setActiveForward={setActiveForward}
        setSearch={setSearch}
        setShowArchive={setShowArchive}
        showArchive={showArchive}
        type={type}
        setIsOpenMessagesList={setIsOpenMessagesList}
        isOpenMessagesList={isOpenMessagesList}
      />
      <ChatMessaging
        activeChat={activeChat}
        activeForward={activeForward}
        activeReply={activeReply}
        getChats={getChats}
        isOpenMessagesList={isOpenMessagesList}
        isSending={isSending}
        message={message}
        documents={documents}
        type={type}
        onSetActiveChat={setActiveChat}
        onSetActiveForward={setActiveForward}
        onSetActiveReply={setActiveReply}
        onSetDocuments={setDocuments}
        onSetMessage={setMessage}
        onSubmitMessage={submitMessage}
      />
      <UserDrawer onClose={onCloseActiveUserModal} user={activeUserModal.data} visible={activeUserModal.show} />
    </div>
  );
}

export default Chat;
