import styles from './LenderInfo.module.css';

function LenderInfo({ companyName, companyLogo }) {
  return (
    <div className={styles.wrapper}>
      <div>
        {companyName && (
          <>
            {companyLogo && <img src={companyLogo} alt="logo" className={styles.logo} />}
            <p className={styles.name}>
              Quote From <strong>{companyName}</strong>
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default LenderInfo;
