import Geocode from 'react-geocode';

async function getAddressFromLatLng(lat, lng) {
  try {
    const response = await Geocode.fromLatLng(lat, lng);
    const address = {
      city: 'unknown',
      state: 'unknown',
      country: 'unknown',
      zip: 'unknown',
      address: '',
    };

    for (let i = 0; i < response.results[0].address_components.length; i++) {
      const { long_name, short_name, types } = response.results[0].address_components[i];
      types.forEach((type) => {
        switch (type) {
          case 'locality':
            address.city = long_name;
            break;
          case 'administrative_area_level_1':
            address.state = short_name;
            break;
          case 'route':
            address.address = `${address.address} ${long_name}`;
            break;
          case 'street_number':
            address.address = `${long_name} ${address.address}`;
            break;
          case 'country':
            address.country = long_name;
            break;
          case 'postal_code':
            address.zip = long_name;
            break;
          default:
        }
      });
    }

    return address;
  } catch (error) {
    console.error(error);
  }
}

export default getAddressFromLatLng;
