import moment from 'moment';
import { numValidator } from './larHelpers';

const fieldsFormData = {
  project: {
    loan_style_name: {
      type: 'select',
    },
    closing_date: {
      type: 'date',
      customValidator: (formName, { name, value, errors }) => {
        errors = [];

        if (!value && value?.toString()) {
          errors = ['Choose the date, please'];
        }

        if (value?.isBefore(moment(new Date(), 'YYYY-DD-MM')?.add(11, 'day'))) {
          errors = ['Closing date should not be less than 11 days from now'];
        }

        return { name, value, errors };
      },
    },
    STR_prior_to_purchase: {
      type: 'checkbox',
    },
    renovation_costs: {
      type: 'number',
    },
    ARV: {
      type: 'number',
      customValidator: (formName, { name, value, errors }, { isPurchaseShort }, values, submit) => {
        errors = [];

        if (value !== 0 && !value && submit) {
          errors = ['Please input the field!'];
        }

        if (isPurchaseShort) {
          const propertyValue = values.property_value;
          const renovationCosts = values.renovation_costs;
          const isBadARV = value < propertyValue + renovationCosts;
          if (isBadARV) {
            errors = ['ARV must be greater than the sum of the purchase price and renovation budget'];
          }
        }

        if (value?.toString()?.length > 9) {
          errors = ['Max length should be 9 symbols!'];
        }

        return { name, value, errors };
      },
    },
    remaining_loan_balance: {
      type: 'number',
      customValidator: (formName, field, _, { property_value }, submit) => {
        if (!field.value && submit) {
          return { ...field, errors: ['Please enter loan payoff'] };
        }

        if (property_value && field.value >= property_value) {
          return { ...field, errors: ['The loan payoff must be less than the property value'] };
        }

        return { ...field, errors: [] };
      },
    },
    interest_rate: {
      type: 'number',
    },
    current_payment: {
      type: 'number',
    },
    refi_cash_out: {
      type: 'number',
    },
    ltc: {
      type: 'number',
    },
    property_value: {
      type: 'number',
      customValidator: (formName, { name, value, errors }, { isPurchaseLong }, values, submit, SA_values) => {
        errors = [];
        if (isPurchaseLong) {
          const getLTVLong = (requestingAmount, propertyValue) => {
            const maxLTV = SA_values?.long_purchase_LTV ?? 80;
            const LTV = Number(((requestingAmount / propertyValue) * 100).toFixed(2));
            const isBadLTV = LTV > maxLTV;
            return {
              LTV,
              isBadLTV,
            };
          };
          const propertyValue = values.property_value;
          const requestingAmount = values.requesting_amount;

          const { isBadLTV } = getLTVLong(requestingAmount, propertyValue);
          if (isBadLTV && !!requestingAmount) {
            errors = ['bad ltv'];
          }
        }

        if (value !== 0 && !value && submit) {
          errors = ['Please input the field!'];
        }

        if (value?.toString()?.length > 8) {
          errors = ['Maximum purchase price is $99,999,999'];
        }

        return { name, value, errors };
      },
    },
    requesting_amount: {
      type: 'number',
      customValidator: (formName, { name, value, errors }, { isPurchaseLong }, values, submit, SA_values) => {
        if (isPurchaseLong) {
          const getLTVLong = (requestingAmount, propertyValue) => {
            const maxLTV = SA_values?.long_purchase_LTV ?? 80;
            const LTV = Number(((requestingAmount / propertyValue) * 100).toFixed(2));
            const isBadLTV = LTV > maxLTV;
            return {
              LTV,
              isBadLTV,
            };
          };
          const propertyValue = values.property_value;
          const requestingAmount = values.requesting_amount;

          const { isBadLTV } = getLTVLong(requestingAmount, propertyValue);

          if (isBadLTV) {
            errors = ['bad ltv'];
          }
        }
        return numValidator({ name, value, errors }, submit);
      },
    },
    mpi_real_estate_taxes: {
      type: 'checkbox',
    },
    mpi_property_insurance: {
      type: 'checkbox',
    },
    mpi_hoa: {
      type: 'checkbox',
    },
    mpi_other: {
      type: 'checkbox',
    },
    custom_amount_maximum_possible: {
      type: 'radio',
    },
  },
  property: {
    property_type: {
      type: 'select',
    },
    occupancy_type: {
      type: 'radio',
    },
    is_currently_occupied: {
      type: 'checkbox',
    },
    number_of_rental_units: {
      type: 'number',
    },
    number_of_rental_units_occupied: {
      type: 'number',
    },
    annual_property_tax: {
      type: 'number',
    },
    annual_property_insurance: {
      type: 'number',
    },
  },
  address: {
    address_all: {
      type: 'text',
    },
    is_rural: {
      type: 'checkbox',
    },
  },
  product: {
    income_doc_type_DSCR: {
      type: 'checkbox',
    },
    income_doc_type_stated: {
      type: 'checkbox',
    },
    income_doc_type_full_doc: {
      type: 'checkbox',
    },
    income_doc_type_1_3_bank_statements: {
      type: 'checkbox',
    },
    income_doc_type_6_12_bank_statements: {
      type: 'checkbox',
    },
    income_doc_type_24_bank_statements: {
      type: 'checkbox',
    },
    income_doc_type_k_1_only: {
      type: 'checkbox',
    },
    income_doc_type_VOE_only: {
      type: 'checkbox',
    },
    bank_account_type: {
      type: 'radio',
    },
    DSCR_rations: {
      type: 'radio',
    },
    interest_rate_type: {
      type: 'checkboxGroup',
    },
    arm_options: {
      type: 'checkboxGroup',
    },
    loan_term: {
      type: 'checkboxGroup',
    },
    payment_type: {
      type: 'checkboxGroup',
    },
    prepayment: {
      type: 'checkboxGroup',
    },
  },
  borrower: {
    borrower_entity_type_name: {
      type: 'radio',
    },
    main_borrower_first_name: {
      type: 'text',
    },
    main_borrower_middle_name: {
      type: ' ',
    },
    main_borrower_last_name: {
      type: 'text',
    },
    main_borrower_suffix: {
      type: ' ',
    },
    main_borrower_email: {
      type: 'text',
    },
    main_borrower_phone: {
      type: 'text',
    },
    main_borrower_over_21: {
      type: 'checkbox',
    },
    main_borrower_citizenship: {
      type: 'select',
    },
    main_borrower_marital_status: {
      type: 'radio',
    },
    main_borrower_employment_type: {
      type: 'radio',
    },
    main_borrower_self_employed_longer_than_24_months: {
      type: 'checkbox',
    },
    entity_name: {
      type: 'radio',
    },
    entity_type: {
      type: 'radio',
    },
    entity_formation_date: 'text',
    business_state: {
      type: 'select',
    },
    main_borrower_first_time_buyer: {
      type: 'checkbox',
    },
    main_borrower_rental_properties: {
      type: 'number',
    },
    main_borrower_flips: {
      type: 'number',
    },
  },
  credit_history: {
    FICO: {
      type: 'number',
    },
    primary_home: {
      type: 'radio',
    },
    mortgage_payment_history_30_days_late: {
      type: 'number',
    },
    mortgage_payment_history_60_days_late: {
      type: 'number',
    },
    mortgage_payment_history_90_days_late: {
      type: 'number',
    },
    mortgage_payment_history_120_days_late: {
      type: 'number',
    },
    bankruptcy: {
      type: 'radio',
    },
    bankruptcy_chapter: {
      type: 'radio',
    },
    discharge_from_bankruptcy_date: {
      type: 'date',
    },
    filling_from_bankruptcy_date: {
      type: 'date',
    },
    signer_has_completed_foreclosure: {
      type: 'radio',
    },
    foreclosure_completion_date: {
      type: 'date',
    },
    foreclosure_property_type: {
      type: 'radio',
    },
  },
  business: {
    business_plan: {
      type: ' ',
    },
  },
};
export default fieldsFormData;
