import './popup.css';
import { Button, Modal } from 'antd';
import { useContext, useEffect, useState } from 'react';
import API from 'api';
import BasicDocument from '../BasicDocument';
import CompleteDocument from '../CompleteDocument';
import CustomButton from 'components/UI/Button';
import CustomTextInput from 'components/CustomComponents/TextInput';
import LARDBContext from 'pages/Onboarding/LARDB/LARDBContext';
import QuestionForm from '../QuestionForm';
import SelectWrapper from 'components/CustomComponents/SelectWrapper';

const itemTypes = [
  {
    preview: 'Complete document',
    value: 'completed',
  },
  {
    preview: 'Basic document',
    value: 'regular',
  },
  {
    preview: 'Question form',
    value: 'question_or_form',
  },
];

const addItemData = {
  regular: {
    Component: BasicDocument,
    item_type: {
      options: itemTypes,
      title: 'Item type',
      value: 'regular',
    },
    item_name: {
      error: false,
      errorMessage: 'Fill this label',
      title: 'Document name',
      value: '',
    },
    item_description: {
      error: false,
      errorMessage: 'Fill this label',
      title: 'Document description',
      value: '',
    },
    document: {
      error: false,
      errorMessage: 'Upload a document',
      value: [],
    },
  },
  completed: {
    Component: CompleteDocument,
    item_type: {
      options: itemTypes,
      title: 'Item type',
      value: 'completed',
    },
    item_name: {
      error: false,
      errorMessage: 'Fill this label',
      title: 'Document name',
      value: '',
    },
    item_description: {
      error: false,
      errorMessage: 'Fill this label',
      title: 'Document description',
      value: '',
    },
    item_action: {
      value: 'acknowledge',
      buttons: {
        approve: {
          text: 'Acknowledge/Approve',
          value: 'acknowledge',
        },
        no_response: {
          text: 'No response needed',
          value: 'nothing',
        },
      },
    },
    document: {
      error: false,
      errorMessage: 'Upload a document',
      value: [],
    },
  },
  question_or_form: {
    Component: QuestionForm,
    item_type: {
      options: itemTypes,
      title: 'Item type',
      value: 'question_or_form',
    },
    item_name: {
      error: false,
      errorMessage: 'Fill this label',
      title: 'Document name',
      value: '',
    },
    item_description: {
      error: false,
      errorMessage: 'Fill this label',
      title: 'Document description',
      value: '',
    },
    questionList: [
      {
        errorMessage: 'Fill this label',
        id: 1,
        input_type_value: '',
        question_value: '',
        questionError: false,
        typeError: false,
      },
    ],
  },
};

function AddItemPopup({ updateDiligenceList, type, setType }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { dd } = useContext(LARDBContext);

  useEffect(() => {
    setData(addItemData[type]);
  }, [type]);

  function changeAddItemField(route, value) {
    setData((prev) => ({
      ...prev,
      [route]: {
        ...prev[route],
        value,
        error: false,
      },
    }));
  }

  function changeAddItemQuestion(fieldName, idx, value) {
    setData((prev) => ({
      ...prev,
      questionList: [
        ...prev.questionList.slice(0, idx),
        {
          ...prev.questionList[idx],
          [fieldName]: value,
          questionError: false,
          typeError: false,
        },
        ...prev.questionList.slice(idx + 1),
      ],
    }));
  }

  function addQuestion() {
    setData((prev) => ({
      ...prev,
      questionList: [
        ...prev.questionList,
        {
          id: prev.questionList.length + 1,
          question_value: '',
          input_type_value: '',
          questionError: false,
          typeError: false,
          errorMessage: 'Fill this label',
        },
      ],
    }));
  }

  function removeQuestion(idx) {
    setData((prev) => ({
      ...prev,
      questionList: prev.questionList.filter((_, index) => index !== idx),
    }));
  }

  function submitAddItem() {
    setLoading(true);
    const formData = new FormData();
    const tempData = { ...data };
    let errors = false;

    Object.entries(tempData).forEach(([optionName, option]) => {
      if (optionName === 'Component') {
        return;
      }

      if (optionName === 'document') {
        if (option.value.length) {
          option.value.forEach((j, idx) => {
            formData.append(`document_${idx + 1}`, j);
          });
        } else if (data?.item_type?.value !== 'regular') {
          tempData[optionName].error = true;
          errors = true;
        }
      } else if (option.value) {
        formData.append(optionName, option.value);
      } else if (Array.isArray(option)) {
        option.forEach((j, idx) => {
          if (!j.question_value) {
            tempData[optionName][idx].questionError = true;
            errors = true;
          }
          if (!j.input_type_value) {
            tempData[optionName][idx].typeError = true;
            errors = true;
          }

          formData.append(`question_${j.id}`, j.question_value);
          formData.append(`question_type_${j.id}`, j.input_type_value);
        });
      } else {
        tempData[optionName].error = true;
        errors = true;
      }
    });

    if (errors) {
      setData(tempData);
      setLoading(false);
      return;
    }

    formData.append('dd_center_id', dd.dd_center_id);

    API()
      .post('/LAR/dd_center/item', formData)
      .then(() => {
        updateDiligenceList();
        setType(null);
      })
      .finally(() => setLoading(false));
  }

  function removeDocument(idx) {
    setData((state) => ({
      ...state,
      document: {
        ...state.document,
        value: state.document.value.filter((_, index) => idx !== index),
      },
    }));
  }

  return (
    <Modal
      centered
      className="diligence-add-item"
      footer={null}
      onCancel={() => setType(null)}
      title="Add item"
      open={Boolean(data)}
      width="565px"
    >
      <SelectWrapper {...data?.item_type} className="bordered" onChange={(t) => setType(t)} size="large" />
      <CustomTextInput {...data?.item_name} onChange={({ target }) => changeAddItemField('item_name', target.value)} />
      <CustomTextInput
        {...data?.item_description}
        onChange={({ target }) => changeAddItemField('item_description', target.value)}
      />
      {data && (
        <data.Component
          activeDocument={data}
          addQuestion={addQuestion}
          changeAddItemQuestion={changeAddItemQuestion}
          onChange={changeAddItemField}
          removeDocument={removeDocument}
          removeQuestion={removeQuestion}
        />
      )}
      <div className="actions">
        <CustomButton text="Close" onClick={() => setType(null)} />
        <Button size="large" className="btn_darkblue" onClick={submitAddItem} loading={loading}>
          Save
        </Button>
      </div>
    </Modal>
  );
}

export default AddItemPopup;
