export const GET_CONGRATULATIONS_LOADING = 'GET_CONGRATULATIONS_LOADING';
export const GET_CONGRATULATIONS_SUCCESS = 'GET_CONGRATULATIONS__SUCCESS';
export const GET_CONGRATULATIONS_ERROR = 'GET_CONGRATULATIONS__ERROR';

export const GET_ACTIVITY_LOG_LOADING = 'GET_ACTIVITY_LOG_LOADING';
export const GET_ACTIVITY_LOG_SUCCESS = 'GET_ACTIVITY_LOG_SUCCESS';
export const GET_ACTIVITY_LOG_ERROR = 'GET_ACTIVITY_LOG_ERROR';

export const GET_ACTIVITY_LOG_FILTER_LOADING = 'GET_ACTIVITY_LOG_FILTER_LOADING';
export const GET_ACTIVITY_LOG_FILTER_SUCCESS = 'GET_ACTIVITY_LOG_FILTER_SUCCESS';
export const GET_ACTIVITY_LOG_FILTER_ERROR = 'GET_ACTIVITY_LOG_FILTER_ERROR';

export const GET_CONVERSATION_LOG_LOADING = 'GET_CONVERSATION_LOG_LOADING';
export const GET_CONVERSATION_LOG_SUCCESS = 'GET_CONVERSATION_LOG_SUCCESS';
export const GET_CONVERSATION_LOG_ERROR = 'GET_CONVERSATION_LOG_ERROR';

export const GET_CONVERSATION_LOG_FILTER_LOADING = 'GET_CONVERSATION_LOG_FILTER_LOADING';
export const GET_CONVERSATION_LOG_FILTER_SUCCESS = 'GET_CONVERSATION_LOG_FILTER_SUCCESS';
export const GET_CONVERSATION_LOG_FILTER_ERROR = 'GET_CONVERSATION_LOG_FILTER_ERROR';

export const UPDATE_PUBLIC_MESSAGES = 'UPDATE_PUBLIC_MESSAGES';
export const GET_RATES_TRACKER = 'GET_RATES_TRACKER';
