import { useEffect, useState } from 'react';
import { getLARFields } from './stringReplaceData';

export const useMergeFields = (string, context, customMergeFields) => {
  const [str, setStr] = useState(string);

  useEffect(() => {
    if (!str) {
      return;
    }

    const fields = getLARFields(context, customMergeFields);

    fields.forEach((el) => {
      setStr((prev) => prev.replaceAll(`\${${el.field}}`, `<b>${el.description}</b>`));
    });
  });

  return [str, setStr];
};
