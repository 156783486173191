import { useContext, useEffect } from 'react';
import { Checkbox, DatePicker, Form, InputNumber, Select, Slider } from 'antd';
import moment from 'moment';
import LarAppContext from '../../../context/LarAppContext';

const getMarks = (num) => {
  const data = {};
  for (let i = num; i >= 50; i -= 10) {
    data[i] = `${i}`;
  }
  return data;
};

const marks = {
  50: '50',
  60: '60',
  70: '70',
  80: '80',
};

const getLTVLong = (requestingAmount, propertyValue, maxLtv = 80) => {
  const LTV = Number(((requestingAmount / propertyValue) * 100).toFixed(2));
  const isBadLTV = LTV > maxLtv;
  return {
    LTV,
    isBadLTV,
  };
};

function ProjectFormPurchaseLong({ form, onFieldsChange }) {
  const { use, loan_style_name, closing_date, STR_prior_to_purchase, property_value, requesting_amount, SA_values } =
    useContext(LarAppContext);
  const propertyValue = Form.useWatch('property_value', form);
  const requestingAmount = Form.useWatch('requesting_amount', form);
  const { LTV, isBadLTV } = getLTVLong(requestingAmount, propertyValue, SA_values?.long_purchase_LTV);

  useEffect(() => {
    form.setFieldsValue({
      loan_style_name,
      closing_date: closing_date ? moment(closing_date) : moment(new Date(), 'YYYY-DD-MM').add(12, 'day'),
      STR_prior_to_purchase,
      property_value,
      requesting_amount,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {use === 'Consumer' ? null : (
        <Form.Item
          label="Investment style"
          name="loan_style_name"
          className="scenario-required-field auction-required-field"
        >
          <Select style={{ width: 215 }}>
            <Select.Option value="Long Term Rental">Long Term Rental</Select.Option>
            <Select.Option value="Short Term Rental (AirBnB)">Short Term Rental (AirBnB)</Select.Option>
          </Select>
        </Form.Item>
      )}
      <Form.Item
        label="Estimated closing date"
        name="closing_date"
        initialValue={moment(new Date(), 'YYYY-DD-MM').add(11, 'day')}
        className="auction-required-field"
      >
        <DatePicker
          style={{ width: 215 }}
          disabledDate={(currentDate) => currentDate.isBefore(moment(new Date(), 'YYYY-DD-MM').add(11, 'day'))}
        />
      </Form.Item>
      <Form.Item
        label="This property has been operating as an STR prior to our purchase"
        name="STR_prior_to_purchase"
        valuePropName="checked"
        initialValue={false}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        label="Purchase price"
        name="property_value"
        className="auction-required-field scenario-required-field"
      >
        <InputNumber
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          style={{ width: 215 }}
          addonBefore="$"
        />
      </Form.Item>
      <Form.Item
        label="Target loan amount"
        name="requesting_amount"
        className="auction-required-field scenario-required-field"
      >
        <InputNumber
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          style={{ width: 215 }}
          addonBefore="$"
        />
      </Form.Item>
      <Form.Item label="Target LTV" className="auction-required-field scenario-required-field">
        <Form.Item
          style={{
            display: 'inline-block',
            marginBottom: 0,
          }}
        >
          <InputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(e) => {
              form.setFieldsValue({
                requesting_amount: Math.round((e * propertyValue) / 100),
                // eslint-disable-next-line
                property_value: new Number(propertyValue),
              });
              const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
                name: el[0],
                value: el[1],
              }));
              onFieldsChange([1], valuesArr);
            }}
            value={LTV}
            max={SA_values?.long_purchase_LTV ?? 80}
            style={{ width: 100 }}
            addonAfter="%"
          />
        </Form.Item>
        <Form.Item
          style={{
            display: 'inline-block',
            marginBottom: 0,
            marginLeft: 30,
          }}
        >
          <Slider
            onChange={(e) => {
              form.setFieldsValue({
                requesting_amount: Math.round((e * propertyValue) / 100),
                // eslint-disable-next-line
                property_value: new Number(propertyValue),
              });
              const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
                name: el[0],
                value: el[1],
              }));
              onFieldsChange([1], valuesArr);
            }}
            value={LTV}
            style={{ width: 220 }}
            min={50}
            max={SA_values?.long_purchase_LTV ?? 80}
            marks={SA_values?.long_purchase_LTV ? getMarks(SA_values?.long_purchase_LTV) : marks}
            step={1}
          />
        </Form.Item>
      </Form.Item>
      <div style={{ marginBottom: 0, color: isBadLTV ? 'red' : '#8C8C8C' }}>
        LTV = Loan Amount / Purchase Price. LTV must be less than {SA_values?.long_purchase_LTV ?? 80}%
      </div>
    </>
  );
}

export default ProjectFormPurchaseLong;
