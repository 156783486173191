import LenderDashboardWidgets from './LenderDashboardWidgets';
import LenderTable from './LenderTable';

import styles from '../BrokerDashboard/BrokerDashboard.module.css';

export default function LenderDashboard() {
  return (
    <div className={styles.content}>
      <LenderDashboardWidgets />
      <LenderTable />
    </div>
  );
}
