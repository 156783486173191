import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './styles/FeesWorksheetCollapse.module.css';

export default function FeesWorksheetCollapse({ additionalHeaderInfo, children, title, isSubItem, collapseAll }) {
  const [collapsed, toggleCollapsed] = useState(false);

  const collapsedHandler = () => {
    toggleCollapsed((prev) => !prev);
  };

  useEffect(() => {
    const collapsedAllHandler = () => {
      toggleCollapsed(collapseAll);
    };

    collapsedAllHandler();
  }, [collapseAll]);

  return (
    <div className={classNames(styles.root)}>
      <div className={classNames(styles.header, isSubItem && styles.subItemHeader)} onClick={collapsedHandler}>
        <div className={styles.title}>
          <div className={styles.iconWrapper}>
            {collapsed ? <PlusOutlined className={styles.icon} /> : <MinusOutlined className={styles.icon} />}
          </div>
          {title}
        </div>
        <div className={classNames(styles.additionalHeaderInfo, collapsed && styles.additionalInfoOfCollapsed)}>
          {additionalHeaderInfo}
        </div>
      </div>
      <div className={classNames(collapsed && styles.collapsed)}>{children}</div>
    </div>
  );
}
