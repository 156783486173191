import { acceptedMimes } from 'constants/mimes';
import { Checkbox, Radio, Space, Switch } from 'antd';
import { FileUploadIcon } from 'assets/icons/icons';
import { useRef, useState } from 'react';
import AutoCompleteTextInput from 'components/UI/AutoCompleteTextInput';
import Button from 'components/UI/Button';
import DropdownMenu from 'components/CustomComponents/DropdownMenu';
import ImageCropModal from 'components/ImageCropModal';
import PhoneInput from 'components/CustomComponents/PhoneInput';
import regExp from 'constants/regExp';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import SelectMultiple from 'components/CustomComponents/SelectMultiple';
import TextInput from 'components/CustomComponents/TextInput';
import classes from 'components/RenderInputs/RenderInputs.module.css';

function getNestedValueByRoute(value, route) {
  if (route) {
    const routeArr = route.split('.');
    const nextKey = routeArr[0];

    return getNestedValueByRoute(value[nextKey], routeArr.slice(1).join('.'));
  }

  return value;
}

function getDisabled(fieldDisabled, data) {
  if (typeof fieldDisabled === 'function') {
    fieldDisabled = fieldDisabled();
  }

  if (typeof fieldDisabled === 'boolean') {
    return fieldDisabled;
  }
  if (fieldDisabled) {
    const shouldDisableValue = getNestedValueByRoute(data, fieldDisabled.route);

    if (fieldDisabled.operator) {
      return shouldDisableValue === fieldDisabled.value;
    }
    return shouldDisableValue !== fieldDisabled.value;
  }
}

export default function RenderInputs({
  autocompleteAddressFields,
  data,
  edit,
  hideErrors,
  onChange,
  onTagDelete,
  onUpload,
  selectTag,
}) {
  const [logoCropModal, setlogoCropModal] = useState({ visible: false, file: null });
  const uploadRef = useRef();

  function renderInput([route, field]) {
    const disabled = getDisabled(field.disabled, data);

    return (
      <div style={field.style} className="input-row" key={route}>
        <TextInput
          title={field.title}
          onChange={(i) => {
            const func = (i) => (field.regex ? regexOnChange(field.regex, i, route) : onChange(i, route));
            if (field.valuePrefix) {
              const { value } = i.target;
              func({ target: { value: value.split(field.valuePrefix)[0] } });
            } else {
              func(i);
            }
          }}
          maxLength={field.maxLength ?? 255}
          disabled={disabled || !edit}
          htmlFor={route}
          wrapperStyle={field.wrapperStyle}
          value={(field.value ?? '') + (field.valuePrefix ?? '')}
          type={field.type}
          error={field.error}
          errorMessage={field.errorMessage}
        />
      </div>
    );
  }

  function renderAutocomplete([route, field]) {
    const disabled = getDisabled(field.disabled, data);

    return (
      <div style={field.style} className="input-row" key={route}>
        <AutoCompleteTextInput
          title={field.title}
          classNameWrapper="street"
          error={field.error}
          wrapperStyle={field.wrapperStyle}
          disabled={disabled || !edit}
          errorMessage={field.errorMessage}
          onChange={(e) => onChange(e, route)}
          setSelectedAddress={autocompleteAddressFields}
          value={field.value}
        />
      </div>
    );
  }

  function renderPhone([route, field]) {
    return (
      <div style={field.style} className="input-row" key={route}>
        <PhoneInput
          disabled={getDisabled(field.disabled, data) || !edit}
          error={field.error}
          errorMessage={field.errorMessage}
          onChange={(e) => onChange(e, route)}
          title={field.title}
          value={field.value}
        />
      </div>
    );
  }

  function renderFieldError([route, { error, title }]) {
    return error ? (
      <li key={route}>
        Please fill
        {title}
      </li>
    ) : null;
  }

  function selectTags([route, field]) {
    const { error, title, tags, errorMessage, style, options } = field;

    return (
      <SelectMultiple
        disabled={!edit}
        error={error}
        errorMessage={errorMessage}
        key={route}
        name={route}
        onChange={selectTag}
        onDelete={onTagDelete}
        options={options}
        title={title}
        value={tags}
        wrapperStyle={style}
      />
    );
  }

  function selectErrors([route, { error, title }]) {
    return error ? (
      <li key={route}>
        Please fill
        {title}
      </li>
    ) : null;
  }

  function renderSelect(item, route) {
    const field = item[1];

    return (
      <DropdownMenu
        className="bordered"
        disabled={!edit}
        error={field.error}
        errorMessage={field.errorMessage}
        key={route}
        onSelect={({ value }) => onChange({ target: { value } }, route ?? item[0])}
        options={field.options}
        search
        title={field.title}
        updateOnRerender
        value={field.value}
        vectorIcon
      />
    );
  }

  function renderDividedInputs([dividedRoute, divided]) {
    return (
      <div
        style={divided.style ?? {}}
        className={`divided-inputs ${divided.mobileLayout ? 'mobile-layout' : ''}`}
        key={dividedRoute}
      >
        {Object.entries(divided.inputs).map((i, index) => {
          const field = i[1];
          const route = `${dividedRoute}.inputs.${i[0]}`;

          if (i[1].type === 'select') {
            return renderSelect(i, route);
          }
          return (
            <TextInput
              title={field.title}
              key={index}
              onChange={(i) => (field.regex ? regexOnChange(field.regex, i, route) : onChange(i, route))}
              maxlength={255}
              disabled={!edit}
              htmlFor={i[0]}
              type={field.type}
              maxLength={field.maxLength ?? null}
              value={field.value}
              error={field.error}
              errorMessage={field.errorMessage}
            />
          );
        })}
      </div>
    );
  }

  function renderDividedErrors([, divided]) {
    return Object.entries(divided.inputs).map(([route, field]) => {
      if (field.error) {
        return (
          <li key={route}>
            Please fill
            {field.title}
          </li>
        );
      }

      return null;
    });
  }

  const regexOnChange = (regex, value, route) => {
    const val = value.target.value;
    if (regex === 'one_decimal') {
      if (regExp.one_number_one_decimal.test(val) || val === '') {
        onChange(value, route);
      }
    }
    if (regex === 'fee') {
      if (regExp.two_number_one_decimal.test(val) || val === '') {
        onChange(value, route);
      }
    } else {
      const reg = new RegExp(regex);
      if (reg.test(val)) {
        onChange(value, route);
      }
    }
  };

  function renderSideTitle([route, title]) {
    return (
      <div className={`side-title-wrapper ${title.bordered ? 'bordered' : ''}`} key={route} style={title.style}>
        <SectionTitle
          style={title.titleStyle}
          title={title.title}
          type="h6"
          wrapperStyle={{ top: title.borderTitleDistance ?? '12px' }}
        />
      </div>
    );
  }

  function renderCheckbox([checkboxesRoute, checkboxes]) {
    return (
      <div
        className={`checkboxes-wrapper ${checkboxes.bordered ? 'bordered' : ''}`}
        key={checkboxesRoute}
        style={checkboxes.style}
      >
        {checkboxes.title && (
          <SectionTitle title={checkboxes.title.value} style={checkboxes.title.style} type={checkboxes.title.type} />
        )}
        <div className="checkboxes">
          {Object.entries(checkboxes.boxes).map((i, index) => {
            const field = i[1];
            const route = `${checkboxesRoute}.boxes.${i[0]}`;

            return (
              <Checkbox
                checked={field.value}
                style={field.style}
                key={index}
                disabled={!edit}
                onChange={(e) => onChange({ target: { value: e.target.checked } }, route)}
              >
                {field.titleType ? (
                  <SectionTitle title={field.title} style={field.style} type={field.titleType} />
                ) : (
                  field.title
                )}
              </Checkbox>
            );
          })}
        </div>
      </div>
    );
  }

  function renderTitle([route, item]) {
    return (
      <SectionTitle
        key={route}
        style={item.style}
        title={item.title}
        type={item.type}
        wrapperStyle={item.wrapperStyle}
      />
    );
  }

  function renderSwitch([wrapRoute, switchWrap]) {
    return (
      <div style={switchWrap.style} className="switches-wrapper" key={wrapRoute}>
        {Object.entries(switchWrap.switches).map((i, index) => {
          const field = i[1];
          const route = `${wrapRoute}.switches.${i[0]}`;

          return (
            <div className="switch" key={index}>
              <Switch
                disabled={!edit}
                checked={field.value}
                onChange={(e) => onChange({ target: { value: e } }, route)}
              />
              <div className="switch-text">
                <span className="switch-title">{field.title}</span>
                <span className="switch-subtitle">{field.subtitle}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  function renderUploadLogo([route, data]) {
    function handleSelectPic() {
      setlogoCropModal({ visible: true, file: uploadRef.current.files[0] });
    }

    function handleCroppedPhoto(blob) {
      setlogoCropModal((prev) => ({ ...prev, visible: false }));

      onUpload(
        {
          preventDefault: () => null,
          target: { files: [blob] },
        },
        route,
      );
    }

    function resetFileInput() {
      uploadRef.current.value = null;
    }

    return (
      <div className="logo-wrapper" key={route}>
        <label htmlFor={route} className="input_title">
          {data.title}
        </label>
        <div className="logo-row">
          {data.value ? <img className={classes.companyLogo} src={data.value} alt="" /> : <div />}
          <Button
            disabled={!edit}
            onClick={() => uploadRef.current.click()}
            primary
            style={{ padding: '8px 16px' }}
            text={
              <div className="upload-logo">
                <FileUploadIcon style={{ color: '#fff' }} />
                <span>{data.buttonText}</span>
              </div>
            }
          />
          <input
            accept={acceptedMimes.avatar.join()}
            hidden
            name={route}
            onChange={handleSelectPic}
            onClick={resetFileInput}
            ref={uploadRef}
            type="file"
          />
        </div>
        <ImageCropModal
          file={logoCropModal.file}
          format="logo"
          onCancel={() => setlogoCropModal((prev) => ({ ...prev, visible: false }))}
          onOk={handleCroppedPhoto}
          visible={logoCropModal.visible}
        />
      </div>
    );
  }

  function renderRadioButtons([route, radio]) {
    return (
      <Radio.Group key={route} onChange={(i) => onChange(i, route)} value={radio.value}>
        <Space size={radio.gap} direction="vertical">
          {Object.entries(radio.buttons).map((i, index) => {
            const field = i[1];

            return (
              <Radio disabled={!edit} key={index} value={field.value}>
                {field.name}
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
    );
  }

  return (
    <div className="render-inputs-wrapper">
      {hideErrors || (
        <ul className="errors-wrapper">
          {Object.entries(data).map((i) => {
            if (i[0].includes('dividedInputs')) {
              return renderDividedErrors(i);
            }
            if (
              i[0].includes('sectionTitle') ||
              i[0].includes('radioButtons') ||
              i[0].includes('switchButtons') ||
              i[0].includes('sideTitle') ||
              i[0].includes('upload') ||
              i[0].includes('checkbox')
            ) {
              return null;
            }
            if (i[0].includes('selectTags')) {
              return selectErrors(i);
            }
            return renderFieldError(i);
          })}
        </ul>
      )}
      {Object.entries(data)
        .filter((i) => !i[1].ignoreRender)
        .map((i) => {
          if (i[0] === 'checkbox_useCompanyAddress' && !edit) {
            return '';
          }

          if (i[0].includes('dividedInputs')) {
            return renderDividedInputs(i);
          }
          if (i[0].includes('sectionTitle')) {
            return renderTitle(i);
          }
          if (i[0].includes('radioButtons')) {
            return renderRadioButtons(i);
          }
          if (i[0].includes('switchButtons')) {
            return renderSwitch(i);
          }
          if (i[0].includes('selectTags')) {
            return selectTags(i);
          }
          if (i[0].includes('sideTitle')) {
            return renderSideTitle(i);
          }
          if (i[0].includes('upload')) {
            return renderUploadLogo(i);
          }
          if (i[0].includes('phone')) {
            return renderPhone(i);
          }
          if (i[0].includes('checkbox')) {
            return renderCheckbox(i);
          }
          if (i[0].includes('street_address') || i[0].includes('street')) {
            return renderAutocomplete(i);
          }
          return renderInput(i);
        })}
    </div>
  );
}
