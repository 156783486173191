import { Col, Row, Card, Divider, Typography, Space } from 'antd';

import MapContainer from '../../../../../components/GoogleApiWrapper/MapWidget';

export default function LoanProfilePropertyDetails({ data = {} }) {
  const list = [
    { title: 'Property Type', value: data.property_type },
    { title: 'Property Description', value: data.property_description },
    { title: 'Number of Units/Keys/Rooms', value: data.number_units },
    { title: 'Current Occupancy', value: data.current_occupancy },
    { title: 'Owner Occupied', value: data.owner_occupied ? 'Yes' : 'No' },
    { title: 'Rentable or Saleable Area (Sq.Ft)', value: data.rentable_or_saleable_area_sq_ft },
    { title: 'Total Property Size (Sq.Ft)', value: data.total_property_size_sq_ft },
  ];

  const list2 = [
    { title: 'The Location is Rural', value: data.location_is_rural ? 'Yes' : 'No' },
    { title: 'Property Address: ', value: data.property_address },
    { title: 'Property Address 2: ', value: data.property_address2 },
  ];

  return (
    <Card>
      <Typography.Title level={4}>Property Details</Typography.Title>
      <Divider style={{ margin: '0 0 10px 0' }} />
      <Row gutter={[40, 8]}>
        <Col span={12}>
          <div style={{ position: 'relative', minHeight: '260px' }}>
            <MapContainer
              geocoder
              location={{
                city: data.property_city,
                state: data.property_state,
                zip: data.property_postal_code,
                address: data.property_address,
              }}
            />
          </div>
        </Col>
        <Col span={12}>
          <Row gutter={[8, 8]}>
            {list
              .filter((i) => i.value)
              .map((item, index) => (
                <Col span={24} key={index}>
                  <Row justify="space-between">
                    <Col>
                      <Typography.Text type="secondary">{item.title}</Typography.Text>
                    </Col>
                    <Col>{item.value}</Col>
                  </Row>
                </Col>
              ))}
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[8, 8]}>
            {list2
              .filter((i) => i.value)
              .map((item, index) => (
                <Col span={24} key={index}>
                  <Row justify="space-between" key={index}>
                    <Col>
                      <Typography.Text type="secondary">{item.title}</Typography.Text>
                    </Col>
                    <Col>{item.value}</Col>
                  </Row>
                </Col>
              ))}
            <Col span={24}>
              <Row justify="space-between" gutter={[16, 16]}>
                <Col span={8}>
                  <Space direction="vertical">
                    <Typography.Text type="secondary">Property City:</Typography.Text>
                    <div>{data.property_city !== 'unknown' ? data.property_city : data.property_state}</div>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space direction="vertical">
                    <Typography.Text type="secondary">Property State:</Typography.Text>
                    <div>{data.property_state}</div>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space direction="vertical">
                    <Typography.Text type="secondary">Property Postal Code:</Typography.Text>
                    <div>{data.property_postal_code}</div>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
