const formRules = {
  email: {
    message: 'Please enter a valid email address',
    type: 'email',
  },
  phone: {
    message: 'Phone number must be 10 numbers',
    pattern: /^[0-9]{10}$/,
    len: 10,
    transform: (v) => v.toString(),
    type: 'string',
  },
  brokerFee: {
    max: 100,
    message: 'Please enter a valid percentage',
    min: 0,
    transform: (v) => parseFloat(v),
    type: 'number',
  },
};

export default formRules;
