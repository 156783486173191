export const faq = [
  {
    title: 'Are you available to discuss the quotes with me?',
    description: 'Of course, contact me anytime.',
  },
  {
    title: 'Is this quote the final figures or can it change? ',
    description:
      'This is a soft quote which means it is subject to change. The lenders based this quote on the limited information you provided. In the next phase, called Due Diligence, the lender will request more detailed information to confirm the information provided already and/or gather what was missing. This is a normal part of every loan process. After their review, they will either confirm this quote or they may revise it based on the information you provided. You will have the opportunity to confirm any changes to the terms. Nothing happens without your review and approval.',
  },
  {
    title: 'How quickly can I close the loan and receive funding?',
    description:
      'That depends on how quickly you are able to provide the information requested by the lender. The sooner they have the information, the sooner they can review, confirm, and proceed to closing and settlement. Unless otherwise stated in the loan quote, you should expect between 30-45 days on average from this point to closing. We use this technology portal to simplify and accelerate the loan process, but there is no magic. Lender’s need to gather and review additional information.',
  },
];
