import { memo } from 'react';
import GenericIcon from './GenericIcon';
import colours from './colours';

export default memo(({ colour, ...rest }) => (
  <GenericIcon colour={colour} {...rest}>
    <path d="M22 12C19.333 16.667 16 19 12 19C8 19 4.667 16.667 2 12C4.667 7.333 8 5 12 5C16 5 19.333 7.333 22 12Z" />
    <path
      d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
      fill={colours.get(colour) || colour}
    />
  </GenericIcon>
));
