import { Form } from 'antd';
import { useEffect, useState } from 'react';
import formSettings from '../../helpers/formSettings';
import { useFieldsChangeNew } from '../../helpers/larHelpers';
import PropertyIncomeExpenseMain from './PropertyIncomeExpenseMain/PropertyIncomeExpenseMain';

function PropertyIncomeExpenseForm(props) {
  const formName = 'property_income_and_expense';
  const { setSuccess, setSuccessLA, setProgress, setLAProgress, form } = props;
  const [requiredFields, setRequiredFields] = useState([]);

  const [total, setTotal] = useState(0);
  const [exp, setExp] = useState(0);
  const getSuccessStatus = (fieldsArr) => {
    const valuesArr = fieldsArr
      .filter((el) => requiredFields.includes(el.name.join('_')))
      .map((fieldObj) => fieldObj.value);
    const total = fieldsArr
      .filter((el) => el.name.includes('expenses') || el.name.includes('income'))
      .reduce((previousValue, currentValue) => {
        if (!currentValue.value) {
          return previousValue;
        }
        if (currentValue.name.includes('expenses')) {
          return previousValue - currentValue.value;
        }
        if (currentValue.name.includes('income')) {
          return previousValue + currentValue.value;
        }
        return 0;
      }, 0);
    const currExp = fieldsArr
      .filter((el) => el.name.includes('expenses'))
      .reduce((previousValue, currentValue) => {
        if (!currentValue.value) {
          return previousValue;
        }
        if (currentValue.name.includes('expenses')) {
          return previousValue + currentValue.value;
        }
        return 0;
      }, 0);
    setExp(currExp);
    setTotal(total);
    return (
      !valuesArr.includes(undefined) && !valuesArr.includes(null) && !valuesArr.includes('') && !valuesArr.includes(0)
    );
  };

  const setSuccessStatus = (status = false) => {
    setSuccess(status);
    setProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const setSuccessStatusLA = (status = false) => {
    setSuccessLA(status);
    setLAProgress((prevState) => ({
      ...prevState,
      [formName]: status,
    }));
  };

  const onSuccess = () => {
    form.submit();
  };

  const onReject = () => {};

  const onFieldsChange = useFieldsChangeNew(
    getSuccessStatus,
    setSuccessStatus,
    onSuccess,
    onReject,
    getSuccessStatus,
    setSuccessStatusLA,
  );

  // TODO: TEST SAVE DATA
  useEffect(() => {
    const valuesArr = Object.entries(form.getFieldsValue()).map((el) => ({
      name: [el[0], 'value', el[1].type],
      value: el[1].value[el[1].type],
    }));
    onFieldsChange([1], valuesArr);
    // eslint-disable-next-line
  }, []);

  return (
    <Form {...formSettings} form={form} name={formName} onFieldsChange={onFieldsChange}>
      <PropertyIncomeExpenseMain
        onFieldsChange={onFieldsChange}
        setRequiredFields={setRequiredFields}
        total={total}
        exp={exp}
        form={form}
      />
    </Form>
  );
}
export default PropertyIncomeExpenseForm;
