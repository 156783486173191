import 'components/OnBoarding/styles/list.css';
import 'components/UniversalTable/universal_table.css';
import { Table } from 'antd';
import PaginationItemRender from 'constants/PaginationItemRender';

export default function UniversalTable({
  className = '',
  columns = [],
  dataSource = [],
  defaultPadding = false,
  expandable = {},
  headerContent = '',
  loading = false,
  onRow,
  pagination = { current: 1, pageSize: 10 },
  rowKey,
  rowSelection = null,
  scroll = {},
  setPagination = () => null,
  sidebarContent = '',
  size = 'middle',
  withHeader = true,
  withPagination = true,
  withSidebar = false,
  withTabMenu = false,
}) {
  const header = (
    <div className={`universal-table__header ${withTabMenu ? 'universal-table__header_with-tab-menu' : ''}`}>
      {headerContent}
    </div>
  );

  const sidebar = <div className="universal-table__sidebar">{sidebarContent}</div>;

  return (
    <div className="useless-universal-table-wrapper">
      <div
        className={`universal-table
                ${defaultPadding ? 'universal-table_default-padding' : ''}
                ${withHeader ? 'universal-table_with-header' : ''}
                ${withSidebar ? 'universal-table_with-sidebar' : ''}
                ${withPagination ? 'universal-table_with-pagination' : ''}
                ${className}`}
      >
        {withSidebar ? sidebar : null}
        <div className="universal-table__table-inner">
          {withHeader ? header : null}
          <div>
            <Table
              onRow={onRow}
              size={size}
              loading={loading}
              rowSelection={rowSelection}
              className="universal-table__table"
              header={<>header</>}
              onChange={setPagination}
              expandable={expandable}
              dataSource={dataSource}
              scroll={scroll}
              rowKey={rowKey}
              columns={columns}
              pagination={
                withPagination
                  ? {
                      showSizeChanger: true,
                      size: 'middle',
                      defaultCurrent: pagination.current,
                      pageSize: pagination.pageSize,
                      total: pagination.total ?? null,
                      itemRender: PaginationItemRender,
                    }
                  : false
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
