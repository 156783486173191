import { Drawer, Input } from 'antd';
import arrowLeft from 'assets/images/arrow-left.svg';
import CustomButton from 'components/UI/Button';
import SectionTitle from 'components/OnBoarding/SectionTitle';

export default function DiligenceNotesDrawer({ data, setDrawerData, submit, visible }) {
  function closeDrawer() {
    setDrawerData((state) => ({ ...state, visible: null }));
  }

  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      closeIcon={false}
      headerStyle={{ display: 'none' }}
      onClose={closeDrawer}
      placement="right"
      visible={visible}
      width={450}
    >
      <div className="drawer-wrapper notes create-note">
        <div className="drawer-header">
          <img onClick={closeDrawer} src={arrowLeft} alt="Close" />
          <strong>
            <SectionTitle type="body-14" title={`${data?.edit ? 'Edit' : 'Leave'} a comment`} />
          </strong>
          <SectionTitle className="lar-identifier long" type="body-14" wrapperStyle={{ marginLeft: -2 }} />
        </div>
        <div className="create-note-body">
          <form>
            <Input.TextArea
              placeholder="Start typing your note here"
              rows={15}
              value={data.text}
              onChange={(e) => {
                setDrawerData((prev) => ({
                  ...prev,
                  data: {
                    ...prev.data,
                    text: e.target.value,
                  },
                }));
              }}
            />
          </form>
        </div>
        <div className="create-note-footer">
          <div className="buttons">
            <CustomButton onClick={() => setDrawerData({ visible: null, data: { text: '' } })} text="Cancel" />
            <CustomButton
              onClick={() => submit(data.text, data.edit ? data.messageId : undefined)}
              primary
              text="Save Comment"
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
}
