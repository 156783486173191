import { useRef } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%',
  borderRadius: '0',
};

function AddressMap(props) {
  const markerRef = useRef();
  const { coordinates } = props;

  if (!coordinates) {
    return null;
  }

  return (
    <Map
      google={props.google}
      zoom={14}
      tilt={0}
      mapTypeControl={false}
      streetViewControl={false}
      zoomControl
      mapType="roadmap"
      rotateControl={false}
      center={coordinates}
      style={mapStyles}
      initialCenter={coordinates || {}}
    >
      <Marker ref={markerRef} position={coordinates} />
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAPS_API_KEY,
})(AddressMap);
