import API from '../API';

export default class BrokerLandingAPI {
  async getCompanyLanding() {
    return API()
      .get('/LAR/landing/broker/broker-custom-landing')
      .then((res) => res.data);
  }

  async getCompanyDomains() {
    return API()
      .get('/LAR/landing/broker/company-domains')
      .then((res) => res.data);
  }
}
