import React from 'react';
import { Select, Input } from 'antd';
import classes from './FinishedAuctionsFilter.module.css';

function FinishedAuctionsFilter({ handleChange, onSearch, options }) {
  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const processChange = debounce((event) => onSearch(event.target.value));

  return (
    <div className={classes.filterWrapper}>
      <Select defaultValue="Sort by" className={classes.select} onChange={handleChange} options={options} />
      <Input.Search className={classes.search} placeholder="ID, Style, Amount" onChange={processChange} enterButton />
    </div>
  );
}

export default FinishedAuctionsFilter;
