import { Dropdown, Menu } from 'antd';
import { HorizontalDotsIcon } from 'assets/icons';
import styles from './styles.module.css';

export function apiUsageColumns(dropdownItems) {
  return [
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Company Admin',
      dataIndex: 'companyAdmin',
      key: 'companyAdmin',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'API Key',
      dataIndex: 'apiKey',
      key: 'apiKey',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: () => (
        <div className={styles.actionContainer}>
          <Dropdown
            overlay={<Menu className="custom-dropdown-menu custom-dropdown-menu_default" items={dropdownItems} />}
          >
            <button aria-label="openSettings" className={styles.buttonSettings}>
              <HorizontalDotsIcon style={{ color: '#8c8c8c' }} />
            </button>
          </Dropdown>
        </div>
      ),
    },
  ];
}
