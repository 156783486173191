const questionInputTypes = [
  {
    preview: 'Number',
    value: 'number',
  },
  {
    preview: 'Currency',
    value: 'currency',
  },
  {
    preview: 'Short text',
    value: 'short_text',
  },
  {
    preview: 'Long text',
    value: 'long_text',
  },
  {
    preview: 'Email',
    value: 'email',
  },
  {
    preview: 'Address',
    value: 'address',
  },
  {
    preview: 'State',
    value: 'state',
  },
  {
    preview: 'Zip',
    value: 'zip',
  },
  {
    preview: 'Phone',
    value: 'phone',
  },
];

export default questionInputTypes;
