import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo(({ direction, ...params }) => (
  <GenericIcon {...params} transform={`scale(${direction === 'right' ? 1 : -1},1)`}>
    <g clip-path="url(#clip0_7362_165096)">
      <path
        d="M9.75002 20.85C11.53 20.15 11.14 18.22 10.24 17C9.35002 15.75 8.12002 14.89 6.88002 14.06C6.00002 13.5 5.19002 12.8 4.54002 12C4.26002 11.67 3.69002 11.06 4.27002 10.94C4.86002 10.82 5.88002 11.4 6.40002 11.62C7.31002 12 8.21002 12.44 9.05002 12.96L10.06 11.26C8.50002 10.23 6.50002 9.32 4.64002 9.05C3.58002 8.89 2.46002 9.11 2.10002 10.26C1.78002 11.25 2.29002 12.25 2.87002 13.03C4.24002 14.86 6.37002 15.74 7.96002 17.32C8.30002 17.65 8.71002 18.04 8.91002 18.5C9.12002 18.94 9.07002 18.97 8.60002 18.97C7.36002 18.97 5.81002 18 4.80002 17.36L3.79002 19.06C5.32002 20 7.88002 21.47 9.75002 20.85ZM18.96 7.33L13.29 13H11V10.71L16.67 5.03L18.96 7.33ZM22.36 6.55C22.35 6.85 22.04 7.16 21.72 7.47L19.2 10L18.33 9.13L20.93 6.54L20.34 5.95L19.67 6.62L17.38 4.33L19.53 2.18C19.77 1.94 20.16 1.94 20.39 2.18L21.82 3.61C22.06 3.83 22.06 4.23 21.82 4.47C21.61 4.68 21.41 4.88 21.41 5.08C21.39 5.28 21.59 5.5 21.79 5.67C22.08 5.97 22.37 6.25 22.36 6.55Z"
        fill="#6C5CE7"
      />
    </g>
    <defs>
      <clipPath id="clip0_7362_165096">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </GenericIcon>
));
