import { acceptedMimes } from 'constants/mimes';
import { Badge, Button, Form, Input, Select, Spin, message, Row, Switch, Col, Space, Modal } from 'antd';
import { formatOutsideLink } from 'constants/formatOutsideLink';
import { formatPhone } from 'constants/formatPhone';
import { PencilIcon } from 'assets/icons';
import { useCallback, useEffect, useState, useReducer, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import AdminReassignModal from 'components/AdminReassignModal';
import AutoCompleteTextInput from 'components/UI/AutoCompleteTextInput';
import CustomAvatar from 'components/UI/Avatar';
import fiddyStates from 'constants/fiddyStates';
import ImageCropModal from 'components/ImageCropModal';
import { ReactComponent as ReloadIcon } from 'assets/icons/reload.svg';
import styles from './styles/CompanyInfo.module.css';

import LegacyAPI from 'api';

const companyTypes = new Map([
  [
    'lender',
    ['NonQM Lender', 'Commercial Lender', 'Alternative Lender', 'Insurance Company', 'Hard Money/Private Lender'],
  ],
  [
    'broker',
    [
      'Residential Mortgage Broker',
      'Commercial Mortgage Broker',
      'Agency Lender',
      'Correspondent Lender',
      'Credit Union',
      'Regional Bank',
      'Commercial Bank',
    ],
  ],
]);

function CompanyInfo({ API }) {
  const [data, setData] = useState({});
  const [editMode, toggleEditMode] = useReducer((state) => !state, false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [resetKeyVisible, setResetKeyVisible] = useState(false);
  const [logoCropModal, setLogoCropModal] = useState({ visible: false, file: null });
  const [pic, setPic] = useState({ file: null, thumb: null });
  const [reassignAdminVisible, toggleReassignAdminVisible] = useReducer((state) => !state, false);
  const role = useSelector((store) => store.user.userData?.role);
  const uploadRef = useRef(null);

  const requestData = useCallback(() => {
    setLoading(true);

    API.getCompanySettings().then((data) => {
      setData(data);
      setLoading(false);
    });
  }, [API]);
  useEffect(requestData, [requestData]);

  function submitForm({ city, name, state, type, website, zip }) {
    setLoading(true);

    API.updateCompanySettings(city, name, state, type, website, zip, pic.file)
      .then((data) => {
        message.success('Data was successfully saved!');
        setData(data);
        toggleEditMode();
      })
      .catch(() => {
        message.error("Couldn't update data!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (editMode) {
      return;
    }

    form.resetFields();
    setPic({ file: null, thumb: null });
  }, [editMode, form]);

  const companyTypeOptions = useMemo(
    () => companyTypes.get(/broker/i.test(role) ? 'broker' : 'lender').map((value) => ({ value })),
    [role],
  );

  function setAddress(city, state, zip) {
    form.setFieldsValue({ city, state, zip });
  }

  const isLender = /lender/i.test(role);

  function handleSelectPic() {
    setLogoCropModal({ visible: true, file: uploadRef.current.files[0] });
  }

  function handleCroppedPhoto(blob, dataURL) {
    setPic({
      file: blob,
      thumb: dataURL,
    });

    setLogoCropModal((prev) => ({ ...prev, visible: false }));
  }

  function resetFileInput() {
    uploadRef.current.value = null;
  }

  const [brokerApiData, setBrokerApiData] = useState(undefined);
  async function getBrokerApi() {
    try {
      const res = await LegacyAPI().get('/user_settings/company/broker/broker_api/enable');
      setBrokerApiData(res.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function brokerResetApiKey() {
    try {
      const res = await LegacyAPI().post('/user_settings/company/broker/broker_api/reset_key', { company_id: data.id });
      console.log(res, 'isResetted');
      setRender((prev) => prev + 1);
    } catch (e) {
      console.log(e);
    }
  }

  async function toggleBrokerApiKey() {
    try {
      await LegacyAPI().post('/user_settings/company/broker/broker_api/enable');
      setRender((prev) => prev + 1);

      if (!brokerApiData.token && !brokerApiData.enabled_by_broker_admin) {
        brokerResetApiKey();
      }
    } catch (e) {
      console.log(e);
    }
  }

  const [render, setRender] = useState(0);

  useEffect(() => {
    if (data.id) {
      getBrokerApi(data.id);
    }
  }, [data.id, render]);

  async function copyToClipBoard() {
    navigator.clipboard.writeText(brokerApiData?.token);
    await message.success('Copied To clipbaord', 0.5);
  }

  const toggleResetKey = () => setResetKeyVisible((prev) => !prev);
  const linkToDocumentation =
    'https://documenter.getpostman.com/view/9482371/2sA3BhdttR#a666d6d7-aaf8-4917-acfa-b8b93920bd16';

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Company Details</h1>
        {!editMode && / admin/i.test(role) && (
          <div className={styles.edit} onClick={toggleEditMode}>
            <p>Edit</p>
            <PencilIcon />
          </div>
        )}
      </div>
      <Spin spinning={loading}>
        <div className={styles.main}>
          {editMode ? (
            <Form
              form={form}
              initialValues={{ ...data, state: data.state?.code }}
              layout="vertical"
              onFinish={submitForm}
              className={styles.form}
            >
              <div>
                <Form.Item label="Company name" name="name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <div className={styles.avatarWrapper}>
                  <input
                    accept={acceptedMimes.avatar.join()}
                    hidden
                    onChange={handleSelectPic}
                    onClick={resetFileInput}
                    ref={uploadRef}
                    type="file"
                  />
                  <Badge count={<PencilIcon colour="white" />} onClick={() => uploadRef.current?.click()}>
                    <CustomAvatar size={80} companyLogoFormat src={pic.thumb || data.logo} />
                  </Badge>
                </div>
              </div>
              <div>
                <Form.Item
                  label={`${isLender ? 'Primary lender' : 'Company'} type`}
                  name="type"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={companyTypeOptions}
                    placeholder={`Select ${isLender ? 'primary lender' : 'company'} type`}
                  />
                </Form.Item>
                <Form.Item
                  className={styles.wideRow}
                  label="Company website"
                  name="website"
                  rules={[
                    {
                      transform: (uri) => (uri ? `https://${uri}` : uri),
                      type: 'url',
                    },
                  ]}
                >
                  <Input addonBefore="https://" placeholder="www.example.com" />
                </Form.Item>
              </div>
              <div>
                <h2>Location</h2>
                <Form.Item label="City" name="city">
                  <AutoCompleteTextInput
                    setSelectedAddress={(_, city, { value: state }, zip) => setAddress(city, state, zip)}
                  />
                </Form.Item>
                <Form.Item label="State" name="state">
                  <Select options={fiddyStates.map((value) => ({ value }))} />
                </Form.Item>
                <Form.Item
                  label="Zip"
                  name="zip"
                  rules={[
                    {
                      len: 5,
                      pattern: /^[0-9]+$/,
                      type: 'string',
                    },
                  ]}
                >
                  <Input maxLength={5} />
                </Form.Item>
              </div>
              <Button onClick={toggleEditMode} size="large">
                Cancel
              </Button>
              <Button onClick={() => form.submit()} size="large" type="dark">
                Save changes
              </Button>
            </Form>
          ) : (
            <>
              <div>
                <div className={styles.multiRow}>
                  <div>Company name:</div>
                  <div className={styles.companyName}>{data.name}</div>
                </div>
                <CustomAvatar className={styles.companyPic} src={data.logo} />
              </div>
              <div>
                <div className={styles.multiRow}>
                  <div>Company role</div>
                  <div>{isLender ? 'Fulfillment' : 'Originator'}</div>
                </div>
                <div className={styles.multiRow}>
                  <div>{isLender ? 'Primary lender type' : 'Company type'}</div>
                  <div>{data.type || 'Unknown'}</div>
                </div>
                <div className={`${styles.multiRow} ${styles.wideRow}`}>
                  <div>Company website:</div>
                  <div>
                    <a className={styles.link} href={formatOutsideLink(data.website)} rel="noreferrer" target="_blank">
                      {data.website}
                    </a>
                  </div>
                </div>
                <div className={`${styles.multiRow} ${styles.wideRow}`}>
                  <div>Company address</div>
                  <div>
                    {data.city} ●{data.state?.name} ●{data.zip}
                  </div>
                </div>
              </div>
            </>
          )}
          <div>
            <div className={`${styles.multiRow} ${styles.changeAdminForm}`}>
              <div>Company Admin:</div>
              <div>
                <CustomAvatar
                  className={styles.adminPic}
                  fullName={data.admin?.full_name}
                  src={data.admin?.profile_photo}
                />
                {data.admin?.full_name}
                {/* {/ admin/i.test(role) && <Button onClick={toggleReassignAdminVisible}>Change admin</Button>} */}
              </div>
            </div>
            <div className={styles.multiRow}>
              <div>Admin phone number</div>
              <div>{formatPhone(`+1${data.admin?.phone}`) || 'Unknown'}</div>
            </div>
            <div className={styles.multiRow}>
              <div>Admin email</div>
              <div>{data.admin?.email || 'Unknown'}</div>
            </div>
          </div>
          {/broker admin/i.test(role) && (
            <div>
              <div className={styles.apiWrapper}>
                {brokerApiData !== undefined && (
                  <Row wrap={false} gutter={16} justify="space-between">
                    <Col>
                      <Space>
                        <Switch defaultChecked={brokerApiData?.enabled_by_broker_admin} onClick={toggleBrokerApiKey} />
                        <div>Loan application & company members API key</div>
                      </Space>
                    </Col>
                    <Col>
                      <Button onClick={() => toggleResetKey()} type="text" icon={<ReloadIcon />} />
                    </Col>
                  </Row>
                )}
                {brokerApiData?.token && brokerApiData?.enabled_by_broker_admin && (
                  <Space>
                    <p
                      style={{
                        wordBreak: 'break-all',
                        maxLines: 2,
                        WebkitLineClamp: 2,
                        lineClamp: 2,
                        overflow: 'hidden',
                      }}
                    >
                      {brokerApiData?.token}
                    </p>
                    <Button onClick={copyToClipBoard} className={styles.copyButton}>
                      Copy
                    </Button>
                  </Space>
                )}
                <a href={linkToDocumentation} className={styles.link}>
                  See Documentation
                </a>
              </div>
            </div>
          )}
        </div>
      </Spin>
      <AdminReassignModal
        companyId={data.id}
        companyName={data.name}
        onAfterReassign={() => {
          toggleEditMode();
          requestData();
        }}
        onCancel={toggleReassignAdminVisible}
        visible={reassignAdminVisible}
      />
      <ImageCropModal
        file={logoCropModal.file}
        format="logo"
        onCancel={() =>
          setLogoCropModal((prev) => ({
            ...prev,
            visible: false,
          }))
        }
        onOk={handleCroppedPhoto}
        visible={logoCropModal.visible}
      />
      <Modal
        okText="Confirm"
        width={660}
        okButtonProps={{ type: 'dark' }}
        title={<p style={{ fontSize: 18 }}>Reset API Key</p>}
        bodyStyle={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 24, paddingTop: 0 }}
        onOk={() => {
          brokerResetApiKey();
          toggleResetKey();
        }}
        open={resetKeyVisible}
        onCancel={toggleResetKey}
      >
        <div className={styles.container}>
          Are you sure you want to reset this API key? This action will disable all existing API connections, and you
          will need to update your API key integrations.
        </div>
      </Modal>
    </div>
  );
}

export default CompanyInfo;
