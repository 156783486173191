import '../Users/styles/create_edit_user.css';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import CustomButton from 'components/UI/Button';
import DropdownMenu from 'components/CustomComponents/DropdownMenu';
import regExp from 'constants/regExp';
import TextInput from 'components/CustomComponents/TextInput';
import useNestedCheckError from 'constants/useNestedCheckError';
import { useSelector } from 'react-redux';

const initState = {
  first_name: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
  last_name: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
  email: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
  user_type: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Choose user type',
    options: [
      { preview: 'PLA', value: 'PLA' },
      { preview: 'LOM', value: 'LOM' },
      { preview: 'BDR', value: 'BDR' },
      // { preview: "Lender Admin", value: "Lender Admin" },
      // { preview: "Broker Admin", value: "Broker Admin" },
      // { preview: "Affiliate", value: "Affiliate" },
    ],
  },
};

export default function CreateEditUser() {
  const [isProspect, setIsProspect] = useState(true);
  const [userData, setUserData] = useState(initState);
  const { checkEmpty } = useNestedCheckError(userData, setUserData);
  const { role } = useSelector((s) => s.user.userData);
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!id) {
      return;
    }

    API()
      .get('/superadmin/user', { params: { id } })
      .then(({ data }) => {
        setIsProspect(/prospect/i.test(data.role));
        setUserData((prev) => ({
          first_name: {
            ...prev.first_name,
            value: data.name,
          },
          last_name: {
            ...prev.last_name,
            value: data.surname,
          },
          email: {
            ...prev.email,
            value: data.email,
          },
          user_type: {
            ...prev.user_type,
            value: data.role,
          },
        }));
      });
  }, [location.pathname, history, id]);

  const createEdit = (e) => {
    e.preventDefault();

    const { error, obj } = checkEmpty(userData);
    setUserData(obj);

    if (error) {
      return;
    }

    if (!regExp.email.test(userData.email.value)) {
      setUserData((prevState) => ({
        ...prevState,
        email: {
          ...prevState.email,
          error: true,
          errorMessage: 'Incorrect email format',
        },
      }));
      return;
    }

    const reqObj = {
      name: userData.first_name.value,
      surname: userData.last_name.value,
      role_name: userData.user_type.value,
      email: userData.email.value,
    };
    if (id) {
      reqObj.id = +id;
    }

    API()
      .post('/superadmin/user', reqObj)
      .then(() => {
        message.success(`You successfully ${id ? 'edited' : 'created'} user`);
        history.push('/onboarding/users');
      })
      .catch((err) => {
        if (err.response?.data?.error === 'This email is already exists!') {
          setUserData((prevState) => ({
            ...prevState,
            email: {
              ...prevState.email,
              error: true,
              errorMessage: 'This email is already exists',
            },
          }));
        }
      });
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
        error: null,
      },
    }));
  };

  const onSelect = (val, name) => {
    setUserData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: val.value ?? '',
        error: false,
      },
    }));
  };

  function onResetPassword() {
    API()
      .post('/superadmin/user/reset-password', { id })
      .then(() => {
        message.success('Link sent successfully');
      });
  }

  const isPLA = /superadmin|pla/i.test(role);

  // pla superadmin

  const userTypeInList = userData.user_type.options.map((e) => e.value).includes(userData.user_type.value);
  return (
    <div className="create_user_wrapper">
      <div className="navigation">
        <Link to="/onboarding/users">Users</Link>
        <span>/</span>
        <span className="active">{id ? 'Edit' : 'Create new'} user</span>
      </div>
      <h2>{id ? 'Edit' : 'Create new'} user</h2>
      <div className="content">
        <form onSubmit={createEdit}>
          <div className="inputs">
            <div className="inline">
              <TextInput
                title="First name"
                error={userData.first_name.error}
                errorMessage={userData.first_name.errorMessage}
                htmlFor="first_name"
                onChange={(e) => onChange(e)}
                value={userData.first_name.value}
              />
              <TextInput
                title="Last name"
                error={userData.last_name.error}
                htmlFor="last_name"
                errorMessage={userData.last_name.errorMessage}
                onChange={(e) => onChange(e)}
                value={userData.last_name.value}
              />
            </div>
            <TextInput
              title="Email"
              error={userData.email.error}
              htmlFor="email"
              errorMessage={userData.email.errorMessage}
              onChange={(e) => onChange(e)}
              value={userData.email.value}
            />
            {userTypeInList && isPLA && (
              <DropdownMenu
                options={userData.user_type.options}
                className="bordered"
                title="User type"
                error={userData.user_type.error}
                errorMessage={userData.user_type.errorMessage}
                onSelect={(e) => onSelect(e, 'user_type')}
                value={userData.user_type.value}
              />
            )}
          </div>
          <p>
            User will receive an email with the link to
            {id ? 'reset' : 'set'} his password
          </p>
          <div className={`buttons_wrap ${id ? 'buttons_wrap_edit' : ''}`}>
            {Boolean(id) && !isProspect ? (
              <CustomButton onClick={onResetPassword} primary text="Send password reset email" />
            ) : (
              <div />
            )}
            <CustomButton primary text={id ? 'Save' : 'Create user'} type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
}
