import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Input, Select, Tooltip, Typography } from 'antd';
import { LARAPI } from 'api';
import Modal from 'components/Modals/Modal';

const reasonOptions = [
  { value: 'Closing days too long' },
  { value: "General loan terms didn't meet the loan request" },
  { value: 'Have better loan offer from other source' },
  { value: 'Interest rate was higher than requested' },
  { value: 'Lender points were too high' },
  { value: 'Loan Amount was insufficient' },
  { value: 'Not specified' },
  { value: 'Other loan quotes provided were better' },
];

export default function RejectQuotesButton({ id }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const [isSending, setIsSending] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const API = useMemo(() => new LARAPI(id), [id]);
  const messageValue = Form.useWatch('message', form);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    setIsSending(true);
    API.archive(messageValue).finally(() => {
      setIsModalOpen(false);
      setIsSending(false);
      history.push('/');
    });
  };

  return (
    <>
      <Tooltip title="To reject all quotes and end this auction process, go to deal settings and select «cancel»">
        <Button danger size="large" onClick={handleOpen}>
          Reject quotes
        </Button>
      </Tooltip>
      <Modal
        open={isModalOpen}
        okText="Confirm"
        okButtonProps={{ loading: isSending, disabled: !messageValue }}
        onCancel={handleClose}
        onOk={() => form.submit()}
      >
        <Typography.Title level={5}>Are you sure you want to reject all of the quotes?</Typography.Title>
        <Typography.Paragraph>
          When you reject all quotes this deal will be cancelled and placed in archive.
        </Typography.Paragraph>
        <Typography.Paragraph>Starport will notify the lenders of your decision.</Typography.Paragraph>
        <Form form={form} initialValues={{ reason: reasonOptions[0].value }} layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Please let us know why you are archiving this deal" name="reason">
            <Select options={reasonOptions} />
          </Form.Item>
          <Form.Item label="Message" name="message">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
