import { Checkbox, Form } from 'antd';
import { useContext, useEffect } from 'react';
import { PRODUCT_SUBTITLE } from 'constants/forms';
import LarAppContext from 'components/LarAppNew/context/LarAppContext';

function ProductFormPurchaseShort({ form }) {
  const { loan_term, prepayment } = useContext(LarAppContext);

  // TODO: TEST SAVE DATA
  useEffect(() => {
    form.setFieldsValue({
      loan_term,
      prepayment,
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <p style={{ color: '#8C8C8C' }}>{PRODUCT_SUBTITLE}</p>
      <Form.Item name="loan_term" label="Loan term (months)" initialValue={[]}>
        <Checkbox.Group className="custom-checkbox-block custom-checkbox-block_3">
          <Checkbox value={12}>12</Checkbox>
          <Checkbox value={18}>18</Checkbox>
          <Checkbox value={24}>24</Checkbox>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item name="prepayment" label="Prepayment" initialValue={[]}>
        <Checkbox.Group className="custom-checkbox-block">
          <Checkbox value="No Prepay">No prepayment</Checkbox>
          <Checkbox value="Allow Prepayment">Allow Prepayment</Checkbox>
        </Checkbox.Group>
      </Form.Item>
    </>
  );
}

export default ProductFormPurchaseShort;
