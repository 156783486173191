import { LARAPI } from '../../api';
import { getFeesWorksheet } from '../actions/feesWorksheetActions';

export const fetchFeesWorksheet = (larId) => {
  const API = new LARAPI(larId);

  return (dispatch) =>
    API.getFeesWorksheet().then((res) => {
      dispatch(getFeesWorksheet(res));
    });
};
