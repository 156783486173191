import { useState } from 'react';
import { Button, Card, Checkbox, Divider, Space, Table, Typography } from 'antd';

import { getFileIcon, getFileTypeFromAWSUrl, humanFileSize, onDownloadFile } from '../../../../../utils/files';

import styles from './LoanProfileDocuments.module.css';

export default function LoanProfileDocuments({ data }) {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectAllChecked(newSelectedRowKeys.length === data.length);
  };

  const dataSource = data.map((i) => ({
    key: i.id,
    checkbox: '',
    file_name: (
      <div className={styles.docName}>
        {getFileIcon(getFileTypeFromAWSUrl(i.elem))}
        <Typography.Text ellipsis>{i.file_name}</Typography.Text>
      </div>
    ),
    file_type: getFileTypeFromAWSUrl(i.elem)?.toUpperCase(),
    size_in_bytes: humanFileSize(i.size_in_bytes),
    download: <Button onClick={() => onDownloadFile(i.elem, i.file_name)}>Download</Button>,
  }));
  const columns = [
    { title: '', dataIndex: 'checkbox', key: 'checkbox', width: 20 },
    { title: 'File Name', dataIndex: 'file_name', key: 'file_name' },
    { title: 'File Type', dataIndex: 'file_type', key: 'file_type' },
    { title: 'File Size', dataIndex: 'size_in_bytes', key: 'size_in_bytes' },
    { title: '', dataIndex: 'download', key: 'download', width: 130 },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);
    setSelectedRowKeys(checked ? dataSource.map((item) => item.key) : []);
  };

  const handleDownloadSelected = () => {
    data.forEach((i) => {
      if (selectedRowKeys.includes(i.id)) {
        onDownloadFile(i.elem, i.file_name);
      }
    });
  };

  return (
    <Card>
      <Typography.Title level={4}>Documents</Typography.Title>
      <Divider style={{ margin: 0 }} />
      <Space className={styles.actions}>
        <Checkbox checked={selectAllChecked} onChange={handleSelectAllChange}>
          Select All
        </Checkbox>
        <Button onClick={handleDownloadSelected}>Download</Button>
      </Space>
      <Table
        className={styles.table}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </Card>
  );
}
