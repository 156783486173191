import { CLOSE_POPUP, SHOW_POPUP } from '../actions/types';

const initialState = {
  show: '',
  data: null,
};

const popupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_POPUP:
      return {
        ...state,
        ...action.payload,
      };
    case CLOSE_POPUP:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default popupsReducer;
