import { Breadcrumb } from 'antd';
import { Link, Redirect, useParams } from 'react-router-dom';
import { SettingsAPI } from 'api';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCompanyCategories } from './hooks/companyCategories';
import { useUserCategories } from './hooks/userCategories';
import classes from './styles/Settings.module.css';
import Dashboard from './Dashboard';
import MainLayout from '../../../components/Layouts/MainLayout';

export default function Settings({ company = false }) {
  const { category: activeCategory, slug } = useParams();
  const userCategories = useUserCategories();
  const companyCategories = useCompanyCategories();
  const { role } = useSelector((store) => store.user.userData);

  const API = useMemo(() => new SettingsAPI(), []);

  const categories = useMemo(
    () => (company ? companyCategories : userCategories),
    [userCategories, companyCategories, company],
  );

  const currentCategoryData = useMemo(
    () => categories.find((c) => activeCategory === c.id),
    [activeCategory, categories],
  );

  return (
    <MainLayout title={company ? 'Company' : 'Account'}>
      <div className={classes.root}>
        {activeCategory ? (
          currentCategoryData.redirect ? (
            <Redirect to={currentCategoryData.redirect} />
          ) : (
            <>
              {/admin|manager/i.test(role) ? (
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to={`/onboarding/${company ? 'company-' : ''}settings`}>Account</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {slug ? (
                      currentCategoryData?.title
                    ) : (
                      <Link to={`/onboarding/${company ? 'company-' : ''}settings/${activeCategory}`}>
                        {currentCategoryData?.title}
                      </Link>
                    )}
                  </Breadcrumb.Item>
                  {slug && (
                    <Breadcrumb.Item>
                      <Link to={`/onboarding/${company ? 'company-' : ''}settings/${activeCategory}/${slug}`}>
                        {slug}
                      </Link>
                    </Breadcrumb.Item>
                  )}
                </Breadcrumb>
              ) : null}

              <currentCategoryData.Component API={API} />
            </>
          )
        ) : (
          <Dashboard categories={categories} company={company} />
        )}
      </div>
    </MainLayout>
  );
}
