import { useSelector } from 'react-redux';

import BrokerDashboard from 'components/Dashboard/BrokerDashboard';
import DashboardContent from 'components/Dashboard/DashboardContent';
import Header from 'components/Dashboard/Header';
import LenderDashboard from 'components/Dashboard/LenderDashboard';

import styles from './Dashboard.module.css';

const roleRegexes = [
  { regex: /broker/i, component: BrokerDashboard },
  { regex: /lender/i, component: LenderDashboard },
];

const getComponentForRole = (role) => {
  for (const { regex, component } of roleRegexes) {
    if (regex.test(role)) {
      return component;
    }
  }
  return DashboardContent;
};

export default function Dashboard() {
  const { role } = useSelector((s) => s.user.userData);
  const isPLA = /superadmin|pla/i.test(role);

  const RoleBasedComponent = getComponentForRole(role);

  return (
    <div className={styles.root}>
      {isPLA && <Header />}
      <RoleBasedComponent />
    </div>
  );
}
