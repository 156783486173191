import React, { useEffect, useMemo, useReducer, useState } from 'react';
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  message,
  Row,
  Space,
  Table,
  Tabs,
  Tooltip as AntdTooltip,
  Checkbox,
} from 'antd';
import { Bar, Pie } from 'react-chartjs-2';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from 'chart.js';
import { useHistory, useParams } from 'react-router-dom';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import DashboardWidget from 'components/Dashboard/DashboardWidget';
import htmlLegendPlugin from 'utils/html-legend-plugin';
import LegacyAPI, { LARAPI } from 'api';
import Loader from 'components/Loader';
import moment from 'moment';
import PaginationItemRender from 'constants/PaginationItemRender';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import WithdrawModal from 'components/LARDB/Settings/WithdrawModal';
import TeamMembersPopover from 'components/TeamMembersPopover';
import ActivityLogTable from 'pages/Onboarding/PLA/Report/ActivityLogTable';
import {
  fetchActivityLog,
  fetchActivityLogFilter,
  fetchConversationLog,
  fetchConversationLogFilter,
} from 'store/async-actions/lar';
import { useDispatch, useSelector } from 'react-redux';

import { activityLogColumns, conversationLogColumns } from 'pages/Onboarding/PLA/Report/ActivityLogTable/columns';
import UserDrawer from 'components/UserDrawer/UserDrawer';
import MessagingLogsTable from './MessagingLogsTable';
import Actions from 'components/Dashboard/ItemTable/Actions';
import ManualEMSModal from 'components/Modals/ManualEMSModal';

ChartJS.register(ArcElement, CategoryScale, Tooltip, Legend, LinearScale, BarElement);

const columns = (status, checked, toggleChecked, data) => [
  {
    title: () => (
      <div style={{ padding: '2px 5px' }}>
        <Checkbox checked={checked.size > 0 && data?.length === checked.size} onChange={() => toggleChecked()} />
      </div>
    ),
    dataIndex: 'checkbox',
    key: 'checkbox',
    render: (_, { user }) => (
      <div onClick={(e) => e.stopPropagation()} style={{ padding: '2px 5px' }}>
        <Checkbox
          checked={checked.has(user.id)}
          disabled={!data.map((e) => e.user.id).includes(user.id)}
          onChange={(e) => {
            e.stopPropagation();
            toggleChecked(user.id);
          }}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    ),
  },
  {
    title: 'Lender Name',
    dataIndex: 'lender_full_name',
    key: 'lender_full_name',
  },
  {
    title: 'Lender #',
    dataIndex: 'Assigned #',
    key: 'Assigned #',
  },
  {
    title: 'Role',
    dataIndex: 'user_role',
    key: 'user_role',
  },
  {
    title: 'Company',
    dataIndex: 'company_name',
    key: 'company_name',
  },
  {
    title: 'Mandate Name',
    dataIndex: 'mandate_name',
    key: 'mandate_name',
  },
  {
    title: 'Mandate Score',
    dataIndex: 'score',
    key: 'score',
  },
  {
    title: 'Invite Result',
    dataIndex: 'invite_status',
    key: 'invite_status',
    render: (val, { description }) => {
      const [reasons, descriptions] = description?.split(/Description:/g) || [];
      return (
        <Space align="center">
          <span style={{ whiteSpace: 'nowrap' }}>{val}</span>
          {val === 'Passed with reason' && description && (
            <span onClick={(e) => e.stopPropagation()}>
              <AntdTooltip
                title={
                  <>
                    <div>User passed the deal</div>
                    {reasons?.replace('Reason:', '').trim() && (
                      <div>
                        Reason:{' '}
                        {reasons
                          ?.replace('Reason:', '')
                          .split(/(?=[A-Z])/)
                          .filter((i) => i.trim())
                          .map((i) => `${i.trim()}. `)}
                      </div>
                    )}
                    {descriptions?.trim() && <div>Description: {descriptions}</div>}
                  </>
                }
                trigger="click"
              >
                <InfoCircleOutlined />
              </AntdTooltip>
            </span>
          )}
        </Space>
      );
    },
  },
  {
    title: 'Date/Time',
    dataIndex: 'invite_sent',
    key: 'invite_sent',
  },
  {
    dataIndex: 'actions',
    render: (_, el) => <Actions meta={{ status }} isReport data={el} />,
    title: '',
  },
];

const pieChartHeaders = [
  { title: 'Lender Responses', key: 'lender_responses' },
  { title: 'No response', key: 'no_response' },
  { title: 'Lenders passed', key: 'lender_passed' },
];

const barChartHeaders = [
  { title: 'Chats count', key: 'chats_count', color: '#73D13D' },
  { title: 'Lender responses', key: 'lender_responses', color: '#FAAD14' },
  {
    title: 'Public messages with docs',
    key: 'public_messages_with_docs',
    color: '#877AEB',
  },
  { title: 'Quotes requested', key: 'quotes_requested', color: '#FF4D4F' },
];

function LenderInvitationsTable({ lar_id, status }) {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 1,
  });
  const [data, setData] = useState([]);
  const [activeDrawer, setActiveDrawer] = useState({ show: '', data: null });
  const [render, setRender] = useState(0);

  function getData() {
    LegacyAPI()
      .get('/LAR/dashboard/PLA/report/invites', {
        params: {
          lar_id,
          page: pagination.current,
          per_page: pagination.pageSize,
        },
      })
      .then((res) => {
        console.log(res.data);
        setPagination((state) => ({ ...state, total: res.data.total_count }));
        setData(res.data.invites);
        setInvitedList(res.data.invites.filter((e) => !!e['Assigned #']));
      });
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [pagination.current, pagination.pageSize, lar_id, render]);

  useEffect(() => {
    if (activeDrawer.data) {
      console.log();
      const item = data.filter((item) => item.user.id === activeDrawer.data.id);
      if (item[0]) {
        setActiveDrawer({ show: 'user-drawer', data: item[0].user });
      }
    }
  }, [data]);

  function handleOpenDrawer(user) {
    setActiveDrawer({ show: 'user-drawer', data: user });
  }

  function handleCloseDrawer() {
    setActiveDrawer({ show: '', data: null });
  }

  async function handleScanWatch() {
    try {
      await LegacyAPI().post('/LAR/dashboard/PLA/rerun_smartmatch', { lar_id: Number(lar_id) });
      setRender((prev) => prev + 1);
    } catch (e) {
      console.log(e);
    }
  }

  const [invitedList, setInvitedList] = useState(data?.filter((e) => !!e['Assigned #']));

  const [checked, toggleChecked] = useReducer((state, id) => {
    if (undefined === id) {
      if (invitedList.length === state.size) {
        return new Set();
      }
      return new Set(invitedList.map((item) => item.user.id));
    }
    const copy = new Set(state);
    state.has(id) ? copy.delete(id) : copy.add(id);
    return copy;
  }, new Set());

  const [isOpen, setIsOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const API = useMemo(() => new LARAPI(lar_id), [lar_id]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSend = async (data) => {
    setIsSending(true);
    console.log('data message', data);
    try {
      await API.sendEmailToMandate({ lar_id, message: data.message || '', users_ids: Array.from(checked) });
      message.success('Email successfully sent');
    } catch (e) {
      if (e.response.data['wrong columns']) {
        message.error(e.response.data['wrong columns'][0].message[0]);
        return;
      }
      message.error(e.response.data.error);
    } finally {
      setIsOpen(false);
      setIsSending(false);
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <SectionTitle title="Lender Invitations" type="heading-20" />
        <div style={{ display: 'flex', gap: 16 }}>
          {['Scheduled', 'Invites Sent', 'Live Auction', 'Active'].includes(status) && checked.size > 0 && (
            <Button onClick={handleOpen} type="dark">
              Send Reminders
            </Button>
          )}
          {['Scheduled', 'Invites Sent', 'Live Auction', 'Active'].includes(status) && (
            <Button onClick={handleScanWatch} type="dark">
              Run Smartmatch
            </Button>
          )}
        </div>
      </div>
      <div style={{ margin: '16px 0 0' }} className="table_with_pagination_wrapper">
        <div className="custom-table table_with_pagination">
          <Table
            size="middle"
            columns={columns(status, checked, toggleChecked, invitedList)}
            dataSource={data}
            loading={false}
            onChange={(p) => setPagination((state) => ({ ...state, ...p }))}
            onRow={(record) => ({
              onClick: (e) => {
                e.stopPropagation();
                handleOpenDrawer(record.user);
              },
            })}
            pagination={{
              current: pagination?.current,
              itemRender: PaginationItemRender,
              showSizeChanger: true,
              total: pagination.total,
              pageSize: pagination.pageSize,
            }}
            scroll={{ x: 1350 }}
          />
        </div>
      </div>
      <UserDrawer
        fetchGetUser={getData}
        visible={activeDrawer.show === 'user-drawer'}
        user={activeDrawer.data}
        onClose={handleCloseDrawer}
      />
      <ManualEMSModal
        mainRecipient={data
          .filter((item) => Array.from(checked).includes(item.user.id))
          .map((e) => {
            console.log(e);
            return { full_name: e['Assigned #'] };
          })}
        visible={isOpen}
        templateRoute={`/LAR/dashboard/PLA/send_email_to_mandate?lar_id=${lar_id}`}
        isSending={isSending}
        onCancel={handleClose}
        onSubmit={handleSend}
        messageNotOptional
      />
    </div>
  );
}

function PieChart({ bid_data }) {
  const values = pieChartHeaders.map((i) => bid_data[i.key]);
  const options = {
    plugins: {
      htmlLegend: {
        // ID of the container to put the legend in
        containerID: 'legend-container_pie',
      },
      legend: {
        display: false,
      },
      datalabels: {
        color: '#8C8C8C',
        formatter(value) {
          if (!value) {
            return null;
          }
          return value;
        },
        font: {
          size: 20,
          weight: 600,
        },
      },
    },
  };
  const data = {
    labels: pieChartHeaders.map((i) => i.title),
    datasets: [
      {
        label: '# of Votes',
        data: values,
        backgroundColor: ['#69C0FF', '#73D13D', '#877AEB', '#FAAD14'],
      },
    ],
  };
  const isEmpty = values.every((i) => !i);
  return (
    isEmpty || (
      <Col span={12}>
        <div style={{ width: '100%', padding: 24, border: '1px solid #D9D9D9' }}>
          <div style={{ paddingLeft: 36, marginBottom: 12, fontSize: 16 }}>
            Total invitations:
            {values.reduce((acc, value) => acc + value, 0)}
          </div>
          <Space size={32}>
            <Pie data={data} options={options} width={230} plugins={[htmlLegendPlugin]} />
            <div style={{ marginTop: 16 }} id="legend-container_pie" />
          </Space>
        </div>
      </Col>
    )
  );
}

function BarChart({ bar_data }) {
  const options = {
    // barPercentage: 0.4,
    responsive: true,
    // barThickness: 80,
    plugins: {
      htmlLegend: {
        // ID of the container to put the legend in
        containerID: 'legend-container',
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        color: '#8C8C8C',
        anchor: 'end',
        align: 'top',
        clamp: true,
        font: {
          size: 20,
          weight: 600,
        },
      },
    },
    scales: {
      xAxis: {
        display: false,
      },
      yAxis: {
        suggestedMax: 70,
        display: false,
      },
    },
  };

  const labels = [' '];

  const data = {
    labels,
    datasets: barChartHeaders.map((i) => ({
      label: i.title,
      data: [bar_data[i.key]],
      backgroundColor: i.color,
    })),
  };

  const isEmpty = barChartHeaders.every((i) => !bar_data[i.key]);
  return (
    isEmpty || (
      <Col span={12}>
        <div style={{ width: '100%', padding: 24, border: '1px solid #D9D9D9' }}>
          <Space size={32}>
            <Bar options={options} data={data} height={230} plugins={[htmlLegendPlugin]} />
            <div style={{ marginTop: 16 }} id="legend-container" />
          </Space>
        </div>
      </Col>
    )
  );
}

function Report({ type }) {
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [loading, setLoading] = useState({
    finish: false,
    withdrawModal: false,
  });
  const [data, setData] = useState({});
  const [LARData, setLARData] = useState({});
  const [activityLogFilter, setActivityLogFilter] = useState({});
  const [conversationLogFilter, setConversationLogFilter] = useState({});
  const { data: logsData } = useSelector((state) => state.lar);
  const [activityLogPagination, setActivityLogPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [conversationLogPagination, setConversationLogPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { id } = useParams();
  const isScenario = type === 'scenario';
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    LegacyAPI()
      .get('/LAR/dashboard/PLA/report', { params: { lar_id: id } })
      .then((res) => {
        setData({ ...res.data, ready: true });
      });
    LegacyAPI()
      .get('/LAR/dashboard/lar_dashboard', { params: { lar_id: id } })
      .then(({ data }) => setLARData(data));
  }, [id]);

  async function handleUpdateLarMembers() {
    LegacyAPI()
      .get('/LAR/dashboard/PLA/report', { params: { lar_id: id } })
      .then((res) => {
        setData({ ...res.data, ready: true });
      });
    LegacyAPI()
      .get('/LAR/dashboard/lar_dashboard', { params: { lar_id: id } })
      .then(({ data }) => setLARData({ ...data }));
  }

  const openLAR = () => window.open(`/onboarding/lar_db/${id}/settings`);

  const openProfile = () => window.open(`/onboarding/loan_profile/${id}`);

  const finishAuction = () => {
    setLoading((prev) => ({
      ...prev,
      finish: true,
    }));
    LegacyAPI()
      .post('/dev/auction/end', { lar_id: +id })
      .then(() => {
        history.push('/onboarding/dashboard');
      })
      .catch(() => message.error('Something went wrong'))
      .finally(() => {
        setLoading((prev) => ({
          ...prev,
          finish: false,
        }));
      });
  };

  const API = useMemo(() => new LARAPI(Number(id)), [id]);

  function handleCancellation({ reason, additionalInfo }) {
    setLoading((prev) => ({
      ...prev,
      withdrawModal: true,
    }));
    API.cancel(reason, additionalInfo)
      .then(() => {
        setCancelModalVisible(false);
        message.success('You successfully cancelled this auction');
        history.push('/onboarding/dashboard');
      })
      .catch(() => message.error('Something went wrong'))
      .finally(() => {
        setLoading((prev) => ({
          ...prev,
          withdrawModal: false,
        }));
      });
  }

  useEffect(() => {
    dispatch(
      fetchActivityLog(
        id,
        activityLogFilter.userNames,
        null,
        null,
        activityLogFilter.dateFrom ? moment(activityLogFilter.dateFrom).format('DD/MM/Y h:MM a') : null,
        activityLogFilter.dateTo ? moment(activityLogFilter.dateTo).format('DD/MM/Y h:MM a') : null,
        null,
        null,
        activityLogPagination.pageSize,
        activityLogPagination.current,
        activityLogFilter.userView,
      ),
    );
    dispatch(fetchActivityLogFilter(id));
  }, [activityLogFilter, activityLogPagination.current, activityLogPagination.pageSize]);

  useEffect(() => {
    dispatch(
      fetchConversationLog(
        id,
        conversationLogFilter.userNames,
        null,
        null,
        conversationLogFilter.dateFrom ? moment(conversationLogFilter.dateFrom).format('DD/MM/Y h:MM a') : null,
        conversationLogFilter.dateTo ? moment(conversationLogFilter.dateTo).format('DD/MM/Y h:MM a') : null,
        null,
        null,
        conversationLogPagination.pageSize,
        conversationLogPagination.current,
      ),
    );
    dispatch(fetchConversationLogFilter(id));
  }, [conversationLogFilter, conversationLogPagination.current, conversationLogPagination.pageSize]);

  useEffect(() => {
    if (logsData) {
      setActivityLogPagination((prev) => ({
        ...prev,
        total: logsData.activityLog?.total_count,
      }));
      setConversationLogPagination((prev) => ({
        ...prev,
        total: logsData.conversationLog?.total_count,
      }));
    }
  }, [logsData]);

  function handleActivityLogFilterDateRange(value) {
    setActivityLogFilter((prev) => ({
      ...prev,
      dateFrom: value?.[0],
      dateTo: value?.[1],
    }));
  }

  function handleConversationLogFilterDateRange(value) {
    setConversationLogFilter((prev) => ({
      ...prev,
      dateFrom: value?.[0],
      dateTo: value?.[1],
    }));
  }

  function handleActivityLogFilterUser(value) {
    setActivityLogFilter((prev) => ({
      ...prev,
      userNames: value,
    }));
  }

  function handleFilterActivityLogUserView(e) {
    setActivityLogFilter((prev) => ({
      ...prev,
      userView: Number(e.target.checked),
    }));
  }

  function handleConversationLogFilterUser(value) {
    setConversationLogFilter((prev) => ({
      ...prev,
      userNames: value,
    }));
  }

  function clearActivityLogFilter() {
    setActivityLogFilter({});
  }

  function clearConversationLogFilter() {
    setConversationLogFilter({});
  }

  function handleActivityLogPagination(p) {
    setActivityLogPagination((state) => ({ ...state, ...p }));
  }

  function handleConversationLogPagination(p) {
    setConversationLogPagination((state) => ({ ...state, ...p }));
  }

  const lastUpdate = moment(data.last_update, 'ddd MMM DD YYYY hh:mm A').fromNow();
  const desc = [id, moment(data.date_submitted).format('ddd MMM DD YYYY hh:mm A'), `Updated ${lastUpdate}`];

  return data.ready ? (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>{isScenario ? 'Scenarios' : 'LARs'}</Breadcrumb.Item>
        <Breadcrumb.Item>{id}</Breadcrumb.Item>
      </Breadcrumb>
      <Row align="middle" justify="space-between" style={{ marginTop: 16 }}>
        <SectionTitle title={data.name} type="regular-24" />
        <Space>
          <TeamMembersPopover updateData={handleUpdateLarMembers} larMembers={LARData.lar_members} larId={id} />
          {/scheduled/i.test(data.status) && (
            <Button onClick={() => setCancelModalVisible(true)} size="large">
              Cancel auction
            </Button>
          )}
          {data.status === 'Live Auction' && (
            <Button onClick={finishAuction} size="large" type="dark" loading={loading.finish}>
              Finish
            </Button>
          )}
          <Button onClick={openProfile} size="large" type="dark">
            View Profile
          </Button>
          {isScenario || (
            <Button onClick={openLAR} size="large" type="dark">
              View Auction
            </Button>
          )}
        </Space>
      </Row>
      <Row align="middle" justify="space-between" style={{ marginTop: 16 }}>
        <Space align="center" size="middle">
          {desc.map((el, idx) => (
            <React.Fragment key={idx}>
              <span>{el}</span>
              {idx === desc.length - 1 ? null : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="2" fill="#BFBFBF" />
                  </svg>
                </div>
              )}
            </React.Fragment>
          ))}
        </Space>
      </Row>
      <Row style={{ marginTop: 16, marginBottom: 32 }}>
        <Space size="middle" align="center">
          <Avatar
            size={40}
            icon={data.broker.profile_photo ? <img src={data.broker.profile_photo} alt="Avatar" /> : <UserOutlined />}
          />
          <Space size={4} direction="vertical">
            <span>{data.broker.full_name}</span>
            <span className="description-14">{data.broker.company_name}</span>
          </Space>
        </Space>
      </Row>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Report" key="report">
          <h2>Report</h2>
          <Row style={{ marginTop: 32 }} gutter={[16, 16]}>
            <Col span={8}>
              <DashboardWidget titleFontSize={16} title={data.status} desc="Status" />
            </Col>
            <Col span={8}>
              <DashboardWidget titleFontSize={16} title={data.days_active} desc="Days Active" />
            </Col>
            <Col span={8}>
              <DashboardWidget titleFontSize={16} title={data.last_update} desc="Last User Activity" />
            </Col>
          </Row>
          {!isScenario && data.winner_data && (
            <div
              style={{
                marginTop: 32,
                border: '1px solid #D9D9D9',
                padding: 16,
              }}
            >
              <SectionTitle type="body-16" title="Winner lender selected" />
              <Space size={24}>
                <div>
                  <div className="description-14">Winner</div>
                  <Space size="middle" align="center">
                    <Avatar
                      size={40}
                      icon={
                        data.winner_data.profile_photo ? (
                          <img src={data.winner_data.profile_photo} alt="Avatar" />
                        ) : (
                          <UserOutlined />
                        )
                      }
                    />
                    <Space size={4} direction="vertical">
                      <span>{data.winner_data.full_name}</span>
                      <span className="description-14">{data.winner_data.company_name}</span>
                    </Space>
                  </Space>
                </div>
                {data.winner_data.date_selected && data.winner_data.date_selected !== 'None' && (
                  <Space size={4} align="center" direction="vertical">
                    <span className="description-14">Date Selected</span>
                    <div>
                      <div>{moment(data.winner_data.date_selected).format('D MMM YYYY')}</div>
                      <div>{moment(data.winner_data.date_selected).format('LT')}</div>
                    </div>
                  </Space>
                )}
              </Space>
            </div>
          )}
          <div style={{ marginTop: 32 }} />
          <Row gutter={16}>
            <PieChart bid_data={data.bid_statistic} />
            <BarChart bar_data={data.bar_chart} />
          </Row>
          <div style={{ marginTop: 32 }} />
          <LenderInvitationsTable lar_id={id} status={data.status} />
          <WithdrawModal
            cancellation
            onCancel={() => setCancelModalVisible(false)}
            onOk={handleCancellation}
            visible={cancelModalVisible}
            loading={loading.withdrawModal}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Activity Log" key="activity_log">
          <ActivityLogTable
            type="activity"
            title="Activity Log"
            filter={activityLogFilter}
            id={id}
            data={logsData.activityLog}
            columns={activityLogColumns}
            dataFilter={logsData.activityLogFilter}
            pagination={activityLogPagination}
            onFilterDateRange={handleActivityLogFilterDateRange}
            onFilterUser={handleActivityLogFilterUser}
            onFilterClear={clearActivityLogFilter}
            onFilterUserView={handleFilterActivityLogUserView}
            handlePagination={handleActivityLogPagination}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Communications Log" key="communications">
          <ActivityLogTable
            type="communication"
            title="Communications Log"
            filter={conversationLogFilter}
            id={id}
            data={logsData.conversationLog}
            dataFilter={logsData.conversationFilter}
            pagination={conversationLogPagination}
            columns={conversationLogColumns}
            onFilterDateRange={handleConversationLogFilterDateRange}
            onFilterUser={handleConversationLogFilterUser}
            onFilterClear={clearConversationLogFilter}
            handlePagination={handleConversationLogPagination}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Messaging Logs" key="messaging">
          <MessagingLogsTable />
        </Tabs.TabPane>
      </Tabs>
    </div>
  ) : (
    <div className="loading_wrap">
      <Loader
        color={{
          '--bgColor': 'var(--main-blue-color)',
          '--size': '100px',
          '--wrapper_size': '100px',
        }}
      />
    </div>
  );
}

export default Report;
