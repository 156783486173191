import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Progress, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import Modal from 'components/Modals/Modal';
import popupInitialState from 'constants/popupState';
import styles from './Header.module.css';
import { useSelector } from 'react-redux';
import { PLA } from 'constants/roles';
import { ACTIVE } from 'constants/larApp';

function Header({ title, progressPercent, progressLAPercent, onFormSave, status, handleSubmit, submitLoading }) {
  const [popup, setPopup] = useState(popupInitialState);
  const role = useSelector((state) => state.user.userData.role);

  const history = useHistory();

  function showExitPopup(goingBack = false) {
    setPopup((prev) => ({
      ...prev,
      show: 'lar-app-exit-popup',
      data: {
        goingBack,
      },
    }));
  }

  function handleCancel() {
    setPopup(popupInitialState);
  }

  function handleExit(goingBack = false) {
    history.push(goingBack ? `/lar_app/create${window.location.search}` : '/');
  }

  function handleSaveAndExit(goingBack = false) {
    onFormSave(handleExit(goingBack), false, true);
  }

  return (
    <div className={styles.root}>
      <Button type="text" icon={<LeftOutlined />} onClick={() => showExitPopup(true)} />
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.rightWrapper}>
        <div className={styles.progress}>
          <Progress
            percent={progressPercent}
            showInfo={false}
            strokeColor="#40A9FF"
            strokeWidth={15}
            type="circle"
            width={24}
          />
          <Progress
            percent={progressLAPercent}
            showInfo={false}
            strokeColor="#73d13d"
            strokeWidth={15}
            type="circle"
            width={24}
          />
          <div className={styles.progressPercents}>
            <div className={styles.progressTitle}>
              <span className={styles.progressScenario}>{progressPercent}%</span> scenario
            </div>
            <div className={styles.progressTitle}>
              <span className={styles.progressLA}>{progressLAPercent}%</span> auction
            </div>
          </div>
        </div>
        <Button onClick={() => showExitPopup(false)} size="large">
          Exit
        </Button>
        <Button onClick={() => onFormSave()} size="large" type="dark">
          Save
        </Button>
        {status !== ACTIVE && role !== PLA && (
          <Button onClick={handleSubmit} size="large" type="dark" loading={submitLoading}>
            Submit
          </Button>
        )}
      </div>
      <Modal
        className={styles.exitPopup}
        closable={false}
        footer={[
          <Button size="large" onClick={handleCancel}>
            Close
          </Button>,
          <Button size="large" type="dark" onClick={() => handleExit(popup?.data?.goingBack)}>
            {popup?.data?.goingBack ? 'Go back' : 'Exit'} without saving
          </Button>,
          <Button size="large" type="dark" onClick={() => handleSaveAndExit(popup?.data?.goingBack)}>
            Save and {popup?.data?.goingBack ? 'go back' : 'exit'}
          </Button>,
        ]}
        onCancel={handleCancel}
        onOk={handleSaveAndExit}
        open={popup.show === 'lar-app-exit-popup'}
        title="You have unsaved changes"
      >
        <p>Would you like to save your changes?</p>
      </Modal>
    </div>
  );
}

export default Header;
