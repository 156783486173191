import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { LARAPI } from 'api';
import { useLARDBContext } from 'pages/Onboarding/LARDB/hooks/LARDBContext';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import WithdrawModal from './WithdrawModal';
import classes from './styles/Reject.module.css';

export default function Reject() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { lar_id: id, lar_status: status } = useLARDBContext();
  const role = useSelector((store) => store.user.userData?.role);
  const history = useHistory();

  const API = useMemo(() => new LARAPI(id), [id]);

  function handleReject({ reason, additionalInfo }) {
    API.archive(reason, additionalInfo).then(() => {
      setIsModalOpen(false);
      history.push('/');
    });
  }

  const buttonText = useMemo(() => {
    if (/broker/i.test(role)) {
      if (/live auction/i.test(status)) {
        return '';
      }

      if (/incomplete|pending/i.test(status)) {
        return 'Delete';
      }

      if (!/loan processing/i.test(status)) {
        return 'Cancel';
      }
    }

    if (/lender/i.test(role)) {
      if (/loan processing/i.test(status)) {
        return 'Cancel';
      }
    }
  }, [role, status]);

  const reasons = useMemo(() => {
    if (/broker/i.test(role)) {
      if (/quote selection/i.test(status)) {
        return [
          'The quotes do not meet the borrower’s request',
          'The borrower chose a lender that is not a Starport member',
          'The borrower was non-responsive',
          'The lender fees are too high',
          'Borrower went with a different loan option',
          'Borrower lost the deal and does not need a loan',
          'Other',
        ];
      }

      if (/loan processing/i.test(status)) {
        return [
          'The borrower refused to complete the lender’s diligence requirements',
          'The borrower refused to complete the loan application',
          'The borrower selected a lender that is not a Starport member',
          'The lender’s revised loan quote was not accepted',
          'Borrower went with a different loan option',
          'Borrower lost the deal and does not need a loan',
          'Other',
        ];
      }
    }

    if (/lender/i.test(role)) {
      return [
        'The originator and/or borrower were non-responsive',
        'The borrower’s credit score was too low',
        'The borrower’s fund available were not sufficient',
        'Diligence revealed variances too large from the information provided on the loan profile',
        'Other',
      ];
    }
  }, [role, status]);

  const happensNext = useMemo(() => {
    if (/broker/i.test(role)) {
      if (/quote selection/i.test(status)) {
        return 'When you confirm, we will notify the lenders and move the deal to the archive. We will not notify the borrower.';
      }

      if (/loan processing/i.test(status)) {
        return 'When you confirm, we will notify the lender and move the deal to the archive. We will not notify the borrower.';
      }
    }

    if (/lender/i.test(role)) {
      return '“When you confirm, we will notify the originator and move the deal to the archive.”';
    }
  }, [role, status]);

  if (!buttonText) {
    return null;
  }

  return (
    <>
      <b>{buttonText}</b>
      <div className={classes.content}>
        <Button danger onClick={() => setIsModalOpen(true)}>
          {buttonText}
        </Button>
      </div>
      <WithdrawModal
        happensNext={happensNext}
        onCancel={() => setIsModalOpen(false)}
        onOk={handleReject}
        open={isModalOpen}
        reasons={reasons}
      />
    </>
  );
}
