import AuctionAuth from 'pages/Onboarding/AuctionDashboard/AuctionAuth';
import AuctionDashboard from 'pages/Onboarding/AuctionDashboard/AuctionDashboard';
import ConfirmAuctionWinner from 'pages/Onboarding/ConfirmAuctionWinner';
import Next from 'pages/Onboarding/AuctionDashboard/Next';

export const mainNavigationAuction = [
  {
    exact: true,
    path: '/winner-selection/log-in',
    main: () => <AuctionAuth type="login" />,
  },
  {
    exact: true,
    path: '/winner-selection/sign-up',
    main: () => <AuctionAuth type="sign-up" />,
  },
  {
    exact: true,
    path: '/winner-selection',
    main: AuctionDashboard,
  },
  {
    exact: true,
    path: '/winner-selection/confirm',
    main: ConfirmAuctionWinner,
  },
  {
    exact: true,
    path: '/winner-selection/next',
    main: Next,
  },
];
