import { message, Space } from 'antd';
import CustomButton from 'components/UI/Button';

const key = 'selected_items';

export default function selectedItems(count, btnHandlers, status, role, view) {
  const isBroker = /broker/i.test(role);
  const isLender = /lender/i.test(role);
  const isSuperAdmin = /superadmin/i.test(role);
  const isBorrower = /borrower/i.test(role);
  const [
    handleOpenBackReview,
    handleOpenLenderReview,
    handleDeleteSelectedItems,
    handleDownloadSelected,
    handleRestoreSelected,
    moveToLenderReady,
    moveToLenderReview,
  ] = btnHandlers;

  const visibleBtns = {
    download: {
      owed: isSuperAdmin,
      pending_verification: isBroker || isSuperAdmin,
      lender_ready: isBroker || isSuperAdmin,
      pending_lender_review: isLender || isSuperAdmin,
      lender_approve: isLender || isSuperAdmin,
      not_applicable: isSuperAdmin,
    },
    delete: {
      owed: isBroker || isLender || isSuperAdmin,
      pending_verification: isBroker || isSuperAdmin,
      lender_ready: isBroker || isSuperAdmin,
      pending_lender_review: isLender || isSuperAdmin,
      lender_approve: isLender || isSuperAdmin,
      not_applicable: false,
    },
    approve: {
      owed: isSuperAdmin || isBorrower,
      pending_verification: isBroker || isSuperAdmin,
      lender_ready: isBroker || isSuperAdmin,
      pending_lender_review: isLender || isSuperAdmin,
      lender_approve: false,
      not_applicable: false,
    },
    restore: {
      owed: false,
      pending_verification: false,
      lender_ready: false,
      pending_lender_review: false,
      lender_approve: false,
      not_applicable: isLender,
    },
    moveLenderReady: {
      owed: isBroker,
      pending_verification: false,
      lender_ready: false,
      pending_lender_review: false,
      lender_approve: false,
      not_applicable: false,
    },
    moveLenderReview: {
      owed: isBroker,
      pending_verification: false,
      lender_ready: false,
      pending_lender_review: false,
      lender_approve: false,
      not_applicable: false,
    },
    moveMLOReview: {
      owed: isBroker,
      pending_verification: false,
      lender_ready: false,
      pending_lender_review: false,
      lender_approve: false,
      not_applicable: false,
    },
    moveBack: ['lender_ready', 'lender_approve'].includes(status),
  };
  if (!count) {
    message.destroy(key);
  } else {
    message.info({
      icon: <></>,
      key,
      duration: 0,
      content: (
        <>
          <span style={{ marginLeft: 9 }}>
            <b>{count}</b> items selected
          </span>
          <Space style={{ marginLeft: 55 }}>
            {visibleBtns.download[status] && (
              <CustomButton onClick={handleDownloadSelected} primary style={{ fontSize: 14 }} text="Download" />
            )}
            {visibleBtns.delete[status] && (
              <CustomButton onClick={handleDeleteSelectedItems} primary style={{ fontSize: 14 }} text="Archive" />
            )}
            {visibleBtns.moveBack && (
              <CustomButton
                onClick={handleOpenBackReview}
                text="Move back to review"
                style={{ fontSize: 14 }}
                primary
              />
            )}
            {visibleBtns.approve[status] && (
              <CustomButton
                onClick={handleOpenLenderReview}
                style={{ fontSize: 14 }}
                text={
                  status === 'lender_ready' ? 'Move to lender review' : status === 'lender_ready' ? 'Submit' : 'Accept'
                }
                primary
              />
            )}
            {visibleBtns.restore[status] && (
              <CustomButton onClick={handleRestoreSelected} text="Restore" style={{ fontSize: 14 }} primary />
            )}
            {view && (
              <>
                {visibleBtns.moveMLOReview[status] && (
                  <CustomButton
                    onClick={handleOpenLenderReview}
                    text="Move to MLO Review"
                    style={{ fontSize: 14 }}
                    primary
                  />
                )}
                {visibleBtns.moveLenderReady[status] && (
                  <CustomButton
                    onClick={moveToLenderReady}
                    text="Move to Lender Ready"
                    style={{ fontSize: 14 }}
                    primary
                  />
                )}
                {visibleBtns.moveLenderReview[status] && (
                  <CustomButton
                    onClick={moveToLenderReview}
                    text="Move to Lender Review"
                    style={{ fontSize: 14 }}
                    primary
                  />
                )}
              </>
            )}
          </Space>
        </>
      ),
      className: 'diligence-list__selected-items-msg',
      style: {
        marginTop: '85vh',
        marginLeft: '208px',
      },
    });
  }
}
