import { Typography } from 'antd';

function DealExpirationNote() {
  return (
    <>
      <Typography.Paragraph>
        Deals remain active for 7 business days and then they are automatically archived.
      </Typography.Paragraph>
      <Typography.Paragraph>
        You can select to <strong style={{ fontWeight: 'bold' }}>“Extend”</strong> the deal for an additional 3 business
        days.
      </Typography.Paragraph>
      <Typography.Paragraph>
        <strong style={{ fontWeight: 'bold' }}>What you can do when a deal is in Active Status:</strong>
        <ol type="1">
          <li>Accept a quote</li>
          <li>Archive the deal</li>
          <li>Extend the deal</li>
        </ol>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <strong style={{ fontWeight: 'bold' }}>How are the days counted?</strong>
        <br /> For scenarios, the count began when lenders were invited. For auctions, the count begins when the arena
        auction finishes, providing you 7 days to choose a winning quote.
      </Typography.Paragraph>
    </>
  );
}

export default DealExpirationNote;
