import { ClockIcon } from 'assets/icons/icons';
import styles from './AuctionWaitingWidget.module.css';

function AuctionWaitingWidget() {
  return (
    <div className={styles.block}>
      <div className={styles.wrapper}>
        <ClockIcon className={styles.icon} />
        <span className={styles.label}>Waiting for quotes</span>
      </div>
    </div>
  );
}

export default AuctionWaitingWidget;
