import mortgageLoanSVG from 'assets/images/MortgageLoan.svg';
import classes from './styles/Placeholder.module.css';

export default function Placeholder() {
  return (
    <div className={classes.root}>
      <img src={mortgageLoanSVG} alt="" />
      <b>There are no responses yet</b>
      <div>
        Please wait for lenders to take a look
        <br />
        and respond to your scenario.
      </div>
    </div>
  );
}
