import { useContext, useState } from 'react';
import CustomSearchInput from '../../../CustomComponents/CustomSearchInput';
import LARDBContext from '../../../../pages/Onboarding/LARDB/LARDBContext';

export default function SearchBox({ setViewDiligence }) {
  const [findItemData, setFindItemData] = useState([]);
  const [ready, setReady] = useState(true);
  const { dd } = useContext(LARDBContext);

  function openDiligencePopup(itemId) {
    const foundItem = dd.dd_center_items.find((i) => itemId === i.id);

    if (foundItem) {
      setViewDiligence(foundItem);
      return;
    }

    dd.API.getItem(itemId).then(({ data }) => setViewDiligence(data));
  }

  function searchItems(query) {
    setReady(false);

    dd.API.search(query).then(({ data }) => {
      const resultsUI = data.dd_center_items.map((item) => (
        <div className="custom-search__result-item" onClick={() => openDiligencePopup(item.id)} key={item.id}>
          {item.item_name}
          <span style={{ float: 'right', marginLeft: 30 }}>
            id:
            {item.id}
          </span>
        </div>
      ));

      setFindItemData(resultsUI);
      setReady(true);
    });
  }

  return (
    <CustomSearchInput
      clearFunc={() => setFindItemData([])}
      enterButton
      loading={!ready}
      placeholder="Search"
      results={findItemData}
      searchFunc={searchItems}
    />
  );
}
