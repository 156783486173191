import '../Lender/styles/registration.css';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import API from 'api';
import Button from 'components/UI/Button';
import fillError from 'constants/fillRegistrationErrors';
import fillFields from 'constants/fillRegistrationFields';
import otherConst from 'constants/otherConst';
import RegistrationSubHeader from 'components/Registration/RegistrationSubHeader';
import RenderInputs from 'components/RenderInputs';
import CustomButton from 'components/UI/Button';
import Modal from '../../../components/Modals/Modal/Modal';
import { closePopup } from '../../../store/actions/popupsActions';

const initialFields = {
  sectionTitle_0: {
    title: 'Сreate Company Profile',
    style: {
      fontWeight: 400,
    },
  },
  dividedInputs_1: {
    mobileLayout: true,
    inputs: {
      name: {
        value: '',
        error: null,
        errorMessage: 'Fill this label',
        title: 'Company name',
      },
    },
  },
  upload_logo: {
    value: null,
    file: null,
    error: null,
    errorMessage: 'Fill this label',
    optional: true,
    title: 'Company logo',
    buttonText: 'Upload a logo',
  },
  sectionTitle_1: {
    title: 'Company address',
    type: 'heading-20',
    style: {
      margin: '32px 0 24px',
    },
  },
  dividedInputs_2: {
    inputs: {
      city: {
        value: '',
        error: null,
        errorMessage: 'Fill this label',
        title: 'City',
      },
      state: {
        value: '',
        type: 'select',
        error: null,
        errorMessage: 'Fill this label',
        title: 'State',
        options: otherConst.states,
      },
    },
  },
  dividedInputs_3: {
    inputs: {
      zip: {
        value: '',
        regex: '^[0-9]*$',
        error: null,
        maxLength: 5,
        errorMessage: 'Please enter 5 symbols',
        title: 'Zip',
      },
    },
  },
  nmls: {
    title: 'NMLS (optional)',
    value: '',
    regex: '^[0-9]{5,7}$',
    error: null,
    errorMessage: 'Fill this label',
    optional: true,
  },
};

export default function CompanyProfile() {
  const [disabledAction, setDisabledAction] = useState(true);
  const [doneTabs, setDoneTabs] = useState({ company: false, profile: false });
  const [edit, setEdit] = useState(null);
  const [fields, setFields] = useState(initialFields);
  const history = useHistory();
  const location = useLocation();
  const activeExitModal = useSelector((state) => state.popups.show);
  const dispatch = useDispatch();

  const role = useSelector((store) => store.user.userData?.role);
  const progress = [
    {
      title: 'Company Profile',
      completed: !disabledAction,
      active: true,
    },
    {
      title: 'Personal Profile',
      completed: doneTabs.profile,
      onClick: () => onSave('link', `/registration/broker/profile${location.search}` ?? ''),
      clickCondition: !disabledAction || doneTabs.company,
    },
  ];

  useEffect(() => {
    const success_fee = fields.iharmoni_success_fee?.value;
    const default_fee = fields.default_broker_fee?.value;

    setFields((state) => ({
      ...state,
      success_fee_compensation: {
        ...state.success_fee_compensation,
        value: (+success_fee || 0) + (+default_fee || 0),
      },
    }));
    // eslint-disable-next-line
  }, [fields.iharmoni_success_fee?.value, fields.default_broker_fee?.value]);

  const autocompleteAddressFields = (address, city, state, zip) => {
    onChange({ target: { value: address } }, 'street_address');
    onChange({ target: { value: city } }, 'dividedInputs_2.inputs.city');
    onChange({ target: { value: state.value ?? state } }, 'dividedInputs_2.inputs.state');
    onChange({ target: { value: zip } }, 'dividedInputs_3.inputs.zip');
  };

  const onChange = (event, route) => {
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const { value } = event.target;

    setFields((state) => {
      const tempFields = { ...state };

      if (routeLength === 3) {
        tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
          ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
          value,
          error: false,
        };
      } else if (routeLength === 2) {
        tempFields[routeArr[0]][routeArr[1]] = {
          ...tempFields[routeArr[0]][routeArr[1]],
          value,
          error: false,
        };
      } else if (routeLength === 1) {
        tempFields[routeArr[0]] = { ...tempFields[routeArr[0]], value, error: false };
      }

      return tempFields;
    });
  };

  const selectTag = (value, route) => {
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const tempFields = { ...fields };

    if (routeLength === 3) {
      const nestedValue = tempFields[routeArr[0]][routeArr[1]][routeArr[2]];
      tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
        ...nestedValue,
        tags: [...nestedValue.tags, value],
        error: false,
      };
    } else if (routeLength === 2) {
      const nestedValue = tempFields[routeArr[0]][routeArr[1]];
      tempFields[routeArr[0]][routeArr[1]] = {
        ...nestedValue,
        tags: [...nestedValue.tags, value],
        error: false,
      };
    } else if (routeLength === 1) {
      const nestedValue = tempFields[routeArr[0]];
      tempFields[routeArr[0]] = {
        ...nestedValue,
        tags: [...nestedValue.tags, value],
        error: false,
      };
    }

    setFields(tempFields);
  };

  const onTagDelete = (item, route) => {
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const tempFields = { ...fields };

    if (routeLength === 3) {
      const nestedValue = tempFields[routeArr[0]][routeArr[1]][routeArr[2]];
      tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
        ...nestedValue,
        tags: nestedValue.tags.filter((i) => i !== item),
      };
    } else if (routeLength === 2) {
      const nestedValue = tempFields[routeArr[0]][routeArr[1]];
      tempFields[routeArr[0]][routeArr[1]] = {
        ...nestedValue,
        tags: nestedValue.tags.filter((i) => i !== item),
      };
    } else if (routeLength === 1) {
      const nestedValue = tempFields[routeArr[0]];
      tempFields[routeArr[0]] = {
        ...nestedValue,
        tags: nestedValue.tags.filter((i) => i !== item),
      };
    }

    setFields(tempFields);
  };

  const onUpload = (event, route) => {
    event.preventDefault();
    const file = event.target.files[0];
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const tempFields = { ...fields };

    if (!file) {
      return;
    }

    // WTF is this
    if (routeLength === 3) {
      tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
        ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
        value: URL.createObjectURL(file),
        file,
      };
    } else if (routeLength === 2) {
      tempFields[routeArr[0]][routeArr[1]] = {
        ...tempFields[routeArr[0]][routeArr[1]],
        value: URL.createObjectURL(file),
        file,
      };
    } else if (routeLength === 1) {
      tempFields[routeArr[0]] = {
        ...tempFields[routeArr[0]],
        value: URL.createObjectURL(file),
        file,
      };
    }

    setFields(tempFields);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const then = (res) => {
      setEdit(res.data.actions.includes('Edit'));
      fillFields(res.data, false, fields, setFields);
      window.scrollTo(0, 0);
    };

    const applyTabs = (res) => {
      const { company_is_full, profile_is_full } = res.data;

      setDoneTabs({
        company: company_is_full,
        profile: profile_is_full,
      });
    };

    const user_id = urlParams.get('id');
    if (user_id) {
      API()
        .get('/prospects/BDR/check_status', { params: { id: Number(user_id) } })
        .then(applyTabs);
      API()
        .get('/prospects/BDR/company', { params: { id: Number(user_id) } })
        .then((res) => {
          then(res);
          if (res.data.individual_company) {
            setFields((state) => {
              const temp = { ...state };
              temp.sectionTitle_0.title = 'Create Sole Proprietor Profile';
              temp.radioButtons_brokerage_size_name.value = '1';
              temp.radioButtons_brokerage_size_name.disabled = true;
              temp.radioButtons_brokerage_size_name.buttons = {
                size1: {
                  value: '1',
                  name: '1',
                },
              };
              return temp;
            });
          }
        });
    } else {
      API().get('/prospects/BDR/check_status').then(applyTabs);
      API()
        .get('/prospects/BDR/company')
        .then((res) => {
          then(res);
          if (res.data.individual_company) {
            setFields((state) => {
              const temp = { ...state };
              temp.sectionTitle_0.title = 'Create Sole Proprietor Profile';
              temp.radioButtons_brokerage_size_name.value = '1';
              temp.radioButtons_brokerage_size_name.disabled = true;
              temp.radioButtons_brokerage_size_name.buttons = {
                size1: {
                  value: '1',
                  name: '1',
                },
              };
              return temp;
            });
          }
        });
    }

    // eslint-disable-next-line
  }, []);

  const onError = (routes) => {
    const tempFields = { ...fields };

    routes.forEach((route) => {
      const routeArr = route.split('.');
      const routeLength = routeArr.length;
      if (routeLength === 3) {
        tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
          ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
          error: true,
        };
      } else if (routeLength === 2) {
        tempFields[routeArr[0]][routeArr[1]] = {
          ...tempFields[routeArr[0]][routeArr[1]],
          error: true,
        };
      } else if (routeLength === 1) {
        if (routeArr[0] === 'year_established') {
          tempFields[routeArr[0]] = {
            ...tempFields[routeArr[0]],
            error: true,
            errorMessage:
              +tempFields[routeArr[0]].value < 1980 ? 'Minimal year is 1980' : tempFields[routeArr[0]].errorMessage,
          };
        } else {
          tempFields[routeArr[0]] = { ...tempFields[routeArr[0]], error: true };
        }
      }
    });

    setFields(tempFields);
  };

  useEffect(() => {
    let unfilledFieldsCount = 0;
    Object.entries(fields).forEach((i) => {
      const field = i[1];
      if (i[0].includes('dividedInputs')) {
        Object.entries(field.inputs).forEach((item) => {
          const input = item[1];
          if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
            unfilledFieldsCount += 1;
          } else if (item[0] === 'zip' && input.value.length < 5) {
            unfilledFieldsCount += 1;
          }
        });
      } else if (i[0].includes('radioButtons')) {
        // eslint-disable-line
      } else if (i[0].includes('switchButtons')) {
        // eslint-disable-line
      } else if (i[0].includes('selectTags')) {
        if (!i[1].optional && !i[1].tags.length) {
          unfilledFieldsCount += 1;
        }
      } else if (i[0].includes('upload')) {
        const upload = i[1];
        if (!upload.optional && !upload.file && !upload.value) {
          unfilledFieldsCount += 1;
        }
      } else if (i[0].includes('checkbox')) {
        // eslint-disable-line
      } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle')) {
        // eslint-disable-line
      } else {
        const input = i[1];
        if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
          unfilledFieldsCount += 1;
        }
      }
    });

    setDisabledAction(!!unfilledFieldsCount);
  }, [fields]);

  const onSave = (type, link) => {
    const request = {};
    const temp = { ...fields };

    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get('id');

    if (!temp.dividedInputs_1.inputs.name.value) {
      onError(['dividedInputs_1.inputs.name']);
      return;
    }

    if (disabledAction && type === 'submit') {
      const routes = [];
      Object.entries(temp).forEach((i) => {
        const field = i[1];
        if (i[0].includes('dividedInputs')) {
          Object.entries(field.inputs).forEach((item) => {
            const input = item[1];
            if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
              routes.push(`${i[0]}.inputs.${item[0]}`);
            } else if (
              (item[0] === 'year_established' && input.value.length < 4 && type !== 'save') ||
              (type === 'save' && input.value.length > 0 && input.value.length < 4)
            ) {
              routes.push(`${i[0]}`);
            } else if (
              (item[0] === 'zip' && input.value.length < 5 && type !== 'save') ||
              (type === 'save' && input.value.length > 0 && input.value.length < 5)
            ) {
              routes.push(`${i[0]}.inputs.${item[0]}`);
            }
          });
        } else if (i[0].includes('radioButtons')) {
          // eslint-disable-line
        } else if (i[0].includes('switchButtons')) {
          // eslint-disable-line
        } else if (i[0].includes('selectTags')) {
          if (!i[1].optional && !i[1].tags.length) {
            routes.push(`${i[0]}`);
          }
        } else if (i[0].includes('upload')) {
          // eslint-disable-line
        } else if (i[0].includes('checkbox')) {
          // eslint-disable-line
        } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle')) {
          // eslint-disable-line
        } else {
          const input = i[1];
          if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
            routes.push(`${i[0]}`);
          } else if (
            (i[0] === 'year_established' && input.value.length < 4 && type !== 'save') ||
            (type === 'save' && input.value.length > 0 && input.value.length < 4)
          ) {
            routes.push(`${i[0]}`);
          }
        }
      });
      onError(routes);
      window.scrollTo(0, 0);
      return;
    }

    Object.entries(temp).forEach((i) => {
      const field = i[1];
      if (i[0].includes('dividedInputs')) {
        Object.entries(field.inputs).forEach((item) => {
          if (
            !item[1].skip &&
            ((item[1].type === 'number' && (item[1].value || item[1].value === 0)) || item[1].type !== 'number')
          ) {
            request[item[0]] = item[1].value;
          }
        });
      } else if (i[0].includes('radioButtons')) {
        if (!i[1].skip) {
          const fieldName = i[0].split('_').slice(1).join('_');
          request[fieldName] = field.value;
        }
      } else if (i[0].includes('switchButtons')) {
        Object.entries(field.switches).forEach((item) => {
          if (!item[1].skip) {
            request[item[0]] = item[1].value;
          }
        });
      } else if (i[0].includes('selectTags')) {
        if (!i[1].skip) {
          const fieldName = i[0].split('_').slice(1).join('_');
          request[fieldName] = i[1].tags.join(', ');
        }
      } else if (i[0].includes('upload')) {
        if (!i[1].skip && i[1].file) {
          const fieldName = i[0].split('_').slice(1).join('_');
          request[fieldName] = i[1].file;
        }
      } else if (i[0].includes('checkbox')) {
        Object.entries(field.boxes).forEach((item) => {
          if (!item[1].skip) {
            request[item[0]] = item[1].value;
          }
        });
      } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle')) {
        // eslint-disable-line
      } else if (
        !i[1].skip &&
        ((i[1].type === 'number' && (i[1].value || i[1].value === 0)) || i[1].type !== 'number')
      ) {
        request[i[0]] = field.value;
      }
    });

    const FD = new FormData();

    Object.entries(request).forEach((i) => {
      if (i[0] !== 'year_established' || (i[0] === 'year_established' && i[1])) {
        FD.append(i[0], typeof i[1] === 'boolean' ? (i[1] ? 1 : 0) : i[1]);
      }
    });

    if (user_id) {
      FD.append('id', user_id);
    }

    API()
      .post('/prospects/BDR/company', FD)
      .then(({ data }) => {
        if (data.error) {
          return;
        }

        if (type === 'submit') {
          return history.push(`/registration/broker/profile${user_id ? `?id=${user_id}` : ''}`);
        }

        if (type === 'link') {
          return history.push(link);
        }

        message.success('Broker Company Profile data successfully saved!');
      })
      .catch(({ response }) => {
        const errors = response.data['wrong columns'] || [];
        const errorObj = {};

        errors.forEach((i) => {
          Object.entries(i).forEach((i) => {
            errorObj[i[0]] = i[1];
          });
        });

        window.scrollTo(0, 0);
        console.error(errorObj);
        fillError(errorObj, true, fields, setFields);
      });
  };

  const handleExit = () => {
    history.push('/');
    dispatch(closePopup());
  };

  const handleSaveAndExit = () => {
    onSave('link', '/');
    dispatch(closePopup());
  };

  const onCloseActiveModal = () => {
    dispatch(closePopup());
  };

  const nextStep = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get('id');

    const url = user_id ? `/registration/broker/profile?id=${user_id}` : '/registration/broker/profile';
    history.push(url);
  };

  return (
    <div className="registration lender-register">
      <RegistrationSubHeader
        logo="BrokerCorp"
        maxWidth="840px"
        progress={progress}
        actions={
          edit
            ? [
                <Button key={1} text="Save" onClick={() => onSave('save')} className="btn_darkblue" />,
                <Button key={2} text="Next" onClick={() => onSave('submit')} className="btn_darkblue" />,
              ]
            : edit === false
            ? [<Button key={1} text="Next" onClick={nextStep} className="btn_darkblue" />]
            : []
        }
      />
      <div className="registration-content">
        <RenderInputs
          edit={edit}
          data={fields}
          onTagDelete={onTagDelete}
          selectTag={selectTag}
          onChange={onChange}
          onUpload={onUpload}
          autocompleteAddressFields={autocompleteAddressFields}
        />
      </div>
      {/^bdr$/i.test(role) && (
        <Modal
          footer={
            <>
              <CustomButton onClick={handleExit} text="Exit" />
              <CustomButton onClick={handleSaveAndExit} primary text="Save progress & exit" />
            </>
          }
          onCancel={onCloseActiveModal}
          visible={activeExitModal === 'exit'}
        >
          Exit without saving progress?
        </Modal>
      )}
    </div>
  );
}
