import { ArchiveIcon, PencilIcon } from 'assets/icons';
import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Input, Menu, Tooltip } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import CustomButton from 'components/UI/Button';
import LARDBContext from 'pages/Onboarding/LARDB/LARDBContext';
import diligenceStatuses, { getPrettyStatusName, getStatusColour } from '../constants/diligenceStatuses';
import classes from './styles/Header.module.css';
import { getPrettySubStatusName, getSubStatusColour } from '../constants/diligenceSubStatuses';
import MoveToStatusModal from '../MoveToStatusModal';
import { PLA } from 'constants/roles';

export default function Header({
  changeEditField,
  closeDiligenceItem,
  deleteItem,
  editFields,
  isUnderwriting,
  setViewDiligence,
  status,
  submitEditField,
  viewDiligence,
}) {
  const [moveToStatusPopup, setMoveToStatusPopup] = useState({
    newStatus: '',
    visible: false,
  });
  const { dd } = useContext(LARDBContext);
  const role = useSelector((store) => store.user.userData?.role);

  function toggleMoveToStatusPopup(newStatus) {
    setMoveToStatusPopup((prev) => ({
      ...prev,
      newStatus,
      visible: !prev.visible,
    }));
  }

  function submitMoveToStatus(step, reason) {
    if (!reason) {
      return;
    }

    dd.API.moveItems([viewDiligence.id], step, reason).then(() => closeDiligenceItem(true));
  }

  const menu = useMemo(
    () => (
      <Menu
        items={diligenceStatuses
          .map((s) => s.value)
          .filter((s) => s !== viewDiligence?.item_stage)
          .map((s) => ({
            label: getPrettyStatusName(s),
            onClick: () => toggleMoveToStatusPopup(s),
          }))}
      />
    ),
    [viewDiligence],
  );

  const subStatus = useMemo(() => {
    if (viewDiligence.item_stage !== 'owed') {
      return null;
    }

    return {
      colour: getSubStatusColour(viewDiligence.item_sub_stage),
      label: getPrettySubStatusName(viewDiligence.item_sub_stage),
    };
  }, [viewDiligence.item_stage, viewDiligence.item_sub_stage]);

  if (!viewDiligence) {
    return null;
  }

  function saveTitle(e) {
    e?.preventDefault();

    if (!editFields.item_name) {
      changeEditField('item_name_error', true);
      return;
    }

    setViewDiligence((state) => ({
      ...state,
      item_name: editFields.item_name,
    }));

    submitEditField({ item_name: editFields.item_name }).then(() => changeEditField('item_name'));
  }

  return (
    <div className={classes.root}>
      {editFields.item_name !== undefined ? (
        <form className={editFields.item_name_error ? 'edit-field-error' : undefined} onSubmit={saveTitle}>
          <Input onChange={({ target }) => changeEditField('item_name', target.value)} value={editFields.item_name} />
          <CustomButton onClick={() => changeEditField('item_name')} text="Cancel" />
          <CustomButton onClick={saveTitle} primary text="Save" />
        </form>
      ) : (
        <div className={classes.titleWrapper}>
          <span>{viewDiligence.item_name}</span>
          {/* TODO: This permission will come from backend in available_actions as rename_item */}
          {/borrower/i.test(role) || (
            <Tooltip title="Edit title">
              <button
                aria-label="editTitle"
                className={classes.actionButton}
                onClick={() => changeEditField('item_name', viewDiligence.item_name)}
              >
                <PencilIcon size="1.5em" />
              </button>
            </Tooltip>
          )}
          {undefined !== deleteItem && role !== PLA && (
            <Tooltip title={/not_applicable/i.test(status) ? 'Remove this item from archive' : 'Archive this item'}>
              <button aria-label="switchActionArchive" className={classes.actionButton} onClick={deleteItem}>
                <ArchiveIcon size="1.5em" />
              </button>
            </Tooltip>
          )}
        </div>
      )}
      {viewDiligence.item_stage && !isUnderwriting && (
        <>
          {role !== PLA && (
            <div className={classes.statusWrapper}>
              <span>Stage:</span>
              <Dropdown
                disabled={!viewDiligence.available_actions.includes('move_item')}
                overlay={menu}
                placement="bottomLeft"
              >
                <span
                  className={classNames(
                    classes.statusBadge,
                    classes[`${getStatusColour(viewDiligence.item_stage)}StatusBadge`],
                  )}
                >
                  <span>{getPrettyStatusName(viewDiligence.item_stage)}</span>
                  {viewDiligence.available_actions.includes('move_item') && <CaretDownOutlined />}
                </span>
              </Dropdown>
            </div>
          )}

          {subStatus && (
            <div className={classes.statusWrapper}>
              <span>Substage:</span>
              <span className={classNames(classes.statusBadge, classes[`${subStatus.colour}StatusBadge`])}>
                {subStatus.label}
              </span>
            </div>
          )}
        </>
      )}
      <MoveToStatusModal
        newStatus={moveToStatusPopup.newStatus}
        onCancel={toggleMoveToStatusPopup}
        onSubmit={submitMoveToStatus}
        visible={moveToStatusPopup.visible}
      />
    </div>
  );
}
