import { Select } from 'antd';
import { useSelector } from 'react-redux';

const sortingOptions = {
  largest_loan_amount: 'Loan Amount',
  last_update: 'Most recent',
  lowest_interest_rate: 'Lowest Interest Rate',
};

function BidTableStartItem({ handleChangeBidsOrder, orderBy, bidValuesData }) {
  const role = useSelector((s) => s.user.userData?.role);
  const isBroker = /broker/i.test(role);

  return (
    <div className="bid-table-list__list-item bid-table-list__list-item_starter">
      <div className="bid-table-list__list-item-header bid-table-list__list-item-header_starter-item">
        <div className="bid-table-list__list-item-select">
          {isBroker && (
            <Select onChange={handleChangeBidsOrder} placeholder="Sort by" style={{ width: '100%' }} value={orderBy}>
              {Object.entries(sortingOptions).map(([id, label]) => (
                <Select.Option value={id} key={id}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </div>
      <div className="bid-table-list__list-item-body">
        <div className="bid-table-list__info-block">
          {bidValuesData.map((el, id) => (
            <div className="bid-table-list__info-block-item bid-table-list__info-block-item_label" key={el.name + id}>
              {el.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BidTableStartItem;
