import 'antd/dist/antd.css';
import 'assets/styles/global.css';
import 'swiper/swiper-bundle.min.css';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals';
import App from 'App';

ReactDOM.render(
  <Provider store={store}>
    <StrictMode>
      <App />
    </StrictMode>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
