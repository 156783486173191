import { useHistory } from 'react-router-dom';
import React from 'react';
import { ThanksForRegistrationIcon } from '../../../assets/icons/icons';
import Button from '../../../components/UI/Button';
import SectionTitle from '../../../components/OnBoarding/SectionTitle';

export default function LenderExpired({ hideEdit = false }) {
  const history = useHistory();

  function btnClick() {
    if (window.location.pathname.includes('broker')) {
      history.push(`/registration/broker/company${window.location.search}`);
    } else {
      history.push(`/registration/lender/company${window.location.search}`);
    }
  }

  return (
    <div className="wrapper_lender_registration">
      <div className="thanks-wrapper">
        <SectionTitle title="Thank you for registration!" />
        <ThanksForRegistrationIcon />
        <b>
          <SectionTitle title="Your account is under review" type="body-16" />
        </b>
        <span style={{ marginTop: '8px' }}>
          We are reviewing your current application info and looking forward working with you
        </span>
        {!hideEdit && (
          <>
            <span className="bordered">You can edit your application during this time</span>
            <Button text="Edit application" onClick={btnClick} className="btn_darkblue" />
          </>
        )}
      </div>
    </div>
  );
}
