import { ArrowBarIcon } from 'assets/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import styles from './LiveAuctionsHeader.module.css';

export default function LiveAuctionsHeader({
  isShowNav = true,
  onSort,
  onToggleNav,
  sortOptions,
  activeSortingOption,
  setActiveSortingOption,
}) {
  // const [activeSortingOption, setActiveSortingOption] = useState(sortOptions[0]?.name);

  const handleClick = (v) => () => {
    setActiveSortingOption(v);
    onSort(v);
  };

  return (
    <header className={styles.root}>
      <h1 className={styles.title}>Live Auctions</h1>
      <div className={styles.buttonsBar}>
        {isShowNav && (
          <>
            <span>Sort by</span>
            <div className={styles.sortingBox}>
              {sortOptions.map(({ name, Icon }) => (
                <Button
                  key={name}
                  size="large"
                  icon={<Icon size="1.3em" colour={name === activeSortingOption ? 'brand-5' : 'grey-7'} />}
                  onClick={handleClick(name)}
                />
              ))}
            </div>
          </>
        )}
        <Button
          size="large"
          icon={<ArrowBarIcon size="1.3em" direction={isShowNav ? 'left' : 'right'} />}
          onClick={onToggleNav}
        />
      </div>
    </header>
  );
}

LiveAuctionsHeader.propTypes = {
  isShowNav: PropTypes.bool,
  onSort: PropTypes.func,
  onToggleNav: PropTypes.func,
};
