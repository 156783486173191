import './styles/popup.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Modal as AntdModal, message } from 'antd';
import { TransformWrapper } from 'react-zoom-pan-pinch';
import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import API from 'api';
import DocumentViewer from 'components/OnBoarding/DocumentViewer';
import Modal from 'components/Modals/Modal';
import Header from './Header';
import { BidTable, CompleteDocument, QuestionForm, RegularDocument } from './flavours';
import PopupTabs from './PopupTabs';

const documentTypes = new Map([
  ['completed', { Component: CompleteDocument }],
  ['disclosures', { Component: BidTable }],
  ['final_approval', { Component: BidTable }],
  ['lender_loan_documents', { Component: RegularDocument }],
  ['loan_decisions_and_conditions', { Component: RegularDocument }],
  ['question_or_form', { Component: QuestionForm }],
  ['regular', { Component: RegularDocument }],
  ['revised_loan_quote', { Component: RegularDocument }],
  ['underwriting', { Component: RegularDocument }],
]);

export default function DiligenceItemPopup({
  isUnderwriting = false,
  larData,
  onCloseCallback = () => null,
  openManualEmsModal = () => null,
  setViewDiligence,
  status = '',
  updateDiligenceList,
  viewDiligence,
}) {
  const [editFields, setEditFields] = useState({
    item_description: undefined,
    item_name: undefined,
    key_fields: [],
  });
  const [fullscreenViwer, setFullscreenViewer] = useState({
    mime: null,
    uri: null,
    visible: false,
  });
  const [modal, setModal] = useState({ visible: null });
  const role = useSelector((store) => store.user.userData?.role);
  const [placeBidEdit, setPlaceBidEdit] = useState(false);

  function changeEditField(name, value) {
    setEditFields((prev) => ({
      ...prev,
      [name]: value,
      [`${name}_error`]: false,
    }));
  }

  async function submitEditField(fields, popupText = 'Field saved successfully') {
    const formData = new FormData();

    for (const [key, value] of Object.entries(fields)) {
      formData.append(key, value);
    }

    formData.append('item_id', viewDiligence.id);

    return API()
      .post('/LAR/dd_center/item/upload', formData)
      .then(({ data }) => {
        message.success(popupText);
        return data;
      });
  }

  function closeDiligenceItem(update = false) {
    setViewDiligence(null);
    update && updateDiligenceList();
    onCloseCallback();
  }

  // Dynamically returns a fitting component based on diligence item type
  const DocumentComponent = useMemo(
    () => documentTypes.get(viewDiligence?.item_type)?.Component || (() => null),
    [viewDiligence?.item_type],
  );

  function deleteItem() {
    API()
      .post('/LAR/dd_center/item/archive', {
        item_id: viewDiligence.id,
      })
      .then(() => {
        setModal({ visible: null });
        closeDiligenceItem(true);
      })
      .catch(({ response }) => message.error(response.data.error));
  }

  useEffect(() => {
    if (undefined === viewDiligence?.id) {
      return;
    }

    const currentDate = +new Date();
    const sessionStorageKey = 'diligenceItemViewsRegister';
    const viewsRegister = JSON.parse(sessionStorage.getItem(sessionStorageKey) || '{}');

    // Timeout is 15 minutes
    if (viewDiligence.id in viewsRegister && currentDate - viewsRegister[viewDiligence.id] < 9e5) {
      return;
    }

    API().post('/LAR/dd_center/item/view', { item_id: viewDiligence.id });

    sessionStorage.setItem(
      sessionStorageKey,
      JSON.stringify({
        ...viewsRegister,
        [viewDiligence.id]: currentDate,
      }),
    );
  }, [viewDiligence?.id]);

  return (
    <AntdModal
      centered
      className="diligence-document-view"
      destroyOnClose
      footer={null}
      onCancel={closeDiligenceItem}
      title={
        <Header
          changeEditField={changeEditField}
          closeDiligenceItem={closeDiligenceItem}
          deleteItem={
            viewDiligence?.available_actions.includes('archive') ? () => setModal({ visible: 'delete' }) : undefined
          }
          editFields={editFields}
          isUnderwriting={isUnderwriting}
          setViewDiligence={setViewDiligence}
          status={status}
          submitEditField={submitEditField}
          viewDiligence={viewDiligence}
        />
      }
      visible={viewDiligence !== null}
      width={viewDiligence && viewDiligence?.item_type === 'bid_terms_updates' ? '100%' : '90%'}
    >
      {viewDiligence && (
        <div className="diligence-content-wrapper">
          {fullscreenViwer.visible && (
            <div className="fullscreen-wrapper">
              <TransformWrapper wheel={{ step: 0.1 }}>
                {(transformWrapperParams) => (
                  <DocumentViewer
                    activeDocument={fullscreenViwer.uri}
                    arrowsColor="white"
                    errorHeight={600}
                    hasZoomIn={{
                      setFullscreen: (visible) => {
                        setFullscreenViewer((prev) => ({
                          ...prev,
                          visible,
                        }));
                      },
                      ...transformWrapperParams,
                    }}
                    isImage={fullscreenViwer.mime?.split('/')[0] === 'image'}
                    loading={
                      <div className="doc-loading">
                        <LoadingOutlined style={{ fontSize: 108 }} />
                      </div>
                    }
                    scale={0.9}
                  />
                )}
              </TransformWrapper>
            </div>
          )}
          <DocumentComponent
            editFields={editFields}
            openManualEmsModal={openManualEmsModal}
            setEditFields={setEditFields}
            setPlaceBidEdit={setPlaceBidEdit}
            diligenceMode
            placeBidEdit={placeBidEdit}
            setViewDiligence={setViewDiligence}
            viewDiligence={viewDiligence}
            viewFullscreen={(uri, mime) => setFullscreenViewer({ visible: true, uri, mime })}
            closeDiligenceItem={closeDiligenceItem}
            isUnderwriting={isUnderwriting}
            role={role}
          />
          <PopupTabs
            changeEditField={changeEditField}
            editFields={editFields}
            placeBidEdit={placeBidEdit}
            setPlaceBidEdit={setPlaceBidEdit}
            larData={larData}
            role={role}
            jointInfo={isUnderwriting}
            onDiligenceCancel={closeDiligenceItem}
            setEditFields={setEditFields}
            setViewDiligence={setViewDiligence}
            updateDiligenceList={updateDiligenceList}
            submitEditField={submitEditField}
            viewDiligence={viewDiligence}
          />
        </div>
      )}
      <Modal
        onCancel={() => setModal({ visible: null })}
        onOk={deleteItem}
        visible={modal.visible === 'delete'}
      >{`Are you sure you want to ${
        /not_applicable/i.test(status) ? 'remove this item from archive' : 'archive this item'
      }?`}</Modal>
    </AntdModal>
  );
}
