import { capitalizeAll } from 'constants/capitalizeFirstLetter';
import { Col, Divider, Form, Input, message, Radio, Row, Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import API from 'api';
import Button from 'components/UI/Button';
import SearchBox from 'components/OnBoarding/Masterlist/SearchBox';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import EditMasterlistTable from './EditMasterlistTable';

const styles = {
  wrap: {
    border: '1px solid #CBD5E1',
    borderRadius: 8,
  },
};

function NoDocumentsInformer({ goToDoc }) {
  const styles = {
    wrap: {
      padding: 15,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inner: {
      background: '#FAFAFA',
      border: '1px solid #D7D3F8',
      borderRadius: 20,
      padding: '25px 55px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    link: {
      padding: '5px 10px',
      color: '#5744E4',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    desc: {
      color: '#9B90EF',
    },
  };
  return (
    <div style={styles.wrap}>
      <div style={styles.inner}>
        <Space direction="vertical" align="center">
          <span style={styles.link} onClick={goToDoc}>
            Add documents to new masterlist
          </span>
          <span style={styles.desc}>There are any document here</span>
        </Space>
      </div>
    </div>
  );
}

export default function AddEditMasterlist({ edit = false }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const { masterlistId } = useParams();
  const [masterlistData, setMasterlistData] = useState({ loading: false });
  const [documentsLoading, setDocumentsLoading] = useState(true);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const formData = [
    {
      name: 'name',
      label: 'Masterlist Name',
      rules: [
        {
          required: true,
          whitespace: true,
          message: 'Please input masterlist name!',
        },
      ],
      children: <Input size="large" placeholder="" />,
    },
    {
      name: 'description',
      label: 'Description',
      rules: [{ required: true, message: 'Please input description!' }],
      children: <Input.TextArea rows={4} size="large" placeholder="" />,
    },
    {
      name: 'assignment',
      label: 'Assignment',
      rules: [{ required: true, message: 'Please select assignment!' }],
      children: (
        <Radio.Group>
          <Radio value="short_term">Short Term</Radio>
          <Radio value="long_term_purchase">Long Term Purchase</Radio>
          <Radio value="long_term_purchase_multifamily">Long Term Purchase Multifamily</Radio>
          <Radio value="long_term_refi">Long Term Refi</Radio>
          <Radio value="long_term_refi_multifamily">Long Term Refi Multifamily</Radio>
          <Radio value="catchall">Catchall</Radio>
          <Radio value="unassigned">Unassigned</Radio>
        </Radio.Group>
      ),
    },
  ];

  const setEditFieldsData = (data) => {
    const { name, description, loan_purposes, property_types, type } = data;

    form.setFields([
      {
        name: 'name',
        value: name,
      },
      {
        name: 'description',
        value: description,
      },
      {
        name: 'loan_purposes',
        value: loan_purposes,
      },
      {
        name: 'property_types',
        value: property_types,
      },
      {
        name: 'assignment',
        value: (type || '').replace(/ /g, '_').toLowerCase(),
      },
    ]);
  };
  const onFormChange = () => {
    setDisableSubmitBtn(false);
  };
  const generateForm = (arr) => arr.map((el, id) => <Form.Item {...el} key={el.name + id} />);

  function getMasterlistData() {
    API()
      .get('/user_settings/masterlist', {
        params: {
          masterlist_id: masterlistId,
        },
      })
      .then((res) => {
        setEditFieldsData(res.data);
        setMasterlistData(() => ({
          loading: true,
          ...res.data,
        }));
      })
      .catch((err) => {
        message.error(err.response.data.error);
      });
  }

  const goToDocumentAddPage = () => {
    history.push(`/onboarding/masterlists/${masterlistId}/document/add`);
  };

  const goToDocumentEditPage = (masterlistDocId) => {
    history.push(`/onboarding/masterlists/${masterlistId}/document/${masterlistDocId}/edit`);
  };

  const goToMasterlists = () => {
    history.push({
      pathname: '/onboarding/masterlists',
    });
  };

  function handleAddEditMasterlist(values) {
    const data = { ...values };
    if (edit) {
      data.masterlist_id = +masterlistId;
    } else {
      setMasterlistData((prevState) => ({
        loading: false,
        ...prevState,
      }));
    }

    if (data.assignment) {
      data.type = capitalizeAll(data.assignment.replace(/_/g, ' '));
      delete data.assignment;
    }

    API()
      .post('/user_settings/masterlist', data)
      .then((res) => {
        if (+res.data.masterlist_id >= 0) {
          history.push({
            pathname: edit ? '/onboarding/masterlists/' : `/onboarding/masterlists/${res.data.masterlist_id}/edit`,
          });
          message.info(edit ? 'Masterlist successful edited' : 'Masterlist successful added');
        }
      })
      .catch((err) => {
        message.error(err.response.data.error);
      });
  }

  function handleRemoveDoc(ids) {
    Promise.allSettled(
      ids.map(async (id) =>
        API()
          .delete('/user_settings/masterlist/document', {
            params: {
              document_id: id,
            },
          })
          .then(() => message.info('Masterlist successfully deleted'))
          .catch((res) => message.error(res.response?.data?.error)),
      ),
    ).then(getMasterlistData);
  }

  const handleCheckRequired = (id) => {
    setDocumentsLoading(false);
    API()
      .post('/user_settings/masterlist/document/required', {
        document_id: id,
      })
      .then((res) => {
        if (res.data.msg === 'ok') {
          setDocumentsLoading(true);
          setMasterlistData((prevState) => ({
            ...prevState,
            documents: prevState.documents.map((doc) => {
              if (doc.id === id) {
                return {
                  ...doc,
                  required: !doc.required,
                };
              }
              return doc;
            }),
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setDocumentsLoading(true);
      });
  };

  useEffect(() => {
    edit && getMasterlistData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="prospects_page_wrapper table_with_header_wrapper table_with_pagination_wrapper masterlist-add">
      <div
        style={{
          margin: '0 0 1.5rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className="section_title_wrapper" style={{ margin: 0 }}>
          <SectionTitle title={edit ? 'Edit masterlist' : 'Add masterlist'} />
          <Space size="large" style={{ marginLeft: 30 }}>
            <Button style={{ minWidth: 110 }} onClick={goToMasterlists} text="Cancel" />
            <Button
              style={{ minWidth: 110 }}
              disabled={disableSubmitBtn}
              onClick={() => form.submit()}
              className="btn_darkblue"
              text={edit ? 'Save' : 'Add'}
            />
          </Space>
        </div>
        <div style={{ minWidth: '30%' }}>
          <SearchBox />
        </div>
      </div>
      <Spin spinning={!masterlistData.loading && edit} size="large">
        <div style={styles.wrap}>
          <div style={{ padding: 15 }}>
            <Form
              onChange={onFormChange}
              form={form}
              onFinish={handleAddEditMasterlist}
              layout="vertical"
              hideRequiredMark
            >
              <Row justify="space-between" gutter={[16, 16]}>
                <Col span={8}>{generateForm(formData.slice(0, 2))}</Col>
                <Col span={8}>
                  <div
                    style={{
                      display: 'flex',
                      gap: 70,
                    }}
                  >
                    {generateForm(formData.slice(2))}
                  </div>
                </Col>
                <Col span={6} />
              </Row>
              <Divider
                style={{
                  borderTop: '1px solid #F0F0F0',
                  margin: 0,
                  marginBottom: 16,
                }}
              />
            </Form>
          </div>
          {edit && (
            <>
              <Divider
                style={{
                  borderTop: '1px solid #CBD5E1',
                  marginBottom: 0,
                }}
              />
              {masterlistData?.documents?.length && masterlistData.loading ? (
                <EditMasterlistTable
                  handleCheckRequired={handleCheckRequired}
                  loading={!documentsLoading}
                  handleRemoveDoc={handleRemoveDoc}
                  goToDocumentAddPage={goToDocumentAddPage}
                  goToDocumentEditPage={goToDocumentEditPage}
                  data={masterlistData.documents}
                />
              ) : (
                <NoDocumentsInformer goToDoc={goToDocumentAddPage} />
              )}
            </>
          )}
        </div>
      </Spin>
    </div>
  );
}
