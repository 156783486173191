import { Button } from 'antd';
import { useLARDBContext } from 'pages/Onboarding/LARDB/hooks/LARDBContext';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import classes from './styles/Control.module.css';
import DeleteLARModal from '../DeleteLARModal';
import UnarchiveLARModal from '../UnarchiveLARModal';
import WithdrawModal from './WithdrawModal';

// TODO: Ask Eugene to remove routes:
// - GET /LAR/auction_settings/cancel_auction
// - POST /LAR/auction_settings/cancel_auction/cancel
// I think they won't be needed anymore

export default function Control({ API }) {
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [unarchiveModalVisible, setUnarchiveModalVisible] = useState(false);
  const [withdrawModalVisible, setWithdrawModalVisible] = useState(false);
  const { lar_id: id, lar_status: status, refresh = () => null } = useLARDBContext();
  const history = useHistory();

  function handleCancellation({ reason, additionalInfo }) {
    API.cancel(reason, additionalInfo).then(() => {
      refresh();
      setCancelModalVisible(false);
    });
  }

  function handleWithdrawal({ reason, additionalInfo }) {
    API.archive(reason, additionalInfo).then(() => {
      setWithdrawModalVisible(false);
      refresh();
    });
  }

  function unarchiveCallback() {
    setUnarchiveModalVisible(false);
    refresh();
  }

  function onDeleteSuccess() {
    setDeleteModalVisible(false);
    history.push('/onboarding/dashboard');
  }

  return (
    <>
      <b>Control</b>
      <div className={classes.content}>
        <div>
          Current status: <b>{status}</b>
        </div>
        <div className={classes.horizontalButtonsWrapper}>
          {(() => {
            switch (status) {
              case 'Loan Processing':
              case 'No winner selected':
                return <Button onClick={() => setWithdrawModalVisible(true)}>Withdraw</Button>;

              case 'Archive':
                return (
                  <>
                    <Button onClick={() => setUnarchiveModalVisible(true)}>Restore</Button>
                    <Button danger onClick={() => setDeleteModalVisible(true)}>
                      Delete
                    </Button>
                  </>
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
      <WithdrawModal
        cancellation
        onCancel={() => setCancelModalVisible(false)}
        onOk={handleCancellation}
        visible={cancelModalVisible}
      />
      <WithdrawModal
        withdraw
        onCancel={() => setWithdrawModalVisible(false)}
        onOk={handleWithdrawal}
        visible={withdrawModalVisible}
      />
      <UnarchiveLARModal
        callback={unarchiveCallback}
        larId={id}
        onCancel={() => setUnarchiveModalVisible(false)}
        visible={unarchiveModalVisible}
      />
      <DeleteLARModal
        larId={id}
        onCancel={() => setDeleteModalVisible(false)}
        onDeleteSuccess={onDeleteSuccess}
        visible={deleteModalVisible}
      />
    </>
  );
}
