import '../styles/onboarding_header.css';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip, Typography } from 'antd';
import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Navigations from 'routers/Navigations';
import { NotificationIcon, MessagesIcon } from 'assets/icons';
import { setActiveRoute, setChatState, setUnreadChatMessages, setUserData } from 'store/actions/userActions';
import { getDashboardMetrics, updateDashboardTimer } from 'store/actions/dashboard';
import { getDaysDiff } from 'utils/date';
import { useMCMNotificationCount } from 'hooks/useMCMNotificationCount';
import API, { DashboardAPI } from 'api';
import Keys from 'constants/helper';
import socket from 'constants/socket';
import useResize from 'constants/useResize';

import AvatarPopover from '../AvatarPopover';
import AuctionPreviewTimer from '../AuctionPreviewTimer';
import CustomAvatar from 'components/UI/Avatar';

import styles from './PageHeader.module.css';

export default function PageHeader({ registration = false, className }) {
  const location = useLocation();
  const { innerWidth } = useResize();
  const dispatch = useDispatch();
  const notificationCount = useMCMNotificationCount(registration);
  const [activeMobMenu, toggleActiveMobMenu] = useReducer((state) => !state, false);
  const { userData, activeRoute, fullLeftPanelSize, unreadChatMessages } = useSelector((store) => store.user);
  const { metrics, timestamp } = useSelector((store) => store.dashboard);
  const withTimer = /broker|lender|pla|superadmin/i.test(userData?.role);
  const DbAPI = new DashboardAPI();

  const getTimingDataFromSocket = () => {
    socket?.on('dashboard_timing_updates', (data) => {
      dispatch(updateDashboardTimer(data));
    });
  };

  useEffect(() => {
    getTimingDataFromSocket();

    return () => {
      socket?.off('dashboard_timing_updates');
    };
  }, [socket]);

  useEffect(() => {
    // It's registration, there's no profile, duh
    if (registration) {
      return;
    }

    const token = localStorage.getItem(Keys.JWT_TOKEN);

    if ((token && (userData?.token ?? false) !== token) || (!userData && token) || !('name' in (userData || {}))) {
      API()
        .get('/profile')
        .then(({ data }) => {
          dispatch(
            setUserData({
              ...data.user,
              avatar: data.user.profile_photo,
              role: data.user.role,
              token,
            }),
          );
        });
    }
  }, [userData, dispatch, registration]);

  useEffect(() => {
    function getNotifications() {
      API()
        .get('/chat/new')
        .then(({ data }) => {
          dispatch(setUnreadChatMessages(+(data.new ?? 0)));
        });
    }

    // Leaving it as is for now
    if (userData?.token && userData?.role !== 'superadmin') {
      getNotifications();
      const intervalId = setInterval(getNotifications, 3e4);

      return () => clearInterval(intervalId);
    }
  }, [userData, dispatch]);

  useEffect(() => {
    if (withTimer) {
      DbAPI.getMetrics().then((res) => {
        dispatch(getDashboardMetrics(res));
      });
    }
  }, [withTimer, timestamp]);

  return (
    <header className={classNames('onboarding_header', { onboarding_header_full_size: fullLeftPanelSize }, className)}>
      {innerWidth <= 700 && (
        <>
          {/* <div className="burger_menu_wrapper" onClick={toggleActiveMobMenu}>
            <div className="burger_menu" />
          </div> */}
          <div className={classNames('mob_menu_header', { mob_menu_header_active: activeMobMenu })}>
            {Navigations.mainNavigationOnboarding
              .filter((i) => !i.hidden)
              .map(({ label, pathname, path, icon, userAccess, hr }) => {
                const userAccessLink = userAccess === 'all' ? true : userAccess.includes(userData.role);

                return (
                  userAccessLink &&
                  label && (
                    <>
                      <Link
                        className={classNames('nav_bar_link', { nav_bar_active_link: pathname === activeRoute })}
                        key={pathname}
                        onClick={() => {
                          setActiveRoute(pathname);
                          toggleActiveMobMenu();
                        }}
                        to={path}
                      >
                        {icon}
                        <span>{label}</span>
                      </Link>
                      {hr && <hr key={`${pathname}-hr`} />}
                    </>
                  )
                );
              })}
          </div>
        </>
      )}
      {withTimer && location.pathname.includes('/onboarding') && (
        <div className={styles.headerInner}>
          <Typography.Title level={4} className={styles.title}>{`Hello, ${userData?.name}`}</Typography.Title>
          {metrics.workday &&
            metrics.timing_json?.next_event_time &&
            getDaysDiff(metrics.timing_json?.next_event_time) === 0 && (
              <AuctionPreviewTimer className={styles.timer} data={metrics.timing_json} />
            )}
        </div>
      )}
      <div className="onboarding_header-actions">
        {registration || (
          <div className="onboarding_header-actions__alerts-wrapper">
            <Tooltip title="Messages" placement="bottom">
              <span
                className="onboarding_header-actions__action-item onboarding_header-actions__messages-alert"
                onClick={() => dispatch(setChatState({ variant: 'messagesList', request: 'all' }))}
              >
                <MessagesIcon size="1.7em" />
                {Number(unreadChatMessages) > 0 && (
                  <span className="action-item-count action-item-count_blue">
                    <span className="action-item-count__text">{unreadChatMessages}</span>
                  </span>
                )}
              </span>
            </Tooltip>
            <Link className="onboarding_header-actions__action-link" to="/onboarding/notifications">
              <Tooltip title="Notifications" placement="bottom">
                <span className="onboarding_header-actions__action-item onboarding_header-actions__notifications-alert">
                  <NotificationIcon size="1.7em" />
                  {notificationCount > 0 && (
                    <span className="action-item-count action-item-count_red">
                      <span className="action-item-count__text">{notificationCount}</span>
                    </span>
                  )}
                </span>
              </Tooltip>
            </Link>
          </div>
        )}
        {userData && (
          <div className="wrapper_user_avatar">
            <AvatarPopover registration={registration}>
              <CustomAvatar name={userData?.name} src={userData?.avatar} surname={userData?.surname} />
            </AvatarPopover>
          </div>
        )}
      </div>
    </header>
  );
}
