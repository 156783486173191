import Keys from 'constants/helper';
import { getUserFromStorage } from 'utils/storage';
import * as types from '../actions/types';

const initState = {
  userData: getUserFromStorage(),
  fullLeftPanelSize: false,
  activeRoute: null,
  breadCrumbHistory: localStorage.getItem(Keys.BREAD_CRUMB_HISTORY_EMS)
    ? JSON.parse(localStorage.getItem(Keys.BREAD_CRUMB_HISTORY_EMS))
    : [
        {
          to: '/onboarding/ems',
          preview: 'Message templates',
        },
      ],
  chatState: undefined,
  chatConnection: 0,
  unreadChatMessages: 0,
};

const authReducer = (state = initState, action) => {
  const isKeepMe = JSON.parse(localStorage.getItem(Keys.KEEP_ME));

  switch (action.type) {
    case types.USER_DATA:
      if (isKeepMe) {
        localStorage.setItem(Keys.USER_DATA, JSON.stringify(action.payload));
      } else {
        sessionStorage.setItem(Keys.USER_DATA, JSON.stringify(action.payload));
      }

      return { ...state, userData: action.payload };
    case types.ACTIVE_ROUTE:
      return { ...state, activeRoute: action.payload };
    case types.BREAD_CRUMB_HISTORY:
      localStorage.setItem(Keys.BREAD_CRUMB_HISTORY_EMS, JSON.stringify(action.payload));
      return { ...state, breadCrumbHistory: action.payload };
    case types.BREAD_CRUMB_HISTORY_SET_VALUE: {
      const breadCrumbHistoryFiltered = state.breadCrumbHistory.filter((e, i) => (i <= action.payload ? e : null));

      localStorage.setItem(Keys.BREAD_CRUMB_HISTORY_EMS, JSON.stringify(breadCrumbHistoryFiltered));
      return { ...state, breadCrumbHistory: breadCrumbHistoryFiltered };
    }
    case types.LEFT_PANEL_SIZE:
      return { ...state, fullLeftPanelSize: action.payload };
    case types.CHAT_STATE:
      return { ...state, chatState: action.payload };
    case types.UPDATE_CHAT_CONNECTION:
      return { ...state, chatConnection: state.chatConnection + 1 };
    case types.UNREAD_CHAT_MESSAGES:
      return { ...state, unreadChatMessages: action.payload };

    default: {
      return state;
    }
  }
};

export default authReducer;
