import { Col, Form, Input, Modal, Row, Select } from 'antd';
import { useEffect } from 'react';
import CustomButton from '../../UI/Button';

function FieldModal({ onClose, visible, editMode = false, handleAddField, activeModal, handleEditField }) {
  const [form] = Form.useForm();
  const types = [
    {
      name: 'String',
      value: 'string',
    },
    {
      name: 'Number',
      value: 'number',
    },
    {
      name: 'Currency',
      value: 'currency',
    },
    {
      name: 'Date',
      value: 'date',
    },
  ];
  const formData = [
    {
      span: 12,
      name: 'name',
      label: 'Name',
      rules: [
        {
          required: true,
          whitespace: true,
          message: 'Please input name!',
        },
      ],
      children: <Input size="large" placeholder="" />,
    },
    {
      span: 12,
      name: 'type',
      label: 'Type',
      rules: [
        {
          required: true,
          message: 'Please select type!',
        },
      ],
      children: (
        <Select size="large">
          {types.map((el) => (
            <Select.Option key={`type_${el.value}`} value={el.value}>
              {el.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      span: 24,
      name: 'description',
      label: 'Description',
      rules: [
        {
          required: true,
          whitespace: true,
          message: 'Please input document description!',
        },
      ],
      children: <Input.TextArea style={{ minHeight: 80 }} autoSize size="large" placeholder="Description" />,
    },
  ];
  const generateForm = (arr) =>
    arr.map((el, id) => (
      <Col key={el.name + id} span={el.span}>
        <Form.Item {...el} />
      </Col>
    ));
  const onFinish = (values) => {
    if (editMode) {
      handleEditField({ ...values, key: activeModal.data.key });
    } else {
      handleAddField(values);
    }
    form.resetFields();
  };
  const setEditFieldsData = (data) => {
    const { name, description, type } = data;
    form.setFields([
      {
        name: 'name',
        value: name,
      },
      {
        name: 'description',
        value: description,
      },
      {
        name: 'type',
        value: type,
      },
    ]);
  };

  useEffect(() => {
    if (visible && editMode) {
      setEditFieldsData(activeModal.data);
    }
    // eslint-disable-next-line
  }, [visible, editMode]);

  return (
    <Modal
      className="wrapper_prospect"
      title={
        <div className="wrapper_prospect__title" style={{ justifyItems: 'start' }}>
          <span className="title">{editMode ? 'Edit field' : 'Add new field'}</span>
        </div>
      }
      open={visible}
      onOk={() => null}
      centered
      footer={
        <div className="wrapper_remove_prospect_btns">
          <CustomButton key="back" onClick={onClose} text="Cancel" />
          <CustomButton key="submit" onClick={form.submit} text={editMode ? 'Save' : 'Add'} className="btn_darkblue" />
        </div>
      }
      onCancel={onClose}
    >
      <Form style={{ maxWidth: 979 }} form={form} onFinish={onFinish} layout="vertical" hideRequiredMark>
        <Row gutter={[25, 25]}>{generateForm(formData)}</Row>
      </Form>
    </Modal>
  );
}

export default FieldModal;
