import { Button, Dropdown, Menu, Space, Table, Tooltip } from 'antd';
import { HorizontalDotsIcon } from 'assets/icons';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from 'api';
import PaginationItemRender from 'constants/PaginationItemRender';
import { selectedBorrower } from 'store/actions/usersActions';

function ProspectsTable({
  filterBy = null,
  setActiveModal,
  restoreBorrower,
  linksPopup,
  setFields,
  updateTabs,
  activeTab,
  showProfile,
}) {
  const history = useHistory();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [data, setData] = useState({
    loading: true,
    borrowers: [],
  });
  const dispatch = useDispatch();
  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'User Status',
      dataIndex: 'registration_status',
      key: 'registration_status',
      render: (status) => {
        const statusColours = {
          active: { background: '#D4E3F9', color: '#2E7CF6' },
          invited: { background: '#D9F7BE', color: '#237804' },
          prospect: { background: '#F0F0F0', color: '#5A5A5A' },
          resent: { background: '#FFD180', color: '#865602' },
        }[status.toLocaleLowerCase()];

        return (
          <Tooltip title={status}>
            <span className="borrowers-user-status" style={statusColours}>
              {status ?? 'NONE'}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Recent login',
      dataIndex: 'last_login',
      key: 'last_login',
    },
    {
      title: 'Active deals',
      dataIndex: 'active_lars',
      key: 'active_lars',
    },
    {
      title: 'Draft',
      dataIndex: 'draft',
      key: 'draft',
      render: (_, record) => {
        const type = record?.last_incomplete_lar?.type;
        const percent = record?.last_incomplete_lar?.percent_completed;

        if (!type) {
          return null;
        }

        return (
          <div>
            <span>
              {type === 'scenario' ? 'Scenario' : 'Loan quote'}:<b>{percent ?? 0}%</b>
            </span>
          </div>
        );
      },
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <Space size="small" style={{ justifyContent: 'end' }} onClick={(e) => e.stopPropagation()}>
          {record?.last_incomplete_lar.id && (
            <span>
              LAR ID:
              {record?.last_incomplete_lar.id}
            </span>
          )}
          {record?.actions?.includes('Continue') && (
            <Button
              type="text"
              disabled={!record?.actions?.includes('Continue')}
              onClick={(event) => {
                event.stopPropagation();
                history.push(
                  `/lar_app/create?lar_id=${record?.last_incomplete_lar.id}&scenario=${
                    record?.last_incomplete_lar?.type === 'scenario'
                  }`,
                );
              }}
            >
              Continue
            </Button>
          )}
          {record?.actions?.includes('Continue') || (
            <Button
              onClick={(event) => {
                event.stopPropagation();
                createNewLoanRequest(record);
              }}
              type="text"
            >
              New loan request
            </Button>
          )}
          {['Invited', 'Resent'].includes(record.registration_status) && (
            <>
              <Button
                type="text"
                onClick={(event) => {
                  event.stopPropagation();
                  setActiveModal({
                    show: 'cancel_invite',
                    data: { ...record, index: record.index },
                  });
                }}
              >
                Cancel invitation
              </Button>
              <Button
                type="text"
                disabled={!linksPopup.value}
                onClick={(event) => {
                  event.stopPropagation();
                  setActiveModal({
                    show: 'send_a_reminder',
                    data: { ...record, index: record.index },
                  });
                }}
              >
                Resend invitation
              </Button>
            </>
          )}
          {record.registration_status === 'Deleted' && record.actions?.includes('Restore borrower') && (
            <Button
              disabled={!linksPopup.value}
              onClick={(event) => {
                event.stopPropagation();
                restoreBorrower(record.id);
              }}
              type="text"
            >
              Restore
            </Button>
          )}
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: '1',
                    label: (
                      <span
                        onClick={(event) => {
                          event.stopPropagation();

                          if (!/prospect/i.test(record.registration_status)) {
                            return;
                          }

                          setActiveModal({
                            show: 'edit_borrower',
                            data: { ...record },
                          });

                          setFields((prev) => ({
                            ...prev,
                            email: {
                              ...prev.email,
                              value: record.email || record.user_json.email,
                            },
                            phone: {
                              ...prev.phone,
                              value: record.phone || record.user_json.phone,
                            },
                            name: {
                              ...prev.name,
                              value: record.full_name.split(' ').slice(0, -1).join(' '),
                            },
                            surname: {
                              ...prev.surname,
                              value: record.full_name.split(' ')[record.full_name.split(' ').length - 1],
                            },
                          }));
                        }}
                      >
                        Edit borrower
                      </span>
                    ),
                    disabled: !/prospect/i.test(record.registration_status),
                  },
                  {
                    key: '2',
                    label: (
                      <span
                        onClick={(event) => {
                          event.stopPropagation();
                          if (record.registration_status === 'Approve') {
                            return;
                          }

                          if (
                            (record.registration_status === 'Deleted' &&
                              !record.actions.includes('Remove From The List')) ||
                            (record.active_lars > 0 && !record.actions.includes('Delete Borrower'))
                          ) {
                            return;
                          }

                          setActiveModal({
                            show: 'delete_borrower',
                            data: {
                              ...record,
                              index: record.index,
                            },
                          });
                        }}
                      >
                        {record.registration_status === 'Deleted' && record.actions.includes('Remove From The List')
                          ? 'Remove from the list'
                          : 'Delete Borrower'}
                      </span>
                    ),
                    disabled:
                      (record.registration_status === 'Deleted' && !record.actions.includes('Remove From The List')) ||
                      (record.active_lars > 0 && !record.actions.includes('Delete Borrower')),
                  },
                ]}
              />
            }
            placement="bottomRight"
            arrow
          >
            <HorizontalDotsIcon />
          </Dropdown>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    API()
      .get(`/prospects/broker?per_page=${pagination.pageSize}&page=${pagination.current}&user_type=${activeTab}`)
      .then((res) => {
        setData(() => ({
          loading: false,
          borrowers: (filterBy
            ? res.data.borrowers.filter((el) => el[filterBy.key] === filterBy.value)
            : res.data.borrowers
          ).map((el, idx) => ({ ...el, index: idx })),
        }));
        setPagination((prevState) => ({
          ...prevState,
          total: res.data.total,
        }));
      });
    // eslint-disable-next-line
  }, [pagination.current, pagination.pageSize, updateTabs]);

  const createNewLoanRequest = (user) => {
    dispatch(selectedBorrower(user));
    history.push('/onboarding/new-deal');
  };

  const handleDisplayProfile = (user) => {
    showProfile(user);
  };

  return (
    <Table
      columns={columns}
      loading={data.loading}
      pagination={{
        defaultCurrent: pagination.current,
        itemRender: PaginationItemRender,
        showSizeChanger: true,
        total: pagination.total ?? null,
      }}
      bordered={false}
      onChange={(e) => setPagination(e)}
      scroll={{ x: 1350 }}
      rowKey="id"
      dataSource={data.borrowers}
      onRow={(record) => ({
        onClick: () => handleDisplayProfile(record),
      })}
    />
  );
}

export default ProspectsTable;
