import { Avatar } from 'antd';
import crossIcon from '../../../../assets/images/cross.svg';

export default function getNotificationList(data, setActiveModal, avatarIcon, avatarBg, actions = []) {
  return data.map((el, idx) => ({
    date: el.sent_at,
    id: el.id,
    title: <div dangerouslySetInnerHTML={{ __html: el.trigger_message }} />,
    avatar: (
      <Avatar
        style={{
          alignItems: 'center',
          backgroundColor: avatarBg,
          display: 'flex',
          justifyContent: 'center',
        }}
        icon={<img alt="notification icon" src={avatarIcon} style={{ width: '24px', height: '24px' }} />}
        size={40}
      />
    ),
    actions: [
      ...actions.map((a) => a(el)),
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
          padding: '5px',
        }}
        onClick={() => {
          setActiveModal({
            show: 'delete_notification',
            data: { ...el, index: idx },
          });
        }}
      >
        <img src={crossIcon} alt="" />
      </div>,
    ],
  }));
}
