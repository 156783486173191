import './VerificationCode/verification_code.css';
import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter';
import { message } from 'antd';
import { setUserData } from 'store/actions/userActions';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import API from 'api';
import Button from 'components/UI/Button';
import Keys from 'constants/helper';
import TextInput from 'components/CustomComponents/TextInput';
import useNestedCheckError from 'constants/useNestedCheckError';

const initState = {
  password: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
  repeatPassword: {
    value: '',
    error: false,
    required: true,
    errorMessage: 'Fill this label',
  },
};

function SetupPassword() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [responseError, setResponseError] = useState(null);
  const [jwtToken, setJwtToken] = useState(null);
  const [field, setFields] = useState(initState);
  const { checkEmpty } = useNestedCheckError(field, setFields);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const jwt = urlParams.get('jwt');
    if (jwt) {
      setJwtToken(jwt);
    } else {
      // history.push('/404')
    }
  }, [location.search, history]);

  const onSubmit = (e) => {
    e.preventDefault();

    const { error, obj } = checkEmpty(field);
    setFields(obj);

    if (error) {
      return;
    }

    if (field.password.value.length <= 7) {
      setFields((prevState) => ({
        ...prevState,
        password: {
          ...prevState.password,
          error: true,
          errorMessage: 'Enter at least 8 characters',
        },
      }));
      return;
    }
    if (field.repeatPassword.value !== field.password.value) {
      setFields((prevState) => ({
        ...prevState,
        repeatPassword: {
          ...prevState.repeatPassword,
          error: true,
          errorMessage: 'Passwords is not similar',
        },
      }));
      return;
    }

    API(jwtToken)
      .post('/sign_in/setup_password', {
        password: field.password.value,
      })
      .then((res) => {
        const userData = {
          role: res.data.role,
          avatar: res.data.profile_photo,
          ...res.data,
        };

        localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token ?? jwtToken);
        dispatch(setUserData({ ...userData, token: res.data.access_token ?? jwtToken }));

        message.success('You successfully set the password');

        history.push('/onboarding/dashboard');
      })
      .catch((e) => {
        if (e.response?.data?.error) {
          setResponseError(e.response.data.error);
        }
      });
  };

  const onChange = (event) => {
    const { value, name } = event.target;
    setFields((prevState) => ({
      ...prevState,
      [name]: {
        value,
        error: null,
      },
    }));
    setResponseError(null);
  };

  return (
    <div className="wrapper_auth wrapper_verification">
      <h3 className="auth_title">Setup password</h3>
      <p className="auth_describe">Please enter your new password. It should be at least 8 characters long.</p>
      <form className="wrapper_auth__inputs" onSubmit={onSubmit}>
        <TextInput
          value={field.password.value}
          title="Password"
          error={field.password.error}
          showPassIcon
          htmlFor="password"
          errorMessage={field.password.errorMessage}
          onChange={onChange}
          autoComplete="on"
          type="password"
        />
        <TextInput
          value={field.repeatPassword.value}
          title="Password confirmation"
          htmlFor="repeatPassword"
          showPassIcon
          errorMessage={field.repeatPassword.errorMessage}
          type="password"
          autoComplete="on"
          error={field.repeatPassword.error}
          onChange={onChange}
        />
        {responseError ? (
          <div className="wrapper_form_error">
            <span>{capitalizeFirstLetter(responseError)}</span>
          </div>
        ) : null}
        <Button text="Continue" className="btn_darkblue" type="submit" />
      </form>
    </div>
  );
}

export default SetupPassword;
