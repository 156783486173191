import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import API from '../../../api';

import styles from './Quote.module.css';
import BidTable from '../../LoanProfile/BidTable';

export default function Quote() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);

    API()
      .get('/LAR/bid_process/lender/bids', { params: { lar_id: id, order_by: 'largest_loan_amount' } })
      .then(({ data }) => setData(data))
      .catch((error) => {
        setError(error.response.data.error);
      })
      .finally(() => setLoading(false));
  }, []);

  const sortedBids = data.bids
    ?.sort((quoteA, quoteB) => quoteB.loan_amount - quoteA.loan_amount)
    .filter((i) => i.is_owner);

  return (
    <div className={styles.wrapper}>
      {error ? (
        <Typography.Paragraph>{error}</Typography.Paragraph>
      ) : (
        <div>
          <Typography.Title level={5}>Quote</Typography.Title>
          <BidTable loading={!loading} bids={sortedBids} />
        </div>
      )}
    </div>
  );
}
