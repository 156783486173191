import './styles/bank_fee_info_edit.css';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from 'api';
import Button from 'components/UI/Button';
import PhoneComponent from 'components/CustomComponents/PhoneInput';
import regExp from 'constants/regExp';
import siteName from 'constants/siteName';
import TextInput from 'components/CustomComponents/TextInput';
import useNestedCheckError from 'constants/useNestedCheckError';

const initState = {
  aba_routing_number: {
    value: '',
    error: false,
    errorMessage: 'Fill the label',
    required: true,
  },
  account_number: {
    value: '',
    error: false,
    errorMessage: 'Fill the label',
    required: true,
  },
  bank_address: {
    value: '',
    error: false,
    errorMessage: 'Fill the label',
    required: true,
  },
  bank_contact_name: {
    value: '',
    required: false,
  },
  bank_name: {
    value: '',
    error: false,
    errorMessage: 'Fill the label',
    required: true,
  },
  iharmoni_default_success_fee: {
    value: '',
    error: false,
    errorMessage: 'Fill the label',
    required: true,
  },
  iharmoni_minimum_fee: {
    value: '',
    error: false,
    errorMessage: 'Fill the label',
    required: true,
  },
  iharmoni_processing_fee: {
    value: '',
    error: false,
    errorMessage: 'Fill the label',
    required: true,
  },
  swift: {
    value: '',
    error: false,
    errorMessage: 'Fill the label',
    required: true,
  },
  phone_number: {
    value: '',
    error: false,
    errorMessage: 'Fill the label',
    required: true,
  },
};

export default function BankFeeInfoEdit() {
  const [fields, setFields] = useState(initState);
  const history = useHistory();
  const { checkEmpty } = useNestedCheckError(fields, setFields);

  useEffect(() => {
    API()
      .get('/superadmin/settings/bank_and_fee_info')
      .then((res) => {
        setFields((prevState) => ({
          ...prevState,
          aba_routing_number: {
            ...prevState.aba_routing_number,
            value: res.data.aba_routing_number ?? '',
          },
          account_number: {
            ...prevState.account_number,
            value: res.data.account_number ?? '',
          },
          bank_address: {
            ...prevState.bank_address,
            value: res.data.bank_address ?? '',
          },
          bank_contact_name: {
            ...prevState.bank_contact_name,
            value: res.data.bank_contact_name ?? '',
          },
          bank_name: {
            ...prevState.bank_name,
            value: res.data.bank_name ?? '',
          },
          swift: {
            ...prevState.swift,
            value: res.data.swift ?? '',
          },
          iharmoni_default_success_fee: {
            ...prevState.iharmoni_default_success_fee,
            value: res.data.iharmoni_default_success_fee ?? '',
          },
          iharmoni_minimum_fee: {
            ...prevState.iharmoni_minimum_fee,
            value: res.data.iharmoni_minimum_fee ?? '',
          },
          iharmoni_processing_fee: {
            ...prevState.iharmoni_processing_fee,
            value: res.data.iharmoni_processing_fee ?? '',
          },
          phone_number: {
            ...prevState.phone_number,
            value: res.data.phone_number ?? '',
          },
        }));
      });
  }, []);

  const onSubmit = () => {
    // event.preventDefault();

    const { error, obj } = checkEmpty(fields);
    setFields(obj);

    if (error) {
      return;
    }

    if (fields.phone_number.value.length < 10) {
      setFields((prevState) => ({
        ...prevState,
        phone_number: {
          ...prevState.phone_number,
          error: true,
          errorMessage: 'Phone number must be 10 numbers',
        },
      }));
      return;
    }

    const reqObj = {
      aba_routing_number: Number(fields.aba_routing_number.value), // l=9
      account_number: Number(fields.account_number.value), // l=9, int
      bank_address: fields.bank_address.value, // l=255
      bank_name: fields.bank_name.value, // l=255
      iharmoni_default_success_fee: parseFloat(fields.iharmoni_default_success_fee.value), // float
      iharmoni_minimum_fee: Number(fields.iharmoni_minimum_fee.value), // float
      iharmoni_processing_fee: Number(fields.iharmoni_processing_fee.value), // float
      phone_number: fields.phone_number.value, // l=13
      swift: fields.swift.value, // l=11
    };

    if (fields.bank_contact_name.value) {
      reqObj.bank_contact_name = fields.bank_contact_name.value;
    }

    API()
      .post('/superadmin/settings/bank_and_fee_info', reqObj)
      .then(() => {
        message.success('You successfully updated bank fee info');
        history.push('/onboarding/admin-settings/bank_fee_info');
      });
  };

  const onChange = (e, name) => {
    setFields((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: e.target.value,
        error: null,
      },
    }));
  };

  const onChangeFloat = (e, name) => {
    if (regExp.number_percent_format.test(e.target.value)) {
      setFields((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: e.target.value,
          error: null,
        },
      }));
    }
  };

  return (
    <form className="wrapper_bank_fee_info_edit" onSubmit={(e) => e.preventDefault()}>
      <TextInput
        title="Bank Name"
        value={fields.bank_name.value}
        maxLength={255}
        error={fields.bank_name.error}
        onChange={(e) => onChange(e, 'bank_name')}
        errorMessage={fields.bank_name.errorMessage}
      />
      <TextInput
        title="Bank Address"
        value={fields.bank_address.value}
        error={fields.bank_address.error}
        maxLength={255}
        onChange={(e) => onChange(e, 'bank_address')}
        errorMessage={fields.bank_address.errorMessage}
      />

      <PhoneComponent
        title="Phone Number"
        value={fields.phone_number.value}
        maxLength={15}
        onChange={(value) => {
          setFields((prevState) => ({
            ...prevState,
            phone_number: {
              ...prevState.phone_number,
              value,
              error: false,
            },
          }));
        }}
        error={fields.phone_number.error}
        errorMessage={fields.phone_number.errorMessage}
      />
      <TextInput
        title="Bank Contact Name"
        value={fields.bank_contact_name.value}
        maxLength={255}
        error={fields.bank_contact_name.error}
        onChange={(e) => onChange(e, 'bank_contact_name')}
        errorMessage={fields.bank_contact_name.errorMessage}
      />
      <TextInput
        title="Account Number"
        maxLength={255}
        value={fields.account_number.value}
        error={fields.account_number.error}
        onChange={(e) => {
          if (regExp.only_number.test(e.target.value)) {
            onChange(e, 'account_number');
          }
        }}
        errorMessage={fields.account_number.errorMessage}
      />
      <TextInput
        title="Aba Routing Number"
        value={fields.aba_routing_number.value}
        maxLength={255}
        error={fields.aba_routing_number.error}
        onChange={(e) => {
          if (regExp.only_number.test(e.target.value)) {
            onChange(e, 'aba_routing_number');
          }
        }}
        errorMessage={fields.aba_routing_number.errorMessage}
      />
      <TextInput
        title="Swift"
        value={fields.swift.value}
        error={fields.swift.error}
        maxLength={11}
        onChange={(e) => onChange(e, 'swift')}
        errorMessage={fields.swift.errorMessage}
      />
      <TextInput
        title={`${siteName} Default Success Fee`}
        addonAfter="%"
        value={fields.iharmoni_default_success_fee.value}
        error={fields.iharmoni_default_success_fee.error}
        onChange={(e) => {
          if ((regExp.two_number_two_decimal.test(e.target.value) && +e.target.value <= 100) || !e.target.value) {
            onChangeFloat(e, 'iharmoni_default_success_fee');
          }
        }}
        errorMessage={fields.iharmoni_default_success_fee.errorMessage}
      />
      <TextInput
        title={`${siteName} Minimum Fee ($)`}
        maxLength={10}
        value={fields.iharmoni_minimum_fee.value}
        error={fields.iharmoni_minimum_fee.error}
        onChange={(e) => onChangeFloat(e, 'iharmoni_minimum_fee')}
        errorMessage={fields.iharmoni_minimum_fee.errorMessage}
      />
      <TextInput
        title={`${siteName} Processing Fee ($)`}
        maxLength={10}
        value={fields.iharmoni_processing_fee.value}
        error={fields.iharmoni_processing_fee.error}
        onChange={(e) => onChangeFloat(e, 'iharmoni_processing_fee')}
        errorMessage={fields.iharmoni_processing_fee.errorMessage}
      />
      <div className="wrapper_btns">
        <Button
          text="Cancel"
          className="cancel"
          onClick={() => history.push('/onboarding/admin-settings/bank_fee_info')}
        />
        <Button onClick={onSubmit} text="Save" className="btn_darkblue" />
      </div>
    </form>
  );
}
