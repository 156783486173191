import { useContext, useEffect, useRef } from 'react';
import { Form, Input, InputNumber, Select } from 'antd';
import { EditableContext } from '../context';

function EditableCell({ title, type, editable, children, dataIndex, record, handleSave, ...restProps }) {
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  }, [dataIndex, record, form]);

  const toggleEdit = () => {
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
    }
  };

  let childNode = children;

  childNode =
    type === 'number' ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {type === 'number' ? (
          <InputNumber max={850} min={350} style={{ minWidth: 120 }} ref={inputRef} onPressEnter={save} onBlur={save} />
        ) : null}
      </Form.Item>
    ) : type === 'text' ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {type === 'text' ? <Input ref={inputRef} onPressEnter={save} onBlur={save} /> : null}
      </Form.Item>
    ) : type === 'select' ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {type === 'select' ? (
          <Select ref={inputRef} onPressEnter={save} onBlur={save} placeholder="Please select">
            <Select.Option value="Spouse">Spouse</Select.Option>
            <Select.Option value="Child (over 18)">Child (over 18)</Select.Option>
            <Select.Option value="Relative">Relative</Select.Option>
            <Select.Option value="No-familial relationship">No-familial relationship</Select.Option>
          </Select>
        ) : null}
      </Form.Item>
    ) : null;

  return <td {...restProps}>{childNode}</td>;
}

export default EditableCell;
