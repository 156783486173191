import React, { Fragment, useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import './styles/settings.css';
import SectionTitle from '../../../../components/OnBoarding/SectionTitle';
import Navigations from '../../../../routers/Navigations/index';

const { TabPane } = Tabs;

function MainRouter() {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(pathname.split('/')[3]);
  const history = useHistory();

  useEffect(() => {
    const url = pathname.split('/')[3];
    if (url) {
      if (pathname.includes('slug')) {
        setActiveTab(Navigations.mainNavigationSuperSettings[0].pathname);
        history.push(Navigations.mainNavigationSuperSettings[0].path);
      } else {
        setActiveTab(url);
      }
    }
  }, [pathname, history]);

  const callback = (key) => history.push(key);

  return (
    <div className="wrapper_user_sa_settings">
      <SectionTitle title="Settings" />
      <div className="settings">
        <Tabs activeKey={pathname} onChange={callback}>
          {Navigations.mainNavigationSuperSettings.map((i, index) =>
            i.label ? (
              <Fragment key={index}>
                <TabPane tab={i.label} key={i.path} />
              </Fragment>
            ) : null,
          )}
        </Tabs>
      </div>
      <div className="main">
        {Navigations.mainNavigationSuperSettings.map(
          (i, index) => activeTab === i.pathname && <Route path={i.path} exact key={index} component={i.main} />,
        )}
      </div>
    </div>
  );
}

export default MainRouter;
