import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter';
import { Checkbox, DatePicker, message, Modal, TimePicker } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import moment from 'moment';
import Button from '../../UI/Button';
import CustomButton from '../../UI/Button';
import CustomPicker from '../../CustomComponents/CustomPicker';
import TextArea from '../../CustomComponents/TextArea';
import TextInput from '../../CustomComponents/TextInput';

const initState = {
  email: {
    value: '',
    error: false,
    errorMessage: 'Fill this label',
  },
  email_cc: {
    value: '',
    error: false,
    errorMessage: 'Fill this label',
  },
  subject: {
    value: '',
    error: false,
    errorMessage: 'Fill this label',
  },
  template: {
    value: '',
    error: false,
    errorMessage: 'Fill this label',
  },
  sendNow: {
    value: false,
  },
  date: {
    value: '',
    errorMessage: 'Fill this label',
    error: null,
  },
  time: {
    value: '',
    errorMessage: 'Fill this label',
    error: null,
  },
  move: {
    value: false,
  },
};

export default function SendPackageModal(props) {
  const {
    modal_title,
    userData,
    onClose,
    toBeVisible,
    responseError,
    setResponseError,
    requestUrl,
    getItems,
    activeModal,
    disabledEmail,
    loanPackageName,
    selectedItems,
    ccEmail,
    preparedFields = {},
    allItemsCount,
    selectedItemsCount,
    clearFields,
  } = props;

  const [fields, setFields] = useState(initState);
  const [showPreview, setShowPreview] = useState(true);

  const onSubmitPreview = () => {
    if (!userData?.email) {
      return null;
    }
    if (!fields.template.value) {
      return null;
    }

    API()
      .post(requestUrl, {
        template: fields.template.value, // required,  max: 400
        items_ids: selectedItems,
        action: 'sent',
        move: fields.move.value,
        email: fields.email.value,
        package_name: loanPackageName,
        subject: fields.subject.value,
      })
      .then(() => {
        message.info('Loan package preview has been sent successfully');
      });
  };
  const onSubmitModalSend = (e) => {
    e?.preventDefault();
    let error = false;
    const protoFields = { ...fields };
    ['subject', 'email', 'email_cc', 'template'].map((el) => {
      if (!protoFields[el].value.trim()) {
        error = true;
        setFields((prevState) => ({
          ...prevState,
          [el]: {
            ...prevState[el],
            error: true,
          },
        }));
      }
      return null;
    });

    if (!protoFields.sendNow.value) {
      if (!protoFields.date.value) {
        error = true;
        setFields((prevState) => ({
          ...prevState,
          date: {
            ...prevState.date,
            error: true,
          },
        }));
      }
      if (!protoFields.time.value) {
        error = true;
        setFields((prevState) => ({
          ...prevState,
          time: {
            ...prevState.time,
            error: true,
          },
        }));
      }
    }

    const reqObj = {
      template: fields.template.value, // required,  max: 400
      items_ids: selectedItems,
      action: 'sent',
      move: fields.move.value,
      email: fields.email.value,
      package_name: loanPackageName,
      subject: fields.subject.value,
    };

    if (fields.date.value && fields.time.value) {
      reqObj.schedule = {
        datetime: `${fields.date.value} ${fields.time.value}`,
      };
    }

    if (!fields.sendNow) {
      reqObj.schedule = `${fields.date.value} ${fields.time.value}`;
    }

    if (!error) {
      API()
        .post(requestUrl, reqObj)
        .then(() => {
          onClose();
          message.info('Loan package has been sent successfully');
          return true;
        })
        .then(() => {
          getItems();
          clearFields();
        })
        .catch(() => protoFields);
    }
  };

  useEffect(() => {
    if (activeModal.data?.email) {
      setFields((prevState) => ({
        ...prevState,
        email: {
          ...prevState.email,
          value: activeModal.data.email,
        },
      }));
    }
    if (preparedFields?.hasFields) {
      setFields((prevState) => ({
        ...prevState,
        ...preparedFields,
      }));
    }
    // eslint-disable-next-line
  }, [activeModal]);

  const onChangeCheckBox = (event) => {
    setFields((prevState) => ({
      ...prevState,
      [event.target.name]: {
        ...prevState[event.target.name],
        value: event.target.checked,
        error: null,
      },
    }));
    if (event.target.name === 'sendNow') {
      setFields((prevState) => ({
        ...prevState,
        date: {
          ...prevState.date,
          error: false,
        },
        time: {
          ...prevState.time,
          error: false,
        },
      }));
    }
  };

  const onChangeDate = (dateString, name) => {
    setFields((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: dateString,
        error: null,
      },
    }));
  };

  const onChange = (event) => {
    const { value, name } = event.target;
    setFields((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
        error: null,
      },
    }));
    setResponseError(null);
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  }

  return (
    <Modal
      className="wrapper_prospect wrapper_modal_send_invite_platform"
      key={1}
      title={
        <div className="wrapper_prospect__title">
          <span className="title">{modal_title}</span>
        </div>
      }
      visible={toBeVisible}
      centered
      footer={
        <div className="wrapper_add_prospect_btns">
          {responseError ? <p className="errorMessage">{capitalizeFirstLetter(responseError)}</p> : null}
          <CustomButton key="back" onClick={onClose} text="Cancel" />
          <CustomButton
            key="submit"
            onClick={() => onSubmitModalSend(null, fields, '/LAR/dd_center/loan_package', 'Loan package sent')}
            text="Send"
            className="btn_darkblue"
          />
        </div>
      }
      onCancel={() => {
        onClose();
        setFields(initState);
      }}
    >
      <form
        className="wrapper_add_prospect_form wrapper-send-invite"
        onSubmit={(e) => onSubmitModalSend(e, fields, '/LAR/dd_center/loan_package', 'Loan package sent')}
      >
        {props?.hideEmailField ? null : (
          <TextInput
            title="To"
            onChange={onChange}
            maxlength={255}
            htmlFor="email"
            disabled={disabledEmail}
            value={fields.email.value}
            error={fields.email.error}
            errorMessage={fields.email.errorMessage}
          />
        )}
        {ccEmail ? (
          <TextInput
            title="CC"
            onChange={onChange}
            maxlength={255}
            htmlFor="email_cc"
            value={fields.email_cc.value}
            error={fields.email_cc.error}
            errorMessage={fields.email_cc.errorMessage}
          />
        ) : null}
        <TextInput
          title="Subject"
          onChange={onChange}
          maxlength={255}
          htmlFor="subject"
          value={fields.subject.value}
          error={fields.subject.error}
          errorMessage={fields.subject.errorMessage}
        />
        <TextArea
          label="Template"
          maxlength={400}
          error={fields.template.error}
          htmlFor="template"
          onChange={onChange}
          errorMessage={fields.template.errorMessage}
          value={fields.template.value}
        />
        <div className="template_preview_wrapper">
          <div className="template_preview_wrapper__title" onClick={() => setShowPreview(!showPreview)}>
            <span className="title">Preview</span>
            <svg
              width="24"
              height="24"
              className={showPreview ? 'svg_active' : null}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6 9L12 15L18 9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          {showPreview ? (
            <div className="template_preview">
              <span
                style={{ overflowWrap: 'anywhere' }}
                dangerouslySetInnerHTML={{
                  // eslint-disable-next-line
                  __html: fields?.template?.value?.split('${name}').join('<b>Username</b>'),
                }}
              />
            </div>
          ) : null}
          {showPreview ? (
            <Button text="Email preview to me" className="email_preview" onClick={() => onSubmitPreview(fields)} />
          ) : null}
        </div>
        {props?.onlySendNow ? null : (
          <>
            <div className="wrapper_send_now">
              <Checkbox name="sendNow" onChange={onChangeCheckBox} checked={fields.sendNow.value}>
                Send now
              </Checkbox>
            </div>
            <div className="wrapper_schedule">
              <p className="wrapper_schedule__title">Schedule</p>
              <CustomPicker error={fields.date.error} errorMessage={fields.date.errorMessage}>
                <DatePicker
                  onChange={(_, d) => onChangeDate(d, 'date')}
                  format={['YYYY-DD-MM', 'YY-DD-MM']}
                  disabledDate={disabledDate}
                  // disabledTime={disabledDateTime}
                  disabled={fields.sendNow.value}
                  value={fields.date.value ? moment(fields.date.value, ['YYYY-DD-MM', 'YY-DD-MM']) : ''}
                />
              </CustomPicker>
              <CustomPicker error={fields.time.error} errorMessage={fields.time.errorMessage}>
                <TimePicker
                  onChange={(_, d) => onChangeDate(d, 'time')}
                  use12Hours
                  allowClear={false}
                  disabled={fields.sendNow.value}
                  hideDisabledOptions
                  format="hh:mm A"
                  value={fields.time.value ? moment(fields.time.value, 'h:mm A') : ''}
                />
              </CustomPicker>
            </div>
            <div>
              <Checkbox name="move" onChange={onChangeCheckBox} checked={fields.move.value}>
                Move {selectedItemsCount}/{allItemsCount} selected items to "Lender Review"
              </Checkbox>
            </div>
          </>
        )}
      </form>
    </Modal>
  );
}
