import { Form, Input, Select } from 'antd';
import Modal from 'components/Modals/Modal/Modal';
import siteName from 'constants/siteName';
import { rejectReasons } from 'constants/rejectReasons';

export default function EndScenarioModal({ onCancel, onOk, ...rest }) {
  const [form] = Form.useForm();

  return (
    <Modal okText="Confirm" onCancel={onCancel} onOk={() => form.submit()} title="End this scenario" {...rest}>
      <Form form={form} initialValues={{ reason: rejectReasons[0] }} layout="vertical" onFinish={onOk}>
        <Form.Item label="Why are you ending this scenario?" name="reason">
          <Select
            options={rejectReasons.map((reason) => ({
              label: reason,
              value: reason,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Please provide an additional explanation for the lenders:"
          name="additionalInfo"
          rules={[
            {
              required: true,
              message: 'Please fill Additional Explanation field',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
      <b>What happens next?</b>
      <ol>
        <li>This scenario will be moved to the archive.</li>
        <li>{siteName} will notify the lenders.</li>
      </ol>
      <p>
        From the archive you will be able to rerun a new scenario or loan auction. Alternatively, you can also
        permanately delete the scenario and all related documents.
      </p>
    </Modal>
  );
}
