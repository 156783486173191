import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <path
      d="M7.5 12H12.5V13.7C12.4 13.8 12.4 13.9 12.3 14H7.5V12ZM7.5 10H12.5V8H7.5V10ZM7.5 6H12.5V4H7.5V6ZM5.5 3C5.5 2.4 5.9 2 6.5 2H14.5V11.8C15.1 11.4 15.8 11.2 16.5 11.1V3C16.5 2.4 16.9 2 17.5 2C18.1 2 18.5 2.4 18.5 3V4H20.5V3C20.5 1.3 19.2 0 17.5 0H6.5C4.8 0 3.5 1.3 3.5 3V14H5.5V3ZM11.5 17V16.4V16H0.5V17C0.5 18.7 1.8 20 3.5 20H12.3C11.8 19.1 11.5 18.1 11.5 17ZM15.5 14V20L20.5 17L15.5 14Z"
      fill="#8C8C8C"
    />
  </GenericIcon>
));
