import { Table, Tooltip } from 'antd';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import { DashboardAPI } from '../../../api';
import { useGetLARLink } from '../../../hooks/useGetLARLink';

import { ChevronIcon } from '../../../assets/icons';
import larPlaceholderImg from 'assets/images/LAR_placeholder.svg';

import styles from './ActiveLoans.module.css';

class Column {
  constructor(title, dataIndex, sortBy, setSortBy, sortingDirection, setSortingDirection, render, width, tooltip) {
    function headerCellClickHandler() {
      if (!sortBy) {
        return;
      }

      if (dataIndex !== sortBy) {
        setSortBy(dataIndex);
        return;
      }

      setSortingDirection((prev) => (prev === 'desc' ? 'asc' : 'desc'));
    }

    this.title = (
      <Tooltip title={tooltip}>
        <div onClick={headerCellClickHandler} className={styles.tableHeaderCell}>
          {title}
          {dataIndex === sortBy && <ChevronIcon direction={sortingDirection === 'desc' ? 'down' : 'up'} />}
        </div>
      </Tooltip>
    );
    this.render = render;
    this.dataIndex = dataIndex;
    this.width = width;
  }
}

export default function ActiveLoans() {
  const { getLARLink } = useGetLARLink();
  const history = useHistory();
  const API = useMemo(() => new DashboardAPI(), []);
  const [data, setData] = useState({});
  const [orderBy, setOrderBy] = useState('id');
  const [orderType, setOrderType] = useState('desc');
  const [isFetching, setIsFetching] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  function getColumn(title, key, props) {
    const render = props?.render || undefined;
    const width = props?.width || undefined;
    const sortByValue = props?.sorting === false ? null : orderBy;
    return new Column(title, key, sortByValue, setOrderBy, orderType, setOrderType, render, width, props?.tooltip);
  }

  const columns = [
    getColumn('', 'preview', {
      sorting: false,
      render: (_, el) => <img src={el.preview || larPlaceholderImg} width={130} height={80} alt="" />,
    }),
    getColumn('Closing', 'closing'),
    getColumn('Deal ID', 'id'),
    getColumn('Deal Owner', 'owner'),
    getColumn('Originator/Borrower', 'originator', {
      sorting: false,
      render: (_, el) => (
        <>
          <div>{el?.originator}</div>
          <div>{el?.borrower}</div>
        </>
      ),
    }),
    getColumn('Property', 'property'),
    getColumn('Style', 'style', {
      render: (_, el) => el.loan_purpose,
    }),
    getColumn('Owed', 'owed', {
      render: (_, el) => <div className={classNames(styles.badge, styles.badgeRed)}>{el.owed || 0}</div>,
      width: 40,
    }),
    getColumn('Pending', 'pending', {
      render: (_, el) => <div className={classNames(styles.badge, styles.badgeOrange)}>{el.pending || 0}</div>,
      width: 40,
    }),
    getColumn('Accepted', 'accepted', {
      render: (_, el) => <div className={classNames(styles.badge, styles.badgeGreen)}>{el.accepted || 0}</div>,
    }),
  ];

  function navigateToItem(id, type, status, restArgs) {
    history.push(getLARLink(id, type, status, restArgs));
  }

  useEffect(() => {
    setIsFetching(true);
    API.getActiveLoans({
      page: pagination.current,
      per_page: pagination.pageSize,
    })
      .then((res) => {
        setData(res);
      })
      .finally(() => setIsFetching(false));
  }, [pagination, API]);

  return (
    <div className={styles.root}>
      <div className={styles.tableContainer}>
        <Table
          columns={columns}
          dataSource={data.lars}
          loading={isFetching}
          onChange={(p) => {
            setPagination((prev) => ({
              ...prev,
              ...p,
            }));
          }}
          onRow={({ id, type, status, cognito_token, borrower_id }) => ({
            onClick: () => navigateToItem(id, type, status, { cognito_token, borrower_id }),
          })}
          pagination={{
            ...pagination,
            showSizeChanger: true,
            total: data.total_count,
          }}
          rowKey="id"
          size="middle"
        />
      </div>
    </div>
  );
}
