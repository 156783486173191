import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { ChevronIcon } from 'assets/icons';
import { Button } from 'antd';
import styles from './Widget.module.css';

const metricLabels = new Map([
  ['broker_quotes', 'Quotes waiting for your review'],
  ['conversations', 'Conversations waiting for your response'],
  ['docs', 'Docs waiting for your review'],
  ['lender_quotes', 'Direct quote requests waiting for your quote'],
  ['lender_ready', 'Lender review docs waiting for your review'],
  ['lender_review', 'Docs waiting for your review'],
  ['not_visited', 'Updated quotes waiting for your response'],
  ['owned', 'Owed docs waiting for you to upload'],
  ['pending_verification', 'Docs waiting for your review'],
  ['responses', 'Responses waiting for your review'],
  ['underwriting', 'Underwriting items waiting for your action'],
  ['winner_selection', 'Waiting for you to select a winner'],
  ['without_quote', 'LAR invitations waiting for you to quote'],
  ['without_response', 'Scenario invitations waiting for your response'],
]);

function Widget({
  activeTab,
  dataIndex,
  extendedDataIndex,
  full = false,
  link,
  metrics,
  setActiveTab,
  tabName,
  title,
  icon,
  toggleFull = () => null,
}) {
  const history = useHistory();
  const role = useSelector((store) => store.user?.userData?.role);

  const itemsTotal = useMemo(() => {
    const data = metrics[extendedDataIndex];
    return data ? Object.values(data).reduce((a, c) => a + c, 0) : 0;
  }, [metrics, extendedDataIndex]);

  const circleColour = useMemo(() => (itemsTotal > 4 ? 'red' : itemsTotal > 0 ? 'yellow' : 'green'), [itemsTotal]);

  const handleActiveTab = () => {
    setActiveTab(tabName);
    sessionStorage.setItem('activeTab', tabName);
  };

  const isHideActions = process.env.REACT_APP_NODE_ENV !== 'development' || /borrower/i.test(role);

  return (
    <div
      className={classNames({
        [styles.activeWidget]: tabName === activeTab,
        [styles.fullWidget]: full,
        [styles.widget]: true,
      })}
    >
      <header>
        <h1 className={styles.widgetTitle}>
          {icon}
          {title}
        </h1>
        <h2>{metrics.active_items?.[dataIndex]}</h2>
      </header>
      {isHideActions || (
        <div className={styles.sum}>
          <div onClick={toggleFull}>
            Actions needed
            <ChevronIcon colour="black" direction={full ? 'up' : 'down'} />
          </div>
          <div className={classNames(styles.circle, styles[circleColour])}>{itemsTotal}</div>
        </div>
      )}
      {full && (
        <ul>
          {Object.entries(metrics[extendedDataIndex] || {}).map(([metric, value]) => (
            <li>
              <span>{value}</span>
              {metricLabels.get(metric)}
            </li>
          ))}
        </ul>
      )}
      <div className={styles.buttonsWrapper}>
        <Button type="primary" onClick={handleActiveTab}>
          View {title}
        </Button>
        {link && <Button onClick={() => history.push(link)}>{`Go to Live ${title}`}</Button>}
      </div>
    </div>
  );
}

export default Widget;
