export default class Keys {
  static AUCTION_END_TIME = 'iHarmoniAuctionEndTime';

  static AUCTION_TUTORIAL_POPUP = 'iHarmoniAuctionTutorialModal';

  static AUCTION_PREVIEW_POPUP = 'iHarmoniAuctionPreviewModal';

  static BREAD_CRUMB_HISTORY_EMS = 'iHarmoniBreadCrumbHistoryEMS';

  static JWT_TOKEN = 'iHarmoniJwtToken';

  static KEEP_ME = 'keepMe';

  static LAR_APPLICATION_DATA = 'iHarmoniLarApplicationData';

  static LARDB_NAVIGATION_LOOP = 'iHarmoniLARDBNavigationLoop';

  static USER_DATA = 'iHarmoniUserData';

  static VERIFICATION_CODE_EMAIL = 'iHarmoniVerEmail';

  static WINNER_SELECTION_PREVIEW = 'iHarmoniWinnerSelectionPreview';

  static COGNITO_TOKEN = 'cognito_save_form_token';
}
