import Keys from 'constants/helper';

export function getUserFromStorage() {
  const isKeepMe = JSON.parse(localStorage.getItem(Keys.KEEP_ME));
  let user;

  if (isKeepMe) {
    user = JSON.parse(localStorage.getItem(Keys.USER_DATA));
  } else {
    user = JSON.parse(sessionStorage.getItem(Keys.USER_DATA));
  }

  return user || null;
}
