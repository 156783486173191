import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo(({ double = false, ...params }) => (
  <GenericIcon {...params}>
    {double ? (
      <>
        <path d="M7 12L12 17L22 7" />
        <path d="M12 12L17 7M2 12L7 17L2 12Z" />
      </>
    ) : (
      <path d="M5 12L10 17L20 7" />
    )}
  </GenericIcon>
));
