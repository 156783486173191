import { useContext } from 'react';
import CustomButton from 'components/UI/Button';
import LARDBContext from 'pages/Onboarding/LARDB/LARDBContext';
import styles from './AuctionStartWidget.module.css';

function AuctionStartWidget({ goToLoanProfile }) {
  const { auction_started } = useContext(LARDBContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {auction_started ? (
          <>
            <span className={styles.label}>Auction starts on</span>
            <span className={styles.text}>{auction_started}</span>
          </>
        ) : (
          <span className={styles.text} style={{ marginTop: 30 }}>
            Waiting for start auction
          </span>
        )}
        <CustomButton className={styles.btn} onClick={goToLoanProfile} primary text="View Loan Profile" />
      </div>
    </div>
  );
}

export default AuctionStartWidget;
