import { Popover, Avatar } from 'antd';
import TeamMembers from 'components/LARDB/TeamMembers/TeamMembers';
import CustomAvatar from 'components/UI/Avatar';

function TeamMembersPopover({ larMembers = {}, larId, updateData }) {
  const userAvatarGroup = Object.values(larMembers)
    .flatMap((memberType) =>
      Object.values(memberType).flatMap((memberTeam) =>
        memberTeam
          .filter((member) => member.user_json?.full_name)
          .map((member, memberIdx) => (
            <CustomAvatar
              fullName={member.user_json?.full_name}
              key={memberIdx}
              src={member.user_json?.avatar}
              style={{
                backgroundColor: '#1890ff',
                color: 'white',
              }}
            />
          )),
      ),
    )
    .filter(Boolean);

  return (
    <Popover
      content={<TeamMembers updateData={updateData} members={larMembers} inPopover larId={larId} />}
      placement="bottom"
      trigger="hover"
      zIndex={999}
    >
      <div>
        <Avatar.Group maxCount={userAvatarGroup.length === 5 ? 5 : 4} maxPopoverTrigger="none" size={40}>
          {userAvatarGroup}
        </Avatar.Group>
      </div>
    </Popover>
  );
}

export default TeamMembersPopover;
