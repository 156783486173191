import './styles/team.css';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import Button from 'components/UI/Button';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import SelectWrapper from 'components/CustomComponents/SelectWrapper';

export default function Team() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Promise.all([
      API().get('/superadmin/settings/team'),
      API().get('/superadmin/settings/team/users?role_name=PLA'),
      API().get('/superadmin/settings/team/users?role_name=LOM'),
    ]).then((res) => {
      setData({
        PLA: {
          activeValue: res[0].data.PLA,
          users: res[1].data.users,
        },
        LOM: {
          activeValue: res[0].data.LOM,
          users: res[2].data.users,
        },
      });
      setLoading(true);
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    API()
      .post('/superadmin/settings/team', {
        LOM: data?.LOM?.activeValue?.user_id ?? '',
        PLA: data?.PLA?.activeValue?.user_id ?? '',
      })
      .then(() => {
        message.success('You successfully updated info');
      });
  };

  const onSelect = (value, name) => {
    setData((prevState) => {
      const user = prevState[name].users.find((i) => i.id === value);

      return {
        ...prevState,
        [name]: {
          ...prevState[name],
          activeValue: { full_name: user.full_name, user_id: user.id },
        },
      };
    });
  };
  const recOptions = (array) =>
    array?.map((i) => {
      i.preview = i.full_name ?? '';
      i.value = i.id;
      return i;
    });

  return (
    <form className="wrapper_team" onSubmit={onSubmit}>
      <SectionTitle title="Default Roles" />
      <div className="dropdowns">
        {loading
          ? Object.entries(data).map((i, index) => (
              <SelectWrapper
                options={recOptions(i[1].users)}
                size="large"
                key={index}
                title={i[0]}
                onChange={(value) => onSelect(value, i[0])}
                className="bordered"
                showSearch
                value={i[1].activeValue?.full_name}
              />
            ))
          : null}
      </div>
      <Button className="btn_darkblue submit" type="submit" text="Save" />
    </form>
  );
}
