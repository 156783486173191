import { Tabs } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setChatState } from 'store/actions/userActions';
import ChatHeader from './ChatHeader';
import ChatRow from './ChatRow';
import styles from './styles/ChatUsers.module.css';

function ChatTab({ chats, name, onChatRowClick }) {
  const sortedChats = chats?.sort(
    (a, b) => new Date(b.last_message.message_data.sent_at) - new Date(a.last_message.message_data.sent_at),
  );

  if (isEmpty(chats)) return <p className={styles.chatsNotFound}>There are no messages</p>;

  return (
    <div key={name} className="chat-user-group-list">
      {sortedChats.map((chat) => (
        <ChatRow key={chat.chat_id} chat={chat} onClick={onChatRowClick} />
      ))}
    </div>
  );
}

export default function ChatUsers({
  activeForward,
  chats,
  setActiveForward,
  setSearch,
  setShowArchive,
  showArchive,
  type,
  setIsOpenMessagesList,
  isOpenMessagesList,
}) {
  const dispatch = useDispatch();
  const { chatState } = useSelector((s) => s.user);

  function onChatRowClick(chat) {
    if (activeForward) {
      setActiveForward((state) => ({
        ...state,
        forward_to: chat,
      }));
    }

    if (chatState?.variant === 'messagesList') {
      dispatch(
        setChatState({
          chat_id: chat.chat_id,
          lar_id: chat.lar_data.id,
          type: 'sidebar',
          variant: 'messagesList',
        }),
      );
      return;
    }

    dispatch(
      setChatState({
        chat_id: chat.chat_id,
        lar_id: chat.lar_data.id,
      }),
    );
  }

  const handleHideChatMessages = () => {
    if (chatState?.variant === 'messagesList') {
      dispatch(setChatState({}));

      return;
    }

    setIsOpenMessagesList((prev) => !prev);
  };

  const handleSearchMessages = (value) => {
    setSearch(value);
  };

  const handleShowArchive = () => {
    setShowArchive(true);
  };

  const handleHideArchive = () => {
    setShowArchive(false);
  };

  const allChatsTab = {
    key: 'all',
    label: 'All',
    children: Object.values(chats).flat(),
  };

  const formattedChats = [allChatsTab].concat(
    Object.entries(chats).map((chat) => ({
      key: chat[0].toLowerCase(),
      label: chat[0],
      children: chat[1],
    })),
  );

  return (
    <div className="chat-users-wrapper">
      <ChatHeader
        type={type}
        handleHideArchive={handleHideArchive}
        handleHideMessages={handleHideChatMessages}
        handleSearch={handleSearchMessages}
        handleArchive={handleShowArchive}
        showArchive={showArchive}
        isOpenMessagesList={isOpenMessagesList}
      />
      <div className="chat-tabs">
        <Tabs defaultActiveKey="all" animated={false}>
          {formattedChats.map((tab) => (
            <Tabs.TabPane tab={tab.label} key={tab.key}>
              <ChatTab name={tab.key} chats={tab.children} onChatRowClick={onChatRowClick} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
}
