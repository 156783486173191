import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo((params) => (
  <GenericIcon {...params}>
    <rect width="24" height="24" rx="12" fill="#FF4D4F" />
    <path
      d="M13.5909 12L18.0441 7.54687C18.2554 7.3359 18.3743 7.04961 18.3745 6.75099C18.3748 6.45236 18.2564 6.16587 18.0455 5.95452C17.8345 5.74318 17.5482 5.6243 17.2496 5.62404C16.951 5.62377 16.6645 5.74215 16.4531 5.95312L12 10.4062L7.54687 5.95312C7.33553 5.74177 7.04888 5.62304 6.75 5.62304C6.45111 5.62304 6.16447 5.74177 5.95312 5.95312C5.74178 6.16446 5.62305 6.45111 5.62305 6.74999C5.62305 7.04888 5.74178 7.33552 5.95312 7.54687L10.4062 12L5.95312 16.4531C5.74178 16.6645 5.62305 16.9511 5.62305 17.25C5.62305 17.5489 5.74178 17.8355 5.95312 18.0469C6.16447 18.2582 6.45111 18.3769 6.75 18.3769C7.04888 18.3769 7.33553 18.2582 7.54687 18.0469L12 13.5937L16.4531 18.0469C16.6645 18.2582 16.9511 18.3769 17.25 18.3769C17.5489 18.3769 17.8355 18.2582 18.0469 18.0469C18.2582 17.8355 18.3769 17.5489 18.3769 17.25C18.3769 16.9511 18.2582 16.6645 18.0469 16.4531L13.5909 12Z"
      fill="#FDFDFF"
    />
  </GenericIcon>
));
