import { FileInvoiceIcon } from 'assets/icons';
import { Switch, Form, Select, message } from 'antd';
import { useEffect } from 'react';
import CustomButton from 'components/UI/Button';
import classes from './styles/Notifications.module.css';

export default function Notifications({ API }) {
  const [form] = Form.useForm();

  useEffect(() => {
    API.getNotificationSettings().then(({ data }) => {
      form.setFieldsValue(data);
    });
  }, [API, form]);

  function submitForm({ enabled, interval }) {
    API.updateNotificationSettings(enabled, interval)
      .then(() => {
        message.success('Notification settings successfully updated!');
      })
      .catch((reason) => {
        message.error(reason.response?.data?.error || "Couldn't update notification settings!");
      });
  }

  return (
    <div className={classes.root}>
      <h1>Recent Document Availability Notifications</h1>
      <div className={classes.main}>
        <div className={classes.description}>
          <FileInvoiceIcon />
          <p>
            Receive notifications digest when new documents are available for review on loans you're following or the
            owner of.
            <br />
            Digest will include all documents available for review since last notification was sent.
          </p>
        </div>
        <Form form={form} layout="vertical" onFinish={submitForm}>
          <Form.Item label="Enable" name="enabled" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item label="Send notifications" name="interval">
            <Select options={[{ label: '30 minutes', value: '30 minutes' }]} />
          </Form.Item>
          <CustomButton primary text="Save changes" onClick={() => form.submit()} />
        </Form>
      </div>
    </div>
  );
}
