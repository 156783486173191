import { io } from 'socket.io-client';
import { LARAPI } from 'api';
import { useSelector } from 'react-redux';
import { useState, useMemo, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import PublicMessage from './PublicMessage';
import styles from './PublicMessages.module.css';

function PublicMessages({ larId, isScenario = false }) {
  const [publicMessages, setPublicMessages] = useState([]);
  const { publicMessages: storePublicMessages } = useSelector((s) => s.lar.data);
  const token = useSelector((s) => s.user.userData?.token);

  const API = useMemo(() => new LARAPI(larId), [larId]);

  useEffect(() => {
    if (!larId) {
      return;
    }

    API.getPublicMessages().then((res) => setPublicMessages(res));
  }, [storePublicMessages.length, API, larId]);

  const socket = useMemo(
    () => io(process.env.REACT_APP_API_URL + (isScenario ? '/scenario' : '/auction'), { query: { jwt: token } }),
    [token, isScenario],
  );

  useEffect(() => {
    if (!token || !larId) {
      return;
    }

    function handleMessage({ message }) {
      setPublicMessages((prev) => [message, ...prev]);
    }

    socket.emit('connect_to_lar', { lar_id: larId });
    socket.on('public_message', handleMessage);

    socket.connect();
    return () => {
      socket.off('public_message', handleMessage);
      socket.emit('disconnect_from_lar', { lar_id: larId });
    };
  }, [socket, larId, token]);

  function hidePublicMessage(id) {
    API.hidePublicMessage(id).then(() => {
      setPublicMessages((prev) => prev.filter((msg) => id !== msg.id));
    });
  }

  return (
    <div className={styles.root}>
      {publicMessages.map(({ id, ...msg }) => (
        <PublicMessage {...msg} API={API} id={id} key={id} onClose={hidePublicMessage} />
      ))}
    </div>
  );
}

PublicMessages.propTypes = {
  larId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isScenario: PropTypes.bool,
};

export default memo(PublicMessages);
