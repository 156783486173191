import { Drawer } from 'antd';
import ChooseBid from './ChooseBid';

function ChooseBidDrawer({ onClose, visible, openPlaceBidDrawer, activeDrawer, mask = false }) {
  return (
    <Drawer
      title="Submit a quote"
      placement="right"
      onClose={onClose}
      mask={mask}
      visible={visible}
      destroyOnClose
      width={500}
    >
      <ChooseBid
        activeDrawer={activeDrawer}
        onCloseActiveDrawer={onClose}
        openPlaceBidDrawer={openPlaceBidDrawer}
        drawerType
      />
    </Drawer>
  );
}
export default ChooseBidDrawer;
