import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TeamMember from '../TeamMember';
import { isEmptyElInObj } from '../../../../utils/empty';

function TeamSection({ sectionName, teamPositionGroup, larId, updateData }) {
  const userRole = useSelector((store) => store.user.userData.role);
  const history = useHistory();
  const isManager = userRole.toLowerCase().includes('manager');
  const isAdmin = userRole.toLowerCase().includes('admin');
  const isPla = userRole === 'PLA';
  const allowRole = isAdmin || isPla || isManager;

  const isShowManageButton = Object.keys(teamPositionGroup).some((memberRole) => {
    const splitedUserRole = userRole.split(' ');
    return memberRole.includes(splitedUserRole[0]);
  });

  return (
    <div className="team-members__section">
      <h3 className="team-members__section-title">
        {sectionName} {isShowManageButton ? '(Your team)' : ''}
        {isShowManageButton && allowRole ? (
          <span
            className="team-members__section-link"
            onClick={() => history.push('/onboarding/company-settings/teams')}
          >
            Manage
          </span>
        ) : null}
      </h3>
      <div className="team-members__members-list">
        {Object.keys(teamPositionGroup).map((teamMembersSection) =>
          !isEmptyElInObj(teamPositionGroup, teamMembersSection)
            ? teamPositionGroup[teamMembersSection].map((member, memberIdx) => {
                console.log(member);
                return (
                  <TeamMember
                    position={teamMembersSection}
                    key={`team_section_${teamMembersSection}_${memberIdx}`}
                    larId={larId}
                    canSendMessage={member.can_send_message}
                    user={member.user_json}
                    updateData={updateData}
                  />
                );
              })
            : null,
        )}
      </div>
    </div>
  );
}

export default TeamSection;
