import { Modal, Row } from 'antd';
import CustomButton from '../../../UI/Button';

function Footer({ onOk }) {
  return (
    <Row className="" align="middle" justify="end">
      <CustomButton
        style={{ fontSize: 14 }}
        key="submit"
        onClick={onOk}
        text="Yes, move the files"
        className="btn_darkblue"
      />
    </Row>
  );
}

function MoveToLenderReviewModal(props) {
  const { visible, onOk, onCancel, onCloseActiveModal, modal } = props;
  const { checked, ddCentreData } = modal?.data;
  const filteredItems = visible
    ? ddCentreData.dd_center_items.filter((dd_item) => [...checked].includes(dd_item.id))
    : [];

  return (
    <Modal
      className=""
      width={460}
      title={<b style={{ fontSize: 18 }}>Move to lender review</b>}
      open={visible}
      destroyOnClose
      onOk={onOk}
      onCancel={onCancel}
      centered
      footer={<Footer onOk={onOk} onCloseActiveModal={onCloseActiveModal} />}
    >
      <div className="">
        <p>Per the lender's request, the following files must be included in the initial loan package.</p>
        <p>Are these files included in the items selected?</p>
        <ol
          style={{
            background: '#F5F5F5',
            paddingLeft: '30px',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          {filteredItems.map((dd_item) => (
            <li key={dd_item.id}>{dd_item.item_name}</li>
          ))}
        </ol>
      </div>
    </Modal>
  );
}
export default MoveToLenderReviewModal;
