import '../../BDR/styles/prospects.css';
import { Breadcrumb, message, Table, Tabs } from 'antd';
import { numberToCurrency } from 'constants/numberToComma';
import { useHistory, useParams } from 'react-router-dom';
import AdminReassignModal from 'components/AdminReassignModal';
import API from 'api';
import copyIcon from 'assets/images/copy.svg';
import PaginationItemRender from 'constants/PaginationItemRender';
import React, { useEffect, useMemo, useState } from 'react';
import SectionTitle from 'components/OnBoarding/SectionTitle';

import CompanyDrawer from './CompanyDrawer';

function Company() {
  const history = useHistory();
  const { id } = useParams();
  const [company, setCompany] = useState({ loading: false });
  const [companyType, setCompanyType] = useState('Brokers');
  const [activeTab, setActiveTab] = useState(history?.location?.state?.companyTab || 'Users');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [activeModal, setActiveModal] = useState({ show: false, data: null });
  const [shouldShowAdminModal, setShouldShowAdminModal] = useState(false);

  const onCloseActiveModal = () => setActiveModal((state) => ({ ...state, show: false }));

  const onOpenActiveModal = () => setActiveModal((state) => ({ ...state, show: true }));

  const brokerColumns = {
    Borrowers: [
      {
        title: 'User name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Recent Sign In',
        dataIndex: 'last_login',
        key: 'last_login',
      },
      {
        title: 'Number of LARs',
        dataIndex: 'number_of_lars',
        key: 'number_of_lars',
      },
    ],
    Users: [
      {
        title: 'User name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Recent Sign In',
        dataIndex: 'last_login',
        key: 'last_login',
      },
      {
        title: 'Number of LARs',
        dataIndex: 'number_of_lars',
        key: 'number_of_lars',
      },
      {
        title: 'Number of Borrowers',
        dataIndex: 'number_of_borrower',
        key: 'number_of_borrower',
      },
    ],
    LARs: [
      {
        title: 'LAR ID',
        dataIndex: 'id',
        key: 'id',
        width: 125,
        render: (value) => (
          <span className="id-column">
            #{value}
            <img
              src={copyIcon}
              onClick={() => {
                message.success('Text was copied to clipboard.');
                navigator.clipboard.writeText(value);
              }}
              alt=""
            />
          </span>
        ),
      },
      {
        title: 'LAR Status',
        dataIndex: 'status',
        key: 'status',
        width: 150,
      },
      {
        title: 'LAE Name',
        dataIndex: 'lae_name',
        key: 'lae_name',
        width: 150,
      },
      {
        title: 'LAE Recent Login',
        dataIndex: 'lar_last_login',
        key: 'lar_last_login',
        width: 200,
      },
      {
        title: 'Loan Amount',
        dataIndex: 'loan_amount',
        key: 'loan_amount',
        width: 150,
        render: (value) => numberToCurrency(value),
      },
      {
        title: 'Broker Name',
        dataIndex: 'broker_name',
        key: 'broker_name',
        width: 150,
      },
      {
        title: 'Borrower Name',
        dataIndex: 'borrower_name',
        key: 'borrower_name',
        width: 150,
      },
    ],
  };
  const lenderColumns = {
    LARs: [
      {
        title: 'LAR ID',
        dataIndex: 'id',
        key: 'id',
        width: 125,
        render: (value) => (
          <span className="id-column">
            #{value}
            <img
              src={copyIcon}
              onClick={() => {
                message.success('Text was copied to clipboard.');
                navigator.clipboard.writeText(value);
              }}
              alt=""
            />
          </span>
        ),
      },
      {
        title: 'LAR Status',
        dataIndex: 'status',
        key: 'status',
        width: 150,
      },
      {
        title: 'LAE Name',
        dataIndex: 'lae_name',
        key: 'lae_name',
        width: 150,
      },
      {
        title: 'LAE Recent Login',
        dataIndex: 'lar_last_login',
        key: 'lar_last_login',
        width: 200,
      },
      {
        title: 'Loan Amount',
        dataIndex: 'loan_amount',
        key: 'loan_amount',
        width: 150,
        render: (value) => numberToCurrency(value),
      },
      {
        title: 'Broker Name',
        dataIndex: 'broker_name',
        key: 'broker_name',
        width: 150,
      },
      {
        title: 'Borrower Name',
        dataIndex: 'borrower_name',
        key: 'borrower_name',
        width: 150,
      },
    ],
    Users: [
      {
        title: 'User name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Recent Sign In',
        dataIndex: 'last_login',
        key: 'last_login',
      },
      {
        title: 'Number of LARs',
        dataIndex: 'number_of_lars',
        key: 'number_of_lars',
      },
    ],
    Mandates: [
      {
        title: 'Mandate name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Active/Not active',
        dataIndex: 'status',
        key: 'status',
        render: (bool) => (bool ? 'Active' : 'Not active'),
      },
      {
        title: 'Assigned to',
        dataIndex: 'LAE_assigned',
        key: 'LAE_assigned',
      },
      {
        title: 'Recent update',
        dataIndex: 'updated_at',
        key: 'updated_at',
      },
    ],
  };

  const tabCallback = (val) => {
    setActiveTab(val);
    setPagination((state) => ({
      ...state,
      current: 1,
    }));
    history.push({
      state: { ...history.location.state, companyTab: val },
    });
  };

  const columns = useMemo(
    () => (companyType === 'Brokers' ? brokerColumns : companyType === 'Lenders' ? lenderColumns : {}),
    // eslint-disable-next-line
    [companyType],
  );

  const goBack = () => {
    history.push({
      pathname: '/onboarding/companies/',
      state: { ...history.location.state, companyTab: activeTab },
    });
  };

  const getCompanyData = () => {
    API()
      .get('/superadmin/company', {
        params: {
          page: pagination.current,
          per_page: pagination.pageSize,
          section: activeTab.toLowerCase(),
          company_id: id,
        },
      })
      .then((res) => {
        setPagination((prevState) => ({ ...prevState, total: res.data.total }));
        setCompany({
          loading: true,
          total: res.data.total,
          name: res.data.name,
          members: res?.data?.members || [],
          mandates: res?.data?.mandates || [],
          lars: res?.data?.lars || [],
          company_data: res.data.company_data,
        });
        setCompanyType(() => {
          switch (undefined) {
            case res.data.company_data.investments:
              return 'Brokers';

            case res.data.company_data.brokerage_type:
              return 'Lenders';

            default:
              return undefined;
          }
        });
      });
  };

  useEffect(() => {
    setCompany((prevState) => ({ loading: false, name: prevState.name }));
    getCompanyData();
    // eslint-disable-next-line
  }, [activeTab, pagination.current, pagination.pageSize]);

  return (
    <>
      <div className="prospects_page_wrapper table_with_header_wrapper table_with_pagination_wrapper">
        <Breadcrumb style={{ marginBottom: 15 }}>
          <Breadcrumb.Item
            href=""
            onClick={(e) => {
              e.preventDefault();
              goBack();
            }}
          >
            Companies
          </Breadcrumb.Item>
          <Breadcrumb.Item>{company?.name}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="section_title_wrapper">
          <SectionTitle title={company?.name} />
          <div
            style={{
              marginTop: 7,
              marginLeft: 10,
            }}
            className="user-preview-wrapper"
          >
            <button onClick={onOpenActiveModal}>View Profile</button>
          </div>
        </div>
        <div className="section_table_wrapper table_with_header table_with_pagination">
          <Table
            columns={columns[activeTab]}
            loading={!company.loading}
            pagination={{
              showSizeChanger: true,
              defaultCurrent: pagination.current,
              total: pagination.total ?? null,
              itemRender: PaginationItemRender,
            }}
            onChange={(e) => setPagination(e)}
            scroll={{ x: 1500 }}
            rowKey="id"
            title={() => (
              <Tabs defaultActiveKey={activeTab} onChange={tabCallback}>
                {companyType === 'Brokers' ? (
                  <>
                    <Tabs.TabPane tab="Brokers" key="Users" />
                    <Tabs.TabPane tab="Borrowers" key="Borrowers" />
                    <Tabs.TabPane tab="LARs" key="LARs" />
                  </>
                ) : (
                  <>
                    <Tabs.TabPane tab="Lenders" key="Users" />
                    <Tabs.TabPane tab="LARs" key="LARs" />
                    <Tabs.TabPane tab="Mandates" key="Mandates" />
                  </>
                )}
              </Tabs>
            )}
            onRow={(record) => ({
              onClick: () => {
                if (activeTab === 'Users') {
                  history.push({
                    pathname: `/onboarding/companies/${id}/user/${record.id}`,
                    state: {
                      ...history.location.state,
                      companyTab: activeTab,
                      companyName: company?.name,
                    },
                  });
                }
              },
            })}
            border
            bordered={false}
            dataSource={
              activeTab === 'Mandates' ? company?.mandates : activeTab === 'LARs' ? company?.lars : company?.members
            }
          />
        </div>
      </div>
      <CompanyDrawer
        setShouldShowAdminModal={setShouldShowAdminModal}
        company={company}
        activeModal={activeModal}
        onCloseActiveModal={onCloseActiveModal}
        updateCompanyData={getCompanyData}
      />
      <AdminReassignModal
        companyId={id}
        companyName={company?.company_data?.name}
        onCancel={() => setShouldShowAdminModal(false)}
        reqRole={companyType === 'Brokers' ? 'broker' : 'lender'}
        visible={shouldShowAdminModal}
        onAfterReassign={() => {
          setShouldShowAdminModal(false);
          getCompanyData();
          onCloseActiveModal();
        }}
      />
    </>
  );
}

export default Company;
