import { Tabs, message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import API from 'api';
import Button from 'components/UI/Button';
import CreateLoanPackage from 'components/OnBoarding/DiligenceManagement/CreateLoanPackage';
import DiligenceItemPopup from 'components/LARDB/Diligence/DiligenceItemPopup/DiligenceItemPopup';
import LoanPackagesOverview from 'components/OnBoarding/DiligenceManagement/LoanPackagesOverview';
import SectionTitle from 'components/OnBoarding/SectionTitle';

function DiligenceManagementTabs({ setViewDiligenceVisible, setViewDiligence, dd_center_id, larDBData }) {
  return (
    <Tabs defaultActiveKey="1" className="custom-tabs custom-tabs_sm custom-tabs_dark">
      <Tabs.TabPane tab="Create loan package" key="1">
        <CreateLoanPackage
          setViewDiligenceVisible={setViewDiligenceVisible}
          setViewDiligence={setViewDiligence}
          dd_center_id={dd_center_id}
          larDBData={larDBData}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="View Loan packages" key="2">
        <LoanPackagesOverview
          dd_center_id={dd_center_id}
          setViewDiligenceVisible={setViewDiligenceVisible}
          setViewDiligence={setViewDiligence}
        />
      </Tabs.TabPane>
    </Tabs>
  );
}

export default function DiligenceManagement() {
  const history = useHistory();
  const [larDBData, setLarDBData] = useState({ loading: false });
  const [viewDiligence, setViewDiligence] = useState(null);
  const [viewDiligenceVisible, setViewDiligenceVisible] = useState(false);
  const { id, dd_center_id } = useParams();

  const getLARPDBData = () => {
    API()
      .get(`/LAR/dashboard/lar_dashboard?lar_id=${id}`)
      .then((res) => {
        setLarDBData((prevState) => ({
          ...prevState,
          ...res.data,
          loading: true,
        }));
      });
  };

  const deleteItem = (id) => {
    API()
      .delete(`/LAR/dd_center/item?item_id=${id}`)
      .then(() => {
        setViewDiligenceVisible(false);
        message.info('Item deleted successful!');
        getLARPDBData();
      });
  };

  useEffect(() => {
    getLARPDBData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Button
        style={{ marginBottom: 24, display: 'flex' }}
        onClick={() => history.goBack()}
        text="Back to loan processing"
      />
      <SectionTitle type="heading-20" className="heading-20" title="Diligence management" />
      <div style={{ marginTop: 14 }} />
      <DiligenceManagementTabs
        setViewDiligenceVisible={setViewDiligenceVisible}
        setViewDiligence={setViewDiligence}
        dd_center_id={dd_center_id}
        larDBData={larDBData}
      />
      <DiligenceItemPopup
        deleteItem={deleteItem}
        setViewDiligence={setViewDiligence}
        setViewDiligenceVisible={setViewDiligenceVisible}
        viewDiligence={viewDiligence}
        viewDiligenceVisible={viewDiligenceVisible}
      />
    </>
  );
}
