import { Modal, Input } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { LENDERS } from 'constants/roles';

import styles from './styles/ItemRejectionPopup.module.css';

function ItemRejectionPopup({ onCancel, onOk, visible }) {
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');
  const { role } = useSelector((state) => state.user.userData);

  function handleOk() {
    if (!reason) {
      setError('This field is required');
      return;
    }

    onOk(reason);
  }

  function handleChange(e) {
    setError('');
    setReason(e.target.value);
  }

  return (
    <Modal onCancel={onCancel} onOk={() => handleOk(reason)} open={visible} okText="Reject">
      <h2>Reject a Diligence Item</h2>

      {LENDERS.includes(role) ? (
        <>
          <p>
            When you reject this item, it will be moved to the "MLO Review" stage, and only the originator will be
            notified by email with the explanation, you write below.
          </p>
          <p>Please explain to the originator what the problem is with this item and how they can cure it.</p>
        </>
      ) : (
        <>
          <p>
            When you reject this item it will be moved to the "Owed" stage and the borrower will be notified by email
            with the explanation you write below
          </p>
          <p>Please explain to the borrower what the problem is with this item and how they can cure it.</p>
        </>
      )}

      <Input.TextArea className={error && styles.error} onChange={handleChange} rows={5} value={reason} required />
      {error && <span className={error && styles.error}>{error}</span>}
    </Modal>
  );
}

export default ItemRejectionPopup;
