import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import {
  getBaloonPayment,
  getDownPaymentCalculation,
  getInterest,
  getMonthlyPayment,
  getMonthlyValue,
  getMortgagePayment,
  getPrincipal,
} from 'utils/formula';
import closeIcon from 'assets/icons/close-white.svg';
import infoIcon from 'assets/icons/info-circle-white.svg';
import Keys from 'constants/helper';
import Modal from 'components/Modals/Modal/Modal';
import { LARAPI } from 'api';
import { closePopup, openPopup } from 'store/actions/popupsActions';
import { numberToCurrency } from 'constants/numberToComma';
import { isEmpty } from 'lodash';
import styles from './QuoteTable.module.css';
import PopupInfo from '../../PopupTable/PopupInfo';
import PopupTable from '../../PopupTable/PopupTable';
import QuoteTableHeader from './QuoteTableHeader/QuoteTableHeader';
import { fetchFeesWorksheet } from '../../../../../store/async-actions/feesWorksheet';

function QuoteTable({ larId, customQuotes, term }) {
  const [popupTableData, setPopupTableData] = useState([]);
  const dispatch = useDispatch();
  const quotesFromStorage = JSON.parse(sessionStorage.getItem(Keys.WINNER_SELECTION_PREVIEW)) || {};
  const quotes = customQuotes || quotesFromStorage[larId] || [];
  const { show: activeExitModal, data } = useSelector((state) => state.popups);
  const { feesWorksheet } = useSelector((state) => state);
  const API = useMemo(() => new LARAPI(larId), [larId]);

  const { ratesTracker } = useSelector((state) => state.lar.data);

  useEffect(() => {
    dispatch(fetchFeesWorksheet(larId));
  }, [API, larId, dispatch]);

  const handleShowInfo = (monthlyPayment, bid) => {
    setPopupData(bid);
    dispatch(openPopup({ show: 'monthlyPayment', data: { monthlyPayment, bid } }));
  };

  const handleShowVariableInfo = () => {
    dispatch(
      openPopup({
        show: 'variableInfo',
        data: 'This quote includes a variable rate, however, the figures displayed are based on calculations that use the initial interest rate throughout the entire loan period. ',
      }),
    );
  };

  const onCloseActiveModal = () => {
    dispatch(closePopup());
  };

  // TODO: after finishing backend work, need to transfer data dynamically
  function setPopupData(bid) {
    setPopupTableData([
      { label: 'Mortgage Payment', value: getMortgagePayment(bid) || 0 },
      {
        label: 'Real Estate Taxes',
        value: getMonthlyValue('real_estate_property_taxes', feesWorksheet),
      },
      {
        label: 'Property Insurance',
        value: getMonthlyValue('property_insurance', feesWorksheet),
      },
      {
        label: 'Mortgage Insurance',
        value: getMonthlyValue('mortgage_insurance', feesWorksheet),
      },
      {
        label: 'Homeowners Assn Dues',
        value: getMonthlyValue('homeowners_assn_dues', feesWorksheet),
      },
    ]);
  }

  if (isEmpty(feesWorksheet)) {
    return null;
  }

  return (
    <div className={styles.quotesList}>
      <QuoteTableHeader term={term} />

      {quotes?.map((bid) => {
        const principal = getPrincipal(bid, ratesTracker);
        const interest = getInterest(bid, ratesTracker);
        const baloon = getBaloonPayment(bid, ratesTracker);
        const total = principal + interest;
        const monthlyPayment = getMonthlyPayment(bid, feesWorksheet, ratesTracker);

        console.log(bid, 'bid');
        let interestRate = bid.interest_rate;
        if (bid.rate_type === 'Floating' && ratesTracker) {
          const variable_rate_index = ratesTracker[`${bid.variable_rate_index.toLowerCase()}_rate`];
          interestRate = variable_rate_index + bid.rate_over_index;
        }

        return (
          <div key={bid.id} className={classNames([styles.quoteItem])}>
            <div className={classNames([styles.col, styles.col1, styles.quoteMain])}>
              <div className={styles.quoteMainTitle}>Lender #{bid.number}</div>
              <div className={styles.quoteMainRow}>
                <div>Principal</div>
                <div>{numberToCurrency(principal)}</div>
              </div>
              <div className={classNames([styles.quoteMainRow, styles.quoteRowBlock])}>
                <div>Interest</div>
                <div>{numberToCurrency(interest)}</div>
              </div>
              <div className={styles.quoteMainRow}>
                <div>Total Loan Payments</div>
                <div>{numberToCurrency(total)}</div>
              </div>
              <div className={styles.quoteMainRow}>
                <div>Baloon Payment</div>
                <div>{numberToCurrency(baloon)}</div>
              </div>
            </div>
            <div className={classNames([styles.col, styles.colAccent, styles.quoteRate])}>
              {interestRate}%
              <span>
                {bid.rate_type}
                {bid.rate_type === 'Variable' && (
                  <span>
                    <Tooltip title="details">
                      <img onClick={handleShowVariableInfo} className={styles.info} src={infoIcon} alt="(i)" />
                    </Tooltip>
                  </span>
                )}
              </span>
            </div>
            <div className={classNames([styles.col, styles.colAccent, styles.quoteMonthlyPayment])}>
              {numberToCurrency(monthlyPayment)}
              <span>
                <Tooltip title="details">
                  <img
                    onClick={() => handleShowInfo(monthlyPayment, bid)}
                    className={styles.info}
                    src={infoIcon}
                    alt="(i)"
                  />
                </Tooltip>
              </span>
            </div>
            <div className={classNames([styles.col, styles.col5, styles.quoteDownPayment])}>
              {getDownPaymentCalculation(feesWorksheet, bid)}
            </div>
          </div>
        );
      })}
      <Modal
        className={styles.modal}
        closeIcon={<img src={closeIcon} alt="X" />}
        bodyStyle={{ backgroundColor: '#262626', padding: 0 }}
        footer=""
        onCancel={onCloseActiveModal}
        visible={activeExitModal === 'monthlyPayment'}
      >
        <PopupTable title="Monthly Payment" data={popupTableData} />
      </Modal>
      <Modal
        className={styles.modal}
        closeIcon={<img src={closeIcon} alt="X" />}
        bodyStyle={{ backgroundColor: '#262626', padding: 0 }}
        footer=""
        onCancel={onCloseActiveModal}
        visible={activeExitModal === 'variableInfo'}
      >
        <PopupInfo title="Variable Rate Disclaimer" text={data} />
      </Modal>
    </div>
  );
}

export default QuoteTable;
