import React from 'react';
import './styles/status-indicator.css';

function StatusIndicator({ status, active }) {
  return (
    <div className="status-indicator-wrapper">
      <div style={{ backgroundColor: active ? '#52C41A' : '#FF4D4F' }} className="status-indicator" />
      <span className="status">{status}</span>
    </div>
  );
}

export default StatusIndicator;
