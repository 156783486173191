import './styles/associate_new_users_page.css';
import 'components/CustomComponents/styles/custom_breadcrumb.css';
import { Breadcrumb, message as toast } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { SettingsAPI } from 'api';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import regExp from 'constants/regExp';
import InviteUsersForm from 'components/OnBoarding/Users/InviteUsers/InviteUsersForm';
import ManualEMSModal from 'components/Modals/ManualEMSModal';

export default function AssociateNewUsers({ roles }) {
  const roleList = roles ?? ['Broker Loan Manager', 'Broker Transaction Manager'];
  const [activeModal, setActiveModal] = useState({ show: '', data: null });
  const [error, setError] = useState({});
  const [userList, setUserList] = useState({
    dataSource: [],
    count: 0,
  });
  const { slug } = useParams();
  const history = useHistory();
  const { role, email } = useSelector((store) => store.user.userData);
  const API = useMemo(() => new SettingsAPI(), []);

  const onCloseActiveModal = () => setActiveModal({ show: '', data: null });

  const openSendInvitesModal = () => {
    setActiveModal({
      show: 'send_invites',
      data: {
        mainRecipient: userList.dataSource.map((u) => ({
          email: u.email,
          full_name: `${u.name} ${u.surname}`,
          name: u.name,
          role_name: u.role_name,
          surname: u.surname,
        })),
      },
    });
  };

  function handleDeleteUserInvite(index) {
    setUserList((prev) => ({
      count: prev.count - 1,
      dataSource: prev.dataSource.filter((item) => item.index !== index),
    }));
  }

  const handleUpdateUserData = (index, data) => {
    if (data.email) {
      setError({});
    }
    const newDataSource = [...userList.dataSource];
    newDataSource[index] = {
      ...newDataSource[index],
      ...data,
    };
    setUserList((prev) => ({
      count: prev.count,
      dataSource: newDataSource,
    }));
  };

  const settingsAPI = useMemo(() => new SettingsAPI(), []);
  const handleSubmitInvitations = useCallback(
    ({ to, message, send_copy_to_self }) => {
      settingsAPI
        .inviteCompanyMember(to, message, send_copy_to_self)
        .then(() => {
          onCloseActiveModal();
          toast.success('Invitation(s) sent!').then(() => {
            history.push(history.location.state?.link || `/onboarding/${slug}/`);
          });
        })
        .catch((e) => {
          if (e?.response?.data?.error === 'This user is already associated with company') {
            toast.error('At least one of the emails in the list is already associated with a company');
          } else {
            toast.error(e?.response?.data?.error || 'ERROR!');
          }
        });
    },
    [role, settingsAPI, history, slug],
  );

  function handleCrateUser({ role = roleList[0] }) {
    setUserList((prev) => ({
      dataSource: [
        ...prev.dataSource,
        {
          role_name: role,
          key: (prev.dataSource[prev.dataSource.length - 1]?.key || 0) + 1,
          index: prev.dataSource.length,
        },
      ],
      count: prev.count + 1,
    }));
  }

  const handleAddNewUser = (index) => {
    setError({});
    const data = userList.dataSource.find((user) => user.index === index);
    const emailDomain = `@${email.split('@').at(-1)}`;

    if (userList.dataSource.some((i) => i.email === data.email + emailDomain)) {
      return setError({ email: 'This email is already on the invitations list' });
    }

    if (!data.email) {
      return setError({ email: 'Field cannot be empty' });
    }

    if (regExp.email.test(data.email)) {
      return setError({ email: 'Wrong email format' });
    }

    if (!data.name) {
      return setError({ name: 'Field cannot be empty' });
    }

    if (!data.surname) {
      return setError({ surname: 'Field cannot be empty' });
    }

    API.checkEmailAvailability(data.email + emailDomain).then((available) => {
      if (!available) {
        return setError({ email: 'This email is already taken' });
      }

      const newUserList = [...userList.dataSource];
      newUserList[index].email = data.email + emailDomain;
      newUserList[index].full_name = `${data.name} ${data.surname}`;

      setUserList((prev) => ({
        ...prev,
        dataSource: newUserList,
      }));
    });
  };

  return (
    <div className="associate-users-page">
      <div className="associate-users-page__wrapper">
        <div className="associate-users-page__breadcrumb-container">
          <Breadcrumb id="custom-breadcrumb">
            <Breadcrumb.Item>
              <Link to={history.location.state.link || `/onboarding/${slug}/`}>Users</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Associate new team members</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="associate-users-page__invite-form-container">
          <InviteUsersForm
            email={email}
            error={error}
            role={role}
            userList={userList}
            roleList={roleList}
            onAddToTable={handleCrateUser}
            onSaveUserInTable={handleAddNewUser}
            onDeleteUser={handleDeleteUserInvite}
            openSendInvitesModal={openSendInvitesModal}
            onUpdateUserData={handleUpdateUserData}
          />
          <ManualEMSModal
            mainRecipient={activeModal.data?.mainRecipient}
            onCancel={onCloseActiveModal}
            onSubmit={handleSubmitInvitations}
            templateRoute="/user_settings/company/members"
            title="Send company invite"
            visible={activeModal.show === 'send_invites'}
          />
        </div>
      </div>
    </div>
  );
}
