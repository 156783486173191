import { Button, Checkbox, Divider, Drawer, Input, Space, Tag } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import classes from './styles/SelectRequestDrawer.module.css';
import CustomButton from '../../UI/Button';
import { CloseIcon } from '../../../assets/icons/icons';
import classesForm from '../../Modals/ManualEMSModal/ManualEMSModal.module.css';
import API from '../../../api';

function SendInviteDrawer({
  visible,
  onClose,
  mainRecipient,
  onCancel,
  onSubmit,
  optionalRecipients = [],
  templateRoute,
}) {
  const [selectedIds, toggleSelectedId] = useReducer((state, id) => {
    if (undefined === id) {
      return new Set();
    }

    const copy = new Set(state);
    state.has(id) ? copy.delete(id) : copy.add(id);
    return copy;
  }, new Set());
  const [message, setMessage] = useState('');
  const [sendCopyToSelf, setSendCopyToSelf] = useState(false);
  const [template, setTemplate] = useState('');
  const currentUser = useSelector((s) => s.user.userData);

  const selectedRecipients = useMemo(
    () =>
      optionalRecipients
        .map((category) => category.items)
        .flat()
        .filter((recipient) => selectedIds.has(recipient.user_id)),
    [optionalRecipients, selectedIds],
  );

  function handleSubmit() {
    onSubmit({
      cc: Array.from(selectedIds),
      message,
      send_copy_to_self: sendCopyToSelf,
    });
  }

  // Clean up after closing modal
  useEffect(() => {
    if (visible) {
      return;
    }

    setSendCopyToSelf(false);
    setMessage('');
    toggleSelectedId();
  }, [visible]);

  useEffect(() => {
    if (!visible || !templateRoute) {
      return;
    }

    API()
      .get(templateRoute)
      .then(({ data }) => {
        setTemplate('email_template' in data ? data.email_template : data);
      });
  }, [visible, templateRoute]);

  return (
    <Drawer width={450} title="Send loan quote request" placement="right" onClose={onClose} visible={visible}>
      <div className={classes.body}>
        <Space size={24} direction="vertical" style={{ width: '100%' }}>
          <div className={classesForm.recipientsWrapper}>
            <label>{`${optionalRecipients.length > 0 ? 'Main r' : 'R'}ecipient (To:)`}</label>
            <div className={classes.selectedRecipients}>
              {Array.isArray(mainRecipient) ? (
                mainRecipient.map((r) => <Tag key={r.full_name}>{r.full_name}</Tag>)
              ) : (
                <Tag>{mainRecipient?.full_name || template?.users?.to?.name}</Tag>
              )}
            </div>
            {selectedIds.size > 0 && (
              <>
                <Divider />
                <label>Selected recipients (CC:)</label>
                <div className={classes.selectedRecipients}>
                  {selectedRecipients.map(({ user_id, full_name }) => (
                    <Tag key={user_id} onClick={() => toggleSelectedId(user_id)}>
                      <CloseIcon />
                      {full_name}
                    </Tag>
                  ))}
                </div>
              </>
            )}
            {optionalRecipients.length > 0 && (
              <>
                <Divider />
                <label>Available recipients</label>
                <div className={classesForm.suggestedRecipients}>
                  {optionalRecipients.map(({ items, label }) => (
                    <div key={label}>
                      <label>{label}:</label>
                      <div>
                        {items.map(({ user_id, full_name }) => (
                          <Button key={user_id} onClick={() => toggleSelectedId(user_id)}>
                            {full_name}
                          </Button>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className={classesForm.templateWrapper}>
            <label>Message</label>
            <div>
              <b>
                From: {currentUser.name} {currentUser.surname}
              </b>
              <br />
              <b>
                Subject:
                {template.subject}
              </b>
              <p>{ReactHtmlParser(template.message)}</p>
              <p>{'<Link to this item appears here>'}</p>
            </div>
          </div>
          <div className={classesForm.messageWrapper}>
            <label>Add a personalised message (Optional)</label>
            <Input.TextArea
              onChange={({ target: { value: v } }) => setMessage(v)}
              placeholder="Message"
              rows={4}
              value={message}
            />
            <Checkbox checked={sendCopyToSelf} onChange={({ target: { checked: c } }) => setSendCopyToSelf(c)}>
              Send copy to myself
            </Checkbox>
          </div>
          <Space style={{ width: '100%' }} size={22}>
            <CustomButton onClick={onCancel} style={{ width: 190 }} text="Cancel" />
            <CustomButton
              onClick={() => handleSubmit()}
              style={{ width: 190 }}
              className="btn_darkblue"
              text="Send Invite"
            />
          </Space>
        </Space>
      </div>
    </Drawer>
  );
}
export default SendInviteDrawer;
