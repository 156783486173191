import './styles/add_prospect_modal.css';
import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter';
import { Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import API from 'api';
import CustomButton from 'components/UI/Button';
import DropdownMenu from 'components/CustomComponents/DropdownMenu';
import PhoneComponent from 'components/CustomComponents/PhoneInput';
import regExp from 'constants/regExp';
import TextInput from 'components/CustomComponents/TextInput';
import useNestedCheckError from 'constants/useNestedCheckError';

const initState = {
  email: {
    error: null,
    errorMessage: "Please enter user's email address",
    required: true,
    value: '',
  },
  phone: {
    error: null,
    errorMessage: "Please enter user's phone number",
    required: false,
    value: '',
  },
  name: {
    error: null,
    errorMessage: "Please enter user's name",
    required: true,
    value: '',
  },
  company_name: {
    error: null,
    errorMessage: "Please enter user's company name",
    required: true,
    value: '',
  },
  surname: {
    error: null,
    errorMessage: "Please enter user's surname",
    required: true,
    value: '',
  },
  user_type: {
    error: null,
    errorMessage: 'Please enter user type',
    required: true,
    value: 'Broker',
  },
};

export default function AddProspectModal({ activeModal, onClose, setProspects }) {
  const [fields, setFields] = useState(initState);
  const [responseError, setResponseError] = useState(null);
  const { checkEmpty } = useNestedCheckError(fields, setFields);

  useEffect(() => {
    if (activeModal.data?.id && activeModal.data?.full_name) {
      const splitFullName = activeModal.data?.full_name.split(' ');

      setFields((prev) => ({
        email: {
          ...prev.email,
          value: activeModal.data?.email ?? '',
        },
        phone: {
          ...prev.phone,
          value: activeModal.data?.phone ?? '',
        },
        name: {
          ...prev.name,
          value: splitFullName[0] ?? '',
        },
        surname: {
          ...prev.surname,
          value: splitFullName[1] ?? '',
        },
      }));
    }
  }, [activeModal.data]);

  const onSubmit = (event) => {
    event?.preventDefault();
    const { error, obj } = checkEmpty(fields);
    setFields(obj);

    if (error) {
      return;
    }

    if (fields.email.value.length < 8) {
      setFields((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          error: true,
          errorMessage: 'Enter at least 8 characters',
        },
      }));
      return;
    }

    if (fields.phone.value.length < 10 && fields.phone.value.length > 0) {
      setFields((prev) => ({
        ...prev,
        phone: {
          ...prev.phone,
          error: true,
          errorMessage: 'Phone number must be 10 digits long',
        },
      }));
      return;
    }

    if (!fields.company_name.value) {
      setFields((prev) => ({
        ...prev,
        company_name: {
          ...prev.company_name,
          error: true,
          errorMessage: 'Company name is required',
        },
      }));
      return;
    }

    if (!regExp.email.test(fields.email.value)) {
      setFields((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          error: true,
          errorMessage: 'Incorrect email format',
        },
      }));
      return;
    }

    API()
      .post('/prospects/BDR', {
        email: fields.email.value,
        id: activeModal.data?.id || undefined,
        name: fields.name.value,
        company_name: fields.company_name.value,
        phone: Number(fields.phone.value),
        surname: fields.surname.value,
        user_type: fields.user_type?.value?.toLocaleLowerCase() || 'broker',
      })
      .then((res) => {
        if (activeModal.data?.id) {
          setProspects((prev) => ({
            ...prev,
            users: prev.users.map((user) =>
              user.id === activeModal.data?.id
                ? {
                    ...user,
                    ...res.data,
                  }
                : user,
            ),
          }));
        } else {
          setProspects((prev) => ({
            ...prev,
            users: [res.data, ...prev.users],
          }));
        }
        onClose();
        setResponseError(null);

        message.success(`You successfully ${activeModal.data?.id ? 'changed' : 'added'} a prospect`);
        setFields(initState);
      })
      .catch((e) => {
        if (e.response?.data?.error) {
          setResponseError(e.response.data.error);
        }
      });
  };

  useEffect(() => {
    if (activeModal.show !== 'change_prospect') {
      setFields(initState);
    }
  }, [activeModal.show]);

  function onChange({ target: { value, name } }) {
    setFields((prev) => ({
      ...prev,
      [name]: {
        value,
        error: null,
      },
    }));

    setResponseError(null);
  }

  return (
    <Modal
      className="wrapper_prospect"
      title={
        <div className="wrapper_prospect__title">
          <span className="title">{activeModal.data?.id ? 'Edit' : 'Add a'} prospect</span>
          {activeModal.data?.id || <span className="description">Add a new prospect to the list</span>}
        </div>
      }
      open={activeModal.show === 'add_prospect' || activeModal.show === 'change_prospect'}
      centered
      footer={
        <div className="wrapper_add_prospect_btns">
          {responseError ? <p className="errorMessage">{capitalizeFirstLetter(responseError)}</p> : null}
          <CustomButton key="back" onClick={onClose} text="Cancel" />
          <CustomButton key="submit" onClick={onSubmit} primary text="Save" type="submit" />
        </div>
      }
      onCancel={() => {
        onClose();
        setFields(initState);
      }}
    >
      <form className="wrapper_add_prospect_form" onSubmit={onSubmit}>
        <TextInput {...fields.name} htmlFor="name" maxlength={255} onChange={onChange} title="First name" />
        <TextInput {...fields.surname} htmlFor="surname" maxlength={255} onChange={onChange} title="Last name" />
        <TextInput
          {...fields.company_name}
          htmlFor="company_name"
          maxlength={255}
          onChange={onChange}
          title="Company name"
        />
        <TextInput {...fields.email} htmlFor="email" maxlength={255} onChange={onChange} title="Email" />
        <PhoneComponent
          {...fields.phone}
          title="Phone"
          onChange={(value) => {
            setFields((prev) => ({
              ...prev,
              phone: {
                value,
                error: null,
              },
            }));
          }}
        />
        {Boolean(activeModal.data?.id) || (
          <DropdownMenu
            {...fields.user_type}
            onSelect={({ preview }) =>
              onChange({
                target: {
                  name: 'user_type',
                  value: preview,
                },
              })
            }
            title="User type"
            options={[{ preview: 'Broker' }, { preview: 'Lender' }]}
          />
        )}
      </form>
    </Modal>
  );
}
