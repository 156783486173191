import { memo } from 'react';
import GenericIcon from './GenericIcon';

export default memo(({ ...params }) => (
  <GenericIcon {...params}>
    <path
      d="M5 20.5C6.10457 20.5 7 19.6046 7 18.5C7 17.3954 6.10457 16.5 5 16.5C3.89543 16.5 3 17.3954 3 18.5C3 19.6046 3.89543 20.5 5 20.5Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 8.5C20.1046 8.5 21 7.60457 21 6.5C21 5.39543 20.1046 4.5 19 4.5C17.8954 4.5 17 5.39543 17 6.5C17 7.60457 17.8954 8.5 19 8.5Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 8.5V13.5C19 14.8261 18.4732 16.0979 17.5355 17.0355C16.5979 17.9732 15.3261 18.5 14 18.5H11M11 18.5L14 15.5M11 18.5L14 21.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 16.5V11.5C5 10.1739 5.52678 8.90215 6.46447 7.96447C7.40215 7.02678 8.67392 6.5 10 6.5H13M13 6.5L10 3.5M13 6.5L10 9.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </GenericIcon>
));
