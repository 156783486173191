import { FileInvoiceIcon } from '../assets/icons';
import docxIcon from '../assets/icons/fileTypes/DOC_File.png';
import xlsxIcon from '../assets/icons/fileTypes/XLS_File.png';
import pptxIcon from '../assets/icons/fileTypes/PPT_File.png';
import pdfIcon from '../assets/icons/fileTypes/PDF_File.png';
import pngIcon from '../assets/icons/fileTypes/PNG_File.png';
import jpgIcon from '../assets/icons/fileTypes/JPG_File.png';
import txtIcon from '../assets/icons/fileTypes/TXT_File.png';
import * as extensions from '../constants/fileExtensions';
import { DOCS_LIST } from '../constants/fileExtensions';

export function humanFileSize(bytes, si = true, dp = 1) {
  if (!bytes) {
    return '';
  }

  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return `${bytes.toFixed(dp)} ${units[u]}`;
}

export const getFileIcon = (type) => {
  switch (type?.toLowerCase()) {
    case extensions.DOC:
    case extensions.DOCX:
      return <img src={docxIcon} alt="docx" />;
    case extensions.XLS:
    case extensions.XLSX:
      return <img src={xlsxIcon} alt="xlsx" />;
    case extensions.PPT:
    case extensions.PPTX:
      return <img src={pptxIcon} alt="pptx" />;
    case extensions.PDF:
      return <img src={pdfIcon} alt="pdf" />;
    case extensions.PNG:
      return <img src={pngIcon} alt="pdf" />;
    case extensions.JPG:
      return <img src={jpgIcon} alt="jpg" />;
    case extensions.TXT:
    case extensions.TXT_FILE:
      return <img src={txtIcon} alt="txt" />;
    default:
      return <FileInvoiceIcon size="5em" strokeWidth={1} />;
  }
};

export const isDocType = (type) => DOCS_LIST.includes(type);

export const onDownloadFile = (url, fileName) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};

export function getFileTypeFromAWSUrl(url) {
  try {
    const parsedUrl = new URL(url);

    const pathname = parsedUrl.pathname;

    const filename = pathname.substring(pathname.lastIndexOf('/') + 1);

    const fileType = filename.substring(filename.lastIndexOf('.') + 1);

    return fileType || null;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
}
