import classNames from 'classnames';
import classes from './styles/BannerColourSchemeSelect.module.css';

export default function BannerColourSchemeSelect({ options = [], value = '', setValue = () => null }) {
  return (
    <div className={classes.root}>
      {options.map((o) => (
        <div
          className={classNames({
            [classes.schemeOption]: true,
            [classes.selectedSchemeOption]: o.value === value,
          })}
          key={o.value}
          onClick={() => setValue(o.value)}
        >
          <div style={{ backgroundColor: o.colourA }} />
          <div style={{ backgroundColor: o.colourB }} />
        </div>
      ))}
    </div>
  );
}
