import styles from './styles.module.css';

export default function ReadyLaunchDescription() {
  return (
    <div className={styles.container}>
      <p className={styles.title}>We're all set and ready to invite lenders to review and quote your deal.</p>
      <p className={styles.subTitle}>
        When you select "Launch Now" our SmartMatch Al will review every lender loan program in our member network and
        invite only the lenders with the most relevant loan programs to review your loan request
      </p>
      <p className={styles.subTitle}>
        Some lenders move very fast and provide a response or communicate with you within minutes. You can monitor
        lender activity from your Scenario Pipeline table on your Dashboard.
      </p>
    </div>
  );
}
