import './styles/registration.css';
import { message as toast } from 'antd';
import { setUserData } from 'store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import API, { BDRAPI } from 'api';
import Button from 'components/UI/Button';
import CustomButton from 'components/UI/Button';
import fillError from 'constants/fillRegistrationErrors';
import fillFields from 'constants/fillRegistrationFields';
import Keys from 'constants/helper';
import ManualEMSModal from 'components/Modals/ManualEMSModal';
import Modal from 'components/Modals/Modal/Modal';
import otherConst from 'constants/otherConst';
import RegistrationSubHeader from 'components/Registration/RegistrationSubHeader';
import RenderInputs from 'components/RenderInputs';
import { getUserFromStorage } from 'utils/storage';
import { closePopup } from '../../../store/actions/popupsActions';

const radioButtons = ['Account Executive', 'Transaction Manager', 'Loan Underwriter', 'Asset Manager', 'Executive'];

const initialFields = {
  sectionTitle_0: {
    title: 'Сreate Lender User Profile',
    style: {
      fontWeight: 400,
    },
  },
  sideTitle_1: {
    title: 'Personal Information',
    bordered: true,
    borderTitleDistance: '12px',
  },
  dividedInputs_1: {
    style: {
      marginTop: '12px',
    },
    inputs: {
      name: {
        value: '',
        error: null,
        errorMessage: 'Fill this label',
        title: 'First Name',
      },
      surname: {
        value: '',
        error: null,
        errorMessage: 'Fill this label',
        title: 'Last Name',
      },
    },
  },
  phone: {
    value: '',
    error: null,
    regex: '^[0-9]*$',
    errorMessage: 'Fill this label',
    title: 'Mobile phone',
  },
  work_phone: {
    error: null,
    errorMessage: 'Fill this label',
    optional: true,
    title: 'Work phone',
    type: 'number',
    value: '',
  },
  sideTitle_2: {
    title: 'Professional Information',
    bordered: true,
    borderTitleDistance: '30px',
  },
  sectionTitle_1: {
    title: 'Your job title',
    type: 'heading-20',
    style: {
      margin: '30px 0 16px',
    },
  },
  radioButtons_job_title: {
    value: 'Account Executive',
    inputValueRoute: 'otherJobTitle.value',
    gap: 16,
    buttons: {
      account_executive: {
        value: 'Account Executive',
        name: 'Account Executive',
      },
      transaction_manager: {
        value: 'Transaction Manager',
        name: 'Transaction Manager',
      },
      loan_underwriter: {
        value: 'Loan Underwriter',
        name: 'Loan Underwriter',
      },
      asset_manager: {
        value: 'Asset Manager',
        name: 'Asset Manager',
      },
      executive: {
        value: 'Executive',
        name: 'Executive',
      },
      other: {
        value: 'Other',
        name: 'Other',
      },
    },
  },
  otherJobTitle: {
    value: '',
    error: null,
    errorMessage: 'Fill this label',
    optional: true,
    skip: true,
    style: { marginTop: 0 },
    disabled: { route: 'radioButtons_job_title.value', operator: false, value: 'Other' },
  },
  sectionTitle_3: {
    title: 'Office Location',
    type: 'heading-20',
    style: {
      margin: '38px 0 24px',
    },
  },
  checkbox_useCompanyAddress: {
    boxes: {
      useCompanyAddress: {
        title: 'Use company address',
        optional: true,
        skip: true,
        style: { margin: 0 },
        value: false,
      },
    },
  },
  dividedInputs_4: {
    inputs: {
      city: {
        title: 'City',
        value: '',
        error: null,
        errorMessage: 'Fill this label',
      },
      state: {
        title: 'State',
        value: '',
        error: null,
        errorMessage: 'Fill this label',
        type: 'select',
        options: otherConst.states,
      },
    },
  },
  dividedInputs_5: {
    inputs: {
      zip: {
        title: 'Zip',
        value: '',
        maxLength: 5,
        error: null,
        regex: '^[0-9]*$',
        errorMessage: 'Fill this label',
      },
    },
  },
};

export default function LenderUser({ isTeam }) {
  const [fields, setFields] = useState(initialFields);
  const [activeModal, setActiveModal] = useState({ show: false, data: {} });
  const [approve, setApprove] = useState(false);
  const [companyAddress, setCompanyAddress] = useState({});
  const [disabledAction, setDisabledAction] = useState(true);
  const [edit, setEdit] = useState(null);
  const [prospectData, setProspectData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const role = useSelector((store) => store.user.userData?.role);
  const activeExitModal = useSelector((state) => state.popups.show);
  const prospectId = useMemo(() => Number(new URLSearchParams(location.search).get('id')), [location]);
  const isLender = /lender/i.test(role);

  const progress = isTeam
    ? [
        {
          title: 'Registration',
          completed: !disabledAction,
        },
      ]
    : [
        {
          title: 'Company Profile',
          completed: true,
          onClick: () => onSave('link', `/registration/lender/company${location.search}` ?? ''),
          clickCondition: true,
        },
        {
          title: 'Personal Profile',
          completed: !disabledAction,
        },
      ];

  useEffect(() => {
    if (prospectData.email && !activeModal.data.email) {
      setActiveModal((prev) => ({ ...prev, data: { ...prev.data, email: prospectData.email } }));
    }

    // eslint-disable-next-line
  }, [activeModal]);

  const autocompleteAddressFields = (address, city, state, zip) => {
    onChange({ target: { value: address } }, 'street_address');
    onChange({ target: { value: city } }, 'dividedInputs_4.inputs.city');
    onChange({ target: { value: state.value ?? state } }, 'dividedInputs_4.inputs.state');
    onChange({ target: { value: zip } }, 'dividedInputs_5.inputs.zip');
  };

  const onChange = (event, route) => {
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const { value } = event.target;

    setFields((prev) => {
      const tempFields = { ...prev };

      if (routeLength === 3) {
        tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
          ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
          value,
          error: false,
        };
      } else if (routeLength === 2) {
        tempFields[routeArr[0]][routeArr[1]] = {
          ...tempFields[routeArr[0]][routeArr[1]],
          value,
          error: false,
        };
      } else if (routeLength === 1) {
        if (route.includes('job_title')) {
          tempFields.otherJobTitle.value = '';
        }
        tempFields[routeArr[0]] = { ...tempFields[routeArr[0]], value, error: false };
      }

      return tempFields;
    });
  };

  const selectTag = (value, route) => {
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const tempFields = { ...fields };

    if (routeLength === 3) {
      const nestedValue = tempFields[routeArr[0]][routeArr[1]][routeArr[2]];
      tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
        ...nestedValue,
        tags: [...nestedValue.tags, value],
        error: false,
      };
    } else if (routeLength === 2) {
      const nestedValue = tempFields[routeArr[0]][routeArr[1]];
      tempFields[routeArr[0]][routeArr[1]] = {
        ...nestedValue,
        tags: [...nestedValue.tags, value],
        error: false,
      };
    } else if (routeLength === 1) {
      const nestedValue = tempFields[routeArr[0]];
      tempFields[routeArr[0]] = {
        ...nestedValue,
        tags: [...nestedValue.tags, value],
        error: false,
      };
    }

    setFields(tempFields);
  };

  const onTagDelete = (item, route) => {
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const tempFields = { ...fields };

    if (routeLength === 3) {
      const nestedValue = tempFields[routeArr[0]][routeArr[1]][routeArr[2]];
      tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
        ...nestedValue,
        tags: nestedValue.tags.filter((i) => i !== item),
      };
    } else if (routeLength === 2) {
      const nestedValue = tempFields[routeArr[0]][routeArr[1]];
      tempFields[routeArr[0]][routeArr[1]] = {
        ...nestedValue,
        tags: nestedValue.tags.filter((i) => i !== item),
      };
    } else if (routeLength === 1) {
      const nestedValue = tempFields[routeArr[0]];
      tempFields[routeArr[0]] = {
        ...nestedValue,
        tags: nestedValue.tags.filter((i) => i !== item),
      };
    }

    setFields(tempFields);
  };

  const onUpload = (event, route) => {
    event.preventDefault();
    const file = event.target.files[0];
    const routeArr = route.split('.');
    const routeLength = routeArr.length;
    const tempFields = { ...fields };

    if (!file) {
      return;
    }

    if (routeLength === 3) {
      tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
        ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
        value: URL.createObjectURL(file),
        file,
      };
    } else if (routeLength === 2) {
      tempFields[routeArr[0]][routeArr[1]] = {
        ...tempFields[routeArr[0]][routeArr[1]],
        value: URL.createObjectURL(file),
        file,
      };
    } else if (routeLength === 1) {
      tempFields[routeArr[0]] = {
        ...tempFields[routeArr[0]],
        value: URL.createObjectURL(file),
        file,
      };
    }

    setFields(tempFields);
  };

  useEffect(() => {
    let unfilledFieldsCount = 0;
    Object.entries(fields).forEach((i) => {
      const field = i[1];
      if (i[0].includes('dividedInputs')) {
        Object.entries(field.inputs).forEach((item) => {
          const input = item[1];
          if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
            unfilledFieldsCount += 1;
          }
        });
      } else if (i[0].includes('radioButtons')) {
        // eslint-disable-line
      } else if (i[0].includes('switchButtons')) {
        // eslint-disable-line
      } else if (i[0].includes('selectTags')) {
        if (!i[1].optional && !i[1].tags.length) {
          unfilledFieldsCount += 1;
        }
      } else if (i[0].includes('upload')) {
        const upload = i[1];
        if (!upload.optional && !upload.file && !upload.value) {
          unfilledFieldsCount += 1;
        }
      } else if (i[0].includes('checkbox')) {
        // eslint-disable-line
      } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle')) {
        // eslint-disable-line
      } else {
        const input = i[1];
        if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
          unfilledFieldsCount += 1;
        }
      }
    });

    setDisabledAction(!!unfilledFieldsCount);
  }, [fields]);

  const getNestedValueByRoute = (value, route) => {
    if (route) {
      const routeArr = route.split('.');
      const nextKey = routeArr[0];
      return getNestedValueByRoute(value[nextKey], routeArr.slice(1).join('.'));
    }
    return value;
  };

  useEffect(() => {
    function handleResponse({ data }) {
      const { city, state, street_address, zip } = isTeam ? data.company_address : data;
      setCompanyAddress({
        city,
        state,
        street_address,
        zip,
      });
      window.scrollTo(0, 0);
    }

    if (isTeam) {
      API().get('/user_settings/company/members/info').then(handleResponse);
    } else {
      API()
        .get('/prospects/BDR/company', { params: { id: prospectId } })
        .then(handleResponse);
    }
  }, [isTeam, prospectId]);

  const onError = (routes) => {
    const tempFields = { ...fields };

    routes.forEach((route) => {
      const routeArr = route.split('.');
      const routeLength = routeArr.length;

      if (routeLength === 3) {
        tempFields[routeArr[0]][routeArr[1]][routeArr[2]] = {
          ...tempFields[routeArr[0]][routeArr[1]][routeArr[2]],
          error: true,
        };
      } else if (routeLength === 2) {
        tempFields[routeArr[0]][routeArr[1]] = {
          ...tempFields[routeArr[0]][routeArr[1]],
          error: true,
        };
      } else if (routeLength === 1) {
        tempFields[routeArr[0]] = { ...tempFields[routeArr[0]], error: true };
      }
    });

    setFields(tempFields);
  };

  useEffect(() => {
    if (fields.checkbox_useCompanyAddress) {
      const useAddress = fields.checkbox_useCompanyAddress.boxes.useCompanyAddress.value;
      const companyData = useAddress
        ? { ...companyAddress }
        : {
            city: '',
            state: '',
            street_address: '',
            zip: '',
          };

      fillFields(companyData, useAddress, fields, setFields);
    }

    // eslint-disable-next-line
  }, [fields.checkbox_useCompanyAddress?.boxes.useCompanyAddress.value]);

  const checkForErrors = () => {
    const routes = [];
    const temp = { ...fields };
    Object.entries(temp).forEach((i) => {
      const field = i[1];
      if (i[0].includes('dividedInputs')) {
        Object.entries(field.inputs).forEach((item) => {
          const input = item[1];
          if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
            routes.push(`${i[0]}.inputs.${item[0]}`);
          } else if (item[0] === 'year_established' && input.value.length < 4) {
            routes.push(`${i[0]}`);
          }
        });
      } else if (i[0].includes('radioButtons')) {
        // eslint-disable-line
      } else if (i[0].includes('switchButtons')) {
        // eslint-disable-line
      } else if (i[0].includes('selectTags')) {
        if (!i[1].optional && !i[1].tags.length) {
          routes.push(`${i[0]}`);
        }
      } else if (i[0].includes('upload')) {
        // eslint-disable-line
      } else if (i[0].includes('checkbox')) {
        // eslint-disable-line
      } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle')) {
        // eslint-disable-line
      } else {
        const input = i[1];

        if (!input.optional && (input.value === '' || input.value === null || input.value === undefined)) {
          routes.push(`${i[0]}`);
        } else if (i[0] === 'year_established' && input.value.length < 4) {
          routes.push(`${i[0]}`);
        }
      }
    });

    window.scrollTo(0, 0);
    onError(routes);
  };

  function onSave(type, link, submitData) {
    if (submitData) {
      submitData.e?.preventDefault();
    }
    const request = {};
    const temp = { ...fields };

    if (disabledAction && type === 'submit') {
      checkForErrors();
    } else {
      Object.entries(temp).forEach((i) => {
        const field = i[1];
        if (i[0].includes('dividedInputs')) {
          Object.entries(field.inputs).forEach((item) => {
            if (!item[1].skip) {
              request[item[0]] = item[1].value;
            }
          });
        } else if (i[0].includes('radioButtons')) {
          if (!i[1].skip) {
            const fieldName = i[0].split('_').slice(1).join('_');
            const inputValue = getNestedValueByRoute(temp, i[1].inputValueRoute);
            if (inputValue) {
              request[fieldName] = inputValue;
            } else {
              request[fieldName] = field.value;
            }
          }
        } else if (i[0].includes('switchButtons')) {
          Object.entries(field.switches).forEach((item) => {
            if (!item[1].skip) {
              request[item[0]] = item[1].value;
            }
          });
        } else if (i[0].includes('selectTags')) {
          if (!i[1].skip) {
            const fieldName = i[0].split('_').slice(1).join('_');
            request[fieldName] = i[1].tags.join(', ');
          }
        } else if (i[0].includes('upload')) {
          if (!i[1].skip && i[1].file) {
            const fieldName = i[0].split('_').slice(1).join('_');
            request[fieldName] = i[1].file;
          }
        } else if (i[0].includes('checkbox')) {
          Object.entries(field.boxes).forEach((item) => {
            if (!item[1].skip) {
              request[item[0]] = item[1].value;
            }
          });
        } else if (i[0].includes('sectionTitle') || i[0].includes('sideTitle') || i[0].includes('selectTags')) {
          // eslint-disable-line
        } else if (!i[1].skip) {
          request[i[0]] = field.value;
        }
      });

      const finalObj = {};

      Object.entries(request).forEach((i) => {
        if (Array.isArray(i[1]) ? i[1].length : i[1] || i[1] === false || i[1] === 0) {
          finalObj[i[0]] = i[1];
        }
      });

      if (prospectId) {
        finalObj.id = prospectId;
      }

      API()
        .post(isTeam ? '/user_settings/company/members/new-member' : '/prospects/BDR/profile', finalObj)
        .then(({ data }) => {
          if (!data.error) {
            if (type === 'submit') {
              if (isTeam) {
                history.push('/onboarding/dashboard');
              }
            } else if (type === 'link') {
              history.push(link);
            } else if (type === 'send_verification') {
              submitAgreementUser();
            } else {
              toast.success('Lender User Profile data successfully saved!');
            }
          }
        })
        .catch(({ response }) => {
          const errorObj = {};

          response.data['wrong columns']?.forEach((i) => {
            Object.entries(i).forEach((i) => {
              errorObj[i[0]] = i[1];
            });
          });

          window.scrollTo(0, 0);
          fillError(errorObj, true, fields, setFields);
        });
    }
  }

  function closeActiveModal() {
    setActiveModal((prev) => ({ ...prev, show: false }));
  }

  const handleExit = () => {
    history.push('/');
    dispatch(closePopup());
  };

  const handleSaveAndExit = () => {
    onSave('link', '/');
    dispatch(closePopup());
  };

  const onCloseActiveModal = () => {
    dispatch(closePopup());
  };

  const onApprove = () => {
    const user = isLender ? getUserFromStorage() : {};

    if (prospectId) {
      API()
        .post('/prospects/BDR/approve', { id: prospectId })
        .then(({ data }) => {
          toast.success('Lender has been approved successfully');

          if (isLender) {
            localStorage.setItem(Keys.JWT_TOKEN, data.access_token);
            dispatch(setUserData({ ...user, token: data.access_token, role: data.role }));
            history.push('/onboarding/dashboard');
          } else {
            history.push('/onboarding/prospects');
          }
        });
    } else {
      API()
        .post('/prospects/BDR/approve')
        .then(({ data }) => {
          toast.success('Lender has been approved successfully');

          if (isLender) {
            localStorage.setItem(Keys.JWT_TOKEN, data.access_token);
            dispatch(setUserData({ ...user, token: data.access_token, role: data.role }));
            history.push('/onboarding/dashboard');
          } else {
            history.push('/onboarding/prospects');
          }
        });
    }
  };

  const submitAgreementUser = () => {
    API()
      .post('/prospects/BDR/send_to_approve')
      .then(() => {
        history.push(`/registration/lender/review?${location.search}`);
      });
  };

  useEffect(() => {
    if (!isTeam) {
      if (prospectId) {
        API()
          .get('/prospects/BDR', { params: { id: prospectId } })
          .then(({ data }) => setProspectData({ ...data }));
      }

      API()
        .get('/prospects/BDR/profile', { params: { id: prospectId } })
        .then(({ data }) => {
          const tempFields = { ...fields };

          if (data.job_title && !radioButtons.includes(data.job_title)) {
            tempFields.radioButtons_job_title.value = 'Other';
            tempFields.otherJobTitle.value = data.job_title;
            delete data.job_title;
          }

          setEdit(data.actions.includes('Edit'));
          setApprove(data.actions.includes('Approve'));
          fillFields(data, true, tempFields, setFields);
        });
    } else {
      setEdit(true);
    }
  }, [isTeam, prospectId]); // eslint-disable-line

  const globalAPI = useMemo(() => new BDRAPI(), []);

  function submitInvitation({ cc, send_copy_to_self, message }) {
    globalAPI.inviteUser(prospectId, cc, message, send_copy_to_self, true).then(() => {
      toast.success('Email successfully sent');

      closeActiveModal();
      history.push('/onboarding/prospects');
    });
  }

  return (
    <div className="registration lender-register">
      <RegistrationSubHeader
        maxWidth={isTeam ? 800 : 946}
        progress={progress}
        actions={
          isTeam
            ? [<Button key={1} text="Complete" onClick={() => onSave('submit')} primary />]
            : edit
            ? [
                <Button key={1} text="Save" onClick={() => onSave('save')} primary />,
                <Button
                  key={2}
                  text={isLender ? 'Send verification' : 'Submit'}
                  onClick={() => {
                    if (isLender) {
                      onSave('send_verification');
                    } else {
                      if (disabledAction) {
                        return checkForErrors();
                      }

                      onSave('save');
                      setActiveModal((prev) => ({ ...prev, show: true }));
                    }
                  }}
                  primary
                />,
              ]
            : approve
            ? [<Button key={1} text="Approve" onClick={onApprove} primary />]
            : []
        }
      />
      <div className="registration-content">
        <ManualEMSModal
          onCancel={closeActiveModal}
          onSubmit={submitInvitation}
          templateRoute={prospectId ? `/prospects/BDR/send_invite?id=${prospectId}` : undefined}
          title="Send verification"
          visible={activeModal.show}
        />
        <RenderInputs
          autocompleteAddressFields={autocompleteAddressFields}
          data={fields}
          edit={edit}
          onChange={onChange}
          onTagDelete={onTagDelete}
          onUpload={onUpload}
          selectTag={selectTag}
        />
      </div>
      {/^bdr$/i.test(role) && (
        <Modal
          footer={
            <>
              <CustomButton onClick={handleExit} text="Exit" />
              <CustomButton onClick={handleSaveAndExit} primary text="Save progress & exit" />
            </>
          }
          onCancel={onCloseActiveModal}
          visible={activeExitModal === 'exit'}
        >
          Exit without saving progress?
        </Modal>
      )}
    </div>
  );
}
