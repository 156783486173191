import { Checkbox, Col, Form, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import otherConst from '../../../../../constants/otherConst';

import Button from '../../../../../components/UI/Button/Button';

export default function MandateLocations({ control, error, view, locations = [], availableStates = [], onSetValue }) {
  const [checkedList, setCheckedList] = useState([]);

  useEffect(() => {
    if (locations) {
      setCheckedList(locations);
    }
  }, [locations?.length]);

  const handleSelectAll = () => {
    if (checkedList?.length === availableStates?.length) {
      setCheckedList([]);
      onSetValue('locations', []);
    } else {
      setCheckedList(availableStates);
      onSetValue('locations', availableStates);
    }
  };

  const handleChange = (checkedValues) => {
    setCheckedList(checkedValues);
    onSetValue('locations', checkedValues);
  };

  return (
    <Form.Item label="Location" validateStatus={error && 'error'} help={error && error.message}>
      <Row gutter={[20, 20]} align="middle">
        <Col span={24}>
          <Controller
            name="rural_locations_accepted"
            control={control}
            render={({ field }) => (
              <Checkbox {...field} checked={field.value}>
                Yes, we finance properties in rural locations
              </Checkbox>
            )}
          />
        </Col>
        <Col>
          <Button
            primary
            disabled={view}
            text={checkedList.length === availableStates?.length ? 'Clear All' : 'Select All'}
            onClick={handleSelectAll}
          />
        </Col>
        <Col span={24}>
          <Checkbox.Group name="locations" value={checkedList} onChange={handleChange}>
            <Space direction="horizontal" size={[64, 12]} wrap>
              {otherConst.states
                .filter(({ preview }) => availableStates.includes(preview))
                .map(({ preview }) => (
                  <Checkbox
                    key={`${preview}_state_name`}
                    style={{ minWidth: 200 }}
                    value={preview}
                    checked={checkedList.includes(preview)}
                  >
                    {preview}
                  </Checkbox>
                ))}
            </Space>
          </Checkbox.Group>
        </Col>
      </Row>
    </Form.Item>
  );
}
