import { useState } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import { ArchiveIcon } from 'assets/icons';
import UnarchiveLARModal from 'components/LARDB/UnarchiveLARModal';
import styles from './ArchivalReasonPlaque.module.css';

function ArchivalReasonPlaque({ id, reason, onUnarchive, className }) {
  const [modalOpen, setModalOpen] = useState(false);

  if (!reason) {
    return null;
  }

  function unarchiveCallback() {
    setModalOpen(false);
    onUnarchive?.();
  }

  return (
    <div className={classNames(styles.root, className)}>
      <ArchiveIcon colour="white" size="2.3em" />
      <div>
        This deal is archived. Reason:
        {reason}
      </div>
      <Button onClick={() => setModalOpen(true)}>Restore</Button>

      <UnarchiveLARModal
        callback={unarchiveCallback}
        larId={id}
        onCancel={() => setModalOpen(false)}
        visible={modalOpen}
      />
    </div>
  );
}

export default ArchivalReasonPlaque;
