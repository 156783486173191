function NewTabLink({ children, href }) {
  return (
    <a target="_blank" rel="noopener noreferrer" href={href}>
      {children}
    </a>
  );
}

export default function BrokerLandingConsentLinks({ links }) {
  if (!links) {
    return null;
  }

  return (
    <span className="description-12">
      {'By signing up I acknowledge and agree with '}
      <NewTabLink href={links['Privacy Policy']}>Privacy Policy</NewTabLink>
      {' and '}
      <NewTabLink href={links['Terms of Service']}>Terms of Service</NewTabLink>
      {' of the platform.'}
    </span>
  );
}
